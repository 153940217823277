export default [
  'font', 'size',
  'bold', 'italic', 'underline', 'strike',
  'color', 'background',
  'script',
  'header', 'blockquote', 'code-block',
  'list', 'indent',
  'direction', 'align',
  'link', 'image',
  // 'link', 'image', 'video', // Đang research resise video
  'clean',
];
