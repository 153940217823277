import React from 'react';
import PropTypes from 'prop-types';
import { query } from 'helpers/QueryHelper';
import { useQuery } from 'react-query';
import { Button, Checkbox } from 'antd';
import i18next from 'i18next';

const fetcher = (teacherId) => () => {
  return query('p_SACHSO_CLASSES_GetClasses_ByTeacherId', { teacherId });
};

const CheckboxGroup = Checkbox.Group;

const SelectClassGroup = ({ id, setSelectedClass }) => {
  const key = ['p_SACHSO_CLASSES_GetClasses_ByTeacherId', id];
  const { data, isLoading } = useQuery(key, fetcher(id));
  const [dataOptions, setDataOptions] = React.useState([]);

  const [checkedList, setCheckedList] = React.useState([]);
  const [indeterminate, setIndeterminate] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    setSelectedClass(list);
    setIndeterminate(!!list.length && list.length < dataOptions.length);
    setCheckAll(list.length === dataOptions.length);
  };

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? dataOptions.map(x => x.value) : []);
    setSelectedClass(e.target.checked ? dataOptions.map(x => x.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  React.useEffect(() => {
    if (!isLoading) {
      setDataOptions(data.map(x => {
        return { label: x.className, value: x.id };
      }));
    }
  }, [data, isLoading]);

  if (isLoading) return null;

  return (
    <div>
      <div style={{ fontWeight: 600, color: '#69738F', marginBottom: 5 }}>{i18next.t("class:title.class list")}</div>
      <div className='my-2'>
        <Button type={checkAll ? 'dashed' : 'default'} danger={checkAll}>
          <Checkbox style={{ color: checkAll ? '#FF7D7F' : 'black' }} indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            {checkAll ? i18next.t("Deselect all") : i18next.t("Select all")}
          </Checkbox>
        </Button>
      </div>
      <CheckboxGroup options={dataOptions} value={checkedList} onChange={onChange} />
    </div>
  );
};

SelectClassGroup.propTypes = {
  id: PropTypes.any.isRequired,
  setSelectedClass: PropTypes.func.isRequired,
};

export default SelectClassGroup;
