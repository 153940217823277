import { sachsoServerUrl } from 'constants/serverUrls';
import settings from 'constants/settings';

const createImageUploadingProps = (setImgUrl, form, message) => ({
  // The API for image storing
  action: `${sachsoServerUrl}/api/v1.0/dynamic/upload-files`,
  headers: {
    Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
    ApplicationName: settings.APPLICATION_NAME,
  },
  data: {
    entityId: '46d30c13-3555-4395-8b83-4b9b2f7402ae',
    subModuleName: 'RESOURCES',
    entityName: 'Questions',
    moduleName: 'SACHSO',
    moduleId: settings.MODULE_ID,
  },

  name: 'files',
  showUploadList: false,
  onChange(info) {
    if (info.file.status === 'done') {
      setImgUrl(info.file.response?.files?.[0]?.downloadUrl);
      form.setFieldsValue({
        imageUrl: info.file.response?.files?.[0]?.downloadUrl,
      });
    } else if (info.file.status === 'error') {
      message.error(`Tải ${info.file.name} thư mục thất bại.`);
    }
  },
  beforeUpload(file) {
    // Validate type and size of the image will be uploaded
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLessThanTwoMb = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
      message.error('Chỉ được upload file JPG hoặc PNG');
    }
    if (!isLessThanTwoMb) {
      message.error('Kích cỡ image không vượt quá 2MB!');
    }

    return (isJpgOrPng && isLessThanTwoMb) || Upload.LIST_IGNORE;
  },
});

export default createImageUploadingProps;
