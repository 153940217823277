import Teacher_Classes_Details from 'pages/Teacher/Classes/ClassDetails';
import Teacher_Classes from 'pages/Teacher/Classes/Classes';
import React from 'react';
// Icons
import AssignmentsManagement from 'modules/TeacherModule/components/AssignmentsManagement/components';
import Users from 'pages/Administration/Users/index';
import { MdClass, MdDoneOutline, MdGroup as ClassIcon } from 'react-icons/md';
import IndexAddQuestion from 'pages/Administration/FormAddQuestions';
import IndexCategoryType from 'pages/Administration/Category';
import IndexImportQuestion from 'pages/Administration/ImportQuestion';
import ConfigSettings from 'pages/Administration/ConfigSettings';
import { FaCogs, FaThList } from 'react-icons/fa';
import colors from 'constants/colors';
import Student_Books from 'pages/Student/Books';
import ManagerCourse from 'pages/Administration/ManagerCourse';
import { SiGoogleclassroom } from 'react-icons/si';
import { HiOutlinePresentationChartBar } from 'react-icons/hi';
import { IoLibraryOutline } from 'react-icons/io5';
import { TbReportAnalytics } from 'react-icons/tb';

export default [
  {
    path: '/admin/import-question',
    name: 'Quản lý câu hỏi',
    miniName: 'C',
    icon: 'fas fa-question text-green',
    component: IndexImportQuestion,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 0,
  },

  // {
  //   path: '/admin/insert-type',
  //   name: 'Type & Options Type',
  //   miniName: 'C',
  //   icon: '	fa fa-bell',
  //   component: IndexCategoryType,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['administrationRouters'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },
  {
    component: Teacher_Classes,
    exact: true,
    icon: <SiGoogleclassroom size={20} className='tw-text-red-500' />,
    layout: '/lms',
    miniName: 'C',
    name: 'Quản lý lớp học',
    path: '/admin/classes',
    roles: ['administrator'],
    showInSidebar: true,
    sortOrder: 2,
    type: ['allowedRoutes'],
  },
  {
    collapse: true,
    path: '/admin/report',
    name: 'Báo cáo, thống kê',
    miniName: 'C',
    icon: <TbReportAnalytics size={20} style={{ color: '#AC81FF' }} />,
    roles: ['administrator', 'teacher'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 5,
    state: 'lms/admin/report',
    views: [
      {
        path: '/admin/report/time',
        name: 'Báo cáo theo ngày',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportTime')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 0,
      },
      {
        path: '/admin/week',
        name: 'Báo cáo theo tuần',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportWeek')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],

        showInSidebar: true,
        sortOrder: 1,
      },
      {
        path: '/admin/month',
        name: 'Báo cáo theo tháng',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportMonth')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },

  // {
  //   component: React.lazy(() => import('pages/Administration/Vimaru/StudentManagement')),
  //   exact: true,
  //   layout: '/lms',
  //   path: '/admin/classes/:classId',
  //   roles: ['administrator'],
  //   type: ['allowedRoutes'],
  // },

  {
    path: '/admin/users',
    name: 'Quản lý người dùng',
    miniName: 'C',
    icon: 'fa fa-user text-red',
    component: Users,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 0,
  },
  {
    path: '/admin/add-question',
    name: 'Quản lý câu hỏi',
    miniName: 'C',
    component: IndexAddQuestion,
    icon: 'fas fa-book text-green',
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: false,
  },
  // {
  //   path: '/admin/students',
  //   name: 'Quản lý học viên',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Administration/Vimaru/StudentManagement')),
  //   icon: <ClassIcon size={20} className='tw-text-yellow-500' />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['allowedRoutes'],
  //   showInSidebar: true,
  //   sortOrder: 1,
  // },

  {
    path: '/admin/presentations',
    name: 'Quản lý bài giảng',
    miniName: 'C',
    component: React.lazy(() => import('pages/Teacher/Presentations')),
    icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500' />,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
  },

  {
    path: '/admin/presentation/:presentationId/create',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/presentation/:presentationId/edit',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },
  {
    collapse: true,
    path: '/admin/worksheets',
    name: 'Quản lý thư viện',
    miniName: 'C',
    icon: <IoLibraryOutline size={20} className='tw-text-green-600' />,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 4,
    state: 'lms/admin/worksheets',
    views: [
      {
        path: '/admin/worksheets/list/:filter',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
      },
      // {
      //   path: '/admin/worksheets/list/quizcity-manual',
      //   name: 'Bài tập Manual',
      //   miniName: 'D',
      //   component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
      //   layout: '/lms',
      //   exact: true,
      //   roles: ['administrator'],
      //   type: ['allowedRoutes'],

      //   showInSidebar: true,
      //   sortOrder: 0,
      // },
      {
        path: '/admin/worksheets/list/quizcity-worksheet',
        name: 'Bài tập Worksheet',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],

        showInSidebar: true,
        sortOrder: 1,
      },
      {
        path: '/admin/worksheets/list/quizcity-quiz',
        name: 'Bài tập Quiz',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },

  {
    path: '/admin/assigments',
    name: 'Quản lý bài tập',
    miniName: 'C',
    component: AssignmentsManagement,
    icon: 'fas fa-book text-green',
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: false,
    sortOrder: 4,
  },

  // {
  //   path: '/admin/quizcity/worksheets/update',
  //   name: 'Tạo worksheet',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Teacher/QuizCity/UpdateWorkSheet')),
  //   icon: <ClassIcon size={20} className='tw-text-blue-600' />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },
  // {
  //   path: '/admin/quizcity/worksheets/update/:id',
  //   component: React.lazy(() => import('pages/Teacher/QuizCity/UpdateWorkSheet')),
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  // },

  {
    path: '/admin/classes/:classId',
    component: Teacher_Classes_Details,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/add',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/edit/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/details/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/Details')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/configs',
    name: 'Thiết lập thông tin',
    component: ConfigSettings,
    icon: <FaCogs style={{ color: '#EC4899' }} />,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 5,
  },
  {
    path: '/admin/danh-muc-khoa-hoc',
    name: 'Danh mục khoá học',
    miniName: 'C',
    icon: <FaThList color='#A3F7BF' />,
    component: ManagerCourse,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 1,
  },
];
