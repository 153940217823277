import withAuthenticator from 'HOCs/withAuthenticator';
import { Progress } from 'antd';
import ConditionsContainer from 'components/Containers/ConditionsContainer';
import colors from 'constants/colors';
import { query } from 'helpers/QueryHelper';
import { statusRequest } from 'pages/Administration/ManagerCoachingSchedule/tableColumn';
import CountUp from 'react-countup';
import { useQuery } from 'react-query';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import '../styles.css';
const fetchTeacher = (teacherId) => {
  return query('p_VIMARU_GetThongKeClass_ByTeacher', { teacherId });
};
const fetchStudent = (studentId, folderId) => {
  return query('p_VIMARU_GetThongKe_BaiTap', { studentId, folderId });
};
const fetchAdmin = (userId) => {
  return query('p_QUIZCITY_Report_ThongKe_Dashboard', { userId });
};

function lgHeader({ loggedInUser, selectedFolder, setReportHeader }) {
  const KEY_STUDENT = ['p_VIMARU_GetThongKe_BaiTap', loggedInUser?.id, selectedFolder?.id];
  const KEY_ADMIN = ['p_QUIZCITY_Report_ThongKe_Dashboard', loggedInUser?.id];
  const { data: statisticalStudent } = useQuery(KEY_STUDENT, () => fetchStudent(loggedInUser?.id, selectedFolder?.id), {
    enabled: loggedInUser?.role === 'student' && !!selectedFolder?.id,
  });
  const { data: statisticalAdmin } = useQuery(KEY_ADMIN, () => fetchAdmin(loggedInUser?.id), {
    enabled: loggedInUser?.role !== 'student',
  });
  let data;
  switch (loggedInUser?.role) {
    case 'student':
      data = statisticalStudent?.[0];
      setReportHeader(statisticalStudent?.[0]);
      break;
    default:
      data = statisticalAdmin?.[0];
  }
  const renderTag = (status) => {
    if (!status) return '';
    const statusDisplay = statusRequest.find((item) => item.status.toUpperCase() === status?.toUpperCase());
    return statusDisplay?.tag;
  };

  return (
    <>
      <div
        className='header pb-6'
        style={{
          backgroundColor: colors.theme.background,
          borderTop: `1px solid ${colors.theme.background}`,
          zIndex: -9,
        }}
      >
        <Container fluid>
          <div className='header-body'>
            {/* Theo roles */}
            <Row style={{ display: 'flex' }}>
              {/* Theo roles */}
              <ConditionsContainer roles={['student']}>
                <Col style={{}}>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className={`project-color text-uppercase mb-0`}>
                            Learning Status
                          </CardTitle>
                        </div>
                        {/* <Col className='col-auto'> */}
                        <div className='icon icon-shape bg-gradient-indigo text-white rounded-circle shadow'>
                          <i className='ni ni-chart-pie-35' />
                        </div>
                        {/* </Col> */}
                      </div>
                      <Row>
                        <Col span={8}>{data?.complete01?.[0]?.title} </Col>
                        <Col span={16}>
                          <Progress percent={data?.complete01?.[0]?.value} />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>{data?.complete02?.[0]?.title} </Col>
                        <Col span={16}>
                          <Progress percent={data?.complete02?.[0]?.value} />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>{data?.complete03?.[0]?.title} </Col>
                        <Col span={16}>
                          <Progress percent={data?.complete03?.[0]?.value} />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}>{data?.complete04?.[0]?.title} </Col>
                        <Col span={16}>
                          {/* <Tag icon={<CheckCircleOutlined />} color='success'>
                          {data?.complete04?.[0]?.value}
                        </Tag> */}
                          {renderTag(data?.complete04?.[0]?.value)}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </ConditionsContainer>
              <ConditionsContainer roles={['administrator', 'manager', 'teacher']}>
                <Col style={{}}>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className={`project-color text-uppercase mb-0`}>
                            Learning Status
                          </CardTitle>

                          <Row>
                            <Col>
                              <span style={{ display: 'inline-block', width: 85 }}>Total users</span>
                              <CountUp
                                useEasing={true}
                                style={{ color: '#F54A49', fontSize: '1rem', fontWeight: 500 }}
                                start={0}
                                duration={5}
                                // prefix='Total users '
                                // suffix={' users'}
                                end={data?.totalUser}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <span style={{ display: 'inline-block', width: 85 }}>Studying</span>
                              <CountUp
                                useEasing={true}
                                style={{ color: '#FB8C40', fontSize: '1rem', fontWeight: 500 }}
                                start={0}
                                duration={5}
                                // suffix={' users'}
                                end={data?.totalStudying}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <span style={{ display: 'inline-block', width: 85 }}>Complete</span>
                              <CountUp
                                useEasing={true}
                                style={{ color: '#2DCEAA', fontSize: '1rem', fontWeight: 500 }}
                                start={0}
                                duration={5}
                                // suffix={' users'}
                                end={data?.totalCompleted}
                              />
                            </Col>
                          </Row>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-indigo text-white rounded-circle shadow'>
                            <i className='ni ni-chart-pie-35' />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </ConditionsContainer>
              <Col style={{}}>
                <Card className='card-stats'>
                  <CardBody className='pb-2'>
                    <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
                      <div
                        className='col'
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                      >
                        <CardTitle tag='h5' className={`project-color text-uppercase mb-0`}>
                          Total hours of learning
                        </CardTitle>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-gradient-red text-white rounded-circle shadow'>
                          <i className='ni ni-active-40' />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          decimals={2}
                          style={{ color: '#F54A49' }}
                          start={0}
                          duration={5}
                          end={data?.hours_Theory}
                        ></CountUp>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col style={{}}>
                <Card className='card-stats'>
                  <CardBody className='pb-2'>
                    <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
                      <div
                        className='col'
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                      >
                        <CardTitle tag='h5' className={`project-color text-uppercase mb-0`}>
                          Total hours of practice
                        </CardTitle>
                      </div>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-gradient-orange text-white rounded-circle shadow'>
                          <i className='ni ni-chart-pie-35' />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Col>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          decimals={2}
                          style={{ color: '#FB8C40' }}
                          start={0}
                          duration={5}
                          end={data?.hours_Practice}
                        ></CountUp>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col style={{}}>
                <Card className='card-stats'>
                  <CardBody className='pb-2'>
                    <Row style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                      <Col>
                        <CardTitle tag='h5' className={`project-color text-uppercase mb-0`}>
                          LIVEWORKSHEET GPA
                        </CardTitle>
                      </Col>
                      <Col className='col-auto'>
                        <div className='icon icon-shape bg-gradient-green text-white rounded-circle shadow'>
                          <i className='ni ni-chart-bar-32' />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Col>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          decimals={2}
                          style={{ color: '#2DCEAA' }}
                          start={0}
                          duration={5}
                          end={data?.liveWprkSheet_GPA}
                          suffix='%'
                        ></CountUp>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default withAuthenticator(lgHeader);
