export default [
  {
    id: 30000,
    imageUrl: '/assets/images/covers/american-skills.jpg',
    externalLink: 'https://hcm-demo-2023.sachso.edu.vn',
    title: 'American Skills:',
    subTitle: 'Math Skills',
    description: 'Starter 1',
    sortOrder: 0,
    schoolTypeId: 1,
    classId: 3,
    bookType: 'sb',
  },
  {
    id: 30001,
    imageUrl: '/assets/images/covers/pattern-math.jpg',
    externalLink: 'https://kynangtuduy-demo-2023.sachso.edu.vn',
    title: 'Giáo trình',
    subTitle: 'Level 3',
    description: 'Kỹ năng tư duy',
    sortOrder: 1,
    schoolTypeId: 1,
    classId: 3,
    bookType: 'sb',
  },
  // sortOrder: 21, 22 dự trù cho lớp 11 version 1
  // sortOrder: 23, 24 dự trù cho lớp 12 version 1
];
