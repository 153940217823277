/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from 'antd/es/tooltip';

// import { captureUserMedia } from './utils';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactMic } from '@cleandersonlobo/react-mic';
import notification from 'components/Notification';
import { Button } from 'antd';
import axios from 'axios';
import _ from 'lodash';

let source = axios.CancelToken.source();
class Recorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false,
      src: null,
      recognizer: null,
      recordAudio: undefined,
      statusRecord: 'start',
      classNames__btnRecord: null,
      disabled: true,
      statusText: 'Thu âm bị lỗi',
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    this.initialRecord();
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { recordParams } = this.props;
    if (recordParams.questionId !== nextProps.recordParams.questionId) {
      this.setState({ isRecord: false });
    }
  };

  onStopCountdown = () => {
    if (this.state.statusRecord === 'start') {
      this.setState({
        statusRecord: 'stop',
        statusText: 'Dừng lại',
      });
    } else {
      this.setState(
        {
          statusRecord: 'cal',
          classNames__btnRecord: null,
        },
        () => {
          this.recordStop();
        },
      );
    }
  };

  onStopNext = async () => {
    const { onStopTimer } = this.props;
    if (onStopTimer) {
      onStopTimer();
      this.props.__onStopRecording(undefined);
    }
    this.setState({
      record: false,
      src: null,
      recognizer: null,
      recordAudio: undefined,
      statusRecord: 'start',
      isRecord: false,
      classNames__btnRecord: null,
    });
  };

  stopOnNext = () => {
    this.onStopNext();
  };

  cancelRequest = () => {
    if (this.state.statusRecord === 'cal') {
      source.cancel();
    }
    source = axios.CancelToken.source();
  };

  initialRecord = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      this.setState({ disabled: false, statusText: 'Bắt đầu ghi âm' });
    } catch (error) {
      // console.log(error);
      notification('danger', 'Thông báo', 'Trình duyệt của bạn chưa hỗ trợ thu âm vui lòng thiết lập cài đặt.');
      // setRecordEnable(false);
    }
  };

  onRecord = () => {
    if (this.state.statusRecord === 'start') {
      this.setState(
        {
          statusRecord: 'stop',
          classNames__btnRecord: ['pulse', 'infinite'],
          statusText: 'Dừng lại',
        },
        () => {
          // record
          this.recordStart();
        },
      );
    } else {
      this.setState(
        {
          statusRecord: 'cal',
          classNames__btnRecord: null,
        },
        () => {
          this.recordStop();
        },
      );
    }
  };

  recordStart = () => {
    this.setState({
      record: true,
    });
    if (this.props.__onRecording) {
      this.props.__onRecording();
    }
  };

  recordStop = () => {
    this.setState({
      record: false,
      classNames__btnRecord: null,
      isRecord: true,
    });
  };

  onData() {
    // console.log('chunk of real-time data is: ', recordedBlob);
  }

  recognize = async (
    recordData,
    readingText,
    studentId,
    scope,
    questionId = '',
    takeExamTime = '',
    fileType = 'wav',
  ) => {
    const formData = new FormData();
    formData.append('base64String', _.replace(recordData.toString(), 'data:audio/wav;base64,', ''));
    formData.append('readingText', readingText);
    formData.append('studentId', studentId);
    formData.append('questionId', questionId);
    formData.append('takeExamTime', takeExamTime);
    formData.append('mode', 'Dictation');
    formData.append('fileType', fileType);
    formData.append('appName', 'MYAMES-WEB');
    formData.append('device', 'WEB');

    scope.setState({
      statusRecord: 'start',
      statusText: 'Thu âm lại',
    });

    const result = {
      recordUrl: recordData,
      responseType: 'A',
    };
    this.props.__onStopRecording({ ...result });
  };

  onStop = async (recordedBlob) => {
    const { recordParams, onStopTimer, assessment } = this.props;
    const { questionId, studentId, takeExamTime, questionText, isTypeIELTS_DICTATION } = recordParams;

    if (onStopTimer) {
      onStopTimer();
    }
    const scope = this;

    var reader = new FileReader();
    reader.readAsDataURL(recordedBlob?.blob);
    reader.onloadend = async () => {
      var base64data = reader.result;

      if (assessment) {
        this.recognize(
          base64data,
          questionText,
          studentId,
          scope,
          questionId,
          takeExamTime,
          isTypeIELTS_DICTATION ? 'mp3' : 'wav',
        );

        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append('Device', 'WEB');
      bodyFormData.append('mode', 'Dictation');
      bodyFormData.append('questionId', questionId);
      bodyFormData.append('readingText', questionText);
      bodyFormData.append('studentID', studentId);
      bodyFormData.append('takeExamTime', takeExamTime);
      bodyFormData.append('extensionInput', isTypeIELTS_DICTATION ? 'mp3' : 'wav');
      bodyFormData.append('input', base64data);
      bodyFormData.append('speechRecognitionAPI', 'A');

      try {
        const response = await axios({
          method: 'POST',
          url: isTypeIELTS_DICTATION
            ? 'https://toeic.ames.edu.vn/ames/api/amesapi/CompareAudioAndSentence'
            : 'https://toeic.ames.edu.vn/ames/api/amesApi/SaveFileAndCalculateScore',
          data: bodyFormData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } },
        });

        scope.setState({ statusRecord: 'start', statusText: 'Thu âm lại' });
        if (response.statusText === 'OK') {
          if (this.props.__onStopRecording) {
            this.props.__onStopRecording(response.data);
          }
        }
      } catch (error) {
        scope.setState({
          statusRecord: 'start',
          statusText: 'Thu âm lại',
        });

        this.props.__onStopRecording(undefined);

        notification('danger', 'Thông báo', 'Thu âm bị lỗi!, Bạn vui lòng thu âm lại!');
      }
    };
  };

  render() {
    let { src, statusRecord, classNames__btnRecord, disabled, statusText, isRecord } = this.state;
    if (this.props.__custom) {
      let { __className, __icon } = this.props;
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div className='mb-3 tw-gap-5 d-flex justify-content-center align-items-center'>
            {this.props.listen}
            {!this.props.isListen && this.props.hasRecord && (
              <Tooltip placement='top' title={statusText}>
                <Button
                  style={{
                    display: 'flex',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '80px',
                    width: '80px',
                  }}
                  className={classNames([
                    'animated',
                    classNames__btnRecord,
                    __className,
                    (statusRecord === 'stop' || statusRecord === 'cal') && 'button-recording-pulse',
                  ])}
                  onClick={disabled ? null : this.onRecord}
                >
                  {disabled ? (
                    <i style={{ fontSize: '25px', color: 'black' }} className='fas fa-microphone-slash' />
                  ) : statusRecord === 'cal' ? (
                    <LoadingOutlined style={{ fontSize: 30, color: 'black' }} />
                  ) : statusRecord === 'start' ? (
                    __icon ? (
                      __icon
                    ) : isRecord ? (
                      <i style={{ fontSize: '25px', color: 'black' }} className='fas fa-undo' />
                    ) : (
                      <i style={{ fontSize: '25px', color: 'black' }} className='fas fa-microphone' />
                    )
                  ) : (
                    <i style={{ fontSize: '25px', color: 'black' }} className='fas fa-stop'></i>
                  )}
                </Button>
              </Tooltip>
            )}
          </div>

          {this.props.children}
          <br />

          <div>
            <ReactMic
              record={this.state.record}
              visualSetting='none'
              className='sound-wave d-none'
              onStop={this.onStop}
              onData={this.onData}
              audioBitsPerSecond={128000}
              mimeType={this.props.recordParams?.isTypeIELTS_DICTATION ? 'audio/mp3' : 'audio/wav'}
            />
          </div>
        </div>
      );
    }

    return null;
  }
}

Recorder.propTypes = {
  __custom: PropTypes.bool,
  __className: PropTypes.string,
  __icon: PropTypes.string,
  __listenAgain: PropTypes.bool,
  onStopTimer: PropTypes.func,
  __onRecording: PropTypes.func,
  __onStopRecording: PropTypes.func,
  __isDoneCal: PropTypes.func,
  recordParams: PropTypes.instanceOf(Object),
  assessment: PropTypes.bool,
  listen: PropTypes.element,
  isListen: PropTypes.bool,
  hasRecord: PropTypes.bool,
};

export default Recorder;
