const crypto = require('crypto');

const algorithm = 'aes-256-cbc';
const key = 'b14ca5898a4e4133bbce2ea2315a1916';
const iv = new Buffer.alloc(16);

export function encrypt(text) {
  const cipher = crypto.createCipheriv(algorithm, Buffer.from(key), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return encrypted.toString('base64');
}

export function decrypt(text) {
  const encryptedText = Buffer.from(text, 'base64');
  const decipher = crypto.createDecipheriv(algorithm, Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}

export function generateSHA1(text) {
  const shasum = crypto.createHash('sha1');
  shasum.update(text);
  return shasum.digest('hex'); // => "0beec7b5ea3f0fdbc95d0dd47f3c5bc275da8a33"
}
