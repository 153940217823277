import React, { FC } from 'react';
import styles from './dotselect.module.css';

type DotSelectProps = {
  id: number;
  userSelect: number[];
};

const DotSelect: FC<DotSelectProps> = ({ id, userSelect }) => {
  if (!userSelect?.includes(id)) return null;
  return <div className={styles.container} />;
};

export default DotSelect;
