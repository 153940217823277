import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Popover } from 'antd';
import Tooltip from 'antd/es/tooltip';
import DynamicTooltip from 'packages/DynamicTooltip/DynamicTooltip';
import i18next from 'i18next';

class Listen extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      statusListen: 'start',
      classNames__btnRecord: null,
      audioURL: null,
    };
    this.onListen = this.onListen.bind(this);
    this.audioPlayer = new Audio(this.props.audioURL);
    this.event = this.audioPlayer.addEventListener('ended', () => this.props.onEnded());
  }

  componentWillUnmount = () => {
    this.onStop();
  };

  componentDidMount = () => {
    if (this.props.onAuto) this.onListen();
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.audioURL !== nextProps.audioURL) {
      if (nextProps.onAuto) this.onListen();
      this.onStop();
      this.setState({
        statusListen: 'start',
        classNames__btnRecord: null,
      });
      this.audioPlayer = new Audio(nextProps.audioURL);
      this.event = this.audioPlayer.addEventListener('ended', () => this.props.onEnded());
    }
  };

  onListen = () => {
    if (!this.props.audioURL) return;
    if (!this.audioPlayer) return;

    if (this.state.statusListen === 'start') {
      this.setState(
        {
          statusListen: 'stop',
          // classNames__btnRecord: ["pulse", "infinite"],
        },
        () => {
          this.onPlay();
        },
      );
    } else {
      this.setState(
        {
          statusListen: 'start',
          classNames__btnRecord: null,
        },
        () => {
          this.onPause();
        },
      );
    }
  };

  /**
  |--------------------------------------------------
  | audio play
  |--------------------------------------------------
  */
  onPlay = () => {
    if (!this.audioPlayer || this.props.disabledAudio) return;

    // do something
    if (this.props.onListened) {
      this.props.onListened();
    }

    this.audioPlayer.play();

    // eventListen when audio ended
    this.audioPlayer.onended = this.onEnded;
  };

  /**
  |--------------------------------------------------
  | audio pause
  |--------------------------------------------------
  */
  onPause = () => {
    if (this.audioPlayer) this.audioPlayer.pause();
  };

  /**
  |--------------------------------------------------
  | audio stop
  |--------------------------------------------------
  */
  onStop = () => {
    this.onPause();
    this.audioPlayer = null;
    this.event = null;
  };

  /**
  |--------------------------------------------------
  | audio reset
  |--------------------------------------------------
  */
  onReset = () => {
    if (this.audioPlayer) this.audioPlayer.currentTime = 0;
  };

  /**
  |--------------------------------------------------
  | audio ended
  |--------------------------------------------------
  */
  onEnded = () => {
    this.setState({
      statusListen: 'start',
      classNames__btnRecord: null,
    });
  };

  /** UI -----------------------------------------* */
  render() {
    const { classNames__btnRecord, statusListen } = this.state;
    if (!this.props.custom) {
      return (
        <Row type='flex' justify='center' align='top'>
          <Col className={classNames(['text-align_center'])}>
            <DynamicTooltip placement='top' title={statusListen === 'start' ? i18next.t("slide:record.Click to listen") : i18next.t("slide:record.Click to pause")}>
              <div
                //  style={{backgroundColor: "#5E72E4"}}
                style={{ width: 80, height: 80 }}
                className={classNames([
                  'btn-dashed',
                  'animated',
                  classNames__btnRecord,
                  'icon icon-shape bg-gradient-danger text-white rounded-circle',
                ])}
                onClick={this.onListen}
              >
                <i
                  style={{ fontSize: '25px', color: 'white' }}
                  className={statusListen === 'start' ? 'fas fa-volume-up' : 'fas fa-pause'}
                />
              </div>
            </DynamicTooltip>
            <Popover trigger='hover' placement='right' content='click on resets the recorder' />
          </Col>
        </Row>
      );
    }
    const { className, style, children } = this.props;
    return (
      <div style={style} className={classNames(['animated', classNames__btnRecord, className])} onClick={this.onListen}>
        {children}
      </div>
    );
  }
}

Listen.propTypes = {
  audioURL: PropTypes.string.isRequired,
  onListened: PropTypes.func,
};

export default Listen;
