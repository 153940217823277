import colors from 'constants/colors';

export const steps = [
  {
    content: (
      <div style={{ color: colors.font }}>
        <div style={{ marginBottom: 15 }}>
          <div style={{ width: '100%', marginBottom: 10 }}>
            <img src={require('assets/img/dashboard/bookingGuide/step.png').default} alt='picture' />
          </div>
          <div
            style={{
              color: 'white',
              backgroundColor: '#C2224f ',
              fontSize: 16,
              fontWeight: 600,
              paddingLeft: 5,
              marginBottom: 5,
            }}
          >
            <span> Coaching Guidelines </span>
          </div>
          <div style={{ color: colors.font }}>
            To make sure you experience the coaching session most conveniently and effectively, let’s keep in mind the
            following guidelines:
          </div>
          <span>
            <strong>1.</strong> &emsp;The learner shall book coaching ONLY after completion of learning and test
            sessions.
          </span>{' '}
          <br />
          <span>
            <strong>2.</strong> &emsp; Each learner has 01 session of <b> 30 – minute </b> coaching during the course.
          </span>
          <br />
          <span>
            <strong>3.</strong> &emsp; The learner should book coaching schedule at least <b>10 days in advance</b> and
            give several options of schedule for TOEIC Trainers to choose one easily.
          </span>
          <br />
          <span>
            <strong>4.</strong>&emsp; The learner may check booking status in this session to get the progress.
          </span>
          <br />
          <span>
            <strong>5.</strong> &emsp; Results of coaching booking will be sent via company email or displayed in
            booking status, including coach’s information, time and platforms to coach within 07 working days.
          </span>
          <br />
          <span>
            <strong>6.</strong> &emsp; <b> Coaching 1-1 </b>: 1 learner – 1 TOEIC Trainer
          </span>
          <br />
          <span>
            <strong>7.</strong> &emsp; Coaching sessions shall be carried out on one of the following platforms:{' '}
            <b>Microsoft Team, Google Meet, Zoom </b> based on convenience and agreement of both learners and coach.
            Each coaching session shall be recorded full time for admin’s supervision and support and the learner takes
            it as reference for later practice. 8. Results of coaching sessions (brief comment) will be uploaded in the
            website.
          </span>
          <br />
          <span>
            <strong>8.</strong>&emsp; Results of coaching sessions (brief comment) will be uploaded in the website.
          </span>
          <br />
        </div>
        <br />

        <div>
          <div
            style={{
              color: 'white',
              backgroundColor: '#C2224f ',
              fontSize: 16,
              fontWeight: 600,
              paddingLeft: 5,
              marginBottom: 5,
            }}
          >
            <span> Coaching Booking Step By Step </span>
          </div>
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <strong>1.</strong> &emsp; Your informations includes your employee code and full name will be autofill by
            the system.
          </div>
          <div style={{ width: '100%', marginBottom: 15 }}>
            <img src={require('assets/img/dashboard/bookingGuide/step1.png').default} alt='picture02' />
          </div>
        </div>
        <div>
          <div>
            <strong>2.</strong> &emsp; Choose coaching schedules which are suitable with your work. You can choose more
            than one for your coach to choose:
          </div>
          <div style={{ marginLeft: 15, display: 'flex', alignItems: 'end' }}>
            {' '}
            ● You click button
            <img
              src={require('assets/img/dashboard/bookingGuide/step2_btn1.png').default}
              alt='picture02-btn1'
              width={30}
            />
            to add a coaching date and coaching time.
          </div>
          <div style={{ marginLeft: 15, display: 'flex', alignItems: 'end', marginBottom: 10 }}>
            {' '}
            ● You click button
            <img
              src={require('assets/img/dashboard/bookingGuide/step2_btn2.png').default}
              alt='picture02-btn2'
              width={30}
            />
            to delete a coaching date and coaching time.
          </div>
          <div style={{ width: '100%', marginBottom: 15 }}>
            <img src={require('assets/img/dashboard/bookingGuide/step2.png').default} alt='picture03' />
          </div>
        </div>

        <div>
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <strong>3.</strong> &emsp; Click to button Booking to confirm your booking:
          </div>
          <div style={{ width: '100%', marginBottom: 15 }}>
            <img src={require('assets/img/dashboard/bookingGuide/step3.png').default} alt='picture04' />
          </div>
        </div>
        <div>
          <div>
            <strong>4.</strong> &emsp; Follow your booking status to update your booking as:
          </div>
          <div style={{ marginLeft: 15 }}>● Pending: Booking is waiting for confirmation</div>
          <div style={{ marginLeft: 15 }}>● Accept: Booking is confirmed</div>
          <div style={{ marginLeft: 15, marginBottom: 10 }}>● Cancel: Booking is not confirmed.</div>
          <div style={{ width: '100%' }}>
            <img src={require('assets/img/dashboard/bookingGuide/step4.png').default} alt='picture05' />
          </div>

          <div style={{ marginLeft: 15, display: 'flex', alignItems: 'end' }}>
            {' '}
            -
            <img
              src={require('assets/img/dashboard/bookingGuide/step4_btn1.png').default}
              alt='picture04-btn1'
              width={30}
            />
            : Review coaching booking detail
          </div>
          <div style={{ marginLeft: 15, display: 'flex', alignItems: 'end' }}>
            {' '}
            -
            <img
              src={require('assets/img/dashboard/bookingGuide/step4_btn2.png').default}
              alt='picture04-btn2'
              width={30}
            />
            : Cancel coaching booking
          </div>
          <div style={{ marginLeft: 15, display: 'flex', alignItems: 'end' }}>
            {' '}
            -
            <img
              src={require('assets/img/dashboard/bookingGuide/step4_btn3.png').default}
              alt='picture04-btn3'
              width={60}
            />
            : Show coaching booking history
          </div>
          <div style={{ marginLeft: 15, marginTop: 10 }}>
            <b style={{ fontStyle: 'italic', color: 'white', backgroundColor: colors.theme.button, padding: 3 }}>
              Notes
            </b>
          </div>
          <div style={{ marginLeft: 30, fontStyle: 'italic' }}>
            <div> - Coaching booking list just display latest coaching booking.</div>
            <div> - Coaching booking just be canceled if its status is pending. </div>
            <div> - Each employee is just allowed to have one coaching session with trainer.</div>
          </div>
        </div>
        <div>
          <div>
            <strong>5.</strong> &emsp;After coaching booking successful, user can view coaching booking detail as:
          </div>
          <div style={{ width: '100%', marginBottom: 15 }}>
            <img src={require('assets/img/dashboard/bookingGuide/step5.png').default} alt='picture05' />
          </div>
        </div>
      </div>
    ),
  },
];
