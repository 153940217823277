import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined } from '@ant-design/icons';
import withAuthenticator from 'HOCs/withAuthenticator';
import { Button, Col, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import Card from 'components/Containers/Card';
import ConditionsContainer from 'components/Containers/ConditionsContainer';
import StudentContainer from 'components/Containers/StudentContainer';
import TeacherContainer from 'components/Containers/TeacherContainer';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router';
import UpdateNotification from './UpdateNotification';
import adminTableData from './adminTableData';
import tableData from './tableData';

const fetcherAdmin = () => () => {
  return query('p_VIMARU_NOTIFICATIONS_GetNotifications');
};

const fetcherUser = (userId) => () => {
  return query('p_VIMARU_GetNotification_ByUser', { userId });
};

const Notifications = ({ loggedInUser, classId }) => {
  const history = useHistory();
  const paramId = history.location.state?.id;
  const [updateModalVisible, setUpdateModalVisible] = React.useState(false);
  const [selectedNotification, setSelectedNotification] = React.useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);
  const queryClient = useQueryClient();
  const [selectedManyNotifications, setSelectedManyNotifications] = React.useState([]);
  const [typeNotificationsDisplay, setTypeNotificationsDisplay] = React.useState('all');

  const fetcher = loggedInUser.role === 'administrator' ? fetcherAdmin() : fetcherUser(loggedInUser.id);
  const key = 'p_VIMARU_GET_notification_all_user';
  const { data: records, isLoading } = useQuery(key, () => fetcher());
  const [notifications, setNotifications] = React.useState([]);

  const handleUpdate = (record) => () => {
    setSelectedNotification(record);
    setUpdateModalVisible(true);
  };

  const handleAdminDelete = (id) => async () => {
    await query('p_VIMARU_DEL_MultiNotification_byAdmin', { notificationIdArray: id });
    queryClient.invalidateQueries(key);
    setSelectedManyNotifications([]);
  };

  const handleUserDelete = (id) => async () => {
    await query('p_VIMARU_DEL_MultiNotification_byUser', { notificationIdArray: id, userId: loggedInUser.id });
    queryClient.invalidateQueries(key);
    setSelectedManyNotifications([]);
  };

  const renderIcon = ({ expanded, onExpand, record }) => {
    if (expanded) {
      return <CaretUpOutlined onClick={(e) => onExpand(record, e)} />;
    }
    return <CaretDownOutlined onClick={(e) => onExpand(record, e)} />;
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedManyNotifications,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedManyNotifications(selectedRowKeys);
    },
  };

  React.useEffect(() => {
    if (records) {
      setNotifications(records);
    }

    return () => {
      setNotifications([]);
    };
  }, [loggedInUser.role, records]);
  React.useEffect(() => {
    const keys = [];
    if (paramId) {
      keys.push(paramId);
      setExpandedRowKeys(keys);
      query('p_VIMARU_Notification_ChangeStatus_ByUser', {
        notificationId: paramId,
        userId: loggedInUser.id,
      }).then(() => {
        queryClient.invalidateQueries(key);
      });
    }
  }, [paramId]);

  const filterFollowCategory = (alldata, category) => {
    if (alldata?.length > 0) {
      return alldata?.filter((i) => i.typeId === category || category === 'all');
    }
    return [];
  };
  const displayArr = filterFollowCategory(notifications, typeNotificationsDisplay);

  return (
    <Card title={i18next.t('notification')} bodyStyles={{ paddingTop: 0, paddingBottom: 0 }}>
      <Row justify='space-between' wrap style={{ padding: '10px 5px', paddingBottom: '5px' }} gutter={[12, 12]}>
        <Col>
          <ConditionsContainer roles={['administrator', 'manager']}>
            <Row align={'middle'} style={{ height: '100%' }} gutter={12}>
              <Col>
                <DynamicButton
                  type='primary'
                  onClick={() => {
                    setSelectedNotification(null);
                    setUpdateModalVisible(true);
                  }}
                >
                  {i18next.t('AddNotification ')}
                </DynamicButton>
              </Col>
              <Col>
                {selectedManyNotifications.length > 0 && (
                  <DynamicButton danger type='dashed' onClick={handleAdminDelete(selectedManyNotifications)}>
                    {i18next.t('DeleteNotification')}
                  </DynamicButton>
                )}
              </Col>
            </Row>
          </ConditionsContainer>
        </Col>
        <Col>
          {settings.IS_LG && (
            <div style={{ height: '100%', display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
              <p style={{ fontWeight: 500, marginBottom: 5 }}>Type: &ensp; </p>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                style={{
                  borderRadius: '0.25rem',
                  width: 150,
                }}
                value={typeNotificationsDisplay}
                options={[
                  { label: 'All', value: 'all' },
                  { label: 'Common', value: 0 },
                  { label: 'Support', value: 1 },
                  { label: 'Booking', value: 2 },
                ]}
                onChange={(value) => {
                  setTypeNotificationsDisplay(value);
                  setSelectedManyNotifications([]);
                }}
              />
            </div>
          )}
        </Col>
      </Row>
      {loggedInUser.role !== 'administrator' &&
        loggedInUser.role !== 'manager' &&
        selectedManyNotifications.length > 0 && (
          <DynamicButton
            danger
            style={{ marginBottom: 24 }}
            type='dashed'
            onClick={handleUserDelete(selectedManyNotifications)}
          >
            {i18next.t('DeleteNotification')}
          </DynamicButton>
        )}
      {/* {notifications.length === 0 && <div>Không có thông báo.</div>} */}
      <DynamicTable
        loading={isLoading}
        expandable={{
          columnWidth: '1%',
          expandedRowKeys,
          expandedRowRender: (record) => (
            <div>
              <ReactQuill theme={null} value={record.body} readOnly />
              {(loggedInUser.role === 'administrator' || loggedInUser.role === 'manager') && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginBlock: 12 }}>
                    <Space>
                      {/* Edit thông báo */}
                      {/* <Tooltip title='Sửa' placement='bottom'>
                      <Button
                        key='update-notification'
                        shape='circle'
                        type='dashed'
                        icon={<EditOutlined />}
                        onClick={handleUpdate(record)}
                      />
                    </Tooltip> */}
                      <Popconfirm
                        // title={
                        //   settings.IS_LG
                        //     ? 'Are you sure to delete this notification Nguyenne?'
                        //     : 'Bạn có muốn xóa thông báo này không?'
                        // }
                        title={i18next.t('Are you sure to delete', { object: i18next.t('notification') })}
                        onConfirm={handleAdminDelete([record.id])}
                        okText={i18next.t('Confirm')}
                        cancelText={i18next.t('Close')}
                      >
                        <Tooltip title={i18next.t('Delete')} placement='bottom'>
                          <Button
                            key='delete-notification'
                            shape='circle'
                            type='dashed'
                            icon={<DeleteOutlined />}
                            danger
                          />
                        </Tooltip>
                      </Popconfirm>
                    </Space>
                  </div>
                </>
              )}
              <TeacherContainer>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBlock: 12 }}>
                  <Space>
                    <Popconfirm
                      title={i18next.t('Are you sure to delete', { object: i18next.t('notification') })}
                      onConfirm={handleUserDelete([record.id])}
                      okText={i18next.t('Confirm')}
                      cancelText={i18next.t('Close')}
                    >
                      <Tooltip title={i18next.t('Delete')} placement='bottom'>
                        <Button
                          key='delete-notification'
                          shape='circle'
                          type='dashed'
                          icon={<DeleteOutlined />}
                          danger
                        />
                      </Tooltip>
                    </Popconfirm>
                  </Space>
                </div>
              </TeacherContainer>
              <StudentContainer>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBlock: 12 }}>
                  <Space>
                    <Popconfirm
                      title={i18next.t('Are you sure to delete', { object: i18next.t('notification') })}
                      onConfirm={handleUserDelete([record.id])}
                      okText={i18next.t('Confirm')}
                      cancelText={i18next.t('Close')}
                    >
                      <Tooltip title={i18next.t('Delete')} placement='bottom'>
                        <Button
                          key='delete-notification'
                          shape='circle'
                          type='dashed'
                          icon={<DeleteOutlined />}
                          danger
                        />
                      </Tooltip>
                    </Popconfirm>
                  </Space>
                </div>
              </StudentContainer>
            </div>
          ),
          expandIcon: ({ expanded, onExpand, record }) => renderIcon({ expanded, onExpand, record }),
          expandIconColumnIndex: 999,
          expandRowByClick: true,
          onExpand: (expanded, record) => {
            const keys = [];
            if (expanded) {
              if (loggedInUser.role !== 'administrator' && record.status) {
                // Quy ước: True - 1 - Chưa xem, False - 0 - Đã xem.
                query('p_VIMARU_Notification_ChangeStatus_ByUser', {
                  notificationId: record.id,
                  userId: loggedInUser.id,
                }).then(() => {
                  queryClient.invalidateQueries(key);
                });
              }
              keys.push(record.id);
            }
            setExpandedRowKeys(keys);
          },
        }}
        dataSource={displayArr}
        initialTableData={
          loggedInUser.role === 'administrator' || loggedInUser.role === 'manager' ? adminTableData : tableData
        }
        pagination={{ defaultPageSize: 10 }}
        rowSelection={{
          ...rowSelection,
        }}
      />
      <UpdateNotification
        data={selectedNotification}
        classId={classId}
        visible={updateModalVisible}
        onOk={() => {
          queryClient.invalidateQueries(key);
          setUpdateModalVisible(false);
        }}
        onCancel={() => {
          setUpdateModalVisible(false);
        }}
      />
    </Card>
  );
};

Notifications.propTypes = {
  classId: PropTypes.any,
  loggedInUser: PropTypes.object,
};

Notifications.defaultProps = {
  classId: null,
  loggedInUser: null,
};

export default withAuthenticator(Notifications);
