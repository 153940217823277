import { InfoCircleOutlined, PlusOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, List, Modal, Space, Tooltip, Upload, message } from 'antd';
import UploadFiles from 'components/UploadFiles';
import colors from 'constants/colors';
import { sachsoServerUrl, websiteUrl } from 'constants/serverUrls';
import settings from 'constants/settings';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Qrcode from 'qrcode';
import { query } from 'helpers/QueryHelper';
import { useQuery, useQueryClient } from 'react-query';
import ImageNote from './Image';
import i18next from 'i18next';
const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
type Props = {
  workItemId?: string | undefined;
  showQrCode?: boolean;
  preview?: boolean;
};
const UploadNote = ({ workItemId, showQrCode, preview }: Props) => {
  const loggedInUser = useSelector((state: any) => state.authReducer.loggedInUser);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const { data } = useQuery(['t_QUIZCITY_Student_QuizNote_WorkItems', refresh, workItemId], () =>
    query('p_QuizCity_NoteLog_Get', { workItemId }),
  );

  const queryClient = useQueryClient();

  const handlePreview = (item: any) => {
    setPreviewImage(item.imageUrl);
    setPreviewOpen(true);
    setPreviewTitle(item.fileName);
  };
  const handleDelete = (item: any) => {
    query('p_QuizCity_NoteLog_Delete', {
      id: item.id,
    })
      .then(() => {
        message.success(i18next.t('upload:UploadNote.Delete {{file}} successfully!'), { file: item.fileName });
        queryClient.invalidateQueries('t_QUIZCITY_Student_QuizNote_WorkItems');
      })
      .catch(() => message.error(i18next.t('upload:UploadNote.This function is updating!')));
  };

  const handleCancel = () => setPreviewOpen(false);

  return (
    <>
      <UploadFiles
        buttonText={i18next.t('upload:UploadNote.Upload photos')}
        maxCount={preview ? 0 : 5}
        entityId={preview ? '0000' : workItemId!}
        entityName='QuizNote_WorkItems'
        subModuleName='Student'
        moduleName='QUIZCITY'
        supportTypeList={['image/png', 'image/jpeg']}
        onSuccess={(info) => {
          const params = {
            fileName: info.file.name,
            imageUrl: info.file.response?.files?.[0]?.downloadUrl,
            score: 0,
            notes: '',
            appName: settings.APPLICATION_NAME,
            workItemId,
            attachmentId: info.file.response?.files?.[0]?.id,
          };
          query('p_QuizCity_NoteLog_Insert_ForQRUpload', params).then(() =>
            queryClient.invalidateQueries('t_QUIZCITY_Student_QuizNote_WorkItems'),
          );
        }}
        loggedInUser={loggedInUser}
        showQrCode={showQrCode}
        preview={preview}
      />
      <Tooltip
        title={`${i18next.t('upload:UploadNote.Load pictures from')} ${
          showQrCode ? 'upload QR' : i18next.t('upload:UploadNote.assignment')
        } `}
      >
        <Button
          disabled={preview}
          style={{ marginBlock: 20 }}
          icon={<ReloadOutlined />}
          onClick={() => setRefresh((prev) => !prev)}
        >
          Refresh
        </Button>
      </Tooltip>
      <Modal destroyOnClose open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
      {/* {qrcodeImageUrl && (
        <React.Fragment>
          <Modal
            centered
            title='Bật camera trên điện thoại để quét mã QRCODE'
            visible={modalVisible}
            footer={null}
            onCancel={() => {
              setModalVisible(false);
            }}
          >
            <div>
              <a
                href={`${window.location.host}/upload/qrcode/SACHSO/RESOURCES/Slides/${slideId}`}
                target='_blank'
                rel='noreferrer'
              >
                <img src={qrcodeImageUrl} alt='QRCODE' style={{ width: '100%' }} />
              </a>
            </div>
          </Modal>
          <div style={{ marginBlock: 12 }}>
            <Space direction='vertical'>
              <Space>
                <InfoCircleOutlined />
                <em>Bạn có thể dùng điện thoại</em>

                <Button
                  type='dashed'
                  onClick={() => {
                    setModalVisible(true);
                  }}
                >
                  <span style={{ color: colors.primary, fontWeight: 600 }}>QUÉT MÃ QRCODE</span>
                </Button>
                <em>để tải lên các tập tin từ điện thoại.</em>
              </Space>
            </Space>
          </div>
        </React.Fragment>
      )} */}
      <List
        grid={{ gutter: 16 }}
        dataSource={data}
        renderItem={(item: any) => (
          <List.Item>
            <Card
              style={{
                border: '1px dashed #d9d9d9',
                borderRadius: '2px',
                width: 200,
                height: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 10,
              }}
              hoverable
              cover={
                <ImageNote
                  onPreview={() => handlePreview(item)}
                  onDelete={() => handleDelete(item)}
                  src={item?.imageUrl}
                  style={{ width: 180, height: 180, objectFit: 'contain' }}
                />
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default UploadNote;
