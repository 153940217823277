import { Checkbox } from 'antd';
import changeStatusNotificationManagement from './api/changeStatusNotificationManagement';
import { useQueryClient } from 'react-query';
import settings from 'constants/settings';
import i18next from 'i18next';

export default {
  displayColumns: [
    {
      key: 'notificationName',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        // minWidth: 72,
      },
      title: i18next.t('Title'),
      width: '5%',
    },
    {
      key: 'notificateCode',
      render: 'renderNoWrap',
      title: i18next.t('Code'),
      width: '5%',
    },
    {
      key: 'content',
      render: (text, record) => <div dangerouslySetInnerHTML={{ __html: text }} />,
      title: i18next.t('Content'),
      width: '30%',
    },
    {
      key: 'notes',
      render: 'renderText',
      title: i18next.t('Notes'),
      width: '10%',
    },
    {
      key: 'status',
      render: (status, record, index) => {
        const { id } = record;
        const queryClient = useQueryClient();
        const onChange = (e) => {
          // console.log(`checked = ${e.target.checked}`);
          changeStatusNotificationManagement(id).then(res => queryClient.invalidateQueries('t_QUIZCITY_Notification_Management'));
        };
        return (
          <Checkbox defaultChecked={status} checked={status} onChange={onChange}>
            {i18next.t('Active')}
          </Checkbox>
        );
      },
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('Status'),
      width: '10%',
    },
  ],
  rowKey: 'id',
};
