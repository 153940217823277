import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, List, Spin, notification } from 'antd';
import { get, remove } from 'helpers/QueryHelper';
import i18next from 'i18next';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

type LectureListProps = {
  countingTime?: boolean; // Không đếm thời gian với trường hợp nhấn vào list menu.
  width?: number | string;
  presentationId: string;
  getSlideId?: Dispatch<SetStateAction<string>>;
  getSlideIndex?: Dispatch<SetStateAction<number>>;
};

const fetch = (presentationId: string) => {
  return get('Slides', '*', { presentationId }, 'SortOrder', '', 'SACHSO');
};

const LectureList = ({ presentationId, countingTime, width, getSlideId, getSlideIndex }: LectureListProps) => {
  const [slides, setSlides] = React.useState([]);
  const KEY_LECTURE_LIST = ['GET_NEW_LECTURE_LIST', presentationId];
  const { data, isLoading } = useQuery(KEY_LECTURE_LIST, () => fetch(presentationId));
  const queryClient = useQueryClient();

  const handleClick = (slideId: string, slideIndex: number) => {
    if (getSlideId && typeof getSlideId === 'function') {
      getSlideId(slideId);
    }
    if (getSlideIndex && typeof getSlideIndex === 'function') {
      getSlideIndex(slideIndex);
    }
  };

  const handleEdit = (index: number) => {};

  const handleDelete = (entityId: string) => {
    remove('Slides', entityId)
      .then((response) => {
        queryClient.invalidateQueries();
        notification.success({
          message: i18next.t('notification'),
          description: i18next.t('delete success', { field: i18next.t('content') }),
        });
      })
      .catch((error) => {
        // console.log('🚀 ~ file: LectureList.tsx ~ line 47 ~ handleDelete ~ error ~ Không thể xoá nội dung!', error);
        notification.success({
          message: i18next.t('notification'),
          description: i18next.t('delete failed', { field: i18next.t('content') }),
        });
      });
  };

  useEffect(() => {
    setSlides(data);
  }, [data]);

  const actions = (item: any, index: number) => {
    switch (localStorage.getItem('mode')) {
      case 'EDIT':
      case 'CREATE':
        return [
          // <EditOutlined key={1} onClick={() => handleEdit(index)} />,
          <Button
            key='delete-slide'
            shape='circle'
            type='dashed'
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDelete(item.id)}
          />,
        ];

      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin></Spin>
      </div>
    );
  }

  return (
    <div>
      <List
        bordered
        dataSource={slides}
        renderItem={(item, index) => {
          return (
            <List.Item
              actions={actions(item, index)}
              key={index}
              onClick={() => handleClick(item.id, index)}
              style={{ cursor: 'pointer' }}
            >
              {`${item.type} - ${item.title}`}
            </List.Item>
          );
        }}
        style={{ width }}
      />
    </div>
  );
};

LectureList.defaultProps = {
  countingTime: false,
  width: '100%',
  getSlideId: undefined,
  getSlideIndex: 0,
};

export default LectureList;
