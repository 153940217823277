import { studentColumns } from 'constants/displayColumns';

export default {
  displayColumns: [
    ...studentColumns,
    {
      title: 'Điểm thi Offline',
      key: 'student-info',
      width: '1%',
      children: [
        {
          title: (
            <span>
              ĐTGK (X<sub>2</sub>)
            </span>
          ),
          key: 'scoreOfflineGDI',
          width: 130,
          render: 'renderNumber',
          style: {
            fontWeight: 600,
          },
          formatString: '0.00',
        },
        {
          title: 'ĐTCK (Y)',
          key: 'scoreOfflineGDII',
          width: 130,
          render: 'renderNumber',
          style: {
            fontWeight: 600,
          },
          formatString: '0.00',
        },
        {
          title: 'Ghi chú',
          key: 'notes',
          width: '10%',
          // render: 'renderNoWrap',
          style: {
            fontWeight: 600,
            textAlign: 'left',
          },
          render: (text) => {
            if (text) {
              return <a>{text?.length > 9 ? text?.slice(0, 9) + '...' : text}</a>;
            } else {
              return <a>{''}</a>;
            }
          },
        },
      ],
    },
  ],
  rowKey: 'id',
};
