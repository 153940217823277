import { DictionaryText } from 'components/Dictionary';
import { QuestionProps } from '../../type';

type Props = {
  question: QuestionProps;
};
const QuestionBlockQuote = ({ question }: Props) => {
  return (
    <blockquote className='mb-0 tw-text-center tw-break-normal'>
      <p style={{ fontSize: 20, fontWeight: '600', maxWidth: '50vw', marginTop: '10px' }}>Sample (Câu trả lời mẫu):</p>
      <p style={{ fontSize: 20, fontWeight: '600', maxWidth: '50vw', marginTop: '10px' }}>{question?.questionText}</p>
      <p style={{ fontSize: 20, fontWeight: '600', maxWidth: '50vw', marginTop: '5px' }}>
        {question?.answers?.[0]?.text}
      </p>
      {question?.answers?.[0].phonetic && (
        <>
          <p style={{ fontSize: 16, fontWeight: '400', fontStyle: 'italic' }}>/{question?.answers?.[0]?.phonetic}/</p>
          <footer className='blockquote-footer'>
            <span style={{ fontSize: '16px' }}>Nhấp vào để tra cứu nghĩa từng từ </span>
          </footer>
        </>
      )}

      <hr />
      <p style={{ fontSize: 20, fontWeight: '600', maxWidth: '50vw', marginTop: '10px' }}>
        {question?.answers?.[0]?.questionTextVN}
      </p>
      <p style={{ fontSize: 20, fontWeight: '600', maxWidth: '50vw', marginTop: '5px' }}>
        {question?.answers?.[0]?.textVN}
      </p>
    </blockquote>
  );
};

export default QuestionBlockQuote;
