import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/types';

function* submit(action) {
  try {
    yield put({
      type: actionTypes.OLYMPIC_SUBMIT_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: actionTypes.OLYMPIC_SUBMIT_FAILURE,
      payload: error,
    });
  }
}

function* submitAll(action) {
  try {
    yield put({
      type: actionTypes.OLYMPIC_SUBMIT_ALL_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: actionTypes.OLYMPIC_SUBMIT_ALL_FAILURE,
      payload: error,
    });
  }
}

export default function* sagas() {
  yield takeLatest(actionTypes.OLYMPIC_SUBMIT, submit);
  yield takeLatest(actionTypes.OLYMPIC_SUBMIT_ALL, submitAll);
}
