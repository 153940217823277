/* eslint-disable react/jsx-props-no-spreading */
import { RootState } from 'modules/store';
import { useSelector } from 'react-redux';
import { LoggedInUserType } from 'types';

type AuthenticatorProps = {
  loggedInUser: LoggedInUserType;
};

export default function withAuthenticator<P>(WrappedComponent: React.ComponentType<P & AuthenticatorProps>) {
  const ComponentWithAuthenticator = (props: P) => {
    const loggedInUser: LoggedInUserType = useSelector((state: RootState) => state.authReducer.loggedInUser);

    return <WrappedComponent loggedInUser={loggedInUser} {...props} />;
  };

  return ComponentWithAuthenticator;
}
