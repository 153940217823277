import Axios from 'axios';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import { get, remove } from './config';

const instance = Axios.create({
  baseURL: 'https://server.sachso.edu.vn/api/v1.0/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
    ApplicationName: settings.APPLICATION_NAME,
  },
});

export async function updateTags(id, tags) {
  if (!id) return;
  const parameters = {
    sqlCommand: 'p_SACHSO_SHARE_Attachments_UpdateTags',
    parameters: {
      id,
      tags,
    },
  };

  await instance.post('/dynamic/execute', parameters);
}

export const pdfToJPG = async (
  file,
  entityId,
  subModuleName = 'CLASSWORK',
  entityName = 'Assignments',
  attachmentId,
) => {
  const DATA = new FormData();
  DATA.append('files', file);
  DATA.append('moduleName', 'SACHSO');
  DATA.append('subModuleName', subModuleName);
  DATA.append('entityName', entityName);
  DATA.append('entityId', entityId);
  DATA.append('tags', 'QUIZCITY-WORKSHEET');
  if (attachmentId) {
    //Nếu không truyền id thì server sẽ tự tạo mới worksheet vào bảng attackments
    DATA.append('id', attachmentId);
  }
  let response = {};

  if (file?.type?.split('/')[1] === 'pdf') {
    response = await instance?.post('pdf/upload-files', DATA);
  } else {
    response = await instance?.post('dynamic/upload-files', DATA);
  }
  const { id, downloadUrl, ...props} = response?.data?.files?.[0];

  return {...props, id, imgURL: downloadUrl  };
};

export const fileToURL = async (file, entityId) => {
  const DATA = new FormData();
  DATA.append('files', file);
  DATA.append('moduleName', 'SACHSO');
  DATA.append('subModuleName', 'CLASSWORK');
  DATA.append('entityName', 'Assignments');
  DATA.append('entityId', entityId);

  // entityName: t_SACHSO_LIVEWORKSHEET_Assignments
  // entityId: 358d8538-628d-471e-8ed5-5c337f4aa8cb
  let rs = null;
  try {
    if (file.type !== 'audio/mpeg') {
      rs = 0; // FIle không đúng định dạng
    } else {
      const response = await instance.post('dynamic/upload-files', DATA);
      if (response.data) {
        const { id, downloadUrl, fileName } = response.data.files[0];
        rs = { id, audioURL: downloadUrl, fileName };
      } else {
        rs = false;
      }
    }
  } catch (error) {
    return false;
  }
  return rs;
};

export const fetcher = async (entityId, id) => {
  const fields = '*';
  const parameters = {
    entityId,
    entityName: 't_SACHSO_CLASSWORK_Assignments',
    id,
  };
  const sort = 'CreatedDate';

  const response = await get('Attachments', fields, parameters, sort, 'SHARE', 'SACHSO');
  return response;
};

export const updateJsonData = async (Id, JsonData, TotalQuestion, createdBy) => {
  if (!JsonData || JsonData === '{}') {
    return message.error('Bạn đang insert dữ liệu rỗng ! Vui lòng thử lại hoặc liên hệ cho đội IT');
  } else {
    const DATA = {
      sqlCommand: '[dbo].[p_SACHSO_SHARE_Attachments_UpdateJsonData]',
      parameters: {
        Id,
        JsonData,
        TotalQuestion,
        createdBy,
      },
    };
    query('p_SACHSO_SHARE_Attachments_UpdateModule', {
      Id: Id,
      // ModuleId: settings.MODULE_ID,
    });
    const response = await instance.post('dynamic/execute', DATA);
    return response;
  }
};

export const removeFile = async (id) => {
  const response = await remove('Attachments', id, 'SHARE');
  return response;
};

export const updateWorksheetData = async (parameters) => {
  // @Id uniqueidentifier,
  // @FolderId uniqueidentifier  = NULL,
  // @FileName NVARCHAR(255) = NULL,
  // @FileUrl NVARCHAR(500) = NULL,
  // @FileSize INT = NULL,
  // @ContentType NVARCHAR(100) = NULL,
  // @JsonData NVARCHAR(MAX) = NULL,
  // @TotalQuestion INT = NULL,
  // @Tags NVARCHAR(100) = NULL,
  // @ModuleId uniqueidentifier  = NULL,
  // @ModifiedBy INT
  const response = await query('p_QUIZCITY_Attachments_Update', parameters);
  return response;
};

export default { pdfToJPG, fetcher, updateJsonData };
