import { CopyOutlined } from '@ant-design/icons';
import { Button, Row, Tooltip, notification } from 'antd';
import colors from 'constants/colors';
import i18next from 'i18next';
import _ from 'lodash';
import { removeAccents } from 'services/Text/formatText';

const coppyCode = (text) => {
  window.navigator.clipboard.writeText(text);
  notification.success({ placement: 'top', message: 'Đã sao chép', duration: 3 });
};

export const shopColumns = {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'Mã',
      key: 'code',
      width: '10%',
      render: (text, record) => (
        <Row justify={'space-between'} wrap={false}>
          <p style={{ marginBottom: 0, fontWeight: 600 }}>{text}</p>
          <Tooltip title='Nhấp chuột để sao chép mã'>
            <Button type='dashed' size='small' shape='circle' icon={<CopyOutlined />} onClick={() => coppyCode(text)} />
          </Tooltip>
        </Row>
      ),
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Tên',
      key: 'categoryName',
      width: '20%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Địa chỉ',
      key: 'address',
      width: '40%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
  ],
};

// Code mới dùng đệ quy - không giới hạn các cấp thư mục, thư mục cấp 1 có parentId = '00000000-0000-0000-0000-000000000000',
export function buildTreeDataShops(records, parentId = '00000000-0000-0000-0000-000000000000', level = 1) {
  if (records && records.length > 0) {
    const nodes = [];
    const filteredRecords = records.filter((record) => record.parentId === parentId);
    for (const record of _.sortBy(filteredRecords, ['categoryName'])) {
      const children = buildTreeDataShops(records, record.id, level + 1);
      const node = {
        key: record.id,
        title: record.categoryName,
        children,
        level: level,
      };
      nodes.push(node);
    }
    return nodes;
  }
  return [];
}
// Code mới dùng đệ quy - không giới hạn các cấp thư mục, thư mục cấp 1 có parentId = '00000000-0000-0000-0000-000000000000',
export function buildTreeSelectShops(records, parentId = '00000000-0000-0000-0000-000000000000', level = 1) {
  if (records && records.length > 0) {
    const nodes = [];
    const filteredRecords = records.filter((record) => record.parentId === parentId);
    for (const record of _.sortBy(filteredRecords, ['categoryName'])) {
      const children = buildTreeSelectShops(records, record.id, level + 1);
      const node = {
        value: record.id,
        title: record.categoryName,
        name: record.categoryName,
        children,
        level: level,
      };
      nodes.push(node);
    }
    return nodes;
  }
  return [];
}

// Thêm option "Tất cả" cho SelectTree ở các vị trí cần filter
export const addOptionAllShops = (options) => [
  {
    title: i18next.t('All'),
    value: '00000000-0000-0000-0000-000000000000',
    name: i18next.t('All'),
    children: options,
    type: 'all',
  },
];

// validate ở rules (Form.Item) chỉ cho phép chọn bộ phận là cấp cuối cùng
export const validateSelectShop = (value, allShops) => {
  if (allShops?.some((i) => i?.parentId === value)) return Promise.reject('Vui lòng chọn bộ phận là cấp cuối cùng');
  else return Promise.resolve();
};

export const filterTreeSelectShops = (inputValue, treeNode) =>
  removeAccents(treeNode?.title ?? '')?.includes(removeAccents(inputValue ?? ''));

// khi filter, nếu chọn "Khối" thì hiển thị luôn cả cấp con, chọn cấp con thì chỉ hiện cấp con đó
//id: là id của "Khối" hoặc "Bộ phận", allData là mảng phẳng lấy từ API
export const getShopIdForFilterData = (id = '', allData = []) => {
  const listId = [id];
  let cloneAllData = _.cloneDeep(allData ?? []).filter((shop) => shop.id !== id);
  let index = 1; // index để tránh vòng lặp vô hạn nếu có lỗi code
  // Lặp vô hạn, đến khi nào lấy hết các id của cấp con (cloneAllData không chứa parentId trong listId thì dừng)
  while (cloneAllData.some((shop) => listId.includes(shop.parentId)) && index < 50) {
    const newArr = [];
    // Duyệt qua mảng cloneAllData , xem có shop nào có parentId thuộc listId thì lấy
    // những phần tử k lấy thì lưu lại cho lần duyệt tiếp theo
    cloneAllData.forEach((shop) => {
      if (listId.some((id) => id === shop.parentId)) {
        listId.push(shop.id);
      } else newArr.push(shop);
    });
    // Loại bỏ các id đã lấy để bắt đầu vòng lặp mới cloneAllData để
    cloneAllData = newArr;
    index = index + 1;
  }
  return listId;
};
// Lấy hết các id con của mảng id truyền vào để fillter phía FE
export const getShopIdsFromIds = (arrayId=[], allData = []) => {
  let listId = [];
  arrayId.forEach(id => {
    const ids = getShopIdForFilterData(id, allData);
    listId = listId.concat(ids);
  });
  return listId;
};
