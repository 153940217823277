const BASE_ICON = {
  LISTEN: require('assets/icon/Icon_Listen.svg'),
  LOOK: require('assets/icon/Icon_Look.svg'),
  // MATCH: require('assets/icon/match_32x32.svg'),
  // NEWWORD: require('assets/icon/newword.svg'),
  SPEAK: require('assets/icon/Icon_Speak.svg'),
  WRITE: require('assets/icon/Icon_Write.svg'),
  READ: require('assets/icon/Icon_Read.svg'),
};

function AssignmentIcons(questionSkills) {
  const skills = [];
  questionSkills?.forEach((element) => {
    switch (element) {
      case 'L':
        skills.push(BASE_ICON.LISTEN);
        break;
      case 'S':
        skills.push(BASE_ICON.SPEAK);
        break;
      case 'R':
        skills.push(BASE_ICON.READ);
        break;
      case 'W':
        skills.push(BASE_ICON.WRITE);
        break;
      default:
        break;
    }
  });
  return skills;
  // switch (questionType) {
  //   case 'ListenAndRepeat':
  //     return [BASE_ICON.LISTEN, BASE_ICON.SPEAK];

  //   case 'OneCorrectQuestionText':
  //     return [BASE_ICON.LISTEN, BASE_ICON.MATCH];

  //   case 'OneTextMultiOptionOneCorrect':
  //     return [BASE_ICON.LOOK, BASE_ICON.MATCH];

  //   case 'OneCorrectQuestionImage':
  //     return [BASE_ICON.LOOK, BASE_ICON.MATCH];

  //   case 'Video':
  //     return [BASE_ICON.LOOK, BASE_ICON.MATCH];

  //   case 'RepeatTheWords':
  //     return [BASE_ICON.LOOK, BASE_ICON.LISTEN, BASE_ICON.SPEAK];

  //   case 'RepeatTheSentence':
  //     return [BASE_ICON.LISTEN, BASE_ICON.LOOK, BASE_ICON.SPEAK];
  //   case 'ScrambleWord':
  //     return [BASE_ICON.LOOK, BASE_ICON.MATCH];
  //   case 'MakeASentence':
  //     return [BASE_ICON.LOOK, BASE_ICON.MATCH];
  //   case 'MatchingWordWithPicture':
  //     return [BASE_ICON.LOOK, BASE_ICON.MATCH];
  //   case 'CompleteWord':
  //     return [BASE_ICON.LOOK, BASE_ICON.MATCH];

  //   case 'Grammar':
  //     return [BASE_ICON.LOOK, BASE_ICON.MATCH];
  //     // case 'WorkSheet':
  //     //   return [BASE_ICON.LISTEN, BASE_ICON.SPEAK, BASE_ICON.WRITE];

  //   default:
  //     return null;
  // }
}

export default AssignmentIcons;
