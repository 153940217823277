import { Button, Col, Descriptions, Form, InputNumber, Modal, Row, Space, message, notification } from 'antd';
import React, { useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useQueryClient } from 'react-query';
import { enterOffLinePTScore } from '../../api';
import { useSelector } from 'react-redux';

type Props = {
  record: any;
};

const EnterOfflineScore = ({ record }: Props) => {
  const [form] = useForm();
  const queryClient = useQueryClient();
  const loggedInUser = useSelector((rootState) => rootState.authReducer.loggedInUser);

  const [visible, setVisible] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const textArray = record.user?.split('/') ?? [];

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        listeningScore: record.listenScoreOffline,
        speakingScore: record.speakScoreOffline,
        readingScore: record.readScoreOffline,
        writingScore: record.writeScoreOffline,
      });
    }
  }, [visible]);

  const onFinish = (values: any) => {
    if (!isProcessing) {
      setIsProcessing(true);
      try {
        const { listeningScore, speakingScore, readingScore, writingScore } = values;
        enterOffLinePTScore({
          PTCode: record.code,
          ListenScoreOffline: listeningScore ?? 0,
          SpeakScoreOffline: speakingScore ?? 0,
          ReadScoreOffline: readingScore ?? 0,
          WriteScoreOffline: writingScore ?? 0,
          UpdateBy: loggedInUser?.id,
        })
          .then((res) => {
            queryClient.invalidateQueries('p_QUIZCITY_PTTest_CodeExam_Dashboard');
            message.success('Lưu dữ liệu thành công!');
            setVisible(false);
          })
          .catch((err) => message.error(err?.message ?? 'Lưu dữ liệu thất bại!'));
      } catch (error) {
        setIsProcessing(false);
        message.error('Có lỗi xảy ra!');
      }
    }
  };

  return (
    <React.Fragment>
      <Button type='text' style={{ color: '#006EE6' }} onClick={() => setVisible(true)}>
        Nhập điểm offline
      </Button>
      <Modal
        centered
        open={visible}
        title='NHẬP ĐIỂM OFFLINE'
        onCancel={() => setVisible(false)}
        destroyOnClose
        cancelText='Đóng'
        okText='Lưu'
        onOk={() => form.submit()}
        okButtonProps={{ loading: isProcessing }}
      >
        <Form form={form} onFinish={onFinish} layout='vertical'>
          <Descriptions
            bordered
            size='small'
            labelStyle={{ color: 'black', fontWeight: 600, minWidth: 130 }}
            column={1}
          >
            <Descriptions.Item label='Mã tham gia'>{record.code}</Descriptions.Item>
            <Descriptions.Item label='Đề thi'>{record?.examName}</Descriptions.Item>
            <Descriptions.Item label='Người dùng'>
              <div style={{ fontWeight: '600' }}>
                <div>{textArray?.[3] ?? ''}</div>
                <div>{textArray?.[0] ?? ''}</div>
                <div>{textArray?.[1] ?? ''}</div>
                <div>{textArray?.[2] ?? ''}</div>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label={'Điểm'}>
              <Row gutter={[12, 0]}>
                <Col xs={24} md={12}>
                  <Form.Item label='Nghe' name={'listeningScore'}>
                    <InputNumber
                      style={{
                        width: '100%',
                      }}
                      type='number'
                      min={0}
                      max={100}
                      step={0.01}
                      precision={2} // Số lượng chữ số thập phân
                      formatter={(value: number | string | undefined) => {
                        // Hiển thị giá trị với cả dấu chấm và dấu phẩy
                        return value ? `${value}` : '';
                      }}
                      parser={(value: string | undefined) => {
                        // Chuyển đổi giá trị từ định dạng có dấu chấm và dấu phẩy về dạng số
                        return value ? parseFloat(value) : 0;
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label='Nói' name={'speakingScore'}>
                    <InputNumber
                      style={{
                        width: '100%',
                      }}
                      type='number'
                      min={0}
                      max={100}
                      step={0.01}
                      precision={2} // Số lượng chữ số thập phân
                      formatter={(value: number | string | undefined) => {
                        // Hiển thị giá trị với cả dấu chấm và dấu phẩy
                        return value ? `${value}` : '';
                      }}
                      parser={(value: string | undefined) => {
                        // Chuyển đổi giá trị từ định dạng có dấu chấm và dấu phẩy về dạng số
                        return value ? parseFloat(value) : 0;
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label='Đọc' name={'readingScore'}>
                    <InputNumber
                      style={{
                        width: '100%',
                      }}
                      type='number'
                      min={0}
                      max={100}
                      step={0.01}
                      precision={2} // Số lượng chữ số thập phân
                      formatter={(value: number | string | undefined) => {
                        // Hiển thị giá trị với cả dấu chấm và dấu phẩy
                        return value ? `${value}` : '';
                      }}
                      parser={(value: string | undefined) => {
                        // Chuyển đổi giá trị từ định dạng có dấu chấm và dấu phẩy về dạng số
                        return value ? parseFloat(value) : 0;
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item label='Viết' name={'writingScore'}>
                    <InputNumber
                      style={{
                        width: '100%',
                      }}
                      type='number'
                      min={0}
                      max={100}
                      step={0.01}
                      precision={2} // Số lượng chữ số thập phân
                      formatter={(value: number | string | undefined) => {
                        // Hiển thị giá trị với cả dấu chấm và dấu phẩy
                        return value ? `${value}` : '';
                      }}
                      parser={(value: string | undefined) => {
                        // Chuyển đổi giá trị từ định dạng có dấu chấm và dấu phẩy về dạng số
                        return value ? parseFloat(value) : 0;
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Descriptions.Item>
          </Descriptions>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default EnterOfflineScore;
