import withAuthenticator from 'HOCs/withAuthenticator';
import AdministratorContainer from 'components/Containers/AdministratorContainer';
import StudentContainer from 'components/Containers/StudentContainer';
import TeacherContainer from 'components/Containers/TeacherContainer';
import colors from 'constants/colors';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import CountUp from 'react-countup';
import { useQuery } from 'react-query';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import './styles.css';

type Props = {
  name: string;
  parentName: string;
  selectedFolder: any;
  loggedInUser?: any;
};

type StatisticalTeacherProps = {
  completedPercentOfStudents: number;
  percentCompletedHomeWork: number;
  percentTotalClass: number;
  percentTotalStudent: number;
  totalClass: number;
  totalStudent: number;
};

type StatisticalStudentProps = {
  aVGScore: number;
  completedPercent: number;
  countExercisesWorked: number;
  countTotal: number;
  percentCompleted: number;
  percentCountTotal: number;
  percentExercisesWorked: number;
  percentScore: number;
  progress_GiuaKy: number;
  progress_CuoiKy: number;
  qualityCompletedPercent: number;
  qualificationCompletedPercent_GiuaKy: number;
  qualificationCompletedPercent_CuoiKy: number;
};
type StatisticalAdminProps = {
  student: number;
  studentInClass: number;
  teacher: number;
  totalClass: number;
  totalPresentation: number;
  totalSlide: number;
  totalUser: number;
  userActive: number;
  userDeactive: number;
};
const fetchTeacher = (teacherId: any) => {
  return query('p_VIMARU_GetThongKeClass_ByTeacher', { teacherId });
};
const fetchStudent = (studentId: any, folderId: any, studentRole: any) => {
  if (studentRole === 'student' && folderId && folderId !== '00000000-0000-0000-0000-000000000000')
    return query('p_VIMARU_GetThongKe_BaiTap', { studentId, folderId });
};
const fetchAdmin = () => {
  return query('p_VIMARU_ThongKeForAdmin_SUMARY');
};

function Header({ loggedInUser, name, parentName, selectedFolder }: Props) {
  const KEY_TEACHER = ['p_VIMARU_GetThongKeClass_ByTeacher', loggedInUser?.id];
  const KEY_STUDENT = ['p_VIMARU_GetThongKe_BaiTap', loggedInUser?.id, selectedFolder?.id];
  const KEY_ADMIN = ['p_VIMARU_ThongKeForAdmin_SUMARY', loggedInUser?.id];
  const { data: statisticalTeacher } = useQuery(KEY_TEACHER, () => fetchTeacher(loggedInUser?.id), {
    enabled: loggedInUser?.role === 'teacher',
    retry: () => false,
  });
  const { data: statisticalStudent } = useQuery(
    KEY_STUDENT,
    () => fetchStudent(loggedInUser?.id, selectedFolder?.id, loggedInUser.role),
    {
      enabled: loggedInUser?.role === 'student' && !!selectedFolder?.id,
      retry: () => false,
    },
  );
  const { data: statisticalAdmin } = useQuery(KEY_ADMIN, () => fetchAdmin(), {
    enabled: loggedInUser?.role === 'administrator',
    retry: () => false,
  });
  // const isNotCompletedGK = statisticalStudent?.[0]?.progress_GiuaKy === 100;
  // const isNotCompletedCK = statisticalStudent?.[0]?.progress_CuoiKy === 100;
  // React.useEffect(() => {
  //   if (loggedInUser.role === 'teacher') {
  //     query('p_VIMARU_GetThongKeClass_ByTeacher', { teacherId: loggedInUser.id }).then((response) => {
  //       setStatisticalTeacher(response);
  //     });
  //   }
  //   if (loggedInUser.role === 'student') {
  //     if (selectedFolder?.id && selectedFolder?.id !== '00000000-0000-0000-0000-000000000000')
  //       query('p_VIMARU_GetThongKe_BaiTap', { studentId: loggedInUser.id, folderId: selectedFolder?.id }).then(
  //         (response) => {
  //           setStatisticalStudent(response);
  //         },
  //       );
  //   }
  //   if (loggedInUser.role === 'administrator' || loggedInUser.role === 'manager') {
  //     query('p_VIMARU_ThongKeForAdmin_SUMARY').then((response) => {
  //       setStatisticalAdmin(response?.[0]);
  //     });
  //   }
  //   return () => {
  //     setStatisticalTeacher([]);
  //     setStatisticalStudent([]);
  //   };
  // }, [loggedInUser, selectedFolder]);

  // Define Statistical section and its props type
  type StatisticalSectionProps = {
    value: unknown;
  };
  const StatisticalSection = ({ value }: StatisticalSectionProps) => {
    if (typeof value !== 'number') {
      value = 0;
    }
    const text: string = `text-${value ? (value > 0 ? 'success' : 'danger') : 'dark'} mr-2`;
    const icon: string = `fa fa-arrow-${value >= 0 ? 'up' : 'down'}`;
    return (
      <p className='mt-3 mb-0 text-sm'>
        <span className={text}>
          <i className={icon} />
          <span>{` ${value}%`}</span>
        </span>
        <span className='text-nowrap'>Tính từ đầu tháng</span>
      </p>
    );
  };

  return (
    <>
      <div
        className='header pb-6'
        style={{ backgroundColor: colors.theme.background, borderTop: `1px solid ${colors.theme.background}` }}
      >
        <Container fluid>
          <div className='header-body'>
            <Row className='align-items-center py-4'>
              <Col lg='6' xs='7'>
                <h6 className='h2 text-white d-inline-block mb-0'>{name}</h6>
                <Breadcrumb
                  className='d-none d-md-inline-block ml-md-4'
                  listClassName='breadcrumb-links breadcrumb-dark'
                >
                  <BreadcrumbItem>
                    <a href='#pablo' onClick={(e) => e.preventDefault()}>
                      <i className='fas fa-home' />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href='#pablo' onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  {/* <BreadcrumbItem aria-current='page' className='active'>
                    {name}
                  </BreadcrumbItem> */}
                </Breadcrumb>
              </Col>
              {/* <Col className='text-right' lg='6' xs='5'>
                <Button className='btn-neutral' color='default' href='#pablo' onClick={(e) => e.preventDefault()} size='sm'>
                  New
                </Button>
                <Button className='btn-neutral' color='default' href='#pablo' onClick={(e) => e.preventDefault()} size='sm'>
                  Filters
                </Button>
              </Col> */}
            </Row>

            <StudentContainer>
              <Row style={{ display: 'flex' }}>
                <Col style={{ flex: 1, maxWidth: '33%' }}>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            TỔNG LƯỢT LÀM BÀI
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-red text-white rounded-circle shadow'>
                            <i className='ni ni-active-40' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          // decimals={2}
                          style={{ color: '#F54A49' }}
                          start={0}
                          duration={5}
                          end={statisticalStudent?.[0]?.totalCount ?? 0}
                        ></CountUp>
                      </Row>
                      {/* <StatisticalSection value={statisticalStudent?.[0]?.percentCountTotal ?? 0} /> */}
                      {/* <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fa fa-arrow-up' />
                          <span>{` ${statisticalStudent?.[0]?.percentCountTotal ?? 0}%`}</span>
                        </span>
                        <span className='text-nowrap'>Tính từ đầu tháng</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col style={{ flex: 1, maxWidth: '33%' }}>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            TIẾN ĐỘ THEO TUẦN
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-orange text-white rounded-circle shadow'>
                            <i className='ni ni-chart-pie-35' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          decimals={2}
                          style={{ color: '#FB8C40' }}
                          start={0}
                          duration={5}
                          end={statisticalStudent?.[0]?.countCompletedPercent ?? 0}
                          suffix='%'
                        ></CountUp>
                      </Row>

                      {/* <StatisticalSection value={statisticalStudent?.[0]?.percentExercisesWorked ?? 0} /> */}
                      {/* <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fa fa-arrow-up' />
                          <span>{` ${statisticalStudent?.[0]?.percentExercisesWorked ?? 0}%`}</span>
                        </span>
                        <span className='text-nowrap'>Tính từ đầu tháng</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col style={{ flex: 1, maxWidth: '33%' }}>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            CHẤT LƯỢNG HOÀN THÀNH THEO TUẦN
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-green text-white rounded-circle shadow'>
                            <i className='ni ni-chart-bar-32' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          decimals={2}
                          style={{ color: '#2DCEAA' }}
                          start={0}
                          duration={5}
                          end={statisticalStudent?.[0]?.qualityCompletedPercent ?? 0}
                          suffix='%'
                        ></CountUp>
                      </Row>

                      {/* <StatisticalSection value={statisticalStudent?.[0]?.percentScore ?? 0} /> */}
                      {/* <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fa fa-arrow-up' />
                          <span>{` ${statisticalStudent?.[0]?.percentScore ?? 0}%`}</span>
                        </span>
                        <span className='text-nowrap'>Tính từ đầu tháng</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col style={{ flex: 1, maxWidth: '33%' }}>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            CHẤT LƯỢNG HOÀN THÀNH
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-purple text-white rounded-circle shadow'>
                            <i className='ni ni-chart-bar-32' />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Giữa kỳ
                          </CardTitle>
                          <CountUp
                            useEasing={true}
                            className='dashboard-countup'
                            decimals={2}
                            style={{ color: '#A465E0', padding: 0 }}
                            start={0}
                            duration={5}
                            end={statisticalStudent?.[0]?.qualificationCompletedPercent_GiuaKy ?? 0}
                            suffix='%'
                          />
                        </Col>
                        <Col span={12}>
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Cuối kỳ
                          </CardTitle>
                          <CountUp
                            useEasing={true}
                            className='dashboard-countup'
                            decimals={2}
                            style={{ color: '#A465E0', padding: 0 }}
                            start={0}
                            duration={5}
                            end={statisticalStudent?.[0]?.qualificationCompletedPercent_CuoiKy ?? 0}
                            suffix='%'
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col style={{ flex: 1, maxWidth: '33%' }}>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            TIẾN ĐỘ HOÀN THÀNH
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-green text-white rounded-circle shadow'>
                            <i className='ni ni-check-bold' />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12}>
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Giữa kỳ
                          </CardTitle>
                          <CountUp
                            useEasing={true}
                            className='dashboard-countup'
                            decimals={2}
                            style={{ color: '#2DCEAA', padding: 0 }}
                            start={0}
                            duration={5}
                            end={statisticalStudent?.[0]?.progress_GiuaKy ?? 0}
                            suffix='%'
                          />
                        </Col>
                        <Col span={12}>
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Cuối kỳ
                          </CardTitle>
                          <CountUp
                            useEasing={true}
                            className='dashboard-countup'
                            decimals={2}
                            style={{ color: '#2DCEAA', padding: 0 }}
                            start={0}
                            duration={5}
                            end={statisticalStudent?.[0]?.progress_CuoiKy ?? 0}
                            suffix='%'
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </StudentContainer>
            <TeacherContainer>
              <Row>
                <Col style={{ flex: 1, maxWidth: '33%' }}>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Tổng số lớp
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-red text-white rounded-circle shadow'>
                            <i className='ni ni-active-40' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          // decimals={2}
                          style={{}}
                          start={0}
                          duration={5}
                          end={statisticalTeacher?.[0]?.totalClass}
                        ></CountUp>
                      </Row>

                      {/* <StatisticalSection value={statisticalTeacher?.[0]?.percentTotalClass ?? 0} /> */}
                      {/* <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fa fa-arrow-up' />
                          <span>{` ${statisticalTeacher?.[0]?.percentTotalClass}%`}</span>
                        </span>
                        <span className='text-nowrap'>Tính từ đầu tháng</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col style={{ flex: 1, maxWidth: '33%' }}>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            {`Tổng số ${settings.studentPronouns1}`}
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-orange text-white rounded-circle shadow'>
                            <i className='ni ni-chart-pie-35' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          // decimals={2}
                          style={{}}
                          start={0}
                          duration={5}
                          end={statisticalTeacher?.[0]?.totalStudent}
                        ></CountUp>
                      </Row>

                      {/* <StatisticalSection value={statisticalTeacher?.[0]?.percentTotalStudent ?? 0} /> */}
                      {/* <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fa fa-arrow-up' />
                          <span>{` ${statisticalTeacher?.[0]?.percentTotalStudent}%`}</span>
                        </span>
                        <span className='text-nowrap'>Tính từ đầu tháng</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col style={{ flex: 1, maxWidth: '33%' }}>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Tỷ lệ hoàn thành bài tập
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-primary text-white rounded-circle shadow'>
                            <i className='ni ni-chart-bar-32' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          decimals={2}
                          style={{}}
                          start={0}
                          duration={5}
                          end={statisticalTeacher?.[0]?.completedPercentOfStudents ?? 0}
                          suffix='%'
                        />
                      </Row>

                      {/* <StatisticalSection value={statisticalTeacher?.[0]?.percentCompletedHomeWork ?? 0} /> */}
                      {/* <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fa fa-arrow-up' />
                          <span>{` ${statisticalTeacher?.[0]?.percentCompletedHomeWork}%`}</span>
                        </span>
                        <span className='text-nowrap'>Tính từ đầu tháng</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TeacherContainer>
            <AdministratorContainer>
              <Row>
                <Col md='6' xl='3'>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Tổng số người dùng
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-red text-white rounded-circle shadow'>
                            <i className='ni ni-active-40' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          // decimals={2}
                          style={{}}
                          start={0}
                          duration={5}
                          end={statisticalAdmin?.[0]?.totalUser}
                        ></CountUp>
                      </Row>

                      <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <span style={{ fontWeight: 500, fontSize: 15 }}>
                            {statisticalAdmin?.[0]?.userActive} Active
                          </span>
                        </span>
                        <span className='text-danger mr-2'>
                          <span style={{ fontWeight: 500, fontSize: 15 }}>
                            {statisticalAdmin?.[0]?.userDeactive} Deactive
                          </span>
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md='6' xl='3'>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Tổng số loại người dùng
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-orange text-white rounded-circle shadow'>
                            <i className='ni ni-chart-pie-35' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          // decimals={2}
                          style={{}}
                          start={0}
                          duration={5}
                          end={statisticalAdmin?.[0]?.totalUser}
                        ></CountUp>
                      </Row>

                      <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <span style={{ fontWeight: 500, fontSize: 15 }}>
                            {statisticalAdmin?.[0]?.student + settings.studentPronouns2}
                          </span>
                        </span>
                        <span className='text-danger mr-2'>
                          <span style={{ fontWeight: 500, fontSize: 15 }}>
                            {statisticalAdmin?.[0]?.teacher} Giáo viên
                          </span>
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md='6' xl='3'>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Tổng số lớp
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-green text-white rounded-circle shadow'>
                            <i className='ni ni-money-coins' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          // decimals={2}
                          style={{}}
                          start={0}
                          duration={5}
                          end={statisticalAdmin?.[0]?.totalClass}
                        ></CountUp>
                      </Row>

                      <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <span style={{ fontWeight: 500, fontSize: 15 }}>
                            {`${statisticalAdmin?.[0]?.studentInClass} ${settings.studentPronouns2} tham gia lớp`}
                          </span>
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md='6' xl='3'>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Tổng số bài giảng
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-primary text-white rounded-circle shadow'>
                            <i className='ni ni-chart-bar-32' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          // decimals={2}
                          style={{}}
                          start={0}
                          duration={5}
                          end={statisticalAdmin?.[0]?.totalPresentation}
                        />
                      </Row>

                      <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <span style={{ fontWeight: 500, fontSize: 15 }}>
                            {statisticalAdmin?.[0]?.totalSlide} Slides
                          </span>
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AdministratorContainer>
          </div>
        </Container>
      </div>
    </>
  );
}

export default withAuthenticator(Header);
