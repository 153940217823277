import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './rootReducer';
import { middlewares, sagaMiddleware } from './middlewares';
import rootSaga from './rootSaga';

// REDUX TOOLS
const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// REDUX STORE
const store = createStore(persistedReducer, compose(composeEnhancers(applyMiddleware(...middlewares))));

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
