import ImageResize from 'quill-image-resize-module-react';
import { Quill } from 'react-quill';

Quill.register('modules/imageResize', ImageResize);

export default {
  toolbar: [
    [{ font: [] }, { size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }, 'blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }, { align: [] }],
    // ['link', 'image'],
    // ['link', 'image', 'video'], // Đang research resise video
    ['clean'],
  ],
  // Resize hình ảnh.
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize', 'Toolbar'], // DisplaySize: hiển thị kích thước file ở bottom-right. Toolbar: căn trái | giữa | phải.
  },
};
