import { Button } from 'antd';
import settings from 'constants/settings';
import i18next from 'i18next';
import ReactExport from 'react-data-export';
import { AiOutlineSave } from 'react-icons/ai';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// API: https://www.npmjs.com/package/react-data-export
// xuất excle với nhiều trang
const sheetsDefault = [
  {
    name: 'Employees', // sheet name
    column: [
      {
        label: 'Name', // column name
        value: 'name', // column value
      },
      {
        label: 'Wallet Money',
        value: 'amount',
      },
      {
        label: 'Gender',
        value: 'sex',
      },
      {
        label: 'Marital Status',
        value: (col) => (col.is_married ? 'Married' : 'Single'),
      },
    ],
    data: [
      {
        name: 'Johson',
        amount: 30000,
        sex: 'M',
        is_married: true,
      },
      {
        name: 'Monika',
        amount: 355000,
        sex: 'F',
        is_married: false,
      },
      {
        name: 'John',
        amount: 250000,
        sex: 'M',
        is_married: false,
      },
      {
        name: 'Josef',
        amount: 450500,
        sex: 'M',
        is_married: true,
      },
    ],
  },
];

function ExportExcel({
  filename = 'Chưa có tên',
  sheets = sheetsDefault,
  element = (
    <Button type='dashed'>
      <div style={{ display: 'flex', alignSelf: 'center' }}>
        <AiOutlineSave size={19} />
        <span>&ensp; {i18next.t('excel export')} </span>
      </div>
    </Button>
  ),
  hideElement = false //To hide the button & directly download excel file
}) {
  return (
    <ExcelFile element={element} filename={filename} hideElement={hideElement}>
      {sheets.map((item) => {
        return (
          <ExcelSheet data={item.data} name={item.name}>
            {item.column.map((i) => {
              return <ExcelColumn label={i.label} value={i.value} />;
            })}
          </ExcelSheet>
        );
      })}
    </ExcelFile>
  );
}
export default ExportExcel;
