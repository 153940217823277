import {
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  PlusOutlined,
  UnlockOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Modal, Popconfirm, Select, Space, Spin, Tooltip, TreeSelect, message } from 'antd';
import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import ExportExcel from 'components/Excel/exportExcel/ExportExcel';
import settings, { IS_BASAO, IS_LX, IS_TRONGDONG } from 'constants/settings';
import { get, query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import _ from 'lodash';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React, { useMemo, useState } from 'react';
import { FaKey } from 'react-icons/fa';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { getWidthOfString } from 'services/Text/widthText';
import ExcelRegister from '../ExcelRegister';
import { getData } from '../ManageKhoaNganh/components/InsertData';
import { addOptionAllShops, buildTreeSelectShops, filterTreeSelectShops } from '../Menard/ManagerShopsV2/tableColumn';
import getSettings from '../api/getSettings';
import FormEditPassword from './components/FormEditPassword';
import InsertUpdateUser from './components/InsertUpdateUser';
import excelColumns from './excelColumns';
import './style.scss';
import tableData from './tableData';

const getCategoryOption = (tag, dataList) => {
  const newOptions = [];
  dataList.forEach((item) => {
    if (item.notes === tag)
      newOptions.push({
        ...item,
        value: item.id,
        label: item.categoryName,
      });
  });
  return newOptions;
};
const buildTreeData = ({ title = 'name', value = 'id', addOptionAll = true, allData = [] }) => {
  let treeOptions =
    allData?.map((item) => ({
      title: item[title],
      key: item[value],
      value: item[value],
    })) ?? [];
  if (addOptionAll)
    treeOptions = [
      { title: i18next.t('All'), key: 'all', value: '00000000-0000-0000-0000-000000000000', children: treeOptions },
    ];
  return treeOptions;
};

export default () => {
  const [teachersList, setTeachersList] = useState([]);
  const [optionsClass, setOptionsClass] = useState([]);
  const [optionsKhoa, setOptionsKhoa] = useState([]);
  const [optionsNganh, setOptionsNganh] = useState([]);
  const [optionsPosition, setOptionsPosition] = useState([]);
  const [isLoadingDeleteUser, setLoadingDeleteUser] = useState(false);
  const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] = useState(false);
  const [modalStatus, setModalStatus] = React.useState({
    title: '',
    open: false,
    component: null,
    width: '90%',
    type: '',
  });
  const [parameters, setParameters] = React.useState({
    email: '',
    name: '',
    phone: '',
    maSV: '',
    roleId: 1,
    status: 'ACTIVE',
  });

  const queryClient = useQueryClient();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const KEY_GET_USERS = ['Users', parameters];
  const { data, isLoading } = useQuery(KEY_GET_USERS, () => query('p_VIMARU_USERS_Search', parameters));
  const { data: dataKhoaNganh } = useQuery('VIMARU_METADATA_Category', () =>
    get('Category', '*', '', 'CreatedDate', 'METADATA', 'VIMARU'),
  );
  const { data: shops } = useQuery('p_QUIZCITY_Shop_Category_Select', () => query('p_QUIZCITY_Shop_Category_Select'));

  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);

  const mailer = ConfigSettings?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value;
  const templateResetPass = ConfigSettings?.find((x) => x?.key.toUpperCase() === 'TEMPLATE_RESETPASSWORD')?.value;

  const optionsShop = buildTreeSelectShops(shops);
  const widthOptionsShop = getWidthOfString(_.maxBy(shops, (i) => i?.categoryName?.length)?.categoryName, '14px') + 80;
  const treeData_ChucVu = buildTreeData({
    title: 'label',
    value: 'value',
    addOptionAll: true,
    allData: optionsPosition,
  });

  const extraRoleId = loggedInUser.role === 'administrator' ? [{ label: i18next.t('Manager'), value: 3 }] : []; // chỉ có admin mới tạo được role manager
  const usersTypeList =
    parameters?.roleId === 1
      ? settings.IS_VIMARU || settings.IS_HIGHUP
        ? i18next.t('Student1')
        : settings.IS_PT || settings.IS_HANDOUT
        ? i18next.t('pupil')
        : i18next.t('Student2')
      : parameters?.roleId === 2
      ? i18next.t('teacher')
      : parameters?.roleId === 3
      ? i18next.t('Manager')
      : '';
  const extraColumn = [
    {
      title: i18next.t('Action'),
      key: 'editInfomation',
      width: '10%',
      // fixed: 'right', anh Hùng bảo bỏ fixed
      sort: false,
      render: (text, record) => {
        const statusAction = record?.status?.toUpperCase() === 'ACTIVE' ? 'khóa' : 'mở';
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Space>
              <Tooltip placement='top' title={i18next.t('updateObject', { object: i18next.t('information') })}>
                <Button
                  key='edit-file'
                  shape='circle'
                  type='dashed'
                  icon={<EditOutlined />}
                  onClick={() => handleShowModal('edituser', record)}
                  sty
                />
              </Tooltip>
              <Popconfirm
                placement='topRight'
                title={`${i18next.t('AreYouSureUpdateStatus', { field: '' })} ${record.email}?`}
                // title={`Bạn có chắc chắn ${statusAction} tài khoản: ${record.email}`}
                onConfirm={() => onUpdateStatusUser(record)}
                okText={i18next.t('Confirm')}
                cancelText={i18next.t('Close')}
              >
                <Tooltip placement='top' title={i18next.t('updateObject', { object: i18next.t('Status') })}>
                  {record.status === 'ACTIVE' ? (
                    <Button
                      loading={isLoadingUpdateStatus}
                      key='edit-file'
                      danger
                      shape='circle'
                      type='dashed'
                      style={{ color: 'red' }}
                      icon={<LockOutlined />}
                    />
                  ) : (
                    <Button
                      loading={isLoadingUpdateStatus}
                      key='edit-file'
                      shape='circle'
                      type='dashed'
                      icon={<UnlockOutlined />}
                    />
                  )}
                </Tooltip>
              </Popconfirm>
              <Tooltip placement='top' title={i18next.t('Update password')}>
                <Button
                  key='edit-file'
                  shape='circle'
                  type='dashed'
                  icon={<FaKey />}
                  onClick={() => handleShowModal('password', record)}
                />
              </Tooltip>
              <Popconfirm
                placement='topRight'
                title={() => {
                  return (
                    <div>
                      <p style={{ fontSize: 14, fontWeight: 400 }}>
                        {i18next.t('Are you sure to delete', { object: i18next.t('account') })}
                        {record.email}?
                      </p>
                      <strong style={{ color: '#f5222d' }}>({i18next.t('popconfirm.unrecoverable')})</strong>
                    </div>
                  );
                }}
                onConfirm={() => confirmDelUser(record)}
                okButtonProps={{
                  loading: isLoadingDeleteUser,
                }}
                okText={i18next.t('Confirm')}
                cancelText={i18next.t('Close')}
              >
                <Tooltip placement='top' title={i18next.t('DeleteAccount')}>
                  <Button
                    key='edit-file'
                    shape='circle'
                    type='dashed'
                    danger
                    style={{ color: '#f5222d' }}
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            </Space>
          </div>
        );
      },
    },
  ];

  // setup option category "Khoa", "Ngành", "Chức vụ"
  React.useEffect(() => {
    if (dataKhoaNganh && dataKhoaNganh?.length > 0) {
      const activeCategory = dataKhoaNganh.filter((position) => position.isActive === true);
      if (settings.IS_VIMARU || settings.IS_HIGHUP) {
        setOptionsKhoa(getCategoryOption('Khoa', activeCategory));
        setOptionsNganh(getCategoryOption('Nganh', activeCategory));
      } else if (settings.IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX) {
        setOptionsPosition(getCategoryOption('ChucVu', activeCategory));
      }
    }
  }, [dataKhoaNganh]);

  React.useEffect(() => {
    query('p_VIMARU_CLASSES_GetClasses', { userId: loggedInUser?.id }).then((res) => {
      const activeClass = res.filter((i) => !(i?.classState?.toLowerCase() === 'finish'));
      setOptionsClass(
        activeClass.map((item) => ({
          value: item.code,
          label: item.className,
          teacherId: item.teacherId,
          classId: item.id,
        })),
      );
    });
    query('p_VIMARU_USERS_Search', {
      email: '',
      name: '',
      phone: '',
      maSV: '',
      roleId: 2,
      status: 'ACTIVE',
    }).then((res) => {
      setTeachersList(res);
    });
  }, []);

  // server đang bắt buộc lưu dữ liệu ngày nên nếu truyền null lên thì sẽ bị chuyển sang ngày mặc định "1900-01-01T00:00:00"
  // nếu server trả về ngày "1900-01-01T00:00:00" thì có nghĩa là thông tin đó người dùng chưa cập nhật (NULL)
  const checkDate = (dateData) => {
    if (dateData === '1900-01-01T00:00:00') {
      return null;
    } else return dateData;
  };
  // Tương tự CheckDate , trường id không được null nên server phải lưu mặc định là '00000000-0000-0000-0000-000000000000'
  const checkId = (id) => {
    if (id === '00000000-0000-0000-0000-000000000000') {
      return null;
    } else return id;
  };

  // match để bổ sung các trường dữ liệu từ categoryId và format dữ liệu trước khi hiển thị
  const customData = (usersList) => {
    return usersList?.map((item) => {
      const shop = shops?.find((i) => i.id === item?.shop) || null;
      const position = optionsPosition?.find((i) => i.value === item?.chucVu) || null;
      return {
        ...item,
        chucVu: checkId(item.chucVu),
        shop: checkId(item.shop),
        khoa: checkId(item.khoa),
        nganh: checkId(item.nganh),
        birthday: checkDate(item.birthday),
        ngayVaoCongTy: checkDate(item.ngayVaoCongTy),
        shopName: shop?.categoryName,
        shopCode: shop?.code,
        positionName: position?.label,
        positionCode: position?.code,
      };
    });
  };

  const listUsers = useMemo(() => customData(data), [data, optionsShop, optionsPosition]);

  const onUpdateStatusUser = (record) => {
    //* Update: status account: Active/DeActive
    setIsLoadingUpdateStatus(true);
    const parameter = {
      Id: record.id,
      MaSV: record.maSV,
      Email: record.email,
      Name: record.fullName,
      Phone: record.phone,
      RoleId: record.roleId,
      Sex: record?.sex,
      Status: record.status === 'ACTIVE' ? 'DEACTIVE' : 'ACTIVE',
      Avatar: record.avatar,
      BirthDay: record?.BirthDay,
    };
    query('p_VIMARU_Users_Edit', parameter)
      .then((res) => {
        if (res?.[0]?.res === 1) {
          userCallbackCancelModal();
          message.success(i18next.t('update success', { field: i18next.t('information') }));
          queryClient.invalidateQueries('Users');
        } else {
          //! Error: Updated fail
          // message.error(`${settings.IS_MENARD ? 'Mã nhân viên' : 'Mã người dùng'} hoặc Email đã tồn tại`);
          message.error(
            i18next.t('alreadyExists2', {
              field: settings.IS_MENARD ? i18next.t('employeeCode') : i18next.t('userCode'),
              field1: i18next.t('Email'),
            }),
          );
        }
      })
      .catch(() => {
        //! Error: Connected Fail
        message.error(i18next.t('connectError'));
      })
      .finally(() => setIsLoadingUpdateStatus(false));
  };
  // TODO: Delete User
  const confirmDelUser = (record) => {
    setLoadingDeleteUser(true);
    query('p_VIMARU_User_DEL', {
      Id: record.id,
    })
      .then((res) => {
        if (res?.[0].res === 1) {
          //* Success Delete User
          // message.success(`Xóa tài khoản ${record.email} thành công`);
          message.success(i18next.t('deleteAccountEmailSuccess', { field: record.email }));

          return queryClient.invalidateQueries('Users');
        }
        //! Error: Delete Fail
        // message.warning(`Xóa tài khoản ${record.email} thất bại`);
        message.success(i18next.t('deleteAccountEmailFailed', { field: record.email }));
      })
      .catch((err) => {
        message.error(i18next.t('connectError'));
      })
      .finally(() => setLoadingDeleteUser(false));
  };

  const userCallbackCancelModal = React.useCallback(() => {
    setModalStatus({
      title: '',
      open: false,
      width: '90%',
      component: null,
      type: '',
    });
  });

  const handleShowModal = (type, record) => {
    if (type === 'password') {
      setModalStatus({
        // title: settings.IS_LG ? 'UPDATE USER PASSWORD' : 'CẬP NHẬT MẬT KHẨU NGƯỜI DÙNG',
        title: i18next.t('updateObject', { object: i18next.t('userPassword') }).toUpperCase(),
        open: true,
        component: (
          <FormEditPassword
            record={record}
            cancelModal={userCallbackCancelModal}
            templateResetPass={templateResetPass}
            mailer={mailer}
          />
        ),
        width: 400,
        type: type,
      });
    } else if (type === 'adduser') {
      setModalStatus({
        title: i18next.t('addUser').toUpperCase(),
        open: true,
        component: (
          <InsertUpdateUser
            type={type}
            cancelModal={userCallbackCancelModal}
            optionsKhoa={optionsKhoa}
            optionsNganh={optionsNganh}
            teachersList={teachersList}
            optionsClass={optionsClass}
            optionsPosition={optionsPosition}
            optionsShop={optionsShop}
            shops={shops}
          />
        ),
        width: '90%',
        type: type,
      });
    } else if (type === 'edituser') {
      setModalStatus({
        // title: settings.IS_LG ? 'UPDATE USER INFORMATION' : 'CẬP NHẬT THÔNG TIN NGƯỜI DÙNG',
        title: i18next.t('commonTitle.Update information', { object: i18next.t('user') }),
        open: true,
        component: (
          <InsertUpdateUser
            type={type}
            cancelModal={userCallbackCancelModal}
            record={record}
            optionsKhoa={optionsKhoa}
            optionsNganh={optionsNganh}
            teachersList={teachersList}
            optionsClass={optionsClass}
            optionsPosition={optionsPosition}
            optionsShop={optionsShop}
            shops={shops}
          />
        ),
        width: '90%',
        type: type,
      });
    } else if (type === 'excel_sv') {
      setModalStatus({
        title: i18next.t('importUserExcel'),
        open: true,
        component: (
          <ExcelRegister buttonCloseModal={userCallbackCancelModal} dataPosition={optionsPosition} dataShop={shops} />
        ),
        width: '90%',
        type: type,
      });
    }
  };

  const onSearchUsers = (values) => {
    // newParams là mặc định các tham số
    const newParams = {
      maSV: '',
      email: '',
      name: '',
      phone: '',
      roleId: 1,
      status: values.status,
    };
    // Khi parameters thay đổi thì useQuery sẽ được gọi lại
    Object.keys(values).forEach((key) => {
      if (['00000000-0000-0000-0000-000000000000', null, undefined, ''].includes(values[key])) {
        // "00000000-0000-0000-0000-000000000000" áp dụng cho các bộ lọc Select (lấy tất cả thì không cần truyền vào params)
      } else if (typeof values[key] === 'object') {
        // Đối với bộ phận và chức vụ, chọn nhiều thì nối id ở đây
        if (!values[key].includes('00000000-0000-0000-0000-000000000000')) {
          newParams[key] = values[key].toString();
        }
      } else newParams[key] = values[key];
    });
    setParameters(newParams);
  };

  return (
    <div id='user-management-page'>
      <Container name='Quản lý người dùng' parentName='Administration'>
        <Card
          title={i18next.t('manageUser')}
          extra={
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', columnGap: 10 }}>
              <DynamicButton onClick={() => handleShowModal('adduser')}>
                <PlusOutlined /> {i18next.t('addUser')}
              </DynamicButton>
              <DynamicButton onClick={() => handleShowModal('excel_sv')}>
                <UploadOutlined /> {i18next.t('importUserExcel')}
              </DynamicButton>
            </div>
          }
        >
          <Spin spinning={isLoading}>
            <Form
              layout='inline'
              autoComplete='off'
              onFinish={onSearchUsers}
              initialValues={{
                maSV: '',
                email: '',
                name: '',
                phone: '',
                roleId: 1,
                shopId: ['00000000-0000-0000-0000-000000000000'],
                chucVuId: ['00000000-0000-0000-0000-000000000000'],
                status: 'ACTIVE',
              }}
              style={{ gap: 10 }}
            >
              <Form.Item
                name='roleId'
                label={i18next.t('accountType')}
                rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('accountType') }) }]}
              >
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  options={[
                    { value: 1, label: settings.studentPronouns2 },
                    { value: 2, label: i18next.t('Teacher') },
                    ...extraRoleId,
                  ]}
                  style={{ width: 150 }}
                />
              </Form.Item>
              <Form.Item
                label={settings.IS_VIMARU ? 'Mã sinh viên' : i18next.t('table:managerCoachingSchedule.Employee code')}
                name='maSV'
              >
                <Input allowClear style={{ width: 120 }} />
              </Form.Item>

              <Form.Item
                label={i18next.t('fullName')}
                name='name'
                // rules={[{ required: true, message:i18next.t('please enter', { field: 'Full name' }) }]}
              >
                <Input style={{ width: 200 }} allowClear />
              </Form.Item>

              <Form.Item label='Email' name='email'>
                <Input style={{ width: 200 }} allowClear />
              </Form.Item>
              <Form.Item label={i18next.t('phoneNumber')} name='phone'>
                <Input style={{ width: 110 }} allowClear />
              </Form.Item>
              {settings.IS_LG && (
                <Form.Item label={i18next.t('Part')} name='shopId'>
                  <TreeSelect
                    showSearch
                    treeData={addOptionAllShops(optionsShop)}
                    treeCheckable={true}
                    dropdownMatchSelectWidth={true}
                    showCheckedStrategy={'SHOW_PARENT'}
                    style={{ minWidth: 120, width: widthOptionsShop }}
                    dropdownStyle={{
                      maxHeight: 600,
                      minWidth: 500,
                      width: '100%',
                    }}
                    // treeDefaultExpandAll
                    placeholder={i18next.t('All')}
                    filterTreeNode={filterTreeSelectShops}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                  />
                </Form.Item>
              )}
              {(settings.IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX) && (
                <>
                  <Form.Item label='Bộ phận' name='shopId'>
                    <TreeSelect
                      showSearch
                      treeData={addOptionAllShops(optionsShop)}
                      treeCheckable={true}
                      dropdownMatchSelectWidth={true}
                      showCheckedStrategy={'SHOW_PARENT'}
                      style={{ minWidth: 120, width: widthOptionsShop }}
                      dropdownStyle={{
                        maxHeight: 600,
                        minWidth: 500,
                        width: '100%',
                      }}
                      // treeDefaultExpandAll
                      placeholder={i18next.t('All')}
                      filterTreeNode={filterTreeSelectShops}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />
                  </Form.Item>
                  <Form.Item label='Chức vụ' name='chucVuId'>
                    <TreeSelect
                      showSearch
                      treeData={treeData_ChucVu}
                      treeCheckable={true}
                      dropdownMatchSelectWidth={true}
                      showCheckedStrategy={'SHOW_PARENT'}
                      style={{ width: 200 }}
                      dropdownStyle={{
                        maxHeight: 600,
                        minWidth: 500,
                        width: '100%',
                      }}
                      // treeDefaultExpandAll
                      placeholder={i18next.t('All')}
                      filterTreeNode={filterTreeSelectShops}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item name='status' label={i18next.t('Status')}>
                <Select
                  options={[
                    { label: 'All', value: '' },
                    { label: 'Active', value: 'ACTIVE' },
                    { label: 'Deactive', value: 'DEACTIVE' },
                  ]}
                  style={{ width: 100 }}
                />
              </Form.Item>
              <Form.Item>
                <Button loading={isLoading} htmlType='submit' type='primary'>
                  {i18next.t('search')}
                </Button>
              </Form.Item>
              <Form.Item>
                <ExportExcel
                  filename={i18next.t('userList', { name: usersTypeList })}
                  sheets={[
                    {
                      name: usersTypeList, // sheet name
                      column: excelColumns,
                      data: listUsers,
                    },
                  ]}
                />
              </Form.Item>

              <div style={{ fontWeight: 600, fontSize: 14, color: '#525F7F', padding: '5px 0px 0px 0px' }}>
                {i18next.t('total')}: {listUsers?.length ?? 0}
              </div>
            </Form>
            <br />
            <DynamicTable
              loading={isLoading}
              dataSource={listUsers}
              initialTableData={tableData}
              extraColumns={extraColumn}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '50', '100'],
              }}
              // pagination={{ defaultPageSize: 10, position: paginationUsersTable ?? ['topRight', 'bottomRight'] }}
              onReload={undefined}
              scroll={{ x: 1800, scrollToFirstRowOnChange: true }}
            />
          </Spin>
        </Card>

        <Modal
          centered={true}
          width={modalStatus?.width}
          title={<div style={{ color: settings.IS_LG ? '#C2224f' : '' }}>{modalStatus.title}</div>}
          open={modalStatus.open}
          footer={null}
          onCancel={() => userCallbackCancelModal()}
          destroyOnClose
        >
          {modalStatus.component}
        </Modal>
      </Container>
    </div>
  );
};
