import { query } from 'helpers/QueryHelper';
import { useQuery } from 'react-query';

type GetStudentsOrStudentByClassId = {
  classId:number, 
  pageNum:number,  
  pageSize:number,   
  search?:string,
}

const getStudentsOrStudentByClassId = (parameters:GetStudentsOrStudentByClassId) => {
  // @ClassId INT = NULL,    
  // @ModuleId UNIQUEIDENTIFIER,    
  // @PageNum INT = 1,    
  // @PageSize INT = 10,    
  // @Search NVARCHAR(250) = N''
  const sqlCommand = 'p_VIMARU_STUDENTS_GetStudents_ByClassId';
  return query(sqlCommand, parameters);
};

const useStudentsOrStudentByClassId = (parameters: GetStudentsOrStudentByClassId) => {
  const KEY_VIMARU_STUDENTS_GetStudents_OrStudent_ByClassId = [
    'p_VIMARU_STUDENTS_GetStudents_OrStudent_ByClassId',
    parameters,
  ];
  return useQuery(
    KEY_VIMARU_STUDENTS_GetStudents_OrStudent_ByClassId,
    () => getStudentsOrStudentByClassId(parameters)
  );
};

export { getStudentsOrStudentByClassId, useStudentsOrStudentByClassId };
