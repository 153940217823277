/* eslint-disable no-unused-vars */
import {
  CalendarOutlined,
  CheckCircleFilled,
  ClockCircleOutlined,
  FileDoneOutlined,
  FileOutlined,
  FileTextFilled,
  FileTextOutlined,
  UserOutlined,
} from '@ant-design/icons';
import settings from 'constants/settings';
import i18next from 'i18next';

const getColor = (type) => {
  // SÁCH SỐ
  let color = '#fa541c';
  if (type && type.toLowerCase() === 'auto_lws') color = '#1890ff';
  else if (type && type.toLowerCase() === 'auto_quiz') {
    color = '#52c41a';
  }
  // QUIZCITY
  if (type === 'QUIZCITY-MANUAL') color = '#fa541c';
  if (type === 'QUIZCITY-WORKSHEET') color = '#1890ff';
  if (type === 'QUIZCITY-QUIZ') color = '#52c41a';
  return color;
};

export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: i18next.t('table:reference.Type'),
      key: 'typeExercise',
      width: 42,
      sort: false,
      align: 'center',
      render: (text, record, index) => {
        const type = text ?? record?.settings?.tags;
        return <FileDoneOutlined style={{ color: getColor(type), cursor: 'pointer' }} />;
      },
    },
    {
      title: i18next.t('Excercise'),
      key: 'title',
      sort: false,
      render: (text, record, index) => {
        return (
          <div
            style={{
              fontWeight: 600,
              cursor: 'pointer',
            }}
          >
            <span style={{ color: record.color }}>{text}</span>
          </div>
        );
      },
    },
    {
      title: i18next.t('Submit'),
      key: 'turnedInNumbers',
      width: 88,
      render: 'renderNumber',
      style: {
        fontWeight: 400,
        cursor: 'pointer',
      },
      prefix: <UserOutlined className='tw-mr-2' />,
    },
    {
      key: 'requirements',
      title: i18next.t('Requirement'),
      children: [
        {
          title: i18next.t('Point'),
          key: 'points',
          width: '1%',
          render: 'renderNumber',
          style: {
            fontWeight: 400,
            cursor: 'pointer',
            minWidth: 72,
          },
        },
        {
          title: i18next.t('Deadline'),
          key: 'dueDate',
          width: 140,
          render: 'renderDateTime',
          style: {
            fontWeight: 600,
            cursor: 'pointer',
          },
          prefix: <ClockCircleOutlined className='tw-mr-2' />,
        },
      ],
    },
    {
      title: i18next.t('table:reference.Created date'),
      key: 'createdDate',
      width: '1%',
      render: 'renderDate',
      style: {
        fontWeight: 400,
        cursor: 'pointer',
      },
      prefix: <CalendarOutlined className='tw-mr-2' />,
    },
  ],
};
