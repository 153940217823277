/* eslint-disable react/prop-types */
import withAuthenticator from 'HOCs/withAuthenticator';
import { Form, Modal, notification } from 'antd';
import settings from 'constants/settings';
import { query, queryFirst } from 'helpers/QueryHelper';
import i18next from 'i18next';
import DynamicForm from 'packages/DynamicForm/components/DynamicForm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { removeAccents } from 'services/Text/formatText';
import initialFormData from './formData';

const fetcher = (classId) => () => {
  return queryFirst('p_VIMARU_CLASSES_GetById', { id: classId });
};

function Update({ loggedInUser, classId, visible, onOk, onCancel, courses, schoolYear }) {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const key = ['p_VIMARU_CLASSES_GetById', classId];
  const { data, isSuccess } = useQuery(key, fetcher(classId), { enabled: visible });
  const [formData, setFormData] = React.useState(null);

  const initialValues = {
    className: data?.className,
    note: data?.note,
    courseId: data?.courseId,
    classState: data?.classState,
    nienKhoaId: data?.nienKhoaId,
  };

  React.useEffect(() => form.resetFields(), [form, initialValues]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    const courseOptions = courses?.map((course) => ({ label: course?.title, value: course?.id }));
    const courseFormItem = {
      label: i18next.t('table:statistic.Course'),
      name: 'courseId',
      // initialValue: currentCourse?.id,
      rules: [
        {
          required: true,
          message: i18next.t('please choose', { field: i18next.t('table:statistic.Course') }),
        },
      ],
      inputComponent: {
        type: 'Select',
        props: {
          // style: { width: 120 },
          options: courseOptions,
          defaultValue: data?.courseId,
          showSearch: true,
          allowClear: true,
          optionFilterProp: 'children',
          filterOption: (input, option) => removeAccents(option?.label)?.includes(removeAccents(input)),
        },
      },
    };
    const schoolYearFormItem = settings.IS_VIMARU
      ? [
          {
            label: 'Niên khóa',
            name: 'nienKhoaId',
            // initialValue: currentCourse?.id,
            rules: [
              {
                required: true,
                message: i18next.t('please choose', { field: i18next.t('School Year').toLowerCase() }),
              },
            ],
            inputComponent: {
              type: 'Select',
              props: {
                // style: { width: 120 },
                options: schoolYear,
                defaultValue: data?.schoolYearId,
                showSearch: true,
                allowClear: true,
                optionFilterProp: 'children',
                filterOption: (input, option) => removeAccents(option?.label)?.includes(removeAccents(input)),
              },
            },
          },
        ]
      : [];
    const cloneFormData = { ...initialFormData };
    const { formFields } = cloneFormData;
    const newFormField = [...formFields, courseFormItem, ...schoolYearFormItem];
    setFormData({ ...initialFormData, formFields: newFormField });
  }, [courses, data, schoolYear]);

  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        title={i18next.t('class:ClassDetails.EditClass.Update class information')}
        open={visible}
        onOk={() => {
          form.submit();
        }}
        onCancel={onCancel}
        okText={i18next.t('Update')}
        cancelText={t('Close')}
      >
        {isSuccess && (
          <DynamicForm
            formInstance={form}
            initialFormData={formData}
            initialValues={initialValues}
            onSubmit={async (parameters) => {
              query(initialFormData.storedProcedure, {
                ...parameters,
                code: data?.code,
                id: data?.id,
                nienKhoaId: parameters.nienKhoaId ?? '00000000-0000-0000-0000-000000000000',
              }).then((res) => {
                if (res?.[0]?.message) {
                  notification.error({
                    message: i18next.t('notification'),
                    description: i18next.t('update failed', { field: i18next.t('table:notification.Classes') }),
                  });
                } else {
                  onOk();
                  notification.success({
                    message: i18next.t('notification'),
                    description: i18next.t('update success', {
                      field: i18next.t('table:notification.Classes').toLowerCase(),
                    }),
                  });
                  queryClient.invalidateQueries(key);
                }
              });
            }}
          />
        )}
      </Modal>
    </div>
  );
}

export default withAuthenticator(Update);
