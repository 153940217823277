import { Form, Input } from 'antd';
import React from 'react';

type Props = {};

const HandoutFormContent = (props: Props) => {
  return (
    <React.Fragment>
      <Form.Item
        hasFeedback
        name='userName'
        rules={[
          { type: 'string', message: 'Tên không hợp lệ' },
          {
            whitespace: true,
            message: 'Vui lòng nhập lại, không xuống dòng!',
          },
          {
            required: true,
            message: 'Vui lòng nhập tên!',
          },
        ]}
      >
        <Input
          style={{
            boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
            borderRadius: 3,
          }}
          allowClear
          size='large'
          prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-user' />}
          placeholder='Họ và tên (*)'
        />
      </Form.Item>
      <Form.Item
        hasFeedback
        name='email'
        rules={[
          { type: 'email', message: 'Email không hợp lệ' },
          {
            whitespace: true,
            message: 'Vui lòng không xuống dòng!',
          },
        ]}
      >
        <Input
          style={{
            boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
            borderRadius: 3,
          }}
          allowClear
          size='large'
          prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fa fa-envelope' />}
          placeholder={`Email`}
        />
      </Form.Item>
      <Form.Item
        hasFeedback
        name='phone'
        rules={[
          {
            whitespace: true,
            message: 'Vui lòng không xuống dòng!',
          },
        ]}
      >
        <Input
          style={{
            boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
            borderRadius: 3,
          }}
          allowClear
          size='large'
          prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fa fa-phone' />}
          placeholder='Số điện thoại'
        />
      </Form.Item>
    </React.Fragment>
  );
};

export default HandoutFormContent;
