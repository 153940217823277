import React, { FC } from 'react';

type IconProps = {
  angle?: number;
  backgroundColor?: string;
  height?: number;
  icon?: string;
  iconColor?: string;
  left?: number;
  top?: number;
  width?: number;
};

const Icon: FC<IconProps> = ({ angle, backgroundColor, height, icon, iconColor, left, top, width }) => {
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor,
        borderRadius: '50%',
        display: 'flex',
        height,
        justifyContent: 'center',
        left,
        padding: height / 2,
        position: 'absolute',
        top,
        transform: `rotate(${angle}deg)`,
        width,
        zIndex: 1,
      }}
    >
      <i className={icon} style={{ color: iconColor }} />
    </div>
  );
};

export default Icon;
