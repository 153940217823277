import { TablePaginationConfig } from 'antd';

interface TableParams {
  pagination?: TablePaginationConfig;
}
const tableParams: TableParams = {
  pagination: {
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100', '500', '1000'],
  },
};

type PaginationProps = {
  pageNumber?: number;
  pageSize?: number;
  totalRows?: number;
  onChange?: (page: number, pageSize: number) => void;
};

const paginationTable = (props: PaginationProps) => {
  if (props.pageNumber && props.pageSize && props.totalRows && typeof props.onChange === 'function') {
    // Cách này dùng cho react-query
    const pagination: TablePaginationConfig = {
      ...tableParams.pagination,
      current: props.pageNumber,
      pageSize: props.pageSize,
      total: props.totalRows,
      onChange: props.onChange,
    };
    return pagination;
  } else {
    //Nếu không truyền đủ tham số thì dùng cấu hình mặc định không phân trang ở client
    return tableParams.pagination;
  }
};

export { paginationTable, tableParams };
