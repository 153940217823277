/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-param-reassign */
import React from 'react';
import { fabric } from 'fabric';
import _ from 'lodash';
import { Row, Col, Space } from 'antd';
import numeral from 'numeral';
import i18next from 'i18next';

const HTMLCanvasElement = 'sachso-student-canvas-view';

const getScore = (result) => {
  if (!result) return 0;

  let correct = 0;
  let wrong = 0;
  if (result.correct) {
    correct = result.correct;
  }

  if (result.wrong) {
    wrong = result.wrong;
  }

  if (correct + wrong === 0) return 0;

  const output = (correct / (correct + wrong)) * 10;
  return output;
};

const getTotal = (result) => {
  if (!result) return 0;

  let correct = 0;
  let wrong = 0;
  if (result.correct) {
    correct = result.correct;
  }

  if (result.wrong) {
    wrong = result.wrong;
  }

  if (correct + wrong === 0) return 0;

  const output = correct + wrong;
  return output;
};

function ViewScoreCanvasWithinImage({ data }) {
  const imageUrl = `https://cloud.softech.vn/${data.fileUrl}`;
  const [result, setResult] = React.useState(null);

  React.useEffect(() => {
    const canvas = new fabric.Canvas(HTMLCanvasElement, {
      isDrawingMode: false,
      renderOnAddRemove: true,
      hoverCursor: 'pointer',
    });
    fabric.Image.fromURL(imageUrl, (img) => {
      canvas.setWidth(img.width);
      canvas.setHeight(img.height);
      canvas.setBackgroundImage(img);
    });

    // load from json;
    const { jsonData } = data;
    if (jsonData) {
      _.each(jsonData.objects, (x) => {
        // console.log(x);
        x.hasBorders = false;
        x.hasControls = false;
        x.lockMovementX = true;
        x.lockMovementY = true;
        if (x.type === 'image') {
          x.src = _.replace(x.src, 'http://localhost:3000', 'https://sachso.edu.vn');
        }
        if (x.type === 'image') {
          if (_.endsWith(x.src, 'correct.png')) {
            x.status = 'correct';
          } else if (_.indexOf(x.src, 'wrong')) {
            x.status = 'wrong';
          }
        } else if (x.type === 'textbox') {
          x.editable = false;
        }
      });

      canvas.loadFromJSON(jsonData);

      const countBy = _.countBy(jsonData.objects, (item) => {
        if (item.type === 'textbox') return 'unknown';
        return item.status === 'correct' ? 'correct' : 'wrong';
      });

      setResult(countBy);
    }
  }, [data, imageUrl]);

  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        <Space size='large'>
          <Row>
            <Col style={{ fontSize: 18, fontWeight: 600 }}>
              <div style={{ height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <strong style={{ fontSize: 18 }}>{`${i18next.t("Score")}: `}</strong>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  marginLeft: 8,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'green',
                  width: 48,
                  height: 48,
                  borderRadius: '50%',
                }}
              >
                <strong style={{ fontSize: 18, color: 'white' }}>{numeral(getScore(result)).format('0.0')}</strong>
              </div>
            </Col>
          </Row>
          <Row>
            <Col style={{ fontSize: 18, fontWeight: 600 }}>
              <div style={{ height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <strong style={{ fontSize: 18 }}>{`${i18next.t("Correct")}: `}</strong>
              </div>
            </Col>
            <Col>
              <div
                style={{ marginLeft: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 48 }}
              >
                <strong style={{ fontSize: 18 }}>{result?.correct ? result?.correct : 0}</strong>
                <span className='tw-mx-2'> / </span>
                <strong style={{ fontSize: 18 }}>{numeral(getTotal(result)).format('0')}</strong>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <img src='/images/icons/correct.png' style={{ width: 32 }} alt='correct' />
            </Col>
            <Col>
              <div style={{ marginLeft: 4 }}>
                <strong style={{ fontSize: 18 }}> {result?.correct ? result.correct : 0}</strong>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src='/images/icons/wrong.png' style={{ width: 32 }} alt='correct' />
            </Col>
            <Col>
              <div style={{ marginLeft: 4 }}>
                <strong style={{ fontSize: 18 }}> {result?.wrong ? result.wrong : 0}</strong>
              </div>
            </Col>
          </Row>
        </Space>
      </div>
      <div style={{ display: 'flex', padding: 0, border: '1px dashed gray' }}>
        <div style={{ flex: 1, overflow: 'auto', width: '100%', height: window.innerHeight * 0.75 }}>
          <canvas id={HTMLCanvasElement} />
        </div>
      </div>
    </div>
  );
}

export default ViewScoreCanvasWithinImage;
