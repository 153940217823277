import * as actionTypes from './types';

export function selectSemester(semester: any) {
  return {
    type: actionTypes.SIDEBAR_SELECT_FOLDER,
    payload: semester,
  };
}
export function folderMenuClicked() {
  return {
    type: actionTypes.FOLDER_MENU_CLICKED,
  };
}
export function folderMenuClickedReset() {
  return {
    type: actionTypes.FOLDER_MENU_CLICKED_RESET,
  };
}
