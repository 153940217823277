// Bỏ dấu tiếng việt và dấu cách ("Đề thi    aBc" => "dethiabc")
function convertToSlug(str: string = '') {
  // Loại bỏ các dấu diacritics trong tiếng Việt
  return (
    str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      // Chuyển thành chữ thường và loại bỏ khoảng trắng
      .toLowerCase()
      .replace(/\s+/g, '')
      .replace(/đ/g, 'd')
      // Loại bỏ các ký tự đặc biệt, chỉ giữ lại ký tự a-z, 0-9 và dấu '-'
      .replace(/[^a-z0-9-]/g, '')
      // Loại bỏ các dấu '-' liền nhau
      .replace(/-+/g, '')
  );
}

// xoá dấu tiếng Việt: "Đề thi" => "de thi"
const removeAccents = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
    .toLowerCase();
};
export { convertToSlug, removeAccents };
