// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';

function AuthHeader({ imgUrl }) {
  // function AuthHeader({ title, lead }) {
  return (
    <>
      <div className='header bg-gradient-info py-5 py-lg-6 pt-lg-6'>
        <Container>
          <div className='header-body text-center mb-7'>
            <Row className='justify-content-center'>
              <Col className='px-5' lg='6' md='8' xl='5' style={{ display: 'flex', justifyContent: 'center' }}>
                <img alt='English City' className='navbar-brand-img' src={imgUrl} width={300} />

                {/* {title ? <h1 style={{ fontSize: 50, fontWeight: '500' }} className='text-white'>{title}</h1> : null}
                {lead ? <p className='text-lead text-white'>{lead}</p> : null} */}
              </Col>
            </Row>
          </div>
        </Container>
        <div className='separator separator-bottom separator-skew zindex-100'>
          <svg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' version='1.1' viewBox='0 0 2560 100' x='0' y='0'>
            <polygon className='fill-default' points='2560 0 2560 100 0 100' />
          </svg>
        </div>
      </div>
    </>
  );
}

AuthHeader.propTypes = {
  imgUrl: PropTypes.string,
};

export default AuthHeader;
