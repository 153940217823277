import { Button, message, Modal, Popconfirm, Space, Spin, Tooltip } from 'antd';
import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import ViewImportQuestion from './components/ViewImportQuestion';
import { tableAllQuestion } from './components/column';
import SearchDataQuestion from './components/Modules/SearchDataQuestion';
import { query } from 'helpers/QueryHelper';
import { useQuery, useQueryClient } from 'react-query';
import ViewImportExam from './components/ViewImportExam';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getQuestions_UseQuery } from './api/QuestionApi';
import InsertUpdateQuestion from './components/InsertUpdateQuestion';

message.config({
  maxCount: 1,
});

const IndexImportQuestion = () => {
  const { t } = useTranslation('table');
  const queryClient = useQueryClient();

  const [modalStatus, setModalStatus] = React.useState(false);
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);
  const [listSelectQuestion, setListSelectQuestion] = React.useState([]);
  const [isLoadingRemoveQuestion, setIsLoadingRemoveQuestion] = React.useState(false);
  const [searchParameters, setSearchParameters] = React.useState({
    type: '',
    optionType: '',
    grade: '',
    tag: '',
    questionText: '',
  });

  const { data: dataQuestion, isLoading } = getQuestions_UseQuery(searchParameters);

  const rowStudentsSelection = {
    onChange: (newSelectedRowKeys) => {
      setListSelectQuestion(newSelectedRowKeys);
    },
  };

  const rowSelectionStudentsTable = {
    type: 'checkbox',
    listSelectQuestion,
    ...rowStudentsSelection,
  };

  const removeMultipleQuestion = () => {
    setIsLoadingRemoveQuestion(true);
    listSelectQuestion?.map((item) => {
      query('p_QUIZCITY_ResouresQuestion_Del', { id: item })
        .then((res) => {
          message.open({
            type: 'success',
            content: 'Xóa dữ liệu thành công.',
          });
          queryClient.invalidateQueries('SACHSO_RESOURCES_Questions');
          setListSelectQuestion([]);
          setIsLoadingRemoveQuestion(false);
        })
        .catch(() => {
          message.open({
            type: 'error',
            content: 'Xóa dữ liệu thất bại.',
          });
          setIsLoadingRemoveQuestion(false);
        });
    });
    setIsLoadingRemoveQuestion(false);
  };

  const userCallbackCancelModal = () => {
    setModalStatus(false);
  };

  const onRefreshQuestionList = () => {
    queryClient.invalidateQueries('p_QUIZCITY_ResourcesQuestion_Search');
  };

  const onSearchQuestion = (values) => {
    const { grade, optionType, type, questionText, tag } = values;
    setSearchParameters({
      questionText: questionText,
      type,
      optionType,
      grade,
      unit: '',
      tag,
    });
  };

  const onRemove = (id) => {
    query('p_QUIZCITY_ResouresQuestion_Del', { id: id }).then((res) => {
      message.success(i18next.t('delete success', { field: '' }));
      onRefreshQuestionList();
    });
  };

  return (
    <Container name='Quản lý câu hỏi' parentName='Administration'>
      <Card
        title={i18next.t('QuestionManagement')}
        extra={
          <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', columnGap: 10 }}>
            <DynamicButton onClick={() => setSelectedQuestion({})}>
              <PlusOutlined />
              {i18next.t('addQuestion')}
            </DynamicButton>
            <DynamicButton onClick={() => setModalStatus(true)}>
              <PlusOutlined />
              {i18next.t('ImportQuestionExcel')}
            </DynamicButton>
          </div>
        }
      >
        <Modal
          title={i18next.t('ImportQuestionExcel')}
          footer={null}
          width={'80%'}
          open={modalStatus}
          onCancel={() => userCallbackCancelModal()}
          destroyOnClose
          centered
        >
          <ViewImportQuestion buttonCloseModal={userCallbackCancelModal} />
        </Modal>

        <Spin spinning={isLoading}>
          <SearchDataQuestion
            key={`search-question-form`}
            isLoading={isLoading}
            initialValues={searchParameters}
            onFinish={onSearchQuestion}
          />
          {listSelectQuestion?.length > 0 && (
            <Popconfirm
              placement='topRight'
              title={() => {
                return (
                  <div>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>
                      {i18next.t('Are you sure to delete those', {
                        object: i18next.t('all selected {object}', {
                          object: i18next.t('Questions').toLowerCase(),
                        }),
                      })}
                    </p>
                    <strong style={{ color: '#f5222d' }}>({i18next.t("Data won't be restored after deleting")})</strong>
                  </div>
                );
              }}
              onConfirm={() => {
                removeMultipleQuestion();
              }}
              onCancel={() => {}}
              okText={i18next.t('Confirm')}
              cancelText={i18next.t('Close')}
            >
              <DynamicButton
                style={{ float: 'right', marginBottom: 5 }}
                type='dashed'
                onClick={() => {}}
                loading={isLoadingRemoveQuestion}
                danger
                icon={<DeleteOutlined />}
              >
                {i18next.t('Delete')}
              </DynamicButton>
            </Popconfirm>
          )}
          <DynamicTable
            loading={isLoading}
            dataSource={dataQuestion}
            initialTableData={tableAllQuestion}
            extraColumns={[
              {
                title: ' ',
                key: 'edit',
                dataIndex: 'edit',
                width: '5%',
                sort: false,
                render: (text, record) => {
                  return (
                    <Space>
                      <Tooltip placement='top' title={i18next.t('Update')}>
                        <Button
                          key='edit-file'
                          shape='circle'
                          type='dashed'
                          icon={<EditOutlined />}
                          onClick={() => {
                            setSelectedQuestion({ ...record });
                          }}
                        />
                      </Tooltip>
                      <Popconfirm
                        placement='topRight'
                        title={() => {
                          return (
                            <div>
                              <p style={{ fontSize: 14, fontWeight: 400 }}>
                                {' '}
                                {i18next.t('Are you sure to delete', { object: i18next.t('question') })}
                              </p>
                            </div>
                          );
                        }}
                        onConfirm={() => {
                          onRemove(record?.id);
                        }}
                        okText={i18next.t('Confirm')}
                        cancelText={<span>{i18next.t('Close')}</span>}
                      >
                        <Tooltip placement='top' title={i18next.t('Delete')}>
                          <Button
                            key='edit-file'
                            shape='circle'
                            type='dashed'
                            danger
                            style={{ color: '#f5222d' }}
                            icon={<DeleteOutlined />}
                          />
                        </Tooltip>
                      </Popconfirm>
                    </Space>
                  );
                },
              },
            ]}
            className='components-table-demo-nested'
            pagination={{ defaultPageSize: 10 }}
            rowSelection={rowSelectionStudentsTable}
          />
        </Spin>
      </Card>
      <InsertUpdateQuestion record={selectedQuestion} onRefreshData={onRefreshQuestionList} />
    </Container>
  );
};

export default IndexImportQuestion;
