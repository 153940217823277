import _ from 'lodash';
import React from 'react';
import { Badge, CardImg, CardBody, Card, CardTitle, CardText } from 'reactstrap';
import { Tooltip } from 'antd';
import { cdnServerUrl } from 'constants/serverUrls';
import FileUpdating from '../FileProcessing/FileUpdating';
import FileDeleting from '../FileProcessing/FileDeleting';
import FileDownloading from '../FileProcessing/FileDownloading';
import FilePreview from '../FileProcessing/FilePreview';
import FileMoving from '../FileProcessing/FileMoving';
import i18next from 'i18next';

// Utility: Extract the file name without extension and it's extension
const extractFileNameAndExtension = (fileString) => {
  const lastIndexOfDot = fileString ? fileString.trim().lastIndexOf('.') : -1;
  let extension = '';
  let fileNameWithoutExtension = '';
  if (lastIndexOfDot !== -1) {
    extension = fileString.slice(lastIndexOfDot + 1);
    fileNameWithoutExtension = fileString.slice(0, lastIndexOfDot);
  }

  return [fileNameWithoutExtension, extension];
};

const FileView = ({ data, KEY_SHARE_ATTACHMENTS, folders, selectedFolderId, loggedInUser }) => {
  const files = data?.map((e) => ({
    id: e.id,
    title: extractFileNameAndExtension(e.fileName)[0],
    fileType: extractFileNameAndExtension(e.fileName)[1],
    downloadUrl: `${cdnServerUrl}${e.fileUrl}`,
    imageUrl: e.imageUrl,
    subTitle: e.description,
    fileName: e.fileName,
    fileUrl: e.fileUrl,
    contentType: e.contentType,
    entityId: e.entityId,
  }));

  const renderCardItem = React.useCallback(
    ({ id, imageUrl, downloadUrl, title, subTitle, fileType, fileName, fileUrl, contentType, entityId }) => {
      const checkType = contentType.indexOf('application/vnd');
      return (
        <Card key={id} className='md:tw-w-64 md:tw-mr-5 lg:tw-w-80 lg:tw-mr-10'>
          {imageUrl && (
            <CardImg
              onClick={() => {
                if (checkType === -1) window.open(downloadUrl, '_blank');
              }}
              style={{ cursor: 'pointer' }}
              alt={title}
              src={imageUrl}
            />
          )}

          <CardBody>
            <CardTitle
              onClick={() => window.open(downloadUrl, '_blank')}
              style={{ cursor: 'pointer' }}
              className='mb-3'
              tag='h3'
            >
              <Tooltip title={title}>
                {_.truncate(`${title}`, {
                  length: 50,
                  omission: '...',
                })}
              </Tooltip>
            </CardTitle>
            <CardText className='mb-4'>{subTitle}</CardText>
            <div className='mt-2'>
              <div>
                <Badge className='tw-mr-2' color='secondary'>
                  {fileType}
                </Badge>
              </div>
              {loggedInUser?.role !== 'student' && (
                <div className='tw-mt-5 tw-flex tw-items-start tw-gap-x-1 t tw-flex-wrap tw-gap-y-2 '>
                  {contentType.indexOf('application/vnd') === -1 && (
                    <FilePreview cdnServerUrl={cdnServerUrl} fileUrl={fileUrl} toolTipTitle={i18next.t('Preview')} />
                  )}
                  <FileDownloading downloadUrl={downloadUrl} toolTipTitle={i18next.t('Download')} fileName={fileName} />
                  <FileUpdating id={id} toolTipTitle={i18next.t('Edit')} />
                  <FileMoving
                    KEY_SHARE_ATTACHMENTS={KEY_SHARE_ATTACHMENTS}
                    id={id}
                    entityId={entityId}
                    folders={folders}
                    toolTipTitle={i18next.t('Move')}
                  />
                  <FileDeleting
                    id={id}
                    selectedFolderId={selectedFolderId}
                    fileName={fileName}
                    KEY_SHARE_ATTACHMENTS={KEY_SHARE_ATTACHMENTS}
                    toolTipTitle={i18next.t('Delete')}
                  />
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      );
    },
    [],
  );

  return (
    <div className='tw-flex tw-flex-wrap'>
      {files &&
        files.length > 0 &&
        files.map((item) => {
          return renderCardItem(item);
        })}
    </div>
  );
};

export default FileView;
