import _ from 'lodash';
import publicRoutes from './publicRoutes';
import studentRoutes from './studentRoutes';
import teacherRoutes from './teacherRoutes';
import adminRoutes from './adminRoutes';
import managerRoutes from './managerRoutes';

const lgInnotekRoutes = _.concat(publicRoutes, studentRoutes, teacherRoutes, managerRoutes, adminRoutes)

export default lgInnotekRoutes;
