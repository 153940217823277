import React from 'react';

export default function QuestionHeader({ title, subTitle, questionIndex, tooltip }) {
  const { href } = window.location;
  const dev = href.includes('localhost');
  return (
    <div className='d-flex'>
      <div className='d-flex justify-content-center align-items-center mr-3 bg-secondary text-default rounded-circle border border-default' style={{ height: 48, width: 48, fontWeight: 600 }}>
        {questionIndex}
      </div>
      <div>
        {dev && <h3>{`Only dev display: ${tooltip}`}</h3>}
        <div className='title'>{title}</div>
        <div className='subTitle'>{subTitle}</div>
      </div>
    </div>
  );
}
