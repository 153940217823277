import { notification } from 'antd';
import axios from 'axios';
import { cdnServerUrl, sachsoServerUrl } from 'constants/serverUrls';
import SETTINGS from 'constants/settings';
import _ from 'lodash';

const authorizationKey = 'Basic 12C1F7EF9AC8E288FBC2177B7F54D';
const { APPLICATION_NAME, MODULE_ID } = SETTINGS;

function getById(
  entityName: string,
  fields: string,
  id: string,
  subModuleName: string = '',
  moduleName: string = 'SACHSO',
  applicationName: string = APPLICATION_NAME,
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      method: 'OPTIONS',
      url: `${sachsoServerUrl}/api/v1.0/dynamic/crud`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        moduleName,
        subModuleName,
        entityName,
        fields,
        parameters: { id, moduleId: MODULE_ID },
      },
    })
      .then((response) => {
        resolve(_.first(response.data.results));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getByEntityId(
  entityName: string,
  fields: string,
  entityId: any,
  subModuleName: string = '',
  moduleName: string = 'SACHSO',
  applicationName: string = APPLICATION_NAME,
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      method: 'OPTIONS',
      url: `${sachsoServerUrl}/api/v1.0/dynamic/crud`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorizationKey,
        ApplicationName: APPLICATION_NAME,
      },
      data: {
        moduleName,
        subModuleName,
        entityName,
        fields,
        parameters: entityId,
      },
    })
      .then((response) => {
        resolve(_.first(response.data.results));
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function get(
  entityName: string,
  fields: string,
  parameters: Object,
  sort: string,
  subModuleName: string = '',
  moduleName: string = 'SACHSO',
  applicationName: string = APPLICATION_NAME,
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      method: 'OPTIONS',
      url: `${sachsoServerUrl}/api/v1.0/dynamic/crud`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        moduleName,
        subModuleName,
        entityName,
        fields,
        parameters: { ...parameters, ...{ moduleId: MODULE_ID } },
        // parameters,
        sort,
      },
    })
      .then((response) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
function getWithoutModuleId(
  entityName: string,
  fields: string,
  parameters: Object,
  sort: string,
  subModuleName: string = '',
  moduleName: string = 'SACHSO',
  applicationName: string = APPLICATION_NAME,
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      method: 'OPTIONS',
      url: `${sachsoServerUrl}/api/v1.0/dynamic/crud`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        moduleName,
        subModuleName,
        entityName,
        fields,
        parameters: { ...parameters },
        // parameters,
        sort,
      },
    })
      .then((response) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function add(
  entityName: string,
  parameters: Object,
  subModuleName: string = '',
  moduleName: string = 'SACHSO',
  applicationName: string = APPLICATION_NAME,
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/crud`,
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: APPLICATION_NAME,
      },
      data: {
        moduleName,
        subModuleName,
        entityName,
        parameters: { ...parameters, ...{ moduleId: MODULE_ID } },
      },
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function update(
  entityName: string,
  entityId: string,
  parameters: Object,
  subModuleName: string = '',
  moduleName: string = 'VIMARU',
  applicationName: string = APPLICATION_NAME,
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/crud`,
      method: 'PUT',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: APPLICATION_NAME,
      },
      data: {
        moduleName,
        subModuleName,
        entityName,
        entityId,
        parameters: { ...parameters, ...{ moduleId: MODULE_ID } },
      },
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function remove(
  entityName: string,
  entityId: string,
  subModuleName: string = '',
  moduleName: string = 'SACHSO',
  applicationName: string = APPLICATION_NAME,
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/crud`,
      method: 'DELETE',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: APPLICATION_NAME,
      },
      data: {
        moduleName,
        subModuleName,
        entityName,
        entityId,
      },
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deleteFile(
  entityName: string,
  entityId: string,
  fileName: string,
  subModuleName: string = '',
  moduleName: string = 'SACHSO',
  applicationName: string = APPLICATION_NAME,
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/files`,
      method: 'DELETE',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: APPLICATION_NAME,
      },
      data: {
        entityName,
        entityId,
        fileName,
        moduleName,
        subModuleName,
        applicationName,
      },
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deleteVideoByUrl(url:string) {
  if (url) {
    const filePath = url.replace(`${cdnServerUrl}/storage/`, '');
    const bodyFormData = new FormData();
    bodyFormData.append('filePath', filePath);
    return axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/del-file`,
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: APPLICATION_NAME,
        'Content-Type': 'multipart/form-data',
      },
      data: bodyFormData,
    });
  } else Promise.reject('Thiếu filePath');
}

function execute(sqlCommand: string, parameters = {}, applicationName = APPLICATION_NAME): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/execute`,
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters: { ...parameters, ...{ moduleId: MODULE_ID } },
      },
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
function executeWithoutModuleId(sqlCommand: string, parameters = {}, applicationName = APPLICATION_NAME): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/execute`,
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters,
      },
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function query(sqlCommand: string, parameters = {}, applicationName = APPLICATION_NAME, isUseModuleId = true): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/query`,
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters: { ...parameters, ...{ moduleId: isUseModuleId ? MODULE_ID : undefined } },
      },
    })
      .then((response) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function queryWithCached(
  sqlCommand: string,
  parameters = {},
  cached = { cachedKey: 'X2', cachedTime: 300 },
  applicationName = APPLICATION_NAME,
): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/query`,
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
        'X-Cached-Key': cached.cachedKey, // key để xác định bản cached
        'X-Cached-AbsoluteExpiration': cached.cachedTime, // thời gian tuyệt đối bản cashed sẽ hết hạn (đơn vị giây)
        'X-Cached-SlidingExpiration': cached.cachedTime, // thời gian trượt (đơn vị giây) - Không được truy cập trong khoảng thời gian này thì bản cached sẽ hết hạn
        'X-Cached-Enabled': true, // trạng thái lây cache hoặc không
      },
      data: {
        sqlCommand,
        parameters: { ...parameters, ...{ moduleId: MODULE_ID } },
      },
    })
      .then((response) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function queryWithoutModuleId(sqlCommand: string, parameters = {}, applicationName = APPLICATION_NAME): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/query`,
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters: { ...parameters },
        // parameters,
      },
    })
      .then((response) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function queryFirst(sqlCommand: string, parameters = {}, applicationName = APPLICATION_NAME): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/query/first`,
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters: { ...parameters, ...{ moduleId: MODULE_ID } },
      },
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const token =
  'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZW5hbWUiOiJzYWNoc28ifSwiaWF0IjoxNjIzOTQ3NTE5LCJleHAiOjE3ODE2Mjc1MTksImF1ZCI6IndlYmhvb2suc29mdGVjaC5jbG91ZCIsImlzcyI6InNvZnRlY2guY2xvdWQiLCJzdWIiOiJzYWNoc28ifQ.g5cijByPRAaLxHp1kv1YXrtlMbKvJjQtpaQTqmpo24hVyw9P7rMH8lLYskfmsT3tSkVbmew7SihaBns2ILcGTQ';

const sachsoClient = axios.create({
  baseURL: 'https://webhook.softech.cloud/api/sachso',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Platform: 'WEB',
    ApplicationName: 'sachso.edu.vn',
    Authorization: token,
  },
  // dataResultWorksheet,
});

function nosql_get(entityName: string, applicationName: string = APPLICATION_NAME) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: 'https://webhook.softech.cloud/api/sachso/collections',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Platform: 'WEB',
        ApplicationName: applicationName,
        Authorization: token,
        collection: entityName,
      },
    })
      .then((response) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function uploadAudio(parameters: any = {}, applicationName: string = APPLICATION_NAME) {
  let bodyFormData = new FormData();
  bodyFormData.append('input', parameters.input);
  bodyFormData.append('extensionInput', parameters.extensionInput);
  bodyFormData.append('studentId', parameters.studentId);
  bodyFormData.append('questionId', parameters.questionId);
  bodyFormData.append('moduleId', MODULE_ID);
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://cloud.softech.cloud/mobile/ames/api/v2.0/uploadFileRecord',
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: bodyFormData,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function amesSendMail(url: string, data: any, applicationName = APPLICATION_NAME): Promise<any> {
  return new Promise<any>(async (resolve, reject) => {
    const res = await axios({
      url: `https://toeic.ames.edu.vn/ames/api/amesapi/${url}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: authorizationKey,
        ApplicationName: applicationName,
        'Content-Type': 'application/json',
      },
      data,
    });

    if (res?.data?.Message === 'OK') {
      return resolve(res?.data);
    } else {
      notification.error({
        message: 'Gửi mail thất bại!',
        description: 'đến địa chỉ ' + data.to,
        duration: 5,
      });
      return reject(0);
    }
  });
}
export {
  add,
  amesSendMail,
  authorizationKey,
  deleteFile,
  deleteVideoByUrl,
  execute,
  executeWithoutModuleId,
  get,
  getByEntityId,
  getById,
  getWithoutModuleId,
  nosql_get,
  query,
  queryFirst,
  queryWithCached,
  queryWithoutModuleId,
  remove,
  update,
  uploadAudio,
};
