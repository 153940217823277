import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';

const deleteNotificationManagement = (Id: number) => {
  const sqlCommand = 'p_QUIZCITY_NotificationManagement_DEL';
  const parameters = { Id };
  const applicationName = settings.APPLICATION_NAME;
  return query(sqlCommand, parameters, applicationName);
};

export default deleteNotificationManagement;
