import _ from 'lodash';
import tvhhAdminRoutes from './tvhhAdminRoutes';
import tvhhManagerRoutes from './tvhhManagerRoutes';
import tvhhPublicRoutes from './tvhhPublicRoutes';
import tvhhStudentRoutes from './tvhhStudentRoutes';
import tvhhTeacherRoutes from './tvhhTeacherRoutes';

const tvhhRoutes = _.concat(tvhhPublicRoutes, tvhhStudentRoutes, tvhhTeacherRoutes, tvhhManagerRoutes, tvhhAdminRoutes);

export default tvhhRoutes;
