import colors from 'constants/colors';
import settings from 'constants/settings';
import i18next from 'i18next';
import { removeAccents } from 'services/Text/formatText';

export default {
  appName: settings.APPLICATION_NAME,
  formFields: [
    // {
    //   label: 'Khối lớp',
    //   name: 'courseId',
    //   initialValue: null,
    //   hasFeedback: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: 'Chưa chọn khối lớp',
    //     },
    //   ],
    //   inputComponent: {
    //     type: 'Select',
    //     dataSource: 'p_SACHSO_Courses_OPTIONS',
    //     dataSourceAppName: 'Sgk',
    //     props: {
    //       style: {},
    //     },
    //   },
    // },
    {
      hasFeedback: true,
      initialValue: '',
      inputComponent: {
        props: {
          // style: { color: colors.font },
        },
        type: 'Input',
      },
      label: i18next.t('class:label.class name'),
      name: 'className',
      rules: [
        {
          message: i18next.t('please enter', { field: i18next.t('className') }),
          required: true,
        },
      ],
    },
    {
      initialValue: '',
      inputComponent: {
        props: {
          style: { color: colors.font },
        },
        type: 'Input',
      },
      label: i18next.t('class:label.class note'),
      name: 'note',
    },
    {
      label: i18next.t('Status'),
      name: 'classState',
      // initialValue: currentCourse?.id,
      rules: [
        {
          required: true,
          message: i18next.t('please choose', { field: i18next.t('Status') }),
        },
      ],
      inputComponent: {
        type: 'Select',
        props: {
          showSearch: true,
          allowClear: true,
          optionFilterProp: 'children',
          filterOption: (input, option) => removeAccents(option?.label)?.includes(removeAccents(input)),
          // style: { width: 120 },
          style: { color: colors.font },
          options: [
            { label: i18next.t('Studying'), value: 'InProgress' },
            { label: i18next.t('Finished'), value: 'Finish' },
          ],
        },
      },
    },
  ],
  layout: {
    type: 'vertical',
  },
  requiredMark: false,
  storedProcedure: 'p_VIMARU_CLASSES_UpdateClass',
  submitButtonText: i18next.t('Update'),
  tailLayout: {},
};
