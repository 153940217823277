import { ImportOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { ExcelRenderer } from 'react-excel-renderer';
import React, { useMemo, useState } from 'react';
import { Button, Modal, Space, Spin, Table, Upload, message } from 'antd';
import { useSelector } from 'react-redux';
import { tableColumns } from './data';
import { enterOffLinePTScore } from '../../api';
import { convertToSlug } from 'services/Text/formatText';

type Props = {
  onSuccess: Function;
};

type RowProps = {
  index: number;
  ptCode: string;
  fullName: string;
  listeningScore: number | string;
  speakingScore: number | string;
  readingScore: number | string;
  writingScore: number | string;
  color?: 'red' | 'green';
  status?: string;
};

const ImportExcel_OfflineScore = ({ onSuccess }: Props) => {
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [rows, setRows] = useState<RowProps[]>([]);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [isLoadingUploadFile, setLoadingUploadFile] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const excelTemplateUrl = `/templates/excel/EnterOfflineScore_PT_Template.xlsx`;

  const tableKey = useMemo(() => {
    if (rows && rows?.length > 0) {
      const stringArr = rows.map((item) => item.status);
      return stringArr.toString();
    } else {
      return 'question-table-excel-data';
    }
  }, [rows]);

  const onCloseModal = () => {
    setVisibleModal(false);
  };
  //Xoá file đã trong <Upload/>
  const handlerRemove = () => {
    setRows([]);
    setIsDisableSubmit(true);
  };

  const formatValueScore = (value: any): number | string => {
    //Nếu không nhập dữ liệu thì mặc định điểm là 0.
    if (!value) {
      return 0;
    }
    //Xử lý điểm có dấu phẩy
    const newValue = String(value)?.replace(',', '.');
    const parseValue = Number(newValue)
    if (typeof parseValue === 'number' && !isNaN(parseValue)) {
      return parseValue?.toFixed(2);
    } else {
      return -1;
    }
  };

  const fileHandler = (fileList: any) => {
    setRows([]);
    setLoadingUploadFile(true);
    setIsDisableSubmit(true);
    const fileObj = fileList;
    if (!fileObj) {
      message.error('Không có tệp nào được tải lên!');
      setLoadingUploadFile(false);
    }
    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      message.error('Định dạng tập tin không xác định. Chỉ các tệp Excel được tải lên!');
      setLoadingUploadFile(false);
    }
    ExcelRenderer(fileObj, async (err: any, resp: any) => {
      if (err) {
        message.error('Có lỗi xảy ra trong quá trình đọc tệp excel');
      } else {
        const header = resp?.rows?.slice(0, 2);
        //Kiểm tra temlate có đúng không dựa theo 2 dòng đầu tiên
        const columnName1 = ['STT', 'Mã tham gia', 'Họ và tên', 'Điểm', null, null, null, null, 'Ghi chú'];
        const columnName2 = [null, null, null, 'Nghe', 'Nói', 'Đọc', 'Viết'];
        const isCorrectTemplate =
          columnName1.every((item, index) => {
            return !item || convertToSlug(item) === convertToSlug(header?.[0]?.[index]);
          }) &&
          columnName2.every((item, index) => {
            return !item || convertToSlug(item) === convertToSlug(header?.[1]?.[index]);
          });
        if (!isCorrectTemplate) {
          message.error('Không đúng template. Vui lòng tải mẫu mới nhất.');
          setLoadingUploadFile(false);
          return;
        }
        //Loại bỏ các dòng không có dữ liệu
        const removeEmptyRow = resp?.rows?.slice(2)?.filter((item: any) => item.length > 0) ?? [];

        if (removeEmptyRow.length === 0) {
          message.error('Không có dữ liệu nào được tìm thấy.');
          setLoadingUploadFile(false);
          return;
        }
        //Chuyển dữ liệu excel dạng lưới thành mảng object và đánh dấu index (index để xử lý lấy đáp án)
        const tickIndex = removeEmptyRow.map((row: any[], index: number) => ({
          index: index+1,
          ptCode: String(row[1] ?? '')?.replaceAll(' ',''),
          fullName: String(row[2] ?? '')?.trim() ?? '',
          listeningScore: row[3],
          speakingScore: row[4],
          readingScore: row[5],
          writingScore: row[6],
        }));
        //Xử lý lấy đáp án cho câu hỏi
        const promises = tickIndex.map(
          (row: RowProps) =>
            new Promise(async (resolve, reject) => {
              if (!row.ptCode) {
                return resolve({ data: row, error: 'Thiếu mã tham gia' });
              }
              const formatScoreL = formatValueScore(row.listeningScore);
              if (formatScoreL === -1) {
                return resolve({ data: row, error: 'Điểm nghe không đúng định dạng' });
              }
              const formatScoreS = formatValueScore(row.speakingScore);
              if (formatScoreS === -1) {
                return resolve({ data: row, error: 'Điểm nói không đúng định dạng' });
              }
              const formatScoreR = formatValueScore(row.readingScore);
              if (formatScoreR === -1) {
                return resolve({ data: row, error: 'Điểm đọc không đúng định dạng' });
              }
              const formatScoreW = formatValueScore(row.writingScore);
              if (formatScoreW === -1) {
                return resolve({ data: row, error: 'Điểm viết không đúng định dạng' });
              }
              const newValue: RowProps = {
                ...row,
                listeningScore: formatScoreL,
                speakingScore: formatScoreS,
                readingScore: formatScoreR,
                writingScore: formatScoreW,
              };

              return resolve({ data: newValue });
            }),
        );

        Promise.all(promises)
          .then((results) => {
            const newRows :RowProps[] = results.map((row) => {
              //kết quả trả về nếu có error thì là có lỗi xảy ra
              const { data, error } = row;
              return {
                ...data,
                color: error ? 'red' : 'green',
                status: error ? error : 'Hợp lệ',
              };
            });
            setRows(newRows);
          })
          .finally(() => {
            setIsDisableSubmit(false);
            setLoadingUploadFile(false);
          });
      }
    });
    // chặn hành vi upload mặc định của <Upload/>
    return false;
  };

  const handleSubmit = () => {
    setLoadingUploadFile(true);
    const promises = rows?.map(
      (row: RowProps) =>
        new Promise((resolve) => {
          try {
            //nếu dữ liệu lỗi thì không lưu
            if (row.color !== 'green') {
              return resolve(row);
            }
            enterOffLinePTScore({
              PTCode: row.ptCode,
              ListenScoreOffline: row.listeningScore,
              SpeakScoreOffline: row.speakingScore,
              ReadScoreOffline: row.readingScore,
              WriteScoreOffline: row.writingScore,
              UpdateBy: loggedInUser?.id,
            })
              .then((res) => {
                resolve({
                  ...row,
                  status: 'Lưu thành công',
                  color: 'green',
                });
              })
              .catch((err) => {
                resolve({
                  ...row,
                  status: err?.message ??  'Lưu thất bại',
                  color: 'red',
                });
              });
          } catch (error) {
            resolve({
              ...row,
              status: 'Lưu thất bại',
              color: 'red',
            });
          }
        }),
    );
    Promise.all(promises).then((results: any[]) => {
      message.success('Hoàn tất lưu dữ liệu!');
      setRows(results);
      setIsDisableSubmit(true);
      setLoadingUploadFile(false);
      onSuccess?.();
    });
  };

  return (
    <>
      <Button type='primary' onClick={() => setVisibleModal(true)}>
        <ImportOutlined /> Nhập điểm offline từ excel
      </Button>

      <Modal
        title='NHẬP ĐIỂM OFFLINE TỪ EXCEL'
        width={'95%'}
        open={visibleModal}
        centered
        cancelText='Đóng'
        onCancel={onCloseModal}
        okText='Lưu'
        onOk={handleSubmit}
        okButtonProps={{ disabled: isDisableSubmit, loading: isLoadingUploadFile }}
        bodyStyle={{ maxHeight: 'calc( 100vh - 120px )', overflowY: 'auto' }}
      >
        <Spin spinning={isLoadingUploadFile} tip={rows?.length > 0 ? 'Đang lưu dữ liệu' : "Đang tải dữ liệu từ excel"}>
          <Space>
            <Upload accept='.xlsx' name='file' beforeUpload={fileHandler} onRemove={handlerRemove} maxCount={1}>
              <Button icon={<UploadOutlined />}>Tải lên</Button>
            </Upload>
          </Space>
          <a href={excelTemplateUrl} download style={{ marginLeft: 10 }}>
            <ins>Tải tệp mẫu</ins>
          </a>
          <div style={{ marginTop: 20 }}>
            <Table rowKey='index' key={tableKey} dataSource={rows} columns={tableColumns} bordered/>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default ImportExcel_OfflineScore;
