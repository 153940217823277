import { Tooltip, Button, Modal, Form, Input, InputNumber, Row, Col, Upload, message } from 'antd';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import ColorPicker from 'components/ColorPicker';
import { query } from 'helpers/QueryHelper';
import { useQuery, useQueryClient } from 'react-query';

import createImageUploadingProps from './imageUploadingProps';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import settings from 'constants/settings';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const FileUpdating = ({ id, buttonText, buttonIcon, buttonType, buttonShape, toolTipTitle, toolTipPlacement }) => {
  const { t } = useTranslation('');
  const [isOpen, setIsOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState(''); // Used for image uploading
  const [fileExtension, setFileExtension] = useState('');

  const queryClient = useQueryClient();

  // Antd Form
  const [form] = Form.useForm();
  const Item = Form.Item;

  // Image Uploading Props
  const imageUploadingProps = createImageUploadingProps(setImgUrl, form, message);

  const extractFileNameAndExtension = (fileString) => {
    const lastIndexOfDot = fileString ? fileString.lastIndexOf('.') : -1;
    let extension = '';
    let fileNameWithoutExtension = '';
    if (lastIndexOfDot !== -1) {
      extension = fileString.slice(lastIndexOfDot + 1);
      fileNameWithoutExtension = fileString.slice(0, lastIndexOfDot);
    }

    return [fileNameWithoutExtension, extension];
  };

  // Fetch data
  const queryKey = ['p_QUIZCITY_Libraries_Folder_GetFile', id];
  const fetchFile = (id) => {
    return query('p_QUIZCITY_Libraries_Folder_GetFile', { id });
  };
  const { data } = useQuery(queryKey, () => fetchFile(id), { enabled: isOpen });

  // Handle form submit
  const onFinish = (values) => {
    const parameters = { ...values, fileName: `${values.fileName}.${fileExtension}`, id };
    query('dbo.p_QUIZCITY_Libraries_Folder_UpdateFile', parameters)
      .then((res) => {
        message.success(i18next.t(i18next.t('update success', { field: i18next.t('data') })));
        queryClient.invalidateQueries('t_SACHSO_SHARE_Attachments');
      })
      .catch((error) => {
        message.error(i18next.t('popconfirm.oops'));
        console.log('error:', error);
      });
  };

  useEffect(() => {
    const fileNameWithoutExtension = extractFileNameAndExtension(data?.[0]?.fileName)?.[0];
    const extension = extractFileNameAndExtension(data?.[0]?.fileName)?.[1];
    setFileExtension(extension);

    // Set initial values for form items
    const initialValues = {
      fileName: fileNameWithoutExtension,
      imageUrl: data?.[0].imageUrl ?? '',
      sortOrder: data?.[0].sortOrder ?? 0,
      color: data?.[0]?.color ?? '#fff',
      description: data?.[0]?.description,
    };

    if (isOpen) {
      form.setFieldsValue(initialValues);
      setImgUrl(data?.[0].imageUrl);
    }
  }, [form, isOpen, data]);

  // Create a button which used to open an modal
  const createButton = (buttonText, buttonIcon, buttonType, buttonShape) => (
    <Button
      icon={buttonIcon}
      type={buttonType}
      shape={buttonShape}
      onClick={() => {
        setIsOpen(true);
      }}
    >
      {buttonText}
    </Button>
  );

  return (
    <>
      {toolTipTitle ? (
        <Tooltip title={toolTipTitle} placement={toolTipPlacement}>
          {createButton(buttonText, buttonIcon, buttonType, buttonShape)}
        </Tooltip>
      ) : (
        createButton(buttonText, buttonIcon, buttonType, buttonShape)
      )}

      <Modal
        maskClosable={false}
        centered
        title={i18next.t('Update').toUpperCase()}
        open={isOpen}
        onOk={() => {
          form.submit();
          setIsOpen(false);
        }}
        onCancel={() => setIsOpen(false)}
        okText={t('Confirm')}
        cancelText={t('Close')}
      >
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <Item
            label={i18next.t('table:reference.File name')}
            name='fileName'
            rules={[
              {
                required: true,
                message: t('please enter', { field: t('fileName') }),
              },
            ]}
          >
            <Input />
          </Item>

          {/* Image Uploading */}
          <Row gutter={[12, 12]}>
            <Col lg={16} xs={24}>
              <Item name={'imageUrl'} label={`Image Url` + i18next.t('reference:fileSize')}>
                <Input placeholder='https://' />
              </Item>
            </Col>
            <Col lg={8}>
              <Item label={` `}>
                <Upload {...imageUploadingProps}>
                  <DynamicButton block icon={<UploadOutlined />}>
                    {i18next.t('uploadImage')}
                  </DynamicButton>
                </Upload>
              </Item>
            </Col>
            {(imgUrl || form.getFieldsValue()?.imageUrl) && (
              <img src={imgUrl || form.getFieldsValue()?.imageUrl} style={{ width: 200, marginBottom: 20 }} />
            )}
          </Row>

          <Item
            label={i18next.t('pages:lectureManagement.Order')}
            name='sortOrder'
            rules={[{ required: true, message: i18next.t('please enter', { field: t('sortOrder') }) }]}
          >
            <InputNumber min={0} />
          </Item>

          <Item label={i18next.t('commonTitle.Color')} name='color'>
            <ColorPicker />
          </Item>

          <Item label={i18next.t('Notes')} name='description'>
            <Input.TextArea rows={4} />
          </Item>
        </Form>
      </Modal>
    </>
  );
};

FileUpdating.defaultProps = {
  id: '',
  buttonText: '',
  buttonIcon: <EditOutlined />,
  buttonType: 'primary',
  buttonShape: 'default',
  toolTipTitle: '',
  toolTipPlacement: 'top',
};

export default FileUpdating;
