import { Card, CardBody, CardFooter, CardImg } from 'reactstrap';
import { Button, Tooltip, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { get, remove } from 'helpers/QueryHelper';
import { useQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import settings from 'constants/settings';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// const FolderItem = ({ item, onClick, displayDescription = true }) => {
// const FolderItem = ({ item, onClick }) => {
// const FolderItem = ({ item, onClick, setUpdateMode, setUpdateModalVisible }) => {
// const FolderItem = ({ item, onClick, setUpdateMode, setUpdateModalVisible, setSelectedFolder }) => {
const defaultImg = 'https://cloud.softech.vn/storage/Data/MENARD/Carousel/image2.jpg';
const FolderItem = ({
  item,
  onClick,
  setUpdateMode,
  setUpdateModalVisible,
  setSelectedFolder,
  setReloadFolders,
  setSelectedFolderId,
  loggedInUser,
}) => {
  const { t } = useTranslation('');
  const { name, imageUrl, description } = item;
  const queryClient = useQueryClient();

  return (
    <>
      <Card style={{ cursor: 'pointer' }}>
        <CardImg
          alt={' Chưa có hình ảnh'}
          src={imageUrl || defaultImg}
          top
          onClick={() => {
            setSelectedFolder(item);
            setSelectedFolderId(item.id);
          }}
        />
        <CardBody>
          <div className='text-center'>
            <h4>{`${name}`}</h4>
            <small>{description}</small>
            {loggedInUser?.role !== 'student' && (
              <div
                style={{ justifyContent: 'space-between' }}
                className='tw-mt-5 tw-flex tw-items-start tw-gap-x-1 t tw-flex-wrap tw-gap-y-2 '
              >
                <Button
                  icon={<EditOutlined />}
                  type='primary'
                  onClick={() => {
                    setSelectedFolder(item);
                    setUpdateMode('edit');
                    setUpdateModalVisible(true);
                  }}
                >
                  {i18next.t('Save')}
                </Button>
                <Popconfirm
                  title={i18next.t('Are you sure to delete', { object: t('Folder') })}
                  onConfirm={async () => {
                    await remove('Folders', item.id, 'LIBRARIES');
                    setReloadFolders(new Date());
                  }}
                  okText={t('Confirm')}
                  cancelText={t('Close')}
                >
                  <Button icon={<DeleteOutlined />} type='primary' danger>
                    {i18next.t('Delete')}
                  </Button>
                </Popconfirm>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default FolderItem;
