import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Tooltip, notification } from 'antd';
import locale from 'antd/es/date-picker/locale/en_US';
import TextArea from 'antd/lib/input/TextArea';
import Card from 'components/Containers/Card';
import colors from 'constants/colors';
import settings from 'constants/settings';
import { sendNotificationToUsersWithRole } from 'helpers/NotificationHelper';
import { amesSendMail, query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import {
  CoachingDateTag,
  FinishedCoachingDateTag,
  RenderBookingDateTags,
  formatDate,
  statusRequest,
  statusValues,
} from 'pages/Administration/ManagerCoachingSchedule/tableColumn';
import getSettings from 'pages/Administration/api/getSettings';
import { useState } from 'react';
import { BsQuestionLg } from 'react-icons/bs';
import { GiCancel } from 'react-icons/gi';
import { GrView } from 'react-icons/gr';
import { useQuery, useQueryClient } from 'react-query';
import { allResultsBookingColumn, resultsBookingColumn } from './tableColumn';
import { useSelector } from 'react-redux';

function CoachingForm({ loggedInUser, setCoachingGuideModalVisible, reportHeader }) {
  const [form] = Form.useForm();
  const [formConfirm] = Form.useForm();
  const [formCancel] = Form.useForm();
  const [isProcessing, setIsProcessing] = useState(false); // loading
  const [visibleAllResults, setVisibleAllResults] = useState(false); // loading
  const [visibleConfirmModal, setVisibleConfirmModal] = useState({ open: false, record: {} });
  const [visibleCancelBookingModal, setVisibleCancelBookingModal] = useState({ open: false, record: {} });
  const [stateBookingResultsModal, setStateBookingResultsModal] = useState({
    open: false,
    data: {},
  });
  const [onChangeCoachingTime, setonChangeCoachingTime] = useState(['Morning']);
  const placeHolder = `Available time: ${onChangeCoachingTime.includes('Morning') ? '8am - 11am' : ''}${
    onChangeCoachingTime.includes('Morning' && 'Afternoon') ? ', ' : ''
  }${onChangeCoachingTime.includes('Afternoon') ? '2pm - 5pm' : ''}${
    onChangeCoachingTime.includes('Morning' && 'Evening') || onChangeCoachingTime.includes('Afternoon' && 'Evening')
      ? ', '
      : ''
  }${onChangeCoachingTime.includes('Evening') ? '6pm - 9pm' : ''}`;
  const KEY_SELECT_RESULTS_BOOKING = ['p_QUIZCITY_CoachingBooking_Request_GetByStudentId', loggedInUser?.id];
  const { data: resultsBooking, isLoading } = useQuery(
    KEY_SELECT_RESULTS_BOOKING,
    () => query('p_QUIZCITY_CoachingBooking_Request_GetByStudentId', { StudentId: loggedInUser?.id }),
    {
      enabled: loggedInUser?.role === 'student',
    },
  );
  const queryClient = useQueryClient();
  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);
  // Tạm thời chỉ check hoàn thành mudule học 3 với bản Live - preview, không check ở Dev để test phần Coaching comment
  const isCompletedModule3 =
    settings.APPLICATION_NAME === 'QUIZCITY_DEV' ? true : reportHeader?.complete03?.[0]?.value === 100;

  // Chỉ có học sinh mới booking
  // Hoành thành module học thứ 3 mới được booking
  // Khi kết quả booking trước cancel thì mới cho booking tiếp
  const isDisableBooking =
    loggedInUser.role !== 'student' ||
    !isCompletedModule3 ||
    (resultsBooking?.length > 0 && resultsBooking?.some((result) => result?.status !== statusValues.cancel));

  const displayResults = _.sortBy(resultsBooking, ['id']);

  const disabledDate = (current) => {
    // Lấy ngày hiện tại
    // const today = new Date();
    const today = moment().subtract(1, 'days');

    // Chặn việc chọn các ngày trong quá khứ
    return current && current < today;
  };

  const dateRender = (current) => {
    const style = {};
    // Tuỳ chỉnh style cho các ngày không được chọn
    if (disabledDate(current)) {
      // style.backgroundColor = 'rgba(255, 0, 0, 0.1)';
      style.textDecoration = 'line-through';
    }
    return (
      <div className='ant-picker-cell-inner' style={style}>
        {current.date()}
      </div>
    );
  };

  const renderFormItem = (type, form) => {
    const isEdit = type === 'register';
    return (
      <>
        <Row gutter={[12, 12]} wrap={true}>
          <Col xs={24} md={8}>
            <Form.Item
              name='studentCode'
              label='Employee code'
              rules={[
                {
                  whitespace: true,
                  message: i18next.t('pleaseDoNotEnter', { field: i18next.t('newline') }),
                },
                {
                  required: true,
                  message: i18next.t('please enter', { field: i18next.t('code') }),
                },
              ]}
            >
              <Input placeholder={i18next.t('please enter', { field: i18next.t('code') })} readOnly />
            </Form.Item>
          </Col>
          <Col xs={24} md={16}>
            <Form.Item
              name='studentName'
              label='Fullname'
              rules={[
                {
                  whitespace: true,
                  message: i18next.t('pleaseDoNotEnter', { field: i18next.t('newline') }),
                },
                {
                  required: true,
                  message: i18next.t('please enter', { field: i18next.t('fullName') }),
                },
              ]}
            >
              <Input placeholder={i18next.t('please enter', { field: i18next.t('fullName') })} readOnly />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row
          gutter={[12, 12]}
          style={{
            // fontSize: '0.875rem',
            fontWeight: 600,
            marginBottom: 5,
          }}
        >
          <Col span={8}>
            <span> {i18next.t('coachingDate')}</span>
          </Col>
          <Col span={16}>
            <span> {i18next.t('coachingTime')}</span>
          </Col>
        </Row> */}
        <Form.List name='booking'>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div style={{ border: '1px solid #000', borderRadius: 5, padding: 5, marginBottom: 5 }}>
                  <Row gutter={[12, 2]} key={field.key}>
                    <Col span={8}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'dateRequest']}
                        // label='Coaching date'
                        fieldKey={[field.fieldKey, 'dateRequest']}
                        rules={[
                          {
                            required: true,
                            message: 'Please choose coaching date!',
                          },
                          {
                            validator: (_, value) => {
                              // Nếu chưa nhập ngày thì không hiện cảnh báo trùng ngày
                              if (value === null || value === undefined) return Promise.resolve();
                              // Kiểm tra giá trị date không được chọn ngày trong quá khứ
                              // const isPassDate = moment().format(formatDate2) > moment(value).format(formatDate2);
                              // if (isPassDate) {
                              //   return Promise.reject(`Cannot choose a date in the past!`);
                              // }
                              // Kiểm tra giá trị date để xác định xem có trùng nhau không
                              const countDuplicate = form.getFieldValue('booking').filter(
                                (f) =>
                                  typeof f !== 'number' && // Khi add() thêm 1 optoin mới và chưa nhập ngày thì Form.List sẽ đánh dấu vị trí optipn mới là 1 sô trong field 'booking'
                                  moment(f.dateRequest).format(formatDate) === moment(value).format(formatDate),
                              );
                              if (countDuplicate.length > 1) {
                                return Promise.reject(`Can't match date!`);
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          locale={locale}
                          disabled={!isEdit}
                          dateRender={dateRender}
                          disabledDate={disabledDate}
                          placeholder={i18next.t('coachingDate')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'timeRequest']}
                        // label='Coaching time'
                        fieldKey={[field.fieldKey, 'timeRequest']}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter coaching time!',
                          },
                        ]}
                      >
                        <Select
                          mode='multiple'
                          options={[
                            { label: 'Morning', value: 'Morning' },
                            { label: 'Afternoon', value: 'Afternoon' },
                            { label: 'Evening', value: 'Evening' },
                          ]}
                          disabled={!isEdit}
                          // onChange={(v) => setTimeRq(v)}
                          placeholder={i18next.t('coachingTime')}
                          onChange={(value) => setonChangeCoachingTime(value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[12, 12]}>
                    <Col flex={'auto'}>
                      <Form.Item
                        // label='Free time'
                        {...field}
                        name={[field.name, 'notes']}
                        // label='Coaching time'
                        fieldKey={[field.fieldKey, 'notes']}
                        rules={[
                          {
                            required: true,
                            validator: (_, value) => {
                              if (!value || value.trim() === '') {
                                return Promise.reject('Please enter your free time!');
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <Input maxLength={100} placeholder={placeHolder} />
                      </Form.Item>
                    </Col>
                    {isEdit && (
                      <Col flex={'80px'}>
                        <Button
                          // disabled={isDisableBooking}
                          className='dynamic-add-button'
                          icon={<PlusCircleOutlined />}
                          type='dashed'
                          shape='circle'
                          style={{ margin: '0 3px 3px 0' }}
                          onClick={() => add(field.name)}
                        />
                        {fields.length > 1 ? (
                          <Button
                            // disabled={isDisableBooking}
                            className='dynamic-delete-button'
                            icon={<MinusCircleOutlined />}
                            type='dashed'
                            shape='circle'
                            danger
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Col>
                    )}
                  </Row>
                </div>
              ))}
            </>
          )}
        </Form.List>
      </>
    );
  };

  //kết thúc điền thông tin
  const onFinishEdit = (values) => {
    formConfirm.setFieldsValue({
      ...values,
      studentCode: loggedInUser?.maSv,
      studentName: loggedInUser?.fullName,
    });
    setVisibleConfirmModal({ open: true, record: values });
  };

  // Gửi thông tin booking
  const onConfirm = (values) => {
    if (!isProcessing) {
      setIsProcessing(true);
      try {
        const { booking } = values;
        const timeNow = moment().toISOString();
        const studentId = loggedInUser.id;
        query('p_QUIZCITY_CoachingBooking_Request_Insert_v2', {
          StudentId: studentId,
          Status: statusValues.pending,
          CreatedBy: studentId,
        }).then(async (res) => {
          const requestId = res[0].id;
          await booking.forEach((option) => {
            const parameter = {
              Id: 0,
              RequestId: requestId,
              StudentId: studentId,
              DateRequest: option.dateRequest,
              TimeRequest: option.timeRequest.toString(),
              Notes: option.notes,
              CreatedDate: timeNow,
              CreatedBy: studentId,
              ModifiedDate: timeNow,
              ModifiedBy: studentId,
            };
            query('p_QUIZCITY_CoachingBooking_RequestDetail_InsertUpdate', parameter);
          });
          await setVisibleConfirmModal({ open: false });
          queryClient.invalidateQueries(KEY_SELECT_RESULTS_BOOKING);
          form.resetFields();
          notification.success({
            message: 'Successful appointment booking',
            description: 'Please wait for a response in the next few days!',
          });
          sendMail(booking);
          handleSendNotification(booking);
        });
      } catch (error) {
        notification.error({
          message: 'Appointment booking failed!',
          description: 'Please try again!',
        });
      } finally {
        setIsProcessing(false);
      }
    }
  };

  const getMailSetting = async () => {
    const mailer =
      ConfigSettings?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value ?? 'AMES ENGLISH <mailer@ames.edu.vn>';
    const CC = ConfigSettings?.find((x) => x?.key.toUpperCase() === 'CC')?.value ?? '';

    return { mailer: mailer, cc: CC };
  };

  //Gửi mail cho tài khoản teacher Ames ('teacher.lms@ames.edu.vn') và admin LG (role manager)
  const sendMail = async (booking) => {
    const { mailer, cc } = getMailSetting();
    const dateNo = moment().format('YYYYMMDDhhmm');
    const bookingHTML = booking
      .map((option) => `<div>- ${moment(option.dateRequest).format(formatDate)}: ${option.notes}</div>`)
      .join('');
    const dataSendMail = {
      // Vì tài khoản teacher giao cho ames nên nếu test ở Stage/ Dev thì gửi về cc
      to: settings.APPLICATION_NAME !== 'QUIZCITY' ? cc : 'teacher.lms@ames.edu.vn',
      isSendFast: true,
      moduleId: settings.MODULE_ID,
      cc: cc,
      bcc: '',
      subject: `Coaching booking #${dateNo}`,
      body: '',
      bodyHTML: '',
      attachment: '',
      templateName: 'LG_Mail_CoachingBooking',
      sourceInfo: `API Save Email - LG_Mail_CoachingBooking`,
      templateData: JSON.stringify({
        dateNo: dateNo,
        studentName: loggedInUser?.fullName,
        bookingHTML: bookingHTML,
      }),
      mailer: mailer,
    };
    amesSendMail('saveEmail', dataSendMail);
  };

  // Gửi thông báo cho role manager và teacher
  const handleSendNotification = (booking) => {
    const bookingHTML = booking
      .map((option) => `<div>- ${moment(option.dateRequest).format(formatDate)}: ${option.notes}</div>`)
      .join('');
    ['manager', 'teacher'].forEach((role) => {
      sendNotificationToUsersWithRole({
        receiver: role, // Các users được nhận thông báo
        title: `Coaching booking - ${loggedInUser.fullName}`, // tiêu đề
        body: bookingHTML, // nội dung thông báo HTML string
        classId: 0, // lớp học, mặc định là 0
        icon: '', // icon
        color: colors.theme.button, // Màu của icon chuông
        createdBy: loggedInUser?.id, // người tạo thông báo
        name: loggedInUser?.fullName, // tên người tạo thông báo
        avatar: loggedInUser?.avatarUrl ?? '', // ảnh người gửi trên thông báo
        type: 2, //0: Common , 1: Support, 2: Booking, 3: Other
      });
    });
  };

  //Modal hiển thị thông tin booking chi tiết
  const renderDetailResult = (dataResult) => {
    const statusDisplay = statusRequest.find((item) => item.status.toUpperCase() === dataResult.status?.toUpperCase());
    return (
      <>
        <Row>
          <Col span={5}>
            <strong> {i18next.t('employeeCode')} </strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>{dataResult.studentCode}</Col>
        </Row>
        <Row>
          <Col span={5}>
            <strong> {i18next.t('fullName')} </strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>{dataResult.studentName}</Col>
        </Row>
        {dataResult.status !== statusValues.completed && (
          <Row>
            <Col span={5}>
              <strong> {i18next.t('Booking')} </strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={18}>
              <RenderBookingDateTags bookingDates={dataResult.booking} />
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: 4 }}>
          <Col span={5}>
            <strong> {i18next.t('Status')} </strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>{statusDisplay?.tag}</Col>
        </Row>
        {/* <Divider orientation='center' style={{ margin: 5 }}>
          Status :{' '}
          <Tag color={statusDisplay?.color} icon={statusDisplay?.icon}>
            {statusDisplay?.status}
          </Tag>
        </Divider> */}

        {statusDisplay?.status === statusValues.cancel && (
          <Row>
            <Col span={5}>
              <strong> {i18next.t('reason')} </strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={18}>{dataResult?.reasonCancel}</Col>
          </Row>
        )}
        {statusDisplay?.status === statusValues.accept && (
          <>
            <Row style={{ marginTop: 4 }}>
              <Col span={5}>
                <strong> {i18next.t('Appointment')} </strong>
              </Col>
              <Col span={1}>:</Col>
              <Col span={18}>
                <CoachingDateTag value={dataResult?.commitedDateTeacher} />
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <strong> {i18next.t('teacherName')} </strong>
              </Col>
              <Col span={1}>:</Col>
              <Col span={18}>{dataResult.teacherName}</Col>
            </Row>
            <Row>
              <Col span={5}>
                <strong>{i18next.t('linkMeeting')} </strong>
              </Col>
              <Col span={1}>:</Col>
              <Col span={18}>
                <a
                  style={{ color: colors.theme.button }}
                  href={dataResult.linkMeeting}
                  target='_blank'
                  alt='link meeting'
                >
                  {dataResult.linkMeeting}
                </a>
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <strong> {i18next.t('Notes')} </strong>
              </Col>
              <Col span={1}>:</Col>
              <Col span={18}>{dataResult.notes}</Col>
            </Row>
          </>
        )}
        {statusDisplay?.status === statusValues.completed && (
          <>
            <Row style={{ marginTop: 4 }}>
              <Col span={5}>
                <strong>Finish date</strong>
              </Col>
              <Col span={1}>:</Col>
              <Col span={18}>
                <FinishedCoachingDateTag value={dataResult?.finishedDate} />
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <strong>Coaching comment</strong>
              </Col>
              <Col span={1}>:</Col>
              <Col span={18}>{dataResult?.finishedCommentTeacher}</Col>
            </Row>
          </>
        )}
      </>
    );
  };

  // Gửi thông tin booking
  const onCancelBooking = (values, record) => {
    setIsProcessing(true);
    const { reasonCancel } = values;
    const { id, studentId, teacherId, notes, commitedDateTeacher, createdDate } = record;
    const parameter = {
      Id: id,
      StudentId: studentId,
      TeacherId: teacherId || '00000000-0000-0000-0000-000000000000',
      Status: statusValues.cancel,
      ReasonCancel: reasonCancel,
      CommitedDateTeacher: commitedDateTeacher,
      Notes: notes ? notes : '',
      CreatedDate: createdDate,
      CreatedBy: studentId,
      ModifiedDate: moment().toISOString(),
      ModifiedBy: loggedInUser?.id,
    };
    query('p_QUIZCITY_CoachingBooking_Request_InsertUpdate', parameter)
      .then((res) => {
        queryClient.invalidateQueries(KEY_SELECT_RESULTS_BOOKING);
        notification.success({
          message: 'Cancellation of booking successfully',
          description: 'You can schedule another coaching',
        });
        formCancel.resetFields();
        setVisibleCancelBookingModal({ open: false });
      })
      .catch((err) => {
        notification.error({
          message: 'An error occurred',
          description: 'Please try again',
        });
        // console.log(' ERROR p_QUIZCITY_CoachingBooking_Request_InsertUpdate', err, parameter);
      })
      .finally(() => setIsProcessing(false));
  };

  return (
    <Card
      title='Coaching Booking'
      style={{ height: 'calc(100% - 30px)' }}
      bodyStyles={{ paddingTop: 0, overflowY: 'auto', height: 450 }}
      extra={
        <div style={{ marginTop: -5 }}>
          <DynamicButton
            type='primary'
            style={{ borderRadius: '50%', border: 'none', padding: 0, width: 24, height: 24 }}
            icon={<BsQuestionLg size={12} />}
            onClick={() => setCoachingGuideModalVisible(true)}
          />
        </div>
      }
    >
      {/* Booking form */}
      <Form
        layout='vertical'
        form={form}
        onFinish={onFinishEdit}
        initialValues={{
          studentCode: loggedInUser?.maSv,
          studentName: loggedInUser?.fullName,
          booking: [{ dateRequest: null, timeRequest: ['Morning'] }],
        }}
        requiredMark={false}
      >
        <div style={{ margin: '5px 0', fontWeight: 500 }}>
          <div>
            <i>{`1) Trainee should book coaching schedule at least 10 days in advance.`}</i>
          </div>
          <div>
            <i>{`2) Trainee can book several days for ${
              settings.IS_LG ? 'TOEIC' : 'IELTS'
            } trainers to choose one easily.`}</i>
          </div>
          <div>
            <i>{`3) Result of coaching booking will be sent via company email.`}</i>
          </div>
          <div>
            <i>{`4)  Each trainee have ONLY 1 coaching session of 30 minutes.`}</i>
          </div>
        </div>
        {renderFormItem('register', form)}
        <Row justify='end'>
          <Col>
            {displayResults?.[displayResults?.length - 1]?.status === statusValues.cancel && (
              <div style={{ color: 'red', fontStyle: 'italic', alignContent: 'center', marginRight: 20 }}>
                {i18next.t('YouNeedToCreate')}
              </div>
            )}
          </Col>
          <Col>
            <Form.Item style={{ margin: '0 10px 0 0' }}>
              <DynamicButton disabled={isDisableBooking} type='primary' htmlType='submit'>
                {i18next.t('Booking')}
              </DynamicButton>
            </Form.Item>
          </Col>
        </Row>

        {/* Các booking results */}
        {displayResults.length > 0 && (
          <>
            <DynamicTable
              loading={isLoading}
              dataSource={[displayResults?.[displayResults?.length - 1]]}
              initialTableData={resultsBookingColumn}
              extraColumns={[
                {
                  title: '',
                  key: 'action',
                  width: '1%',
                  sort: false,
                  render: (_, record) => (
                    <Space key={record.id}>
                      <Tooltip title={'See more'}>
                        <Button
                          type='text'
                          // shape='circle'
                          style={{ border: '1px dashed #3b3737' }}
                          icon={<GrView style={{ marginTop: 5 }} />}
                          onClick={() => setStateBookingResultsModal({ open: true, data: record })}
                        />
                      </Tooltip>
                      {record?.status === statusValues.pending && (
                        <Tooltip placement='top' title={'Cancel booking'}>
                          <Button
                            key='edit-file'
                            // shape='circle'
                            type='dashed'
                            danger
                            style={{ color: '#f5222d' }}
                            icon={<GiCancel style={{ marginTop: 5 }} />}
                            onClick={() => setVisibleCancelBookingModal({ open: true, record: record })}
                          />
                        </Tooltip>
                      )}
                    </Space>
                  ),
                },
              ]}
              pagination={false}
            />
            {displayResults.length > 1 && (
              <div style={{ display: 'flex', justifyContent: 'end', marginTop: 5 }}>
                <DynamicButton style={{ color: colors.theme.button }} onClick={() => setVisibleAllResults(true)}>
                  {i18next.t('Seeall')}
                </DynamicButton>
              </div>
            )}
          </>
        )}
      </Form>

      {/* Confirm booking modal */}
      <Modal
        title={
          <div style={{ color: colors.theme.button }}> {i18next.t('confirmCoachingInformation').toUpperCase()} </div>
        }
        // destroyOnClose
        centered
        open={visibleConfirmModal?.open}
        onCancel={() => setVisibleConfirmModal({ open: false })}
        footer={[
          <DynamicButton onClick={() => setVisibleConfirmModal({ open: false })}>{i18next.t('Close')}</DynamicButton>,
          <DynamicButton type='primary' loading={isProcessing} onClick={() => onConfirm(visibleConfirmModal.record)}>
            {i18next.t('Booking')}
          </DynamicButton>,
        ]}
      >
        <Row>
          <Col span={5}>
            <strong> {i18next.t('employeeCode')}</strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>{visibleConfirmModal?.record?.studentCode}</Col>
        </Row>
        <Row>
          <Col span={5}>
            <strong> {i18next.t('fullName')}</strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>{visibleConfirmModal?.record?.studentName}</Col>
        </Row>
        <Row>
          <Col span={5}>
            <strong> {i18next.t('Booking')} </strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>
            <RenderBookingDateTags bookingDates={visibleConfirmModal?.record?.booking} />
          </Col>
        </Row>
      </Modal>

      {/* Results details */}
      <Modal
        title={<div style={{ color: colors.theme.button }}>{i18next.t('BookingResult').toUpperCase()}</div>}
        centered
        open={stateBookingResultsModal?.open}
        onCancel={() => setStateBookingResultsModal({ open: false })}
        width={650}
        footer={[
          <DynamicButton onClick={() => setStateBookingResultsModal({ open: false })}>
            {i18next.t('Close')}
          </DynamicButton>,
        ]}
      >
        {stateBookingResultsModal?.data ? renderDetailResult(stateBookingResultsModal?.data) : ''}
      </Modal>

      {/*All Results */}
      <Modal
        title={<div style={{ color: colors.theme.button }}> {i18next.t('AllBookingResult').toUpperCase()} </div>}
        centered
        open={visibleAllResults}
        onCancel={() => setVisibleAllResults(false)}
        footer={[<DynamicButton onClick={() => setVisibleAllResults(false)}> {i18next.t('Close')} </DynamicButton>]}
        width={1000}
      >
        <DynamicTable dataSource={displayResults} initialTableData={allResultsBookingColumn} pagination={false} />
      </Modal>

      {/* Modal cancel booking */}
      <Modal
        title={<div style={{ color: colors.theme.button }}> {i18next.t('CancelBooking').toUpperCase()} </div>}
        open={visibleCancelBookingModal.open}
        onCancel={() => setVisibleCancelBookingModal({ open: false })}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <div>
              <DynamicButton onClick={() => setVisibleCancelBookingModal({ open: false })} style={{ marginRight: 5 }}>
                {i18next.t('Close')}
              </DynamicButton>
              <DynamicButton loading={isProcessing} type='primary' onClick={() => formCancel.submit()}>
                {i18next.t('Save')}
              </DynamicButton>
            </div>
          </div>,
        ]}
        centered
        destroyOnClose
      >
        <Row>
          <Col span={5}>
            <strong>{i18next.t('employeeCode')}</strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>{visibleCancelBookingModal.record?.studentCode}</Col>
        </Row>
        <Row>
          <Col span={5}>
            <strong>{i18next.t('fullName')}</strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>{visibleCancelBookingModal.record?.studentName}</Col>
        </Row>
        <Row>
          <Col span={5}>
            <strong>{i18next.t('Booking')}</strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>
            <RenderBookingDateTags bookingDates={visibleCancelBookingModal.record?.booking} />
          </Col>
        </Row>
        <Row style={{ marginTop: 4 }}>
          <Col span={5}>
            <strong>{i18next.t('Status')}</strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>{statusRequest.find((i) => i.status === statusValues.cancel).tag}</Col>
        </Row>

        <Form
          form={formCancel}
          onFinish={(value) => onCancelBooking(value, visibleCancelBookingModal?.record)}
          layout='vertical'
          initialValues={{ reasonCancel: '' }}
        >
          <Form.Item
            name='reasonCancel'
            label='Reason'
            rules={[
              {
                validator: (_, value) => {
                  if (!value || value.replace(/\s/g, '') === '') return Promise.reject(`Can't be left blank`);
                  else return Promise.resolve();
                },
              },
            ]}
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
}

export default CoachingForm;
