import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { execute } from 'helpers/QueryHelper';
import AddOrEditAssignment from '../../AddOrEditAssignment';
import i18next from 'i18next';

const ExerciseForStudent = ({ loggedInUser, exerciseModalVisible, setExerciseModalVisible, selectedFolderId, worksheetFile }) => {
  const submitRef = React.useRef();

  return (
    <div>
      <Modal
        width='95%'
        style={{ marginTop: 24 }}
        centered
        className='exerciseForStudentModal'
        visible={exerciseModalVisible}
        title={(worksheetFile.tags ? i18next.t("lecture:Presentation.Assign assignments automatically graded") : i18next.t("lecture:Presentation.Fast assigning")).toUpperCase()}
        onCancel={() => {
          setExerciseModalVisible(false);
        }}
        footer={[
          <Button
            key='submit'
            type='primary'
            icon={<SaveOutlined />}
            onClick={async () => {
              const IsValid = submitRef.current.submit();
              if (IsValid) {
                setTimeout(() => {
                  setExerciseModalVisible(false);
                }, 1200);
                // Sau khi tạo assignment thì tạo attachment.
                await execute(
                  'p_SACHSO_SHARE_Attachments_Copy',
                  {
                    id: worksheetFile.id,
                    entityId: selectedFolderId,
                    entityName: 't_SACHSO_CLASSWORK_Assignments',
                    createdBy: loggedInUser?.id,
                  },
                  'SACHSO',
                );
              }
            }}
          >
            {i18next.t("lecture:Presentation.Give homework")}
          </Button>,
        ]}
      >
        <Row gutter={[16, 16]} style={{ overflowY: 'auto', height: '75vh' }}>
          <Col span={24} md={12} lg={14} xl={16}>
            <AddOrEditAssignment entityId={selectedFolderId} worksheetFile={worksheetFile} submitRef={submitRef} />
          </Col>
          <Col span={24} md={12} lg={10} xl={8}>
            <img className='d-none d-md-block' src={worksheetFile?.imgURL} alt='' style={{ width: '100%' }} />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

ExerciseForStudent.propTypes = {
  loggedInUser: PropTypes.object.isRequired,
  exerciseModalVisible: PropTypes.bool.isRequired,
  setExerciseModalVisible: PropTypes.func.isRequired,
  selectedFolderId: PropTypes.any.isRequired,
  worksheetFile: PropTypes.any.isRequired,
};

export default ExerciseForStudent;
