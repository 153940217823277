import { DictionaryText } from 'components/Dictionary';
import { QuestionProps } from '../../ExercicesTypes/type';

type Props = {
  question?: any;
};
const TextBlockQuote = ({ question }: Props) => {
  return (
    <blockquote className='mt-3 mb-0 blockquote tw-relative'>
      <p style={{ fontSize: 20, fontWeight: '600', maxWidth: '50vw' }}>
        <DictionaryText text={question?.answers?.[0]?.text} />
      </p>
      {question?.answers?.[0]?.phonetic && (
        <p style={{ fontSize: 16, fontWeight: '400', fontStyle: 'italic' }}>{question?.answers?.[0]?.phonetic}</p>
      )}
      {/* <p style={{ fontSize: 15, fontWeight: '400' }}>({question.wordType})</p> */}
      <footer className='blockquote-footer'>
        <span style={{ fontSize: '16px' }}>Nhấp vào để tra cứu nghĩa từng từ </span>
      </footer>
    </blockquote>
  );
};

export default TextBlockQuote;
