import { Button, Col, Form, FormInstance, Input, InputProps, Row, Select, SelectProps } from 'antd';
import { queryWithoutModuleId } from 'helpers/QueryHelper';
import React, { useState } from 'react';
import { AiOutlineBranches } from 'react-icons/ai';
import { BiCodeAlt } from 'react-icons/bi';
import { BsFillBuildingsFill } from 'react-icons/bs';
import { SiGoogleclassroom } from 'react-icons/si';
import { useQuery } from 'react-query';

type Props = {
  formRef: FormInstance;
};

type ParticipantType = 'hoc_sinh' | 'sinh_vien' | 'doanh_nghiep';

const participantOptions: { label: string; value: ParticipantType }[] = [
  {
    label: 'Học sinh',
    value: 'hoc_sinh',
  },
  {
    label: 'Sinh viên',
    value: 'sinh_vien',
  },
  {
    label: 'Cơ quan - Doanh nghiệp',
    value: 'doanh_nghiep',
  },
];

const gradeOptions = Array(12)
  .fill(null)
  .map((i, index) => ({ label: `Khối ${index + 1}`, value: index + 1 }));

const defaultInputProps: InputProps = {
  style: {
    boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
    borderRadius: 3,
  },
  allowClear: true,
  size: 'large',
};

const PTFormContent = ({ formRef }: Props) => {
  const [participantType, setParticipantType] = useState<ParticipantType | undefined>();
  const [selectedCityId, setSelectedCityId] = useState<any>();

  const { data: cities } = useQuery('p_QUIZCITY_GET_Cities', () => queryWithoutModuleId('p_QUIZCITY_GET_Cities'));
  const { data: districts } = useQuery(
    ['p_QUIZCITY_GET_Districts', selectedCityId],
    () => queryWithoutModuleId('p_QUIZCITY_GET_Districts', { cityId: selectedCityId }),
    {
      enabled: !!selectedCityId,
    },
  );

  //Tên
  const userName = (
    <Form.Item
      hasFeedback
      name='userName'
      rules={[
        { type: 'string', message: 'Tên không hợp lệ' },
        {
          whitespace: true,
          message: 'Vui lòng nhập lại, không xuống dòng!',
        },
        {
          required: true,
          message: 'Vui lòng nhập tên!',
        },
      ]}
    >
      <Input
        {...defaultInputProps}
        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-user' />}
        placeholder='Họ và tên (*)'
      />
    </Form.Item>
  );

  //Email
  const email = (
    <Form.Item
      hasFeedback
      name='email'
      rules={[
        { type: 'email', message: 'Email không hợp lệ' },
        {
          whitespace: true,
          message: 'Vui lòng không xuống dòng!',
        },
        {
          required: true,
          message: 'Vui lòng nhập email!',
        },
      ]}
    >
      <Input
        {...defaultInputProps}
        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fa fa-envelope' />}
        placeholder={`Email (*)`}
      />
    </Form.Item>
  );

  //Điện thoại
  const phone = (
    <Form.Item
      hasFeedback
      name='phone'
      rules={[
        {
          whitespace: true,
          message: 'Vui lòng không xuống dòng!',
        },
        {
          required: true,
          message: 'Vui lòng số điện thoại!',
        },
        {
          pattern: /^[0]\d{8,10}$/,
          message: 'Số điện thoại không đúng định dạng!',
        },
      ]}
    >
      <Input
        {...defaultInputProps}
        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fa fa-phone' />}
        placeholder={`Số điện thoại (*)`}
      />
    </Form.Item>
  );

  //Mã tham gia
  const codeExam = (
    <Form.Item name='codeExam'>
      <Input
        disabled
        {...defaultInputProps}
        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fa fa-lock' />}
      />
    </Form.Item>
  );

  //Công ty
  const company = (
    <Form.Item
      hasFeedback
      name='company'
      rules={[
        {
          whitespace: true,
          message: 'Vui lòng không xuống dòng!',
        },
        {
          required: true,
          message: 'Vui lòng nhập tên công ty!',
        },
      ]}
    >
      <Input
        {...defaultInputProps}
        prefix={<BsFillBuildingsFill style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder='Tên công ty đang làm việc (*)'
      />
    </Form.Item>
  );

  //Chi nhánh
  const branch = (
    <Form.Item
      hasFeedback
      name='branch'
      rules={[
        {
          whitespace: true,
          message: 'Vui lòng không xuống dòng!',
        },
      ]}
    >
      <Input
        {...defaultInputProps}
        prefix={<AiOutlineBranches style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder='Chi nhánh/cơ sở (Nếu có)'
      />
    </Form.Item>
  );

  //Bộ phận
  const department = (
    <Form.Item
      hasFeedback
      name='department'
      rules={[
        {
          whitespace: true,
          message: 'Vui lòng không xuống dòng!',
        },
      ]}
    >
      <Input
        {...defaultInputProps}
        prefix={<SiGoogleclassroom style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder='Bộ phận/phòng ban (Nếu có)'
      />
    </Form.Item>
  );

  //Mã nhân viên
  const employyeeCode = (
    <Form.Item
      hasFeedback
      name='employyeeCode'
      rules={[
        {
          whitespace: true,
          message: 'Vui lòng không xuống dòng!',
        },
      ]}
    >
      <Input
        {...defaultInputProps}
        prefix={<BiCodeAlt style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder='Mã nhân viên'
        maxLength={12}
      />
    </Form.Item>
  );

  //Lớp học
  const className = (
    <Form.Item
      hasFeedback
      name='className'
      rules={[
        {
          whitespace: true,
          message: 'Vui lòng không xuống dòng!',
        },
        {
          required: true,
          message: 'Vui lòng nhập tên lớp!',
        },
      ]}
    >
      <Input {...defaultInputProps} placeholder='Tên lớp (*)' maxLength={50} />
    </Form.Item>
  );

  //Khối lớp
  const grade = (
    <Form.Item
      hasFeedback
      name='grade'
      rules={[
        {
          required: true,
          message: 'Vui lòng nhập khối lớp!',
        },
      ]}
    >
      <Select
        {...(defaultInputProps as SelectProps)}
        options={gradeOptions}
        placeholder='Khối lớp (*)'
        filterOption={(input, option) => ((option?.label as String) ?? '')?.toLowerCase().includes(input.toLowerCase())}
      />
    </Form.Item>
  );

  //Lớp học
  const schoolName = (
    <Form.Item
      hasFeedback
      name='schoolName'
      rules={[
        {
          whitespace: true,
          message: 'Vui lòng không xuống dòng!',
        },
        {
          required: true,
          message: 'Vui lòng nhập tên trường!',
        },
      ]}
    >
      <Input {...defaultInputProps} placeholder='Tên trường (*)' maxLength={50} />
    </Form.Item>
  );

  //'Tỉnh/ Thành phố/ Đoàn trực thuộc (*)'
  const cityId = (
    <Form.Item
      hasFeedback
      name='cityId'
      rules={[
        {
          required: true,
          message: 'Chưa nhập Tỉnh/ Thành phố/ Đoàn trực thuộc!',
        },
      ]}
    >
      <Select
        {...(defaultInputProps as SelectProps)}
        options={cities}
        placeholder='Tỉnh/ Thành phố/ Đoàn trực thuộc (*)'
        onChange={(value) => {
          setSelectedCityId(value);
          formRef.setFieldValue('dictrictId', null);
        }}
        filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
        fieldNames={{ label: 'name', value: 'id' }}
      />
    </Form.Item>
  );

  //Quận/ Huyện/ Thị xã (*)
  const dictrictId = (
    <Form.Item name='dictrictId' hasFeedback rules={[{ required: true, message: 'Chưa nhập Quận/ Huyện/ Thị xã' }]}>
      <Select
        {...(defaultInputProps as SelectProps)}
        disabled={!selectedCityId}
        showSearch
        placeholder='Quận/ Huyện/ Thị xã (*)'
        options={districts}
        filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
        fieldNames={{ label: 'name', value: 'id' }}
      />
    </Form.Item>
  );

  //Phường/Xã (*)
  const ward = (
    <Form.Item
      hasFeedback
      name='ward'
      rules={[
        {
          whitespace: true,
          message: 'Vui lòng không xuống dòng!',
        },
        { required: true, message: 'Chưa nhập Phường/Xã' },
      ]}
    >
      <Input {...defaultInputProps} placeholder='Phường/Xã (*)' maxLength={50} />
    </Form.Item>
  );

  //Tên Khoa (*)
  const faculty = (
    <Form.Item
      hasFeedback
      name='faculty'
      rules={[
        {
          whitespace: true,
          message: 'Vui lòng không xuống dòng!',
        },
        { required: true, message: 'Chưa nhập Tên Khoa!' },
      ]}
    >
      <Input {...defaultInputProps} placeholder='Tên Khoa (*)' maxLength={50} />
    </Form.Item>
  );

  const RenderFormItems = () => {
    const arrayItems = [];

    switch (participantType) {
      case 'hoc_sinh':
        arrayItems.push(userName, phone, cityId, dictrictId, ward, schoolName, grade, className);
        break;
      case 'sinh_vien':
        arrayItems.push(userName, email, phone, cityId, dictrictId, ward, schoolName, faculty, className);
        break;
      case 'doanh_nghiep':
        arrayItems.push(userName, email, phone, company, branch, department, employyeeCode);
        break;
      default:
        break;
    }
    return arrayItems.map((item, index) => (
      <Col key={index} xs={24} sm={12}>
        {item}
      </Col>
    ));
  };

  return (
    <React.Fragment>
      <Row gutter={[12, 0]}>
        <Col xs={24} sm={12}>
          {codeExam}
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            hasFeedback
            name='participantType'
            rules={[
              {
                required: true,
                message: 'Chưa nhập!',
              },
            ]}
          >
            <Select
              {...(defaultInputProps as SelectProps)}
              options={participantOptions}
              placeholder='Đối tượng tham gia (*)'
              onChange={(value) => setParticipantType(value)}
            />
          </Form.Item>
        </Col>

        {RenderFormItems()}
      </Row>
    </React.Fragment>
  );
};

export default PTFormContent;
