import { Button, Modal, Spin } from 'antd';
import settings from 'constants/settings';

const ViewReport = ({ record }) => {
  const onClick = () => {
    window.open(`/${settings.PATHNAME}/student/codeExam/${record?.code}/preview`, '_blank');
  };

  return (
    <div>
      <Button type='link' onClick={() => onClick()}>
        Nhật kí bài làm
      </Button>
    </div>
  );
};
export default ViewReport;
