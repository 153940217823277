import { Button, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
// import { cdnServerUrl } from 'constants/serverUrls';

const FilePreview = ({
  cdnServerUrl,
  fileUrl,
  buttonText,
  buttonIcon,
  buttonType,
  buttonShape,
  toolTipTitle,
  toolTipPlacement,
}) => {
  const createButton = (buttonText, buttonIcon, buttonType, buttonShape) => (
    <Button icon={buttonIcon} type={buttonType} shape={buttonShape}>
      {buttonText}
    </Button>
  );

  return (
    <>
      <a href={cdnServerUrl + fileUrl} target='_blank'>
        {toolTipTitle ? (
          <Tooltip title={toolTipTitle} placement={toolTipPlacement}>
            {createButton(buttonText, buttonIcon, buttonType, buttonShape)}
          </Tooltip>
        ) : (
          createButton(buttonText, buttonIcon, buttonType, buttonShape)
        )}
      </a>
    </>
  );
};

FilePreview.defaultProps = {
  cdnServerUrl: '',
  fileUrl: '',
  buttonText: '',
  buttonIcon: <EyeOutlined />,
  buttonType: 'primary',
  buttonShape: 'default',
  toolTipTitle: '',
  toolTipPlacement: 'top',
};

export default FilePreview;
