import { MenuOutlined } from '@ant-design/icons';
import { Button, Col, Popover, Row, Space, Typography } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import LectureList from '../Editor/components/LectureList';
import { KeyLecture, modeName, PresentationProps } from '../types';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import settings from 'constants/settings';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

type FooterProps = {
  presentation?: PresentationProps;
  presentationId?: string;
  mode: modeName;
  setMode?: Dispatch<SetStateAction<modeName>>;
  total?: number;
  currentIndex?: number;
  setCurrentIndex?: Dispatch<SetStateAction<number>>;
  title?: string;
  onSubmit?: Function;
  type?: KeyLecture;
  audio?: any;
};

const Footer = ({
  mode,
  setMode,
  presentation,
  total,
  currentIndex,
  setCurrentIndex,
  title,
  onSubmit,
  type,
  audio,
  presentationId,
}: FooterProps) => {
  const { t } = useTranslation('');
  const handlePreview = React.useCallback(() => {
    setCurrentIndex(0);
    // setMode('PREVIEW');
  }, [setCurrentIndex, setMode]);

  const handleEdit = React.useCallback(() => {
    setCurrentIndex(0);
    setMode('EDIT');
  }, [setCurrentIndex, setMode]);
  const renderFooter = React.useCallback(() => {
    switch (mode) {
      case 'CREATE':
        return [
          <DynamicButton key='CREATE' onClick={handlePreview}>
            {i18next.t('Preview')}
          </DynamicButton>,
        ];
      case 'PREVIEW':
        return (
          <Row>
            <Col span={8} style={{ fontWeight: 'bold', textAlign: 'left' }}>
              {/* {presentation?.title.toUpperCase()} */}
              <DynamicButton
                disabled={currentIndex === 0}
                onClick={() => {
                  if (currentIndex > 0) {
                    setCurrentIndex(currentIndex - 1);
                  }
                }}
              >
                {i18next.t('slide:FooterStudentV2.Back')}
              </DynamicButton>
            </Col>
            <Col span={8} style={{ fontWeight: 'bold', textAlign: 'center' }}>
              <Typography.Title level={4}>{title}</Typography.Title>
              {type === 'WorkSheet' && audio && <div>{audio}</div>}
            </Col>
            <Col span={8}>
              <Space>
                <DynamicButton hidden={type !== 'WorkSheet'} onClick={() => onSubmit()} type='primary'>
                  {i18next.t('Test')}
                </DynamicButton>
                <Popover
                  content={
                    <LectureList
                      presentationId={presentation.id ?? presentationId}
                      countingTime
                      width={300}
                      getSlideIndex={setCurrentIndex}
                    />
                  }
                  style={{ width: '200px !important' }}
                  title={i18next.t('LessonList')}
                  trigger='click'
                  zIndex={9999}
                >
                  <Button icon={<MenuOutlined />} style={{ borderColor: 'red', color: 'red' }} type='dashed' />
                </Popover>
                <DynamicButton
                  disabled={currentIndex === total}
                  onClick={() => {
                    if (currentIndex < total) {
                      setCurrentIndex(currentIndex + 1);
                    }
                  }}
                  type={`${type === 'WorkSheet' ? 'default' : 'primary'}`}
                >
                  {i18next.t('slide:FooterStudentV2.nextSlide')}
                </DynamicButton>
                <DynamicButton key='PREVIEW_OF_EDIT' onClick={handleEdit}>
                  {t('Close')}
                </DynamicButton>
              </Space>
            </Col>
          </Row>
        );
      case 'EDIT':
        return [
          <DynamicButton key='EDIT' onClick={handlePreview}>
            {i18next.t('Preview')}
          </DynamicButton>,
        ];
      case 'VIEW':
        return (
          <Row>
            <Col span={8} style={{ fontWeight: 'bold', textAlign: 'left' }}>
              <DynamicButton
                disabled={currentIndex === 0}
                onClick={() => {
                  if (currentIndex > 0) {
                    setCurrentIndex(currentIndex - 1);
                  }
                }}
              >
                {i18next.t('slide:FooterStudentV2.Back')}
              </DynamicButton>
            </Col>
            <Col span={8} style={{ fontWeight: 'bold', textAlign: 'center' }}>
              <Typography.Title level={4}>{title}</Typography.Title>
              {type === 'WorkSheet' && audio && <div>{audio}</div>}
            </Col>
            <Col span={8}>
              {/* <Button hidden={type !== 'WorkSheet'} onClick={() => onSubmit()} type='primary'>
                Kiểm tra
              </Button> */}
              <Popover
                content={
                  <LectureList
                    presentationId={presentation?.id ?? presentationId}
                    countingTime
                    width={300}
                    getSlideIndex={setCurrentIndex}
                  />
                }
                style={{ width: '200px !important' }}
                title={i18next.t('LessonList')}
                trigger='click'
                zIndex={9999}
              >
                <Button icon={<MenuOutlined />} style={{ borderColor: 'red', color: 'red' }} type='dashed' />
              </Popover>
              <DynamicButton
                disabled={currentIndex === total}
                onClick={() => {
                  if (currentIndex < total) {
                    setCurrentIndex(currentIndex + 1);
                  }
                }}
                type={`primary`}
              >
                {i18next.t('slide:FooterStudentV2.nextSlide')}
              </DynamicButton>
            </Col>
          </Row>
        );

      default:
        return (
          <DynamicButton key='DEFAULT' onClick={handlePreview}>
            {i18next.t('Preview')}
          </DynamicButton>
        );
    }
  }, [
    audio,
    currentIndex,
    handleEdit,
    handlePreview,
    mode,
    onSubmit,
    presentation.id,
    presentationId,
    setCurrentIndex,
    title,
    total,
    type,
  ]);

  return <>{renderFooter()}</>;
};

Footer.defaultProps = {
  presentation: { id: '', title: '', createdDate: '', startDate: '', endDate: '' },
  setMode: () => {},
  total: 0,
  currentIndex: 0,
  setCurrentIndex: () => {},
  title: '',
  onSubmit: () => {},
  type: 'None',
  audio: undefined,
};

export default Footer;
