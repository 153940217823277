import { Col, Form, Input, message, Row, Select } from 'antd';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import { SelectDataQuestion } from 'modules/Administration/ModuleAddQuestions';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { useEffect, useState } from 'react';
import { removeAccents } from 'services/Text/formatText';
import {
  getQuestionGradeOptions_UseQuery,
  getQuestionOptionTypeOptions,
  getQuestionTagOptions_UseQuery,
  getQuestionTypeOptions_UseQuery,
} from '../../api/QuestionCategoriesApi';

const SearchDataQuestion = ({ dataQuestion, setLoadingPage ,
//maintain
initialValues = {
  type: '',
  optionType: '',
  grade: '',
  tags: '',
  questionText: '',
},
isLoading,
onFinish
}) => {
  const [form] = Form.useForm();
  const [dropdownOptionType, setDropdownOptionType] = useState([]);

  const { data: dropdownType, isLoading: isLoading_QuestionType } = getQuestionTypeOptions_UseQuery();
  const { data: dropdownTags, isLoading: isLoading_QuestionTag } = getQuestionTagOptions_UseQuery();
  const { data: dropdownGrade, isLoading: isLoading_QuestionGrade } = getQuestionGradeOptions_UseQuery();

  const onChangeType = async (value, option) => {
    form.setFieldsValue({
      optionType: '',
    });
    if (!value) {
      setDropdownOptionType([]);
      return;
    }
    const optionType = await getQuestionOptionTypeOptions(option?.id);
    setDropdownOptionType([{ value: '', label: 'All' },...optionType]);
  };

  const onSearchQuestions = async (values) => {
    dataQuestion([]);
    setLoadingPage(true);
    const { grade, optionType, type, questionText, tags } = values;
    const resultSearch = await SelectDataQuestion(questionText.trim(), type, optionType, grade, '', tags);
    if (resultSearch?.length > 0) {
      const newArray = [];
      resultSearch?.map((item) => {
        return newArray.push({
          ...item,
          key: item.id,
        });
      });
      setLoadingPage(false);
      dataQuestion(newArray);
      return;
    }
    message.warning(i18next.t('nodata'));
    dataQuestion([]);
    setLoadingPage(false);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout='vertical'
      initialValues={initialValues}
    >
      <Row gutter={[12, 12]}>
        <Col lg={4} sx={24}>
          <Form.Item name='type' label={i18next.t('typeQuestions')}>
            <Select
              allowClear
              showSearch
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              style={{ width: '100%' }}
              placeholder='Type'
              optionFilterProp='children'
              filterOption={(input, option) => removeAccents(option?.label ?? '').includes(removeAccents(input))}
              onChange={onChangeType}
              options={dropdownType}
              loading={isLoading_QuestionType}
              dropdownMatchSelectWidth={false}
            />
          </Form.Item>
        </Col>
        <Col lg={4} xs={24}>
          <Form.Item name='optionType' label={i18next.t('optionType')}>
            <Select
              allowClear
              showSearch
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              style={{ width: '100%' }}
              placeholder='Options Type'
              optionFilterProp='children'
              filterOption={(input, option) => removeAccents(option?.label ?? '').includes(removeAccents(input))}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={dropdownOptionType}
              loading={isLoading_QuestionType}
              dropdownMatchSelectWidth={false}
            />
          </Form.Item>
        </Col>
        <Col lg={4} xs={24}>
          <Form.Item name='grade' label={i18next.t('Level')}>
            <Select
              allowClear
              showSearch
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              loading={isLoading_QuestionGrade}
              style={{ width: '100%' }}
              placeholder='Grade'
              optionFilterProp='children'
              filterOption={(input, option) => removeAccents(option?.label ?? '').includes(removeAccents(input))}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={dropdownGrade}
              dropdownMatchSelectWidth={false}
            />
          </Form.Item>
        </Col>
        <Col lg={4} xs={24}>
          <Form.Item name='tag' label='Tags'>
            <Select
              allowClear
              showSearch
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              loading={isLoading_QuestionTag}
              style={{ width: '100%' }}
              placeholder='Tags'
              optionFilterProp='children'
              filterOption={(input, option) => removeAccents(option?.label ?? '').includes(removeAccents(input))}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={dropdownTags}
              dropdownMatchSelectWidth={false}
            />
          </Form.Item>
        </Col>
        <Col lg={4} xs={24}>
          <Form.Item name='questionText' label={i18next.t('question')}>
            <Input allowClear placeholder={i18next.t('question')} style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item label=' '>
            <DynamicButton type='primary' htmlType='submit'>
              {i18next.t('search')}
            </DynamicButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchDataQuestion;
