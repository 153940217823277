/* eslint-disable react/prop-types */
import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Button, Result, Statistic } from 'antd';
import colors from 'constants/colors';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';
import { CardHeader } from 'reactstrap';

import settings from 'constants/settings';
import i18next from 'i18next';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import ResultDetails from './ResultDetails';
import Bad from './imgs/Bad.svg';
import Good from './imgs/Good.svg';
import Great from './imgs/Great.svg';
import Perfect from './imgs/Perfect.svg';
import { ResultProps } from './type';

const Results = ({
  currentType,
  submittedQuestions,
  examination,
  totalScore,
  percentPass,
  dataSubmittedQuiz,
  isExample,
}: ResultProps) => {
  const passScoreQuiz = percentPass ?? 0;
  const jsonDataSubmittedAnswers = dataSubmittedQuiz?.[0]?.jsonData;
  const data =
    submittedQuestions && submittedQuestions?.length
      ? submittedQuestions
      : jsonDataSubmittedAnswers?.submittedQuestions || [];
  // State

  const handledTotalScore =
    submittedQuestions && submittedQuestions?.length ? totalScore : jsonDataSubmittedAnswers?.totalScore || 0;
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [questionIndex, setQuestionIndex] = React.useState(0);

  // History
  const history = useHistory();

  //Get User Correct Questions
  const correctQuestions = _.reduce(
    data,
    (sum, item) => {
      const result = Number.isFinite(item?.userData?.result)
        ? item?.userData?.result >= passScoreQuiz
        : item?.userData?.result;
      const value = result === true ? 1 : 0;
      return sum + value;
    },
    0,
  );

  const questions = currentType === 'COMBINE' ? examination?.questions?.[0] : examination?.questions;

  const total = questions?.type === 'COMBINE' ? questions?.answers?.length : questions?.length;

  const correctPercent = handledTotalScore / total;
  let correctIcon = Bad;
  if (!isExample) {
    if (correctPercent >= 50 && correctPercent < 75) {
      correctIcon = Good;
    } else if (correctPercent >= 75 && correctPercent < 100) {
      correctIcon = Great;
    } else {
      correctIcon = Perfect;
    }
  }

  return (
    <div>
      {/* <CardHeader className='mb-3 mt-7'>
        <h3>{`Kết quả làm bài của đề thi số ${examination.code}`.toUpperCase()}</h3>
      </CardHeader> */}

      <div className='d-flex flex-column flex-lg-row'>
        <div className='p-0 pl-0 mb-3 col col-lg-4 pr-lg-2'>
          <CardHeader>
            <Result
              style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}
              status={isExample ? 'info' : handledTotalScore / total >= passScoreQuiz ? 'success' : 'error'}
              // icon={
              //   <div className="d-flex justify-content-center">
              //     <img src={correctIcon} alt="" />
              //   </div>
              // }
              title={
                <span>
                  {isExample
                    ? i18next.t('You have completed the exercise')
                    : i18next.t('You have completed and result', {
                        result: i18next.t(handledTotalScore / total >= passScoreQuiz ? 'pass' : "didn't pass"),
                      })}
                </span>
              }
              subTitle={
                !isExample && (
                  <>
                    <div className='mt-2 d-flex justify-content-center'>
                      <Statistic
                        title={<span>{i18next.t('table:statistic.Result')}</span>}
                        suffix={` / ${total}`}
                        value={correctQuestions}
                        valueStyle={{ fontSize: 16, fontWeight: 600 }}
                        style={{ marginRight: 32 }}
                      />
                      <Statistic
                        precision={2}
                        title={<span>{i18next.t('Quality')}</span>}
                        suffix='%'
                        value={correctPercent}
                        valueStyle={{ fontSize: 16, fontWeight: 600 }}
                        // style={{ color: 'goldenrod' }}
                      />
                    </div>
                    {handledTotalScore / total < passScoreQuiz && (
                      <div style={{ fontSize: 16, fontWeight: 600, color: 'red', marginTop: 20 }}>
                        {i18next.t('You need to get above {percentPass}% to continue', { percent: percentPass })}
                      </div>
                    )}
                  </>
                )
              }
              extra={[
                <DynamicButton
                  color='primary'
                  key='console'
                  onClick={async () => {
                    // TODO: Clear all redux of olympic
                    history.push(`/${settings.PATHNAME}/home`);
                  }}
                >
                  {i18next.t('BackHomePage')}
                </DynamicButton>,
              ]}
            />
          </CardHeader>
        </div>
        <div className={'col col-lg-8 p-0 pr-0 pl-lg-2'}>
          <CardHeader className='p-0'>
            {data.length < 1 && (
              <Result
                status='404'
                title={i18next.t('NoWorkList')}
                subTitle={i18next.t('DoNotDoList')}
                // extra={<Button type="primary">Back Home</Button>}
              />
            )}
            {data.map((item: any, index: number) => {
              const { question, userData } = item;
              const checkIsCorrect = () => {
                if (question?.type?.toUpperCase() === 'RECORD') {
                  return userData?.result >= passScoreQuiz ? true : false;
                } else {
                  return userData?.result ? true : false;
                }
              };
              return (
                <div
                  key={question.id}
                  className='px-3 py-3 d-flex justify-content-between'
                  style={{ borderBottom: '1px solid #f1eeee' }}
                >
                  <div className='d-flex flex-grow-1 align-items-md-center'>
                    <div className='pl-0 mr-3 d-flex align-items-center'>
                      <div className='mr-2'>
                        {!isExample ? (
                          <>
                            {checkIsCorrect() ? (
                              <CheckCircleFilled style={{ color: colors.theme.successBold, fontSize: 24 }} />
                            ) : (
                              <CloseCircleFilled style={{ color: colors.theme.danger, fontSize: 24 }} />
                            )}
                          </>
                        ) : (
                          <InfoCircleFilled style={{ color: colors.theme.primary, fontSize: 24 }} />
                        )}
                      </div>
                      <div style={{ fontWeight: 600 }}>{`${i18next.t('question')} ${index + 1}`}</div>
                    </div>
                    <div style={{ color: colors.gray[500] }} className='pl-0 col col-md-9'>
                      {currentType === 'COMBINE' ? question?.questionText : question?.title}
                    </div>
                  </div>
                  {!isExample &&
                  (question?.componentType?.toUpperCase() !== 'LISTENANDRECORD' || userData.answers) &&
                  userData.answers !== 'NoData' ? (
                    <DynamicButton
                      // shape='circle'
                      type='dashed'
                      // icon={<MoreOutlined />}
                      onClick={() => {
                        setModalVisible(!isModalVisible);
                        setQuestionIndex(index);
                      }}
                    >
                      {i18next.t('Detail')}
                    </DynamicButton>
                  ) : null}
                </div>
              );
            })}
          </CardHeader>
        </div>
        {isModalVisible && (
          <ResultDetails
            data={data}
            currentType={currentType}
            questionIndex={questionIndex}
            visible={isModalVisible}
            setModalVisible={setModalVisible}
          />
        )}
      </div>
    </div>
  );
};

export default Results;
