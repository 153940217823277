import colors from 'constants/colors';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { WorksheetTypesProps } from '../../type';
import Drop from './Drop';

function DragDrop({ type, data, againAnswer, isDisableTest, isHiddenShowColor }: WorksheetTypesProps) {
  const [state, setState] = useState<any>([]);
  const [result, setResult] = useState<any>(undefined);

  useEffect(() => {
    Object.assign(type, {
      submit: () => {
        if (data.length === 0) return {};
        // const res = handleResult(state);
        const res = handleResultByGroup(state);
        // setResult(res);
        return { dragdropResult: res };
      },
      tryAgain: () => {
        // // tryAgain handle
        setState(formatData(state));
        // setResult(undefined);
      },
      setResult,
    });
    return () => {};
  }, [state]);

  useEffect(() => {
    setState(formatData(againAnswer?.userData));
  }, [data?.length, againAnswer]);

  //Render lại ==> cần lấy lại kết quả làm bài nếu có
  const formatData = (answers?: any[]) => {
    if (!data) {
      return [];
    }
    const newData = _.cloneDeep(data).map((item: any, index: number) => {
      //dùng res để lấy kết quả cũ (nếu có) - fix lỗi submit kết quả của bài khác
      //find theo vị trí thì dù có bị cache kết quả cũng không sao
      const res = answers?.find((i) => i.left === item.left && i.top === item.top);
      const answer = item.image ? item.text.match(/\d+$/g)[0] : undefined;
      return res ?? {
        ...item,
        answer,
      };
    });
    return newData;
  };

  const handleDropAtDropComponent = (draggedItem: any, droppedIndex: number) => {
    const { value, draggedIndex } = draggedItem;
    if (!value.image) return;
    const isImageExisted = state[droppedIndex].image;
    isImageExisted === undefined
      ? setState((pre: any) => {
          pre[droppedIndex].image = value.image;
          pre[droppedIndex].answer = value.answer;
          // #region Trường hợp 2: Đem theo tọa độ gốc, để có thể trả về vị trí cũ.
          pre[droppedIndex].originalIndex = value.originalIndex;
          // #endregion

          delete pre[draggedIndex].image;
          delete pre[draggedIndex].answer;
          // #region Trường hợp 2: Xóa tọa độ gốc ở vị trí cũ.
          delete pre[draggedIndex].originalIndex;
          // #endregion
          return [...pre];
        })
      : state[droppedIndex].image !== value.image &&
        setState((pre: any) => {
          // #region Trường hợp 1: Đưa hình về vị trí ban đầu.
          const { answer, image, originalIndex } = pre[droppedIndex];
          // Đưa hình vị trí hiện tại về vị trí ban đầu.
          pre[originalIndex].answer = answer;
          pre[originalIndex].image = image;
          pre[originalIndex].originalIndex = originalIndex;
          // Đưa hình mới vào vị trí hiện tại.
          pre[droppedIndex].answer = value.answer;
          pre[droppedIndex].image = value.image;
          pre[droppedIndex].originalIndex = value.originalIndex;
          // Xóa hình mới ở vị trí cũ.
          delete pre[draggedIndex].image;
          delete pre[draggedIndex].answer;
          delete pre[draggedIndex].originalIndex;
          return [...pre];
        });
  };
  //
  if (!data) return null;
  //
  return (
    <div>
      {state.map((item: any, index: number) => {
        const { angle, answer, groupName, height, image, left, mode, text, top, width, isCorrect } = item;
        const boxStyle = { border: '2px dotted gray' };

        if (isDisableTest && !isHiddenShowColor) {
          if (isCorrect === true) {
            boxStyle.border = `2px solid ${colors.theme.successBold}`;
          } else if (isCorrect === false) {
            boxStyle.border = `2px solid ${colors.theme.danger}`;
          }
        }

        return (
          <div
            key={index}
            style={{
              position: 'absolute',
              width,
              height,
              top,
              left,
              transform: `rotate(${angle}deg)`,
              cursor: isDisableTest ? 'no-drop' : 'pointer',
            }}
          >
            <Drop
              boxStyle={boxStyle}
              value={item}
              onDrop={(draggedItem) => {
                if (isDisableTest) return {};
                handleDropAtDropComponent(draggedItem, index);
              }}
              draggedIndex={index}
            />
          </div>
        );
      })}
    </div>
  );
}

DragDrop.propTypes = {
  type: PropTypes.object,
  data: PropTypes.array,
};

DragDrop.defaultProps = {
  type: {},
  data: [],
};

export default DragDrop;

// Tính điểm theo nhóm (sai 1 chỗ, vẫn tính điểm chỗ khác)
const handleResultByGroup = (userData: any) => {
  const DROP = 'Drop';
  const DRAG = 'Drag';
  const newState = userData.map((item: any) => {
    const { text, answer, image } = item;
    const [type, number] = text.split(':');
    let isCorrect = null;
    //Chỉ hiện màu, xét đúng/sai ở vị trí có hình ảnh
    if (type === DROP && image) {
      isCorrect = number === answer;
    } else if (type === DRAG && image) {
      // nếu DRAG có hình ảnh thì là sai
      isCorrect = false;
    }
    return { ...item, isCorrect };
  });

  const newStateOnlyDrop = newState.filter((item: any) => item.text.includes('Drop'));

  const groups = _.groupBy(newStateOnlyDrop, 'groupName');

  // #region Tính điểm theo nhóm (sai 1 chỗ, vẫn tính điểm chỗ khác).
  const resultByGroup = _.map(groups, (item) => {
    const correctByGroup = _.countBy(item, 'isCorrect').true ?? 0;
    const totalByGroup = item.length;
    const score = correctByGroup / totalByGroup;
    return { groupName: item[0].groupName, score };
  });
  // #endregion

  const correctQuestions = resultByGroup
    .reduce((previousValue, currentValue) => previousValue + currentValue.score, 0)
    .toFixed(2);
  const totalQuestions = resultByGroup.length;

  const percent = (parseInt(correctQuestions) * 100) / totalQuestions;
  const resultString = `${correctQuestions}/${totalQuestions}`;
  const star = percent / 20;

  // #region Số câu đã làm - không tính vị trí đúng sai trong câu => cứ có làm là tính hoàn thành 1 câu.
  const groupsName = _.groupBy(newState, 'groupName');
  const count = Object.values(groupsName).filter((group) =>
    group.some(({ text, image }) => text.includes(DROP) && image),
  ).length;
  // #endregion
  const complete = `${count}/${totalQuestions}`;

  return { userData: newState, percent: percent || 0, resultString, star: star || 0, complete };
};

// Tính điểm theo từng vị trí HOẶC tính điểm theo nhóm (sai 1 chỗ trong nhóm => sai cả câu)
const handleResult = (userData: any) => {
  // const newState = userData.map((item) => {
  //   if (!item.image) return item;
  //   item.isCorrect = _.startsWith(item.text, 'Drop') && item.text.match(/\d+$/g)[0] === item.answer;
  //   return item;
  // });
  //
  const DROP = 'Drop';
  const newState = userData.map((item: any) => {
    const { text, answer } = item;
    const [type, number] = text.split(':');
    let isCorrect = null;
    if (type === DROP) {
      isCorrect = number === answer;
    }
    return { ...item, isCorrect };
  });

  // #region Tính điểm theo nhóm.
  // const groups = _.groupBy(newState, 'groupName');
  // trong 1 groupName có nhiều vị trí điền, chỉ cần 1 vị trí điền sai, thì cả groupName đó sai
  // Nếu có 1 chỗ sai trong groupName => groupName đó đúng => Phủ định của đúng là sai
  // const booleanArray = _.toArray(groups).map((item) => !_.some(item, ['isCorrect', false]));
  // #endregion

  // #region Tính điểm theo vị trí.
  const groups = newState.filter((x: any) => x.text.split(':')[0] === DROP);
  const booleanArray = groups.map((item: any) => item.isCorrect);
  // #endregion

  const correct = booleanArray.filter((x: any) => x).length;
  const total = booleanArray.length;

  const percent = (parseInt(correct) * 100) / total;
  const resultString = `${correct}/${total}`;
  const star = percent / 20;

  // #region Số câu đúng theo nhóm.
  // const count = Object.values(groups).filter(group => group.some(({ text, image }) => text.includes(DROP) && image)).length;
  // #endregion

  // #region Số câu đúng theo vị trí.
  const count = Object.values(groups).filter((group: any) => group.text.includes(DROP) && group.image).length;
  // #endregion

  const complete = `${count}/${total}`;

  return { userData: newState, percent, resultString, star, complete };
};
