import _ from 'lodash';
import React from 'react';
import { FolderOutlined, WarningOutlined } from '@ant-design/icons';
import { ReactNode } from 'react-markdown';
import moment from 'moment';
import { Tooltip } from 'antd';
import { convertToSlug } from 'services/Text/formatText';

const Title = ({ text, color }: { text: string; color: string }) => {
  return (
    <React.Fragment>
      <FolderOutlined style={{ color, fontSize: 16 }} className='tw-mr-1' />
      <span style={{ fontWeight: 400 }}>{` ${text}`}</span>
    </React.Fragment>
  );
};

type TreeNode = {
  key: string;
  title: any;
  color: string;
  children: Array<TreeNode>;
  level: number;
};

type SelectTreeNode = {
  value: string;
  title: ReactNode;
  color: string;
  children: Array<SelectTreeNode>;
  level: number;
};
// Code mới dùng đệ quy - không giới hạn các cấp thư mục
export function buildTreeData(records: Array<any>, parentId: string | null = null, level: number = 0): Array<TreeNode> {
  
  if (records && records.length > 0) {
    const nodes: Array<TreeNode> = [];
    const filteredRecords = records.filter((record) => record.parentId === parentId);
    for (const record of _.sortBy(filteredRecords, ['sortOrder'])) {
      const children = buildTreeData(records, record.id, level + 1);
      let titleName = record.name;
      let error ; // Nếu có lỗi dữ liệu
      if (level === 1) {
        if (record.startDate && record.finishDate) {
          titleName = `${record.name} (${moment(record.startDate).format('DD/MM/yyyy')} - ${moment(record.finishDate).format(
              'DD/MM/yyyy',
            )})`
        }
          if (record.week && convertToSlug(record.name).includes('tuan')) {
            const splitChar = record.name.trim().replaceAll('  ',' ').split(' ');
            if (parseInt(splitChar[1]) !== record.week) {
              error = 'Giá trị tuần học không đúng với tên thư mục'
            }
          }
      };
      const title = <span>{titleName}{error ? <>&ensp;<Tooltip title={error}><WarningOutlined style={{color:'red'}}/></Tooltip></> : ''}</span>
      const node: TreeNode = {
        key: record.id,
        // Ở node cấp 2, level=== 1 thì hiển thị ngày
        title:title,
        color: record.color,
        children,
        level: level,
      };
      nodes.push(node);
    }

    return nodes;
  }
  return [];
}

export function buildSelectTreeData(
  records: Array<any>,
  parentId: string | null = null,
  level: number = 0,
): Array<SelectTreeNode> {
  if (records && records?.length > 0) {
    const nodes: Array<SelectTreeNode> = [];
    const filteredRecords = records.filter((record) => record.parentId === parentId);

    for (const record of _.sortBy(filteredRecords, ['sortOrder'])) {
      const children = buildSelectTreeData(records, record.id, level + 1);
      const node: SelectTreeNode = {
        value: record.id,
        title: <Title text={record.name} color={record.color} />,
        color: record.color,
        children,
        level: level,
      };
      nodes.push(node);
    }

    return nodes;
  }
  return [];
}

// Code cũ giới hạn tạo ra được cây thư mục 4 cấp
// export function buildTreeData(records: any): Array<TreeNode> {
//   const treeData: Array<TreeNode> = [];
//   // Level 0
//   const l0Records = _.filter(records, (r) => r.parentId === null);
//   _.forEach(_.sortBy(l0Records, ['sortOrder']), (l0) => {
//     const item0: TreeNode = { key: l0.id, title: l0.name, color: l0.color, children: [], level: 0 };
//     treeData.push(item0);
//     // Level 1
//     const l1Records = _.filter(records, (l1) => l1.parentId === l0.id);
//     _.forEach(_.sortBy(l1Records, ['sortOrder']), (l1) => {
//       const item1: TreeNode = {
//         key: l1.id,
//         title:
//           l1.startDate && l1.finishDate
//             ? `${l1.name} (${moment(l1.startDate).format('DD/MM/yyyy')} - ${moment(l1.finishDate).format(
//                 'DD/MM/yyyy',
//               )})`
//             : l1.name,
//         color: l1.color,
//         children: [],
//         level: 1,
//       };
//       item0.children.push(item1);
//       // Level 2
//       const l2Records = _.filter(records, (l2) => l2.parentId === l1.id);
//       _.forEach(_.sortBy(l2Records, ['sortOrder']), (l2) => {
//         const item2: TreeNode = { key: l2.id, title: l2.name, color: l2.color, children: [], level: 2 };
//         item1.children.push(item2);
//         // Level 3
//         const l3Records = _.filter(records, (l3) => l3.parentId === l2.id);
//         _.forEach(_.sortBy(l3Records, ['sortOrder']), (l3) => {
//           const item3: TreeNode = { key: l3.id, title: l3.name, color: l3.color, children: [], level: 3 };
//           item2.children.push(item3);
//         });
//       });
//     });
//   });
//   return treeData;
// }

// export function buildSelectTreeData(records: any): Array<SelectTreeNode> {
//   const treeData: Array<SelectTreeNode> = [];
//   // Level 0
//   const l0Records = _.filter(records, (r) => r.parentId === null);
//   _.forEach(_.sortBy(l0Records, ['sortOrder']), (l0) => {
//     const item0: SelectTreeNode = {
//       value: l0.id,
//       title: <Title text={l0.name} color={l0.color} />,
//       color: l0.color,
//       children: [],
//       level: 0,
//     };
//     treeData.push(item0);
//     // Level 1
//     const l1Records = _.filter(records, (l1) => l1.parentId === l0.id);
//     _.forEach(_.sortBy(l1Records, ['sortOrder']), (l1) => {
//       const item1: SelectTreeNode = {
//         value: l1.id,
//         title: <Title text={l1.name} color={l1.color} />,
//         color: l1.color,
//         children: [],
//         level: 1,
//       };
//       item0.children.push(item1);
//       // Level 2
//       const l2Records = _.filter(records, (l2) => l2.parentId === l1.id);
//       _.forEach(_.sortBy(l2Records, ['sortOrder']), (l2) => {
//         const item2: SelectTreeNode = {
//           value: l2.id,
//           title: <Title text={l2.name} color={l2.color} />,
//           color: l2.color,
//           children: [],
//           level: 2,
//         };
//         item1.children.push(item2);
//         // Level 3
//         const l3Records = _.filter(records, (l3) => l3.parentId === l2.id);
//         _.forEach(_.sortBy(l3Records, ['sortOrder']), (l3) => {
//           const item3: SelectTreeNode = {
//             value: l3.id,
//             title: <Title text={l3.name} color={l3.color} />,
//             color: l3.color,
//             children: [],
//             level: 3,
//           };
//           item2.children.push(item3);
//         });
//       });
//     });
//   });
//   return treeData;
// }
