import { query } from 'helpers/QueryHelper';

export const SelectResultExercise = async (
  sqlCommand: string,
  parameters: any,
  setResult: Function,
  setLoading: Function,
) => {
  await query(sqlCommand, parameters)
    .then((response: any) => {
      if (response?.length > 0) {
         setResult(response?.[0]);
      }
    })
    .finally(() => setLoading(false));
};
