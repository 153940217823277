import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Breadcrumb, Button, Card, Modal, Result, Spin, Tooltip, message } from 'antd';
import NoData from 'components/NoData';
import Container from 'components/Containers/Container';
import ExcercisePage from 'modules/WorkSheet/CanvasToolPlus/ExcercisePage';
import { query } from 'helpers/QueryHelper';
import '../style.css';
import Axios from 'axios';
import moment from 'moment';
import settings from 'constants/settings';
import DynamicButton from 'packages/DynamicButton/DynamicButton';

const ViewReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState({ open: false, title: '' });
  const [dataResult, setDataResult] = useState([]);
  const [jsonData, setJsonData] = useState(null);
  const [audio, setAudio] = useState();
  const [isResult, setIsResult] = useState(false);
  const { handoutCode } = useParams();
  const aggregationTexts = {
    submissionRateText: 'Submission Rate',
    averageSubmissionRateText: 'Average submission rate',
    averageScoreText: 'Average score',
    highestScoreText: 'Highest score',
    lowestScoreText: 'Lowest score',
  };
  const arrayOfAggregationTexts = Object.values(aggregationTexts);

  // Tự động làm mới dữ liệu sau 10s
  useEffect(() => {
    const refresh = setInterval(() => {
      // Khi đã gửi mail thì không cần cập nhật kết quả nữa
      if (!isResult)
        query('p_QUIZCITY_Academy_LinkHandout_Teacher ', {
          HandoutCode: handoutCode,
          userId: 0,
        }).then((res) => {
          setJsonData(res?.[0]?.jsonData?.[0]);
        });
    }, 10000);
    return () => {
      // *** Clear the interval on unmount
      clearInterval(refresh); // ***
    };
  }, [isResult]);

  useEffect(() => {
    setIsLoading(true);
    query('p_QUIZCITY_Academy_LinkHandout_Teacher ', {
      HandoutCode: handoutCode,
      userId: 0,
    })
      .then((res) => {
        setJsonData(res?.[0]?.jsonData?.[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('🚀 ~p_QUIZCITY_Academy_LinkHandout_Dashboard err:', err);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!visibleModal?.open) setDataResult({});
  }, [visibleModal?.open]);
  // Tổng số lượt nộp bài / tổng số lần làm bài
  const calcSubmissionRate = (dataFormat, totalPages) => {
    // khởi tạo các field theo dataIndex của column
    const row = { name: 'Submission Rate' };
    for (let i = 1; i <= totalPages; i++) {
      row['p' + i] = 0;
    }
    //tổng số lượt nộp bài
    dataFormat.forEach((element) => {
      Object.entries(element).forEach(([key, value], index) => {
        if (key.includes('p') && key.length <= 3 && typeof value === 'number') row[key]++;
      });
    });
    // format output
    for (let i = 1; i <= totalPages; i++) {
      row['p' + i] = row['p' + i] + '/' + dataFormat.length;
    }
    return row;
  };
  // Trung bình (%) lượt nộp bài
  const calcAverageSubmissionRate = (dataFormat, totalPages) => {
    // khởi tạo biến đếm
    const row = {};
    for (let i = 1; i <= totalPages; i++) {
      row['p' + i] = 0;
    }
    dataFormat.forEach((element) => {
      Object.entries(element).forEach(([key, value], index) => {
        if (key.includes('p') && key.length <= 3 && typeof value === 'number') row[key]++;
      });
    });
    let sum = 0;
    Object.entries(row).forEach(([key, value], index) => {
      if (key.includes('p') && key.length <= 3) sum = sum + value / dataFormat.length;
    });
    return (sum / totalPages).toFixed(4) * 100 + '%';
  };
  // Điểm trung bình
  const calcAverageScore = (dataFormat, totalPages) => {
    // Khởi tạo
    const row = {};
    for (let i = 1; i <= totalPages; i++) {
      row['p' + i] = 0;
    }
    // tổng điểm theo trang
    dataFormat.forEach((element) => {
      Object.entries(element).forEach(([key, value], index) => {
        if (key.includes('p') && key.length <= 3 && typeof value === 'number') row[key] = row[key] + value;
      });
    });
    // tính trung bình điểm theo số học sinh
    for (let i = 1; i <= totalPages; i++) {
      row['p' + i] = (row['p' + i] / dataFormat.length).toFixed(2);
    }
    return { name: 'Average score', ...row };
  };
  // Điểm cao nhất
  const calcHighestScore = (dataFormat, totalPages) => {
    // Khởi tạo
    const count = {}; // biến đếm, nếu không tìm được điểm cao nhất thì để trống
    const row = {};
    for (let i = 1; i <= totalPages; i++) {
      count['p' + i] = 0;
      row['p' + i] = 0;
    }
    // tìm điểm cao nhất theo trang
    dataFormat.forEach((element) => {
      Object.entries(element).forEach(([key, value], index) => {
        if (key.includes('p') && key.length <= 3 && row[key] <= value && typeof value === 'number') {
          count[key]++;
          row[key] = value;
        }
      });
    });
    // Kiểm tra lại xem có trang nào toàn 'No submit' không
    dataFormat.forEach((element) => {
      Object.entries(element).forEach(([key, value], index) => {
        if (count[key] === 0) {
          row[key] = '';
        }
      });
    });
    return { name: 'Highest score', ...row };
  };
  // Điểm thấp nhất
  const calcLowestScore = (dataFormat, totalPages) => {
    // Khởi tạo
    const row = {};
    for (let i = 1; i <= totalPages; i++) {
      row['p' + i] = 100;
    }
    // Tìm điểm thấp nhất
    dataFormat.forEach((element) => {
      Object.entries(element).forEach(([key, value], index) => {
        if (key.includes('p') && key.length <= 3) {
          if (value === 'No submisson') row[key] = 0;
          else if (row[key] > value) row[key] = value;
        }
      });
    });
    return { name: 'Lowest score', ...row };
  };

  // Format json về dạng dữ kiệu đưa vào DynamicTable
  const createTableData = (data) => {
    if (data) {
      const dataOuput = data?.details?.map((item, index) => {
        // Khởi tạo
        const row = { name: item.name, id: item.id, isOnClick: true, handoutId: data.id };
        // Thêm điểm theo trang và slideId của trang đó
        item.pages.forEach((e, i) => {
          row['p' + e.no] = e.score === null || e.score === undefined ? 'No submisson' : e.score;
          row['slideId_p' + e.no] = e.id;
          row['slideName_p' + e.no] = e.name;
        });
        return row;
      });
      // Output
      const output = [
        ...dataOuput,
        calcSubmissionRate(dataOuput, data.totalPages),
        { name: 'Average submission rate', p1: calcAverageSubmissionRate(dataOuput, data.totalPages) },
        calcAverageScore(dataOuput, data.totalPages),
        calcHighestScore(dataOuput, data.totalPages),
        calcLowestScore(dataOuput, data.totalPages),
      ];
      return output;
    }
    return [];
  };

  // Tạo column động theo số lượng trang mà database trả về
  const createTableColumn = (data) => {
    if (data) {
      // Khởi tạo các cột điểm
      const columnPage = [];
      // lấy tất cả slide bài làm của tất cả học sinh
      const allSlide = data.details.map((element) => element.pages).flat();
      // tìm tên bài silde của từng trang
      const slideName = {};
      allSlide.forEach((item) => {
        slideName[item.no] = item.name;
      });
      // thêm số column theo số trang bài tập
      for (let index = 1; index <= data.totalPages; index++) {
        columnPage.push({
          // title: `Trang ${index}`,
          title: slideName[index],
          dataIndex: 'p' + String(index),
          key: 'p' + String(index),
          render: (text, record) => {
            // nếu không phải là các hàng tính toán trong arrayOfAggregationTexts thì sẽ có className='cellHover'
            return arrayOfAggregationTexts.includes(record.name) ? (
              <span style={{ fontWeight: 600, color: 'blue' }}>{text}</span>
            ) : (
              <div
                className={
                  record.isOnClick && (record['p' + String(index)] || record['p' + String(index)] === 0)
                    ? 'cellHover'
                    : ''
                }
              >
                {text}
              </div>
            );
          },
          // Click 1 cell
          onCell: (record, rowIndex) => {
            if (record.isOnClick && (record['p' + String(index)] || record['p' + String(index)] === 0))
              return {
                onClick: (ev) => {
                  handleClickCell(record, 'p' + String(index));
                },
              };
          },
          sort: false,
        });
      }
      return {
        rowKey: 'name',
        displayColumns: [
          {
            title: settings.studentPronouns2,
            dataIndex: 'name',
            key: 'name',
            width: 300,
            render: (text, record) => {
              return arrayOfAggregationTexts.includes(record.name) ? (
                <span style={{ fontWeight: 600, color: 'blue' }}>{text}</span>
              ) : (
                text
              );
            },
            sort: false,
          },

          ...columnPage,
        ],
      };
    }
    return [];
  };

  // TODO: Load all answer của học sinh
  const queryAllAnswerFromDB = (studentId, slideId, handoutId) => {
    setIsLoading(true);
    query('p_QUIZCITY_Handout_GetResult_TypeWorksheet_ByStudent ', {
      SlideId: slideId,
      HandoutId: handoutId,
      StudentId: studentId,
    })
      .then((response) => {
        setDataResult(response?.[0]?.jsonData);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log('🚀 ~p_QUIZCITY_Handout_GetResult_TypeWorksheet_ByStudent err:', err);
        setIsLoading(false);
      });
  };

  const handleClickCell = (record, columnName) => {
    queryAllAnswerFromDB(record.id, record['slideId_' + columnName], record.handoutId);
    // setVisibleModal({ open: true, title: ` | ${record?.name} | ${record['slideName_' + columnName]}` });
    setVisibleModal({
      open: true,
      title: (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ marginBottom: -1 }}>BÀI LÀM: &nbsp;</div>
          <Breadcrumb style={{ fontSize: '16px' }}>
            <Breadcrumb.Item>{record?.name}</Breadcrumb.Item>
            <Breadcrumb.Item>{jsonData.presentationTitle}</Breadcrumb.Item>
            <Breadcrumb.Item>{record['slideName_' + columnName]}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      ),
    });
  };

  const handleSendEmail = () => {
    if (jsonData?.teacherEmail.length > 0) {
      const templateData = {
        teacherName: jsonData?.teacherName,
        linkTeacher: window.location.href,
        dateNo: moment().format('DD/MM/YYYY'),
        id: moment().format('YYYYMMDDHHmm'),
      };
      Axios.post('https://toeic.ames.edu.vn/ames/api/amesapi/SaveEmail', {
        to: jsonData?.teacherEmail,
        cc: '',
        bcc: '',
        subject: `Thông báo buổi học #${moment().format('YYYYMMDDHHmm')}`,
        body: '',
        bodyHTML: '',
        attachment: '',
        templateName: 'AMES_Mail_Handout_Teacher',
        templateData: JSON.stringify(templateData),
        sourceInfo: 'API SaveEmail Handout',
      })
        .then((res) => {
          if (res?.data?.Content === 'FAIL') {
            message.error('Gửi mail thất bại');
          } else {
            setIsResult(true);
          }
        })
        .catch((err) => {
          message.error('Gửi mail thất bại');
          console.log('Gửi mail thất bại ', err);
        });
    }
  };

  return (
    <React.Fragment>
      <Container fluid className='mt--6' parentName='Handout' name='Chi tiết buổi học'>
        {!isResult ? (
          <Card
            title={`Giáo viên: ${jsonData?.teacherName} | Bài tập: ${jsonData?.presentationTitle}`}
            extra={
              <>
                {/* Nếu có dữ liệu và cửa sổ được mở từ trang chủ Handout thì mới hiển thị nút*/}
                {jsonData?.details?.length > 0 && (
                  <Tooltip title={'Đóng cửa sổ và gửi email tới giáo viên'}>
                    <DynamicButton disabled={isResult} type='primary' onClick={() => handleSendEmail()}>
                      Hoàn thành
                    </DynamicButton>
                  </Tooltip>
                )}
              </>
            }
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin spinning={isLoading} />
            </div>
            {jsonData?.details?.length > 0 && jsonData?.totalPages > 0 ? (
              <div style={{ margin: 10 }}>
                <DynamicTable
                  loading={false}
                  dataSource={createTableData(jsonData)}
                  initialTableData={createTableColumn(jsonData)}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50', '100'],
                  }}
                  scroll={{
                    x: 500,
                  }}
                />
              </div>
            ) : (
              <NoData />
            )}
          </Card>
        ) : (
          <div
            style={{
              width: '100%',
              height: '70vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // margin: '0 auto',
              // marginTop: 100,
            }}
          >
            <Result
              status='success'
              title='Hoàn thành buổi học'
              subTitle='Hệ thống sẽ gửi kết quả buổi học về mail giáo viên sau ít phút.'
              extra={[
                <DynamicButton
                  type='primary'
                  onClick={() => {
                    window.location.replace(`${settings.DOMAIN_IS_USE}/${settings.PATHNAME}/home`);
                  }}
                  key='console'
                >
                  Về trang chủ
                </DynamicButton>,
              ]}
              style={{ backgroundColor: '#f8f0f0', borderRadius: '20px' }}
            />
          </div>
        )}
      </Container>
      <Modal
        width='80%'
        centered
        visible={visibleModal?.open}
        title={visibleModal?.title}
        destroyOnClose // Unmount child components on onClose.
        onCancel={() => {
          setVisibleModal({ open: false, title: '' });
        }}
        footer={[
          <DynamicButton
            type='primary'
            onClick={() => {
              setVisibleModal({ open: false, title: '' });
            }}
          >
            Đóng
          </DynamicButton>,
        ]}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin spinning={isLoading} />
        </div>
        <div>
          {dataResult && dataResult.length !== 0 ? (
            <ExcercisePage
              setAudio={audio}
              typeView={'view'}
              userAnswer={dataResult}
              loadingAnswer={false}
              isHiddenShowColor={false}
            />
          ) : (
            <NoData description='Không có dữ liệu' />
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ViewReport;
