import { Form, Input, message, Modal, Radio } from 'antd';
import ColorPicker from 'components/ColorPicker';
import colors from 'constants/colors';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import withAuthenticator from 'HOCs/withAuthenticator';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
function UpdateNotification({ loggedInUser, classId, data, visible, onOk, onCancel }) {
  //
  const { t } = useTranslation();
  const initialValues = {
    body: data && data?.body,
    color: data && data.color ? data.color : '#32325d',
    receiver: data && data?.receiver,
    title: data && data?.title,
  };
  const [processing, setProcessing] = React.useState(false);
  const [form] = Form.useForm();
  const validateContent = (rule, value, callback) => {
    if (value === '<p></p>' || value === '<p><br></p>' || !value) {
      callback(i18next.t('please enter', { field: i18next.t('table:setUpNotification.Content') }));
    } else {
      callback();
    }
  };

  React.useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, visible]);

  //
  const onFinish = async (values) => {
    setProcessing(true);
    if (data) {
      // UPDATE
      const parameters = {
        ...values,
        id: data.id,
      };
      // const response = await update('Notifications', data.id, parameters);
      const response = await query('p_VIMARU_NotificationContent_Update', parameters);
      if (response) {
        message.success(t('update success', { field: t('notification') }));
        onOk();
        setProcessing(false);
      } else {
        message.error(t('update failed', { field: t('notification') }));
        setProcessing(false);
      }
    } else {
      // INSERT
      const parameters = {
        ...values,
        avatar: loggedInUser.avatarUrl ?? '',
        classId: classId ?? 0,
        createdBy: loggedInUser.id,
        icon: '',
        name: loggedInUser?.fullName ?? '',
      };

      // query('p_QUIZCITY_User_GetListUserToken').then((res) => {
      // const newToken = [];
      // if (parameters?.receiver?.toUpperCase() === 'ALL') {
      //   res?.map((item) => {
      //     newToken.push(item);
      //   });
      // } else {
      //   res
      //     .filter((item) => {
      //       return item.userRoles?.toUpperCase() === parameters?.receiver?.toUpperCase();
      //     })
      //     ?.map((item) => {
      //       newToken.push(item);
      //     });
      // }
      // newToken?.forEach((token) => {
      //   axios.post(
      //     settings.urlSendNotification,
      //     {
      //       to: token?.token,
      //       data: {},
      //       notification: {
      //         title: parameters?.title,
      //         body: parameters?.body,
      //       },
      //     },
      //     {
      //       headers: {
      //         'Content-Type': 'application/json',
      //         Authorization: settings.serverKeyNotification,
      //       },
      //     },
      //   );
      // });

      query('p_VIMARU_Notification_Insert', parameters)
        .then((response) => {
          if (response) {
            message.success(t('update success', { field: t('notification') }));
            onOk();
          } else {
            message.error(t('update failed', { field: t('notification') }));
          }
        })
        .finally(() => setProcessing(false));
      // });
    }
  };
  return (
    <Modal
      confirmLoading={processing}
      maskClosable={false}
      centered
      title={
        data
          ? i18next.t('commonTitle.Update information', { object: i18next.t('notification') }).toUpperCase()
          : i18next.t('commonTitle.Add new', { object: i18next.t('notification').toUpperCase() })
      }
      visible={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={onCancel}
      okText={
        data
          ? i18next.t('commonTitle.Update information', { object: i18next.t('notification').toLowerCase() })
          : i18next.t('commonTitle.Add new', { object: i18next.t('notification').toLowerCase() })
      }
      cancelText={i18next.t('Close')}
    >
      <Form form={form} layout='vertical' onFinish={onFinish} requiredMark={false} initialValues={initialValues}>
        <Form.Item
          label={i18next.t('Title')}
          name='title'
          rules={[{ message: t('please enter', { field: i18next.t('Title') }), required: true }]}
        >
          <Input style={{ color: colors.font }} />
        </Form.Item>
        <Form.Item
          label={i18next.t('commonTitle.Color')}
          name='color'
          rules={[{ message: t('please enter', { field: i18next.t('commonTitle.Color') }), required: true }]}
        >
          <ColorPicker />
        </Form.Item>
        <Form.Item
          label={i18next.t('table:setUpNotification.Content')}
          name='body'
          rules={[
            {
              validator: validateContent,
            },
          ]}
        >
          <ReactQuill
            theme='snow'
            style={{ color: colors.font }}
            // modules={{ toolbar: [[{ header: [1, 2, false] }], ['bold', 'italic', 'underline', 'strike', 'blockquote'], [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }], ['link'], ['clean']] }}
            // formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image']}
          />
        </Form.Item>
        <Form.Item
          label={i18next.t('table:notification.Send to')}
          name='receiver'
          rules={[{ message: i18next.t('please choose', { field: i18next.t('Option') }), required: true }]}
        >
          <Radio.Group>
            {/* <Radio style={{ color: colors.font }} value='admin'>
              Admin
            </Radio>
            <Radio style={{ color: colors.font }} value='manager'>
              {i18next.t('Manager')}
            </Radio> */}
            <Radio style={{ color: colors.font }} value='student'>
              {settings.studentPronouns2}
            </Radio>
            <Radio style={{ color: colors.font }} value='teacher'>
              {i18next.t('table:notification.Teacher')}
            </Radio>
            <Radio style={{ color: colors.font }} value='all'>
              {i18next.t('table:notification.All')}
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}

UpdateNotification.propTypes = {
  classId: PropTypes.number,
  data: PropTypes.object,
  loggedInUser: PropTypes.object,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  visible: PropTypes.bool,
};

UpdateNotification.defaultProps = {
  classId: 0,
  data: null,
  loggedInUser: null,
  onCancel: null,
  onOk: null,
  visible: false,
};

export default withAuthenticator(UpdateNotification);
