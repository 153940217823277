import { notification } from 'antd';
import { hanhtrangsoClient } from 'configs/api';
import { generateSHA1 } from 'helpers/CryptoHelper';
import { query, queryFirst } from 'helpers/QueryHelper';
import i18next from 'i18next';
import _ from 'lodash';

export async function hanhtrangsoLogin(email = '', password = '') {
  try {
    const response = await hanhtrangsoClient.post('/login', { email, password });

    const { accessToken } = response.data.data;
    // GET MORE INFORMATION
    const baseInfo = await hanhtrangsoClient.get('/Home/GetBaseInfo', {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return {
      ...response.data.data.userProfile,
      ...baseInfo.data.data,
      ...{
        accessToken: response.data?.data?.accessToken,
        refreshToken: response.data?.data?.refreshToken,
        password,
        role: response.data.data.userProfile.roleCode === 'PUPIL' ? 'student' : 'teacher',
        ...baseInfo.data.data.userProfile,
      },
    };
  } catch (error) {
    throw new Error(error);
  }
}

export async function sachsoRegister(hanhtrangsoUser) {
  // console.log('🚀', hanhtrangsoUser);

  const { email, password } = hanhtrangsoUser;
  const fullName = `${hanhtrangsoUser.firstName} ${hanhtrangsoUser.lastName}`;
  const roleId = hanhtrangsoUser.roleCode === 'PUPIL' ? 1 : 2;
  const { schoolTypeId } = hanhtrangsoUser;
  const { city: cityId } = hanhtrangsoUser;
  const { phoneNumber: phone } = hanhtrangsoUser;

  // console.log('🚀 hanhtrangsoUser', hanhtrangsoUser);

  try {
    const registeredUser = await queryFirst(
      'p_SACHSO_Users_Register',
      { email, password: generateSHA1(password), fullName, roleId, schoolTypeId, cityId, phone },
      'SachSo',
    );
    return registeredUser;
  } catch (error) {
    throw new Error(error);
  }
}

export async function sachsoLogin(email = '', password = '') {
  try {
    const { message, data } = await queryFirst('p_SACHSO_Users_Login', { email, password: generateSHA1(password) });

    if (!data || !data?.[0]) {
      notification.error({ message: i18next.t(message) });
      if (message.includes('chưa xác thực')) {
        notification.error({
          message: i18next.t(message),
          description: i18next.t('You will be redirected to the verification page in 3 seconds'),
          duration: 0,
        });
        setTimeout(() => {
          window.location.href = `/verify?email=${encodeURIComponent(email)}`;
        }, 3000);
      }
      return undefined;
    }
    const loggedInUser = data?.[0];
    const finalUser = { ...loggedInUser, accessToken: '', refreshToken: '', password };

    if (_.find(loggedInUser.roles, (role) => role.name === 'Admin')) {
      finalUser.role = 'administrator';
    } else if (_.find(loggedInUser.roles, (role) => role.name === 'Manager')) {
      finalUser.role = 'manager';
    } else if (_.find(loggedInUser.roles, (role) => role.name === 'Teacher')) {
      finalUser.role = 'teacher';
    } else if (_.find(loggedInUser.roles, (role) => role.name === 'Student')) {
      finalUser.role = 'student';
    }
    notification.success({ message: i18next.t(`${message}`) });
    return finalUser;
  } catch (error) {
    throw new Error(error);
  }
}
export async function emailLoginGoogle(email = '') {
  try {
    const result = await query('p_QUIZCITY_LoginGmail_AMESTEACHER', { email });
    const loggedInUser = result[0].data?.[0];
    if (!loggedInUser) return { loggedInUser: undefined, message: result[0].message };
    const { password, ...noPasswordUser } = loggedInUser;
    const finalUser = { ...noPasswordUser, accessToken: '', refreshToken: '', password };

    if (_.find(loggedInUser.roles, (role) => role.name === 'Student')) {
      finalUser.role = 'student';
    } else if (_.find(loggedInUser.roles, (role) => role.name === 'Teacher')) {
      finalUser.role = 'teacher';
    } else if (_.find(loggedInUser.roles, (role) => role.name === 'Manager')) {
      finalUser.role = 'manager';
    } else if (_.find(loggedInUser.roles, (role) => role.name === 'Admin')) {
      finalUser.role = 'administrator';
    }

    return { loggedInUser: finalUser, message: result[0].message };
  } catch (error) {
    throw new Error(error);
  }
}
