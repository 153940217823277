import { message, notification } from 'antd';
import wrongSound from 'assets/audioGame/soundfalse.mp3';
import correctSound from 'assets/audioGame/tingting.mp3';
import Fireworks from 'components/Firework';
import settings, { IS_LG, IS_PT } from 'constants/settings';
import i18next from 'i18next';
import _ from 'lodash';
import { submit } from 'modules/Presentation/Viewer/actions';
import { QuestionProps } from 'modules/Presentation/Viewer/components/ExercicesTypes/type';
import { RootState } from 'modules/rootReducer';
import { SaveUserAnswerQuiz, SaveUserAnswerQuizPT } from 'pages/Student/Presentation/modules/onSubmitExercise';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import QuestionTypes from '../../../components/ExercicesTypes';
import '../index.css';
import { QuizComponentProps } from '../type';

notification.config({
  maxCount: 1,
});

const SlideQuiz = ({
  data, // array quiz
  currentQuizIndex,
  setCurrentQuizIndex,
  refQuizQuestion,
  loggedInUser,
  preview = false,
  previewMode = 'ONE',
  setCountQuizCorrectAnswers,
  setTotalScore,
  setIsDisableContinue,
  hiddenTitle = false,
  isDisableContinue,
  setArraySubmitQuestion,
  onFinishCountDown,
  setDisableButtonNextSlide,
  isExample,
  isDisableButtonNextSlide,
  takeExamTime,
  percentPass,
  //New param PT & Handout
  type,
  codeExam,
  folderId,
  slideId,
  scoreWeight,
  resultQuiz,
  isEssay,
  setAudio,
}: QuizComponentProps) => {
  // Params
  const params = useParams<{
    presentationId: string;
    slideId: string;
    questionId: string;
  }>();

  // Ref
  const refFireWork = React.useRef<any>();

  // Hooks
  const history = useHistory();

  // Redux
  const dispatch = useDispatch();
  const submittedQuestions =
    settings.IS_PT && type === 'VIEW'
      ? resultQuiz
      : useSelector((state: RootState) => state.slideQuizReducer.submittedQuestions);
  const selectedFolder = useSelector((state: RootState) => state.sidebarReducer.selectedFolder);
  const count = data?.length;
  const questions = data;
  // PT & Handout học sinh không có tài khoản đăng nhập nên chỉ truyền vào id là loggedInUser
  const studentId = settings.IS_PT || settings.IS_HANDOUT ? loggedInUser : loggedInUser.id;

  // Functions
  const next = () => {
    if (!settings.IS_PT && !settings.IS_HANDOUT) {
      const { presentationId } = params;
      setDisableButtonNextSlide(false);

      if (currentQuizIndex < count - 1) {
        history.push(
          `/${settings.PATHNAME}/student/presentations/${presentationId}/slides/${params.slideId}/questions/${
            questions[currentQuizIndex + 1]?.id
          }`,
        );
        setCurrentQuizIndex(currentQuizIndex + 1);
      } else {
        history.push(
          `/${settings.PATHNAME}/student/presentations/${presentationId}/slides/${params.slideId}/questions/result`,
        );
      }
    }
  };

  const getFireworksStart = (result: any) => {
    const audio = new Audio();
    if (result) {
      audio.src = correctSound;
      audio.play();
    } else {
      audio.src = wrongSound;
      audio.play();
    }
    return result ? refFireWork?.current.handlerClickStart() : '';
  };

  const getScoreUserData = (question: QuestionProps, userData: any) => {
    const minScore = 0;
    const maxScore = settings.IS_PT ? (scoreWeight / count).toFixed(2) : 100;
    if (userData) {
      // * Trả về điểm chuẩn cho type Record
      if (question.type.toUpperCase() === 'RECORD' || question.type.toUpperCase() === 'COMBINE') {
        return userData.result;
      }
      return userData.result ? maxScore : minScore;
    }
    return minScore;
  };

  const saveQuestionData = (question: QuestionProps, userData: any) => {
    if (settings.IS_PT) {
      SaveUserAnswerQuizPT(
        codeExam,
        question,
        slideId,
        userData,
        submittedQuestions.filter((item: any) => item.question.slideId === slideId), // Chỉ lấy những câu hỏi thuộc slideId đang đứng
        folderId,
        takeExamTime,
        isEssay ? 0 : getScoreUserData(question, userData),
        Number(scoreWeight),
        count,
        isEssay,
      );
    } else
      SaveUserAnswerQuiz(
        question,
        params?.slideId,
        getScoreUserData(question, userData),
        userData,
        loggedInUser?.id,
        takeExamTime,
        count,
        settings?.APPLICATION_NAME,
        selectedFolder?.id,
        setArraySubmitQuestion,
      );
  };

  const handleRemoveRecordUrl = (userData: any) => {
    const noRecordData = { ...userData, ...(userData.answers !== undefined && { answers: userData.answers }) };
    if (Object.keys(userData.answers ?? {}).includes('recordUrl')) {
      const { recordUrl, ...noRecordAnswers } = { ...userData.answers };
      if (userData.answers) noRecordData.answers = noRecordAnswers;
      return noRecordData;
    } else {
      return userData;
    }
  };
  const onSubmitWhenCancel = (userData: any, question: any) => {
    const noRecordData = handleRemoveRecordUrl(userData);
    saveQuestionData(question, noRecordData);
    dispatch(submit({ question: { ...question, slideId }, userData: noRecordData }));
    setTotalScore((pre) => pre + getScoreUserData(question, userData));
  };
  const onSubmit = (userData: any, question: any) => {
    const noRecordData = handleRemoveRecordUrl(userData);
    saveQuestionData(question, noRecordData);
    dispatch(submit({ question: { ...question, slideId }, userData: noRecordData }));
    if (isExample) {
      message.info(i18next.t('slide:NewViewer.QuizComponent.SlideQuiz.onSubmit.Save the post successfully!'));
      next();
    } else {
      if (!IS_PT) setDisableButtonNextSlide(true);
      setTotalScore((pre) => pre + getScoreUserData(question, userData));
      if (question.componentType === 'Essay') {
        notification.success({
          message: IS_LG
            ? 'Successfully submitted'
            : i18next.t('slide:NewViewer.QuizComponent.SlideQuiz.onSubmit.Essay submission successful!'),
          onClose() {
            next();
          },
        });
      } else if (!IS_PT) {
        const result = Number.isFinite(userData?.result) ? userData?.result >= 50 : userData?.result;
        getFireworksStart(result);
        notification.open({
          message: result ? 'CORRECT' : 'INCORRECT',
          type: result ? 'success' : 'error',
          onClose() {
            next();
          },
          duration: 1,
        });
      }

      if (userData.result) {
        setCountQuizCorrectAnswers((pre) => [...pre, 100]);
      } else {
        setCountQuizCorrectAnswers((pre) => [...pre, 0]);
      }
    }
  };

  // Render
  return (
    <div className='tw-h-full'>
      {questions?.map((question: QuestionProps, i: number) => {
        if (previewMode !== 'LIST') {
          if (i !== currentQuizIndex) return null;
        }
        const QuestionComponent = QuestionTypes[question?.componentType?.toUpperCase() as keyof typeof QuestionTypes];
        if (!QuestionComponent) return null;
        const submittedQuestion = _.find(submittedQuestions, (x: any) => {
          return x.question.id === question.id;
        });
        return (
          <div key={question.id} className='question-component tw-h-full'>
            <QuestionComponent
              data={question}
              userData={submittedQuestion?.userData}
              questionIndex={i + 1}
              ref={refQuizQuestion}
              preview={preview}
              studentId={studentId}
              setIsDisableContinue={setIsDisableContinue}
              isDisableContinue={isDisableContinue}
              hiddenTitle={hiddenTitle}
              loggedInUser={loggedInUser}
              isDisableButtonNextSlide={isDisableButtonNextSlide}
              type='SINGLE'
              onSubmit={onSubmit}
              onSubmitWhenCancel={onSubmitWhenCancel}
              showUserData={IS_PT}
              slideId={slideId}
              setAudio={setAudio}
            />
          </div>
        );
      })}
      <Fireworks ref={refFireWork} />
    </div>
  );
};
export default SlideQuiz;
