import { Col, Image, Row, message } from 'antd';
import settings from 'constants/settings';
import { amesSendMail, query, queryWithoutModuleId } from 'helpers/QueryHelper';
import i18next from 'i18next';
import moment from 'moment';
import getSettings from 'pages/Administration/api/getSettings';
import React, { useImperativeHandle } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import UploadNote from './UploadNote';
import { getDurationSlide_Seconds } from 'pages/Student/Presentation/modules/duration';

type Props = {
  preview?: boolean;
  coverUrl?: string;
};
const SlideNote = ({ preview, coverUrl }: Props, ref: any) => {
  useImperativeHandle(ref, () => ({
    onNext: () => {
      handleNext();
    },
  }));
  const { slideId } = useParams<any>();
  const loggedInUser = useSelector((state: any) => state.authReducer.loggedInUser);
  const selectedFolder = useSelector((state: any) => state.sidebarReducer.selectedFolder);

  const { data: workItem } = useQuery(['p_SACHSO_CLASSWORK_Assignments_StudentWorkItems_Get'], () =>
    query('p_QuizCity_NoteLog_WorkItems_Get', { studentId: loggedInUser.id, slideId, folderId: selectedFolder.id }),
  );
  // dùng proc lấy thông tin bộ phận, chức vụ vì trong redux không có sẵn
  const { data: student } = useQuery('p_SACHSO_USERS_GetById', () =>
    queryWithoutModuleId('p_SACHSO_USERS_GetById', { id: loggedInUser.id }),
  );
  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);

  const mailer = ConfigSettings?.find((x:any) => x?.key.toUpperCase() === 'MAILER')?.value;
  const CC = ConfigSettings?.find((x:any) => x?.key.toUpperCase() === 'CC')?.value;
  const BCC = ConfigSettings?.find((x:any) => x?.key.toUpperCase() === 'BCC')?.value;

  const handleNext = async () => {
    if (workItem?.[0]?.id)
      query('p_QuizCity_NoteLog_Get', { workItemId: workItem?.[0]?.id }).then((res) => {
        const params = {
          studentId: loggedInUser.id,
          slideId,
          folderId: selectedFolder.id,
          userId: loggedInUser.id,
          jsonData: JSON.stringify(res),
          duration: getDurationSlide_Seconds(),
        };
        query('p_QUIZCITY_API_SaveQuizNoteSummary_Log', params).then(() => {
          message.success(i18next.t('slide:NewViewer.EssayResult.SlideNote.Save the note successfully!'));
          query('p_QUIZCITY_Class_Teacher_GetInfo', { studentId: loggedInUser.id, folderId: selectedFolder.id }).then(
            (res) => {
              const today = moment(new Date()).format('DD/MM/YYYY');
              const dateNo = moment().format('YYYYMMDDhhmm');
              const { email, fullName, shopName, positionName } = student?.[0];
              const extraTitle = `${fullName}${shopName ? ` - ${shopName}` : ''}`;

              const dataSendMail = {
                to: res?.[0]?.teacherEmail ?? '',
                isSendFast: true,
                moduleId: settings.MODULE_ID,
                cc: CC,
                bcc: BCC,
                subject: `Menard Class Note ${dateNo}`,
                body: '',
                bodyHTML: '',
                attachment: '',
                templateName: 'MENARD_Mail_SubmitNote',
                sourceInfo: 'API Save Email - MENARD_Mail_SubmitNote',
                templateData: JSON.stringify({
                  extraTitle,
                  fullName: fullName,
                  hotline: '0379010101',
                  class: res[0].className,
                  shopName,
                  submittedDate: today,
                  classNoteLink: `${settings.DOMAIN_IS_USE}/${settings.PATHNAME}/teacher/classes/${res[0].classId}/studentEmail/${email}`,
                }),
                mailer:mailer, // Lấy từ phần 'Thiết lập thôg tin của admin'
              };
              amesSendMail('saveEmail', dataSendMail);
            },
          );
        });
      });
  };
  return (
    <>
      {(workItem?.[0]?.id || preview) && (
        <>
          <h6 className='mb-4 ml-4 heading-small text-muted'>Đề bài</h6>
          {coverUrl && (
            <Row gutter={[12, 12]} style={{ margin: '0px 0px 30px 0px' }}>
              {coverUrl?.split(',')?.map((url:string, index:number) => (
                <Col xs={24} md={8} lg={6}>
                  <Image key={index} style={{ width: '100%' }} src={url} />
                </Col>
              ))}
            </Row>
          )}
          <hr className='my-4' />
          <h6 className='mb-4 ml-4 heading-small text-muted'>Bài làm</h6>
          <div style={{ paddingLeft: 20 }}>
            <UploadNote preview={preview} workItemId={workItem?.[0]?.id} showQrCode={true} />
          </div>
        </>
      )}
    </>
  );
};

export default React.forwardRef(SlideNote);
