/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
// import { Button } from 'antd';
import { Badge, Skeleton, Tag, notification } from 'antd';
import StudentContainer from 'components/Containers/StudentContainer';
import colors from 'constants/colors';
import {
  IS_BASAO,
  IS_HANDOUT,
  IS_ISE,
  IS_LG,
  IS_MENARD,
  IS_PT,
  IS_TRONGDONG,
  IS_TVHH,
  PATHNAME,
  studentPronouns1,
} from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import { selectSemester } from 'modules/Sidebar/actions';
import moment from 'moment';
import { useQuery, useQueryClient } from 'react-query';
import {
  Button,
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Media,
  Nav,
  NavItem,
  Navbar,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { AUTH_LOG_OUT } from '../../modules/Login/actions/types';

const auth = ['ngothanhtung.it@gmail.com', 'nmhaof@gmail.com'];

const fetcherAdmin = () => () => {
  return query('p_VIMARU_NOTIFICATIONS_GetNotifications');
};

const fetcherUser = (userId) => () => {
  return query('p_VIMARU_GetNotification_ByUser', { userId });
};

notification.config({
  placement: 'topRight',
  bottom: 50,
  duration: 3,
});
function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const history = useHistory();
  // const [data, setData] = React.useState();
  // const [isLoading, setIsLoading] = React.useState(true);
  const queryClient = useQueryClient();
  const [isOpenBell, setIsOpenBell] = useState(false);
  // const { data, isLoading } = useGetNotifications(loggedInUser.role, loggedInUser.id);
  // useGetNotifications(loggedInUser.role, loggedInUser.id).then((res) => {
  //   setData(res?.data);
  //   setIsLoading(res?.isLoading);
  // });
  const [notifications, setNotifications] = React.useState(0);

  const fetcher = loggedInUser.role === 'administrator' ? fetcherAdmin() : fetcherUser(loggedInUser.id);
  const key = 'p_VIMARU_GET_notification_all_user';
  const { data, isLoading } = useQuery(key, () => fetcher(), {
    refetchInterval: 5 * 60 * 1000, // 5 phút (5 * 60 * 1000 milliseconds)
  });

  const dispatch = useDispatch();
  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(() => {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  };

  React.useEffect(() => {
    // Cộng tổng thông báo chưa xem.
    const number = data?.reduce((total, current) => {
      let value = 0;
      if (typeof current.status === 'boolean') {
        // Teacher, student.
        // Quy ước: True - 1 - Chưa xem, False - 0 - Đã xem.
        value = current.status ? 1 : 0;
      } else if (typeof current.status === 'string') {
        // Admin.
        value = current.status === 'ACTIVE' ? 0 : 1;
      }
      return total + value;
    }, 0);
    setNotifications(number);

    return () => {
      setNotifications(0);
    };
  }, [data, loggedInUser.role]);

  return (
    <>
      <Navbar
        className={classnames(
          'navbar-top navbar-expand',
          { 'navbar-dark ': theme === 'dark' },
          { 'navbar-light bg-secondary': theme === 'light' },
        )}
        style={{ backgroundColor: colors.theme.background }}
      >
        <Container fluid>
          <Collapse navbar isOpen>
            <Nav className='align-items-center ml-md-auto' navbar>
              <NavItem className='d-xl-none'>
                <div
                  className={classnames(
                    'pr-3 sidenav-toggler',
                    { active: sidenavOpen },
                    { 'sidenav-toggler-dark': theme === 'dark' },
                  )}
                  onClick={toggleSidenav}
                >
                  <div className='sidenav-toggler-inner'>
                    <i
                      className='sidenav-toggler-line'
                      style={{ backgroundColor: (IS_MENARD || IS_LG || IS_BASAO || IS_TRONGDONG) && colors.theme.button }}
                    />
                    <i
                      className='sidenav-toggler-line'
                      style={{ backgroundColor: (IS_MENARD || IS_LG || IS_BASAO || IS_TRONGDONG) && colors.theme.button }}
                    />
                    <i
                      className='sidenav-toggler-line'
                      style={{ backgroundColor: (IS_MENARD || IS_LG || IS_BASAO || IS_TRONGDONG) && colors.theme.button }}
                    />
                  </div>
                </div>
              </NavItem>
              {/* <NavItem className='d-sm-none'>
                <NavLink onClick={openSearch}>
                  <i className='ni ni-zoom-split-in' />
                </NavLink>
              </NavItem> */}
              <StudentContainer>
                {auth.includes(loggedInUser.email) && (
                  <NavItem nav='true'>
                    <Button
                      className='mr-2'
                      size='sm'
                      onClick={() => {
                        history.push('/lms/student/beans');
                      }}
                    >
                      200 beans
                    </Button>
                  </NavItem>
                )}
              </StudentContainer>

              {!IS_PT && !IS_HANDOUT && (
                <Dropdown isOpen={isOpenBell} toggle={() => setIsOpenBell(!isOpenBell)}>
                  <DropdownToggle className='nav-link' color='' tag='a'>
                    {/* Nếu thông báo > 9, offset dịch sang phải 8px. */}
                    <Badge
                      count={notifications}
                      offset={notifications > 9 ? [8, 0] : [0, 0]}
                      overflowCount={IS_LG ? 5 : 9}
                      size={'small'}
                    >
                      {/* Màu color của .ant-badge ảnh hưởng tới màu icon, nên style color inline. */}
                      <i
                        className='ni ni-bell-55'
                        style={{
                          color: IS_MENARD || IS_LG || IS_BASAO || IS_TRONGDONG ? colors.theme.button : 'rgba(255, 255, 255, 0.95)',
                          fontSize: 20,
                        }}
                      />
                    </Badge>
                  </DropdownToggle>
                  <DropdownMenu className='py-0 overflow-hidden dropdown-menu-xl' right>
                    {isLoading && <Skeleton active />}
                    {!isLoading && (
                      <React.Fragment>
                        <div className='px-3 py-3'>
                          <h6 className='m-0 text-sm text-muted'>
                            <span>{i18next.t('thereAre')} </span>
                            <strong className={`project-color`}>{notifications}</strong>
                            <span> {i18next.t('new announ')}</span>
                          </h6>
                        </div>

                        <ListGroup flush>
                          {data?.map((item, index) => {
                            if (index > 4) {
                              return null;
                            }
                            return (
                              <ListGroupItem
                                key={item.id}
                                className='list-group-item-action'
                                // href='#lms'
                                onClick={() => {
                                  setIsOpenBell(false);
                                  history.push({
                                    pathname: `/${PATHNAME}/notifications`,
                                    state: { id: item.id },
                                  });
                                }}
                              >
                                <Row className='align-items-center'>
                                  {/* <DropdownItem> */}
                                  <Col className='col-auto'>
                                    <Badge
                                      dot={typeof item.status === 'boolean' ? item.status : item.status !== 'ACTIVE'}
                                    >
                                      {item.avatar ? (
                                        <img
                                          alt='...'
                                          className='avatar rounded-circle'
                                          src={`${item.avatar ?? '/assets/images/ngo-nhat-linh.jpg'}`}
                                        />
                                      ) : (
                                        <FaUser className='avatar rounded-circle' />
                                      )}
                                    </Badge>
                                  </Col>
                                  <div className='col ml--2' style={{ width: 300 }}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                      <div>
                                        <h4 className='mb-0 text-sm'>
                                          {item?.typeName && IS_LG && (
                                            <Tag color={colors.theme.button}>{item?.typeName}</Tag>
                                          )}
                                          {item.title.length > 50 ? item.title.slice(0, 50) + '...' : item.title}
                                        </h4>
                                      </div>
                                      <div className='text-right text-muted'>
                                        <small>{moment(item.createdDate).fromNow()}</small>
                                        {/* <small>{moment(item.createdDate).format('DD/MM/YYYY HH:mm')}</small> */}
                                      </div>
                                    </div>
                                    <p
                                      className='mb-0 text-sm'
                                      style={{
                                        display: '-webkit-box',
                                        overflow: 'hidden',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2,
                                      }}
                                    >
                                      {
                                        new DOMParser()
                                          .parseFromString(item.body, 'text/html')
                                          .getElementsByTagName('body')[0].textContent
                                      }
                                    </p>
                                  </div>
                                  {/* </DropdownItem> */}
                                </Row>
                              </ListGroupItem>
                            );
                          })}
                        </ListGroup>
                        <DropdownItem
                          className='py-3 text-center font-weight-bold project-color'
                          href='#lms'
                          // onClick={(e) => e.preventDefault()}
                          onClick={() => {
                            history.push(`/${PATHNAME}/notifications`);
                          }}
                        >
                          {i18next.t('seeAllAnnoun')}
                        </DropdownItem>
                      </React.Fragment>
                    )}
                  </DropdownMenu>
                </Dropdown>
              )}

              {/* <Dropdown nav>
                <DropdownToggle className='nav-link' color='' tag='a'>
                  <i className='ni ni-ungroup' />
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-lg dropdown-menu-dark bg-default' right>
                  <Row className='px-4 shortcuts'>
                    <Col className='shortcut-item' href='#lms' onClick={(e) => e.preventDefault()} xs='4' tag='a'>
                      <span className='shortcut-media avatar rounded-circle bg-gradient-red'>
                        <i className='ni ni-calendar-grid-58' />
                      </span>
                      <small>Calendar</small>
                    </Col>
                    <Col className='shortcut-item' href='#lms' onClick={(e) => e.preventDefault()} xs='4' tag='a'>
                      <span className='shortcut-media avatar rounded-circle bg-gradient-orange'>
                        <i className='ni ni-email-83' />
                      </span>
                      <small>Email</small>
                    </Col>
                    <Col className='shortcut-item' href='#lms' onClick={(e) => e.preventDefault()} xs='4' tag='a'>
                      <span className='shortcut-media avatar rounded-circle bg-gradient-info'>
                        <i className='ni ni-credit-card' />
                      </span>
                      <small>Payments</small>
                    </Col>
                    <Col className='shortcut-item' href='#lms' onClick={(e) => e.preventDefault()} xs='4' tag='a'>
                      <span className='shortcut-media avatar rounded-circle bg-gradient-green'>
                        <i className='ni ni-books' />
                      </span>
                      <small>Reports</small>
                    </Col>
                    <Col className='shortcut-item' href='#lms' onClick={(e) => e.preventDefault()} xs='4' tag='a'>
                      <span className='shortcut-media avatar rounded-circle bg-gradient-purple'>
                        <i className='ni ni-pin-3' />
                      </span>
                      <small>Maps</small>
                    </Col>
                    <Col className='shortcut-item' href='#lms' onClick={(e) => e.preventDefault()} xs='4' tag='a'>
                      <span className='shortcut-media avatar rounded-circle bg-gradient-yellow'>
                        <i className='ni ni-basket' />
                      </span>
                      <small>Shop</small>
                    </Col>
                  </Row>
                </DropdownMenu>
              </Dropdown> */}
            </Nav>
            <Nav className='ml-auto align-items-center ml-md-0' navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className='pr-0 nav-link' color='' tag='a'>
                  <Media className='align-items-center'>
                    <span
                      className='avatar avatar-sm rounded-circle'
                      style={{
                        backgroundColor: IS_MENARD || IS_LG || IS_BASAO  || IS_TRONGDONG? colors.theme.button : '#ffffff',
                      }}
                    >
                      {loggedInUser.avatarUrl ? (
                        <img alt='...' src={loggedInUser.avatarUrl} />
                      ) : (
                        // <FaUser className={`${IS_MENARD && 'menard-theme-icon'} text-primary`} />
                        <FaUser className={!(IS_MENARD || IS_LG || IS_BASAO || IS_TRONGDONG) ? 'text-primary' : 'menard-theme-icon'} />
                      )}
                    </span>
                    <Media className='ml-2 d-none d-lg-block'>
                      <span
                        className='mb-0 text-sm font-weight-bold'
                        style={{ color: IS_MENARD || IS_LG || IS_BASAO  || IS_TRONGDONG? colors.theme.button : '#ffffff' }}
                      >
                        {loggedInUser?.fullName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className='noti-title' header tag='div'>
                    <h6 className='m-0 text-overflow'>
                      {IS_LG || IS_ISE || IS_TVHH
                        ? `${
                            loggedInUser?.role === 'teacher'
                              ? 'Teacher'
                              : loggedInUser?.role === 'administrator'
                              ? 'Admin'
                              : loggedInUser?.role === 'manager'
                              ? 'Manager'
                              : 'Student'
                          } account`
                        : `Tài khoản ${
                            loggedInUser?.role === 'teacher'
                              ? 'giáo viên'
                              : loggedInUser?.role === 'administrator'
                              ? 'admin'
                              : loggedInUser?.role === 'manager'
                              ? 'quản trị viên'
                              : studentPronouns1
                          }`}
                    </h6>
                  </DropdownItem>

                  {((!IS_HANDOUT && !IS_PT) || loggedInUser?.role === 'administrator') && (
                    <>
                      <DropdownItem
                        href='#lms'
                        tag='a'
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/${PATHNAME}/profile`);
                        }}
                      >
                        <i className='ni ni-single-02' />
                        {/* <span> {IS_LG ? 'Account' : 'Tài khoản'}</span> */}
                        <span> {i18next.t('Account')}</span>
                      </DropdownItem>
                      {/* <DropdownItem href='#lms' onClick={(e) => e.preventDefault()}>
                        <i className='ni ni-settings-gear-65' />
                        <span> {i18next.t('Config')}</span>
                      </DropdownItem>
                      <DropdownItem href='#lms' onClick={(e) => e.preventDefault()}>
                        <i className='ni ni-calendar-grid-58' />
                        <span> {i18next.t('index:Activities')}</span>
                      </DropdownItem>
                      <DropdownItem href='#lms' onClick={(e) => e.preventDefault()}>
                        <i className='ni ni-support-16' />
                        <span>{i18next.t('Support')}</span>
                      </DropdownItem> */}
                    </>
                  )}
                  <DropdownItem divider />
                  <DropdownItem
                    // href='#lms'
                    onClick={(e) => {
                      localStorage.clear();
                      history.push(`/${PATHNAME}`);
                      dispatch({ type: AUTH_LOG_OUT });
                      dispatch(selectSemester(null));
                    }}
                  >
                    <i className='ni ni-user-run' />
                    <span> {i18next.t('LogOut')}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  sidenavOpen: false,
  theme: 'dark',
  toggleSidenav: () => {},
};
AdminNavbar.propTypes = {
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
  toggleSidenav: PropTypes.func,
};

export default AdminNavbar;
