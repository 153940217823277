import { Badge, Modal, Tabs } from 'antd';
import colors from 'constants/colors';
import { IS_LG } from 'constants/settings';
import i18next from 'i18next';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import EssayTab from './EssayTab';
import NoteTab from './NoteTab';

const { TabPane } = Tabs;
const StudentsEssay = ({ record }) => {
  const [visible, setVisible] = React.useState(false);
  const params = useParams();
  const { t } = useTranslation('');
  return (
    // 'Learners Management' Bài làm học viên
    <React.Fragment>
      <DynamicButton type='text' style={{}} onClick={() => setVisible(true)}>
        <Badge offset={[5, -5]} count={record?.totalNoMark} overflowCount={IS_LG ? 5 :999}>
          <span style={{ color: colors.theme.button }}>{i18next.t('table:classInformation.Mark')}</span>
        </Badge>
      </DynamicButton>
      <Modal
        centered
        width='90vw'
        open={visible}
        title={i18next.t('Submition').toUpperCase()}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[
          <DynamicButton
            key='close-modal'
            onClick={() => {
              setVisible(false);
            }}
          >
            {t('Close')}
          </DynamicButton>,
        ]}
        bodyStyle={{
          height: 'calc( 100vh - 120px )',
          overflowY:'auto',
          padding: '0px 24px'
        }}
        destroyOnClose
      >
        <Tabs defaultActiveKey='1' type='line' size='middle' style={{ minHeight: 300, fontWeight: 600 }}>
          <TabPane tab={<span>{i18next.t('slide:Essay.Essay work')}</span>} key='student-essay'>
            <EssayTab record={record} classId={params.classId} />
          </TabPane>
          <TabPane tab={<span>{i18next.t('slide:Essay.Exercise Notes')}</span>} key='student-note'>
            <NoteTab record={record} classId={params.classId} />
          </TabPane>
        </Tabs>
      </Modal>
    </React.Fragment>
  );
};

StudentsEssay.propTypes = {
  classId: PropTypes.string,
};

StudentsEssay.defaultProps = {
  classId: '',
};

export default StudentsEssay;
