import settings from 'constants/settings';
import Class_Students from 'modules/Teacher/components/EnterExamScore/Students/index.js';
import IndexAddQuestion from 'pages/Administration/FormAddQuestions';
import ManagerCoachingSchedule from 'pages/Administration/ManagerCoachingSchedule';
import ManagerRequestSupport from 'pages/Administration/ManagerRequestSupport';
import ManagerTeacher from 'pages/Administration/ManagerTeacher';
import Teacher_Classes_Details from 'pages/Teacher/Classes/ClassDetails';
import Teacher_Classes from 'pages/Teacher/Classes/Classes';
import React from 'react';
import { AiOutlineSchedule } from 'react-icons/ai';
import { FaChalkboardTeacher, FaListAlt } from 'react-icons/fa';
import { SiGoogleclassroom } from 'react-icons/si';
import { TbReportAnalytics } from 'react-icons/tb';

export default [
  {
    component: Teacher_Classes,
    exact: true,
    icon: <SiGoogleclassroom size={20} className='tw-text-red-500 project-color' />,
    layout: `/${settings.PATHNAME}`,
    miniName: 'C',
    name: 'Learners Management',
    path: '/teacher/classes',
    roles: ['teacher'],
    showInSidebar: true,
    sortOrder: 1,
    type: ['allowedRoutes'],
  },
  // {
  //   path: '/admin/presentations',
  //   name: 'Lectures Management',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Teacher/Presentations')),
  //   icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500 project-color' />,
  //   layout: `/${settings.PATHNAME}`,
  //   exact: true,
  //   roles: ['teacher'],
  //   type: ['allowedRoutes'],
  //   showInSidebar: true,
  //   sortOrder: 4,
  // },
  // // {
  // //   collapse: true,
  // //   path: '/admin/worksheets',
  // //   name: 'Assignments Management',
  // //   miniName: 'C',
  // //   icon: <IoLibraryOutline size={20} className='tw-text-green-600 project-color' />,
  // //   roles: ['teacher'],
  // //   type: ['allowedRoutes'],
  // //   showInSidebar: true,
  // //   sortOrder: 5,
  // //   state: 'lms/admin/worksheets',
  // //   views: [
  // //     {
  // //       path: '/admin/worksheets/list/:filter',
  // //       component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
  // //       layout: `/${settings.PATHNAME}`,
  // //       exact: true,
  // //       roles: ['teacher'],
  // //       type: ['allowedRoutes'],
  // //     },
  // //     {
  // //       path: '/admin/worksheets/list/quizcity-worksheet',
  // //       name: 'Worksheet Exercises',
  // //       miniName: 'D',
  // //       component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
  // //       layout: `/${settings.PATHNAME}`,
  // //       exact: true,
  // //       roles: ['teacher'],
  // //       type: ['allowedRoutes'],
  // //       showInSidebar: true,
  // //       sortOrder: 1,
  // //     },
  // //     {
  // //       path: '/admin/worksheets/list/quizcity-quiz',
  // //       name: 'Quiz Exercises',
  // //       miniName: 'D',
  // //       component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
  // //       layout: `/${settings.PATHNAME}`,
  // //       exact: true,
  // //       roles: ['teacher'],
  // //       type: ['allowedRoutes'],
  // //       showInSidebar: true,
  // //       sortOrder: 2,
  // //     },
  // //   ],
  // // },
  // {
  //   path: '/admin/import-question',
  //   name: 'Questions Management',
  //   miniName: 'C',
  //   icon: 'fas fa-question text-green project-color',
  //   component: IndexImportQuestion,
  //   layout: `/${settings.PATHNAME}`,
  //   exact: true,
  //   roles: ['teacher'],
  //   type: ['allowedRoutes'],
  //   showInSidebar: true,
  //   sortOrder: 6,
  // },
  {
    component: ManagerCoachingSchedule,
    exact: true,
    icon: <AiOutlineSchedule size={20} className='project-color' />,
    layout: `/${settings.PATHNAME}`,
    miniName: 'C',
    name: 'Manager Coaching Schedule',
    path: '/admin/manager-coaching-schedule',
    roles: ['teacher'],
    showInSidebar: true,
    sortOrder: 6,
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/teacher-category',
    name: 'Teacher Category',
    miniName: 'C',
    icon: <FaChalkboardTeacher size={16} className='project-color' />,
    component: ManagerTeacher,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 7,
  },
  {
    path: '/admin/request-support-management',
    name: 'Request Support',
    miniName: 'C',
    icon: <FaListAlt className='project-color' />,
    component: ManagerRequestSupport,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 8,
  },
  {
    path: '/admin/request-support-management/:requestId',
    component: ManagerRequestSupport,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
  },
  {
    collapse: true,
    path: '/admin/report',
    name: 'Statistics',
    miniName: 'C',
    icon: <TbReportAnalytics size={20} className='project-color' />,
    roles: ['teacher'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 9,
    state: 'lms/admin/report',
    views: [
      {
        path: '/admin/courses_report',
        name: 'Course Report',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportCourses')),
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['teacher'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },
  {
    component: Teacher_Classes_Details,
    exact: true,
    layout: `/${settings.PATHNAME}`,
    path: '/teacher/classes/:classId',
    roles: ['teacher'],
    type: ['allowedRoutes'],
  },
  {
    component: Class_Students,
    exact: true,
    layout: `/${settings.PATHNAME}`,
    path: '/teacher/enter-exam-score/classes/:classId',
    roles: ['teacher'],
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/add-question',
    miniName: 'C',
    component: IndexAddQuestion,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
    showInSidebar: false,
  },
  {
    path: '/admin/presentation/:presentationId/create',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/presentation/:presentationId/edit',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/classes/:classId',
    component: Teacher_Classes_Details,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/add',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/edit/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/details/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/Details')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
  },
];
