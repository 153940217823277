import React, { useState } from 'react';
import { Modal } from 'reactstrap';

import { CloseCircleOutlined } from '@ant-design/icons';
import withAuthenticator from 'HOCs/withAuthenticator';
import { Row } from 'antd';
import { IS_BASAO, IS_ISE, IS_LG, IS_MENARD, IS_TRONGDONG, IS_TVHH, IS_VIMARU } from 'constants/settings';
import i18next from 'i18next';
import VimaruLoginPopup from 'modules/Auth/components/LoginPopup/VimaruLoginPopup';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import LGHomeModal from 'pages/LGinnotek/LGModal/LGHomeModal';
const href = IS_VIMARU
  ? 'http://www.vimaru.edu.vn/'
  : IS_LG
  ? 'https://www.lginnotek.com/'
  : IS_BASAO
  ? 'https://tristarvn.com.vn/'
  : IS_TRONGDONG
  ? 'https://trongdongpalace.com/'
  : 'https://menard.vn/';

function VimaruNavbar() {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 576;
  const bannerUrl = IS_VIMARU
    ? require('assets/img/introduce/vimaru_background.jpg').default
    : IS_LG
    ? require('assets/img/introduce/background-login-lg.png').default
    : IS_TVHH
    ? require('assets/img/introduce/bg_thuyenvien.jpg').default
    : IS_ISE
    ? require('assets/img/introduce/bg_ise.jpeg').default
    : IS_BASAO
    ? require('assets/img/introduce/bg_basao_login.png').default
    : IS_TRONGDONG
    ? require('assets/img/introduce/bg_trong_dong_login.png').default
    : isMobile
    ? require('assets/img/introduce/menard_home_phone.png').default
    : require('assets/img/introduce/menard_home.jpg').default;
  const extraStyle =
    isMobile && IS_MENARD
      ? {
          padding: `150px 20vw 30px 20vw`,
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
        }
      : {
          padding: `30px 50px`,
          justifyContent: 'flex-end',
        };

  const customStyleBtn = IS_VIMARU
    ? { color: 'white', fontSize: 14, fontWeight: 600, border: 'none', backgroundColor: '#1c3691' }
    : { color: 'white', fontSize: 14, fontWeight: 600, border: 'none' };
  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });
  return (
    <Row
      style={{
        backgroundImage: `url(${bannerUrl}) `,
        // backgroundSize: '100% 100% ',
        backgroundSize: 'cover',
        backgroundPosition:'center',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        gap: 20,
        rowGap: 10,
        ...extraStyle,
      }}
    >
      {IS_VIMARU || IS_MENARD || IS_BASAO || IS_TRONGDONG? (
        <DynamicButton type='primary' size='large' onClick={() => window.open(href)} style={customStyleBtn}>
          Trang chủ
        </DynamicButton>
      ) : (
        <></>
      )}
      <DynamicButton type='primary' size='large' onClick={() => setModalVisible(true)} style={customStyleBtn}>
        {i18next.t('auth:login')}
      </DynamicButton>

      <Modal isOpen={modalVisible} centered backdrop='static' backdropClassName='sachso-modal-backdrop'>
        <VimaruLoginPopup />
        <div style={{ padding: 12, position: 'absolute', right: 0, top: 0 }}>
          <CloseCircleOutlined
            style={{ cursor: 'pointer', fontSize: 24 }}
            onClick={() => {
              setModalVisible(false);
            }}
          />
        </div>
      </Modal>
      {/* modal LG Home */}
      {IS_LG || IS_ISE || IS_TVHH ? (
        <LGHomeModal setModalVisible={setModalVisible} />
      ) : (
        <></>
      )}
    </Row>
  );
}

export default withAuthenticator(VimaruNavbar);
