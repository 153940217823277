import { notification } from 'antd';
import Listen from 'components/Listening';
import PronunciationAssessment from 'components/Modal/PronunciationAssessment';
import Recorder from 'components/Recording/react_mic_v3';
import functions, { uuid } from 'components/functions';
import React from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import SuggestBlockquote from '../../../common/Blockquote/SuggestBlockquote';
import TextBlockQuote from '../../../common/Blockquote/TextBlockQuote';
import QuestionHeader from '../../QuestionHeader';
import { QuestionComponentProps, QuestionComponentRef } from '../../type';
import RecorderTypeContent from '../components/RecorderTypeContent';
import { ResultRecordProps } from '../types/recordType';

notification.config({
  placement: 'top',
  maxCount: 2,
  duration: 1,
});

const ListenAndRecord = (
  {
    studentId,
    startRecord,
    data,
    userData,
    onSubmit,
    questionIndex,
    setIsDisableContinue,
    showUserData = false,
    hiddenTitle = false,
    index,
    onSubmitWhenCancel,
  }: QuestionComponentProps,
  ref: QuestionComponentRef,
) => {
  // * Kết quả record
  const [resultRecord, setResultRecord] = React.useState<any>(undefined);

  // * Hiển thị Text Blockquote
  const [hasTextBlockquote, setHasTextBlockquote] = React.useState<boolean>(true);

  // * Hiển thị phần nghe
  const [hasListen, setHasListen] = React.useState<boolean>(false);

  // * Hiển thị phần Record
  const [hasRecord, setHasRecord] = React.useState<boolean>(true);

  // * Hiển thị Suggest Blockquote
  const [hasSuggest, setHasSuggest] = React.useState<boolean>(false);

  // * Hiển thị Suggest Button
  const [hasSuggestButton, setHasSuggestButton] = React.useState<boolean>(false);

  // * Hiển thị hình ảnh
  const [hasImage, setHasImage] = React.useState<boolean>(false);

  // * Kiểm tra trạng thái nghe
  const [isListen, setIsListen] = React.useState<boolean>(true);

  const refRecorder = React.createRef<any>();
  const refCountdownTimer = React.createRef<any>();

  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
    onSubmitWhenCancel() {
      handleSubmitOnCanceling();
    },
  }));

  React.useEffect(() => {
    if (userData && showUserData) {
      setResultRecord(userData?.answers);
      setHasRecord(false);
      setHasImage(false);
      setHasListen(false);
      setIsListen(false);
      setHasSuggest(false);
      setHasSuggestButton(false);
      setHasTextBlockquote(false);
    } else {
      setHasRecord(true);
      setHasImage(data.settings?.hasImage);
      setHasListen(data.settings?.hasListen);
      setIsListen(data.settings?.hasIsListen);
      setHasSuggest(data.settings?.hasSuggest);
      setHasSuggestButton(data.settings?.hasSuggestButton);
      setHasTextBlockquote(data.settings?.hasTextBlockQuote);
    }

    return () => {
      setResultRecord(undefined);
    };
  }, [data]);

  const onRecording = () => {
    if (typeof startRecord === 'function') {
      setResultRecord(undefined);
    }
    refCountdownTimer.current.startTimer();
    setIsDisableContinue(true);
  };

  const onStopTimer = () => {
    if (typeof refCountdownTimer.current?.stopTimer === 'function') {
      refCountdownTimer.current?.stopTimer();
      setResultRecord(undefined);
    }
  };

  const onEndCountDown = () => {
    refRecorder.current?.onStopCountdown();
  };

  const isDoneCal = (status: boolean) => {
    if (status) {
      setIsDisableContinue(false);
    }
  };

  const onStopRecording = (result: ResultRecordProps) => {
    setIsDisableContinue(false);
    if (result) {
      setResultRecord(result);
    } else {
      setResultRecord(undefined);
      setHasListen(data.settings?.hasListen);
      setIsListen(data.settings?.hasIsListen);
      setHasSuggestButton(data.settings?.hasSuggestButton);
      return;
    }
    setHasSuggest(data.settings?.hasSuggest);
    setHasTextBlockquote(false);
    setHasImage(data.settings?.hasImage);

    onEndCountDown();
  };

  const onListenStart = () => {
    setIsListen(true);
  };
  const onListenEnded = () => {
    setTimeout(() => {
      setIsListen(false);
    }, 500);
  };

  const renderRecorder = () => {
    const recordParams = {
      questionId: data?.id,
      questionText: data?.answers?.[0]?.text,
      studentId,
      takeExamTime: uuid(),
    };

    const getSecondByWord = (str: string) => {
      let countWord = str?.split(' ').length;
      return 5 + countWord * 5;
    };

    const renderListen = (
      <>
        {hasListen && (
          <CardTitle className='mb-0'>
            <Listen audioURL={data?.audioUrl} onAuto={false} onListened={onListenStart} onEnded={onListenEnded} />
          </CardTitle>
        )}
      </>
    );

    return (
      <>
        <Recorder
          ref={refRecorder}
          __custom
          __className={'question-type__recordType02'}
          __onRecording={onRecording}
          __onStopRecording={onStopRecording}
          recordParams={recordParams}
          onStopTimer={onStopTimer}
          assessment={true}
          listen={!resultRecord && renderListen}
          isListen={isListen}
          __isDoneCal={isDoneCal}
          hasRecord={!resultRecord && hasRecord}
        >
          <RecorderTypeContent
            seconds={getSecondByWord(data?.answers?.[0]?.text) | 15}
            refCountdownTimer={refCountdownTimer}
            onStopRecording={onStopRecording}
            onEndCountdown={onEndCountDown}
            resultRecord={resultRecord}
            functions={functions}
            hasScore={true} //* Chấm điểm
            hasRecord={!resultRecord && hasRecord}
          />

          {hasTextBlockquote && <TextBlockQuote question={data}></TextBlockQuote>}
          {hasSuggest && <SuggestBlockquote question={data} hasSuggestButton={hasSuggestButton}></SuggestBlockquote>}
          {hasImage && <img src={data?.imageUrl} alt='' style={{ height: '400px' }} />}
        </Recorder>
      </>
    );
  };

  const resetRecord = () => {
    refRecorder.current?.stopOnNext();
  };
  const handleAnswersNResult = () => {
    const answers = resultRecord;
    const result = resultRecord ? resultRecord?.score : 0;
    resetRecord();
    return {
      answers,
      result,
    };
  };
  const handleSubmitOnCanceling = () => {
    const { answers, result } = handleAnswersNResult();

    onSubmitWhenCancel?.({id: data.id, answers, result }, data);
  };
  const handleSubmit = () => {
    const { answers, result } = handleAnswersNResult();

    onSubmit?.({id: data.id, answers, result }, data);
    setResultRecord(undefined);
    refRecorder.current?.cancelRequest();
    setHasImage(data.settings?.hasImage);
    setHasListen(data.settings?.hasListen);
    setIsListen(data.settings?.hasIsListen);
    setHasSuggest(data.settings?.hasSuggest);
    setHasSuggestButton(data.settings?.hasSuggestButton);
    setHasTextBlockquote(data.settings?.hasTextBlockQuote);
  };

  return (
    <Card>
      {!hiddenTitle ? (
        <CardHeader>
          <QuestionHeader title={data.title} subTitle={data.subTitle} questionIndex={questionIndex} />
        </CardHeader>
      ) : (
        <CardBody>
          <div className='mb-3'>
            <span
              className='mb-2'
              style={{ fontSize: 18, color: 'black', lineHeight: 2, textAlign: 'justify', fontWeight: 600 }}
            >
              {`${index ? `${index + 1}.` : ''}`} {data.questionText}
            </span>
          </div>
        </CardBody>
      )}
      {/* {!resultRecord && ( */}
      <CardBody style={{ flex: 'none', textAlign: 'center', minHeight: 300 }}>{renderRecorder()}</CardBody>
      {/* )} */}

      <PronunciationAssessment assessment={resultRecord} isSentence={true} />

      {/* <CardFooter>
    <Button color='primary' disabled={!selectedAnswer} onClick={handleSubmit}>
      Submit <i className='ml-3 fas fa-arrow-right' />
    </Button>
  </CardFooter> */}
    </Card>
  );
};

export default React.forwardRef(ListenAndRecord);
