import { Tag, TagProps } from 'antd';
import { ColumnType } from 'antd/lib/list';
import { ColumnsType } from 'antd/lib/table';

const tableColumns: ColumnsType = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: 'Mã tham gia',
    dataIndex: 'ptCode',
    key: 'ptCode',
  },
  {
    title: 'Họ và tên',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Điểm',
    dataIndex: 'title',
    key: 'title',
    children: [
      {
        title: 'Nghe',
        dataIndex: 'listeningScore',
        key: 'listeningScore',
        align:'right'
      },
      {
        title: 'Nói',
        dataIndex: 'speakingScore',
        key: 'speakingScore',
        align:'right'
      },
      {
        title: 'Đọc',
        dataIndex: 'readingScore',
        key: 'readingScore',
        align:'right'
      },
      {
        title: 'Viết',
        dataIndex: 'writingScore',
        key: 'writingScore',
        align:'right'
      },
    ],
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render: (value: string, record: any) => {
      return (
        <Tag key={record.index} color={record?.color}>
          {record?.status}
        </Tag>
      );
    },
  },
];

export { tableColumns };
