import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import settings, { IS_BASAO, IS_LX, IS_TRONGDONG } from 'constants/settings';
import i18next from 'i18next';
import SlideDetails from '../SlideDetails';
import StudentsEssay from '../StudentsEssay';
import ExamDiary from '../ExamDiary';

const categoryColumn =
  settings.IS_VIMARU || settings.IS_HIGHUP
    ? [
        {
          title: i18next.t('table:userManagement.Phone number'),
          key: 'phone',
          width: '10%',
          render: 'renderNoWrap',
          style: {
            fontWeight: 400,
          },
          prefix: <PhoneOutlined className='tw-mr-2' />,
        },
        {
          title: i18next.t('table:classInformation.Department'),
          key: 'khoa',
          // fixed: 'left',
          width: '10%',
          render: 'renderNoWrap',
          style: {
            fontWeight: 600,
            minWidth: 160,
          },
        },
        {
          title: i18next.t('table:classInformation.Major'),
          key: 'nganh',
          // fixed: 'left',
          width: '10%',
          render: 'renderNoWrap',
          style: {
            fontWeight: 600,
            minWidth: 160,
          },
        },
      ]
    : settings.IS_MENARD|| IS_BASAO || IS_TRONGDONG || IS_LX
    ? [
        {
          title: i18next.t('table:userManagement.Phone number'),
          key: 'phone',
          width: '10%',
          render: 'renderNoWrap',
          style: {
            fontWeight: 400,
          },
          prefix: <PhoneOutlined className='tw-mr-2' />,
        },
        {
          title: 'Bộ phận',
          key: 'shopName',
          // fixed: 'left',
          width: '10%',
          render: 'renderNoWrap',
          style: {
            fontWeight: 600,
            minWidth: 160,
          },
        },
        {
          title: 'Chức vụ',
          key: 'positionName',
          // fixed: 'left',
          width: '10%',
          render: 'renderNoWrap',
          style: {
            fontWeight: 600,
            minWidth: 160,
          },
        },
      ]
    : settings.IS_LG
    ? [
        {
          title: i18next.t('Part'),
          key: 'shopName',
          // fixed: 'left',
          width: '10%',
          render: 'renderNoWrap',
          style: {
            fontWeight: 600,
            minWidth: 160,
          },
        },
      ]
    : [];
export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: i18next.t('table:course.Code'),
      key: 'maSV',
      width: '1%',
      fixed: 'left',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        // textAlign: 'right',
      },
      prefix: <UserOutlined className='tw-mr-2' />,
    },
    {
      title: i18next.t('table:statistic.Full name'),
      key: 'fullName',
      fixed: 'left',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    {
      title: i18next.t('Email'),
      key: 'email',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 400,
      },
      prefix: <MailOutlined className='tw-mr-2' />,
    },
    ...categoryColumn,
    {
      key: 'id',
      render: (text, record) => <ExamDiary record={record} />,
      sort: false,
      style: {
        fontWeight: 400,
      },
      title: '',
      width: '1%',
    },
    {
      key: 'Essay',
      render: (text, record) => <StudentsEssay record={record} />,
      sort: false,
      style: {
        fontWeight: 400,
      },
      title: '',
      width: '1%',
    },
  ],
};
