import { DeleteOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { Modal, Popconfirm, Space, Tooltip, message } from 'antd';
import NoData from 'components/NoData';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import tableData from './tableData';
const fetcher = (studentId, slideId) => {
  return query('p_VIMARU_GetAnswerLogs_ByStudentId_Details', { studentId, slideId });
};
const SlideDetails = ({ record }) => {
  const { classId } = useParams();
  const [visible, setVisible] = React.useState(false);
  const queryClient = useQueryClient();
  const KEY = ['p_VIMARU_GetAnswerLogs_ByStudentId_Details', record?.studentId, record?.slideId];
  const { data, isLoading } = useQuery(KEY, () => fetcher(record?.studentId, record?.slideId), { enabled: visible });

  const onDeleteExcercises = () => {
    query('p_QUIZCITY_Student_Examination_Result_Delete', {
      folderId: record.folderId,
      classId: Number(classId),
      studentId: record.studentId,
      slideId: record.slideId,
    })
      .then((res) => {
        // Lấy phần tử cha có id (id được đặt ở chỗ render columns table)
        const parentElement = document.getElementById(`completed-quality-${record.id}`);

        // Lấy phần tử con có class là "ant-progress-text" và thay đổi nội dung và thuộc tính
        const progressTextElement = parentElement.querySelector('.ant-progress-text');
        progressTextElement.textContent = '0%';

        // Lấy phần tử con có class là "ant-progress-bg" và thay đổi thuộc tính style
        const progressBgElement = parentElement.querySelector('.ant-progress-bg');
        progressBgElement.style.width = '0%';
        progressBgElement.style.height = '8px';

        //Notification
        message.success('Xoá thành công bài làm', 5000);
      })
      .catch((err) => {
        message.error('Xoá bài làm thất bại', 5000);
      });
  };

  const onClickDeleteButton = () => {
    Modal.confirm({
      title: i18next.t('Are you sure to delete', { object: i18next.t('Excercise').toLowerCase() }),
      icon: <ExclamationCircleOutlined />,
      content: <strong style={{ color: '#f5222d' }}>({i18next.t('popconfirm.unrecoverable')})</strong>,
      okText: i18next.t('Confirm'),
      cancelText: i18next.t('Close'),
      centered: true,
      onOk: () => onDeleteExcercises(),
    });
  };

  if (!['WorkSheet', 'Quiz'].includes(record?.type)) {
    //Hiện chỉ mới xử lý hiển thị kết quả làm bài cho ['WorkSheet', 'Quiz'] , bổ sung thì bỏ đoạn mã này đi
    return <span/>
  };
  
  return (
    <React.Fragment>
      <Space size={[8, 8]}>
        <Tooltip title={i18next.t('Detail')}>
          <DynamicButton type='dashed' icon={<EyeOutlined />} onClick={() => setVisible(true)}></DynamicButton>
        </Tooltip>
        {/* Chỉ xoá dữ liệu làm bài có countdown */}
        {record.timeExam ? (
          <Tooltip title={i18next.t('table:classInformation.Delete work')}>
            <DynamicButton
              type='dashed'
              danger
              style={{ color: '#f5222d' }}
              icon={<DeleteOutlined />}
              onClick={() => onClickDeleteButton()}
            ></DynamicButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Space>

      <Modal
        centered
        width='90vw'
        open={visible}
        title={i18next.t('Detail')}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[
          <DynamicButton
            key='close-modal'
            onClick={() => {
              setVisible(false);
            }}
          >
            {i18next.t('Close')}
          </DynamicButton>,
        ]}
        bodyStyle={{
          height: 'calc( 100vh - 120px )',
          overflowY: 'scroll',
          paddingBottom: 0,
        }}
      >
        {(data && data.length) || isLoading ? (
          <DynamicTable loading={isLoading} initialTableData={tableData} dataSource={data} />
        ) : (
          <NoData description={settings.studentPronouns2 + ' chưa làm slide này '} />
        )}
      </Modal>
    </React.Fragment>
  );
};

SlideDetails.propTypes = {
  classId: PropTypes.string,
};

SlideDetails.defaultProps = {
  classId: '',
};

export default SlideDetails;
