export const canvasID = 'canvas-id';
//
export const modeName = {
  // pointer: 'pointer',
  // pencil: 'pencil',
  // text: 'text',
  // bucket: 'bucket',
  // shape: 'shape',
  // erase: 'delete',
  WRITE: 'Write',
  CHOICE: 'Choice',
  MULTIPLECHOICE2: 'MultipleChoice2',
  MATCH: 'Match',
  MULTIPLEMATCH: 'MultipleMatch',
  LISTEN: 'Listen',
  SELECTWORD: 'SelectWord',
  DROPDOWN: 'DropDown',
  DRAGDROP: 'DragDrop',
  SPEECHRECOGNITION: 'SpeechRecognition',
  RECORD: 'Record',
};
//
export const modeColor = {
  [modeName.WRITE]: 'rgba(255,255,255,0.7)',
  [modeName.MATCH]: 'rgba(255,255,248,0.8)',
  [modeName.MULTIPLEMATCH]: 'rgba(255,255,248,0.8)',
  [modeName.CHOICE]: 'rgba(230,230,230, 0.80)',
  [modeName.MULTIPLECHOICE2]: 'rgba(230,230,230, 0.80)',
  [modeName.SELECTWORD]: 'rgba(230,230,230, 0.80)',
  [modeName.DROPDOWN]: 'rgba(230,230,230, 0.80)',
  [modeName.DRAGDROP]: 'rgba(230,230,230, 0.80)',
  [modeName.LISTEN]: 'rgba(255, 240, 165, 0.81)',
  [modeName.SPEECHRECOGNITION]: 'rgba(139, 69, 19, 1)',
  [modeName.RECORD]: 'rgba(0, 128, 128, 1)',
};
//
export const keyToolbar = {
  SAVE: 'save',
  REMOVEALL: 'removeAll',
  CANCEL: 'cancel',
  DONE: 'done',
  CHANGEMODE: 'change_mode',
};

export const SOURCE_MULTIPLEMATCH_ICON = 'fas fa-circle';
export const DESTINATE_MULTIPLEMATCH_ICON = 'far fa-dot-circle';
