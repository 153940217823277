/* eslint-disable consistent-return */
/* eslint-disable no-useless-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, Col, Form, Input } from 'antd';
import React from 'react';
import Layout from '../../Layouts/Layout';
const IndexOneChoice = ({ form, field, setAddField, optionType }) => {
  const RenderLayoutByOptionType = () => {
    switch (optionType.toUpperCase()) {
      case 'LOOK_CHOICE':
        return (
          <Layout
            field={field}
            layout={['checkbox', 'input']}
            setAddField={setAddField}
            form={form}
            autoFocus={true}
            lg={20}
          />
        );
      case 'READANSWER':
        return (
          <Layout
            field={field}
            layout={['checkbox', 'input']}
            setAddField={setAddField}
            form={form}
            autoFocus={true}
            lg={20}
          />
        );
      case 'LISTENSOUND':
        return (
          <Layout
            field={field}
            layout={['checkbox', 'audio']}
            setAddField={setAddField}
            form={form}
            autoFocus={true}
            lg={19}
          />
        );
      case 'LISTENPICTURE':
        return (
          <Layout
            field={field}
            layout={['checkbox', 'image']}
            setAddField={setAddField}
            form={form}
            autoFocus={true}
            lg={20}
          />
        );
      case 'LISTENDIFFERENTBEGININGSOUND':
        return (
          <Layout
            field={field}
            layout={['checkbox', 'audio']}
            setAddField={setAddField}
            form={form}
            autoFocus={true}
            lg={20}
          />
        );
      case 'LISTENPICTUREDESCRIBED':
        return (
          <Layout
            field={field}
            layout={['checkbox', 'image']}
            setAddField={setAddField}
            form={form}
            autoFocus={true}
            lg={20}
          />
        );
      default:
        return <></>;
    }
  };

  return <RenderLayoutByOptionType />;
};

export default IndexOneChoice;
