import { query } from 'helpers/QueryHelper';
import { useQuery } from 'react-query';
import { Options, checkAvailableTime, getItemLocalStorage, getTimeNo, setItemLocalStorage } from 'utils/localStorage';

function getQuestionGradeOptions() {
  return new Promise<any>((resolve, reject) => {
    const { questionGrade } = getItemLocalStorage();
    if (questionGrade?.[0]?.availableTime && checkAvailableTime({ time: questionGrade?.[0]?.availableTime })) {
      resolve(questionGrade);
    }
    query('p_QUIZCITY_GetGrade')
      .then((response) => {
        const timeNo = getTimeNo();
        const options = (response ?? [])?.map((grade: any) => ({
          ...grade,
          value: grade.code,
          label: grade.name,
          availableTime: timeNo,
        }));
        setItemLocalStorage({ questionGrade: options });
        resolve(options);
      })
      .catch((error) => {
        resolve([]);
      });
  });
}

function getQuestionTagOptions() {
  return new Promise<any>((resolve, reject) => {
    const { questionTag } = getItemLocalStorage();
    if (questionTag?.[0]?.availableTime && checkAvailableTime({ time: questionTag?.[0]?.availableTime })) {
      resolve(questionTag);
    }
    query('p_QUIZCITY_GetQuestionTag')
      .then((response) => {
        const timeNo = getTimeNo();
        const options = (response ?? [])?.map((tag: any) => ({
          ...tag,
          value: tag.tags,
          label: tag.tags,
          availableTime: timeNo,
        }));
        setItemLocalStorage({ questionTag: options });
        resolve(options);
      })
      .catch((error) => {
        resolve([]);
      });
  });
}

function getQuestionTypeOptions() {
  return new Promise<any>((resolve, reject) => {
    const { questionType } = getItemLocalStorage();
    if (questionType?.[0]?.availableTime && checkAvailableTime({ time: questionType?.[0]?.availableTime })) {
      const type = questionType.filter((i: Options) => i.parentId === null);
      resolve(type);
    }
    query('p_QUIZCITY_GetQuestionType_Category')
      .then((response) => {
        const timeNo = getTimeNo();
        const options = (response ?? []).map((item: any) => ({
          ...item,
          value: item.questionTypeCode,
          label: item.questionTypeName,
          availableTime: timeNo,
        }));
        setItemLocalStorage({ questionType: options });
        const type = options.filter((i: Options) => i.parentId === null);
        resolve(type);
      })
      .catch((error) => {
        resolve([]);
      });
  });
}

function getQuestionOptionTypeOptions(questionTypeId: any) {
  return new Promise<any>((resolve, reject) => {
    const { questionType } = getItemLocalStorage();
    if (questionType?.[0]?.availableTime && checkAvailableTime({ time: questionType?.[0]?.availableTime })) {
      const optionsType = questionType.filter((i: Options) => i.parentId === questionTypeId);
      resolve(optionsType);
    }
    query('p_QUIZCITY_GetQuestionType_Category')
      .then((response) => {
        const timeNo = getTimeNo();
        const options = (response ?? []).map((item: any) => ({
          ...item,
          value: item.questionTypeCode,
          label: item.questionTypeName,
          availableTime: timeNo,
        }));
        setItemLocalStorage({ questionType: options });
        const optionsType = options.filter((i: Options) => i.parentId === questionTypeId);
        resolve(optionsType);
      })
      .catch((error) => {
        resolve([]);
      });
  });
}

//================================= USE_QUERY ===========================================//

function getQuestionGradeOptions_UseQuery() {
  return useQuery(['p_QUIZCITY_GetGrade'], () => getQuestionGradeOptions());
}

function getQuestionTagOptions_UseQuery() {
  return useQuery(['p_QUIZCITY_GetQuestionTag'], () => getQuestionTagOptions());
}

function getQuestionTypeOptions_UseQuery() {
  return useQuery(['p_QUIZCITY_GetQuestionType_Category'], () => getQuestionTypeOptions());
}

export {
  getQuestionGradeOptions,
  getQuestionTagOptions,
  getQuestionTypeOptions,
  getQuestionOptionTypeOptions,
  //USE_QUERY
  getQuestionGradeOptions_UseQuery,
  getQuestionTagOptions_UseQuery,
  getQuestionTypeOptions_UseQuery,
};
