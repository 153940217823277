/* eslint-disable react/jsx-props-no-spreading */
import { Button, Form, Input, message, Modal, notification, Radio, Select } from 'antd';
// core components
import colors from 'constants/colors';
import settings, { IS_HANDOUT, IS_PT } from 'constants/settings';
import { generateSHA1 } from 'helpers/CryptoHelper';
import { amesSendMail, query, queryWithoutModuleId } from 'helpers/QueryHelper';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import vimaruColors from 'constants/vimaru';
import moment from 'moment';
import getSettings from 'pages/Administration/api/getSettings';
import Verify from 'pages/Verify';
import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { init, login, loginGoogle } from '../actions';
import RegisterStudentPopup from './RegisterStudentPopup';

const { confirm } = Modal;

const FormItem = Form.Item;
const CLIENT_ID = '970836020263-h8vnhe0qstno3k0fgmbv102lvnufvvna.apps.googleusercontent.com';
const logoUrl =
  window.location.hostname === 'quizcity.io'
    ? require('assets/img/brand/logo-quizcity.png').default
    : settings.IS_MENARD
      ? require('assets/img/brand/logo-menard.png').default
      : settings.IS_CDP
        ? require('assets/img/brand/cdp-logo.png').default
        : require('assets/img/brand/english-city-01.png').default;

const bannerUrl = require('assets/img/introduce/ames-login-BG.png').default;

const fetchCurrentModule = () => {
  return queryWithoutModuleId('p_QUIZCITY_GetModuleTest', {}, settings.APPLICATION_NAME);
};
const fetchCities = () => {
  return queryWithoutModuleId('p_QUIZCITY_GET_Cities');
};
const fetchDistricts = (cityId) => {
  return queryWithoutModuleId('p_QUIZCITY_GET_Districts', { cityId });
};
const fetchSchools = (cityId, districtId) => {
  return queryWithoutModuleId('p_QUIZCITY_GET_Schools', { cityId, districtId });
};

const gradeOptions = [
  { label: 'Lớp 1', value: 1 },
  { label: 'Lớp 2', value: 2 },
  { label: 'Lớp 3', value: 3 },
  { label: 'Lớp 4', value: 4 },
  { label: 'Lớp 5', value: 5 },
];

const subDomainOptions = [
  { value: 'pt', label: 'Placement Test' },
  { value: 'handout', label: 'Handout' },
  { value: '', label: 'Highup' },
];

const options = (array) => {
  if (!array) return [];

  const options = array.map((item) => ({ value: item?.id, label: item?.name }));
  return options;
};
const schoolOptions = (array) => {
  if (!array) return [];

  const options = array.map((item) => ({ value: item?.id, label: item?.name }));
  return [...options, { label: 'Khác', value: 0 }];
};

const forms = {
  verify: 'verify',
  login: 'login',
  forgotPassword: 'forgotPassword',
  register: 'register',
};

const LoginPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isVerifying = window.location.pathname.includes('verify');
  const [formStatus, setFormStatus] = React.useState(isVerifying ? forms.verify : forms.login); //trạng thái hiển thị các form
  const loggedInUser = useSelector((userState) => userState.authReducer.loggedInUser);
  const [modalStudentVisible, setModalStudentVisible] = React.useState(false);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [selectedDistrict, setSelectedDistrict] = React.useState(null);
  const [selectedSchool, setSelectedSchool] = React.useState(null);

  const [form] = Form.useForm();
  const [formForgotPassword] = Form.useForm();

  const { data: cities } = useQuery('p_QUIZCITY_GET_Cities', () => fetchCities(), {
    enabled: formStatus === forms.register,
  });
  const { data: districts } = useQuery(['p_QUIZCITY_GET_Districts', selectedCity], () => fetchDistricts(selectedCity), {
    enabled: !!selectedCity,
  });
  const { data: schools } = useQuery(
    ['p_QUIZCITY_GET_Schools', selectedCity, selectedDistrict],
    () => fetchSchools(selectedCity, selectedDistrict),
    {
      enabled: !!selectedCity && !!selectedDistrict,
    },
  );
  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);
  const mailer =
    ConfigSettings?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value ?? 'AMES ENGLISH <mailer@ames.edu.vn>';
  const templateResetPassword =
    ConfigSettings?.find((x) => x?.key === 'TEMPLATE_RESETPASSWORD')?.value ?? 'AMES_Mail_ResetPassword';

  React.useEffect(() => {
    if (loggedInUser) {
      history.push(`/${settings?.PATHNAME}/home`);
    } else {
      dispatch(init());
    }
  }, [dispatch, history, loggedInUser]);

  const handleSubmit = (values) => {
    const { username, password } = values;
    dispatch(login(username, password));
  };

  React.useEffect(() => {
    const pathNameArray = window.location.pathname.split('/');
    form.setFieldValue('subDomain', pathNameArray[2] ?? '');
  }, []);

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };
  const handleDistrictChange = (value) => {
    setSelectedDistrict(value);
  };
  const handleSchoolChange = (value) => {
    setSelectedSchool(value);
  };

  const sendForgotPassword = async (values) => {
    await query('p_VIMARU_User_SearchByEmail', { Email: values.Email }).then((res) => {
      if (res.length > 0) {
        const { fullName, email, message: messageResult, userId } = res[0];
        if (fullName && email) {
          const dateNo = moment().format('YYYYMMDDhhmm');
          const password = GenPasswordModel(2, 6);
          const dataSendMail = {
            to: email.toLowerCase(),
            isSendFast: true,
            moduleId: settings.MODULE_ID,
            cc: 'ebm.it@ames.edu.vn',
            bcc: '',
            subject: `Quên mật khẩu #${dateNo}`,
            body: '',
            bodyHTML: '',
            attachment: '',
            templateName: templateResetPassword,
            sourceInfo: `API Save Email - ${templateResetPassword}`,
            templateData: JSON.stringify({
              email: `${email}`,
              password: `${password}`,
              dateNo: `${dateNo}`,
              studentName: fullName,
            }),
            mailer: mailer,
          };
          const parameters = {
            Email: email,
            Password: generateSHA1(password),
          };
          onUpdatePassword(parameters);
          amesSendMail('saveEmail', dataSendMail);
          notification.success({ message: `Mật khẩu đã được gửi qua email: ${email}` });
        } else {
          notification.error({ message: messageResult });
          if (messageResult.includes('chưa xác thực')) {
            notification.error({
              message: messageResult,
              description: 'Bạn sẽ chuyển tới trang xác thực trong vòng 3s',
              duration: 0,
            });
            setTimeout(() => {
              window.location.href = `/verify?email=${encodeURIComponent(values.Email)}`;
            }, 3000);
          }
        }
      } else {
        notification.error({ message: 'Email không tồn tại' });
      }
    });
  };
  const onUpdatePassword = async (parameters) => {
    await query('p_VIMARU_UserPassword_RESET', parameters);
  };

  const logInByGoogle = async (email) => {
    return await queryWithoutModuleId('p_QUIZCITY_LoginGmail_AMESTEACHER', { email });
  };
  const responseGoogle = async (response) => {
    if (response.profileObj) {
      const email = response.profileObj.email;
      dispatch(loginGoogle(email));
    } else {
      if (response?.error === 'popup_closed_by_user') {
        message.error(
          'Đăng nhập bị huỷ bởi người dùng. Nếu không phải do bạn huỷ, hãy thử "Cài đặt > Xoá dữ liệu duyệt web" và thử lại.',
        );
      } else {
        message.error(`Đã xảy ra lỗi ${response?.details ?? response?.error}`);
      }
    }
  };
  const onFinish = (values) => {
    const password = GenPasswordModel(2, 6);
    const parameters = {
      email: values.email,
      password: password,
      passwordEncrypt: generateSHA1(password),
      fullName: `${values.surname} ${values.name}`,
      phone: values.numberPhone ?? '',
      cityId: values.city,
      districtId: values.district,
      schoolId: values.school,
      tenTruongHoc: values.schoolName ?? '',
      grade: values.grade,
      lopHoc: values.className,
      roleId: 1,
      maSv: GenPasswordModel(1, 6),
      note: '',
      classId: null,
      birthDay: null,
      sex: values.gender,
    };
    query('p_QUIZCITY_HU_User_Register', parameters)
      .then((res) => {
        const {
          isverify,
          message,
          userId, // trường hợp không đăng kí được
        } = res[0];
        if (res?.[0]?.userId) {
          amesSendMail(
            'SendMailFastVerifyQuizcity',
            { To: values.email.toLowerCase(), ModuleID: settings.MODULE_ID, Cc: 'ebm.it@ames.edu.vn' },
            settings.APPLICATION_NAME,
          );
          notification.success({ message: 'Tạo tài khoản thành công, vui lòng kiểm tra email để xác nhận' });
          history.push('/verify', {
            email: values.email,
            verifyCode: res?.[0]?.verifyCode,
            studentName: `${values.surname} ${values.name}`,
          });
        } else if (isverify === 1) {
          confirm({
            title: message,
            content: 'Bạn có thể quay về trang đăng nhập hoặc đăng kí với mail khác !',
            okText: 'Đăng nhập',
            onOk() {
              setFormStatus(forms.login);
            },
            cancelText: 'Đăng kí với mail khác',
            onCancel() {},
          });
        } else if (isverify === 0) {
          confirm({
            title: message,
            content: 'Email này đã được đăng kí nhưng chưa xác thực. Bạn phải xác thực để sử dụng',
            okText: 'Xác thực email',
            onOk() {
              history.push('/verify', {
                email: values.email,
                verifyCode: res?.[0]?.verifyCode,
                studentName: `${values.surname} ${values.name}`,
              });
            },
            cancelText: 'Đăng kí với mail khác',
            onCancel() {},
          });
        } else notification.error({ message: message });
        // notification.error({ message: message, description: 'Bạn sẽ chuyển tới trang xác thực trong vòng 3s', duration: 0, });
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: `url(${bannerUrl}) `,
          backgroundSize: '100% 100%',
          minHeight: '100vh',
          padding: '50px 0px',
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Row className='justify-content-center' style={{ width: '100%', paddingBottom: 20, margin: 0 }}>
          <div style={{ width: 300, height: 60 }}>
            <img alt='English City' className='navbar-brand-img' src={logoUrl} />
          </div>
        </Row>
        <Row className='justify-content-center' style={{ margin: 0 }}>
          {formStatus === forms.verify ? (
            <Col style={{ maxWidth: 500, width: '95vw' }}>
              <Verify {...props} />
            </Col>
          ) : formStatus === forms.login ? (
            <Col style={{ maxWidth: 470, width: '95vw' }}>
              <Card className='bg-secondary border-0 mb-0'>
                <CardHeader className='bg-transparent pb-3'>
                  <div className='text-center mt-2 mb-1'>
                    <span style={{ fontSize: 24, fontWeight: 600, color: colors.font }}>ĐĂNG NHẬP</span>
                  </div>
                  {!settings.IS_CDP && (
                    <div className='text-center'>
                      <span>
                        <span>Sử dụng tài khoản </span>
                        <a href='https://englishcity.vn'>ENGLISH CITY</a>
                        <span> để đăng nhập</span>
                      </span>
                    </div>
                  )}
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Form autoComplete='on' form={form} onFinish={handleSubmit}>
                    {/* {settings?.IS_AMES && (
                        <Form.Item name='subDomain'>
                          <Select
                            options={subDomainOptions}
                            onChange={(e) => (window.location.pathname = `/auth/${e}`)}
                          ></Select>
                        </Form.Item>
                      )} */}
                    <FormItem
                      hasFeedback
                      name='username'
                      rules={[
                        {
                          whitespace: true,
                          message: 'Vui lòng không xuống dòng',
                        },
                        {
                          required: true,
                          message: 'Vui lòng nhập username (email)!',
                        },
                      ]}
                      maxLength={250}
                    >
                      <Input
                        style={{
                          boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                          borderRadius: 3,
                        }}
                        allowClear
                        size='large'
                        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-user' />}
                        placeholder='Username (*)'
                      />
                    </FormItem>

                    <FormItem
                      hasFeedback
                      name='password'
                      rules={[
                        // { min: 6, message: "Tối thiểu 6 ký tự!" },
                        {
                          whitespace: true,
                          message: 'Vui lòng không xuống dòng',
                        },
                        { required: true, message: 'Vui lòng nhập mật khẩu!' },
                      ]}
                    >
                      <Input.Password
                        style={{
                          boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                          borderRadius: 3,
                        }}
                        allowClear
                        size='large'
                        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-lock' />}
                        placeholder='Mật khẩu (*)'
                      />
                    </FormItem>

                    <div className='text-center'>
                      <Button className='mt-2' type='primary' htmlType='submit' size='large' block>
                        Đăng nhập
                      </Button>

                      {/* <Button
                        className='mt-2'
                        type='primary'
                        block
                        danger
                        htmlType='button'
                        size='large'
                        onClick={() => {
                          setModalStudentVisible(true);
                          // window.open('https://hanhtrangso.nxbgd.vn/', '_blank');
                        }}
                      >
                        Đăng ký
                      </Button> */}
                    </div>
                  </Form>
                  <Row className='mt-2'>
                    <Col sm='8'>
                      <div className='text-light' href='#pablo' onClick={() => setFormStatus(forms.forgotPassword)}>
                        <span>Quên mật khẩu?</span>
                      </div>
                    </Col>
                  </Row>
                  {/* <Row className='mt-2'>
                   <Col sm='5'>
                     <span style={{ cursor: 'pointer' }} className='text-black' onClick={toggleModal}>
                       <small style={{ fontSize: 15 }}>Quên mật khẩu?</small>
                     </span>
                     <ForgotPasswordModal toggleModal={toggleModal} isVisibled={state.isVisibled} />
                   </Col>
                 </Row> */}
                  {settings.IS_EITHER_AMES && (
                    <>
                      <div style={{ display: 'flex', flex: 1, alignItems: 'center', padding: 12 }}>
                        <div style={{ height: 1, backgroundColor: '#bdc3c7', flex: 1 }} />
                        <div style={{ marginLeft: 12, marginRight: 12 }}>OR</div>
                        <div style={{ height: 1, backgroundColor: '#bdc3c7', flex: 1 }} />
                      </div>
                      <GoogleLogin
                        clientId={CLIENT_ID}
                        render={(renderProps) => (
                          <Button
                            block
                            type='danger'
                            size='lg'
                            onClick={renderProps.onClick}
                            style={{
                              fontSize: 16,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <i className='fab fa-google-plus-g' style={{ fontSize: 24 }}></i>
                            &ensp;Sign in using AMES email
                          </Button>
                        )}
                        buttonText='Login'
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                        prompt='select_account'
                      />
                      <hr style={{ marginBlock: 10 }} />
                      {!IS_HANDOUT && !IS_PT ? (
                        <div className='text-light' style={{ textAlign: 'center' }}>
                          <span>Chưa có tài khoản ? </span>
                          <span
                            style={{ fontWeight: 'bold', color: vimaruColors.tone1 }}
                            onClick={() => setFormStatus(forms.register)}
                          >
                            Đăng kí ngay
                          </span>
                        </div>) : null}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          ) : formStatus === forms.register ? (
            <Col style={{ maxWidth: 730, width: '95vw' }}>
              <Card className='bg-secondary border-0 mb-0'>
                <CardHeader className='bg-transparent pb-3'>
                  <span style={{ fontSize: 24, fontWeight: 600, color: colors.font }}>ĐĂNG KÝ HỌC SINH</span>
                </CardHeader>
                <CardBody className='px-lg-2 py-lg-2'>
                  <div style={{ paddingInline: 32, paddingBlock: 20 }}>
                    <Form
                      layout='vertical'
                      autoComplete='off'
                      form={form}
                      onFinish={onFinish}
                      requiredMark={true}
                    // labelCol={{ span: 7 }}
                    // wrapperCol={{ span: 17 }}
                    >
                      <Row>
                        <Col className='col-8'>
                          <Form.Item
                            style={{ fontWeight: 600 }}
                            name='surname'
                            label='Họ và tên đệm'
                            hasFeedback
                            rules={[
                              { whitespace: true, message: 'Chưa nhập họ và tên đệm' },
                              { required: true, message: 'Chưa nhập họ và tên đệm' },
                            ]}
                          >
                            <Input allowClear placeholder='Họ và tên đệm' />
                          </Form.Item>
                        </Col>
                        <Col className='col-4'>
                          <Form.Item
                            style={{ fontWeight: 600 }}
                            name='name'
                            label='Tên'
                            hasFeedback
                            rules={[
                              { whitespace: true, message: 'Chưa nhập tên' },
                              { required: true, message: 'Chưa nhập tên' },
                            ]}
                          >
                            <Input allowClear placeholder='Tên' />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        style={{ fontWeight: 600 }}
                        name='email'
                        label='Email'
                        hasFeedback
                        rules={[
                          { type: 'email', message: 'Email không hợp lệ' },
                          { whitespace: true, message: 'Chưa nhập Email' },
                          { required: true, message: 'Chưa nhập Email' },
                        ]}
                      >
                        <Input allowClear placeholder='Email' />
                      </Form.Item>

                      <Form.Item
                        style={{ fontWeight: 600 }}
                        name='numberPhone'
                        label='Số điện thoại'
                        hasFeedback
                        rules={[
                          {
                            pattern: /^[0]\d{8,10}$/,
                            message: 'Số điện thoại không đúng định dạng!',
                          },
                        ]}
                      >
                        <Input allowClear placeholder='Số điện thoại' />
                      </Form.Item>
                      <Form.Item
                        style={{ fontWeight: 600 }}
                        name='gender'
                        label='Giới tính'
                        hasFeedback
                        rules={[
                          // { type: 'email', message: 'Email không hợp lệ' },
                          // { whitespace: true, message: 'Chưa nhập Email' },
                          { required: true, message: 'Chưa chọn giới tính' },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={1}> Nam </Radio>
                          <Radio value={0}> Nữ </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        style={{ fontWeight: 600 }}
                        name='city'
                        label='Thành phố'
                        hasFeedback
                        rules={[
                          // { type: 'email', message: 'Email không hợp lệ' },
                          // { whitespace: true, message: 'Chưa nhập Email' },
                          { required: true, message: 'Chưa chọn thành phố' },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder='Chọn thành phố'
                          options={options(cities)}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          onChange={handleCityChange}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ fontWeight: 600 }}
                        name='district'
                        label='Quận huyện'
                        hasFeedback
                        rules={[
                          // { type: 'email', message: 'Email không hợp lệ' },
                          // { whitespace: true, message: 'Chưa nhập Email' },
                          { required: true, message: 'Chưa chọn Quận huyện' },
                        ]}
                      >
                        <Select
                          disabled={!selectedCity}
                          showSearch
                          placeholder='Chọn Quận huyện'
                          options={options(districts)}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          onChange={handleDistrictChange}
                        />
                      </Form.Item>
                      <Row>
                        <Col>
                          <Form.Item
                            style={{ fontWeight: 600 }}
                            name='school'
                            label='Trường'
                            hasFeedback
                            rules={[
                              // { type: 'email', message: 'Email không hợp lệ' },
                              // { whitespace: true, message: 'Chưa nhập Email' },
                              { required: true, message: 'Chưa chọn Trường' },
                            ]}
                          >
                            <Select
                              disabled={!selectedCity || !selectedDistrict}
                              showSearch
                              placeholder='Chọn Trường'
                              options={schoolOptions(schools)}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                              onChange={handleSchoolChange}
                            />
                          </Form.Item>
                        </Col>
                        {selectedSchool === 0 && (
                          <Col>
                            <Form.Item
                              style={{ fontWeight: 600 }}
                              name='otherSchool'
                              label='Trường khác'
                              hasFeedback
                              rules={[
                                // { whitespace: true, message: 'Chưa nhập mật khẩu' },
                                { required: true, message: 'Chưa nhập trường' },
                                // { min: 6, message: 'Mật khẩu ít nhất có 6 ký tự' },
                              ]}
                            >
                              <Input placeholder='Nhập trường của bạn' />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col>
                          <Form.Item
                            style={{ fontWeight: 600 }}
                            name='grade'
                            label='Khối lớp'
                            hasFeedback
                            rules={[
                              // { type: 'email', message: 'Email không hợp lệ' },
                              // { whitespace: true, message: 'Chưa nhập Email' },
                              { required: true, message: 'Chưa chọn Lớp' },
                            ]}
                          >
                            <Select
                              // disabled={!selectedCity || !selectedDistrict}
                              // showSearch
                              placeholder='Chọn khối lớp'
                              options={gradeOptions}
                              filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                              }
                            // onChange={handleSchoolChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            style={{ fontWeight: 600 }}
                            name='className'
                            label='Tên lớp'
                            hasFeedback
                            rules={[
                              // { whitespace: true, message: 'Chưa nhập mật khẩu' },
                              { required: true, message: 'Chưa nhập tên lớp' },
                              // { min: 6, message: 'Mật khẩu ít nhất có 6 ký tự' },
                            ]}
                          >
                            <Input placeholder='Tên lớp (vd: 1/1)' />
                          </Form.Item>
                        </Col>
                      </Row>

                      {/* <Form.Item
                          style={{ fontWeight: 600 }}
                          name='confirmPassword'
                          label='Xác nhận mật khẩu'
                          dependencies={['password']}
                          hasFeedback
                          rules={[
                            { whitespace: true, message: 'Chưa nhập xác nhận mật khẩu' },
                            { required: true, message: 'Chưa nhập xác nhận mật khẩu' },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (!value || getFieldValue('registerPassword') === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(new Error('Xác nhận mật khẩu không khớp'));
                              },
                            }),
                          ]}
                        >
                          <Input.Password allowClear size='large' placeholder='Xác nhận mật khẩu' />
                        </Form.Item> */}

                      <Button
                        // loading={loading}
                        size='large'
                        className='mb-2'
                        htmlType='submit'
                        type='primary'
                        block
                        style={{
                          backgroundColor: '#017AFF',
                          // fontWeight: 600,
                          // height: 48,
                          borderRadius: '0.25rem',
                        }}
                      >
                        Đăng ký
                      </Button>
                      {/* {error && error.event && (
                      <Alert
                        message='Đăng nhập không thành công'
                        type='error'
                        showIcon
                        style={{ marginBlock: 6, borderRadius: '0.25rem' }}
                      />
                    )} */}
                      <div style={{ textAlign: 'center' }}>
                        <span style={{ color: colors.greyChateau }}>Bạn đã có tài khoản?</span>
                        <Button
                          type='link'
                          size='small'
                          onClick={() => {
                            setFormStatus(forms.login);
                          }}
                        >
                          Đăng nhập
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ) : (
            formStatus === forms.forgotPassword && (
              <Col style={{ maxWidth: 470, width: '95vw' }}>
                <Card className='bg-secondary border-0 mb-0'>
                  <CardHeader className='bg-transparent pb-3'>
                    <div className='text-center mt-2 mb-1'>
                      <span style={{ fontSize: 24, fontWeight: 600, color: colors.font }}>ĐĂNG NHẬP</span>
                    </div>
                    {!settings.IS_CDP && (
                      <div className='text-center'>
                        <small>
                          <span>Sử dụng tài khoản </span>
                          <a href='https://englishcity.vn'>ENGLISH CITY</a>
                          <span> để đăng nhập</span>
                        </small>
                      </div>
                    )}
                  </CardHeader>
                  <CardBody className='px-lg-5 py-lg-5'>
                    <Form autoComplete='on' form={formForgotPassword} onFinish={sendForgotPassword}>
                      <FormItem
                        hasFeedback
                        name='Email'
                        rules={[
                          { type: 'email', message: 'Email không hợp lệ' },
                          {
                            whitespace: true,
                            message: 'Vui lòng nhập email!',
                          },
                          {
                            required: true,
                            message: 'Vui lòng nhập email!',
                          },
                        ]}
                        maxLength={250}
                      >
                        <Input
                          style={{
                            boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                            borderRadius: 3,
                          }}
                          allowClear
                          size='large'
                          prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-user' />}
                          placeholder='Username (*)'
                        />
                      </FormItem>

                      <div className='text-center'>
                        <Button className='mt-2' type='primary' htmlType='submit' size='large' block>
                          Gửi
                        </Button>

                        <Button
                          className='mt-2'
                          type='primary'
                          block
                          danger
                          htmlType='button'
                          size='large'
                          onClick={() => {
                            setFormStatus(forms.login);
                          }}
                        >
                          Quay lại đăng nhập
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            )
          )}
        </Row>
      </div>
      <Modal
        isOpen={modalStudentVisible}
        centered
        backdrop='static'
        backdropClassName='sachso-modal-backdrop'
        size='ms'
      >
        <RegisterStudentPopup
          onClose={() => {
            setModalStudentVisible(false);
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

LoginPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};
export default LoginPage;
