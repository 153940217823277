export const SLIDE_START = 'SLIDE_START';
export const SLIDE_UPDATE_COUNTDOWN = 'SLIDE_UPDATE_COUNTDOWN';

export const SLIDE_SUBMIT = 'SLIDE_SUBMIT';
export const SLIDE_SUBMIT_SUCCESS = 'SLIDE_SUBMIT_SUCCESS';
export const SLIDE_SUBMIT_FAILURE = 'SLIDE_SUBMIT_FAILURE';

export const SLIDE_SUBMIT_ALL = 'SLIDE_SUBMIT_ALL';
export const SLIDE_SUBMIT_ALL_SUCCESS = 'SLIDE_SUBMIT_ALL_SUCCESS';
export const SLIDE_SUBMIT_ALL_FAILURE = 'SLIDE_SUBMIT_ALL_FAILURE';
