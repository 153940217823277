/* eslint-disable react/jsx-one-expression-per-line */
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { Button, Result } from 'antd';
import blackTheme from 'constants/theme';
import { AiOutlineHome, AiOutlineRollback } from 'react-icons/ai';
import styles from './notFound.module.css';

const NotFound = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <div className={`tw-h-screen tw-flex tw-flex-col tw-justify-center ${blackTheme() ? styles.blackTheme : ''}`}>
      <Helmet>
        <title>Không tìm thấy trang</title>
        <meta name='robots' content='noindex, follow' />
        <meta name='description' content='Không tìm thấy trang bạn đang tìm' />
      </Helmet>
      <Result
        status='404'
        icon={<img src='/images/notfound.png' alt='404' className='tw-max-w-lg tw-w-11/12 tw-mx-auto' />}
        title={<h1 className='tw-text-7xl tw-my-8'>404</h1>}
        subTitle={<h2 className='tw-text-2xl'>Không tìm thấy trang bạn đang tìm</h2>}
        extra={
          <div className='tw-flex tw-justify-center tw-gap-2'>
            <Button type='default' size='large' onClick={handleGoBack}>
              <AiOutlineRollback className='tw-mr-2' />
              Quay lại
            </Button>
            <Link to='/'>
              <Button type='primary' size='large'>
                <AiOutlineHome className='tw-mr-2' />
                Trang chủ
              </Button>
            </Link>
          </div>
        }
      />
    </div>
  );
};

export default NotFound;
