/* eslint-disable sort-keys */
/* eslint-disable no-unused-vars */

import React from 'react';
import { Button, Col, Progress, Row } from 'antd';
import { execute } from 'helpers/QueryHelper';
import colors from 'constants/colors';
import {
  CalendarOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { AiFillStar } from 'react-icons/ai';
import moment from 'moment';

export default {
  rowkey: 'id',
  scroll: { x: true },
  displayColumns: [
    {
      title: 'Mã SV',
      key: 'maSV',
      dataIndex: 'maSV',
      width: '10vw',
      fixed: 'left',
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: 600,
        color: colors.font,
        width: 150,
      },
      sort: false,
    },
    {
      title: 'Họ Tên',
      key: 'studentName',
      dataIndex: 'studentName',
      fixed: 'left',
      width: '10vw',
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
        width: 150,
      },
    },
    {
      title: 'SĐT',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      width: '10vw',
      render: 'renderNoWrap',
      fixed: 'left',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      width: '10vw',
      render: 'renderNoWrap',
      fixed: 'left',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
    },
    {
      title: 'Lớp',
      key: 'class',
      dataIndex: 'class',
      width: '10vw',
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
    },
    {
      title: 'Giáo viên',
      key: 'teacherName',
      dataIndex: 'teacherName',
      width: '10vw',
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
    },
    {
      title: 'Số lượng hoàn thành',
      key: 'percentCompleted',
      dataIndex: 'percentCompleted',
      width: 500,
      render: 'renderNumber',
      formatString: '0.00',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
      dataType: 'number',
    },
    {
      title: 'Chất lượng hoàn thành',
      key: 'qualityPercentCompleted',
      dataIndex: 'qualityPercentCompleted',
      width: 500,
      render: 'renderNumber',
      formatString: '0.00',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
      dataType: 'number',
    },
    {
      title: 'Chất lượng hoàn thành tư cách',
      key: 'qualificationCompletedPercent',
      dataIndex: 'qualificationCompletedPercent',
      width: 500,
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
      dataType: 'number',
    },
    {
      title: 'Tiến độ hoàn thành',
      key: 'progress',
      dataIndex: 'progress',
      width: 500,
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
      dataType: 'number',
    },
    {
      title: 'Điểm tư cách giữa kỳ',
      key: 'scoresQualificationMidSemester',
      dataIndex: 'scoresQualificationMidSemester',
      width: 500,
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
      dataType: 'number',
    },
    {
      title: 'Điểm thi giữa kỳ',
      key: 'scoresMidSemester',
      dataIndex: 'scoresMidSemester',
      width: 500,
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
      dataType: 'number',
    },
    {
      title: 'Điểm tư cách cuối kỳ',
      key: 'scoresQualificationEndSemester',
      dataIndex: 'scoresQualificationEndSemester',
      width: 500,
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
      dataType: 'number',
    },
    {
      title: 'Điểm thi cuối kỳ',
      key: 'scoresEndSemester',
      dataIndex: 'scoresEndSemester',
      width: 500,
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
      dataType: 'number',
    },
    {
      title: 'Điểm tổng kết',
      key: 'scoresTotal',
      dataIndex: 'scoresTotal',
      width: 500,
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
      dataType: 'number',
    },
    {
      title: 'Ghi chú',
      key: 'notes',
      dataIndex: 'notes',
      width: 500,
      render: 'renderNoWrap',
      align: 'center',
      style: {
        textAlign: 'left',
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
        width: 150,
      },
    },

    // {
    //   title: 'Điểm',
  ],
};
