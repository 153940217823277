import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';

const addNotificationManagement = (
  Content: string,
  CreatedBy: number,
  Notes: string,
  NotificateCode: string,
  NotificationName: string,
  Status: boolean,
) => {
  const sqlCommand = 'p_QUIZCITY_AddNotificationManagement';
  const parameters = { Content, CreatedBy, Notes, NotificateCode, NotificationName, Status };
  const applicationName = settings.APPLICATION_NAME;
  return query(sqlCommand, parameters, applicationName);
};

export default addNotificationManagement;
