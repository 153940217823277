import { Col, Row } from 'antd';
import React, { SyntheticEvent } from 'react';
import LineTo, { Line } from 'react-lineto';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import AudioButton from '../AudioButton';
import QuestionHeader from '../QuestionHeader';
import _ from 'lodash';
import './style.css';
import { QuestionComponentRef } from '../type';

const LineToImage = ({ data, questionIndex, onSubmit, userData, preview = false }: any, ref: QuestionComponentRef) => {
  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
  }));

  // State
  const [selectedAnswer, setSelectedAnswer] = React.useState('');
  const [selectedPoint, setSelectedPoint] = React.useState('');
  const [arr, setArr] = React.useState<any[]>([]);
  const [dataAnswers, setDataAnswers] = React.useState<any[]>([]);
  const [colorList, setColorList] = React.useState<any[]>([]);
  const [vh, setVh] = React.useState<number>();
  const [vw, setVw] = React.useState<number>();

  //Ref
  const refSvg = React.useRef<any>([]);
  const refImage = React.useRef<any>([]);
  const refAnswer = React.useRef<any>([]);

  //Variables
  const imageSVG = data?.imageSVG;

  window.addEventListener('resize', () => {
    setVw(document.documentElement.clientWidth);
    setVh(document.documentElement.clientHeight);
  });

  //Effect
  React.useEffect(() => {
    let rgb: any = [];
    for (var i = 0; i < data.answers.length; i++) rgb.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
    setColorList(rgb);

    data.answers.map((item: any, index: number) => {
      setDataAnswers((existingAnswers: any) => [
        ...existingAnswers,
        { id: index, answerClass: item.answer, pointClass: `path-item-${index}` },
      ]);
    });

    if (preview) {
      data.answers.map((item: any, index: number) => {
        setArr((existingAnswers: any) => [
          ...existingAnswers,
          { id: index, answerClass: item.answer, pointClass: `path-item-${index}` },
        ]);
      });
    } else if (userData) {
      userData.userAnswers.map((item: any, index: number) => {
        setArr((existingAnswers) => [
          ...existingAnswers,
          { id: index, answerClass: item.answerClass, pointClass: item.pointClass },
        ]);
      });
    } else {
      data.answers.map((item: any, index: number) => {
        // if (index === 0) {
        setArr((existingAnswers) => [
          ...existingAnswers,
          { id: index, answerClass: item.answer, pointClass: index === 0 ? `path-item-${index}` : '' },
        ]);
      });
    }
    return () => {
      setArr([]);
      setDataAnswers([]);
    };
  }, [data, userData, preview]);

  const handleClickAnswer = React.useCallback((index: number) => {
    let answerClass = refAnswer.current[index].getAttribute('data-answer');
    setSelectedAnswer(answerClass);

    data.answers.map((item: any, i: number) => {
      if (i === index) {
        refAnswer.current[i].style.border = '3px solid #A3CB38';
      } else {
        refAnswer.current[i].style.border = '3px solid #b2bec3';
      }
    });
  }, []);

  const handleClickPoint = (key: any) => {
    let pointClass = refImage.current[key].getAttribute('data-point');
    refImage.current[key].setAttribute('data-answer', selectedAnswer);
    setSelectedPoint(pointClass);
    arr.forEach((item: any, index: number) => {
      if (index === _.findIndex(arr, { answerClass: selectedAnswer })) {
        arr[index] = { id: index, answerClass: selectedAnswer, pointClass: pointClass };
      }
    });

    setArr(arr);
  };

  const getColor = (index: number) => {
    if (preview) {
      return '#27ae60';
    } else if (userData) {
      if (_.isEqual(dataAnswers?.[index], arr?.[index])) {
        return '#27ae60';
      } else {
        return '#e74c3c';
      }
    } else {
      return colorList[index];
    }
  };

  const renderLine = React.useCallback(
    (item, index) => {
      let answerClass = item.answerClass;
      let pointClass = item.pointClass;

      return (
        <LineTo
          key={index}
          from={answerClass}
          to={pointClass}
          fromAnchor={index < 3 ? '50% right' : 'left 50%'}
          zIndex={999999}
          borderWidth={3}
          borderColor={getColor(index)}
          borderStyle='dashed'
        />
      );
    },
    [data, userData, arr, selectedAnswer, selectedPoint, vh, vw],
  );

  const handleSubmit = () => {
    const result = _.isEqual(arr, dataAnswers) ? 100 : 0;
    const userAnswers = arr;
    onSubmit?.({id: data.id, userAnswers, result }, data);
    setArr([]);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <QuestionHeader title={data.title} subTitle={data.subTitle} questionIndex={questionIndex} />
        </CardHeader>
        <CardBody className='pb-3 tw-relative' style={{ minHeight: 300 }}>
          <div className='tw-absolute tw-top-6'>
            <AudioButton audioUrl={data?.audioUrl} />
          </div>
          <Row style={{ width: '100%' }} className='tw-items-center'>
            <Col span={5}>
              {data.answers.map((item: any, index: number) => {
                if (index < 3)
                  return (
                    <div
                      key={index}
                      data-answer={item.answer}
                      className={`${item.answer} tw-p-5 tw-border-2 tw-mb-5 tw-flex tw-gap-5 tw-items-center tw-rounded-lg tw-cursor-pointer`}
                      onClick={() => {
                        handleClickAnswer(index);
                      }}
                      ref={(el) => (refAnswer.current[index] = el)}
                      style={{ backgroundColor: 'white', border: '3px solid #b2bec3' }}
                    >
                      {item.image && (
                        <div style={{ width: '50%' }}>
                          <img src={item.image} style={{ height: 60 }} alt='' />
                        </div>
                      )}
                      <span style={{ fontSize: 18 }}>
                        <strong>{item.answer}</strong>
                      </span>
                    </div>
                  );
              })}
            </Col>
            <Col span={14} className='tw-flex tw-justify-center'>
              <svg width={imageSVG?.width} height={imageSVG?.height} viewBox={imageSVG?.viewBox} ref={refSvg}>
                <image width={imageSVG?.image?.width} href={imageSVG?.image?.href} />
                {imageSVG.itemPaths.map((item: any, key: number) => (
                  <path
                    key={key}
                    ref={(el) => (refImage.current[key] = el)}
                    data-answer=''
                    data-point={`path-item-${key}`}
                    onClick={() => handleClickPoint(key)}
                    style={{
                      cursor: 'pointer',
                      fill: 'red',
                      stroke: 'black',
                    }}
                    className={`path-item-${key} point-item`}
                    d={item.data}
                  />
                ))}
              </svg>
            </Col>
            <Col span={5}>
              {data.answers.map((item: any, index: number) => {
                if (index >= 3)
                  return (
                    <div
                      key={index}
                      data-answer={item.answer}
                      className={`${item.answer} tw-p-5 tw-border-2 tw-mb-5 tw-flex tw-gap-5 tw-items-center tw-rounded-lg tw-cursor-pointer`}
                      onClick={() => {
                        handleClickAnswer(index);
                      }}
                      ref={(el) => (refAnswer.current[index] = el)}
                      style={{ backgroundColor: 'white', border: '3px solid #b2bec3' }}
                    >
                      {item.image && (
                        <div style={{ width: '50%' }}>
                          <img src={item.image} style={{ height: 60 }} alt='' />
                        </div>
                      )}
                      <span style={{ fontSize: 18 }}>
                        <strong>{item.answer}</strong>
                      </span>
                    </div>
                  );
              })}
            </Col>
          </Row>
        </CardBody>
        <div>{arr.map(renderLine)}</div>
      </Card>
    </>
  );
};

export default React.forwardRef(LineToImage);
