import { notification } from 'antd';
import axios from 'axios';
import { sachsoServerUrl } from 'constants/serverUrls';
import { APPLICATION_NAME, MODULE_ID } from 'constants/settings';
import i18next from 'i18next';
import { convertToSlug } from 'services/Text/formatText';

const authorizationKey = 'Basic 12C1F7EF9AC8E288FBC2177B7F54D';

const exportExcel = ({ sqlCommand, parameters = {}, fileName }: { sqlCommand: string; parameters: any; fileName: string }) => {
  let fileNameDefault = convertToSlug(fileName ?? Date.now().toString())+'.xlsx';
  return new Promise((resolve, reject) => {
    queryExcel(sqlCommand, parameters, APPLICATION_NAME, false)
      .then((response: any) => {
        if (response.ok && response.excelDownloadUrl !== '') {
            const url = response.excelDownloadUrl;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileNameDefault);
            document.body.appendChild(link);
            link.click();
                 notification.success({
                   message: 'Thành công',
                   description: 'Xuất tệp excel thành công!',
                   placement: 'top',
                 });
            resolve(response);
        } else {
          reject(response);
           notification.error({
             message: i18next.t('Warning'),
             description: 'Xuất tệp excel thất bại!',
             placement: 'top',
           });
        }
      })
      .catch((error: any) => {
         notification.error({
           message: i18next.t('Warning'),
           description: 'Xuất tệp excel thất bại!',
           placement: 'top',
         });
        reject(error);
      });
  })


};

function queryExcel(sqlCommand: string, parameters = {}, applicationName: string = APPLICATION_NAME, addModuleId = true) {
  let params = parameters;
if(addModuleId)params = { ...parameters, moduleId:  MODULE_ID  };
  return new Promise((resolve, reject) => {
    axios({
      url: `${sachsoServerUrl}/api/v1.0/dynamic/query`,
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters: params,
        exportToExcel: true,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export { exportExcel, queryExcel };
