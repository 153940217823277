import _ from 'lodash';
import { modeName } from '../../CanvasToolPage/configuration';

const { WRITE, CHOICE,MULTIPLECHOICE2, MATCH, MULTIPLEMATCH, SELECTWORD, LISTEN, DRAGDROP, DROPDOWN, SPEECHRECOGNITION, RECORD } = modeName;
export function modeFilter(data) {
  if (!data) return {};
  // const NO = 'no';
  const YES = 'yes';
  const list = _.groupBy(data, (o) => o.mode);

  //mode = CHOICE
  const groupChoice = Object.values(_.groupBy(list[CHOICE], (x) => x.groupName));
  const oneChoice = groupChoice.filter((item) => _.countBy(item, (o) => o.text)[YES] === 1);
  const multipleChoice = groupChoice.filter((item) => _.countBy(item, (o) => o.text)[YES] > 1);
  //
  return {
    write: list[WRITE],
    match: list[MATCH],
    multipleMatch: list[MULTIPLEMATCH],
    listen: list[LISTEN],
    oneChoice, //mode = CHOICE
    multipleChoice, //mode = CHOICE
    multipleChoice2: list[MULTIPLECHOICE2], //Vì follow cũ tách mode = CHOICE ra 2 component nên giờ cần tạo component khác
    selectWord: list[SELECTWORD],
    dragdrop: list[DRAGDROP],
    dropDown: list[DROPDOWN],
    speechRecognition: list[SPEECHRECOGNITION],
    record: list[RECORD],
  };
}
