import React from 'react';
import { Descriptions } from 'antd';
import { queryWithoutModuleId } from 'helpers/QueryHelper';
import { useQuery } from 'react-query';
import colors from 'constants/colors';
import i18next from 'i18next';

type Props = {
  student: any;
};

const StudentInfomationHeaderv2 = ({ student }: Props) => {
  return (
    <React.Fragment>
      {student && (
        <Descriptions
          labelStyle={{ fontWeight: 600, color: colors.font, width: '1%', whiteSpace: 'nowrap' }}
          layout='vertical'
          bordered
          column={1}
          size='small'
        >
          <Descriptions.Item label={i18next.t("Student's full name")}>{student?.[0]?.fullName}</Descriptions.Item>
          {/* <Descriptions.Item label='Điện thoại liên lạc'>{student?.[0].phone ?? ''}</Descriptions.Item> */}
          <Descriptions.Item label='Email'>{student?.[0]?.email ?? ''}</Descriptions.Item>
        </Descriptions>
      )}
    </React.Fragment>
  );
};
export default StudentInfomationHeaderv2;
