import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { Fragment, memo, useEffect, useState } from 'react';
import ColorModal from './components/ColorModal';

function MultipleMatchElement({ canvas, obj, dependency }) {
  const { data, top, left, width, height, scaleX, scaleY } = JSON.parse(dependency);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [icon, setIcon] = useState(data.icon);
  if (obj.group) return null; // when selecting multiple objects

  //
  const newWidth = width * scaleX;
  const newHeight = height * scaleY;
  // update data
  // Dành cho sử dụng input phía dưới
  // const handleChange = (e) => {
  //   const { value } = e.target;
  //   const { data: handleChangeData } = obj;
  //   handleChangeData.text = value;
  //   canvas.renderAll();
  // };

  const handleChange = (text, shape) => {
    setIcon(shape);
    const { data: handleChangeData } = obj;
    handleChangeData.text = text;
    handleChangeData.icon = shape;
    canvas.renderAll();
  };
  //
  const titleLabel = () => {
    const { data: titleLableData } = obj;
    return (
      <div>
        {/* <span> Mode: {obj.mode}</span> */}
        <span>
          Group:
          {' '}
          {titleLableData.groupName}
        </span>
      </div>
    );
  };
  //
  return (
    <Fragment>
      <div
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          top: top + newHeight / 2,
          left: left + newWidth / 2,
          // width: newWidth,
        }}
      >
        {/* <Input size='small' defaultValue={data.text} style={{ top: 0, width: '100%', fontSize: 16, backgroundColor: data.text }} onChange={handleChange} /> */}
        <Tooltip placement='top' title={titleLabel()} zIndex={9999}>
          <Button type='text' onClick={() => setIsModalVisible(true)}>
            <i className={`${icon} fa-2x`} style={{ color: data.text }} />
          </Button>
        </Tooltip>
      </div>
      <ColorModal
        handleChange={handleChange}
        isModalVisible={isModalVisible}
        position={{
          top,
          left,
          width: newWidth,
          height: newHeight,
        }}
        setIsModalVisible={setIsModalVisible}
      />
    </Fragment>
  );
}

MultipleMatchElement.propTypes = {
  canvas: PropTypes.object,
  obj: PropTypes.object,
  dependency: PropTypes.string, // update reload dependency
};

MultipleMatchElement.defaultProps = {
  canvas: {},
  obj: {},
  dependency: '', // update reload dependency
};

export default memo(MultipleMatchElement);
