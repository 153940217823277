import { IS_BASAO, IS_HANDOUT, IS_HCM, IS_ISE, IS_LG, IS_LX, IS_MENARD, IS_PT, IS_TRONGDONG, IS_TVHH } from 'constants/settings';
import _ from 'lodash';
import adminRoutes from './adminRoutes';
import handoutRoutes from './handoutRoutes/index';
import iseRoutes from './iseRoutes';
import lgInnotekRoutes from './lgInnotekRoutes';
import menardManagerRoutes from './menardManagerRoutes';
import menardRoutes from './menardRoutes';
import ptRoutes from './ptRoutes';
import publicRoutes from './publicRoutes';
import quizcityRoutes from './quizcityRoutes';
import studentRoutes from './studentRoutes';
import teacherRoutes from './teacherRoutes';
import tvhhRoutes from './tvhhRoutes';
import vimaruManagerRoutes from './vimaruManagerRoutes';

const shareRoutes = IS_TVHH
  ? tvhhRoutes
  : IS_ISE
  ? iseRoutes
  : IS_LG
  ? lgInnotekRoutes
  : IS_PT
  ? ptRoutes
  : IS_HANDOUT
  ? handoutRoutes
  : IS_HCM
  ? _.concat(publicRoutes, teacherRoutes, quizcityRoutes, studentRoutes)
  : IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX
  ? _.concat(publicRoutes, studentRoutes, teacherRoutes, menardManagerRoutes, menardRoutes)
  : _.concat(publicRoutes, studentRoutes, teacherRoutes, adminRoutes, vimaruManagerRoutes);

export default shareRoutes;
