import React, { useState, useEffect } from 'react';
import { query } from 'helpers/QueryHelper';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import tableData from '../tableData';
import { useQuery } from 'react-query';

const fetcher = async (parameters) => {
  const results = await query('p_QUIZCITY_PTTest_CodeExam_Select', parameters);
  return results;
};

export default function ListCodes({ parameters }) {
  const { data, isLoading } = useQuery(['p_QUIZCITY_PTTest_CodeExam_Select', parameters], () => fetcher(parameters));

  return (
    <DynamicTable
      rowClassName='tw-cursor-pointer'
      rowKey='id'
      loading={isLoading && parameters}
      dataSource={data}
      initialTableData={tableData}
      pagination={{ defaultPageSize: 10 }}
      scroll={{ x: 1300 }}
    />
  );
}
