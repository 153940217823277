import React from 'react';
import { Container } from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader';

type Props = {
  name: string;
  parentName: string;
  children: JSX.Element | JSX.Element[];
};

export default ({ name, parentName = 'LMS', children }: Props) => {
  return (
    <React.Fragment>
      {/* <SimpleHeader name={name} parentName={parentName} /> */}
      <Container className='mt-4' fluid>
        {children}
      </Container>
    </React.Fragment>
  );
};
