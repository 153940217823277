import React from 'react';
import Container from 'components/Containers/Container';
import Card from 'components/Containers/Card';
import Folders from '../../Folders';

const AssignmentsManagement = (props) => {
  return (
    <React.Fragment>
      <Container name='' parentName='Quản lý bài tập'>
        <Card title='QUẢN LÝ BÀI TẬP' subTitle='Tài nguyên dùng chung'>
          <Folders />
        </Card>
      </Container>
    </React.Fragment>
  );
};

AssignmentsManagement.propTypes = {};

export default AssignmentsManagement;
