import { combineReducers } from 'redux';
import authReducer from './Auth/reducers';
import settingReducer from './Setting/reducers';
import settingsReducer from './Settings/reducers';
// import authReducer from './Login/reducers';
import assignmentReducer from './AssignmentModule/reducers';
import resultReducer from './ResultModule/reducers';

import olympicReducer from './Olympic/reducers';
import teacherReducer from './TeacherModule/reducers';
import sidebarReducer from './Sidebar/reducers';
import slideQuizReducer from './Presentation/Viewer/reducers';

const rootReducer = combineReducers({
  settingReducer,
  authReducer,
  assignmentReducer,
  resultReducer,
  olympicReducer,
  teacherReducer,
  sidebarReducer,
  slideQuizReducer,
  settingsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
