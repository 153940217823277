import { CopyOutlined } from '@ant-design/icons';
import { Button, message, Tag } from 'antd';
import settings from 'constants/settings';

export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'Tên bài giảng',
      key: 'presentationTitle',
      width: '15%',
      fixed: 'left',
      sort: false,
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
      },
    },
    {
      title: 'Ghi chú',
      key: 'note',
      width: '10%',
      sort: false,
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
      },
    },

    {
      title: 'Sĩ số',
      key: 'totalStudents',
      sort: false,
      render: (text, record) => <div style={{ fontWeight: 600, minWidth: 30, textAlign: 'right' }}>{text}</div>,
    },
    {
      title: 'Link giáo viên',
      key: 'linkHandoutTeacher',
      width: '15%',
      sort: false,
      render: (linkHandout) => {
        return (
          <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'space-between', gap: 5 }}>
            <Button type='link' onClick={() => window.open(settings.DOMAIN_IS_USE + linkHandout)}>
              {settings.DOMAIN_IS_USE + linkHandout}
            </Button>
            <Button
              type='dashed'
              onClick={() => {
                navigator.clipboard.writeText(settings.DOMAIN_IS_USE + linkHandout);
                message.success('Sao chép đường dẫn thành công');
              }}
              icon={<CopyOutlined />}
            />
          </div>
        );
      },
    },
    {
      title: `Link ${settings.studentPronouns1}`,
      key: 'linkHandoutStudent',
      width: '15%',
      sort: false,
      render: (linkHandout) => {
        return (
          <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'space-between', gap: 5 }}>
            <span>{settings.DOMAIN_IS_USE + linkHandout}</span>
            <Button
              type='dashed'
              onClick={() => {
                navigator.clipboard.writeText(settings.DOMAIN_IS_USE + linkHandout);
                message.success('Sao chép đường dẫn thành công');
              }}
              icon={<CopyOutlined />}
            />
          </div>
        );
      },
    },
    {
      title: 'Trạng thái',
      key: 'status',
      width: '10%',
      sort: false,
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>
            <Tag color={text ? 'red' : 'blue'}>{text ? 'Đã sử dụng' : 'Chưa sử dụng'}</Tag>
          </div>
        );
      },
    },
    {
      title: 'Tên giáo viên',
      key: 'teacherName',
      width: '10%',
      sort: false,
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
      },
    },
    {
      title: 'Email',
      key: 'teacherEmail',
      width: '10%',
      sort: false,
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
      },
    },
    {
      title: 'Chi nhánh',
      key: 'divisionName',
      width: '10%',
      sort: false,
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
      },
    },
    // {
    //   title: '',
    //   key: 'detailLink',
    //   width: '5%',
    //   sort: false,
    //   render: (text, record) => {
    //     return <Button type='link' onClick={() => {
    //       window.open(settings.DOMAIN_IS_USE + record.linkHandoutTeacher);
    //     }}>Xem chi tiết</Button>;
    //   },
    // },
  ],
};
