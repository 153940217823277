import Teacher_Classes_Details from 'pages/Teacher/Classes/ClassDetails';
import Teacher_Classes from 'pages/Teacher/Classes/Classes';
import React from 'react';
// Icons
import Users from 'pages/Administration/Users/index';
import { MdDoneOutline, MdGroup as ClassIcon, MdClass } from 'react-icons/md';
import IndexManageKhoaNganh from 'pages/Administration/ManageKhoaNganh';
import ManagerCourse from 'pages/Administration/ManagerCourse';
import IndexImportQuestion from 'pages/Administration/ImportQuestion';
import ConfigSettings from 'pages/Administration/ConfigSettings';
import { FaChalkboardTeacher, FaCogs } from 'react-icons/fa';
import { SiGoogleclassroom } from 'react-icons/si';
import colors from 'constants/colors';
import { HiClipboardList } from 'react-icons/hi';
import settings from 'constants/settings';
import { TbReportAnalytics } from 'react-icons/tb';

const URL_HuongDanSuDung = settings.IS_VIMARU
  ? 'https://cloud.softech.vn/storage/Data/VIMARU/Huong-dan-su-dung-cho-Quan-tri-vien.pdf'
  : null;

const fileHelp = {
  name: 'Hướng dẫn sử dụng',
  href: URL_HuongDanSuDung,
  icon: <HiClipboardList size={20} className='tw-text-blue-500' />,
  layout: '/lms',
  exact: true,
  roles: ['manager'],
  showInSidebar: true,
  type: ['allowedRoutes'],
  sortOrder: 9999,
};
export default [
  {
    component: Teacher_Classes,
    exact: true,
    icon: <SiGoogleclassroom size={20} className='tw-text-red-500' />,
    layout: '/lms',
    miniName: 'C',
    name: 'Quản lý lớp học',
    path: '/admin/classes',
    roles: ['manager'],
    showInSidebar: true,
    sortOrder: 2,
    type: ['allowedRoutes'],
  },
  {
    collapse: true,
    path: '/admin/report',
    name: 'Báo cáo, thống kê',
    miniName: 'C',
    icon: <TbReportAnalytics size={20} style={{ color: '#AC81FF' }} />,
    roles: ['manager'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 4,
    state: 'lms/admin/report',
    views: [
      {
        path: '/admin/week',
        name: 'Báo cáo theo tuần',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportWeek')),
        layout: '/lms',
        exact: true,
        roles: ['manager'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
      {
        path: '/admin/final',
        name: 'Báo cáo tổng kết',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportFinal')),
        layout: '/lms',
        exact: true,
        roles: ['manager'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },
  // {
  //   path: '/admin/import-question',
  //   name: 'Quản lý câu hỏi',
  //   miniName: 'C',
  //   icon: 'fas fa-question text-green',
  //   component: IndexImportQuestion,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['manager'],
  //   type: ['administrationRouters'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },
  {
    component: Teacher_Classes_Details,
    exact: true,
    layout: '/lms',
    path: '/admin/classes/:classId',
    roles: ['manager'],
    type: ['allowedRoutes'],
  },

  // {
  //   path: '/admin/users',
  //   name: 'Quản lý người dùng',
  //   miniName: 'C',
  //   icon: 'fa fa-user text-red',
  //   component: Users,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['manager'],
  //   type: ['administrationRouters'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },
  // {
  //   path: '/admin/quan-ly-khoa-nganh',
  //   name: 'Danh mục Khoa, Ngành',
  //   miniName: 'C',
  //   icon: '	fa fa-server',
  //   component: IndexManageKhoaNganh,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['manager'],
  //   type: ['administrationRouters'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },
  // {
  //   path: '/admin/danh-muc-khoa-hoc',
  //   name: 'Danh mục khoá học',
  //   miniName: 'C',
  //   icon: <MdClass />,
  //   component: ManagerCourse,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['manager'],
  //   type: ['administrationRouters'],
  //   showInSidebar: true,
  //   sortOrder: 1,
  // },
  // {
  //   path: '/admin/presentations',
  //   name: 'Quản lý bài giảng',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Teacher/Presentations')),
  //   icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500' />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['manager'],
  //   type: ['allowedRoutes'],
  //   showInSidebar: true,
  //   sortOrder: 3,
  // },

  {
    path: '/admin/presentation/:presentationId/create',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/presentation/:presentationId/edit',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['allowedRoutes'],
  },

  // {
  //   collapse: true,
  //   path: '/admin/worksheets',
  //   name: 'Quản lý thư viện',
  //   miniName: 'C',
  //   icon: <MdDoneOutline size={20} className='tw-text-green-600' />,
  //   roles: ['manager'],
  //   type: ['allowedRoutes'],
  //   showInSidebar: true,
  //   sortOrder: 3,
  //   state: 'lms/admin/worksheets',
  //   views: [
  //     {
  //       path: '/admin/worksheets/list/:filter',
  //       component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
  //       layout: '/lms',
  //       exact: true,
  //       roles: ['manager'],
  //       type: ['allowedRoutes'],
  //     },
  //     {
  //       path: '/admin/worksheets/list/quizcity-worksheet',
  //       name: 'Bài tập Worksheet',
  //       miniName: 'D',
  //       component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
  //       layout: '/lms',
  //       exact: true,
  //       roles: ['manager'],
  //       type: ['allowedRoutes'],

  //       showInSidebar: true,
  //       sortOrder: 1,
  //     },
  //     {
  //       path: '/admin/worksheets/list/quizcity-quiz',
  //       name: 'Bài tập Quiz',
  //       miniName: 'D',
  //       component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
  //       layout: '/lms',
  //       exact: true,
  //       roles: ['manager'],
  //       type: ['allowedRoutes'],
  //       showInSidebar: true,
  //       sortOrder: 1,
  //     },
  //   ],
  // },
  {
    path: '/admin/classes/:classId/classwork/assignments/add',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/edit/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/details/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/Details')),
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['allowedRoutes'],
  },
].concat(URL_HuongDanSuDung ? [fileHelp] : []);
