/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { message, Form, Input, Modal, TreeSelect, InputNumber, Row, Col, Upload, Button } from 'antd';
import { FolderOutlined, UploadOutlined } from '@ant-design/icons';
import React from 'react';
import _ from 'lodash';

import { add, update } from 'helpers/QueryHelper';
import withAuthenticator from 'HOCs/withAuthenticator';

import ColorPicker from 'components/ColorPicker';
import { LoggedInUserType } from 'types';

import createImageUploadingProps from '../../../FileProcessing/imageUploadingProps';
import settings from 'constants/settings';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import colors from 'constants/colors';

type Props = {
  loggedInUser?: LoggedInUserType;
  updateMode: string | 'add' | 'edit';
  data: any;
  visible: boolean;
  onOk: Function;
  onCancel: Function;
  treeData: any;
};

// Module quản lý thư viện, tài nguyên.

function UpdateFolder({ loggedInUser, updateMode, data, visible, onOk, onCancel, treeData }: Props): JSX.Element {
  const { t } = useTranslation('');
  const [imgUrl, setImgUrl] = React.useState(''); // Used for image uploading
  const [form] = Form.useForm();
  const [processing, setProcessing] = React.useState(false);
  // Image Uploading Props
  const imageUploadingProps = createImageUploadingProps(setImgUrl, form, message);

  React.useEffect(() => {
    const initialValues = {
      parentId: updateMode === 'add' ? data?.id : data?.parentId,
      name: updateMode === 'add' ? '' : data?.name,
      color: updateMode === 'add' ? '#32325d' : data && data.color ? data.color : '#32325d',
      sortOrder: updateMode === 'add' ? 0 : data?.sortOrder,
      imageUrl: data?.imageUrl ?? '',
    };
    if (visible) {
      form.setFieldsValue(initialValues);
      setImgUrl(data?.imageUrl);
    }
  }, [form, visible]);

  // Handle form submit
  const onFinish = async (values: any) => {
    await setProcessing(true);

    const parameters = {
      ...values,
      // classId: data.classId, // Module Quản lý thư viện, tài nguyên không cần classId.
      createdBy: loggedInUser.id,
    };

    if (updateMode === 'add') {
      // Thêm thư mục module Quản lý thư viện, tài nguyên.
      const response = await add('Folders', parameters, 'LIBRARIES');
      if (response) {
        message.success(i18next.t('popconfirm.createDatasuccess'));
        setTimeout(() => setProcessing(false), 1500);
        onOk();
      } else {
        setTimeout(() => setProcessing(false), 1500);
        message.error(t('popconfirm.oops'));
      }
    } else {
      // updateMode === 'edit'
      // Sửa thư mục module Quản lý thư viện, tài nguyên.
      const response = await update('Folders', data.id, parameters, 'LIBRARIES', 'SACHSO');
      form.setFieldsValue({ imageUrl: '' }); // Remove value in imageUrl input
      if (response) {
        setTimeout(() => setProcessing(false), 1500);
        message.success(t('update success', { field: 'data' }));

        onOk();
      } else {
        setTimeout(() => setProcessing(false), 1500);
        message.error(t('popconfirm.oops'));
      }
    }
  };

  return (
    <Modal
      confirmLoading={processing}
      maskClosable={false}
      centered
      title={
        updateMode === 'add'
          ? i18next.t('commonTitle.Add new', { object: '' }).toUpperCase()
          : i18next.t('Update').toUpperCase()
      }
      open={visible}
      onOk={async () => {
        form.submit();
      }}
      onCancel={onCancel}
      okText={updateMode === 'add' ? i18next.t('commonTitle.Add new', { object: '' }) : i18next.t('Update')}
      cancelText={t('Close')}
      width={600}
    >
      <Form form={form} layout='vertical' onFinish={onFinish} requiredMark={false}>
        <Form.Item label={i18next.t('reference:Parentfolder')} name='parentId'>
          <TreeSelect
            style={{ color: colors.font }}
            treeData={treeData}
            allowClear
            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: 10000 }}
            treeDefaultExpandAll
          />
        </Form.Item>

        <Form.Item
          label={i18next.t('reference:Foldername')}
          name='name'
          rules={[{ required: true, message: t('please enter', { field: t('folderName') }) }]}
        >
          <Input style={{ color: colors.font }} />
        </Form.Item>

        {/* Image Uploading */}
        <Row gutter={[12, 12]}>
          <Col lg={16} xs={24}>
            <Form.Item name={'imageUrl'} label={`Image Url` + i18next.t('reference:fileSize')}>
              <Input style={{ color: colors.font }} placeholder='https://' />
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item label={` `}>
              <Upload {...imageUploadingProps}>
                <DynamicButton block icon={<UploadOutlined />}>
                  {i18next.t('uploadImage')}
                </DynamicButton>
              </Upload>
            </Form.Item>
          </Col>
          {(imgUrl || form.getFieldsValue()?.imageUrl) && (
            <img src={imgUrl || form.getFieldsValue()?.imageUrl} style={{ width: 200, marginBottom: 20 }} />
          )}
        </Row>

        <Form.Item
          label={i18next.t('pages:lectureManagement.Order')}
          name='sortOrder'
          rules={[{ required: true, message: t('please enter', { field: t('sortOrder') }) }]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item label={i18next.t('commonTitle.Color')} name='color'>
          <ColorPicker />
        </Form.Item>
      </Form>
    </Modal>
  );
}

UpdateFolder.defaultProps = {
  loggedInUser: undefined,
};

export default withAuthenticator(UpdateFolder);
