import { UploadOutlined } from '@ant-design/icons';
import { Modal, Spin, Table, Upload, message } from 'antd';
import _ from 'lodash';
import React from 'react';
import { ExcelRenderer } from 'react-excel-renderer';

import colors from 'constants/colors';
import { sendNotificationToUser } from 'helpers/NotificationHelper';
import { query, queryFirst } from 'helpers/QueryHelper';
import i18next from 'i18next';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { useSelector } from 'react-redux';
import columns from './columns';
const MAX = 100;
const MIN = 0;

const checkClass = async (userId) => {
  return query('p_VIMARU_CLASSES_GetClasses', { userId: userId });
};
const handleScore = (score) => {
  if (score > MAX) {
    return MAX;
  }
  if (score < MIN) {
    return MIN;
  }
  return score;
};

const getUserInforByEmail = async (email) => {
  const res = await queryFirst('p_VIMARU_User_SearchByEmail', { email: email });
  return res;
};
export default function ExcelInputScoreModal({ openModal, setOpenModal }) {
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const [rows, setRows] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [loadingUploadFile, seLoadingUploadFile] = React.useState(false);
  const [isUpdated, setIsUpdated] = React.useState(false);

  const handleEnterExel = () => {
    setLoading(true);
    Promise.all(
      rows?.map((row) => {
        if (row.color === 'green') {
          const params = {
            email: row.email,
            scoreOfflineGDI: handleScore(row.scoreGDI) ?? 0,
            scoreOfflineGDII: handleScore(row.scoreGDII) ?? 0,
            notes: row.notes ?? '',
            createdBy: loggedInUser?.id,
            classCode: row.classCode,
          };
          return query('p_VIMARU_ThongKeBaiTap_SummaryOffline_InsertUpdate_ByEmail', params)
            .then((res) => {
              row.excelStatus = res?.[0]?.message ?? 'Cập nhật điểm thành công';
              row.excelColor = res?.[0]?.status === 'fail' ? 'red' : 'green';
              if (res?.[0]?.status !== 'fail') {
                handlSendNotificationToStudent({ ...row, ...params });
              }
            })
            .catch(() => {
              message.error('Có lỗi đã xảy ra');
            });
        }
      }),
    )
      .then(() => {
        setRows([...rows]);
        setIsUpdated(true);
      })
      .finally(() => {
        setLoading(false);
      });
    // setRows((prev) => [...tem, { a: 0, c: 0 }]);
  };
  const fileHandler = async (fileList) => {
    seLoadingUploadFile(true);
    const fileObj = fileList;
    if (!fileObj) {
      message.error(i18next.t('noFileUpload'));
      seLoadingUploadFile(false);
      return false;
    }
    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      message.error(i18next.t('unknowfile'));
      seLoadingUploadFile(false);
      return false;
    }
    const classes = await checkClass(loggedInUser?.id);
    const classCodes = classes.map(({ code }) => code?.toUpperCase());
    await ExcelRenderer(fileObj, async (err, resp) => {
      if (err) {
      } else {
        const newRows = [];
        Promise.all(
          resp.rows.slice(1).map(async (row) => {
            if (row.length === 0 || !row) {
              return null;
            } else {
              let statusData = '';
              let color;
              let typeUploadFile;
              let studentInfor;
              const email = row?.[1] ? String(row[1]).replaceAll(' ', '').toLowerCase() : '';
              const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

              if (!row[0] || !row[1]) {
                statusData = 'Vui lòng điền các trường bắt buộc (*)';
                color = 'red';
              } else if (!classCodes.includes(row[0].toUpperCase())) {
                statusData = 'Mã lớp không tồn tại';
                color = 'red';
              } else if (!filter.test(email)) {
                statusData = 'Vui lòng nhập đúng định dạng Email';
                color = 'red';
              } else if (isNaN(parseFloat(row[2] ?? 0))) {
                statusData = 'Vui lòng nhập điểm X định dạng số ';
                color = 'red';
              } else if (parseFloat(row[2] ?? 0) > MAX || parseFloat(row[2] ?? 0) < MIN) {
                statusData = `Vui lòng nhập điểm X trong khoảng từ ${MIN} - ${MAX}`;
                color = 'red';
              } else if (isNaN(parseFloat(row[3] ?? 0))) {
                statusData = 'Vui lòng nhập điểm Y định dạng số ';
                color = 'red';
              } else if (parseFloat(row[3] ?? 0) > MAX || parseFloat(row[3] ?? 0) < MIN) {
                statusData = `Vui lòng nhập điểm Y trong khoảng từ ${MIN} - ${MAX}`;
                color = 'red';
              } else {
                const { studentId, studentCode, fullName } = await getUserInforByEmail(email);
                if (studentId && studentCode && fullName) {
                  //có các thông tin sau thì có nghĩa là user tồn tại
                  studentInfor = { studentId, studentCode, fullName };
                  statusData = 'OK';
                  color = 'green';
                } else {
                  statusData = 'Email chưa đăng kí';
                  color = 'red';
                }
              }
              newRows.push({
                typeUploadFile,
                classCode: row[0],
                email: email,
                scoreGDI: parseFloat(row[2] ?? 0),
                scoreGDII: parseFloat(row[3] ?? 0),
                notes: row[4] || ' ',
                accessInsert: statusData === 'OK',
                color,
                statusData,
                ...studentInfor,
                //
              });
            }
          }),
        ).finally(() => {
          if (newRows.length === 0) {
            message.error('Không có dữ liệu nào được tìm thấy trong tệp!');
            seLoadingUploadFile(false);
            return false;
          }
          setRows(newRows);
        });
      }
    });
    seLoadingUploadFile(false);
    return false;
  };

  const handlSendNotificationToStudent = (data) => {
    const buildBodyNoti = ({ studentCode, studentName, diemGK, diemCK }) => {
      return `<div>
            <strong>Hệ thống Vimaru EnglishCity xin thông báo kết quả kiểm tra môn AVCB như sau:</strong>
          </div>
          <div style='padding: 10px 0px'>
            <div>- Họ tên sinh viên: ${studentName}</div>
            <div>- Mã sinh viên: ${studentCode}</div>
            <div>- Điểm kiểm tra giữa kỳ: ${diemGK}</div>
            <div>- Điểm kiểm tra cuối kỳ: ${diemCK}</div>
          </div>
          <div>
            <strong>Lưu ý:</strong> 
            <i>
              Với những sinh viên đã nộp chứng chỉ tiếng Anh cho phòng đào tạo thì điểm quy đổi sẽ được ghi nhận trên hệ
              thống của Phòng đào tạo.
            </i>
          </div>`;
    };
    sendNotificationToUser({
      title: 'Thông báo kết quả kiểm tra',
      body: buildBodyNoti({
        studentCode: data.studentCode,
        studentName: data.fullName,
        diemGK: data.scoreOfflineGDI,
        diemCK: data.scoreOfflineGDII,
      }), // HTML string
      classId: 0,
      avatar: loggedInUser?.avatarUrl ?? '',
      name: loggedInUser?.fullName,
      icon: '',
      color: colors.theme.button,
      createdBy: loggedInUser?.id,
      receiverId: data.studentId,
      type: 3, //0: Common , 1: Support, 2: Booking, 3: Other
    });
  };
  return (
    <Modal
      width={'90vw'}
      title='Nhập điểm bằng exel'
      open={openModal}
      onCancel={() => setOpenModal(false)}
      destroyOnClose
      centered
      footer={
        <div style={{ textAlign: 'right' }}>
          <DynamicButton
            onClick={() => {
              setOpenModal(false);
              setIsUpdated(false);
              setRows([]);
            }}
            type={`${isUpdated && 'primary'}`}
          >
            Đóng
          </DynamicButton>
          {!isUpdated && (
            <DynamicButton
              // disabled={isWaiting}
              onClick={() => handleEnterExel()}
              type='primary'
            >
              Thêm
            </DynamicButton>
          )}
        </div>
      }
    >
      <div style={{ display: 'flex', gap: 20 }}>
        <Upload beforeUpload={fileHandler} maxCount={1}>
          <DynamicButton loading={loadingUploadFile} icon={<UploadOutlined />}>
            {i18next.t('uploadExcel')}
          </DynamicButton>
        </Upload>
        <span style={{ fontSize: 14, textDecoration: 'underline', color: 'blue', cursor: 'pointer', marginTop: 4 }}>
          <a href='https://cloud.softech.vn/storage/Data/TemplateImportExel/ScoreTemplate.xlsx' download>
            {i18next.t('Sample file')}
          </a>
        </span>
      </div>
      {rows && !!rows.length && (
        <>
          <Spin spinning={loading} tip='Đang cập nhật dữ liệu...'>
            <div style={{ marginTop: 20 }}>
              <Table key={JSON.stringify(rows)} dataSource={rows} columns={_.concat(columns)} />
            </div>
          </Spin>
        </>
      )}
    </Modal>
  );
}
