import { InfoCircleFilled } from '@ant-design/icons';
import withAuthenticator from 'HOCs/withAuthenticator';
import { Affix, Form, Input, Modal, Select, message } from 'antd';
import colors from 'constants/colors';
import settings, { MODULE_ID } from 'constants/settings';
import { amesSendMail, query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import moment from 'moment';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import getSettings from 'pages/Administration/api/getSettings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

const getAdminInfor = () => {
  return query('p_QUIZCITY_User_GetRoleAdmin');
};

function SendRequest({ loggedInUser }) {
  const { t } = useTranslation(['index', 'table']);
  const [form] = Form.useForm();
  const [guideModalVisible, setGuideModalVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { data: categories, isLoading } = useQuery('p_QUIZCITY_Request_Category_Select', () =>
    query('p_QUIZCITY_Request_Category_Select'),
  );
  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);
  const queryClient = useQueryClient();

  const formatCategoriesOption = (arr) => {
    if (arr?.length > 0) {
      const sort = _.sortBy(arr, ['categoryName']); // sắp xếp theo categoryName
      return sort.map((i) => ({
        label: i.categoryName,
        value: i.id,
      }));
    } else return [];
  };

  const bodySupportRequestNoti = ({ requestDate, category, content }) => {
    return `
      <p style="margin: 0;">
        <b>Your support request date ${requestDate} has been sent successfully !</b>
      </p>
      <hr />
      <p>
        <ul>
          <li>
            Category : ${category}
          </li>
          <li>
            Request Content: ${content}
          </li>
        </ul>
      </p>
    `;
  };

  const handleSendRequest = (value) => {
    setLoading(true);
    const { requestCategoryId, questionContent } = value;
    const requestCategoryName = categories?.find((i) => i.id === requestCategoryId).categoryName;
    const createdDate = moment().toISOString();
    // Send request to admin
    query('p_QUIZCITY_Request_Management_InsertUpdate', {
      createdBy: loggedInUser.id,
      id: 0,
      studentId: loggedInUser.id,
      status: 'Waiting',
      requestCategoryId,
      notes: null,
      questionContent: questionContent.trim().replace(/\s+/g, ' '),
      createdDate: createdDate,
      responedDate: null,
      modifiedDate: null,
      modifiedBy: null,
    })
      .then((res) => {
        setGuideModalVisible(false);
        message.success('Your request has been sent.');
        handleSendEmail(requestCategoryName, questionContent, createdDate, res?.[0]?.id);
        sendNotification(requestCategoryName, questionContent, createdDate);
        form.resetFields();
      })
      .catch((err) => {
        message.error("Your request hasn't been sent.");
      })
      .finally(() => setLoading(false));
  };

  // Notification to sender
  const sendNotification = (category, question, createdDate) => {
    const formatDate = moment(createdDate).locale('en').format('DD/MM/yyyy H:mm A');
    query('p_VIMARU_Notification_InsertByStudent', {
      Title: `Your support request has been sent to admin at ${formatDate}`,
      Body: bodySupportRequestNoti({
        requestDate: formatDate,
        category: category,
        content: question,
      }),
      // ClassId : ,
      Avatar: loggedInUser?.avatarUrl || '',
      Name: loggedInUser?.fullName || '',
      // Icon : ,
      Color: colors.theme.button,
      CreatedBy: loggedInUser.id,
      Type: 1, //0: Common , 1: Support, 2: Booking, 3: Other
    })
      .then((res) => {
        //gọi lại useQuery ở src\components\Navbars\AdminNavbar.js
        queryClient.invalidateQueries('p_VIMARU_GET_notification_all_user');
      })
      .catch((err) => console.log('🚀 ~ err p_VIMARU_Notification_InsertByStudent:', err));
  };

  // sendMail to manager
  const handleSendEmail = (category, content, submittedDate, requestId) => {
    const mailInfor = {};

    // Gửi cùng lúc hai lời hứa từ hàm getMailer và getReceiverMail
    Promise.all([getAdminInfor()])
      .then(([receiverMailRes]) => {
        mailInfor.MAILER = ConfigSettings?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value;
        mailInfor.CC = ConfigSettings?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value;
        mailInfor.receivermail = receiverMailRes[0]?.email;

        // Kiểm tra nếu cả hai giá trị đã được cập nhật, tiếp tục xử lý gửi mail
        if (mailInfor.MAILER && mailInfor.receivermail) {
          const serial = moment(submittedDate).format('yyyyMMDDHHmmss');
          const timeRequest = moment(submittedDate).locale('en').format('DD/MM/yyyy H:mm A');
          const linkRequest = `${settings.DOMAIN_IS_USE}/lginnotek/admin/request-support-manager/${requestId}`;

          const dataSendMail = {
            to: 'admin.lg@ames.edu.vn',
            isSendFast: true,
            moduleId: MODULE_ID,
            cc: mailInfor.CC,
            bcc: '',
            subject: 'LG - send request',
            body: '',
            bodyHTML: '',
            attachment: '',
            templateName: 'LG_Mail_SupportRequest',
            sourceInfo: `API Save Email - ${'LG_Mail_SupportRequest'}`,
            templateData: JSON.stringify({
              serial: serial,
              submittedDate: timeRequest,
              category: category,
              content: content,
              requestLink: linkRequest,
              requestId: requestId,
              requestor: `${loggedInUser?.fullName} - ${loggedInUser?.email}`,
            }),
            mailer: mailInfor.MAILER,
          };
          amesSendMail('saveEmail', dataSendMail);
        }
      })
      .catch((error) => {
        console.error('Error fetching mail information:', error);
        // Xử lý lỗi nếu cần thiết
      });
  };

  return (
    <>
      <Affix style={{ position: 'fixed', bottom: 12, right: 12, zIndex: 9999 }}>
        <DynamicButton
          style={{ border: 'none', color: 'white' }}
          icon={<InfoCircleFilled />}
          type='primary'
          onClick={() => {
            setGuideModalVisible(true);
          }}
        >
          Supports
        </DynamicButton>
      </Affix>
      {/* Liên hệ hỗ trợ */}
      <Modal
        title='REQUEST SUPPORT'
        centered
        open={guideModalVisible}
        onCancel={() => {
          setGuideModalVisible(false);
        }}
        footer={[
          <DynamicButton
            onClick={() => {
              setGuideModalVisible(false);
              form.resetFields();
            }}
          >
            {i18next.t('Close')}
          </DynamicButton>,
          <DynamicButton
            loading={loading}
            type='primary'
            onClick={(value) => {
              form.submit(value);
            }}
          >
            {i18next.t('Send')}
          </DynamicButton>,
        ]}
        bodyStyle={{ padding: '24px 24px 0px 24px' }}
      >
        <div style={{ color: colors.font }}>
          <div>If you have any question about course, please contact us:</div>
          {settings.IS_LG ? (
            <div>
              <span>Email: </span>
              <i style={{ fontWeight: 500 }}>admin.lg@ames.edu.vn</i> or{' '}
              <i style={{ fontWeight: 500 }}>huyen1.pham@lginnotek.com</i>
            </div>
          ) : (
            <div>
              <span>Hotline: </span>
              <i style={{ fontWeight: 500 }}>1818 ****</i>
            </div>
          )}
        </div>
        {/* <div style={{ display: 'flex' }}>
          <span>Email: &nbsp;</span>
          <div>
            <i style={{ fontWeight: 500 }}>admin.lg@ames.edu.vn</i>
            <br />
            or <i style={{ fontWeight: 500 }}>huyen1.pham@lginnotek.com</i>
          </div>
        </div> */}
        <Form layout='vertical' form={form} onFinish={handleSendRequest}>
          <div style={{ position: 'relative', marginBottom: 10 }}>
            Or send request support:
            <Form.Item
              name='requestCategoryId'
              label='Category:'
              rules={[{ required: true, message: t('please choose', { field: t('table:Q&A.Category') }) }]}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder='Choose a support category'
                style={{
                  borderRadius: '0.25rem',
                }}
                options={formatCategoriesOption(categories)}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name='questionContent'
              label='Request content:'
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || value.trim() === '')
                      return Promise.reject(t('please enter', { field: t('table:setUpNotification.Content') }));
                    else return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.TextArea placeholder='Please enter your request content' />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default withAuthenticator(SendRequest);
