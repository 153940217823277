import colors from 'constants/colors';
import i18next from 'i18next';

export const courseColumns = {
  rowKey: 'id',
  displayColumns: [
    {
      title: i18next.t('Code'),
      key: 'code',
      fixed: 'left',
      width: '5%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
    },
    {
      title: i18next.t('Course name'),
      key: 'title',
      fixed: 'left',
      width: '30%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
    },
    {
      title: i18next.t('table:course.Description'),
      key: 'note',
      width: '30%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    // {
    //   title: 'Trạng thái',
    //   key: 'status',
    //   width: '10%',
    //   render: 'renderNoWrap',
    //   style: {
    //     fontWeight: 600,
    //     color: colors.font,
    //   },
    // },
    // {
    //   title: 'Ngày tạo',
    //   key: 'CreatedDate',
    //   width: '15%',
    //   sort: false,
    //   render: 'renderDateTime',
    //   prefix: <CalendarOutlined className='tw-mr-2' />,
    // },
  ],
};
