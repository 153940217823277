import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Tag, Space, Tooltip, Button, message, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { query } from 'helpers/QueryHelper';

export default function SelectCode({ record }) {
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const queryClient = useQueryClient();
  const coppyCode = () => {
    window.navigator.clipboard.writeText(record?.code);
    message.success({ content: 'Đã sao chép mã tham gia', duration: 1.5 });
    if (!record.userGetPTCode)
      query('p_QUIZCITY_PTTest_CodeExam_UpdateUserGetCode', {
        PTCode: record?.code,
        UserId: loggedInUser?.id,
      })
        .then((res) => {
          message.success({ content: 'Cập nhật trạng thái cấp mã thành công', duration: 1.5 });
          queryClient.invalidateQueries('p_QUIZCITY_PTTest_CodeExam_Dashboard');
        })
        .catch((err) => {
          console.error(err);
          message.error({ content: 'Cập nhật trạng thái cấp mã thất bại', duration: 1.5 });
        });
  };
  return (
    <Row justify={'space-between'} wrap={false}>
      <p style={{ marginBottom: 0, fontWeight: 600 }}>{record.code}</p>
      <Tooltip title='Nhấp chuột để sao chép mã lớp học'>
        <Button type='dashed' size='small' shape='circle' icon={<CopyOutlined />} onClick={() => coppyCode()} />
      </Tooltip>
    </Row>
  );
}
