import Results from 'modules/Presentation/Viewer/components/Result/Result';

const QuizDetailPreview = ({ data }) => {
  if (!data || !data[0]?.jsonData?.submittedQuestions) return null;

  const questions = data[0].jsonData.submittedQuestions.map((q) => q.question);
  let totalScore = 0;
  const submittedQuestions = data[0].userData.map((d) => {
    const question = questions.find((q) => q.id === d.questionId);
    const studentChoice = JSON.parse(d?.studentChoices ?? '');
    totalScore += studentChoice.score;
    const userData = JSON.parse(studentChoice.userData ?? '');
    return { question, userData };
  });

  return (
    <Results
      submittedQuestions={submittedQuestions}
      totalScore={totalScore}
      examination={{ questions }}
      percentPass={data[0].percentPass}
    />
  );
};

export default QuizDetailPreview;
