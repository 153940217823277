import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import settings from 'constants/settings';
import i18next from 'i18next';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTooltip from 'packages/DynamicTooltip/DynamicTooltip';
import { object } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  hasTooltip?: boolean;
  tooltipPlacement?: TooltipPlacement;
  onConfirm: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};
const DeletePopconfirm = ({ hasTooltip, tooltipPlacement, onConfirm }: Props) => {
  const { t } = useTranslation();
  return (
    <Popconfirm
      title={i18next.t('Are you sure to delete', { object: i18next.t('file') })}
      onConfirm={onConfirm}
      okText={t('Confirm')}
      cancelText={t('Close')}
    >
      {hasTooltip ? (
        <DynamicTooltip title={i18next.t('DeleteFiles')} placement={tooltipPlacement}>
          <DynamicButton key='delete-file' shape='circle' type='dashed' size='small' icon={<DeleteOutlined />} danger />
        </DynamicTooltip>
      ) : (
        <DynamicButton key='delete-file' shape='circle' type='dashed' size='small' icon={<DeleteOutlined />} danger />
      )}
    </Popconfirm>
  );
};

export default DeletePopconfirm;
