export function getWidthOfString(string, fontSize = '14px') {
  // Tạo một HTML element ẩn
  const hiddenElement = document.createElement('span');
  // Thiết lập font-size cho element
  hiddenElement.style.fontSize = fontSize;
  // Thiết lập nội dung của element bằng chuỗi cần tính độ dài
  hiddenElement.textContent = string;
  // Thêm element vào body để tính độ dài
  document.body.appendChild(hiddenElement);
  // Lấy độ dài (width) của element
  const width = hiddenElement.offsetWidth;
  // Xóa element khỏi body
  document.body.removeChild(hiddenElement);
  // Trả về độ dài
  return width;
}

// Tìm độ width lớn nhất trong mảng
// Áp dụng để responsive cho các Select antd
export function getMaxWidthOfStringArray(stringArray, fontSize = '14px') {
  let maxWidth = 0;
  stringArray.forEach(string => {
    const stringWidth = getWidthOfString(string, fontSize);
    if (stringWidth > maxWidth) maxWidth = stringWidth;
  });
  return maxWidth;
}