import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Collapse, Select } from 'antd';
import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import NoData from 'components/NoData';
import colors from 'constants/colors';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import React from 'react';
import { useQuery } from 'react-query';
import './style.css';

const formatCategoriesOption = (arr) => {
  if (arr?.length > 0) {
    const sort = _.sortBy(arr, ['categoryName']); // sắp xếp theo categoryName
    const options = sort.map((i) => ({
      label: i.categoryName,
      value: i.id,
    }));
    return [{ label: 'All', value: 'all' }, ...options];
  } else return [];
};
const filterFollowCategory = (alldata, category) => {
  if (alldata?.length > 0) {
    return alldata?.filter((i) => (i.requestCategoryId === category || category === 'all') && i.status === 'ACTIVE');
  }
  return [];
};

function LgUserManualQA() {
  const [displayCategory, setDisplayCategory] = React.useState('all');
  const { data: categories } = useQuery('p_QUIZCITY_Request_Category_Select', () =>
    query('p_QUIZCITY_Request_Category_Select'),
  );
  const { data: questionList, isLoading } = useQuery('p_QUIZCITY_QA_Management_Select', () =>
    query('p_QUIZCITY_QA_Management_Select'),
  );

  const displayArr = filterFollowCategory(questionList, displayCategory);

  const onChangDisplay = (category) => {
    setDisplayCategory(category);
  };

  return (
    <div>
      <Container name={'User manual'} parentName='Trang chủ'>
        <Card
          title={
            <div>
              <h3 className={`mb-0 tw-text-base ${settings.IS_LG ? 'title-color' : ''} `}>Q&A</h3>
              <div
                style={{
                  fontSize: '1rem',
                  fontStyle: 'italic',
                  textTransform: 'none',
                  color: '#000',
                  // marginTop: -5,
                  fontWeight: 400,
                }}
              >
                The Q&A section will be continuously updated according to common user problems.
              </div>
            </div>
          }
          // bodyStyles={{ height: '480px', overflowY: 'auto' }}
          extra={
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <div style={{ fontWeight: 500, marginBottom: 5 }}>Category: &ensp; </div>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                style={{
                  borderRadius: '0.25rem',
                  width: 150,
                }}
                value={displayCategory}
                options={formatCategoriesOption(categories)}
                onChange={onChangDisplay}
              />
            </div>
          }
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span style={{ width: '100%' }}>
              <img
                alt='picture cover'
                src={
                  settings.IS_LG
                    ? settings.DOMAIN_IS_USE + '/images/LG/LG_QnA.png'
                    : settings.IS_TVHH
                    ? require('assets/img/introduce/bg_thuyenvien_popup.png').default
                    : require('assets/img/ISE/bg_ies_intro_popup.jpeg').default
                }
                width={'100%'}
              />
            </span>
          </div>
          <br />
          <Collapse
            accordion
            style={{ background: 'white', paddingBottom: 20, border: 'none' }}
            expandIcon={({ isActive }) =>
              isActive ? (
                <DownOutlined style={{ color: colors.theme.button, marginTop: 8 }} />
              ) : (
                <RightOutlined style={{ color: colors.theme.button, marginTop: 8 }} />
              )
            }
          >
            {displayArr.length > 0 ? (
              displayArr.map((question) => (
                <Collapse.Panel
                  style={{
                    background: '#fafafa',
                    border: '1px solid #d9d9d9',
                    borderRadius: '0.25rem',
                    marginBottom: 10,
                  }}
                  header={
                    <div
                      style={{
                        color: colors.theme.button,
                        fontSize: 14,
                        fontWeight: '500',
                        textTransform: 'uppercase',
                      }}
                    >
                      {question.question}
                    </div>
                  }
                  key={question.id}
                >
                  <div dangerouslySetInnerHTML={{ __html: question.answer }} />
                </Collapse.Panel>
              ))
            ) : (
              <NoData />
            )}
          </Collapse>
        </Card>
      </Container>
    </div>
  );
}

export default LgUserManualQA;
