import NoData from 'components/NoData';
import settings from 'constants/settings';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import tableData from './tableDatav2';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import { paginationTable } from 'constants/Pagination';

export default function NoteTab({ record, classId }) {
  const [parameters, setParameters] = useState({
    pageSize: 10,
    pageNumber: 1,
  });
  const KEY = ['p_VIMARU_GetAnswerLogs_ByStudentId','Note', parameters];
  const { data, isLoading } = useQuery(KEY, () =>
    query('p_VIMARU_GetAnswerLogs_ByStudentId', {
      id: record?.id,
      classId,
      type: 'Note',
      pageNum: parameters.pageNumber,
      pageSize: parameters.pageSize,
    }),
  );
  const totalRows = data?.[0]?.totalRows ?? 0;

  return isLoading || (data && data.length) ? (
    <DynamicTable
      loading={isLoading}
      initialTableData={tableData}
      dataSource={data}
      pagination={paginationTable({
        pageSize: parameters.pageSize,
        pageNumber: parameters.pageNumber,
        totalRows: totalRows,
        onChange: (page, pageSize) => {
          setParameters({ pageNumber: page, pageSize: pageSize });
        },
      })}
    />
  ) : (
    !isLoading && <NoData description={i18next.t("slide:Essay.{{student}} who have not submitted their essays yet", { student: settings.studentPronouns2 })}/>
  );
}
