import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/types';

import { ames247Axios } from 'configs/api';

function* fetchAssignments(action) {
  try {
    yield put({ type: actionTypes.FETCH_ASSIGNMENT_REQUEST });
    let { studentId, classId, sessionId } = action;
    const response = yield ames247Axios.get(`/api/GetStudentAssignmentBySession/${studentId}/${classId}/${sessionId}`);

    response.data.results.sessionTitle = response.data.sessionTitle;
    const receivedAssignments = response.data.results;
    const params = {
      asrId: 13910,
      className: 'Tiếng Anh 2 Family and Friends National Edition',
      completedPercent: 0,
      courseNotes: 'No SS',
      description: 'Video bài giảng. ',
      formatControl: '{"icon": "menu","backgroundColor": "#0553A3", "color": "#FFFFFF","historyResultIcon": "#0553A3"}',
      groupPart: '[]',
      groupType: '',
      id: '3bce7b02-b6c6-4d1b-93cd-8d0fafce054e',
      imageUrl: null,
      questionTitle: 'Video',
      questionType: 'Video',
      questionTypeText: 'English Grammar',
      sessionTitle: 'Tiếng Anh 2 Family and Friends National Edition',
      sortOrder: 20,
      star: 0,
      status: 'ACTIVE',
      title: 'English Grammar',
    };
    const result = JSON.parse(JSON.stringify(receivedAssignments));
    result.push(params);

    yield put({
      type: actionTypes.FETCH_ASSIGNMENT_SUCCESS,
      payload: result.reverse(),
    });
  } catch (error) {
    // alert("error load data");
    yield put({
      type: actionTypes.FETCH_ASSIGNMENT_FAILURE,
      payload: error,
    });
  }
}

export default function* assignmentSaga() {
  yield takeLatest(actionTypes.FETCH_ASSIGNMENT, fetchAssignments);
}
