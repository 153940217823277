/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
import classnames from 'classnames';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import _ from 'lodash';
import { folderMenuClicked, folderMenuClickedReset, selectSemester } from 'modules/Sidebar/actions';
import { PropTypes } from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink as NavLinkRRD, useHistory, useLocation } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink, Navbar, NavbarBrand } from 'reactstrap';

const fetchFolders = (loggedInUser) => {
  return query('p_QUIZCITY_Get_FolderMenu', { studentId: loggedInUser.id });
};
function Sidebar({
  toggleSidenav,
  sidenavOpen,
  routes,
  rolesRouters,
  administrationRouters,
  logo,
  rtlActive,
  semesters,
}) {
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const allRoutes = routes;
  const [state, setState] = React.useState({});
  const location = useLocation();
  // const { data: semesters } = useQuery(['dbo.p_QUIZCITY_Get_FolderMenu', loggedInUser?.id], () =>
  //   fetchFolders(loggedInUser),
  // );
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedFolder = useSelector((state) => state.sidebarReducer.selectedFolder);

  React.useEffect(() => {
    if (semesters && semesters?.length !== 0) {
      if (!selectedFolder) dispatch(selectSemester(semesters[0]));
      if (history.location.pathname.includes(`/${settings?.PATHNAME}/home`))
        history.push(`/${settings?.PATHNAME}/home`);
    } else {
      if (history.location.pathname === `/${settings?.PATHNAME}/home`)
        history.push(`/${settings.PATHNAME}/home/no-data`);
    }
  }, [semesters]);

  React.useEffect(() => {
    setState(getCollapseStates(allRoutes));
    dispatch(folderMenuClickedReset());
    // eslint-disable-next-line
  }, []);
  //first route when clicking on collapse
  const handleCollapseClick = (st) => {
    const collapsingRoute = allRoutes.find((r) => r.state === Object.keys(st)[0]);
    if (collapsingRoute && collapsingRoute.views?.length > 0 && collapsingRoute.collapse) {
      const realRoutes = collapsingRoute.views?.filter((r) => !r.path.includes(':'));
      const firstRoute = realRoutes[0];
      history.push(`${firstRoute.layout}${firstRoute.path}`);
    }
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeItem) => {
    const routeName = routeItem.layout + routeItem.path;
    if (routeItem.semester) {
      return semesters?.indexOf(selectedFolder) === routeItem.semester - 1 &&
        history.location.pathname === `/${settings?.PATHNAME}/home`
        ? 'active'
        : '';
    }
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  };

  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routeItems) => {
    let initialState = {};
    routeItems?.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routeItems) => {
    for (let i = 0; i < routeItems?.length; i++) {
      if (routeItems?.[i]?.collapse && getCollapseInitialState(routeItems?.[i]?.views)) {
        return true;
      }

      if (location.pathname.indexOf(routeItems[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const handleClickNavLink = (e, routeItem) => {
    closeSidenav();
    if (routeItem?.semester) {
      dispatch(selectSemester(semesters[routeItem?.semester - 1]));
      dispatch(folderMenuClicked());
    }
  };

  const semestersNav = () => {
    if (!semesters) return;
    return semesters.map((semester, index) => (
      <NavItem key={`semester-${index}`}>
        <NavLink
          to={`/${settings?.PATHNAME}/home`}
          // href='#'
          // data-toggle='collapse'
          // aria-expanded={state[semester.state]}
          className={classnames({
            // active: getCollapseInitialState(semester.views),
          })}
          onClick={() => {
            // e.preventDefault();
            dispatch(selectSemester(semester));
            // setState(st);
          }}
        >
          <i class='fas fa-calendar-alt'></i>
          <span className='nav-link-text'>{semester.name}</span>
        </NavLink>
      </NavItem>
    ));
  };
  const createLinks = (routeItems) => {
    return routeItems?.map((routeItem, key) => {
      // Administration Route
      if (_.find(routeItem.roles, (role) => role === 'administrators')) {
        return null;
      }

      if (routeItem.redirect) {
        return null;
      }

      // Show in sidebar
      if (!routeItem.showInSidebar) {
        return null;
      }
      if (routeItem.href) {
        return (
          <NavItem key={key}>
            <NavLink href={routeItem.href} target='_blank'>
              {routeItem.icon !== undefined ? (
                <>
                  {routeItem.icon && typeof routeItem.icon === 'string' ? (
                    <i className={routeItem.icon} />
                  ) : (
                    routeItem.icon
                  )}

                  <span className='nav-link-text'>{routeItem.name}</span>
                </>
              ) : (
                routeItem.name
              )}
            </NavLink>
          </NavItem>
        );
      }
      if (routeItem.collapse) {
        const st = {};
        st[routeItem.state] = !state[routeItem.state];
        let { views } = routeItem;

        if (routeItem.type === 'classLevel') {
          const classLevelDefine = [
            {
              min: 1,
              max: 5,
            },
            {
              min: 6,
              max: 9,
            },
            {
              min: 10,
              max: 12,
            },
            {
              min: 6,
              max: 9,
            },
          ];
          const classLevel = loggedInUser.schoolTypeId;
          if (classLevel < 1) {
            // nếu cấp dạy chưa có
            // không hiện danh sách của sách điện tử theo lớp
            views = [];
          } else {
            views = views.slice(classLevelDefine[classLevel - 1].min - 1, classLevelDefine[classLevel - 1].max);
          }
        }
        return (
          <NavItem key={key}>
            <NavLink
              href='#'
              data-toggle='collapse'
              aria-expanded={state[routeItem.state]}
              className={classnames({
                active: getCollapseInitialState(routeItem.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
                handleCollapseClick(st);
              }}
            >
              {routeItem.icon ? (
                <>
                  {typeof routeItem.icon === 'string' ? <i className={routeItem.icon} /> : routeItem.icon}
                  <span className='nav-link-text'>{routeItem.name}</span>
                </>
              ) : routeItem.miniName ? (
                <>
                  <span className='sidenav-mini-icon'>{routeItem.miniName}</span>
                  <span className='sidenav-normal'>{routeItem.name}</span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={state[routeItem.state]}>
              <Nav className='nav-sm flex-column'>{createLinks(views)}</Nav>
            </Collapse>
          </NavItem>
        );
      }

      return (
        <NavItem className={activeRoute(routeItem)} key={key}>
          <NavLink
            to={routeItem.layout + routeItem.path}
            activeClassName=''
            onClick={(e) => handleClickNavLink(e, routeItem)}
            tag={NavLinkRRD}
          >
            {routeItem.icon !== undefined ? (
              <>
                {routeItem.icon && typeof routeItem.icon === 'string' ? (
                  <i className={routeItem.icon} />
                ) : (
                  routeItem.icon
                )}
                <span className='nav-link-text'>{routeItem.name}</span>
              </>
            ) : routeItem.miniName !== undefined ? (
              <>
                <span className='sidenav-mini-icon'>{routeItem.miniName}</span>
                <span className='sidenav-normal'>{routeItem.name}</span>
              </>
            ) : (
              routeItem.name
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    };
  }
  const scrollBarInner = (
    <div className='scrollbar-inner'>
      <div className='sidenav-header d-flex align-items-center'>
        {logo ? (
          settings.IS_ISE || settings.IS_TVHH ? (
            <NavbarBrand {...navbarBrandProps} style={{ flex: 1, padding: '12px 0px 12px 24px ' }}>
              <img alt={logo.imgAlt} className='navbar-brand-img' src={logo.imgSrc} style={{ maxHeight: '100%' }} />
            </NavbarBrand>
          ) : (
            <NavbarBrand {...navbarBrandProps} style={{ padding: '24px 12px 24px 36px ' }}>
              <img alt={logo.imgAlt} className='navbar-brand-img' src={logo.imgSrc} />
            </NavbarBrand>
          )
        ) : (
          <></>
        )}
        <div className='ml-auto'>
          <div
            className={classnames('sidenav-toggler d-none d-xl-block', { active: sidenavOpen })}
            onClick={toggleSidenav}
          >
            <div className='sidenav-toggler-inner'>
              <i className='sidenav-toggler-line' />
              <i className='sidenav-toggler-line' />
              <i className='sidenav-toggler-line' />
            </div>
          </div>
        </div>
      </div>
      <div className='navbar-inner'>
        <Collapse navbar isOpen>
          <Nav navbar>{createLinks(routes)}</Nav>
          {/* <Nav navbar>{window.location.hostname !== 'menard.quizcity.io' && semestersNav()}</Nav> */}
          {(loggedInUser?.role === 'administrator' || loggedInUser?.role === 'manager') &&
          administrationRouters?.length > 0 ? (
            <React.Fragment>
              <hr className='my-3' />
              <h6 className='navbar-heading p-0 text-muted'>
                <span className='docs-normal'>Administrators</span>
                <span className='docs-mini'>A</span>
              </h6>
              <Nav className='mb-md-3' navbar>
                {createLinks(administrationRouters)}

                {/* <NavItem>
              <NavLink href='https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/overview?ref=adpr-sidebar' target='_blank'>
                <i className='ni ni-spaceship' />
                <span className='nav-link-text'>Quản lý người dùng</span>
              </NavLink>
            </NavItem> */}
                {/* <NavItem>
              <NavLink href='https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/colors?ref=adpr-sidebar' target='_blank'>
                <i className='ni ni-palette' />
                <span className='nav-link-text'>Foundation</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/alert?ref=adpr-sidebar' target='_blank'>
                <i className='ni ni-ui-04' />
                <span className='nav-link-text'>Components</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/charts?ref=adpr-sidebar' target='_blank'>
                <i className='ni ni-chart-pie-35' />
                <span className='nav-link-text'>Plugins</span>
              </NavLink>
            </NavItem> */}
              </Nav>
              {rolesRouters.length > 0 && (
                <>
                  <hr className='my-3' />
                  <h6 className='navbar-heading p-0 text-muted'>
                    <span className='docs-normal'>Roles</span>
                    <span className='docs-mini'>A</span>
                  </h6>
                  <Nav className='mb-md-3' navbar>
                    {createLinks(rolesRouters)}
                  </Nav>
                </>
              )}
            </React.Fragment>
          ) : null}
        </Collapse>
      </div>
    </div>
  );
  return (
    <Navbar
      className={`sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ${rtlActive ? '' : 'fixed-left'}`}
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf('Win') > -1 ? <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar> : scrollBarInner}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  administrationRouters: PropTypes.arrayOf(PropTypes.object).isRequired,
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;
