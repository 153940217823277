/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Button } from 'reactstrap/lib';

type Props = {
  audioUrl: string;
};

export default function AudioButton({ audioUrl }: Props) {
  const refAudio = React.useRef<HTMLAudioElement>(null);
  const playAudio = React.useCallback(() => {
    refAudio?.current?.play();
  }, []);

  if (!audioUrl) return null;

  return (
    <div className='mb-4'>
      <audio ref={refAudio} src={audioUrl} />
      <Button
        color='warning'
        onClick={() => {
          playAudio();
        }}
      >
        <i className='fas fa-play' />
        &nbsp;&nbsp;Click to listen
      </Button>
    </div>
  );
}
