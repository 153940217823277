import withAuthenticator from 'HOCs/withAuthenticator';
import React from 'react';
import { LoggedInUserType } from 'types';

type Props = {
  loggedInUser?: LoggedInUserType;
  children?: JSX.Element | JSX.Element[];
};

const TeacherContainer = ({ loggedInUser, children }: Props) => {
  if (loggedInUser && (loggedInUser.role === 'teacher')) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return null;
};

TeacherContainer.defaultProps = {
  loggedInUser: null,
  children: null,
};

export default withAuthenticator(TeacherContainer);
