import { CalendarOutlined, PaperClipOutlined } from '@ant-design/icons';
import settings from 'constants/settings';
import i18next from 'i18next';

export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: i18next.t('table:reference.File name'),
      key: 'fileName',
      width: '100%',
      // sort: false,
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        cursor: 'pointer',
      },
      prefix: <PaperClipOutlined className='tw-mr-2' />,
    },
    {
      title: i18next.t('table:reference.Size'),
      key: 'fileSize',
      width: '1%',
      sort: false,
      render: 'renderNumber',
      formatString: '0.0 b',
      style: {
        minWidth: 84,
        fontWeight: '400',
      },
    },
    {
      title: i18next.t('table:reference.Type'),
      key: 'contentType',
      width: '1%',
      sort: false,
      render: (text) => {
        const textType = text?.split('/').length > 0 ? text?.split('/')[0] : text;
        const exportText = textType === 'application' && settings.IS_LG ? 'pdf' : textType;
        return <div style={{ minWidth: 50, fontWeight: '400' }}>{exportText}</div>;
      },
    },
    {
      title: i18next.t('table:reference.Created date'),
      key: 'createdDate',
      width: '1%',
      // sort: false,
      render: 'renderDate',
      style: {
        fontWeight: '400',
      },
      prefix: <CalendarOutlined className='tw-mr-2' />,
    },
  ],
};
