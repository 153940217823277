import { Button, Checkbox, Form, Input, Spin, notification } from 'antd';
import withAuthenticator from 'HOCs/withAuthenticator';
import React from 'react';
import ReactQuill from 'react-quill';
import addNotificationManagement from '../api/addNotificationManagement';
import editNotificationManagement from '../api/editNotificationManagement';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import settings from 'constants/settings';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// const validateMessages = {
//   number: {
//     range: '${label} must be between ${min} and ${max}',
//   },
//   required: '${label} is required!',
//   types: {
//     email: '${label} is not a valid email!',
//     number: '${label} is not a valid number!',
//   },
// };

const modulesQuill = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ align: [] }],
      ['bold', 'italic', 'underline'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        {
          color: [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            'custom-color',
          ],
        },
        { background: [] },
        'link',
      ],
    ],
    handlers: {
      color: function (value) {
        if (value == 'custom-color') value = window.prompt('Enter Hex Color Code');
        this.quill.format('color', value);
      },
    },
  },
};

const formatsQuill = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'align',
  'link',
  'image',
  'background',
  'color',
];
const validateContent = (rule, value, callback) => {
  if (value === '<p></p>' || value === '<p><br></p>' || !value) {
    callback(i18next.t('please enter', { field: i18next.t('contentsAnnoun') }));
  } else {
    callback();
  }
};

const AddContentNotification = ({ loggedInUser, cancelModalNotification, form, notificationId, data, statusModal }) => {
  const { t } = useTranslation();
  const [proccessing, setProccessing] = React.useState(false);
  const onFinishAddContentNotification = (values) => {
    setProccessing(true);
    const { content, notes, notificateCode, notificationName, status } = values;
    if (notificationId) {
      editNotificationManagement(content, notificationId, notes, notificateCode, notificationName, status)
        .then((response) => {
          notification.success({
            description: t('update success', { field: t('notification') }),
            message: t('notification'),
          });
          cancelModalNotification();
          setTimeout(() => setProccessing(false), 1500);
        })
        .catch((error) => {
          setTimeout(() => setProccessing(false), 1500);
          notification.error({
            description: t('update failed', { field: t('notification') }),
            message: t('notification'),
          });
        });
    } else {
      addNotificationManagement(content, loggedInUser.id, notes, notificateCode, notificationName, status)
        .then((response) => {
          notification.success({
            description: t('update success', { field: t('content') }),

            message: t('notification'),
          });
          setTimeout(() => setProccessing(false), 1500);
          cancelModalNotification();
        })
        .catch((error) => {
          notification.error({
            description: t('update failed', { field: t('content') }),

            message: t('notification'),
          });
          setTimeout(() => setProccessing(false), 1500);
        });
    }
  };

  return (
    <Form initialValues={{ status: true }} layout='vertical' form={form} onFinish={onFinishAddContentNotification}>
      <Form.Item
        name='notificationName'
        label={i18next.t('Title')}
        rules={[{ required: true, message: t('please enter', { field: t('Title') }) }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='notificateCode'
        label={i18next.t('Code')}
        rules={[
          {
            validator: (_, value) => {
              if (value.trim() === '') return Promise.reject(i18next.t('please enter', { field: i18next.t('code') }));
              else {
                const checkDuplicate = data?.some(
                  (item) =>
                    item?.notificateCode.trim().toLowerCase().replace(/\s+/g, ' ') ===
                      value.trim().toLowerCase().replace(/\s+/g, ' ') &&
                    (item?.id !== notificationId || statusModal === 'add'),
                );
                if (checkDuplicate) return Promise.reject(i18next.t('unavailable', { field: i18next.t('Code') }));
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='content'
        label={i18next.t('contentsAnnoun')}
        rules={[{ validator: validateContent, message: t('please enter', { field: t('contentsAnnoun') }) }]}
      >
        <ReactQuill style={{ marginBottom: 0 }} theme='snow' modules={modulesQuill} formats={formatsQuill} />
      </Form.Item>
      <Form.Item name='notes' label={i18next.t('Notes')}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name='status' valuePropName='checked'>
        <Checkbox>{i18next.t('Active')}</Checkbox>
      </Form.Item>

      <div style={{ alignItems: 'center', columnGap: 20, display: 'flex', justifyContent: 'end' }}>
        <DynamicButton htmlType='button' onClick={() => cancelModalNotification?.()}>
          {i18next.t('Close')}
        </DynamicButton>
        <DynamicButton style={{ border: 'none' }} type='primary' htmlType='submit' loading={proccessing}>
          {i18next.t('Save')}
        </DynamicButton>
      </div>
    </Form>
  );
};

export default withAuthenticator(AddContentNotification);
