import AdministratorContainer from 'components/Containers/AdministratorContainer';
import StudentContainer from 'components/Containers/StudentContainer';
import TeacherContainer from 'components/Containers/TeacherContainer';
import vimaruColors from 'constants/vimaru';
import { query } from 'helpers/QueryHelper';
import withAuthenticator from 'HOCs/withAuthenticator';
import React from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import CountUp from 'react-countup';
import './styles.css';
import CartReportHeader from './CartReportHeader';
import { useSelector } from 'react-redux';
import { RootState } from 'modules/rootReducer';
import colors from 'constants/colors';
import { useQuery } from 'react-query';
import settings from 'constants/settings';
import HeaderUserCard from './UserCard';

type Props = {
  name: string;
  parentName: string;
  loggedInUser: {
    id: number;
    role: string;
  };
  selectedFolder?: any;
};

type StatisticalTeacherProps = {
  completedPercentOfStudents: number;
  percentCompletedHomeWork: number;
  percentTotalClass: number;
  percentTotalStudent: number;
  totalClass: number;
  totalStudent: number;
};

type StatisticalStudentProps = {
  aVGScore: number;
  completedPercent: number;
  countExercisesWorked: number;
  countTotal: number;
  percentCompleted: number;
  percentCountTotal: number;
  percentExercisesWorked: number;
  percentScore: number;
  progress: string;
  totalCount: number;
  countCompletedPercent: number;
  qualityCompletedPercent: number;
  qualificationCompletedPercent: number;
};
type StatisticalAdminProps = {
  totalCourse: number; // Khoá học
  totalExam: number; // kì thi
  totalServey: number; // Khảo sát
  totalUser: number; // người dùng
  totalPresentation: number; // tổng số lượt học
  totalSlide: number; // tổng số lượt làm bài
  totalQuestion: number; // tổng số câu hỏi
  totalCertificate: number; // người đạt chứng chỉ
};

const fetchTeacher = (teacherId: any) => {
  return query('p_VIMARU_GetThongKeClass_ByTeacher', { teacherId });
};
const fetchStudent = (studentId: any, folderId: any) => {
  return query('p_VIMARU_GetThongKe_BaiTap', { studentId, folderId });
};
const fetchAdmin = (userId: any) => {
  return query('p_QUIZCITY_Report_ThongKe_Dashboard', { userId });
};

function MenardHeader({ loggedInUser, name, parentName, selectedFolder }: Props) {
  const KEY_TEACHER = ['p_VIMARU_GetThongKeClass_ByTeacher', loggedInUser?.id];
  const KEY_STUDENT = ['p_VIMARU_GetThongKe_BaiTap', loggedInUser?.id, selectedFolder?.id];
  const KEY_ADMIN = ['p_QUIZCITY_Report_ThongKe_Dashboard', loggedInUser?.id];
  const { data: statisticalTeacher } = useQuery(KEY_TEACHER, () => fetchTeacher(loggedInUser?.id), {
    enabled: loggedInUser?.role === 'teacher',
  });
  const { data: statisticalStudent } = useQuery(KEY_STUDENT, () => fetchStudent(loggedInUser?.id, selectedFolder?.id), {
    enabled: loggedInUser?.role === 'student' && !!selectedFolder?.id,
  });
  const { data: statisticalAdmin } = useQuery(KEY_ADMIN, () => fetchAdmin(loggedInUser?.id), {
    enabled: loggedInUser?.role === 'administrator' || loggedInUser.role === 'manager',
  });

  const isNotCompleted = statisticalStudent?.[0]?.progress?.toLowerCase() === 'chưa hoàn thành';
  // // const isNotCompleted = true;
  // React.useEffect(() => {
  //   if (loggedInUser.role === 'teacher') {
  //     query('p_VIMARU_GetThongKeClass_ByTeacher', { teacherId: loggedInUser.id }).then((response) => {
  //       setStatisticalTeacher(response?.[0]);
  //     });
  //   }
  //   if (loggedInUser.role === 'student') {
  //     if (selectedFolder?.id && selectedFolder?.id !== '00000000-0000-0000-0000-000000000000')
  //       query('p_VIMARU_GetThongKe_BaiTap', { studentId: loggedInUser.id, folderId: selectedFolder?.id }).then(
  //         (response) => {
  //           setStatisticalStudent(response?.[0]);
  //         },
  //       );
  //   }
  //   if (loggedInUser.role === 'administrator') {
  //     query('p_QUIZCITY_Report_ThongKe_Dashboard', { userId: loggedInUser.id }).then((response) => {
  //       setStatisticalAdmin(response?.[0]);
  //     });
  //   }
  //   return () => {
  //     setStatisticalTeacher(null);
  //     setStatisticalStudent(null);
  //   };
  // }, [loggedInUser, selectedFolder]);

  // Define Statistical section and its props type
  type StatisticalSectionProps = {
    value: unknown;
  };
  const StatisticalSection = ({ value }: StatisticalSectionProps) => {
    if (typeof value !== 'number') {
      value = 0;
    }
    const text: string = `text-${value ? (value > 0 ? 'success' : 'danger') : 'dark'} mr-2`;
    const icon: string = `fa fa-arrow-${value >= 0 ? 'up' : 'down'}`;
    return (
      <p className='mt-3 mb-0 text-sm'>
        <span className={text}>
          <i className={icon} />
          <span>{` ${value}%`}</span>
        </span>
        <span className='text-nowrap'>Tính từ đầu tháng</span>
      </p>
    );
  };

  return (
    <>
      <div
        className='header pb-6'
        style={{
          backgroundColor: colors.theme.background,
          borderTop: `1px solid ${colors.theme.background}`,
          zIndex: -9,
        }}
      >
        <Container fluid>
          <div className='header-body'>
            {/* <Row className='align-items-center py-4'>
              <Col lg='6' xs='7'>
                <h6 className='h2 text-white d-inline-block mb-0'>{name}</h6>
                <Breadcrumb
                  className='d-none d-md-inline-block ml-md-4'
                  listClassName='breadcrumb-links breadcrumb-dark'
                >
                  <BreadcrumbItem>
                    <a href='#pablo' onClick={(e) => e.preventDefault()}>
                      <i className='fas fa-home' />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href='#pablo' onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row> */}

            <StudentContainer>
              <Row xs={1} md={3}>
                <Col>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Tổng lượt làm bài
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-red text-white rounded-circle shadow'>
                            <i className='ni ni-active-40' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          // decimals={2}
                          style={{ color: '#F54A49' }}
                          start={0}
                          duration={5}
                          end={statisticalStudent?.[0]?.totalCount ?? 0}
                        ></CountUp>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            SỐ LƯỢNG HOÀN THÀNH
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-orange text-white rounded-circle shadow'>
                            <i className='ni ni-chart-pie-35' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          decimals={2}
                          style={{ color: '#FB8C40' }}
                          start={0}
                          duration={5}
                          end={statisticalStudent?.[0]?.countCompletedPercent ?? 0}
                          suffix='%'
                        ></CountUp>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            CHẤT LƯỢNG HOÀN THÀNH
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-green text-white rounded-circle shadow'>
                            <i className='ni ni-chart-bar-32' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          decimals={2}
                          style={{ color: '#2DCEAA' }}
                          start={0}
                          duration={5}
                          end={statisticalStudent?.[0]?.qualityCompletedPercent ?? 0}
                          suffix='%'
                        ></CountUp>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </StudentContainer>
            <TeacherContainer>
              <Row xs={1} sm={3}>
                <Col>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Tổng số lớp
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-red text-white rounded-circle shadow'>
                            <i className='ni ni-active-40' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          // decimals={2}
                          style={{}}
                          start={0}
                          duration={5}
                          end={statisticalTeacher?.[0]?.totalClass}
                        ></CountUp>
                      </Row>

                      {/* <StatisticalSection value={statisticalTeacher?.[0]?.percentTotalClass ?? 0} /> */}
                      {/* <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fa fa-arrow-up' />
                          <span>{` ${statisticalTeacher?.[0]?.percentTotalClass}%`}</span>
                        </span>
                        <span className='text-nowrap'>Tính từ đầu tháng</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            {`Tổng số ${settings.studentPronouns1}`}
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-orange text-white rounded-circle shadow'>
                            <i className='ni ni-chart-pie-35' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          // decimals={2}
                          style={{}}
                          start={0}
                          duration={5}
                          end={statisticalTeacher?.[0]?.totalStudent}
                        ></CountUp>
                      </Row>

                      {/* <StatisticalSection value={statisticalTeacher?.[0]?.percentTotalStudent ?? 0} /> */}
                      {/* <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fa fa-arrow-up' />
                          <span>{` ${statisticalTeacher?.[0]?.percentTotalStudent}%`}</span>
                        </span>
                        <span className='text-nowrap'>Tính từ đầu tháng</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Tỷ lệ hoàn thành bài tập
                          </CardTitle>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-primary text-white rounded-circle shadow'>
                            <i className='ni ni-chart-bar-32' />
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}>
                        <CountUp
                          useEasing={true}
                          className='dashboard-countup'
                          decimals={2}
                          style={{}}
                          start={0}
                          duration={5}
                          end={statisticalTeacher?.[0]?.completedPercentOfStudents ?? 0}
                          suffix='%'
                        />
                      </Row>

                      {/* <StatisticalSection value={statisticalTeacher?.[0]?.percentCompletedHomeWork ?? 0} /> */}
                      {/* <p className='mt-3 mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fa fa-arrow-up' />
                          <span>{` ${statisticalTeacher?.[0]?.percentCompletedHomeWork}%`}</span>
                        </span>
                        <span className='text-nowrap'>Tính từ đầu tháng</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TeacherContainer>
            {(loggedInUser.role === 'administrator' || loggedInUser.role === 'manager') && (
              <Row xs={1} sm={2} md={3} lg={4}>
                <Col>
                  <CartReportHeader
                    title='Khoá học'
                    value={{ value: statisticalAdmin?.[0]?.totalCourse, color: '#5e72e4' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-blue' }}
                  />
                </Col>
                <Col>
                  <CartReportHeader
                    title='Kì thi'
                    value={{ value: statisticalAdmin?.[0]?.totalExam, color: '#5603ad' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-indigo' }}
                  />
                </Col>
                <Col>
                  <CartReportHeader
                    title='Khảo sát'
                    value={{ value: statisticalAdmin?.[0]?.totalServey, color: '#8965e0' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-purple' }}
                  />
                </Col>
                <Col>
                  <Card className='card-stats'>
                    <CardBody className='pb-2'>
                      <Row>
                        <div
                          className='col'
                          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                        >
                          <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                            Người dùng
                          </CardTitle>
                          <CountUp
                            useEasing={true}
                            className='dashboard-countup'
                            // decimals={2}
                            style={{ color: '#f3a4b5' }}
                            start={0}
                            duration={5}
                            end={statisticalAdmin?.[0]?.totalUser}
                          ></CountUp>
                        </div>
                        <Col className='col-auto'>
                          <div className='icon icon-shape bg-gradient-pink text-white rounded-circle shadow'>
                            <i className='ni ni-chart-pie-35' />
                          </div>
                        </Col>
                      </Row>
                      {/* <Row style={{ display: 'flex', justifyContent: 'flex-start', paddingInline: '1rem' }}></Row> */}

                      <p className='mb-0 text-sm'>
                        <span className='text-success mr-2'>
                          <span style={{ fontWeight: 500, fontSize: 12 }}>
                            {statisticalAdmin?.[0]?.userActive} Active
                          </span>
                        </span>
                        <span className='text-danger mr-2'>
                          <span style={{ fontWeight: 500, fontSize: 12 }}>
                            {statisticalAdmin?.[0]?.userDeactive} Deactive
                          </span>
                        </span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <CartReportHeader
                    title='Lượt học'
                    value={{ value: statisticalAdmin?.[0]?.totalPresentation, color: '#f5365c' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-red' }}
                  />
                </Col>
                <Col>
                  <CartReportHeader
                    title='Lượt thi'
                    value={{ value: statisticalAdmin?.[0]?.totalSlide, color: '#fb6340' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-orange' }}
                  />
                </Col>
                <Col>
                  <CartReportHeader
                    title='Câu hỏi'
                    value={{ value: statisticalAdmin?.[0]?.totalQuestion, color: '#ffd600' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-yellow' }}
                  />
                </Col>
                <Col>
                  <CartReportHeader
                    title='Người đạt chứng chỉ'
                    value={{ value: statisticalAdmin?.[0]?.totalCertificate, color: '#2dce89' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-green' }}
                  />
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}

export default withAuthenticator(MenardHeader);
