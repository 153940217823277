/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sort-keys */
/* eslint-disable react/prop-types */
import { DeleteOutlined, EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Popconfirm, Space, Spin, Tooltip, message } from 'antd';
import settings from 'constants/settings';
import { generateSHA1 } from 'helpers/CryptoHelper';
import { amesSendMail, query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import moment from 'moment';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import IndexFormAddUser from 'pages/Administration/ManualRegister';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaKey } from 'react-icons/fa';
import { useQueryClient } from 'react-query';

const EditInfomation = ({ data, mailer, templateResetPass }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); //* Form Edit/Update User Information
  const [formUpdatePassword] = Form.useForm(); //* Form Update password
  const [loading, setLoading] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const queryClient = useQueryClient();
  const GenPassword = GenPasswordModel(2, 6);
  const [isModalOpenEditInformationUser, setIsModalOpenEditInformationUser] = useState(false);
  const [isModalOpenRemoveUser, setIsModalOpenRemoveUser] = useState(false);
  const [isModalUpdatePassword, setModalUpdatePassword] = useState(false);
  const [isLoadingDeleteUser, setLoadingDeleteUser] = useState(false);

  React.useEffect(() => {
    // ? Set values default cho form Edit/Update user information
    form.setFieldsValue({
      MaSv: data.maSV,
      firstName: data.fullName.split(' ').slice(0, -1).join(' '),
      lastName: data.fullName.split(' ').slice(-1).join(' '),
      email: data.email,
      userType: data.roleId,
      phone: data.phone,
      sex: data.sex ? 1 : 0,
      class: data.class,
      khoa: data.khoa,
      chuyen_nganh: data.nganh,
      birthday: moment(data.birthday),
      chucVu: data.chucVu,
      shop: data.shop,
    });
    // ? Set values "Email" Default; Gen password Default
    formUpdatePassword.setFieldsValue({
      email: data.email,
      password: GenPassword,
    });
  }, [data, isModalOpenRemoveUser, isModalOpenEditInformationUser, form]);

  const showModalEditInformationUser = () => {
    setIsModalOpenEditInformationUser(true);
  };

  const handleCancel = () => {
    setIsModalOpenEditInformationUser(false);
    setIsModalOpenRemoveUser(false);
    setModalUpdatePassword(false);
  };
  const useCallbackEditInformationUser = React.useCallback(
    (values, isClassId) => {
      //* Khi click button "Lưu"
      const { MaSv, email, firstName, lastName, userType, phone, sex, chuyen_nganh, khoa, birthday, shop, chucVu } =
        values;
      const parameters = {
        Id: data.id,
        MaSV: MaSv,
        Email: email,
        Name: `${firstName} ${lastName}`,
        Phone: phone,
        RoleId: userType,
        schoolTypeId: null,
        Sex: sex,
        Status: data.status,
        Avatar: data.avatar,
        BirthDay: birthday ? birthday : null,
        nganh: chuyen_nganh || '00000000-0000-0000-0000-000000000000',
        khoa: khoa || '00000000-0000-0000-0000-000000000000',
        classId: isClassId,
        shop,
        chucVu,
      };
      onSaveToDB(parameters);
    },
    [onSaveToDB],
  );

  const showModalUpdatePassword = () => {
    setModalUpdatePassword(true);
  };

  const handleFinishUpdatePassword = (values) => {
    setLoadingResetPassword(true);
    const { email, password } = values;
    const parameters = {
      Email: email,
      Password: generateSHA1(password),
    };
    onUpdatePassword(parameters, password);
  };
  // TODO: Save new Password to DB
  const onUpdatePassword = async (parameters, password) => {
    await query('p_VIMARU_UserPassword_RESET', parameters)
      .then((res) => {
        if (res?.[0].res === 1) {
          // *if success  password update => notification success password update to user => Send email new password information to users via
          message.success(i18next.t('updatePassEmailSuccess', { field: parameters.Email }));

          const dateNo = moment().format('YYYYMMDDhhmm');
          const dataSendMail = {
            to: parameters.Email.toLowerCase(),
            isSendFast: true,
            moduleId: settings.MODULE_ID,
            cc: '',
            bcc: '',
            subject: `Quên mật khẩu #${dateNo}`,
            body: '',
            bodyHTML: '',
            attachment: '',
            templateName: templateResetPass,
            sourceInfo: `API Save Email - ${templateResetPass}`,
            templateData: JSON.stringify({
              email: parameters.Email,
              password: password,
              dateNo: dateNo,
              studentName: data.fullName,
            }),
            mailer: mailer,
          };
          amesSendMail('saveEmail', dataSendMail).then((responseSendEmail) => {
              message.success(i18next.t('passSentEmail', { field: parameters.Email }));
          });
          // *Close Modal
          setModalUpdatePassword(false);
        } else {
          //! Error: Update password fail
          return message.warning(i18next.t('updatePassEmailFailed', { field: parameters.Email }));
        }
      })
      .catch(() => message.error(i18next.t('connectError'))); //! Error: connect Fail
    setLoadingResetPassword(false);
  };
  // TODO: Delete User
  const confirmDelUser = () => {
    setLoadingDeleteUser(true);
    query('p_VIMARU_User_DEL', {
      Id: data.id,
    })
      .then((res) => {
        if (res?.[0].res === 1) {
          //* Success Delete User
          message.success(i18next.t('delete success', { field: t('account') }));

          return queryClient.invalidateQueries('Users');
        }
        //! Error: Delete Fail
        message.warning(i18next.t('delete failed', { field: t('account') }));

        setLoadingDeleteUser(false);
      })
      .catch((err) => {
        //! Error: Connected Fail
        message.error(i18next.t('connectError'));
        setLoadingDeleteUser(false);
      });
  };
  // TODO: Save Information updated to DB
  const onSaveToDB = async (dataArr) => {
    setLoading(true);
    await query('p_VIMARU_Users_Edit', dataArr)
      .then((res) => {
        if (res?.[0]?.res === 1) {
          //* Update success
          setIsModalOpenEditInformationUser(false);
          setIsModalOpenRemoveUser(false);
          message.success(i18next.t('update success', { field: t('information') }));

          queryClient.invalidateQueries('Users');
        } else {
          //! Error: Updated fail
          message.error(i18next.t('alreadyExists2', { field: t('userCode'), field1: t('Email') }));
        }
      })
      .catch(() => {
        //! Error: Connected Fail
        message.error(i18next.t('connectError'));
      });
    setLoading(false);
  };
  const onUpdateStatusUser = () => {
    //* Update: status account: Active/DeActive
    const parameters = {
      Id: data.id,
      MaSV: data.maSV,
      Email: data.email,
      Name: data.fullName,
      Phone: data.phone,
      RoleId: data.roleId,
      Sex: data?.sex,
      Status: data.status === 'ACTIVE' ? 'DEACTIVE' : 'ACTIVE',
      Avatar: data.avatar,
      BirthDay: data?.BirthDay,
    };
    onSaveToDB(parameters);
  };
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Space>
          <Tooltip placement='top' title={i18next.t('updateObject', { object: i18next.t('information') })}>
            <Button
              key='edit-file'
              shape='circle'
              type='dashed'
              icon={<EditOutlined />}
              onClick={showModalEditInformationUser}
            />
          </Tooltip>
          <Popconfirm
            placement='topRight'
            // title={`Bạn có chắc chắn cập nhật trạng thái tài khoản: ${data.email}`}
            title={i18next.t('AreYouSureUpdateStatus', { field: data.email })}
            onConfirm={onUpdateStatusUser}
            okText={t('Confirm')}
            cancelText={t('Close')}
          >
            <Tooltip placement='top' title={i18next.t('updateObject', { object: i18next.t('Status') })}>
              {data.status === 'ACTIVE' ? (
                <Button key='edit-file' danger shape='circle' type='dashed' icon={<LockOutlined />} />
              ) : (
                <Button key='edit-file' shape='circle' type='dashed' icon={<UnlockOutlined />} />
              )}
            </Tooltip>
          </Popconfirm>
          <Tooltip placement='top' title={i18next.t('updateObject', { object: i18next.t('password') })}>
            <Button key='edit-file' shape='circle' type='dashed' icon={<FaKey />} onClick={showModalUpdatePassword} />
          </Tooltip>
          <Popconfirm
            placement='topRight'
            title={() => {
              return (
                <div>
                  <p style={{ fontSize: 14, fontWeight: 400 }}>
                    {i18next.t('Are you sure to delete', { object: t('account') })}
                    {data.email} ?
                  </p>
                  <strong style={{ color: '#f5222d' }}>({t('popconfirm.unrecoverable')})</strong>
                </div>
              );
            }}
            onConfirm={confirmDelUser}
            okButtonProps={{
              loading: isLoadingDeleteUser,
            }}
            okText={t('Confirm')}
            cancelText={t('Close')}
          >
            <Tooltip placement='top' title={i18next.t('DeleteAccount')}>
              <Button
                key='edit-file'
                shape='circle'
                type='dashed'
                danger
                style={{ color: '#f5222d' }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      </div>

      <Modal
        centered={true}
        title={i18next.t('updateObject', { object: i18next.t('information') })}
        open={isModalOpenEditInformationUser}
        footer={null}
        onCancel={handleCancel}
        width={'80%'}
      >
        <Spin spinning={loading}>
          <IndexFormAddUser
            buttonCloseModal={handleCancel}
            useCallbackEditInformationUser={useCallbackEditInformationUser}
            contentForm={form}
            type='update'
          />
        </Spin>
      </Modal>
      <Modal
        // title='CẬP NHẬT MẬT KHẨU NGƯỜI DÙNG'
        title={i18next.t('updateObject', { object: i18next.t('password') })}
        open={isModalUpdatePassword}
        footer={null}
        onCancel={handleCancel}
      >
        <Spin spinning={loadingResetPassword}>
          <Form onFinish={handleFinishUpdatePassword} form={formUpdatePassword} layout='vertical' autoComplete='off'>
            <Form.Item name='email' label='Email'>
              <Input disabled className='input__editInfomation' />
            </Form.Item>
            <Form.Item
              name='password'
              label='Mật khẩu'
              rules={[
                {
                  required: true,
                  message: i18next.t('please enter', { field: t('password') }),
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', columnGap: 20 }}>
              <DynamicButton key='back' onClick={handleCancel}>
                {i18next.t('Close')}
              </DynamicButton>
              <DynamicButton style={{ border: 'none' }} type='primary' htmlType='submit'>
                {i18next.t('Save')}
              </DynamicButton>
            </div>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
export default EditInfomation;
