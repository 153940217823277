/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { DownloadOutlined, EditOutlined, EyeOutlined, FolderOutlined } from '@ant-design/icons';
import withAuthenticator from 'HOCs/withAuthenticator';
import { Col, Form, Modal, Radio, Row, Space, TreeSelect, message } from 'antd';
import axios from 'axios';
import MoveButton from 'components/Button/MoveButton/MoveButton';
import UploadFiles from 'components/UploadFiles';
import colors from 'constants/colors';
import { cdnServerUrl } from 'constants/serverUrls';
import settings, { IS_BASAO, IS_LX, IS_TRONGDONG } from 'constants/settings';
import { deleteFile, execute, get, remove } from 'helpers/QueryHelper';
import fileDownload from 'js-file-download';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { LoggedInUserType } from 'types';
import { buildSelectTreeData } from 'utils';
import DeletePopconfirm from '../../components/Popconfirm/DeletePopconfirm/DeletePopconfirm';
import FolderTreeView from './components/FolderTreeView';
import tableData from './tableData';

import constants from 'constants/settings';
import { HiViewGrid, HiViewList } from 'react-icons/hi';
import FileUpdating from './components/FileProcessing/FileUpdating';
import FileView from './components/Layouts/FileView';
import FolderView from './components/Layouts/FolderView';

import i18next from 'i18next';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import Buttons from './components/Layouts/Buttons/Buttons';
import moment from 'moment';

const { IS_MENARD } = constants;

const getAttachments = (folderId: string) => (): Promise<any> => {
  if (!folderId) {
    return new Promise((resolve) => {
      resolve([]);
    });
  }

  const parameters = { entityId: folderId, entityName: 't_SACHSO_LIBRARIES_Folders' };
  const fields = [
    'Id',
    'EntityId',
    'EntityName',
    'Title',
    'FileName',
    'FileUrl',
    'FileSize',
    'ContentType',
    'CreatedDate',
    'ModuleId',
    'ImageUrl',
    'Description',
  ].join(',');
  const orderBy = ['CreatedDate DESC'].join(',');
  return get('Attachments', fields, parameters, orderBy, 'SHARE', 'SACHSO');
};

type Props = {
  loggedInUser?: LoggedInUserType;
  classId: string;
};

// Module quản lý thư viện, tài nguyên.

function Folders({ classId, loggedInUser }: Props) {
  const [reloadFolders, setReloadFolders] = React.useState(new Date()); // Biến này để làm mới lại cây thư mục sau khi có chỉnh sửa
  const [selectedFolderId, setSelectedFolderId] = React.useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);
  const [loadings, setLoadings] = React.useState<boolean>();
  const [viewMode, setViewMode] = React.useState<string>('card');
  const [refreshListFolders, setRefreshListFolders] = React.useState(moment().format('HHmmss'));

  const [selectedFolder, setSelectedFolder] = useState({});

  const queryClient = useQueryClient();
  const [childFolders, setChildFolders] = useState<any[]>([]);

  const KEY_SHARE_ATTACHMENTS = ['t_SACHSO_SHARE_Attachments', selectedFolderId];
  const { data, isLoading } = useQuery(KEY_SHARE_ATTACHMENTS, getAttachments(selectedFolderId));

  const KET_CLASSES_FOLDERS = ['t_SACHSO_LIBRARIES_Folders'];
  const { data: allFolders, isLoading: isLoadingGetAllFolders } = useQuery(KET_CLASSES_FOLDERS, () => {
    return get('Folders', '*', {}, 'Name, SortOrder', 'LIBRARIES', 'SACHSO');
  });

  const currrentFolders = !selectedFolderId ? allFolders?.filter((e: any) => e?.parentId === null) : childFolders;

  useEffect(() => {
    setChildFolders(allFolders?.filter((e: any) => e?.parentId && e?.parentId === selectedFolderId));
  }, [allFolders, selectedFolderId]);

  const [form] = Form.useForm();

  const [modalMoveFileVisible, setModalMoveFileVisible] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(null);

  const handleDowload = (url: string, fileName: string) => {
    setLoadings(true);
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, fileName);
        setLoadings(false);
      });
    setLoadings(false);
  };

  const expandedRowRender = ({ id, fileName, fileUrl, contentType }: any) => {
    const checkType = contentType.indexOf('application/vnd');
    return (
      <div style={{}} className='d-flex'>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-start', columnGap: 20, alignItems: 'center' }}>
          {checkType === -1 && (
            <>
              <a href={cdnServerUrl + fileUrl} target='_blank'>
                <DynamicButton icon={<EyeOutlined />}>{i18next.t('preview')}</DynamicButton>
              </a>
            </>
          )}
          {/* xem trước Reference */}
          {!(loggedInUser.role === 'student' && (IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX)) && (
            <DynamicButton
              key='download-file'
              loading={loadings}
              icon={<DownloadOutlined />}
              onClick={() => handleDowload(`${cdnServerUrl}${fileUrl}`, fileName)}
            >
              {i18next.t('Download')}
            </DynamicButton>
          )}
        </div>
        {(loggedInUser.role === 'administrator' ||
          loggedInUser.role === 'manager' ||
          (loggedInUser.role === 'teacher' && (IS_MENARD || settings.IS_LG || IS_BASAO || IS_TRONGDONG || IS_LX))) && (
          <div style={{ display: 'flex', flex: 0, justifyContent: 'flex-end' }}>
            <Space>
              <FileUpdating
                id={id}
                buttonIcon={<EditOutlined />}
                buttonType='dashed'
                buttonShape='circle'
                toolTipTitle={i18next.t('editfile')}
              />
              <MoveButton
                hasTooltip
                title={i18next.t('move folder')}
                placement='bottom'
                icon={<FolderOutlined />}
                onClick={() => {
                  setModalMoveFileVisible(true);
                }}
              />
              <DeletePopconfirm
                hasTooltip
                tooltipPlacement='bottom'
                onConfirm={async () => {
                  // DELETE
                  await remove('Attachments', id, 'SHARE');
                  // DELETE FILENAME IN FOLDERS
                  await deleteFile('Folders', selectedFolderId, fileName, 'CLASSES', 'SACHSO');
                  message.success(i18next.t('Delete success', { object: '' }));
                  queryClient.invalidateQueries(KEY_SHARE_ATTACHMENTS);
                }}
              />
            </Space>
          </div>
        )}
      </div>
    );
  };

  const SwitchViewMode = () => {
    return (
      <div className='tw-flex tw-justify-end tw-mb-5'>
        <Radio.Group
          defaultValue={viewMode}
          optionType='button'
          buttonStyle='solid'
          onChange={({ target: { value } }) => setViewMode(value)}
        >
          <Radio value='card'>
            <HiViewGrid />
          </Radio>
          <Radio value='table'>
            <HiViewList />
          </Radio>
        </Radio.Group>
      </div>
    );
  };

  return (
    <>
      {/* Thanh chỉnh chế độ view chỉ sử dụng cho Menard */}
      {(IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX) && (
        <Buttons
          allFolders={allFolders}
          setSelectedFolder={setSelectedFolder}
          selectedFolder={selectedFolder}
          setSelectedFolderId={setSelectedFolderId}
          viewMode={viewMode}
          setViewMode={setViewMode}
          selectedFolderId={selectedFolderId}
        />
      )}
      {/* Chế độ xem dạng card */}
      {(IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX) && viewMode === 'card' ? (
        data && data.length > 0 ? (
          <>
            {loggedInUser.role !== 'student' && (
              <div className='tw-flex tw-justify-end'>
                <UploadFiles
                  entityId={selectedFolderId}
                  entityName='Folders'
                  subModuleName='LIBRARIES'
                  moduleName='SACHSO'
                  tags='FILE'
                  onSuccess={() => {
                    queryClient.invalidateQueries(KEY_SHARE_ATTACHMENTS);
                  }}
                  buttonStyle={{
                    marginBottom: 24,
                  }}
                  buttonText={i18next.t('Upload')}
                  showQrCode={false}
                />
              </div>
            )}
            <FileView
              data={data}
              KEY_SHARE_ATTACHMENTS={KEY_SHARE_ATTACHMENTS}
              folders={allFolders}
              selectedFolderId={selectedFolderId}
              loggedInUser={loggedInUser}
            />
          </>
        ) : (
          <>
            <FolderView
              classId={classId}
              setSelectedFolderId={setSelectedFolderId}
              data={currrentFolders}
              setReloadFolders={setReloadFolders}
              viewMode={viewMode}
              setViewMode={setViewMode}
              allFolders={allFolders}
              selectedFolderId={selectedFolderId}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              KEY_SHARE_ATTACHMENTS={KEY_SHARE_ATTACHMENTS}
            />
          </>
        )
      ) : (
        // Chế độ xem mặc định dạng cây thư mục + table
        <>
          <div>
            <Row gutter={[12, 12]}>
              <Col xs={24} sm={24} md={6}>
                <div
                  style={{
                    border: 'dashed 1px',
                    borderColor: colors.border,
                    height: '100%',
                    padding: 12,
                  }}
                  className='rounded'
                >
                  {/* {allFolders?.length > 0 && ( */}
                  <FolderTreeView
                    classId={classId}
                    onSelect={(folder: any) => {
                      setSelectedFolderId(folder.id);
                    }}
                    allFolders={allFolders}
                    reference={data}
                    isLoading={isLoadingGetAllFolders}
                    setRefreshListFolders={setRefreshListFolders}
                  />
                  {/* )} */}
                </div>
              </Col>
              <Col xs={24} sm={24} md={18}>
                <div
                  style={{
                    border: 'dashed 1px',
                    borderColor: colors.border,
                    height: '100%',
                    padding: 12,
                    overflow: 'auto',
                  }}
                  className='rounded'
                >
                  {(loggedInUser.role === 'administrator' || loggedInUser.role === 'manager') && (
                    <UploadFiles
                      entityId={selectedFolderId}
                      entityName='Folders'
                      subModuleName='LIBRARIES'
                      moduleName='SACHSO'
                      tags='FILE'
                      onSuccess={() => {
                        queryClient.invalidateQueries(KEY_SHARE_ATTACHMENTS);
                      }}
                      showQrCode={false}
                      buttonStyle={{
                        marginBottom: 24,
                      }}
                    />
                  )}
                  {/* isLoading, data, tableData, expandedRowRender */}
                  <DynamicTable
                    loading={isLoading}
                    dataSource={data}
                    initialTableData={tableData}
                    expandable={{
                      columnWidth: '1%',
                      expandedRowKeys,
                      expandedRowRender,
                      expandIconColumnIndex: 999,
                      // expandIcon: ({ expanded, onExpand, record }) => (expanded ? <CaretUpOutlined onClick={(e) => onExpand(record, e)} /> : <CaretDownOutlined onClick={(e) => onExpand(record, e)} />),
                      expandRowByClick: true,
                      onExpand: (expanded: any, record: any) => {
                        const keys = [];
                        if (expanded) {
                          keys.push(record.id);
                        }
                        setExpandedRowKeys(keys);
                        setSelectedRecord(record);
                      },
                    }}
                    onReload={undefined}
                    pagination={false}
                  />
                </div>
              </Col>
            </Row>

            <Modal
              maskClosable={false}
              centered
              title={i18next.t('Move').toUpperCase()}
              visible={modalMoveFileVisible}
              onOk={() => {
                form.submit();
              }}
              onCancel={() => {
                setModalMoveFileVisible(false);
              }}
              okText={i18next.t('Confirm')}
              cancelText={i18next.t('Close')}
            >
              <Form
                form={form}
                layout='vertical'
                onFinish={async (values) => {
                  const parameters = {
                    ...values,
                    id: selectedRecord?.id,
                  };

                  await execute(
                    'p_SACHSO_SHARE_Attachments_UpdateEntityId',
                    parameters,
                    settings.APPLICATION_NAME,
                  ).then(() => {
                    message.success(i18next.t('update success', { field: '' }));
                    setModalMoveFileVisible(false);
                    setExpandedRowKeys([]);
                  });
                  queryClient.invalidateQueries(KEY_SHARE_ATTACHMENTS);
                }}
                requiredMark={false}
                initialValues={{ entityId: selectedRecord?.entityId }}
              >
                <Form.Item
                  label={i18next.t('Move to')}
                  name='entityId'
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(i18next.t('please choose', { field: i18next.t('Folder').toLowerCase() }));
                      },
                    },
                  ]}
                >
                  <TreeSelect
                    treeData={buildSelectTreeData(allFolders)}
                    allowClear
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: 10000 }}
                    treeDefaultExpandAll
                  />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </>
      )}
    </>
  );
}

Folders.defaultProps = {
  loggedInUser: undefined,
};

export default withAuthenticator(Folders);
