import { put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../actions/types";
import { ames247Axios } from "configs/api";

function* getResult(action) {
  const { studentId, sessionId, assignmentId, takeExamTime } = action;

  try {
    const data = yield ames247Axios.get(
      `/api/GetResultOfPlayedTime/${studentId}/${sessionId}/${assignmentId}/${takeExamTime}`
    );
    const results = data.data.results
   

    yield put({
      type: actionTypes.GET_RESULT_SUCCESS,
      results
    });

  } catch (error) {
    yield put({
      type: actionTypes.GET_RESULT_FAILURE,
      payload: { error },
    });
  }
}

export default function* resultSaga() {
  yield takeLatest(actionTypes.GET_RESULT, getResult);
}
