/* eslint-disable import/prefer-default-export */
import { add, query } from 'helpers/QueryHelper';
import _ from 'lodash';
import { SumStringToArray } from '../Result';
import moment from 'moment';

export const saveAnswerAcademy = async (
  file,
  score,
  data,
  studentId,
  studentData,
  assignmentId,
  slideId,
  folderId,
  handoutCode,
  codeId,
) => {
  const typesResult = {
    writeResult: '',
    oneChoiceResult: '',
    multipleChoiceResult: '',
    matchResult: '',
    multipleMatchResult: '',
    selectWordResult: '',
    dropDownResult: '',
    dragDropResult: '',
    speechRecognitionResult: '',
    record: null,
  };

  const { result, resultListen } = data;
  const isEmpty = JSON.stringify(result) === '{}';

  for (const temp in result) {
    typesResult[temp] = result[temp].resultString;
  }
  const resultData = isEmpty ? resultListen : result;
  //
  const resultArray = SumStringToArray(resultData, 'resultString');
  const [completed, totalCompleted] = SumStringToArray(resultData, 'complete');
  const newScore = isEmpty ? (completed * 10) / totalCompleted : score;
  const totalCompletedAnswer = (completed * 100) / totalCompleted;
  let dateNow = moment().format('MM-DD-YYYY hh:mm:ss');

  return query(
    'p_QUIZCITY_Academy_HandOut_Worksheet_Answer_Insert',
    {
      ...studentData,
      HandoutId: codeId,
      studentId,
      slideId,
      jsonData: JSON.stringify(data),
      type: 'WorkSheet',
      teacherNotes: '',
      notes: '',
      star: Number((newScore * 10).toFixed(1)) || 0,
      CompletedPercent: totalCompletedAnswer || 0,
      totalQuestion: totalCompleted,
      totalAnswer: completed,
      passedQuestion: parseInt(resultArray[0].toFixed()),
      createdBy: studentId,
      createdDate: dateNow,
      folderId,
      AttachmentId: file?.id ?? file?.attachmentId ?? file?.workSheetId,
      AssignmentId: assignmentId,
      HandoutCode: handoutCode,
      ...typesResult,
    },
    // 'CLASSWORK',
  );
};
