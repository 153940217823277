import withAuthenticator from 'HOCs/withAuthenticator';
import { Space, Spin } from 'antd';
import NoData from 'components/NoData';
import { get } from 'helpers/QueryHelper';
import i18next from 'i18next';
import SlideQuiz_v2 from 'modules/Presentation/Viewer/ViewerTypes/Quiz/SlideQuiz';
import ExcercisePage from 'modules/WorkSheet/CanvasToolPlus/ExcercisePage';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { LoggedInUserType } from 'types';
import { KeyLecture } from '../types';
import SlideNote from './ViewerTypes/SlideNote';
import SlideVideo from './ViewerTypes/Video/SlideVideo';
import SlideWord from './ViewerTypes/Word/SlideWord';
import { OUTERMOST_PADDING } from './configs/distances';

type ViewerProps = {
  loggedInUser?: LoggedInUserType;
  presentationId: string;
  presentationIndex?: number;
  getTitle?: Dispatch<SetStateAction<string>>;
  refWorkSheetSubmit?: any;
  getType?: Dispatch<SetStateAction<KeyLecture>>;
  setAudio?: any;
};

const Viewer = ({
  loggedInUser,
  presentationId,
  presentationIndex = 0,
  getTitle,
  refWorkSheetSubmit,
  getType,
  setAudio,
}: ViewerProps) => {
  const params = useParams<{ presentationId: string }>();
  const [slides, setSlides] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(presentationIndex);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    setLoading(true);
    get('Slides', '*', { presentationId: presentationId ?? params.presentationId }, 'SortOrder', '', 'SACHSO')
      .then((result) => {
        setSlides(result);
      })
      .finally(() => setLoading(false));
  }, [params.presentationId, presentationId, setAudio]);

  // Effect xử lý các dữ liệu callback từ bên ngoài.
  useEffect(() => {
    setSelectedIndex(presentationIndex);
    if (typeof getTitle === 'function') {
      getTitle(
        slides[selectedIndex]?.jsonData?.subTitle ??
          slides[selectedIndex]?.jsonData?.title ??
          slides[selectedIndex]?.jsonData?.[0]?.title,
      );
    }
    if (typeof getType === 'function') {
      getType(slides[selectedIndex]?.jsonData?.type);
    }
  }, [getTitle, getType, presentationIndex, selectedIndex, slides]);
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin size='large'></Spin>
      </div>
    );
  }
  if (!slides || slides?.length === 0) {
    return (
      <>
        <NoData description={i18next.t('lecture:Presentation.The lecture has no slides')} />
      </>
    );
  }
  return (
    <React.Fragment>
      {slides && (
        <Space direction='vertical' size={0} style={{ display: 'flex', height: 'inherit', padding: OUTERMOST_PADDING }}>
          <section
            style={{
              flex: 1,
              height: `calc(100vh - 200px - ${OUTERMOST_PADDING * 2}px`,
              overflow: 'auto',
              width: '100%',
            }}
          >
            {(slides[selectedIndex]?.jsonData?.type === 'Video' || slides[selectedIndex]?.type === 'Video') && (
              <SlideVideo videoUrl={slides[selectedIndex]?.jsonData?.videoUrl} />
            )}
            {slides[selectedIndex]?.jsonData?.type === 'WorkSheet' && (
              <React.Fragment key={`worksheet-${selectedIndex}`}>
                <ExcercisePage
                  file={slides[selectedIndex]?.jsonData ?? null}
                  idCurrent={slides[selectedIndex]?.jsonData?.workSheetId}
                  entityName='Folders'
                  subModuleName='CLASSES'
                  // ref={refSubmit}
                  // studentId={studentId}
                  // codeId={codeId}
                  // isSubmitted={isSubmitted}
                  isVisibleResult={false}
                  currentSlideId={slides[selectedIndex]?.id}
                  exerciseType='TEST'
                  isHiddenShowColor={true}
                  // resultExercise={result}
                  // handoutCode={codeExam}
                  // userAnswer={resultWorkSheet}
                  // folderIdHandoutPt={params?.folderId}
                  setAudio={setAudio}
                />
              </React.Fragment>
            )}
            {slides[selectedIndex]?.jsonData?.type === 'Word' && (
              <SlideWord content={slides[selectedIndex]?.jsonData?.word} />
            )}
            {slides[selectedIndex]?.jsonData?.type === 'Note' && (
              <SlideNote preview coverUrl={slides[selectedIndex]?.jsonData?.coverUrl} />
            )}
            {slides[selectedIndex]?.jsonData?.[0]?.type === 'Quiz' && (
              <SlideQuiz_v2
                data={slides[selectedIndex]?.jsonData?.[0]?.questions?.[0]?.quizData?.questions}
                loggedInUser={loggedInUser}
                currentQuizIndex={0}
                preview
                previewMode='LIST'
              />
            )}
          </section>
        </Space>
      )}
    </React.Fragment>
  );
};

Viewer.defaultProps = {
  loggedInUser: undefined,
  presentationIndex: 0,
  getTitle: undefined,
  refWorkSheetSubmit: {},
  getType: undefined,
};

export default withAuthenticator(Viewer);
