import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/types';
import openNotificationWithIcon from 'components/Notification';
import i18next from 'i18next';

function* postlogin(action) {
  try {
    if (action.result.ok) {
      const loggedInUser = {
        userTeacher: action.result.items[0],
        typeLogin: 'teacher',
      };
      localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
      yield put({
        type: actionTypes.POST_TEACHER_LOGIN_SUCCESS,
        payload: loggedInUser,
      });
      openNotificationWithIcon('success', i18next.t('Notification'), i18next.t('Logged in successfully'));
    } else {
      openNotificationWithIcon('danger', i18next.t('Notification'), i18next.t('An error occurred, please try again'));
      yield put({
        type: actionTypes.POST_TEACHER_LOGIN_FAILURE,
        payload: {
          message: i18next.t('auth:Log in failed'),
        },
      });
    }
  } catch (error) {
    openNotificationWithIcon('danger', i18next.t('Notification'), i18next.t('An error occurred, please try again'));
  }
}

function LOGOUT() {
  localStorage.clear();
}

export default function* teacherSaga() {
  yield takeLatest(actionTypes.POST_TEACHER_LOGIN_REQUEST, postlogin);
  yield takeLatest(actionTypes.POST_LOGOUT, LOGOUT);
}
