import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Popconfirm, Space, Tooltip, message } from 'antd';
import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import { query } from 'helpers/QueryHelper';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import moment from 'moment';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React from 'react';
import { useSelector } from 'react-redux';
import { courseColumns } from './tableColumn';
const { RangePicker } = DatePicker;
const IndexManageSchoolYear = () => {
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const [form] = Form.useForm();
  const [selectRow, setSelectRow] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [dataNienKhoa, setDataNienKhoa] = React.useState([]);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const [modalStatus, setModalStatus] = React.useState({
    open: false,
    title: '',
    type: 'add',
  });

  const CourseExtraColumns = [
    {
      key: 'editInfomation',
      render: (text, record) => {
        return (
          <Space>
            <Tooltip placement='top' title={'Cập nhật'}>
              <Button
                key='edit-file'
                shape='circle'
                type='dashed'
                style={{ color: '#006EE6' }}
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectRow(record);
                  handleShowModal('edit', record);
                }}
              />
            </Tooltip>
            <Popconfirm
              placement='topRight'
              title={() => {
                return (
                  <div>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>Bạn có chắc chắn muốn xóa không ?</p>
                    <strong style={{ color: '#f5222d' }}>(Sau khi xóa dữ liệu sẽ không thể khôi phục)</strong>
                  </div>
                );
              }}
              onConfirm={() => onDelete(record.id)}
              okText='Đồng ý'
              cancelText='Đóng'
            >
              <Tooltip placement='top' title={'Xóa'}>
                <Button
                  key='edit-file'
                  shape='circle'
                  type='dashed'
                  danger
                  style={{ color: '#f5222d' }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
      sort: false,
      title: 'Thao tác',
      width: '10%',
    },
  ];

  React.useEffect(() => {
    query('p_SACHSO_METADATA_NienKhoa_List')
      .then((res) => {
        setDataNienKhoa(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [refresh]);

  const handleShowModal = (type, record) => {
    if (type === 'edit') {
      record.giaiDoanI = [moment(record.giaiDoan1Start), moment(record.giaiDoan1End)];
      record.giaiDoanII = [moment(record.giaiDoan2Start), moment(record.giaiDoan2End)];
    }
    form.setFieldsValue(record);
    setModalStatus({
      open: true,
      title: type === 'add' ? 'THÊM NIÊN KHÓA' : 'CẬP NHẬT THÔNG TIN NIÊN KHÓA',
      type,
    });
  };

  const onInsert = (value) => {
    const { parentId, name, notes, sortOrder, giaiDoanI, giaiDoanII } = value;
    query('dbo.p_SACHSO_METADATA_NienKhoa_InsertUpdate', {
      code: '',
      createdBy: loggedInUser.id,
      id: '00000000-0000-0000-0000-000000000000',
      notes,
      parentId: parentId || '00000000-0000-0000-0000-000000000000',
      sortOrder: 1,
      name,
      giaiDoanI_FromDate: moment(giaiDoanI[0]._d).format(),
      giaiDoanI_ToDate: moment(giaiDoanI[1]._d).format(),
      giaiDoanII_FromDate: moment(giaiDoanII[0]._d).format(),
      giaiDoanII_ToDate: moment(giaiDoanII[1]._d).format(),
      isActive: 1,
      createdDate: new Date(),
      modifiedDate: new Date(),
      modeifiedBy: loggedInUser.id,
    })
      .then((res) => {
        message.success('Thêm mới dữ liệu thành công.');
        setRefresh(!refresh);
        setModalStatus({ open: false });
        form.resetFields();
      })
      .catch((error) => {
        message.error('Thêm mới dữ liệu thất bại.');
        console.log(error);
      })
      .finally(() => setIsProcessing(false));
  };

  const onUpdate = (value) => {
    const { id, createdDate, isActive, sortOrder, createdBy } = selectRow;
    const { parentId, name, notes, giaiDoanI, giaiDoanII } = value;
    query('dbo.p_SACHSO_METADATA_NienKhoa_InsertUpdate', {
      code: '',
      createdBy,
      id,
      notes,
      parentId: parentId || '00000000-0000-0000-0000-000000000000',
      sortOrder,
      name,
      giaiDoanI_FromDate: moment(giaiDoanI[0]._d).format(),
      giaiDoanI_ToDate: moment(giaiDoanI[1]._d).format(),
      giaiDoanII_FromDate: moment(giaiDoanII[0]._d).format(),
      giaiDoanII_ToDate: moment(giaiDoanII[1]._d).format(),
      isActive,
      createdDate,
      modifiedDate: new Date(),
      modeifiedBy: loggedInUser.id,
    })
      .then((res) => {
        setRefresh(!refresh);
        setModalStatus({ open: false });
        message.success('Cập nhật dữ liệu thành công.');
        form.resetFields();
      })
      .catch((error) => {
        message.error('Cập nhật dữ liệu thất bại.');
        console.log(error);
      })
      .finally(() => setIsProcessing(false));
  };

  const onDelete = (id) => {
    query('p_SACHSO_METADATA_NienKhoa_Delete', { id })
      .then((res) => {
        const { status, message: messageRes, data } = res[0];
        if (status) {
          message.success('Xóa dữ liệu thành công');
          setRefresh(!refresh);
        } else {
          Modal.warning({
            title: 'Không thể xóa',
            content: (
              <>
                {data && <p>{'Lớp học đang dùng: ' + data.map((item) => item?.className).toString() + '.'}</p>}

                <div>Niên khóa đang được dùng, vui lòng xóa khỏi lớp học</div>
              </>
            ),
          });
        }
      })
      .catch((error) => {
        message.error('Xoá dữ liệu thất bại.');
        console.log(error);
      });
  };

  const handleSubmit = (type, value) => {
    setIsProcessing(true);
    if (type === 'add') onInsert(value);
    else onUpdate(value);
  };

  return (
    <div>
      <Container name='Danh mục niên khóa' parentName='Administration'>
        <Card
          title='Danh mục niên khóa'
          extra={
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', columnGap: 10 }}>
              <Button
                onClick={() =>
                  handleShowModal('add', {
                    code: GenPasswordModel(1, 6),
                    notes: '',
                    title: '',
                  })
                }
              >
                <PlusOutlined /> Thêm niên khóa
              </Button>
            </div>
          }
        >
          <DynamicTable
            // loading={loading}
            dataSource={dataNienKhoa}
            initialTableData={courseColumns}
            extraColumns={CourseExtraColumns}
            className='components-table-demo-nested'
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '50', '100'],
            }}
            expandIconColumnIndex={5}
            expandIconAsCell={false}
          />
        </Card>
      </Container>

      <Modal
        title={modalStatus.title}
        okText='Lưu'
        cancelText='Đóng'
        onOk={() => {
          form.submit();
        }}
        open={modalStatus.open}
        onCancel={() => setModalStatus({ open: false })}
        footer={null}
      >
        <Form form={form} onFinish={(value) => handleSubmit(modalStatus.type, value)} layout='vertical'>
          {/* <Form.Item name='code' label='Mã niên khóa' rules={[{ required: true, message: 'Chưa nhập' }]}>
            <Input disabled />
          </Form.Item> */}
          <Form.Item
            name='name'
            label='Niên khóa'
            rules={[
              {
                validator: (rule, value) => {
                  if (!value || value.trim() === '') {
                    return Promise.reject('Chưa nhập niên khóa');
                  } else if (
                    dataNienKhoa.some(
                      (item) =>
                        item.name.trim() === value.trim() && (item.id !== selectRow?.id || modalStatus.type === 'add'),
                    )
                  ) {
                    return Promise.reject('Tên niên khóa đã tồn tại');
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='giaiDoanI'
            label='Giai đoạn 1'
            rules={[{ required: true, message: 'Chưa chọn thời gian cho giai đoạn 1' }]}
          >
            <RangePicker
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
          <Form.Item
            dependencies={['giaiDoanI']}
            name='giaiDoanII'
            label='Giai đoạn 2'
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject('Chưa chọn thời gian cho giai đoạn 2');
                  } else if (getFieldValue('giaiDoanI')[1]._d - value[0]._d >= 0) {
                    return Promise.reject(new Error('Ngày bắt đầu giai đoạn 2 phải lớn hơn ngày kết thúc giai đoạn 1'));
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <RangePicker
              format={'DD/MM/YYYY'}
              style={{ width: '100%' }}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>
          <Form.Item name='notes' label='Mô tả'>
            <Input />
          </Form.Item>
          {/* <Form.Item name='status' label='Trạng thái'>
            <Select>
              <Select.Option value="demo">ACTIVE</Select.Option>
              <Select.Option value="demo2">DEACTIVE</Select.Option>
            </Select>
          </Form.Item> */}
          {/* <Form.Item name='sortOrder' label='Thứ tự hiển thị' rules={[
            { required: true, message: 'Chưa nhập' },
          ]}>
            <InputNumber min={0} defaultValue={0} />
          </Form.Item> */}
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: 20 }}>
            <Button
              htmlType='button'
              onClick={() => {
                setModalStatus({ open: false });
                form.resetFields();
              }}
            >
              Đóng
            </Button>
            <Button type='primary' loading={isProcessing} htmlType='submit'>
              Lưu
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default IndexManageSchoolYear;
