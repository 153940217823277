import React, { useState } from 'react';
import { Row, Col, Button, Modal } from 'antd';
import Classes from 'modules/Teacher/components/Classes';
import CreateClass from 'modules/Teacher/components/CreateClass';
import Container from 'components/Containers/Container';
import Card from 'components/Containers/Card';
import AdministratorContainer from 'components/Containers/AdministratorContainer';
import TeacherContainer from 'components/Containers/TeacherContainer';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import ExcelInputScoreModal from './ExcelInputScoreModal';
import { IS_BASAO, IS_LG, IS_MENARD, IS_TRONGDONG } from 'constants/settings';
import i18next from 'i18next';

export default () => {
  const loggedInUser = useSelector((rootState) => rootState.authReducer.loggedInUser);
  const history = useHistory();
  const isAtEnteringScore = history.location.pathname.includes('enter-exam-score');
  const parentName =
    isAtEnteringScore && IS_LG
      ? 'Enter exam score'
      : !isAtEnteringScore && IS_LG
      ? 'Class management'
      : isAtEnteringScore && !IS_LG
      ? 'Nhập điểm thi'
      : 'Quản lý lớp học';
  const [openModal, setOpenModal] = useState(false);
  return (
    <Container name='' parentName={parentName}>
      <Row gutter={[24, 24]}>
        {(loggedInUser.role === 'teacher' || loggedInUser.role === 'manager') && (
          <>
            <Col lg={IS_MENARD || IS_LG || IS_BASAO || IS_TRONGDONG ? 18 : 24} xs={24}>
              <Card title={i18next.t('class:title.class list')}>
                <Classes />
              </Card>
            </Col>
            {(IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LG) && (
              <Col lg={6} xs={24}>
                <Card title={i18next.t('class:title.add class')}>
                  <CreateClass />
                </Card>
              </Col>
            )}
          </>
        )}

        <AdministratorContainer>
          <Col lg={isAtEnteringScore ? 24 : 18} xs={24}>
            <Card
              title={i18next.t('class:title.class list')}
              extra={
                isAtEnteringScore && (
                  <Button onClick={() => setOpenModal(true)}>
                    {/* {IS_LG ? 'Enter score by Excel' : 'Nhập điểm bằng Excel'} */}
                    {i18next.t('importScoreExcel')}
                  </Button>
                )
              }
            >
              <Classes />
            </Card>
          </Col>
          {!isAtEnteringScore && (
            <Col lg={6} xs={24}>
              <Card title={i18next.t('class:title.add class')}>
                <CreateClass />
              </Card>
            </Col>
          )}
        </AdministratorContainer>

        <ExcelInputScoreModal openModal={openModal} setOpenModal={setOpenModal} />
      </Row>
    </Container>
  );
};
