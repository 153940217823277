import i18next from 'i18next';
import {
  CoachingDateTag,
  FinishedCoachingDateTag,
  RenderBookingDateTags,
  statusRequest,
  statusValues,
} from 'pages/Administration/ManagerCoachingSchedule/tableColumn';

export const resultsBookingColumn = {
  rowKey: 'id',
  displayColumns: [
    {
      title: i18next.t('table:home.Booking'),
      key: 'booking',
      width: '30%',
      sort: false,
      render: (value, record) => <RenderBookingDateTags bookingDates={value} />,
    },
    {
      title: i18next.t('table:home.Status'),
      key: 'status',
      width: '1%',
      sort: false,
      render: (status, record) => {
        const statusDisplay = statusRequest.find((item) => item.status.toUpperCase() === status?.toUpperCase());
        return statusDisplay.tag;
      },
    },
  ],
};

export const allResultsBookingColumn = {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'Booking',
      key: 'booking',
      width: '30%',
      sort: false,
      render: (value, record) => <RenderBookingDateTags bookingDates={value} />,
    },
    {
      title: 'Status',
      key: 'status',
      width: '1%',
      sort: false,
      render: (status, record) => {
        const statusDisplay = statusRequest.find((item) => item.status.toUpperCase() === status?.toUpperCase());
        return statusDisplay.tag;
      },
    },
    {
      title: 'Informations',
      key: 'informations',
      width: '30%',
      sort: false,
      render: (value, record) => {
        const displayitem = (
          <>
            {record?.status === statusValues.accept && (
              <>
                <div>
                  <strong>{i18next.t('Appointment')}</strong>
                  :&nbsp;
                  <CoachingDateTag value={record?.commitedDateTeacher} />
                </div>
                <div>
                  <strong>Teacher name</strong>
                  :&nbsp;
                  {record?.teacherName}
                </div>
                <div>
                  <strong>Link meeting</strong>
                  :&nbsp;
                  <a href={record?.linkMeeting} alt='link meeting'>
                    {record?.linkMeeting}
                  </a>
                </div>
                <div>
                  <strong>Notes</strong>
                  :&nbsp;
                  {record?.notes}
                </div>
              </>
            )}
            {record?.status === statusValues.completed && (
              <>
                <div>
                  <strong>Coaching comment</strong>
                  :&nbsp;
                  <FinishedCoachingDateTag value={record?.finishedDate} />
                </div>
                <div>
                  <strong>Coaching comment</strong>
                  :&nbsp;
                  {record?.finishedCommentTeacher}
                </div>
              </>
            )}
            {record?.status === statusValues.cancel && (
              <div>
                <strong>Reason cancel</strong>
                :&nbsp;
                {record?.reasonCancel}
              </div>
            )}
          </>
        );
        return displayitem;
      },
    },
  ],
};
