import { Button, message, Spin, Tooltip, Modal } from 'antd';
import colors from 'constants/colors';
import _ from 'lodash';
import { fileToURL } from 'modules/WorkSheet/CanvasToolPlus/CanvasToolPage/api';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { modeName } from '../../configuration/constant';
import Icon from '../../ExercisePopup/components/Icon';
import { WorksheetTypesProps } from '../../type';

const Record = ({type, data, againAnswer, isDisableTest, isHiddenShowColor }:WorksheetTypesProps) => {
  const [result, setResult] = useState(null);
  const [state, setState] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const refUpLoad = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isCliclOpenModal, setClickCopenModal] = useState<number>(0);
  const [userAudio, setUserAudio] = useState<
    | boolean
    | {
        id: string;
        audioURL: string;
        fileName: string;
      }
  >();

  //
  useEffect(() => {
    Object.assign(type, {
      submit: () => {
        // handle submit
        if (state.length === 0) return {};
        const res = handleResult(state);
        // const res = handleResultByGroup(state);
        // const res = { complete: '2/2', percent: 100, resultString: '2.00/2', star: 5, userData: [] };
        // setResult(res);
        // setIsDone(true);
        return { record: res };
      },
      tryAgain: () => {
        setState(formatData(state));
        // setResult(null);
        // setIsDone(false);
      },
      setResult,
      setIsDone,
    });
    return () => {};
  }, [data, state, type]);

  useEffect(() => {
    setState(formatData(againAnswer?.userData));
  }, [data, againAnswer]);

  //data đang là mảng phẳng, cần format về dạng gom nhóm để render
  const formatData = (answers?: any[]) => {
    if (!data) {
      return [];
    }
    const floatArray = answers ? _.flatten(answers) : [];
    //Nếu có kết quả làm bài thì tìm input tương ứng để lấy đáp án
    const newData = answers
      ? data.map((item: any) => {
          const userAnswer = floatArray.find((i) => i.left === item.left && i.top === item.top);
          return userAnswer ?? item;
        })
      : _.cloneDeep(data);
    return newData;
  };
  //
  // update data
  async function handleChange(e: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    const file = e.target.files[0];
    setIsLoading(true);
    if (!file) {
      message.warning('Tải file không thành công!');
      setIsLoading(false);
      return null;
    }
    const params_id = '358d8538-628d-471e-8ed5-5c337f4aa8cb';
    const audio = await fileToURL(file, params_id);
    setUserAudio(audio);
    setState((pre) => {
      const arrayState = Array.from(pre);
      if (isCliclOpenModal <= state.length) {
        Object.assign(arrayState[selectedIndex], { userAnswer: audio });
      }

      return arrayState;
    });
    // setName({ text: audio.fileName, type: modeName.RECORD });
    if (audio === 0) {
      message.error('Vui lòng tải file mp3!');
    } else if (audio) {
      message.success('Tải file thành công.');
    } else {
      message.success('Tải file không thành công!');
    }

    setIsLoading(false);
  }
  const showModal = (text: string, index: number) => {
    setIsModalVisible(true);
    let numberClick = isCliclOpenModal;
    setSelectedIndex(index);
    if (index !== selectedIndex) setClickCopenModal((numberClick += 1));
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  if (!data) return null;

  return (
    <div>
      {state.map((item, index) => {
        const { width, height, top, left, text, groupName, angle, isCorrect } = item;
        let color = colors.font;
        if (isDisableTest) {
          if (isCorrect === true) {
            color = colors.theme.success;
          } else if (isCorrect === false) {
            color = colors.theme.danger;
          }
        }
        return (
          <div
            key={`input-${index}`}
            style={{
              backgroundColor: 'rgba(0, 128, 128, 0.1)',
              position: 'absolute',
              width,
              height,
              top,
              left,
              transform: `rotate(${angle}deg)`,
            }}
          >
            <Icon
              angle={angle}
              backgroundColor={'rgba(252, 175, 20, 1)'}
              height={24}
              icon={'fa fa-microphone'}
              iconColor={'white'}
              left={width - 12}
              top={-12}
              width={24}
            />
            {/* {renderResult({ angle, height, left, top, width }, index)} */}
            <Button
              type='primary'
              onClick={() => showModal(text, index)}
              style={{
                backgroundColor: 'rgba(0, 128, 128, 0.1)',
                color,
                fontSize: 16,
                height,
                width,
              }}
            >
              {''}
              &nbsp;
            </Button>
          </div>
        );
      })}
      <Modal
        centered
        title={'titleModal'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        getContainer={false}
      >
        <Spin spinning={isLoading} tip={'Đang tải file'}>
          <div style={{ display: 'flex', columnGap: 10, alignItems: 'center' }}>
            <Tooltip
              title={state[selectedIndex]?.userAnswer?.fileName ?? 'upload audio file'}
              getPopupContainer={(trigger) => {
                return trigger.parentElement;
              }}
            >
              <Button
                shape='circle'
                icon={
                  <i
                    className='fa fa-upload'
                    style={{ color: state[selectedIndex]?.userAnswer ? '#52C41A' : 'gray' }}
                  />
                }
                onClick={() => refUpLoad.current.click()}
              />
            </Tooltip>

            {state[selectedIndex]?.userAnswer?.audioURL && (
              <audio controls>
                <source src={state[selectedIndex]?.userAnswer?.audioURL} type='audio/mpeg' />
              </audio>
            )}
          </div>
          <input ref={refUpLoad} type='file' onChange={handleChange} style={{ display: 'none' }} />
        </Spin>
      </Modal>
    </div>
  );
};

export default Record;
const handleResult = (userData: any) => {
  const totalQuestion = userData.length;
  let count = 0;
  if (userData) {
    userData.forEach((item: any) => {
      if (item.userAnswer) {
        count += 1;
      }
    });
  }

  // count: Số câu hoàn thành
  // totalQuestion: Tổng số câu hỏi

  let complete = count + '/' + totalQuestion;
  let percent = (count * 100) / totalQuestion;
  let resultString = complete;
  let star = percent / 20;
  // complete: số câu hoàn thành / số câu hỏi
  // percent: Phần trăm làm bài
  //resultString: điểm

  // const totalQ = userData.length;
  // let count = 0;
  // let resultString = 0;
  // if (userData) {
  //   userData.forEach((item: any) => {
  //     let totalResultAnswer = 0;
  //     console.log(item);
  //     if (item.userAnswer) {
  //       count += 1;
  //       const userAnswer = item.userAnswer.pronunciationAssessment;
  //       const { accuracyScore, completenessScore, fluencyScore, pronunciationScore } = userAnswer;
  //       totalResultAnswer = (accuracyScore + completenessScore + fluencyScore + pronunciationScore) / 4;
  //       resultString += totalResultAnswer;
  //     }
  //   });
  // }
  // const percent = (count * 100) / totalQ;
  // const star = percent / 20;
  // const res = {  };

  // return { complete: `${count}/${totalQ}`, percent, resultString: `${(resultString / 100).toFixed(2)}/${totalQ}`, star, userData };
  return { complete, percent, resultString, star, userData };
};
