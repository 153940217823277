export const SettingDefault = (type, optionType) => {
  switch (optionType) {
    case 'LOOKSENTENCEANDIMAGE':
      return {
        setting: {
          hasListen: false,
          hasIsListen: false,
          hasImage: true,
          hasSuggest: true,
          hasSuggestButton: false,
          hasTextBlockQuote: false,
        },
      };
    case 'SAYTHEWORDSTEXT':
      return {
        setting: {
          hasListen: true,
          hasIsListen: true,
          hasImage: false,
          hasSuggest: false,
          hasSuggestButton: false,
          hasTextBlockQuote: true,
        },
      };
    case 'UNSCRAMBLEWORDS':
      return {
        setting: {
          question: {
            shuffedType: 'character',
          },
        },
      };
    case 'READMATCHSENTENCE':
      return {
        setting: {
          showTitleColumn: false, // Hiển thị cột A, B.
          isDragAColumnDisabled: true,
        },
      };
    case 'LISTENANDREPEAT':
      return {
        setting: {
          hasListen: false,
          hasIsListen: false,
          hasImage: false,
          hasSuggest: false,
          hasSuggestButton: false,
          hasTextBlockQuote: true,
        },
      };
    default:
      return {
        setting: {},
      };
  }
};
