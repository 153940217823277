import AudioUpload from 'modules/WorkSheet/WorkSheetPage/AudioUpload';
import FileUpload from 'modules/WorkSheet/WorkSheetPage/FileUpload';
import React, { useState } from 'react';
import { FaClosedCaptioning } from 'react-icons/fa';
import ButtonUpload from '../../Layout/ButtonUpload';
import AddInput from '../../Modules/AddInput';
import InputTitle from '../../Modules/FormHeader';

const OneChoose = ({ form, content, field_paren }) => {
  return (
    <>
      <ButtonUpload fieldC={field_paren} form={form} buttonText='Upload Image' />
      <InputTitle fieldC={field_paren} />
      <AddInput form={form} content={content} fieldC={field_paren} view={['checkBox', 'input']} />
    </>
  );
};

export default OneChoose;
