/* eslint-disable react/prop-types */
import moment from 'moment';
import React from 'react';
import { BiExitFullscreen, BiFullscreen } from 'react-icons/bi';
import { BsPauseFill, BsPlayFill } from 'react-icons/bs';
import ServerPlayer from 'react-player';
import ReactPlayer from 'react-player/lazy';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { RootState } from '../../../../../store';
import saveVideoLog from '../../../../api/saveVideoLog';
import './index.css';
type Ref = React.ForwardedRef<any>;

const SlideVideo = ({ percentPass, loggedInUser, videoUrl = '', coverUrl, setStatusButtonFooter }: any, ref: Ref) => {
  React.useImperativeHandle(ref, () => ({
    onNext: () => {
      handleNext();
    },
  }));

  // * Trạng thái bộ đếm
  const [counterStatus, setCounterStatus] = React.useState<'START' | 'STOP'>('STOP');

  // * Bộ đếm
  const [counterTimer, setCounterTimer] = React.useState(0);

  // * Tổng thời gian video
  const [durationVideo, setDurationVideo] = React.useState(0);

  // * Trạng thái video
  const [playing, setPlaying] = React.useState<boolean>(false);
  const [visibleControl, setVisibleControl] = React.useState<boolean>(true);
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const videoRef = React.useRef<any>();

  const url = `https://www.youtube.com/watch?v=${videoUrl.split('=')[1]}`;
  const isYouTube = !!videoUrl.split('=')[1];

  const params = useParams<{
    presentationId: string;
    slideId: string;
  }>();

  const playerConfig = {
    file: {
      attributes: {
        controlsList: 'nodownload', // Disable download option
      },
    },
  };

  //* Tỉ lệ phần trăm đã xem
  const getPercentWatched = (played: number) => {
    return (played / durationVideo) * 100;
  };

  const selectedFolder = useSelector((state: RootState) => state.sidebarReducer.selectedFolder);
  // * Bộ đếm thời gian xem video
  React.useEffect(() => {
    const id = setInterval(
      () => setCounterTimer((oldCount) => (counterStatus === 'START' ? oldCount + 1 : oldCount)),
      1000,
    );

    return () => {
      clearInterval(id);
    };
  }, [counterTimer, counterStatus]);

  React.useEffect(() => {
    if (setStatusButtonFooter) {
      setStatusButtonFooter((prev: any) => ({
        ...prev,
        disableNextPresentation: true,
      }));
      if (percentPass === 0 || getPercentWatched(counterTimer) >= percentPass) {
        setStatusButtonFooter((prev: any) => ({
          ...prev,
          disableNextPresentation: false,
        }));
      }
    }
  }, [getPercentWatched(counterTimer), percentPass]);

  React.useEffect(() => {
    const handleKeyDown = (event: any) => {
      // Intercept "ESC" key press when in fullscreen mode
      if (event.key === 'f') {
        isFullscreen ? exitFullscreen() : enterFullscreen();
      }
    };

    // Add event listener for "keydown"
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  React.useEffect(() => {
    const preventEscapeKey = (event) => {
      if (event.key === 'Escape' && isFullscreen) {
        event.preventDefault();
      }
    };
    // Listen for fullscreenchange event to detect if fullscreen is exited from other methods
    const handleFullscreenChange = () => {
      setIsFullscreen(!!(document.fullscreenElement || document.webkitFullscreenElement));
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);

    // Prevent Esc key from exiting fullscreen
    document.addEventListener('keydown', preventEscapeKey);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);

      // Cleanup event listener on unmount
      document.removeEventListener('keydown', preventEscapeKey);
    };
  }, [isFullscreen]);

  //* Format thời gian thành chuỗi
  const formatSecondTimer = (time: number) => {
    return moment.utc(time * 1000).format('mm:ss');
  };

  // * Parameters gửi kết quả

  //* Trả về kết quả phần trăm đã xem
  const percentDuration = () => {
    if (counterTimer < durationVideo) {
      return getPercentWatched(counterTimer);
    } else if (counterTimer === 0 && durationVideo === 0) {
      return 0;
    } else {
      return 100;
    }
  };

  //* Xử lí khi ấn bài tiếp
  const handleNext = () => {
    const parameters = {
      StudentId: loggedInUser?.id ?? 0,
      SlideId: params.slideId,
      PresentationId: params.presentationId,
      TotalDuration: formatSecondTimer(durationVideo),
      folderId: selectedFolder?.id,
    };
    setCounterStatus('STOP');
    setCounterTimer(0);
    let param = {
      ...parameters,
      IsCompleted: getPercentWatched(counterTimer) >= percentPass ? 1 : 0,
      ViewDuration: counterTimer < durationVideo ? formatSecondTimer(counterTimer) : formatSecondTimer(durationVideo),
      PercentDurarion: percentDuration(),
    };
    saveVideoLog(param);
  };
  const playIcon = () => {
    return (
      <div
        style={{
          borderRadius: '50%',
          border: '2px solid white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 5,
          paddingLeft: 8,
        }}
      >
        <BsPlayFill size={60} color={'white'} />
      </div>
    );
  };
  const LGPlayIcon = () => {
    return (
      <div
        style={{
          borderRadius: '50%',
          border: '2px solid white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 5,
          paddingLeft: playing ? 6 : 8,
        }}
      >
        {playing ? <BsPauseFill size={60} color={'white'} /> : <BsPlayFill size={60} color={'white'} />}
      </div>
    );
  };

  const handleDoubleClick = () => {
    const player = videoRef.current;
    if (player) {
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        // If already in fullscreen, exit fullscreen mode
        exitFullscreen();
      } else {
        // If not in fullscreen, enter fullscreen mode
        enterFullscreen();
      }
    }
  };

  const enterFullscreen = () => {
    const player = videoRef.current;
    if (player) {
      if (player.wrapper) {
        const wrapperElement = player.wrapper;
        if (wrapperElement.requestFullscreen) {
          wrapperElement.requestFullscreen();
        } else if (wrapperElement.webkitRequestFullscreen) {
          wrapperElement.webkitRequestFullscreen();
        }
        setIsFullscreen(true);
      }
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
    setIsFullscreen(false);
  };

  const customControl = () => {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          background: 'rgba(0, 0, 0, 0.2)',
          borderRadius: '5px',
          padding: '5px',
          height: '100%',
          width: '100%',
          zIndex: '99',
        }}
        onKeyDown={(e) => console.log('keydown')}
      >
        <div className='play-icon'>{LGPlayIcon()}</div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            isFullscreen ? exitFullscreen() : enterFullscreen();
          }}
          style={{ position: 'absolute', bottom: 7, right: 24 }}
        >
          {isFullscreen ? (
            <BiExitFullscreen style={{ color: 'white', cursor: 'pointer' }} size={25} />
          ) : (
            <BiFullscreen style={{ color: 'white', cursor: 'pointer' }} size={25} />
          )}
        </div>
      </div>
    );
  };

  return (
    <div style={{ height: '100%', width: '100%', position: 'relative' }}>
      {isYouTube && (
        <ReactPlayer
          url={url}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          controls
          width='100%'
          height='100%'
          onDuration={(duration) => {
            setDurationVideo(duration);
          }}
          onStart={() => {
            setCounterStatus('START');
          }}
          onPlay={() => {
            setCounterStatus('START');
          }}
          onPause={() => {
            setCounterStatus('STOP');
          }}
        />
      )}
      {
        !isYouTube && (
          // (!settings.IS_LG ?
          <ServerPlayer
            playing={playing}
            controls
            url={videoUrl}
            width='100%'
            height='100%'
            light={coverUrl}
            onClickPreview={() => setPlaying(true)}
            onEnded={() => setPlaying(false)}
            playIcon={playIcon()}
            onDuration={(duration) => {
              setDurationVideo(duration);
            }}
            onStart={() => {
              setCounterStatus('START');
            }}
            onPlay={() => {
              setCounterStatus('START');
            }}
            onPause={() => {
              setCounterStatus('STOP');
            }}
            config={playerConfig}
          />
        )
        //  : (
        //   <div
        //     style={{ height: '100%', width: '100%', position: 'relative', zIndex: 1000 }}
        //     onMouseEnter={() => {
        //       setVisibleControl(true);
        //     }}
        //     onMouseLeave={() => {
        //       setVisibleControl(false);
        //     }}
        //     onDoubleClick={handleDoubleClick}
        //     onMouseMove={() => setVisibleControl(true)}
        //     onClick={() => setPlaying((prev) => !prev)}
        //     onKeyDown={(e) => {
        //       if (e.key === 'f') {
        //         enterFullscreen();
        //       }
        //     }}
        //   >
        //     {/* <div style={{ height: '100%', width: '100%', background: 'yellow', zIndex: 999 }}> */}

        //     {visibleControl && customControl()}
        //     <ReactPlayer
        //       playing={playing}
        //       ref={videoRef}
        //       url={videoUrl}
        //       width='100%'
        //       height='100%'
        //       light={coverUrl}
        //       onClickPreview={() => setPlaying(true)}
        //       onEnded={() => setPlaying(false)}
        //       playIcon={playIcon()}
        //       onDuration={(duration) => {
        //         setDurationVideo(duration);
        //       }}
        //       onStart={() => {
        //         setCounterStatus('START');
        //       }}
        //       onPlay={() => {
        //         setCounterStatus('START');
        //       }}
        //       onPause={() => {
        //         setCounterStatus('STOP');
        //       }}
        //       config={playerConfig}
        //       fullscreen={true}
        //       wrapper={'player-wrapper'}
        //       style={{ zIndex: 1 }}
        //       // onReady={() => {
        //       //   setTimeout(() => setPlaying(true), 1000);
        //       // }}
        //       // style={{ zIndex: 1 }}
        //     ></ReactPlayer>
        //     {isFullscreen && ReactDOM.createPortal(customControl(), videoRef.current?.wrapper ?? document.body)}

        //     {/* </div> */}
        //   </div>
        // ))
      }
    </div>
  );
};
export default React.forwardRef(SlideVideo);
