import colors from 'constants/colors';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { CopyOutlined } from '@ant-design/icons';
import { Tag, message } from 'antd';
import moment from 'moment';
import i18next from 'i18next';
export const statusTag = [
  {
    key: 'Waiting',
    value: <Tag color='default'>{'Waiting'}</Tag>,
  },
  {
    key: 'Replied',
    value: <Tag color='success'>{'Replied'}</Tag>,
  },
];
export const supportColumns = {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'Date',
      key: 'createdDate',
      width: '5%',
      render: (text) => {
        return moment(text).format('DD/MM/YYYY');
      },
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Requestor',
      key: 'createdByName',
      width: '10%',
      sort: false,
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Category',
      key: 'requestCategoryName',
      width: '5%',
      sort: false,
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Question',
      key: 'questionContent',
      width: '20%',
      sort: false,
      render: 'renderText',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Status',
      key: 'status',
      width: '5%',
      sort: false,
      render: (status, record, index) => {
        return statusTag.find((i) => i.key === status)?.value;
      },
      style: {
        fontWeight: 600,
      },
    },

    {
      title: 'Response',
      key: 'notes',
      width: '20%',
      sort: false,
      render: 'renderText',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Reply date',
      key: 'responedDate',
      width: '20%',
      sort: false,
      render: (text, record) => {
        if (record.notes === '') {
          return null;
        }
        return moment(text).format('DD/MM/YYYY');
      },
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Replier',
      key: 'modifiedByName',
      width: '20%',
      sort: false,
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
  ],
};
