/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

// import { message } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import QuestionHeader from '../QuestionHeader';

// data.answers = data.answers.map((item, index) => {
//   return {
//     id: item.id,
//     textLeft: index + 1 + '. ' + item.textLeft,
//     textRight: index + 1 + '. ' + item.textRight,
//   };
// });

// const alphabet = 'abcdefghijklmnopqrstxyuvz';

const grid = 8;
const getItemStyle = (isDragging, draggableStyle, height) => {
  return {
    height,
    minWidth: 300,
    maxWidth: 300,
    color: 'black',
    userSelect: 'none',
    padding: 10,
    borderRadius: '0.25rem',
    margin: `0 0 ${grid}px 10px`,
    border: '1px dashed #adb5bd',
    background: isDragging ? '#b2b7c1' : '#f4f5f7',

    ...draggableStyle,
  };
};

function shuffle(array, data) {
  let currentIndex = array.length;
  let randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  // không cho item 1 đứng đầu tiên
  if (data.answers[0].textRight === array[0].textRight) {
    return shuffle(array, data);
  }

  return array;
}

function shuffleLeft(array, data) {
  let currentIndex = array.length;
  let randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  if (data.answers[0].textLeft === array[0].textLeft) {
    return shuffle(array, data);
  }

  return array;
}

const MatchingTwoColumn = ({ ExplainText, data, userData, questionIndex = 0 }) => {
  const [state, setState] = React.useState({
    itemsRight: null,
    itemsLeft: null,
    correctRight: null,
    correctLeft: null,
    boolAraayLeft: [],
    boolAraayRight: [],
  });

  const [maxHeight, setMaxHeight] = React.useState(70);

  const onDragEnd = React.useCallback((result) => {}, []);

  const onDragEndLeft = React.useCallback((result) => {}, []);
  // -------------------- Để trước hàm useEffect --------------------
  let showTitleColumn = data.settings && data.settings.showTitleColumn;
  if (showTitleColumn === null || showTitleColumn === undefined) {
    showTitleColumn = true;
  }

  // Cho phép cột A kéo thả hoặc không
  let isDragAColumnDisabled = data.settings && data.settings.isDragAColumnDisabled;
  if (isDragAColumnDisabled === null || isDragAColumnDisabled === undefined) {
    isDragAColumnDisabled = false;
  }

  // Cho phép cột B kéo thả hoặc không
  let isDragBColumnDisabled = data.settings && data.settings.isDragBColumnDisabled;
  if (isDragBColumnDisabled === null || isDragBColumnDisabled === undefined) {
    isDragBColumnDisabled = false;
  }
  // ----------------------------------------------------------------
  React.useEffect(() => {
    const arrayData = data.answers.map((item, index) => {
      return { textLeft: item.textLeft, textRight: item.textRight, id: index };
    });

    const itemsRight = shuffle(arrayData, data).map((item, index) => {
      return {
        id: `item-${index}`,
        content: item.textRight,
        match: item.id,
      };
    });

    const itemsLeft = isDragAColumnDisabled // Nếu kéo thả bị vô hiệu hóa (true) thì sắp xếp theo thứ tự, ngược lại xáo trộn
      ? data.answers.map((item, index) => {
          return {
            id: `item-${index}`,
            contentLeft: item.textLeft,
            match: index,
          };
        })
      : shuffleLeft(arrayData, data).map((item, index) => {
          return {
            id: `item-${index}`,
            contentLeft: item.textLeft,
            match: item.id,
          };
        });

    if (userData && userData.length !== 0 && userData?.left !== null && userData?.right !== null) {
      const { answers } = userData;
      const { answers: correctAnswers } = data;
      const correct = { left: [], right: [] };
      correctAnswers.forEach((element, index) => {
        correct.left.push({
          contentLeft: element.textLeft,
          id: `item-${index}`,
          match: index,
        });
        correct.right.push({
          content: element.textRight,
          id: `item-${index}`,
          match: index,
        });
      });
      setState((prevState) => ({
        ...prevState,
        itemsRight: answers.right,
        itemsLeft: answers.left,
        correctRight: correct.right,
        correctLeft: correct.left,
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      itemsRight,
      itemsLeft,
    }));
  }, [data, data.answers, isDragAColumnDisabled, userData]);

  React.useEffect(() => {
    const { answers } = data;
    const length = answers.map((item) => {
      const left = item.textLeft.length;
      const right = item.textRight.length;
      return left > right ? left : right;
    });
    const max = Math.max(...length);
    const height = max > 68 ? 89 : 70;
    setMaxHeight(height);
  }, [data]);

  if (!state.itemsRight) return null;

  const dragContent = (items) => {
    return (
      <div style={{ display: 'flex' }}>
        <DragDropContext onDragEnd={onDragEndLeft}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='d-flex flex-column justify-content-center '
              >
                {showTitleColumn && (
                  <div className='d-flex justify-content-start align-items-center '>
                    <span style={{ margin: '0 auto', fontSize: 24 }}>A</span>
                  </div>
                )}

                {items.itemsLeft.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={isDragAColumnDisabled && item.id === `item-${index}`}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, maxHeight)}
                      >
                        {/* {`${index + 1}. ${item.contentLeft}`} */}
                        {item.contentLeft}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='d-flex flex-column justify-content-center '
              >
                {showTitleColumn && (
                  <div className='d-flex justify-content-start align-items-center '>
                    <span style={{ margin: '0 auto', fontSize: 24 }}>B</span>
                  </div>
                )}

                {items.itemsRight.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={isDragBColumnDisabled && item.id === `item-${index}`}
                  >
                    {(provided, snapshot) => (
                      <div
                        className='drag-box center-mactching'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, maxHeight)}
                      >
                        {item.content}
                        {/* {`${alphabet[index].toUpperCase()}. ${item.content}`} */}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  return (
    <Card className='m-0'>
      <CardHeader>
        <QuestionHeader
          title={data.title}
          subTitle={data.subTitle}
          questionIndex={questionIndex}
          tooltip='MatchingTwoColumn'
        />
      </CardHeader>
      <CardBody className='d-flex justify-content-start align-items-center'>
        {dragContent({ itemsLeft: state.itemsLeft, itemsRight: state.itemsRight })}
      </CardBody>
      {ExplainText}
      <CardFooter>
        <h3>Đáp án đúng</h3>
        {dragContent({ itemsLeft: state.correctLeft, itemsRight: state.correctRight })}
      </CardFooter>
    </Card>
  );
};
MatchingTwoColumn.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.any,
  userData: PropTypes.any,
  questionIndex: PropTypes.number,
};

MatchingTwoColumn.defaultProps = {
  onSubmit: null,
  userData: null,
  questionIndex: 0,
};

export default MatchingTwoColumn;
