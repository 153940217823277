/* eslint-disable no-nested-ternary */
/* eslint-disable no-eval */
/* eslint-disable react/prop-types */
import React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import { Button, Checkbox, Modal, Progress, Rate, Tag } from 'antd';
import { renderForm_LMS_ChamDiemToeicSpeaking } from './LMS/renderFunctions';

function truncate(str, n) {
  if (str) return str.length > n ? `${str.substr(0, n - 1)} ...` : str;
  return '';
}

// --------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------
const renderAmesFunctions = {
  renderForm_LMS_ChamDiemToeicSpeaking,
};

export default renderAmesFunctions;
