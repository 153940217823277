import _ from 'lodash';

export function calculateResultMultipleMatch(
  userAnswers, // câu trả lời của học sinh
  arrayAnswers, // đáp án
  data // Dữ liệu của các ô nối
) {
  // Trường hợp không làm
  if (!userAnswers) {
    return {
      resultString: `0/${countColorsOfGroups}`,
      percent: 0,
      complete: `0/${countColorsOfGroups}`,
    };
  }

  const cloneUserAnswers = [...userAnswers];
  // Đếm tổng số câu (màu) trong group
  const countColorsOfGroup = arrayAnswers?.map((group) => Object.keys(group).length);
  // Đếm tổng số câu (màu) trong bài
  const countColorsOfGroups = _.sum(countColorsOfGroup);

  // lấy ra các line nối đúng cảu bài
  const getAnswerGroups = arrayAnswers?.map((group) => {
    return Object.values(group).map(color => color.lines);
  });
  const linesCorrectArray = _.flattenDeep(getAnswerGroups);
  // Nếu làm không đầy đủ, chèn undefined vào chỗ thiếu.
  if (cloneUserAnswers.length < linesCorrectArray.length)
    for (let index = 0; index < linesCorrectArray.length; index++) {
      if (!cloneUserAnswers[index]) cloneUserAnswers[index] = undefined;
    };

  //listBoolean biến hiển thị css đúng/sai
  const listBoolean = {};
  // Kiểm tra đúng sai.
  const booleanArray = cloneUserAnswers.map((line, index) => {
    if (linesCorrectArray.some(x => x === line)) {
      listBoolean[line] = true;
      return true;
    }
    else {
      if (line) listBoolean[line] = false;
      return false;
    }
  });

  const score = checkScoreByGroups(userAnswers, arrayAnswers);

  const percent = parseInt((score * 100) / countColorsOfGroups); // tính %
  const resultString = `${score}/${countColorsOfGroups}`; // điểm / tổng
  const star = percent;
  // Đếm theo số câu đúng sai.
  const complete = resultString;
  return { listBoolean, booleanArray, percent, resultString, star, complete };
}

export const checkSameGroup = (userSelect, data) => {
  if (userSelect.length < 2) return true; // Không đủ 1 cặp => Không xử lý.
  // userSelect.length===2
  const [groupFirst, groupSecond] = userSelect.map((item) => data[item].groupName);

  return groupFirst === groupSecond;
};

const checkScoreByGroups = (userAnswers, arrayAnswers) => {
  const getAnswerGroups = arrayAnswers.map((group) => Object.values(group));
  // lấy đáp án đúng theo từng nhóm màu - từng group [ [],[],[đáp án nối đúng theo từng  màu],... ]
  const linesCorrectArray = _.flatten(getAnswerGroups);
  const listScoreOfColor = linesCorrectArray.map((listLineCorrect) => {
    //lấy ra các đường line có chứa point của color
    const checkArr = userAnswers.filter(line => {
      // tách pointIndex từ line
      const points = line.split('-').map(point => Number(point));
      // nếu line chứa point của color thì lấy để check đúng sai cho color
      const commontPoint = _.intersection(points, listLineCorrect.points)
      if (commontPoint.length > 0) return true;
      else return false;
    })
    // nếu số line của user = số line của đáp án thì check, ngược lại thì sai (nối dư hoặc thiếu)
    if (checkArr.length === listLineCorrect.lines.length) {
      // Check số đáp án và câu trả lời có giống nhau không
      const commontLines = _.intersection(checkArr, listLineCorrect.lines)
      if (commontLines.length === listLineCorrect.lines.length) return 1;
      else return 0;
    } else return 0;

  });
  // số câu làm đúng theo màu - group
  const score = _.sum(listScoreOfColor);
  return score;
};
