import { Button, Modal, Result } from 'antd';
import React from 'react';
import { ModalProps } from '../type';
import DynamicButton from 'packages/DynamicButton/DynamicButton';

const ModalWarningClose = ({ isVisible, onCloseModal, onOk }: ModalProps) => {
  return (
    <Modal open={isVisible} closable={false} footer={null} centered={true}>
      <Result
        status='warning'
        title='Bạn có chắc chắn muốn dừng làm bài'
        subTitle=''
        extra={[
          <DynamicButton
            type='primary'
            onClick={() => {
              onOk();
            }}
            key='console'
          >
            Đồng ý
          </DynamicButton>,
          <DynamicButton
            type='primary'
            onClick={() => {
              onCloseModal();
            }}
            key='console'
          >
            Đóng
          </DynamicButton>,
        ]}
      />
    </Modal>
  );
};

export default ModalWarningClose;
