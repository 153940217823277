import { Progress, Rate } from 'antd';
import colors from 'constants/colors';
import { studentColumns } from 'constants/displayColumns';
import settings from 'constants/settings';
import i18next from 'i18next';

export default {
  rowKey: 'id',
  displayColumns: [
    {
      key: 'createdDate',
      render: 'renderDateTime',
      title: i18next.t('Date'),
      width: '10%',
    },
    {
      key: 'lessonTitle',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: 'Lesson',
      width: '10%',
    },

    {
      key: 'presentationTitle',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: 'Part',
      width: '10%',
    },
    {
      key: 'title',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('Title'),
      width: '10%',
    },
    {
      key: 'total',
      render: 'renderText',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('table:classInformation.Completed quantity'),
      width: '10%',
      dataType: 'math',
    },
    {
      key: 'completedPercent',
      dataType: 'number',
      render: (completedPercent, record, index) => {
        return <Progress percent={`${Number.parseFloat(completedPercent ?? 0).toFixed(0)}`} status='active' />;
      },
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('table:classInformation.Completed quality'),
      width: '10%',
    },
    {
      key: 'countPlayedTimes',
      render: 'renderText',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('table:classInformation.Number of times'),
      width: '10%',
      dataType: 'number',
    },
  ],
};
