import { Form, Input } from 'antd';
import _ from 'lodash';
import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import AudioButton from '../AudioButton';
import QuestionHeader from '../QuestionHeader';
import QuestionImage from '../QuestionImage';
import { QuestionComponentProps, QuestionComponentRef } from '../type';
import './styles.scss';

const FillInTheBlank = (
  {
    data,
    onSubmit,
    userData,
    questionIndex = 0,
    preview = false,
    showUserData = false,
    setAudio,
    onSubmitWhenCancel,
    isChildQuestion,
  }: QuestionComponentProps,
  ref: QuestionComponentRef,
) => {
  const [form] = Form.useForm();
  const [DATA, setDATA] = React.useState<any>(null);

  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
    onSubmitWhenCancel() {
      handleSubmitOnCanceling();
    },
  }));

  React.useEffect(() => {
    if (userData && showUserData) {
      form.setFieldsValue(userData.answers);
    }

    const answers = data.answers.map((item: any, index: any) => {
      const answer = item.answer.split('|');
      const id = index + 1;
      return { ...item, id, answer };
    });
    setDATA({ ...data, answers });
    if (preview) {
      const correctAnswer = _.mapValues(_.keyBy(data.answers, 'id'), 'answer');
      form.setFieldsValue(correctAnswer);
    }
  }, [data, form, userData, preview]);

  const renderQuestion = React.useCallback(() => {
    const text = DATA.questionText.split(/(___|\r\n)/g); // cắt bởi '___' hoặc '\r\n'

    const answers: any[] = Array.from(DATA.answers);
    const inputLength = Math.max(...answers.map((x) => x.answer.map((y: string) => y.length)).flat());
    return text.map((item: any, index: number) => {
      if (item === '\r\n') return <br key={index} />; // xuống dòng
      if (item === '___') {
        // input
        const answers_id = answers.shift().id;
        return (
          <div key={index} style={{ display: 'inline-block', height: 30 }}>
            <Form.Item name={`${answers_id}`}>
              <Input
                autoComplete='off'
                className='input-fill-in-the-blank'
                style={{ minWidth: 100, width: inputLength * 10, maxWidth: 400, fontSize: 20 }}
              />
            </Form.Item>
          </div>
        );
      }
      return <span key={index}>{item}</span>; // text
    });
  }, [DATA]);

  if (!DATA) return null;
  const handleAnswersNResult = () => {
    const userAnswer = form.getFieldsValue();
    const answers = Array.from(DATA.answers);
    // Điền đúng hết các ô trống thì mới result = true
    const result = answers.every((item: any) => {
      const { id, answer, isCorrect } = item;
      return userAnswer[id] === answer[0];
    });
    return {
      answers: userAnswer,
      result,
    };
  };
  const handleSubmitOnCanceling = () => {
    const { answers, result } = handleAnswersNResult();
    onSubmitWhenCancel?.({id: data.id, answers, result }, data);
  };
  const handleSubmit = () => {
    const { answers, result } = handleAnswersNResult();
    onSubmit?.({ id: data.id,answers, result }, data);
  };

  return (
    <Card className='fillintheblank-component'>
      {isChildQuestion ? null : (
        <CardHeader>
          <QuestionHeader title={DATA.title} subTitle={DATA.subTitle} questionIndex={questionIndex} />
        </CardHeader>
      )}

      <CardBody style={{ minHeight: 300 }}>
        <AudioButton audioUrl={DATA.audioUrl} setAudio={setAudio} preview={preview} />
        <QuestionImage imageUrl={DATA.imageUrl} />
        <Form form={form}>
          <div className='questionText'>{renderQuestion()}</div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default React.forwardRef(FillInTheBlank);
