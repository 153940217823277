// export const POST_LOGIN_REQUEST = 'POST_LOGIN_REQUEST';
// export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
// export const POST_LOGIN_FAILURE = 'POST_LOGIN_FAILURE';
// export const POST_LOGOUT = 'POST_LOGOUT';

// export const POST_MODAL_MODE = 'POST_MODAL_MODE';
// export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';

// export const UPDATE_USER_INFORMATION_REQUEST = 'UPDATE_USER_INFORMATION_REQUEST';
// export const UPDATE_USER_INFORMATION_SUCCESS = 'UPDATE_USER_INFORMATION_SUCCESS';
// export const UPDATE_USER_INFORMATION_FAILURE = 'UPDATE_USER_INFORMATION_FAILURE';

// export const POST_RESET_PASS = 'POST_RESET_PASS';
// export const POST_RESET_PASS_REQUEST = 'POST_RESET_PASS_REQUEST';
// export const POST_RESET_PASS_SUCCESS = 'POST_RESET_PASS_SUCCESS';
// export const POST_RESET_PASS_FAILURE = 'POST_RESET_PASS_FAILURE';

// export const POST_UPDATE_SUCCESS = 'POST_UPDATE_SUCCESS';

// export const POST_REGISTER_REQUEST = 'POST_REGISTER_REQUEST';
// export const POST_REGISTER_SUCCESS = 'POST_REGISTER_SUCCESS';
// export const POST_REGISTER_FAILURE = 'POST_REGISTER_FAILURE';

export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';

export const AUTH_SET_LOGGED_IN_USER = 'AUTH_SET_LOGGED_IN_USER';
export const AUTH_SET_LOGGED_IN_USER_SUCCESS = 'AUTH_SET_LOGGED_IN_USER_SUCCESS';
