/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
// import { query } from 'helpers/QueryHelper';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import DynamicForm from 'packages/DynamicForm/components/DynamicForm';
import getScoresByMonth from 'modules/Teacher/api/getScoresByMonth';
import tableData from './tableData';
import initialFormData from './formData';

function formatResult(result) {
  const students = _.map(_.uniqBy(result, 'studentId'), (x) => {
    return { studentId: x.studentId, studentName: x.studentName, month: x.month, year: x.year, classId: x.classId };
  });

  // console.log('🧑', students);
  _.forEach(students, (student) => {
    const recordsOfStudent = _.filter(result, (x) => {
      return x.studentId === student.studentId;
    });

    _.forEach(recordsOfStudent, (x) => {
      if (x.book) {
        if (x.book.includes('WB')) {
          student.wbScore = x.avgScore;
        } else if (x.book.includes('SB')) {
          student.sbScore = x.avgScore;
        }
      }
    });
  });

  return students;
}

export default ({ classId }) => {
  const [formData, setFormData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [records, setRecords] = React.useState([]);
  const [formParameters, setFormParameters] = React.useState({ classId, month: moment().format('M') });

  React.useEffect(() => {
    setFormData(initialFormData);
  }, []);

  React.useEffect(() => {
    let mounted = true;
    setLoading(true);

    getScoresByMonth({ month: formParameters.month, classId: classId }).then((result) => {
      if (mounted) {
        setRecords(formatResult(result));
        setLoading(false);
      }
    });
    // query('p_SACHSO_EBOOKS_GetScores_ByClassId', formParameters, 'Sgk').then((result) => {
    //   if (mounted) {
    //     setRecords(formatResult(result));
    //     setLoading(false);
    //   }
    // });

    return () => {
      mounted = false;
    };
  }, [classId, formParameters]);

  return (
    <React.Fragment>
      <div className='d-flex justify-content-start'>
        <DynamicForm
          loading={loading}
          initialFormData={formData}
          onSubmit={async (parameters) => {
            setFormParameters({ ...parameters, ...{ classId } });
          }}
        />
      </div>
      <DynamicTable
        loading={loading}
        dataSource={records}
        initialTableData={tableData}
        pagination={{ defaultPageSize: 10 }}
      />
    </React.Fragment>
  );
};
