import { Radio } from 'antd';
import { HiViewGrid, HiViewList } from 'react-icons/hi';

const SwitchViewModeButton = ({ viewMode, setViewMode }: { viewMode:any; setViewMode :Function}) => {
  return (
      <Radio.Group
        defaultValue={viewMode}
        optionType='button'
        buttonStyle='solid'
        onChange={({ target: { value } }) => setViewMode(value)}
      >
        <Radio value='card'>
          <HiViewGrid />
        </Radio>
        <Radio value='table'>
          <HiViewList />
        </Radio>
      </Radio.Group>
  );
};

const SwitchModeButton = ({
  value,
  setValue,
  options,
}: {
  value: any;
  setValue: Function;
  options: {
    label: any;
    value: any;
  }[];
}) => {
  return (
    <div className='tw-flex tw-justify-end tw-mb-5'>
      <Radio.Group value={value} optionType='button' buttonStyle='solid' onChange={(e) => setValue(e.target.value)}>
        {options.map((option, index) => (
          <Radio key={index} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export { SwitchModeButton, SwitchViewModeButton };

