import React from 'react';
import { Container } from 'reactstrap';

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  isFluid?: boolean;
};

export default ({ children, className = 'mt--6', isFluid = true }: Props) => {
  return (
    <React.Fragment>
      {isFluid ? (
        <Container className={className} fluid>
          {children}
        </Container>
      ) : (
        <div>{children}</div>
      )}
    </React.Fragment>
  );
};
