import * as actionTypes from '../actions/types';
const initialState = {
  isLoading: false,
  configs: null,
  error: null,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SETTINGS:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        configs: action.payload,
        error: null,
      };
    case actionTypes.FETCH_SETTINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        configs: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;
