import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';

const saveVideoLog = (_parameters = {}) => {
  const sqlCommand = 'p_SACHSO_SaveVideoLog';
  const parameters = _parameters;
  const applicationName = settings.APPLICATION_NAME;
  return query(sqlCommand, parameters, applicationName);
};

export default saveVideoLog;