import '../style.css';

type Props = {
  height: number;
  width: number;
  color: string;
};
const Crayon = ({ height, width, color }: Props) => {
  return (
    <div className='crayon'>
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 502.001 502.001'
        height={height}
        width={width}
      >
        <g>
          <polygon
            points='400.273,9.999 85.286,324.986 94.804,334.505 64.517,364.792 10,471.231 30.769,491.999 
 137.207,437.482 167.494,407.195 177.013,416.714 492,101.726 	'
            style={{ fill: `${color}` }}
          />
          <rect
            x='223.782'
            y='48.143'
            transform='matrix(-0.7071 -0.7071 0.7071 -0.7071 341.8795 568.3177)'
            width='129.721'
            height='330.42'
            style={{ fill: 'rgb(240, 239, 228)' }}
          />
        </g>
        <path
          d='M499.071,94.655L407.344,2.928c-3.906-3.904-10.236-3.904-14.143,0L78.214,317.915c-1.875,1.876-2.929,4.419-2.929,7.071
 s1.054,5.196,2.93,7.071l2.447,2.447l-23.217,23.217c-0.736,0.737-1.354,1.585-1.829,2.513L1.1,466.672
 c-1.979,3.862-1.239,8.561,1.829,11.63l20.769,20.769c1.922,1.921,4.48,2.929,7.074,2.929c1.55,0,3.111-0.359,4.556-1.1
 l106.438-54.517c0.928-0.476,1.775-1.093,2.513-1.829l23.217-23.217l2.447,2.447c1.875,1.876,4.419,2.93,7.071,2.93
 s5.195-1.054,7.071-2.929l314.987-314.987C502.977,104.893,502.977,98.56,499.071,94.655z M217.684,361.899L140.1,284.315
 L359.602,64.813l77.584,77.584L217.684,361.899z M94.804,348.648l58.547,58.547l-16.145,16.145L78.66,364.792L94.804,348.648z
 M32.691,479.779l-10.471-10.47l44.937-87.734l53.268,53.268L32.691,479.779z M99.428,324.986l26.529-26.529l77.585,77.585
 l-26.529,26.529L99.428,324.986z M451.329,128.255L373.744,50.67l26.529-26.529l77.585,77.585L451.329,128.255z'
        />
        <path
          d='M285.988,156.868L166.57,276.287c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929
 s5.118-0.977,7.071-2.929l119.419-119.419c3.905-3.905,3.905-10.237,0-14.143C296.225,152.964,289.895,152.964,285.988,156.868z'
        />
        <path
          d='M324.929,117.928l-12.384,12.384c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.952,4.512,2.929,7.071,2.929
 s5.118-0.977,7.071-2.929l12.384-12.384c3.905-3.905,3.905-10.237,0-14.143C335.165,114.023,328.835,114.023,324.929,117.928z'
        />
      </svg>
    </div>
  );
};

export default Crayon;
