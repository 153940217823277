import {
  DeleteOutlined,
  EditOutlined,
  FolderOpenFilled,
  FolderOutlined,
  MinusSquareOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { Button, Divider, Modal, Popconfirm, Skeleton, Space, Tooltip, Tree, message } from 'antd';
import AdministratorContainer from 'components/Containers/AdministratorContainer';
import { get, remove } from 'helpers/QueryHelper';
import withAuthenticator from 'HOCs/withAuthenticator';
import _ from 'lodash';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { buildSelectTreeData, buildTreeData } from 'utils';
import { useSelector } from 'react-redux';
import UpdateFolder from './components/UpdateFolder';
import settings from 'constants/settings';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
const { confirm, error } = Modal;

type Props = {
  classId: string;
  onSelect: Function;
  allFolders: Array<any>;
  isLoading: boolean;
  reference: Array<any>;
  setRefreshListFolders: Function;
};

// Module quản lý thư viện, tài nguyên.

const FolderTreeView = ({ classId, onSelect, allFolders, isLoading, reference, setRefreshListFolders }: Props) => {
  const { t } = useTranslation('');
  const queryClient = useQueryClient();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [updateModalVisible, setUpdateModalVisible] = React.useState(false);
  const [selectedFolder, setSelectedFolder] = React.useState<any>(null);

  const [updateMode, setUpdateMode] = React.useState<string>('');

  // const [expandedKeys, setExpandedKeys] = useState(allFolders?.map((i) => i?.id)); mắc định mở hết các node khi vào giao diện
  // const [expandedStatus, setExpandedStatus] = useState('collapseAll');
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [expandedStatus, setExpandedStatus] = useState('expandAll');

  const KET_CLASSES_FOLDERS = 't_SACHSO_LIBRARIES_Folders';

  const treeFolders = buildTreeData(allFolders);

  // ColapseAll or ExpandAll tree folder
  const handleExpandOrCollapseAll = (type) => {
    if (type === 'expandAll') {
      setExpandedStatus('collapseAll');
      const keys = [];
      const traverse = (data) => {
        data.forEach((item) => {
          keys.push(item.key);
          if (item.children) {
            traverse(item.children);
          }
        });
      };
      traverse(treeFolders);
      setExpandedKeys(keys);
    } else if (type === 'collapseAll') {
      setExpandedStatus('expandAll');
      setExpandedKeys([]);
    }
  };
  //when select folder
  const handleExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };
  const onClickDeleteFolderBtn = (selectedFolder: object, listFolders: Array<any>, listPresentations: Array<any>) => {
    // không cho xoá các folder có chưa dữ liệu (folder con hoặc bài giảng)
    const checkDeleteFolder = !(
      listPresentations.length > 0 || listFolders.some((folder) => folder.parentId === selectedFolder.id)
    );
    if (checkDeleteFolder) {
      confirm({
        title: i18next.t("lecture:TreeFolders.Are you sure to delete the '{{name}}' folder?", {
          name: selectedFolder.name,
        }),
        content: i18next.t('popconfirm.unrecoverable'),
        okText: i18next.t('Confirm'),
        okType: 'danger',
        cancelText: i18next.t('Cancel'),
        onOk() {
          remove('Folders', selectedFolder.id, 'LIBRARIES').then((res) => {
            queryClient.invalidateQueries(KET_CLASSES_FOLDERS);
            message.success(
              i18next.t("lecture:TreeFolders.Successfully deleted folder '{{name}}'", { name: selectedFolder.name }),
            );
          });
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    } else
      error({
        title: i18next.t("lecture:TreeFolders.Can not delete folder '{{name}}'", { name: selectedFolder.name }),
        content: i18next.t(
          'lecture:TreeFolders.Only folders that do not contain any subfolders and lectures can be deleted !',
        ),
        // // okButtonProps: { className: settings.IS_LG ? 'modal-btn' : '' },
      });
  };
  return (
    <React.Fragment>
      <Skeleton active loading={isLoading}>
        {(loggedInUser.role === 'administrator' || loggedInUser.role === 'manager') && (
          <>
            <UpdateFolder
              updateMode={updateMode}
              treeData={buildSelectTreeData(allFolders)}
              data={{ classId, ...selectedFolder }}
              visible={updateModalVisible}
              onOk={() => {
                queryClient.invalidateQueries(KET_CLASSES_FOLDERS);
                setUpdateModalVisible(false);
              }}
              onCancel={() => {
                setUpdateModalVisible(false);
              }}
            />

            <Space size='small' wrap>
              <Tooltip title={i18next.t('Add folder')} placement='bottom'>
                <Button
                  shape='circle'
                  type='dashed'
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setUpdateMode('add');
                    setUpdateModalVisible(true);
                  }}
                />
              </Tooltip>
              {selectedFolder && (
                <React.Fragment>
                  <Tooltip title={i18next.t('Edit folder')} placement='bottom'>
                    <Button
                      shape='circle'
                      type='dashed'
                      icon={<EditOutlined />}
                      style={{ alignSelf: 'flex-end' }}
                      onClick={() => {
                        setUpdateMode('edit');
                        setUpdateModalVisible(true);
                      }}
                    />
                  </Tooltip>
                  {/* <Popconfirm
                    title={i18next.t('Are you sure to delete', { object: i18next.t('Folder') })}
                    onConfirm={async () => {
                      await remove('Folders', selectedFolder.id, 'LIBRARIES');
                      message.success(i18next.t('Delete success', { object: i18next.t('Folder').toLowerCase() }));
                      queryClient.invalidateQueries(KET_CLASSES_FOLDERS);
                    }}
                    okText={i18next.t('Confirm')}
                    cancelText={i18next.t('Close')}
                  > */}
                  <Tooltip title={i18next.t('Delete folder')} placement='bottom'>
                    <Button
                      // icon xóa, quản lý bài giảng
                      //
                      shape='circle'
                      type='dashed'
                      danger
                      icon={<DeleteOutlined />}
                      style={{ alignSelf: 'flex-end' }}
                      onClick={() => onClickDeleteFolderBtn(selectedFolder, allFolders, reference)}
                    />
                  </Tooltip>
                  {/* </Popconfirm> */}
                </React.Fragment>
              )}
            </Space>
            <Divider style={{ marginBottom: 12 }} />
          </>
        )}
        {/* REFERENCE */}
        <div
          style={{
            overflowY: 'auto',
            minHeight: 'calc(100vh - 300px)',
            maxHeight: 'calc(100vh - 100px)',
            height: 'calc(100% - 50px)',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Tree.DirectoryTree
            icon={(props: any) => {
              return (
                <React.Fragment>
                  {props.selected ? (
                    <FolderOpenFilled className='tw-mr-1' style={{ color: props.data.color, fontSize: 16 }} />
                  ) : (
                    <FolderOutlined className='tw-mr-1' style={{ color: props.data.color, fontSize: 16 }} />
                  )}
                </React.Fragment>
              );
            }}
            expandedKeys={expandedKeys} // colapseAll
            onExpand={handleExpand}
            multiple={false}
            onExpand={handleExpand}
            onSelect={(selectedKeys, info) => {
              if (info.selected) {
                const id = info.node.key;
                const folder = _.find(allFolders, (x) => {
                  return x.id === id;
                });
                setSelectedFolder(folder);
                onSelect(folder);
              }
            }}
            treeData={treeFolders}
          />
          {allFolders?.length > 0 && (
            <div style={{ padding: '0 5px' }}>
              {expandedStatus === 'collapseAll' ? (
                <MinusSquareOutlined onClick={() => handleExpandOrCollapseAll(expandedStatus)} />
              ) : (
                <PlusSquareOutlined onClick={() => handleExpandOrCollapseAll(expandedStatus)} />
              )}
            </div>
          )}
        </div>
      </Skeleton>
    </React.Fragment>
  );
};

export default withAuthenticator(FolderTreeView);
