import { EyeOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';
import SlideQuiz_v2 from 'modules/Presentation/Viewer/ViewerTypes/Quiz/SlideQuiz';
import withAuthenticator from 'HOCs/withAuthenticator';
import { useSelector } from 'react-redux';
import { query } from 'helpers/QueryHelper';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const PreviewQuestion = ({ data }) => {
  const { t } = useTranslation('');
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataPreview, setDataPreview] = useState([]);
  const handleCancelModal = () => {
    setOpenModalPreview(false);
  };

  const onPreview = () => {
    setIsLoading(true);
    query('p_QUIZCITY_GetComponentType_ByTypeCode', {
      TypeCode: data?.type,
      OptionTypeCode: data?.optionType,
    }).then((response) => {
      setDataPreview({
        ...data,
        componentType: response?.[0]?.componentType?.toUpperCase(),
        subTitle: response?.[0]?.subTypeName,
        title: response?.[0]?.questionTypeName,
      });
      setIsLoading(false);
    });
    setOpenModalPreview(true);
  };
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <EyeOutlined style={{ cursor: 'pointer' }} onClick={() => onPreview()} />
      </div>

      <Modal
        onCancel={handleCancelModal}
        open={openModalPreview}
        title={i18next.t('Preview')}
        width={'90%'}
        centered
        style={{}}
        footer={[
          <DynamicButton type='primary' onClick={handleCancelModal}>
            {t('Close')}
          </DynamicButton>,
        ]}
      >
        <Spin spinning={isLoading}>
          {dataPreview?.length !== 0 && (
            <SlideQuiz_v2 data={[dataPreview]} currentQuizIndex={0} loggedInUser={loggedInUser.id} preview={true} />
          )}
        </Spin>
      </Modal>
    </div>
  );
};

export default withAuthenticator(PreviewQuestion);
