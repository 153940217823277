import { Popover } from 'antd';
import React from 'react';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { QuestionProps } from '../../ExercicesTypes/type';

type Props = {
  question: QuestionProps;
  hasSuggestButton: boolean;
};

const SuggestBlockquote = ({ question, hasSuggestButton }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const renderContent = () => {
    return (
      <div className='tw-text-base'>
        {question?.answers && (
          <ListGroup className='text-center'>
            <ListGroupItem className='active' style={{ fontWeight: '600', backgroundColor: '#11CDEF', border: 'none' }}>
              Choice: 1 in {question?.answers?.length}
            </ListGroupItem>
            {question?.answers.map((answer: any, index: number) => {
              return <ListGroupItem key={index}>{answer.text}</ListGroupItem>;
            })}
          </ListGroup>
        )}
      </div>
    );
  };
  return (
    <blockquote className='mb-0 blockquote'>
      <p style={{ fontSize: '20px', fontWeight: '600' }}>{question?.questionText}</p>
      {hasSuggestButton && (
        <div>
          <div className='mt-3 mb-4 blockquote-footer'>
            <small style={{ fontSize: 16 }}>{question?.answers?.[0]?.description_VN}</small>
          </div>
          <div>
            <Popover
              id='tu-vung'
              placement='bottom'
              content={renderContent}
              title=''
              trigger='click'
              style={{ zIndex: 99 }}
            >
              <Button
                style={{ fontSize: '16px', lineHeight: '22px', borderRadius: '5px', padding: '10px 20px' }}
                danger={true}
                id='Popover1'
                color='info'
                onClick={handleOpen}
                trigger='click'
              >
                Suggestions
              </Button>
            </Popover>
          </div>
        </div>
      )}
    </blockquote>
  );
};

export default SuggestBlockquote;
