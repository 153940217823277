/* eslint-disable react/prop-types */
import { Tabs } from 'antd';
import Assignments from 'modules/Classwork/components/Assignments/List';
import Students from './components/Students';
import Notifications from './components/Notifications';
import Folders from './components/Folders';
import PresentationStudent from '../../../Presentation/Student';
import EbookScores from './components/EbookScores';
import settings from 'constants/settings';
import StudentsEssay from './components/StudentsEssay';

const { TabPane } = Tabs;

function ClassDetails({ classId }) {
  return (
    <div>
      <Tabs defaultActiveKey='1' type='line' size='middle' style={{ minHeight: 300, fontWeight: 600 }}>
        {/* <TabPane tab='Bài giảng' key='classwork-presentation'>
          <PresentationStudent classId={classId} />
        </TabPane> */}
        {/* <TabPane tab='Bài tập' key='classwork-assigment'>
          <Assignments classId={classId} />
        </TabPane> */}
        {settings.IS_HCM ? (
          <>
            <TabPane tab='Kết quả làm bài' key='ebooks-scores'>
              <EbookScores classId={classId} />
            </TabPane>
            <TabPane tab='Học viên' key='students'>
              <Students classId={classId} />
            </TabPane>
            <TabPane tab='Thông báo' key='notifications'>
              <Notifications classId={classId} />
            </TabPane>
            <TabPane tab='Thư viện, tài nguyên' key='folders'>
              <Folders classId={classId} />
            </TabPane>
          </>
        ) : (
          <>
            <TabPane tab={settings.studentPronouns2} key='students'>
              <Students classId={classId} />
            </TabPane>
          </>
        )}
      </Tabs>
    </div>
  );
}

export default ClassDetails;
