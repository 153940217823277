/* eslint-disable react/no-array-index-key */
import { Select } from 'antd';
import colors from 'constants/colors';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function SelectWord({ type, data, againAnswer, isDisableTest, isHiddenShowColor }) {
  const [state, setState] = useState([]);
  const [result, setResult] = useState();
  const [isDone, setIsDone] = useState(false);
  //
  const handleSelect = (e, indexGroup, index) => {
    const array = _.cloneDeep(state);
    array[indexGroup][index].userAnswers = e;
    setState(array);
  };

  //
  useEffect(() => {
    setState(formatData(againAnswer?.userData));
  }, [data?.length, againAnswer]);

  useEffect(() => {
    Object.assign(type, {
      submit: () => {
        if (!data) return {};
        // const res = handleResult(state);
        const res = handleResultByGroup(state);
        // setIsDone(true);
        return { selectWordResult: res };
      },
      tryAgain: () => {
        // setResult();
        setState(formatData(state));
        // setIsDone(false);
      },
      setIsDone,
      setResult,
    });
    return () => {};
  }, [data, type, state]);

  //data đang là mảng phẳng, cần format về dạng gom nhóm để render
  const formatData = (answers) => {
    if (!data) {
      return [];
    }
    const floatArray = answers ? _.flatten(answers) : [];
    //Nếu có kết quả làm bài thì tìm input tương ứng để lấy đáp án
    const matchAnswerToData = answers
      ? data.map((item) => {
          const userAnswers = floatArray.find((i) => i.left === item.left && i.top === item.top);
          return userAnswers ?? item;
        })
      : data;
    //gom nhóm các input cùng group
    const group = _.groupBy(matchAnswerToData, 'groupName');
    const newData = _.cloneDeep(Object.values(group));
    return newData;
  };

  if (!data) return null;
  return (
    <React.Fragment>
      {state.map((item, indexGroup) => {
        return (
          <React.Fragment key={indexGroup}>
            {item.map((value, index) => {
              const { width, top, left, angle, isCorrect, userAnswers, text } = value;
              let color = colors.font;

              if (isDisableTest && !isHiddenShowColor) {
                if (isCorrect) {
                  color = colors.theme.success;
                } else {
                  color = colors.theme.danger;
                }
              }

              return (
                <Select
                  key={index}
                  onChange={(e) => handleSelect(e, indexGroup, index)}
                  value={userAnswers}
                  style={{
                    position: 'absolute',
                    width,
                    top,
                    left,
                    transform: `rotate(${angle}deg)`,
                    color,
                    fontWeight: 600,
                    fontSize: 20,
                    cursor: isDisableTest ? 'no-drop' : 'pointer',
                  }}
                  open={isDisableTest ? false : undefined}
                  getPopupContainer={() => document.getElementsByClassName('match-container')[0]}
                >
                  {_.shuffle(item).map((x, i) => (
                    <Select.Option key={i} value={x.text}>
                      {x.text}
                    </Select.Option>
                  ))}
                </Select>
              );
            })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

SelectWord.propTypes = {
  type: PropTypes.object,
  data: PropTypes.array,
};

export default SelectWord;

// #region Tính điểm theo nhóm (sai 1 chỗ, vẫn tính điểm chỗ khác)
const handleResultByGroup = (state) => {
  const newState = state.map((value) =>
    value.map((item) => Object.assign(item, { isCorrect: item.userAnswers === item.text })),
  );

  // #region Tính điểm.
  const resultByGroup = newState.map((item) => {
    const correctByGroup = item.filter((x) => x.isCorrect).length;
    const totalByGroup = item.length;
    const score = correctByGroup / totalByGroup;
    // #region Kiểm tra câu hiện tại đã làm chưa?, không quan tâm đúng sai. Phía dưới dùng.
    const completedByGroup = item.some((x) => x.userAnswers);
    // #endregion
    return { groupName: item[0].groupName, score, completed: completedByGroup };
  });
  // #endregion

  const correct = resultByGroup
    .reduce((previousValue, currentValue) => previousValue + currentValue.score, 0)
    .toFixed(2);
  const total = resultByGroup.length;

  const percent = parseInt((correct * 100) / total);
  const resultString = `${correct}/${total}`;
  const star = percent / 20;

  // #region Đếm số câu đã làm
  const countSelected = resultByGroup.filter((x) => x.completed).length;
  const complete = `${countSelected}/${total}`;
  // #endregion

  return { userData: newState, percent, resultString, star, complete };
};
// #endregion

// Tính điểm theo vị trí.
const handleResult = (state) => {
  const newState = state.map((value) =>
    value.map((item) => Object.assign(item, { isCorrect: item.userAnswers === item.text })),
  );
  const correct = newState
    .flat()
    .map((value) => value.isCorrect)
    .filter((item) => item).length;
  const total = newState.flat().length;
  const percent = parseInt((correct * 100) / total);
  const resultString = `${correct}/${total}`;
  const star = percent / 20;
  //
  // const countSelected = newState.filter((group) => group.some((x) => x.userAnswers)).length;
  const countSelected = newState.flat().filter((x) => x.userAnswers).length;
  const complete = `${countSelected}/${total}`;
  return { userData: newState, percent, resultString, star, complete };
};
