/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable sort-keys */
/* eslint-disable import/prefer-default-export */
import { query } from 'helpers/QueryHelper';
import _ from 'lodash';
import { SumStringToArray } from './Result';

export const saveToServer = async (
  file,
  score,
  data,
  studentId,
  studentData,
  assignmentId,
  slideId,
  folderId,
  duration,
) => {
  const typesResult = {
    writeResult: '',
    oneChoiceResult: '',
    multipleChoiceResult: '',
    matchResult: '',
    multipleMatchResult: '',
    selectWordResult: '',
    dropDownResult: '',
    dragdropResult: '',
    speechRecognitionResult: '',
    record: null,
  };

  const { result, resultListen } = data;
  const isEmpty = JSON.stringify(result) === '{}';

  for (const temp in result) {
    typesResult[temp] = result[temp].resultString;
  }

  // _.forIn(result, (value, key) => {
  //   typesResult[key] = value[key].resultString;
  // });

  // Object.assign(typesResult, result);

  const resultData = isEmpty ? resultListen : result;
  //
  const resultArray = SumStringToArray(resultData, 'resultString');
  const [completed, totalCompleted] = SumStringToArray(resultData, 'complete');
  const newScore = isEmpty ? (completed * 10) / totalCompleted : score;
  const totalCompletedAnswer = (completed * 100) / totalCompleted;

  return query(
    'p_QUIZCITY_API_SaveStudentAnswer_WorksheetLog',
    {
      ...studentData,
      StudentId: studentId,
      AttachmentId: file?.id ?? file?.attachmentId ?? file?.workSheetId,
      AssignmentId: assignmentId,
      SlideId: slideId,
      JsonData: JSON.stringify(data),
      Type: 'QUIZCITY-WORKSHEET',
      Score: Math.round(newScore) || 0,
      TeacherNoteS: '',
      Notes: '',
      Star: Math.floor(newScore * 10) || 0,
      // completedPercent: Math.round((completed / totalCompleted[1]) * 100),
      completedPercent: totalCompletedAnswer || 0,
      TotalQuestion: totalCompleted,
      TotalAnswer: completed,
      PassedQuestion: parseInt(resultArray[0].toFixed()),
      folderId,
      duration,
      ...typesResult,
    },
    // 'CLASSWORK',
  );
};

export const selectDataUserAnswer = async (studentId, assignmentId, slideId) => {};
