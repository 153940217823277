import settings from 'constants/settings';
import ConfigSettings from 'pages/Administration/ConfigSettings';
import IndexAddQuestion from 'pages/Administration/FormAddQuestions';
import IndexImportQuestion from 'pages/Administration/ImportQuestion';
import ManagerCoachingSchedule from 'pages/Administration/ManagerCoachingSchedule/index';
import ManagerCourse from 'pages/Administration/ManagerCourse';
import ManagerQA from 'pages/Administration/ManagerQA/ManagerQA';
import ManagerRequestSupport from 'pages/Administration/ManagerRequestSupport';
import ManagerSupport from 'pages/Administration/ManagerSupport';
import ManagerTOEICExamImage from 'pages/Administration/ManagerTOEICExamImage';
import ManagerTeacher from 'pages/Administration/ManagerTeacher';
import Users from 'pages/Administration/Users/index';
import Teacher_Classes_Details from 'pages/Teacher/Classes/ClassDetails';
import Teacher_Classes from 'pages/Teacher/Classes/Classes';
import React from 'react';
import { AiOutlineSchedule, AiOutlineUser, AiTwotoneShop } from 'react-icons/ai';
import { BiBookBookmark, BiSupport } from 'react-icons/bi';
import { FaChalkboardTeacher, FaCogs, FaImages, FaListAlt } from 'react-icons/fa';
import { HiOutlinePresentationChartBar } from 'react-icons/hi';
import { IoLibraryOutline } from 'react-icons/io5';
import { RiQuestionAnswerLine } from 'react-icons/ri';
import { SiGoogleclassroom } from 'react-icons/si';
import { TbReportAnalytics } from 'react-icons/tb';

export default [
  {
    component: Teacher_Classes,
    exact: true,
    icon: <SiGoogleclassroom size={20} className='tw-text-red-500 project-color' />,
    layout: `/${settings.PATHNAME}`,
    miniName: 'C',
    name: 'Learners Management',
    path: '/admin/classes',
    roles: ['administrator'],
    showInSidebar: true,
    sortOrder: 1,
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/presentations',
    name: 'Lectures Management',
    miniName: 'C',
    component: React.lazy(() => import('pages/Teacher/Presentations')),
    icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500 project-color' />,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 4,
  },
  {
    collapse: true,
    path: '/admin/worksheets',
    name: 'Assignments Management',
    miniName: 'C',
    icon: <IoLibraryOutline size={20} className='tw-text-green-600 project-color' />,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 5,
    state: 'lms/admin/worksheets',
    views: [
      {
        path: '/admin/worksheets/list/:filter',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
      },
      {
        path: '/admin/worksheets/list/quizcity-worksheet',
        name: 'Worksheet Exercises',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],

        showInSidebar: true,
        sortOrder: 1,
      },
      {
        path: '/admin/worksheets/list/quizcity-quiz',
        name: 'Quiz Exercises',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 2,
      },
    ],
  },
  {
    collapse: true,
    path: '/admin/report',
    name: 'Statistics',
    miniName: 'C',
    icon: <TbReportAnalytics size={20} className='project-color' />,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 11,
    state: 'lms/admin/report',
    views: [
      {
        path: '/admin/courses_report',
        name: 'Course Report',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportCourses')),
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },
  {
    component: Teacher_Classes_Details,
    exact: true,
    layout: `/${settings.PATHNAME}`,
    path: '/admin/classes/:classId',
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/add-question',
    miniName: 'C',
    component: IndexAddQuestion,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: false,
  },
  {
    path: '/admin/presentation/:presentationId/create',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/presentation/:presentationId/edit',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/classes/:classId',
    component: Teacher_Classes_Details,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/add',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/edit/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/details/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/Details')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/users-manager',
    name: 'Users Management',
    miniName: 'C',
    icon: <AiOutlineUser size={20} className='project-color' />,
    component: Users,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 0,
  },
  {
    path: '/admin/import-question',
    name: 'Questions Management',
    miniName: 'C',
    icon: 'fas fa-question text-green project-color',
    component: IndexImportQuestion,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 1,
  },

  {
    component: ManagerCoachingSchedule,
    exact: true,
    icon: <AiOutlineSchedule size={20} className='project-color' />,
    layout: `/${settings.PATHNAME}`,
    miniName: 'C',
    name: 'Manager Coaching Schedule',
    path: '/admin/manager-coaching-schedule',
    roles: ['administrator'],
    showInSidebar: true,
    sortOrder: 2,
    type: ['administrationRouters'],
  },
  {
    path: '/admin/request-support-management',
    name: 'Request Support',
    miniName: 'C',
    icon: <FaListAlt className='project-color' />,
    component: ManagerRequestSupport,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 3,
  },
  {
    path: '/admin/request-support-management/:requestId',
    component: ManagerRequestSupport,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
  },
  {
    path: '/admin/users-manual-management',
    name: 'Q&A Management',
    miniName: 'C',
    icon: <RiQuestionAnswerLine size={20} className='project-color' />,
    component: ManagerQA,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 4,
  },
  {
    path: '/admin/toeic-exam-image',
    name: 'Toeic Exam Image',
    miniName: 'C',
    icon: <FaImages size={18} className='project-color' />,
    component: ManagerTOEICExamImage,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 5,
  },
  {
    path: '/admin/departments-management',
    name: 'Departments Management',
    miniName: 'C',
    icon: <AiTwotoneShop size={18} className='project-color' />,
    component: React.lazy(() => import('pages/Administration/Menard/ManagerShops')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 6,
  },
  {
    path: '/admin/course-category',
    name: 'Course Category',
    miniName: 'C',
    icon: <BiBookBookmark size={18} className='project-color' />,
    component: ManagerCourse,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 7,
  },
  {
    path: '/admin/teacher-category',
    name: 'Teacher Category',
    miniName: 'C',
    icon: <FaChalkboardTeacher size={16} className='project-color' />,
    component: ManagerTeacher,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 8,
  },
  {
    path: '/admin/support-category',
    name: 'Support Category',
    miniName: 'C',
    icon: <BiSupport size={18} className='project-color' />,
    component: ManagerSupport,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 9,
  },
  // {
  //   component: ManageNotification,
  //   exact: true,
  //   icon: <AiOutlineBell size={20} className='tw-text-blue-500 project-color' />,
  //   layout: `/${settings.PATHNAME}`,
  //   miniName: 'C',
  //   name: 'Notification Setting',
  //   path: '/admin/setup-notifications',
  //   roles: ['administrator'],
  //   showInSidebar: true,
  //   sortOrder: 10,
  //   type: ['administrationRouters'],
  // },
  {
    path: '/admin/configs',
    name: 'Information Setting',
    component: ConfigSettings,
    icon: <FaCogs className='project-color' />,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 11,
  },
];
