/* eslint-disable react/prop-types */
import { ImportOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space, Spin, TreeSelect } from 'antd';
import AdministratorContainer from 'components/Containers/AdministratorContainer';
import Card from 'components/Containers/Card';
import ExportExcel from 'components/Excel/exportExcel/ExportExcel';
import NoData from 'components/NoData';
import notificationWithIcon from 'components/Notification/notificationWithIcon';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import PTTestHeader from 'modules/Student/components/Dashboards/Header/PTTestHeader';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import {
  addOptionAllTestTopics,
  buildTreeSelectTestTopics,
  filterTreeSelectTestTopics,
  validateSelectTestTopic,
} from 'pages/Administration/TestTopics/tableColumn';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Container } from 'reactstrap';
import { excelColumns } from './excelColumns';
import tableDatav2 from './tableDatav2';
import { paginationTable } from 'constants/Pagination';
import ImportExcel_OfflineScore from './component/ImportExcel_OfflineScore/ImportExcel_OfflineScore';
const { RangePicker } = DatePicker;

export default function Home({ loggedInUser }) {
  const dateFormat = 'YYYY-MM-DD';
  const [listCodeData, setListCodeData] = useState([]);
  const [coursesList, setCoursesList] = React.useState([{ label: 'Tất cả đề test', value: null }]);
  const [examCategory, setExamCategory] = React.useState([]);

  const [isOpenModalCreateCode, setIsOpenModalCreateCode] = useState(false);
  const [parameters, setParameters] = useState({ pageNum: 1, pageSize: 10 });
  const [isDisabledCreateButton, setIsDisabledCreateButton] = useState(false);

  const [form] = Form.useForm();

  const initialValues = {
    examId: '00000000-0000-0000-0000-000000000000',
    status: 1,
  };

  const { data, isLoading } = useQuery(
    ['p_QUIZCITY_PTTest_CodeExam_Dashboard', parameters],
    () => query('p_QUIZCITY_PTTest_CodeExam_Dashboard', parameters),
    {
      enabled: !!parameters?.UserId,
    },
  );

  const totalRows = data?.[0]?.totalRows ?? 0;

  useEffect(() => {
    const newArr = data?.map((item, index) => {
      return {
        ...item,
        // Thêm cột index để xuất excel
        index: index + 1,
        // user , selectBy, status là nối các thông tin để hiển thị gọn các cột lại
        user: `${item?.contactName ?? ''}/${item?.contactEmail ?? ''}/${item?.contactPhone ?? ''}/${item?.contactCode ??''}`,
        selectBy: `${item?.userGetPTCodeName ?? ''}/${item?.divisionName ?? ''}`,
        status: `${item?.statusAssigned ?? ''}/${item?.statusActived ?? ''}`,
      };
    });
    setListCodeData(newArr);
  }, [data]);

  useEffect(() => {
    query('p_QUIZCITY_Exam_Category_Select').then((res) => {
      const options = buildTreeSelectTestTopics(res);
      setCoursesList(options);
      setExamCategory(res);
    });
    onSearchData({ code: null, contactEmail: '', contactName: '', examId: '', rangeDate: initialValues.rangeDate });
  }, []);

  const onSearchData = (values) => {
    setListCodeData([]);
    const { code, contactEmail, contactName, examId, rangeDate, status } = values;
    const fromDate = rangeDate?.[0] && rangeDate?.[1] ? rangeDate?.[0] : undefined;
    const toDate = rangeDate?.[0] && rangeDate?.[1] ? rangeDate?.[1] : undefined;
    const params = {
      UserId: loggedInUser?.id,
      ExamId: examId || '00000000-0000-0000-0000-000000000000',
      Status: status,
      Code: code || null,
      ContactName: contactName || '',
      ContactEmail: contactEmail || '',
      ContactPhone: '',
    };
    // Sau khi parameters thay đổi thì useQuery sẽ được gọi lại
    setParameters({
      pageNum: 1,
      pageSize: parameters.pageSize,
      ...params,
      fromDate,
      toDate,
    });
  };

  const handleCreateCodeExam = (value) => {
    setIsDisabledCreateButton(true);
    const { courseId, number, note, prefix } = value;
    let arrCode = [];
    const length = prefix?.length;
    for (let i = 0; i < number; i++) {
      if (prefix) {
        arrCode.push(`${prefix}${GenPasswordModel(2, 6).slice(0, 6 - length)}`);
      } else arrCode.push(GenPasswordModel(2, 6));
    }
    query('p_QUIZCITY_PTTest_CodeExam_GenerateCode', {
      ExamId: courseId,
      CodeStrings: arrCode.toString(),
      CreatedBy: loggedInUser?.id,
      note,
    })
      .then((res) => {
        if (courseId === parameters?.ExamId) {
          form.submit();
        }
        setIsOpenModalCreateCode(false);
        notificationWithIcon('success', 'Thông báo', 'Lưu dữ liệu thành công');
      })
      .catch((err) => {
        console.error('err', err);
        notificationWithIcon('warning', 'Thông báo', 'Lưu dữ liệu thất bại');
      })
      .finally(() => setIsDisabledCreateButton(false));
  };

  return (
    <div>
      <PTTestHeader parentName='Trang chủ' />

      <React.Fragment>
        {(loggedInUser.role === 'teacher' || loggedInUser.role === 'administrator') && (
          <Container fluid className='mt--6' style={{ marginBottom: 102 }}>
            <Card
              title='Kết quả bài test'
              extra={
                <Space>
                  <AdministratorContainer>
                    <DynamicButton
                      type='primary'
                      onClick={() => {
                        setIsOpenModalCreateCode(true);
                        setIsDisabledCreateButton(false);
                      }}
                    >
                      <PlusOutlined /> {i18next.t('Create test code')}
                    </DynamicButton>
                  </AdministratorContainer>
                  <AdministratorContainer>
                    {listCodeData?.length > 0 && (
                      <ExportExcel
                        filename='DS_ma-tham-gia-bai-test'
                        element={
                          <DynamicButton loading={isLoading} type='primary'>
                            {i18next.t('excel export')}
                          </DynamicButton>
                        }
                        sheets={[
                          {
                            name: 'CodeExam', // sheet name
                            column: excelColumns,
                            data: listCodeData,
                          },
                        ]}
                      />
                    )}
                  </AdministratorContainer>
                  <ImportExcel_OfflineScore />
                </Space>
              }
              bodyStyles={{ padding: '10px 24px 0 24px' }}
            >
              <Form layout='vertical' onFinish={(values) => onSearchData(values)} initialValues={initialValues}>
                <Row gutter={10} align='bottom'>
                  <Col>
                    <Form.Item name='examId' label='Đề test' style={{ minWidth: 130 }}>
                      <TreeSelect
                        showSearch
                        treeData={addOptionAllTestTopics(coursesList)}
                        dropdownStyle={{ maxHeight: 800, overflow: 'auto', width: 350 }}
                        // treeDefaultExpandAll
                        filterTreeNode={filterTreeSelectTestTopics}
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        allowClear
                        placeholder='Chọn đề bài test'
                        dropdownMatchSelectWidth={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name='status' label='Trạng thái mã' style={{ minWidth: 155 }}>
                      <Select
                        options={[
                          { label: 'Tất cả', value: -1 },
                          { label: 'Đã kích hoạt', value: 1 },
                          { label: 'Chưa kích hoạt', value: 0 },
                        ]}
                        placeholder='Trạng thái mã'
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name='rangeDate'
                      label='Ngày kích hoạt'
                      // rules={[{ required: true, message: 'Chưa nhập' }]}
                    >
                      <RangePicker placeholder={['Từ ngày ', 'Đến ngày']} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name='code'>
                      <Input placeholder='Mã tham gia' allowClear />
                    </Form.Item>
                  </Col>
                  {/* <Form.Item name='contactName' hasFeedback>
                    <Input placeholder='Họ và tên' allowClear />
                  </Form.Item>
                  <Form.Item name='contactEmail' hasFeedback>
                    <Input placeholder='Email' allowClear />
                  </Form.Item> */}
                  <Col>
                    <Form.Item>
                      <DynamicButton type='primary' htmlType='submit' loading={isLoading}>
                        Tìm kiếm
                      </DynamicButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div>
                <Spin spinning={isLoading}>
                  {!(listCodeData?.length > 0) && !isLoading && <NoData />}
                  <DynamicTable
                    rowClassName='tw-cursor-pointer'
                    rowKey='id'
                    loading={isLoading}
                    dataSource={listCodeData}
                    initialTableData={tableDatav2}
                    pagination={paginationTable({
                      pageNumber: parameters?.pageNum,
                      pageSize: parameters?.pageSize,
                      totalRows: totalRows,
                      onChange: (pageNumber, pageSize) => {
                        setParameters((pre) => ({ ...pre, pageNum: pageNumber, pageSize: pageSize }));
                      },
                    })}
                    scroll={{ x: 1700 }}
                  />
                </Spin>
              </div>
            </Card>
            <Modal
              title='TẠO MÃ TEST'
              open={isOpenModalCreateCode}
              footer={null}
              onCancel={() => {
                setIsOpenModalCreateCode(false);
              }}
              destroyOnClose
            >
              <Form key='createdForm' onFinish={handleCreateCodeExam} layout='vertical'>
                <Form.Item
                  label='Đề bài test'
                  name='courseId'
                  rules={[
                    { required: true, message: 'Chưa nhập' },
                    { validator: (_, value) => validateSelectTestTopic(value, examCategory) },
                  ]}
                >
                  <TreeSelect
                    showSearch
                    treeData={coursesList}
                    dropdownStyle={{ maxHeight: 800, overflow: 'auto' }}
                    // treeDefaultExpandAll
                    filterTreeNode={filterTreeSelectTestTopics}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    allowClear
                    placeholder='Chọn đề test'
                  />
                </Form.Item>
                <Row gutter={10}>
                  <Col span={8}>
                    <Form.Item name='number' label='Số lượng mã' rules={[{ required: true, message: 'Chưa nhập' }]}>
                      <InputNumber min={1} max={1000} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item name='prefix' label='Tiền tố'>
                      <Input maxLength={3} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name='note' label='Ghi chú'>
                  <Input />
                </Form.Item>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', columnGap: 20 }}>
                  <DynamicButton type='primary' htmlType='submit' disabled={isDisabledCreateButton}>
                    Bắt đầu tạo
                  </DynamicButton>
                </div>
              </Form>
            </Modal>
          </Container>
        )}
      </React.Fragment>
    </div>
  );
}
