import { Modal } from 'antd';
import settings, { IS_BASAO, IS_TRONGDONG } from 'constants/settings';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionTypes from '../ExercicesTypes';
import QuestionTypesWithHint from '../Result/components/index';

type Props = {
  data: any;
  currentType: string;
  visible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  questionIndex: number;
  viewMode?: string;
};

const ResultDetails = ({ data, currentType, visible, setModalVisible, questionIndex = 0, viewMode = 'ONE' }: Props) => {
  const { t } = useTranslation('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
    setModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalVisible(false);
  };
  React.useEffect(() => {
    setIsModalVisible(visible);
  }, [visible]);
  // const { question, userData } = data[questionIndex];
  // const QuestionComponent = QuestionTypes[question?.componentType?.toUpperCase() as keyof typeof QuestionTypes];

  return (
    <Modal
      centered
      width='80%'
      open={isModalVisible}
      onCancel={handleCancel}
      closable={false}
      footer={[
        <DynamicButton
          key='close-modal'
          onClick={() => {
            handleOk();
          }}
        >
          {t('Close')}
        </DynamicButton>,
      ]}
      destroyOnClose
    >
      {data?.map((item, index) => {
        if (viewMode !== 'LIST') {
          if (index !== questionIndex) return null;
        }
        const { question, userData } = item;
        const HandledQuestionTypes = settings?.IS_MENARD || IS_BASAO || IS_TRONGDONG? QuestionTypesWithHint : QuestionTypes;
        const QuestionComponent =
          HandledQuestionTypes[question?.componentType?.toUpperCase() as keyof typeof QuestionTypesWithHint];
        return (
          <QuestionComponent
            data={question}
            // ExplainText={<ExplainText data={question} />}
            onSubmit={() => ''}
            userData={userData}
            questionIndex={index + 1}
            showUserData={true}
            hiddenTitle={currentType === 'COMBINE' ? true : false}
            preview={currentType === 'EssayParent' ? true : false}
          />
        );
      })}
    </Modal>
  );
};

export default ResultDetails;
