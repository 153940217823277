import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import colors from "constants/colors";

export const formatDate = 'DD/MM/yyyy'
export const tagColors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple'];
export const statusRequest = [
  { status: 'ACTIVE', color: 'success', icon: <CheckCircleOutlined /> },
  { status: 'DEACTIVE', color: 'error', icon: <CloseCircleOutlined /> },
]

const screenWidth = window.innerWidth;

export const courseColumns = {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'Creator',
      key: 'createdByName',
      fixed: 'left',
      width: '1%',
      render: 'renderText',
      style: {
        minWidth: 100,
        fontWeight: 600,
        color: colors.font,
      },
      sort: false,
    },
    {
      title: 'Category',
      key: 'requestCategoryName',
      fixed: 'left',
      width: '1%',
      render: 'renderText',
      style: {
        minWidth: 100,
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Question',
      key: 'question',
      width: '20%',
      sort: false,
      render: 'renderText',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
      render: (answer, record) => {
        if (answer) {
          const textLength = 100; // giới hạn hiển thị là 100 kí tự
          const text = answer.length > textLength ? answer.slice(0, textLength) + "..." : answer; // Nếu câu trả lời dài hơn textLength thì mới hiển thị "..."
          return (
            <div style={{
              fontWeight: 600,
              color: colors.font,
            }}>{text}</div>)
        }
        else return '';
      },
    },
    {
      title: 'Answer',
      key: 'answer',
      width: '30%',
      sort: false,
      render: (answer, record) => {
        if (answer) {
          const textLength = Math.floor(screenWidth / 10) // 10% màn hình
          const text = answer.length > textLength ? answer.slice(0, textLength) + "..." : answer; // Nếu câu trả lời dài hơn textLength thì mới hiển thị "..."
          return (
            <div dangerouslySetInnerHTML={{ __html: text }} />
            )
        }
        else return '';
      },
    },
    {
      title: 'Status',
      key: 'status',
      width: '1%',
      render: (status, record) => {
        const statusDisplay = statusRequest.find(item => item.status.toUpperCase() === status.toUpperCase());
        return (
          <Tag color={statusDisplay?.color} icon={statusDisplay?.icon}>{statusDisplay?.status}</Tag>)
      },
    },
  ],
};