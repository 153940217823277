import * as actionTypes from './types';

export function init() {
  return {
    type: actionTypes.AUTH_INIT,
  };
}

export function login(email, password) {
  return {
    type: actionTypes.AUTH_LOGIN,
    payload: { email, password },
  };
}
export function loginGoogle(email) {
  return {
    type: actionTypes.AUTH_LOGIN_GOOGLE,
    payload: { email },
  };
}
export function register() {}
