import React from 'react';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import settings from 'constants/settings';
import DynamicButton from 'packages/DynamicButton/DynamicButton';

const AccessDenied = () => {
  return (
    <Container name='Hạn chế quyền truy cập' parentName='Trang chủ'>
      <Card title='Hạn chế quyền truy cập' subTitle='403 Forbidden'>
        <Result
          status='403'
          title='403 Forbidden'
          subTitle='Xin lỗi, bạn không được phép truy cập trang này.'
          extra={
            <Link to={`/${settings.PATHNAME}/home`}>
              <DynamicButton type='primary'>Quay lại</DynamicButton>
            </Link>
          }
        />
      </Card>
    </Container>
  );
};

export default AccessDenied;
