import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';

const getNotificationManagement = () => {
  const sqlCommand = 'dbo.p_QUIZCITY_GetAll_NotificationManagement';
  const parameters = {};
  const applicationName = settings.APPLICATION_NAME;
  return query(sqlCommand, parameters, applicationName);
};

export default getNotificationManagement;
