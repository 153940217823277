import { BellOutlined as BellIcon, CalendarOutlined } from '@ant-design/icons';
import settings from 'constants/settings';
import i18next from 'i18next';
const type = settings.IS_LG
  ? [
      {
        title: 'Type',
        key: 'typeName',
        width: '1%',
        render: 'renderNoWrap',
        style: {
          minwidth: 50,
          fontWeight: '400',
        },
        sort: false,
      },
    ]
  : [];
export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: i18next.t('table:notification.Title'),
      key: 'title',
      sort: false,
      render: (text, record, index) => {
        return (
          <div
            style={{
              fontWeight: record.status ? 600 : 400,
              cursor: 'pointer',
            }}
          >
            <BellIcon style={{ color: record.color }} className='tw-mr-2' />
            {text}
          </div>
        );
      },
    },
    ...type,
    {
      title: i18next.t('table:notification.Created date'),
      key: 'createdDate',
      width: '1%',
      render: 'renderDateTime',
      style: {
        fontWeight: '400',
      },
      prefix: <CalendarOutlined className='tw-mr-2' />,
    },
    {
      title: i18next.t('table:notification.Status'),
      key: 'status',
      width: '6%',
      render: (status, record, index) => {
        // Quy ước: True - 1 - Chưa xem, False - 0 - Đã xem.
        return (
          <div style={{ fontWeight: record.status ? 600 : 400, textAlign: 'center' }}>
            {status ? i18next.t('table:notification.Not seen') : i18next.t('table:notification.Seen')}
          </div>
        );
      },
      style: {
        fontWeight: '400',
      },
      prefix: <CalendarOutlined className='tw-mr-2' />,
    },
  ],
};
