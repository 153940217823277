import * as actionTypes from "../actions/types";
const initialState = { loading: false, error: null, results: [] };

const resultReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case actionTypes.GET_RESULT:
      return { ...state, loading: true, results: null, error: null };

    // case actionTypes.GET_RESULT_REQUEST:
    // return { ...state, loading: true, results: null, error: null };

    case actionTypes.GET_RESULT_SUCCESS:
     
      return {
        ...state,
        loading: false,
        results: action.results,
        error: null
      };

    case actionTypes.GET_RESULT_FAILURE:
      return {
        ...state,
        loading: false,
        results: [],
        error: action
      };

    default:
      return state;
  }
};
export default resultReducer;
