import { Button, Modal, Form, TreeSelect, Tooltip } from 'antd';
import { FolderOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { deleteFile, execute, get, remove } from 'helpers/QueryHelper';
import settings from 'constants/settings';
import { useQuery, useQueryClient } from 'react-query';
import { buildSelectTreeData } from 'utils';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const FileMoving = ({
  KEY_SHARE_ATTACHMENTS,
  folders,
  id,
  entityId,
  buttonText,
  buttonIcon,
  buttonType,
  buttonShape,
  toolTipTitle,
  toolTipPlacement,
}) => {
  const { t } = useTranslation('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const createButton = (buttonText, buttonIcon, buttonType, buttonShape) => (
    <Button icon={buttonIcon} type={buttonType} shape={buttonShape} onClick={() => setIsModalOpen(true)}>
      {buttonText}
    </Button>
  );

  return (
    <>
      {toolTipTitle ? (
        <Tooltip title={toolTipTitle} placement={toolTipPlacement}>
          {createButton(buttonText, buttonIcon, buttonType, buttonShape)}
        </Tooltip>
      ) : (
        createButton(buttonText, buttonIcon, buttonType, buttonShape)
      )}

      <Modal
        maskClosable={false}
        centered
        title={i18next.t('move folder')}
        open={isModalOpen}
        onOk={() => {
          form.submit();
          setIsModalOpen(false);
          // setExpandedRowKeys([]);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        okText={t('Update')}
        cancelText={t('Close')}
      >
        <Form
          form={form}
          layout='vertical'
          onFinish={async (values) => {
            const parameters = {
              ...values,
              id,
            };
            await execute('p_SACHSO_SHARE_Attachments_UpdateEntityId', parameters, settings.APPLICATION_NAME);
            queryClient.invalidateQueries(KEY_SHARE_ATTACHMENTS);
          }}
          requiredMark={false}
          initialValues={{ entityId: entityId }}
        >
          <Form.Item label={i18next.t('Move to')} name='entityId'>
            <TreeSelect
              treeData={buildSelectTreeData(folders)}
              allowClear
              dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: 10000 }}
              treeDefaultExpandAll
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

FileMoving.defaultProps = {
  KEY_SHARE_ATTACHMENTS: [],
  folders: [],
  id: '',
  entityId: '',
  buttonText: '',
  buttonIcon: <FolderOutlined />,
  buttonType: 'primary',
  buttonShape: 'default',
  toolTipTitle: '',
  toolTipPlacement: 'top',
};

export default FileMoving;
