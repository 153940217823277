import settings from 'constants/settings';
import React from 'react';
import { MdGroup as ClassIcon, MdAssignment as AssignmentIcon } from 'react-icons/md';

export default [
  {
    path: '/student/classes',
    name: 'Lớp học',
    miniName: 'C',
    component: React.lazy(() => import('pages/Student/Classes/Classes')),
    icon: <ClassIcon size={20} className='tw-text-yellow-500' />,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student'],
    type: ['allowedRoutes'],
    showInSidebar: settings.IS_HCM,
    sortOrder: 10,
  },
  {
    path: '/student/presentations',
    name: 'Bài giảng',
    miniName: 'C',
    component: React.lazy(() => import('pages/Student/Presentation/List')),
    icon: 'fas fa-book text-primary',
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student'],
    type: ['allowedRoutes'],
    showInSidebar: false,
    sortOrder: 15,
  },
  {
    path: '/student/presentations/:presentationId/slides/:slideId',
    component: React.lazy(() => import('pages/Student/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student', 'teacher', 'manager', 'administrator'], // Học sinh và giáo viên đều có thể làm bài.
    type: ['allowedRoutes'],
  },
  {
    path: '/student/presentations/:presentationId/slides/:slideId/questions/:questionId',
    component: React.lazy(() => import('pages/Student/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student', 'teacher', 'manager', 'administrator'], // Học sinh và giáo viên đều có thể làm bài.
    type: ['allowedRoutes'],
  },
  {
    path: '/student/presentations/:presentationId/slides/:slideId/questions/result',
    component: React.lazy(() => import('pages/Student/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student', 'teacher', 'manager', 'administrator'], // Học sinh và giáo viên đều có thể làm bài.
    type: ['allowedRoutes'],
  },
  {
    path: '/student/classes/:classId',
    component: React.lazy(() => import('pages/Student/Classes/ClassDetails')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student'],
    type: ['allowedRoutes'],
  },
  {
    path: '/student/classes/:classId/classwork/assignments/:assignmentId/details',
    component: React.lazy(() => import('pages/Student/Classwork/Assignments/Details')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student'],
    type: ['allowedRoutes'],
  }
];
