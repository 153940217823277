import { Col, Form, Input } from 'antd';
import React from 'react';
import Layout from '../../Layouts/Layout';

const IndexMatching = ({ form, field, setAddField, optionType }) => {
  const RenderLayOutAnswer = () => {
    switch (optionType.toUpperCase()) {
      case 'MATCHPICTURESWORD':
        return (
          <Layout
            field={field}
            layout={['image', 'input']}
            setAddField={setAddField}
            form={form}
            autoFocus={true}
            lg={10}
          />
        );
      case 'READMATCHSENTENCE':
        return (
          <Layout
            field={field}
            layout={['textLeft', 'textRight']}
            setAddField={setAddField}
            form={form}
            autoFocus={true}
            lg={11}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <RenderLayOutAnswer />
      {/* <Col lg={11}>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          {...field}
          name={[field.name, 'textLeft']}
          label={'Cột trái'}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col lg={11}>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          {...field}
          name={[field.name, 'textRight']}
          label={'Cột phải'}
        >
          <Input />
        </Form.Item>
      </Col> */}
    </>
  );
};

export default IndexMatching;
