import styled from 'styled-components';
const StyleQuiz = styled.div`
  @media (max-width: 767px) {
    .ant-col-20 {
      max-width: 100% !important;
      flex: 0 0 100% !important;
    }
    .title {
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1;
    }
  }
`;
export { StyleQuiz };
