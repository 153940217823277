import { Col, notification, Row } from 'antd';
import _ from 'lodash';
import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import AudioButton from '../AudioButton';
import QuestionHeader from '../QuestionHeader';
import './style.css';
import Crayon from './component/Crayon';
import { QuestionComponentRef } from '../type';

notification.config({
  placement: 'top',
  maxCount: 2,
  duration: 1,
});

type AnswerProps = {
  id: number;
  answer: string;
};

const FillColor = ({ data, questionIndex, onSubmit, userData, preview = false }: any, ref: QuestionComponentRef) => {
  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
  }));

  // Ref
  const refColor = React.useRef<any>([]);
  const refImage = React.useRef<any>([]);

  // State
  const [selectedColor, setSelectedColor] = React.useState('transparent');
  const [userAnswers, setUserAnswers] = React.useState<AnswerProps[]>([]);

  //Variables
  const colors = _.uniqBy(data.answers, 'answer');
  const imageSVG = data?.imageSVG;

  // Effect
  React.useEffect(() => {
    if (userData) {
      const answers = userData.userAnswers;
      initQuestion(answers);
    }

    if (preview) {
      const answers = data.answers;
      initQuestion(answers);
    }
    return () => {
      setUserAnswers([]);
    };
  }, [data]);

  const initQuestion = (data: any) => {
    data.map((item: any, index: number) => {
      refImage.current[data?.[index]?.id - 1]?.setAttribute(
        'style',
        `cursor: pointer; fill: ${item.answer}; stroke: white`,
      );
      refImage.current[data?.[index]?.id]?.setAttribute('data-color', `${selectedColor}`);
    });
  };

  const handleChangeColor = (key: number) => {
    refImage.current[key].setAttribute('style', `cursor: pointer; fill: ${selectedColor}; stroke: white`);
    refImage.current[key].setAttribute('data-color', `${selectedColor}`);
    var found = userAnswers.find((item) => item.id === key + 1);

    if (found === undefined) {
      setUserAnswers((pre) =>
        _.sortBy(
          [...pre, { id: key + 1, answer: selectedColor }],
          [
            function (o) {
              return o.id;
            },
          ],
        ),
      );
    } else {
      found.answer = selectedColor;
    }
  };

  const handleSubmit = () => {
    const result = _.isEqual(data.answers, userAnswers) ? 100 : 0;
    onSubmit?.({id: data.id, userAnswers, result }, data);
    setUserAnswers([]);
  };

  const handleSelectColor = (color: any) => {
    setSelectedColor(color.answer);
  };

  return (
    <Card>
      <CardHeader>
        <QuestionHeader title={data.title} subTitle={data.subTitle} questionIndex={questionIndex} />
      </CardHeader>
      <CardBody className='pb-3 tw-flex' style={{ minHeight: 300 }}>
        <Row>
          <Col span={8}>
            <AudioButton audioUrl={data?.audioUrl} />

            <div className='tw-flex tw-flex-col tw-p-3 tw-gap-2'>
              {(!userData || !preview) &&
                colors.map((color: any, index: number) => (
                  <div>
                    <div
                      key={index}
                      ref={(el) => (refColor.current[index] = el)}
                      onClick={() => handleSelectColor(color)}
                      className='crayon-item tw-cursor-pointer'
                    >
                      <Crayon height={50} width={50} color={color.answer}></Crayon>
                    </div>
                  </div>
                ))}
            </div>
          </Col>
          <Col span={16}>
            <svg width={imageSVG?.width} viewBox={imageSVG?.viewBox}>
              <image width={imageSVG?.image?.width} href={imageSVG?.image?.href} />
              {imageSVG.itemPaths.map((item: any, key: number) => (
                <path
                  key={key}
                  ref={(el) => (refImage.current[key] = el)}
                  onClick={() => {
                    handleChangeColor(key);
                  }}
                  data-color='#ffffff'
                  style={{
                    cursor: 'pointer',
                    fill: 'transparent',
                    stroke: 'none',
                  }}
                  className='path-item'
                  d={item.data}
                />
              ))}
            </svg>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default React.forwardRef(FillColor);
