import AdministratorContainer from 'components/Containers/AdministratorContainer';
import StudentContainer from 'components/Containers/StudentContainer';
import TeacherContainer from 'components/Containers/TeacherContainer';
import vimaruColors from 'constants/vimaru';
import { query } from 'helpers/QueryHelper';
import withAuthenticator from 'HOCs/withAuthenticator';
import React from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import CountUp from 'react-countup';
import './styles.css';
import CartReportHeader from './CartReportHeader';
import colors from 'constants/colors';

type Props = {
  name: string;
  parentName: string;
  loggedInUser: {
    id: number;
    role: string;
  };
};

type StatisticalTeacherProps = {
  totalCode: number;
  totalActive: number;
  totalNoactive: number;
};
type StatisticalAdminProps = {
  totalExam: number; // kì thi
  totalCode: number;
  totalActive: number;
  totalNoactive: number;
};

function MenardHeader({ loggedInUser, name, parentName }: Props) {
  const [statisticalTeacher, setStatisticalTeacher] = React.useState<StatisticalTeacherProps>(null);
  const [statisticalAdmin, setStatisticalAdmin] = React.useState<StatisticalAdminProps>(null);
  React.useEffect(() => {
    if (loggedInUser.role === 'teacher') {
      query('p_VIMARU_GetThongKeClass_ByTeacher', { teacherId: loggedInUser.id }).then((response) => {
        setStatisticalTeacher(response?.[0]);
      });
    }
    if (loggedInUser.role === 'administrator') {
      query('p_QUIZCITY_Report_ThongKe_Dashboard', { userId: loggedInUser.id }).then((response) => {
        setStatisticalAdmin(response?.[0]);
      });
    }
    return () => {
      setStatisticalTeacher(null);
    };
  }, [loggedInUser]);

  return (
    <>
      <div
        className='header pb-6'
        style={{ backgroundColor: colors.theme.background, borderTop: `1px solid ${colors.theme.background}` }}
      >
        <Container fluid>
          <div className='header-body'>
            <Row className='align-items-center py-4'>
              <Col lg='6' xs='7'>
                <h6 className='h2 text-white d-inline-block mb-0'>{name}</h6>
                <Breadcrumb
                  className='d-none d-md-inline-block ml-md-4'
                  listClassName='breadcrumb-links breadcrumb-dark'
                >
                  <BreadcrumbItem>
                    <a href='#pablo' onClick={(e) => e.preventDefault()}>
                      <i className='fas fa-home' />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href='#pablo' onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>

            {/* <TeacherContainer>
              <Row>
                <Col md='4' xl='4'>
                  <CartReportHeader
                    title='Số mã tham gia test'
                    value={{ value: statisticalTeacher?.totalCode, color: '#f5365c' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-red' }}
                  />
                </Col>
                <Col md='4' xl='4'>
                  <CartReportHeader
                    title='Số mã đã sử dụng'
                    value={{ value: statisticalTeacher?.totalActive, color: '#2dce89' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-green' }}
                  />
                </Col>
                <Col md='4' xl='4'>
                  <CartReportHeader
                    title='Số mã chưa sử dụng'
                    value={{ value: statisticalTeacher?.totalNoactive, color: '#5e72e4' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-blue' }}
                  />
                </Col>
              </Row>
            </TeacherContainer> */}
            <AdministratorContainer>
              <Row>
                <Col md='6' xl='6'>
                  <CartReportHeader
                    title='Số đề test'
                    value={{ value: statisticalAdmin?.totalExam, color: '#f5365c' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-red' }}
                  />
                </Col>
                <Col md='6' xl='6'>
                  <CartReportHeader
                    title='Số mã test'
                    value={{ value: statisticalAdmin?.totalCode, color: '#2dce89' }}
                    icon={{ name: 'ni ni-chart-pie-35', color: 'bg-gradient-green' }}
                  />
                </Col>
                {/* <Col md='6' xl='3'>
                  <CartReportHeader 
                  title='Số mã đã dùng' 
                  value={{value: statisticalAdmin?.totalActive, color:'#2dce89'}} 
                  icon={{name: 'ni ni-chart-pie-35', color: 'bg-gradient-green'}}/>
                </Col>
                <Col md='6' xl='3'>
                  <CartReportHeader 
                  title='Số mã chưa dùng' 
                  value={{value: statisticalAdmin?.totalNoactive, color:'#5603ad'}} 
                  icon={{name: 'ni ni-chart-pie-35', color: 'bg-gradient-indigo'}}/>
                </Col> */}
              </Row>
            </AdministratorContainer>
          </div>
        </Container>
      </div>
    </>
  );
}

export default withAuthenticator(MenardHeader);
