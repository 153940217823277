import { ExclamationCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import withAuthenticator from 'HOCs/withAuthenticator';
import { Modal, notification } from 'antd';
import Axios from 'axios';
import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import colors from 'constants/colors';
import settings from 'constants/settings';
import fileDownload from 'js-file-download';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { useEffect, useRef, useState } from 'react';
import './style.css';

const sizeCalculation = () => {
  //padding=16
  //title=60
  //maxHeight của body = 682
  //maxWidth của body = 885
  //w/h = 1.3
  const w = window.innerWidth > 885 ? 885 : window.innerWidth;
  const h = window.innerHeight - 60 > 682 ? 682 : window.innerHeight - 60;
  // 1.3 là tỷ lệ width/height của page html (body)
  if ((w / h).toFixed(2) > 1.3) {
    // responsive theo screen height
    return {
      width: h * 1.3,
      bodyHeight: h,
    };
  } else {
    // responsive theo screen width
    return {
      width: w,
      bodyHeight: w / 1.3,
    };
  }
};

const LgUserManualInstructions = ({ loggedInUser }) => {
  const [languageModal, setLanguageModal] = useState({ open: false, action: '', title: '' });
  const [modalVisible, setModalVisible] = useState({ open: false, htmlUrl: '' });
  const [loading, setLoading] = useState(false);
  const [modalSize, setModalSize] = useState(() => sizeCalculation());
  const ref = useRef();

  const url_HDSD_VI =
    loggedInUser?.role === 'student'
      ? 'https://cloud.softech.vn/storage/Data/LG/Huong-dan-su-dung-cho-Student-LG.pdf'
      : 'https://cloud.softech.vn/storage/Data/LG/Huong-dan-su-dung-cho-Manager-LG.pdf';
  const url_HDSD_EN =
    loggedInUser?.role === 'student'
      ? 'https://cloud.softech.vn/storage/Data/LG/User-Manual-for-Student-LG.pdf'
      : 'https://cloud.softech.vn/storage/Data/LG/LG-learning-web-user-manual-for%20-Manager.pdf';

  const urlPreview_HDSD_VI =
    loggedInUser?.role === 'student'
      ? `/HDSD_LG/HDSD_LG_STUDENT_VI/index.html`
      : `/HDSD_LG/HDSD_LG_MANAGER_VI/index.html`;
  const urlPreview_HDSD_EN =
    loggedInUser?.role === 'student'
      ? `/HDSD_LG/HDSD_LG_STUDENT_EN/index.html`
      : `/HDSD_LG/HDSD_LG_MANAGER_EN/index.html`;
  // useEffect(() => {
  //   if (modalVisible) ref.current = document.querySelector('.c56');
  // }, [modalVisible]);

  useEffect(() => {
    const handleResize = () => {
      const size = sizeCalculation();
      setModalSize(size);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const handleDowload = (url, fileName) => {
    setLoading(true);
    Axios.get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, fileName);
        notification.success({ message: 'File download successful', placement: 'top' });
      })
      .finally(() => setLoading(false));
  };
  const showConfirm = () => {
    Modal.warning({
      title: 'Coming soon',
      icon: <ExclamationCircleFilled />,
      content: 'This feature will be updated later',
      // okButtonProps: { className: settings.IS_LG ? 'modal-btn' : '' },
    });
  };

  // action = 'preview' || 'downLoad , type= 'VI' , "EN"
  const handleChooseLanguaghe = (action, type) => {
    setLanguageModal({ open: false, action: '', title: '' });
    if (action === 'preview') {
      if (type === 'VI') {
        setModalVisible({ open: true, htmlUrl: urlPreview_HDSD_VI });
      } else if (type === 'EN') {
        setModalVisible({ open: true, htmlUrl: urlPreview_HDSD_EN });
      }
    } else if (action === 'download') {
      if (type === 'VI') {
        handleDowload(
          url_HDSD_VI,
          'User Manual.pdf', // tên file phải kèm đuôi định dạng
        );
      } else if (type === 'EN') {
        handleDowload(
          url_HDSD_EN,
          'User Manual.pdf', // tên file phải kèm đuôi định dạng
        );
      }
    }
  };

  return (
    <div>
      <Container name={'User manual'} parentName='Trang chủ'>
        <Card title={'USER MANUAL'}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <span style={{ maxWidth: 916 }}> */}
            <span style={{ width: '100%' }}>
              <img
                alt='picture cover'
                // src={require('assets/img/LG/instruction/coverPicture.jpg').default}
                src={
                  settings.IS_LG
                    ? settings.DOMAIN_IS_USE + '/images/LG/LG_usermanual.png'
                    : settings.IS_TVHH
                    ? require('assets/img/introduce/bg_thuyenvien_popup.png').default
                    : require('assets/img/ISE/bg_ies_intro_popup.jpeg').default
                }
                width={'100%'}
              />
            </span>
          </div>
          <br />
          <div className='custom-list'>
            <div>
              <b>Learning </b> : In the Home menu, the student learns each lesson in TOEIC course in Learning Journey
              from Introduction to Coaching. Your current lecture is displayed directly in Home menu for your quickly
              access <br />
              <b> Practice </b>: The student does multiple choice questions, pronunciation exercise and recording to
              practice after each lesson.
              <br />
              <b>Test</b>: The student do the trial test with 11 questions by recording your answer in Sample & Test
              module <br />
              <b>Coaching</b>: After learning, practicing and doing the test, the student will book a coaching 1-1 with
              TOEIC trainer to review the knowledge and get direct feedback.
              <br />
              To follow your learning progress, you can check your learning status with detail data in the first part of
              Home menu.
              <br />
              The course is only available within 05 months.
              <br />
              To understand the detail guideline for all functions of learning website, the student can check user
              manual as bellows.
            </div>
            <br />
            {settings.IS_LG && (
              <div>
                <strong>Detail:&emsp;</strong>
                <DynamicButton
                  type='primary'
                  onClick={() =>
                    setLanguageModal({
                      open: true,
                      action: 'preview',
                      title: `What language would you like to see the tutorial in?`,
                    })
                  }
                >
                  Preview
                </DynamicButton>
                &emsp;
                <DynamicButton
                  type='primary'
                  loading={loading}
                  onClick={() =>
                    setLanguageModal({
                      open: true,
                      action: 'download',
                      title: `What language do you want to download the file in?`,
                    })
                  }
                >
                  Download
                </DynamicButton>
              </div>
            )}
          </div>
        </Card>
      </Container>

      <Modal
        width={450}
        open={languageModal?.open}
        title=''
        footer={false}
        centered
        bodyStyle={{ padding: '12px 24px' }}
        onCancel={() => setLanguageModal({ open: false })}
      >
        <div style={{ fontWeight: 600 }}>
          <QuestionCircleOutlined style={{ color: 'green', fontSize: 16 }} /> &ensp; {languageModal?.title}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', gap: 20, paddingTop: 10 }}>
          <DynamicButton
            size='small'
            style={{ width: 50 }}
            type='primary'
            onClick={() => {
              handleChooseLanguaghe(languageModal.action, 'VI');
            }}
          >
            VI
          </DynamicButton>
          <DynamicButton
            size='small'
            style={{ width: 50 }}
            type='primary'
            onClick={() => {
              handleChooseLanguaghe(languageModal.action, 'EN');
            }}
          >
            EN
          </DynamicButton>
        </div>
      </Modal>

      <Modal
        title={<div style={{ color: colors.theme.button }}>USER MANUAL</div>}
        open={modalVisible?.open}
        onCancel={() => setModalVisible({ open: false })}
        footer={null}
        width={modalSize.width}
        // width={'calc(100vh * 0.7)'}
        bodyStyle={{
          // height: 'calc(100vh - 70px)',
          height: modalSize.bodyHeight,
          padding: 4,
          // overflowY: 'scroll',
          // display: 'flex',
          // justifyContent: 'center',
        }}
        centered
      >
        <iframe
          title='External Website'
          // src='https://docs.google.com/document/d/1xVUKSBmO5lyHt1H_hKv3z4AVSrtWZfCZ/edit?usp=sharing&ouid=113815081786336369160&rtpof=true&sd=true'
          // src='src/pages/LGinnotek/InstructionalContent/HuongdansudungchoStudentLG.html'
          src={settings.DOMAIN_IS_USE + modalVisible?.htmlUrl}
          width='100%'
          height='100%'
        />
        {/* <div style={{ position: 'relative', height: '100%' }}>
          <Tooltip
            title='Go to Index'
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode;
            }}
          >
            <DynamicButton
              style={{
                position: 'fixed',
                left: 'calc(50% + 360px)',
                top: '88%',
                zIndex: 9999,
                borderRadius: '50%',
                backgroundColor: 'rgba(194, 34, 79, 0.74)',
              }}
              icon={<ArrowUpOutlined />}
              type='primary'
              onClick={() => {
                if (ref.current) {
                  ref.current.scrollIntoView({
                    // behavior: 'smooth',
                  });
                }
              }}
            />
          </Tooltip>
          <InstructionalContent />
        </div> */}
      </Modal>
    </div>
  );
};
export default withAuthenticator(LgUserManualInstructions);
