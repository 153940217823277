import React from 'react';

type Props = {
  videoUrl: string;
};
export default function QuestionVideo({ videoUrl }: Props) {
  if (!videoUrl) return null;
  const ref = React.useRef<any>();
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginBlock: 10 }}>
      <video
        ref={ref}
        onCanPlay={() => {
          if (ref.current) {
            ref.current.play();
          }
        }}
        controls
        controlsList='nodownload'
        style={{ width: 'auto', height: '55vh' }}
      >
        <source src={videoUrl} type='video/mp4' />
      </video>
    </div>
  );
}
