import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import React from 'react';
import './style.css';
import { Collapse } from 'antd';
import colors from 'constants/colors';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { query } from 'helpers/QueryHelper';
import NoData from 'components/NoData';

function LgLearningRegulations() {
  const [questionList, setQuestionList] = React.useState([]);

  const getQuestion = async () => {
    const result = await query('p_QUIZCITY_QA_Management_Select');
    setQuestionList(result);
  };
  React.useEffect(() => {
    getQuestion();
  }, []);

  return (
    <Container name={'User manual'} parentName='Trang chủ'>
      <Card style={{ marginBottom: 20 }} title={'LG COURSE COMPLETED EVALUATIVE CRITERIA'}>
        <div className='custom-list'>
          <div className='custom-list-item1'>
            During study progress, students must take the midterm and final exams.
          </div>
          <div className='custom-list-item1'>
            Conditions to participate in the exam:
            <div className='custom-list'>
              <div className='custom-list-item2'>Complete the lesson on time;</div>
              <div className='custom-list-item2'>The completion quality score of the exercise is 70% or higher.</div>
            </div>
          </div>
          <div className='custom-list-item1'>
            Conditions to complete the course: Course evaluation score (Z) is at least 4.0
          </div>
          <div className='custom-list-item1'>
            How to calculate the course evaluation score: <b>Z = 0.4X + 0.6Y</b>
            <div className='custom-list custom-list-item2'>
              <b>In there</b>:
              <div className='custom-list'>
                <div>- Z is the course evaluation score</div>
                <div>- X is the midterm evaluation score</div>
                <div>- Y is the final assessment score</div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Container>
  );
}

export default LgLearningRegulations;
