import { Button, Form, Input } from 'antd';
import { get } from 'helpers/QueryHelper';
import React from 'react';
import { useSelector } from 'react-redux';
import { InsertData } from './InsertData';
import { useQueryClient } from 'react-query';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import i18next from 'i18next';

const Khoa = ({ buttonCloseModal, data }) => {
  const [form] = Form.useForm();
  const loggedInUse = useSelector((state) => state.authReducer.loggedInUser);
  const queryClient = useQueryClient();
  const [proccessing, setProccessing] = React.useState(false);

  const onFinish = async (values) => {
    setProccessing(true);
    const parameters = {
      ParentId: null,
      CategoryName: values.name,
      CreatedBy: loggedInUse?.id,
      ModeifiedBy: loggedInUse?.id,
      Notes: 'Khoa',
      code: values.code,
    };
    const response = await InsertData(parameters);
    form.resetFields();
    if (response) {
      queryClient.invalidateQueries('VIMARU_METADATA_Category').finally(() => {
        setTimeout(() => {
          setProccessing(false);
        }, 1000);
      });
    }
    setTimeout(() => {
      buttonCloseModal?.();
    }, 500);
  };
  return (
    <div>
      <Form onFinish={onFinish} form={form} layout='vertical'>
        <Form.Item
          label='Mã Khoa'
          name='code'
          rules={[
            {
              validator: (_, value) => {
                if ( value === undefined || value.trim() === '') return Promise.reject(i18next.t('please enter', { field: i18next.t('code') }));
                else {
                  const checkDuplicate = data?.some(
                    (item) =>
                      item?.code.trim().toLowerCase().replace(/\s+/g, ' ') ===
                      value.trim().toLowerCase().replace(/\s+/g, ' '),
                  );
                  if (checkDuplicate) return Promise.reject(i18next.t('unavailable', { field: i18next.t('Code') }));
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label='Tên Khoa' name='name' rules={[{ required: true, message: 'Vui lòng nhập tên Khoa' }]}>
          <Input />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: 20 }}>
          <DynamicButton htmlType='button' onClick={() => buttonCloseModal?.()}>
            Đóng
          </DynamicButton>
          <DynamicButton type='primary' htmlType='submit' loading={proccessing}>
            Lưu
          </DynamicButton>
        </div>
      </Form>
    </div>
  );
};

export default Khoa;
