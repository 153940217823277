import { Form, Input, Modal, message } from 'antd';
import colors from 'constants/colors';
import settings from 'constants/settings';
import { sendNotificationToUser } from 'helpers/NotificationHelper';
import { query } from 'helpers/QueryHelper';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import './index.css';
const MAX = 100;
const MIN = 0;

export default function UpdateModal({ student, visibleEnterModal, setVisibleEnterModal, classId, teacherId }) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  React.useEffect(() => {
    if (student) {
      form.setFieldsValue({
        scoreOfflineGDI: student?.scoreOfflineGDI ?? MIN,
        scoreOfflineGDII: student?.scoreOfflineGDII ?? MIN,
        notes: student?.notes || '',
      });
    }
  }, [student]);

  const handleBlur = (e) => {
    if (parseFloat(e.target.value) > parseFloat(e.target.max)) {
      e.target.value = e.target.max;
    }
    if (parseFloat(e.target.value) < parseFloat(e.target.min)) {
      e.target.value = e.target.min;
    }
  };

  const handleScore = (score) => {
    if (score > MAX) {
      return MAX;
    }
    if (score < MIN) {
      return MIN;
    }
    return score;
  };
  const columns = {
    displayColumns: [
      {
        title: `Tên ${settings.studentPronouns1}`,
        dataIndex: 'fullName',
        render: 'renderNoWrap',
        key: 'fullName',
        sorter: false,
      },
      {
        title: 'Email',
        render: 'renderNoWrap',
        dataIndex: 'email',
        key: 'email',
        sorter: false,
      },
      {
        title: 'Khoa',
        render: 'renderNoWrap',
        dataIndex: 'khoa',
        key: 'khoa',
        sorter: false,
      },
      {
        title: 'Ngành',
        render: 'renderNoWrap',
        dataIndex: 'nganh',
        key: 'nganh',
        sorter: false,
      },
      {
        title: (
          <span>
            ĐTGK (X<sub>2</sub>)
          </span>
        ),
        dataIndex: 'scoreOfflineGDI',
        minWidth: 50,
        sorter: false,
        key: 'scoreOfflineGDI',
        render: (text, record) => (
          <Form.Item className='form-item' name={`scoreOfflineGDI`}>
            <Input
              defaultValue={text}
              className='enterscore-input'
              // style={{ textAlign: 'center' }}
              style={{
                marginTop: 25,
                minWidth: 40,
                textAlign: 'center',
              }}
              type='number'
              min={MIN}
              max={MAX}
              step={0.1}
              onBlur={handleBlur}
            />
          </Form.Item>
        ),
      },
      {
        title: 'ĐTCK (Y)',
        dataIndex: 'scoreOfflineGDII',
        minWidth: 50,
        sorter: false,
        key: 'scoreOfflineGDII',
        render: (text, record) => (
          <Form.Item
            className='form-item'
            name={`scoreOfflineGDII`}
            // style={{ position: 'relative' }}
          >
            <Input
              defaultValue={text}
              className='enterscore-input'
              style={{
                marginTop: 25,
                textAlign: 'center',
                minWidth: 40,
                // position: 'absolute',
                // left: '50%' /* Căn giữa theo chiều ngang */,
                // transform: 'translateX(-50%)' /* Để căn giữa theo chiều ngang */,
              }}
              type='number'
              min={MIN}
              max={MAX}
              step={0.1}
              onBlur={handleBlur}
            />
          </Form.Item>
        ),
      },
      {
        title: 'Ghi chú',
        minWidth: 170,
        dataIndex: 'notes',
        key: 'notes',
        sorter: false,
        render: (text, record) => (
          <Form.Item className='form-item' name={`notes`}>
            <Input
              defaultValue={text}
              className='enterscore-input'
              style={{
                marginTop: 25,
                minWidth: 150,
              }}
            />
          </Form.Item>
        ),
      },
    ],
  };

  const dataSource = [
    {
      fullName: student?.fullName,
      email: student?.email,
      khoa: student?.khoa,
      nganh: student?.nganh,
      scoreOfflineGDI: student?.scoreOfflineGDI,
      scoreOfflineGDII: student?.scoreOfflineGDII,
      notes: student?.notes,
    },
  ];

  const handleFinish = (values) => {
    const parameters = {
      studentId: student?.id,
      scoreOfflineGDI:values?.scoreOfflineGDI ? handleScore(values?.scoreOfflineGDI) : 0,
      scoreOfflineGDII:values?.scoreOfflineGDII? handleScore(values?.scoreOfflineGDII) : 0,
      notes: values?.notes || '',
      createdBy: loggedInUser?.id,
      classId,
    };
    query('p_VIMARU_ThongKeBaiTap_SummaryOffline_InsertUpdate', parameters)
      .then((res) => {
        // handlSendNotificationToStudent({
        //   ...parameters,
        //   studentCode: student?.maSV,
        //   studentName: student?.fullName,
        // });
        message.success('Cập nhật điểm thành công');
        queryClient.invalidateQueries('p_VIMARU_STUDENTS_GetStudents_OrStudent_ByClassId');
        setVisibleEnterModal(false);
      })
      .catch((error) => message.error('Chức năng này đang cập nhật'));
  };
  const handlSendNotificationToStudent = (data) => {
    const buildBodyNoti = ({ studentCode, studentName, diemGK, diemCK }) => {
      return `<div>
            <strong>Hệ thống Vimaru EnglishCity xin thông báo kết quả kiểm tra môn AVCB như sau:</strong>
          </div>
          <div style='padding: 10px 0px'>
            <div>- Họ tên sinh viên: ${studentName}</div>
            <div>- Mã sinh viên: ${studentCode}</div>
            <div>- Điểm kiểm tra giữa kỳ: ${diemGK}</div>
            <div>- Điểm kiểm tra cuối kỳ: ${diemCK}</div>
          </div>
          <div>
            <strong>Lưu ý:</strong> 
            <i>
              Với những sinh viên đã nộp chứng chỉ tiếng Anh cho phòng đào tạo thì điểm quy đổi sẽ được ghi nhận trên hệ
              thống của Phòng đào tạo.
            </i>
          </div>`;
    };
    sendNotificationToUser({
      title: 'Thông báo kết quả kiểm tra',
      body: buildBodyNoti({
        studentCode: data.studentCode,
        studentName: data.fullName,
        diemGK: data.scoreOfflineGDI === 0 ? '' : data.scoreOfflineGDI,
        diemCK: data.scoreOfflineGDII === 0 ? '' : data.scoreOfflineGDII,
      }), // HTML string
      classId: 0,
      avatar: loggedInUser?.avatarUrl ?? '',
      name: loggedInUser?.fullName,
      icon: '',
      color: colors.theme.button,
      createdBy: loggedInUser?.id,
      receiverId: data.studentId,
      type: 3, //0: Common , 1: Support, 2: Booking, 3: Other
    });
  };
  return (
    <Modal
      width={'90vw'}
      style={{ minHeight: '80vh' }}
      centered
      open={visibleEnterModal}
      onCancel={() => setVisibleEnterModal(false)}
      title={'Điểm thi của ' + settings.studentPronouns1}
      onOk={() => form.submit()}
    >
      <div style={{ display: 'flex', justifyContent: 'end', fontSize: 14 }}>
        <i>
          Giải thích: "<strong>ĐTGK</strong>" - Điểm thi giữa kì; "<strong>ĐTCK</strong>" - Điểm thi cuối kì.
        </i>
      </div>
      <Card className='mb-0'>
        <CardBody style={{ padding: 0 }}>
          <Form form={form} onFinish={(values) => handleFinish(values)}>
            {/* <table className='input-table'>
              <tr>
                <td>{'Tên ' + settings.studentPronouns1}</td>
                <td>Email</td>
                <td>Khoa</td>
                <td>Ngành</td>
                <td>Điểm X</td>
                <td>Điểm Y</td>
                <td>Ghi chú</td>
              </tr>
              <tr>
                <td>{student?.fullName}</td>
                <td>{student?.email}</td>
                <td>{student?.khoa}</td>
                <td>{student?.nganh}</td>
                <td>
                  <Form.Item className='form-item' name='scoreOfflineGDI'>
                    <input
                      className='enterscore-input'
                      type='number'
                      min={MIN}
                      max={MAX}
                      step={0.1}
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item className='form-item' name='scoreOfflineGDII'>
                    <input
                      className='enterscore-input'
                      type='number'
                      min={MIN}
                      max={MAX}
                      step={0.1}
                      onBlur={handleBlur}
                    />
                  </Form.Item>
                </td>
                <td>
                  <Form.Item className='form-item' name='notes'>
                    <input className='enterscore-input' />
                  </Form.Item>
                </td>
              </tr>
            </table> */}
            <DynamicTable initialTableData={columns} dataSource={dataSource} pagination={false} />
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
}
