import { Button, Modal } from 'antd';
import colors from 'constants/colors';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import Icon from '../../ExercisePopup/components/Icon';
import SR from './components/SpeechRecognition';
import { ResultProps, SpeechRecognitionResultProps } from './types';
import { WorksheetTypesProps } from '../../type';

const SpeechRecognition = ({ data, type,againAnswer, isDisableTest}:WorksheetTypesProps) => {
  const [result, setResult] = useState<ResultProps>(null);
  const [state, setState] = useState([]);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [content, setContent] = useState<string>('');
  const [isCliclOpenModal, setClickCopenModal] = useState<number>(0);
  const [speechRecognitionResult, setSpeechRecognitionResult] = useState<SpeechRecognitionResultProps>(null);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    setState(formatData(againAnswer?.userData));
  }, [data, againAnswer]);

  useEffect(() => {
    Object.assign(type, {
      submit: () => {
        // handle submit
        if (state.length === 0) return {};
        // tính điểm trung bình
        const res = handleResult(state);
        // setResult(res);
        // setIsDone(true);
        return { speechRecognitionResult: res };
      },
      tryAgain: () => {
        setState(formatData(state));
        // setResult(null);
        // setIsDone(false);
      },
      setResult,
      setIsDone,
    });
    return () => {};
  }, [data, state, type]);

  //data đang là mảng phẳng, cần format về dạng gom nhóm để render
  const formatData = (answers?: any[]) => {
    if (!data) {
      return [];
    }
    const floatArray = answers ? _.flatten(answers) : [];
    //Nếu có kết quả làm bài thì tìm input tương ứng để lấy đáp án
    const newData = answers
      ? data.map((item: any) => {
          const userAnswer = floatArray.find((i) => i.left === item.left && i.top === item.top);
          return userAnswer ?? item;
        })
      : _.cloneDeep(data);
    return newData;
  };

  const showModal = (text: string, index: number) => {
    setModalVisible(true);

    let numberClick = isCliclOpenModal;
    setContent(text);
    setSelectedIndex(index);
    if (index !== selectedIndex) setClickCopenModal((numberClick += 1));
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
    // Học sinh chưa nộp bài, đã làm trước đó, xem lại vẫn còn kết quả, có thể làm lại.
    // Nếu học sinh đã nộp bài, thì khi giáo viên xem bài, không bị setState lại.
    if (!isDisableTest && speechRecognitionResult) {
      setState((pre) => {
        const arrayState = Array.from(pre);
        if (isCliclOpenModal <= state.length) {
          Object.assign(arrayState[selectedIndex], { userAnswer: speechRecognitionResult });
        }
        setSpeechRecognitionResult(null);
        return arrayState;
      });
    }
  };

  return (
    <div>
      {state.map((item, index) => {
        const { width, height, top, left, text, groupName, angle, isCorrect, mode } = item;
        let borderColor;
        if (isDisableTest) {
          if (againAnswer?.userData[index]?.userAnswer) {
            borderColor = colors.theme.success;
          } else borderColor = colors.theme.danger;
        }
        return (
          <div
            key={`input-${index}`}
            style={{
              backgroundColor: 'rgba(0, 128, 128, 0.1)',
              position: 'absolute',
              width,
              height,
              top,
              left,
              transform: `rotate(${angle}deg)`,
            }}
          >
            <Icon
              angle={angle}
              backgroundColor='#088da5'
              height={24}
              icon='fa fa-lightbulb'
              left={width - 12}
              top={-12}
              iconColor='white'
              width={24}
            />
            <Button
              type='primary'
              onClick={() => showModal(text, index)}
              style={{
                backgroundColor: '#e5f2f2',
                color: '#000',
                borderColor,
                borderWidth: 2,
                fontSize: 16,
                height,
                width,
              }}
            >
              {text}
            </Button>
          </div>
        );
      })}
      <Modal
        centered
        title='Speaking with A.I'
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        getContainer={false}
      >
        <SR
          isDone={isDisableTest}
          selectedIndex={selectedIndex}
          setSpeechRecognitionResult={setSpeechRecognitionResult}
          testSpeech={state}
          text={content}
          resultDataAnswer={againAnswer}
          isCompleted={isDisableTest}
        />
      </Modal>
    </div>
  );
};

export default SpeechRecognition;

const handleResult = (userData: any) => {
  const totalQ = userData.length;
  let count = 0;
  let resultString = 0;
  if (userData) {
    userData.forEach((item: any) => {
      let totalResultAnswer = 0;
      if (item.userAnswer) {
        count += 1;
        const userAnswer = item.userAnswer.pronunciationAssessment;
        const { accuracyScore, completenessScore, fluencyScore, pronunciationScore } = userAnswer;
        totalResultAnswer = (accuracyScore + completenessScore + fluencyScore + pronunciationScore) / 4;
        resultString += totalResultAnswer;
      }
    });
  }
  const percent = (count * 100) / totalQ;
  const star = percent / 20;

  return {
    complete: `${count}/${totalQ}`,
    percent,
    resultString: `${(resultString / 100).toFixed(2)}/${totalQ}`,
    star,
    userData,
  };
};
