import { useQuery } from 'react-query';

import { add, get, getByEntityId, query, remove, removeById, update } from 'helpers/QueryHelper';
const entityName = 'Category';
const subModuleName = 'METADATA';
const moduleName = 'VIMARU';
export const InsertData = (parameters) => {
  return add(entityName, parameters, subModuleName, moduleName);
};
export const RemoveData = (parameters) => {
  return remove(entityName, parameters, subModuleName, moduleName);
};
export const UpdateData = (entityId, parameters) => {
  return update(entityName, entityId, parameters, subModuleName, moduleName);
};
export const getDataById = (id) => {
  return getByEntityId(entityName, '*', id, subModuleName, moduleName);
};
export const getData = () => {
  return useQuery('VIMARU_METADATA_Category', () => {
    return get(entityName, '*', '', 'CreatedDate', subModuleName, moduleName);
  });
};
