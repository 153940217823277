import { fetchSettings } from 'modules/Settings/actions';
import React from 'react'
import { useDispatch } from 'react-redux';

type Props = {
    children:any
}

const ConfigSettingsContainer = ({children}: Props) => {
    const dispatch = useDispatch();
  
  //Lấy setting về lưu localStorage
    React.useEffect(() => {
      dispatch(fetchSettings());
    }, []);
  return (
    <div>{children}</div>
  )
}

export default ConfigSettingsContainer