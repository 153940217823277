import CodesExam from 'pages/Teacher/CodesExam/index';
import Preview from 'pages/Teacher/PTTest/Preview';
import React from 'react';
import { HiOutlinePresentationChartBar } from 'react-icons/hi';
import { MdDoneOutline, MdGroup as ClassIcon, MdClass } from 'react-icons/md';
import { SiGoogleclassroom } from 'react-icons/si';
import { IoLibraryOutline } from 'react-icons/io5';
import IndexImportQuestion from 'pages/Administration/ImportQuestion';

export default [
  {
    path: '/admin/presentations',
    name: 'Quản lý bài giảng',
    miniName: 'C',
    component: React.lazy(() => import('pages/Teacher/Presentations')),
    icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500' />,
    layout: '/pt',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
  },
  {
    collapse: true,
    path: '/admin/worksheets',
    name: 'Quản lý thư viện',
    miniName: 'C',
    icon: <IoLibraryOutline size={20} className='tw-text-green-600' />,
    roles: ['teacher', 'administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 4,
    state: 'lms/admin/worksheets',
    views: [
      {
        path: '/admin/worksheets/list/:filter',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/pt',
        exact: true,
        roles: ['teacher', 'administrator'],
        type: ['allowedRoutes'],
      },
      {
        path: '/admin/worksheets/list/quizcity-worksheet',
        name: 'BT Chấm tự động',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/pt',
        exact: true,
        roles: ['teacher', 'administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
      {
        path: '/admin/worksheets/list/quizcity-quiz',
        name: 'BT Giáo viên chấm',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/pt',
        exact: true,
        roles: ['teacher', 'administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },
  // {
  //   component: CodesExam,
  //   exact: true,
  //   icon: <SiGoogleclassroom size={20} className='tw-text-red-500' />,
  //   layout: '/pt',
  //   miniName: 'C',
  //   name: 'Quản lý mã tham gia test',
  //   path: '/admin/code-exams',
  //   roles: ['teacher'],
  //   showInSidebar: true,
  //   sortOrder: 2,
  //   type: ['allowedRoutes'],
  // },
  {
    path: '/admin/import-question',
    name: 'Quản lý câu hỏi',
    miniName: 'C',
    icon: 'fas fa-question text-green',
    component: IndexImportQuestion,
    layout: '/pt',
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 4,
  },
  {
    path: '/student/codeExam/presentations/:presentationId/slides/:slideId',
    component: React.lazy(() => import('pages/AmesOnline/index')),
    layout: '/pt',
    exact: true,
    roles: ['teacher', 'manager', 'administrator'],
    type: ['allowedRoutes'],
  },
  {
    path: '/student/codeExam/:code/preview',
    component: React.lazy(() => import('pages/Teacher/PTTest/Preview')),
    layout: '/pt',
    exact: true,
    roles: ['teacher', 'manager', 'administrator'],
    type: ['allowedRoutes'],
  },
];
