import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';

const getJsonDataFromSlide = (SlideId: string) => {
  const sqlCommand = 'p_VIMARU_GetJsonData_BySlideId';
  const parameters = { SlideId };
  const applicationName = settings.APPLICATION_NAME;
  return query(sqlCommand, parameters, applicationName);
};

const getFirstQuestionId = async (SlideId: string) => {
  const response = await getJsonDataFromSlide(SlideId);
  const firstQuestionId =
    response[0]?.jsonData[0]?.questions[0]?.quizData?.questions[0]?.id ??
    response[0]?.jsonData[0]?.questions[0]?.quizData?.questions[0]?.answers?.[0].id;
  if (!firstQuestionId) return null;
  return firstQuestionId;
};

export default getFirstQuestionId;
