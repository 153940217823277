import colors from 'constants/colors';
import LineTo from 'react-lineto';

type RenderLineToProps = {
  isDone: boolean;
  listBoolean: { [key: string]: boolean };
  userAnswers: string[];
  isHiddenShowColor?: boolean;
  timeExamSlide?: any;
};

export default function RenderLineTo({
  userAnswers,
  isDone,
  listBoolean,
  isHiddenShowColor,
}: RenderLineToProps) {
  if (!userAnswers) return null;

  return userAnswers.map((item, index) => {
    const [first, second] = item.split('-');
    let color = '#1890ff';
    if (isDone === true && listBoolean && !isHiddenShowColor) {
      const isCorrect = listBoolean[item];
      color = isCorrect ? colors.theme.success : colors.theme.danger;
    }
    
    return (
      <LineTo
        key={index}
        fromAnchor='center'
        toAnchor='center'
        from={`multiplematch-dot-${first}`}
        to={`multiplematch-dot-${second}`}
        within='match-container'
        borderColor={color}
        borderWidth={3}
        delay={500}
      />
    );
  });
}
