import colors from 'constants/colors';
import {
  CalendarOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { IS_BASAO, IS_LG, IS_LX, IS_MENARD, IS_TRONGDONG, IS_VIMARU } from 'constants/settings';
import { AiOutlineShop } from 'react-icons/ai';
import { BsBag } from 'react-icons/bs';
import i18next from 'i18next';

const phoneNumber = IS_LG
  ? {}
  : {
      title: i18next.t('phoneNumber'),
      key: 'phone',
      render: 'renderNoWrap',
      width: 135,
      style: {
        fontWeight: 400,
        color: colors.font,
      },
      prefix: <PhoneOutlined className='tw-mr-2' />,
    };
const classifyColumns = IS_LG
  ? [
      {
        title: i18next.t('Part'),
        key: 'shopName',
        render: 'renderNoWrap',
        width: 135,
        style: {
          fontWeight: 400,
          color: colors.font,
        },
        prefix: <AiOutlineShop className='tw-mr-2' />,
      },
    ]
  : IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX
  ? [
      {
        title: 'Bộ phận',
        key: 'shopName',
        render: 'renderNoWrap',
        width: 135,
        style: {
          fontWeight: 400,
          color: colors.font,
        },
        prefix: <AiOutlineShop className='tw-mr-2' />,
      },
      {
        title: 'Chức vụ',
        key: 'positionName',
        render: 'renderNoWrap',
        width: 135,
        style: {
          fontWeight: 400,
          color: colors.font,
        },
        prefix: <BsBag className='tw-mr-2' />,
      },
      {
        title: 'Ngày vào công ty',
        key: 'ngayVaoCongTy',
        width: 135,
        sort: false,
        render: 'renderDate',
        prefix: <CalendarOutlined className='tw-mr-2' />,
      },
    ]
  : [];

export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: IS_LG ? i18next.t('employeeCode') : IS_VIMARU ? 'Mã sinh viên' : i18next.t('userCode'),
      key: 'maSV',
      width: '1%',
      fixed: 'left',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: i18next.t('fullName'),
      key: 'fullName',
      fixed: 'left',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
      prefix: <UserOutlined className='tw-mr-2' />,
    },
    {
      title: 'Email',
      key: 'email',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
      },
      prefix: <MailOutlined className='tw-mr-2' />,
    },
    phoneNumber,

    // {
    //   title: 'Loại tài khoản',
    //   key: 'roleId',
    //   render: 'renderExpression',
    //   expression: `record.roleId === 1 ? "${settings.studentPronouns2}" : ${IS_VIMARU || IS_MENARD ? `record.roleId === 3 ? "Quản trị viên" :` : ''} "Giáo viên"`,
    //   width: 135,
    //   style: {
    //     fontWeight: 400,
    //     color: colors.font,
    //   },
    // },
    {
      title: i18next.t('gender'),
      sort: false,
      width: 80,
      key: 'sex',
      render: (_, record) => {
        return (
          <span>{record?.sex !== null ? (record?.sex === true ? i18next.t('male') : i18next.t('female')) : ''}</span>
        );
      },
    },
    ...classifyColumns,
    {
      title: i18next.t('Notes'),
      key: 'description',
      width: 80,
      render: (text, record) => {
        return <span>{record.note}</span>;
      },
      soft: false,
      style: {
        fontWeight: '400',
        color: colors.font,
      },
    },
    {
      title: i18next.t('Status'),
      key: 'status',
      sort: false,
      width: 100,
      render: (text, record) => {
        const { status } = record;
        return (
          <span className={`status__table__user ${status === 'ACTIVE' ? 'active' : 'deactive'}`}>
            {status === 'ACTIVE' ? <UnlockOutlined /> : <LockOutlined />} {status}
          </span>
        );
      },
      soft: false,
      style: {
        fontWeight: '500',
        color: colors.font,
      },
    },
    {
      title: i18next.t('table:userManagement.Created date'),
      key: 'createdDate',
      width: '1%',
      sort: false,
      render: 'renderDateTime',
      prefix: <CalendarOutlined className='tw-mr-2' />,
    },
  ],
};
