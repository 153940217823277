import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';

const editNotificationManagement = (
  Content: string,
  Id: number,
  Notes: string,
  NotificateCode: string,
  NotificationName: string,
  Status: boolean,
) => {
  const sqlCommand = 'p_QUIZCITY_EditNotificationManagement';
  const parameters = { Content, Id, Notes, NotificateCode, NotificationName, Status };
  const applicationName = settings.APPLICATION_NAME;
  return query(sqlCommand, parameters, applicationName);
};

export default editNotificationManagement;
