/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import style from './style.module.css';

// #region Tính điểm theo nhóm (sai 1 chỗ, vẫn tính điểm chỗ khác)
function handleResultByGroup(state) {
  const convention = {
    yes: true,
    no: false,
  };

  const newState = state.map((group) => {
    return group.map((item) => {
      const { text } = item;
      return Object.assign(item, { isCorrect: convention[text] });
    });
  });

  // #region Tính điểm.
  const resultByGroup = newState.map((item) => {
    // #region Tính điểm kiểu toán, đúng hết là đúng, các trường hợp còn lại đều sai.
    const selectedUser = item.filter((x) => x.isSelected);
    const countTrueFalse = _.countBy(selectedUser, 'isCorrect');
    const totalByGroup = _.countBy(item, 'isCorrect').true;
    // Hint: muốn tính điểm tương đối thì xóa: && countTrueFalse.true === totalByGroup
    const correctByGroup =
      countTrueFalse.false === undefined && countTrueFalse.true === totalByGroup ? countTrueFalse.true : 0;
    // #endregion

    // #region Tính điểm kiểu tiếng anh, đúng chỗ nào, tính chỗ đó.
    // const correctByGroup = item.filter((x) => x.isSelected && x.isCorrect).length;
    // const totalByGroup = _.countBy(item, 'isCorrect').true;
    // #endregion
    const score = correctByGroup / totalByGroup;
    // #region Kiểm tra câu hiện tại đã làm chưa?, không quan tâm đúng sai. Phía dưới dùng.
    const completedByGroup = item.some((x) => x.isSelected);
    // #endregion
    return { groupName: item[0].groupName, score, completed: completedByGroup };
  });
  // #endregion

  const correct = resultByGroup
    .reduce((previousValue, currentValue) => previousValue + currentValue.score, 0)
    .toFixed(2);
  const total = resultByGroup.length;

  const percent = parseInt((correct * 100) / total);
  const resultString = `${correct}/${total}`;
  const star = percent / 20;

  // #region Đếm số câu đã làm
  const countSelected = resultByGroup.filter((x) => x.completed).length;
  const complete = `${countSelected}/${total}`;
  // #endregion

  return { userData: newState, percent, resultString, star, complete };
}
// #endregion

// Tính điểm theo vị trí.
//
const filterSelect = (groupItems) => {
  let limit = 0;
  let selectedCount = 0;
  groupItems.forEach((item) => {
    const { text, isSelected } = item;

    if (text === 'yes' || text === 'no') limit++;
    if (isSelected === true) selectedCount++;
  });

  if (selectedCount > limit) {
    const arraySelected = groupItems.filter((x) => x.isSelected === true);
    const minTime = Math.min(...arraySelected.map((x) => x.time));
    //
    groupItems.find((x) => x.time === minTime).isSelected = false;
  }

  return groupItems;
};

function MultipleChoice({ type, data, isDisableTest, againAnswer, isHiddenShowColor }) {
  const [state, setState] = useState([]);
  const [result, setResult] = useState();
  const [isDone, setIsDone] = useState(false);
  //
  useEffect(() => {
    Object.assign(type, {
      submit: () => {
        if (data?.length === 0) return {};
        // const res = calculateResult(state);
        const res = handleResultByGroup(state);
        // setIsDone(true);
        return { multipleChoiceResult: res };
      },
      tryAgain: () => {
        setState(formatData(state));
        // setResult();
        setIsDone(false);
      },
      setResult,
      setIsDone,
    });
    return () => {};
  }, [data?.length, state]);
  //
  useEffect(() => {
    setState(formatData(againAnswer?.userData));
  }, [data?.length, againAnswer]);

  //Render lại ==> cần lấy lại kết quả làm bài nếu có
  const formatData = (answers) => {
    if (!data) {
      return [];
    }
    const answersFlatten = _.flatMapDeep(answers) ?? [];
    const newData = data.map((group, index) => {
      return group.map((item) => {
        //dùng res để lấy kết quả cũ (nếu có) - fix lỗi submit kết quả của bài khác
        //find theo vị trí thì dù có bị cache kết quả cũng không sao
        const res = answersFlatten?.find((i) => i.left === item.left && i.top === item.top);
        return res ?? item;
      });
    });
    return newData;
  };

  const handleClick = (indexGroup, index) => {
    if (isDone) return;
    setState((pre) => {
      const array = _.cloneDeep(pre);
      const item = array[indexGroup][index];
      const { isSelected } = item;
      Object.assign(item, { isSelected: !isSelected, time: Date.now() });
      //
      filterSelect(array[indexGroup]);
      return array;
    });
  };

  if (!data) return null;

  return state?.map((group, indexGroup) => {
    return group.map((item, index) => {
      const { width, height, top, left, angle, isSelected, isCorrect, text } = item;
      let viewColor = '';

      if (isDisableTest && !isHiddenShowColor && isSelected) {
        if (isCorrect) {
          viewColor = style.MultipleTrue;
        } else if (isCorrect === false) {
          viewColor = style.MultipleFalse;
        }
      }

      return (
        <div
          key={`button-${index}`}
          onKeyPress={() => {}}
          onClick={() => {
            if (isDisableTest) return null;
            handleClick(indexGroup, index);
          }}
          className={`${style.boxMultiplechoice}
          ${isSelected ? style.boxMultiplechoiceClick : style.boxMultiplechoiceHover} 
          ${viewColor}
          `}
          style={{
            position: 'absolute',
            width,
            height,
            top,
            left,
            transform: `rotate(${angle}deg)`,
            cursor: isDisableTest ? 'no-drop' : 'pointer',
            // border: `2px dotted ${color}`,
          }}
        />
      );
    });
  });
}

MultipleChoice.propTypes = {
  data: PropTypes.array.isRequired,
};

export default MultipleChoice;
