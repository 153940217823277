/* eslint-disable react/prop-types */
import { Col, DatePicker, Form, Input, InputNumber, message, Row, Space } from 'antd';
import { useParams } from 'react-router-dom';
import { AssignmentType, LoggedInUserType } from 'types';
import { useQuery } from 'react-query';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import moment from 'moment';

import notificationWithIcon from 'components/Notification/notificationWithIcon';
import { add, queryFirst } from 'helpers/QueryHelper';
import { quillFormats, quillModules } from 'constants/quill';
import withAuthenticator from 'HOCs/withAuthenticator';
import colors from 'constants/colors';
import './styles.css';
import SelectClassGroup from '../SelectClassGroup';
import i18next from 'i18next';

type CreateAssignmentProps = {
  loggedInUser?: LoggedInUserType;
  entityId: string;
  worksheetFile: { id: string; tags: string; imgURL: string };
  submitRef: any;
};
//
// REACT COMPONENT
const AddOrEditAssignment = ({ loggedInUser, entityId, worksheetFile, submitRef }: CreateAssignmentProps) => {
  const { assignmentId } = useParams<{ classId: string; assignmentId: string }>();

  const CLASSWORK_ASSIGNMENT_GETBYID_QUERY_KEY = ['p_SACHSO_CLASSWORK_Assignments_GetById', assignmentId];
  const { data: assignment } = useQuery<AssignmentType>(
    CLASSWORK_ASSIGNMENT_GETBYID_QUERY_KEY,
    () => {
      return queryFirst('p_SACHSO_CLASSWORK_Assignments_GetById', { id: assignmentId });
    },
    {
      enabled: assignmentId !== undefined,
    },
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValues = {
    title: assignment?.title ?? '',
    instructions: assignment?.instructions ?? '',
    dueDate: assignment?.dueDate ? moment(assignment.dueDate) : null,
    color: assignment?.color ?? colors.font,
    // icon: assignment?.icon ?? colors.font,
    assignmentType: 'ASSIGNMENT',
    points: assignment?.points ?? 10,
  };
  //
  const [selectedClass, setSelectedClass] = useState([]);
  //
  const [form] = Form.useForm();

  const assignExerciseToStudent = async (values: any, classes: any, index: number) => {
    // INSERT
    const parameters = {
      ...values,
      dueDate: moment.isMoment(values.dueDate) ? values.dueDate.format('YYYY-MM-DD HH:mm:ss') : null,
      assignmentType: 'ASSIGNMENT',
      classId: classes[index],
      createdBy: loggedInUser?.id,
      attachmentId: worksheetFile.id,
      settings: JSON.stringify({ tags: worksheetFile.tags }),
    };

    const response = await add('Assignments', parameters, 'CLASSWORK');
    if (response.id) {
      if (classes.length > index + 1) {
        await assignExerciseToStudent(values, classes, index + 1);
      }
    } else {
      message.error(i18next.t('popconfirm.oops'));
      return false;
    }
    return true;
  };

  const onFinish = async (values: any) => {
    if (selectedClass.length < 1) {
      notificationWithIcon(
        'warning',
        i18next.t('Warning'),
        i18next.t('please choose', { field: i18next.t('Class').toLowerCase() }),
      );
      return true;
    }
    const res = await assignExerciseToStudent(values, selectedClass, 0);
    if (res) {
      notificationWithIcon(
        'success',
        i18next.t('Warning'),
        i18next.t('lecture:Presentation.Successful assignment of assignments'),
      );
    }
    return false;
  };

  React.useImperativeHandle(submitRef, () => ({
    submit: () => {
      const object = form.getFieldsValue();
      const isNullOrEmpty = Object.values(object)
        .slice(0, 3)
        .some((x) => !x);
      form.submit();
      if (isNullOrEmpty || selectedClass.length < 1) {
        return false;
      }
      return true;
    },
  }));

  return (
    <div className='px-2'>
      <Form form={form} layout='vertical' onFinish={onFinish} requiredMark={false} initialValues={initialValues}>
        <Form.Item
          label={i18next.t('Title')}
          name='title'
          rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('Title') }) }]}
        >
          <Input />
        </Form.Item>
        <Row gutter={[16, 0]}>
          <Col>
            <Form.Item label={i18next.t('Score')} name='points'>
              <InputNumber min={0} max={10} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={i18next.t('lecture:Presentation.Submission date')}
              name='dueDate'
              rules={[
                {
                  required: true,
                  message: i18next.t('please enter', { field: i18next.t('lecture:Presentation.Submission date') }),
                },
              ]}
            >
              <DatePicker
                disabledDate={(current) => {
                  return current && current < moment().endOf('day');
                }}
                showTime
                format='DD/MM/YYYY HH:mm'
                getPopupContainer={(trigger) => {
                  return trigger.parentElement;
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className='quill-update-assignment-editor'>
          <Form.Item label={i18next.t('lecture:Presentation.Requirements / Guide')} name='instructions'>
            <ReactQuill theme='snow' modules={quillModules} formats={quillFormats} />
          </Form.Item>
        </div>
      </Form>
      <Space direction='vertical'>
        <Space size={[8, 16]} wrap>
          <SelectClassGroup id={loggedInUser.id} setSelectedClass={setSelectedClass} />
        </Space>
      </Space>
    </div>
  );
};

AddOrEditAssignment.defaultProps = {
  loggedInUser: null,
};

export default withAuthenticator(AddOrEditAssignment);
