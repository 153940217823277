import { Button, Col, Collapse, Progress, Row, Spin, notification } from 'antd';
import Card from 'components/Containers/Card';
import SimpleContainer from 'components/Containers/SimpleContainer';
import StudentContainer from 'components/Containers/StudentContainer';
import NoData from 'components/NoData';
import RdIcon from 'components/functions/rdSlideIcons';
import colors from 'constants/colors';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import _ from 'lodash';
import getFirstQuestionId from 'modules/Presentation/api/getFirstQuestionId';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { AiFillCheckCircle, AiFillLock, AiFillPlayCircle, AiFillStar } from 'react-icons/ai';
import { FiCheckSquare, FiEdit, FiFileText, FiVideo } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import color from '../../../constants/colors';
import SwimlaneLG from '../Swimlane/SwimlaneLG';
import CoachingForm from './components/CoachingForm/CoachingForm';
import CoachingGuideModal from './components/CoachingGuideModal';
import LGHeader from './components/LGHeader';
import Slide from './components/Slide';
import LearningCommitmentModal from './components/studentHome/LearningCommitmentModal';
import './styles.css';
const { Panel } = Collapse;

const images = [require('assets/img/home/FUJI3297.JPG').default];

function LGhome({ loggedInUser }) {
  const selectedFolder = useSelector((state) => state.sidebarReducer.selectedFolder);
  const KEY_PRESENTATIONS = ['p_QUIZCITY_Get_Folders_Presentation_V3', loggedInUser?.id, selectedFolder?.id];
  const [viewMode, setViewMode] = useState(false);
  const [coachingGuideModalVisible, setCoachingGuideModalVisible] = useState(false);
  const [reportHeader, setReportHeader] = useState({}); // lấy kết quả từ header truyền qua coachingForm
  const history = useHistory();
  const [recordImages, setRecordImages] = React.useState(images);
  const moveToSlide = async (presentationId, slide) => {
    const questionId = await getFirstQuestionId(slide.id ?? slide?.slideId);

    if (slide.status !== 'Locked') {
      if (slide.type === 'Quiz')
        return history.push(
          `/${settings.PATHNAME}/student/presentations/${presentationId}/slides/${
            slide.id ?? slide?.slideId
          }/questions/${questionId}`,
        );
      return history.push(
        `/${settings.PATHNAME}/student/presentations/${presentationId}/slides/${slide.id ?? slide?.slideId}`,
      );
    }
  };

  const {
    data: folders,
    isLoading,
    isSuccess,
  } = useQuery(
    KEY_PRESENTATIONS,
    () => {
      return query('p_QUIZCITY_Get_Folders_Presentation_V3', {
        studentId: loggedInUser?.id,
        folderId: selectedFolder?.id || '00000000-0000-0000-0000-000000000000',
      });
    },
    { enabled: !!selectedFolder?.id && selectedFolder?.id !== '00000000-0000-0000-0000-000000000000' },
  );
  const [selectedFolderId, setSelectedFolderId] = React.useState(null);

  const KEY_CURRENT_SLIDE = ['p_QUIZCITY_Slides_Current', loggedInUser?.id, selectedFolder?.id];

  const { data: currentSlide, isLoading: isCurrentSlideLoading } = useQuery(
    KEY_CURRENT_SLIDE,
    () => {
      if (loggedInUser.role === 'student') {
        return query('p_QUIZCITY_Slides_Current', { studentId: loggedInUser?.id, folderId: selectedFolder?.id });
      } else {
        return;
      }
    },
    { enabled: !!selectedFolder?.id && selectedFolder?.id !== '00000000-0000-0000-0000-000000000000' },
  );

  const carouselImages = [];
  for (let i = 1; i <= 5; i++) {
    let href = '';
    switch (i) {
      case 1:
        href = 'https://menard.vn/collagen/';
        break;
      case 2:
        href = 'https://menard.vn/collagen/';
        break;
      case 3:
        href = 'https://shop.menard.vn/duong-da/dong-san-pham-duong-da/authent-dong-san-pham-duong-da/';
        break;
      case 4:
        href = 'https://shop.menard.vn/trang-diem/embellir-trang-diem/';
        break;
      case 5:
        href = 'https://shop.menard.vn/duong-da/dong-san-pham-duong-da/saranari-dong-san-pham-duong-da/';
        break;
    }
    carouselImages.push(
      <a href={href} target='_blank'>
        <img
          key={`carousel-image-${i}`}
          style={{ width: '100%', objectFit: 'cover', borderRadius: '0.5rem' }}
          className='carousel-image'
          src={`https://cloud.softech.vn/storage/Data/MENARD/Carousel/carousel${i}.jpg`}
        />
      </a>,
    );
  }
  const currentSlideId = (presentation) => {
    let id = presentation?.slides?.[0]?.id;
    presentation?.slides?.forEach((slide) => {
      if (slide?.status === 'Playing') id = slide?.id;
    });
    return id;
  };

  // Vị trí thư mục, lesson đang học.
  const folderIndex = folders?.findIndex((x) => x.id === currentSlide?.[0]?.folderId);
  let lessonIndex = -1;
  if (folderIndex > -1) {
    lessonIndex = folders[folderIndex]?.lessons?.findIndex((x) => x.id.toLowerCase() === currentSlide?.[0]?.lessionId);
  }

  useEffect(() => {
    if (folders?.length === 0 && !isLoading) {
      notification.error({
        message: i18next.t('home:notify.You are not in any course or the current course has no lessons'),
        duration: 5,
      });
    }
  }, [selectedFolder, folders, isLoading]);
  useEffect(() => {
    query('p_QUIZCITY_ExamImage_Category_Select').then((res) => {
      const sortRecord = res.sort((a, b) => a.sortOrder - b.sortOrder);
      const mapArr = sortRecord.map((item) => item.imageUrl);

      setRecordImages(mapArr.length === 0 ? images : mapArr);
    });
  }, []);
  return (
    <>
      <LGHeader selectedFolder={selectedFolder} setReportHeader={setReportHeader} />
      <StudentContainer>
        <Container fluid className='mt--6' style={{ marginBottom: 102 }}>
          {/* Current Lecture */}
          <Card
            className={`${settings.IS_LG ? 'lg-card-container' : ''}`}
            style={{ justifyContent: 'center' }}

            // extra={
            //   <Switch
            //     checkedChildren={<UnorderedListOutlined />}
            //     unCheckedChildren={<TableOutlined />}
            //     onChange={(e) => setViewMode(e)}
            //   />
            // }
          >
            {selectedFolder && currentSlide && currentSlide?.length !== 0 ? (
              <Row gutter={[24, 24]} style={{ alignItems: 'center' }}>
                <Col>
                  <h3
                    className='mb-0 tw-text-base null'
                    style={{ color: settings.IS_LG ? color.theme.button : '#32325d' }}
                  >
                    {settings.IS_LG ? 'CURRENT LECTURE' : 'Current Lecture'}
                  </h3>
                </Col>
                <Col>
                  <img alt='' style={{ width: 32 }} src='https://cdn-icons-png.flaticon.com/512/7341/7341822.png' />
                </Col>
                <Col flex={1} style={{ paddingTop: 16 }}>
                  <Link
                    style={{ fontWeight: '700', color: colors.theme.button }}
                    // to={`/lms/student/presentations/${
                    //   currentSlide?.[0]?.presentationId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id
                    // }/slides/${
                    //   currentSlide?.[0]?.slideId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id
                    // }`}
                    to='#'
                    onClick={() => {
                      query('p_QUIZCITY_CheckPermissonLearning', {
                        slideId:
                          currentSlide?.[0]?.slideId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id,
                        studentId: loggedInUser?.id,
                      }).then((res) => {
                        if (res?.[0]?.warningMessage) {
                        } else {
                          history.push(
                            `/${settings.PATHNAME}/student/presentations/${
                              currentSlide?.[0]?.presentationId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id
                            }/slides/${
                              currentSlide?.[0]?.slideId ??
                              folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id
                            }`,
                          );
                        }
                      });
                    }}
                  >
                    {`${currentSlide?.[0]?.folderTitle} >
                      ${currentSlide?.[0]?.lessonTitle} >
                      ${currentSlide?.[0]?.presentationTitle}`}
                  </Link>
                  <ul style={{ marginLeft: 0 }}>
                    <li style={{ marginBlock: 4 }}>
                      <div className='tw-items-center' style={{ display: 'flex', flexDirection: 'row' }}>
                        {currentSlide?.[0]?.type === 'Video' && <FiVideo className='tw-mr-2' />}
                        {currentSlide?.[0]?.type === 'WorkSheet' && <FiFileText className='tw-mr-2' />}
                        <div>
                          {/* {currentSlide?.[0]?.slideTitle ??
                            folders?.[0]?.lessons?.[0]?.presentations?.[0].slides?.[0].title} */}
                          {currentSlide?.[0]?.slideTitle}
                        </div>
                        <AiFillPlayCircle color={colors.gold} size={20} className='tw-ml-2' />
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <Button
                    type='primary'
                    onClick={() => {
                      query('p_QUIZCITY_CheckPermissonLearning', {
                        slideId:
                          currentSlide?.[0]?.slideId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id,
                        studentId: loggedInUser?.id,
                      }).then((res) => {
                        if (res?.[0]?.warningMessage) {
                        } else {
                          history.push(
                            `/${settings.PATHNAME}/student/presentations/${
                              currentSlide?.[0]?.presentationId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id
                            }/slides/${
                              currentSlide?.[0]?.slideId ??
                              folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id
                            }`,
                          );
                          moveToSlide(
                            currentSlide?.[0]?.presentationId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id,
                            currentSlide?.[0],
                          );
                        }
                      });
                    }}
                  >
                    Continue
                  </Button>
                  {/* </Link> */}
                </Col>
              </Row>
            ) : isCurrentSlideLoading ? (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            ) : (
              <NoData />
            )}
          </Card>
        </Container>
      </StudentContainer>
      <SimpleContainer>
        <Card
          // title={`LEARNING JOURNEY - ${selectedFolder?.name}`}
          title={settings.IS_LG ? 'LEARNING JOURNEY' : `LEARNING JOURNEY - ${selectedFolder?.name}`}
          // extra={
          //   <Switch
          //     checkedChildren={<TableOutlined />}
          //     unCheckedChildren={<UnorderedListOutlined />}
          //     onChange={(e) => setViewMode(!viewMode)}
          //   />
          // }
        >
          {isLoading && selectedFolder && (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          )}
          {!selectedFolder ? (
            <NoData></NoData>
          ) : !viewMode ? (
            folders &&
            folders.length !== 0 &&
            (<SwimlaneLG folders={folders} setCoachingGuideModalVisible={setCoachingGuideModalVisible} />)
          ) : (
            <Row>
              <Col span={24}>
                {isSuccess && (
                  <Collapse
                    // defaultActiveKey={[folders?.[folderIndex > -1 ? folderIndex : 0]?.id]}
                    style={{ marginBottom: 24 }}
                  >
                    {folders?.map((folder, folderIndex) => {
                      return (
                        <Panel
                          header={
                            <div>
                              {/* <div style={{ color: '#8898AA', fontSize: 10 }}>ANH VĂN CƠ BẢN 1</div> */}
                              <div>{folder.title}</div>
                            </div>
                          }
                          key={folder.id}
                          style={{ backgroundColor: 'white' }}
                        >
                          <Collapse
                            onChange={(e) => {
                              if (e[0]) setSelectedFolderId(e[0]);
                            }}
                          >
                            {/* <Collapse defaultActiveKey={[folder?.lessons?.[lessonIndex > -1 ? lessonIndex : 0]?.id]}> */}
                            {folder?.lessons?.map((lesson, lessonIndex) => {
                              return (
                                <Panel
                                  key={lesson.id}
                                  style={{ backgroundColor: 'white' }}
                                  header={
                                    <div className='d-flex tw-items-center'>
                                      <img
                                        alt=''
                                        className='tw-mr-2'
                                        style={{ width: 32 }}
                                        src='https://cdn-icons-png.flaticon.com/512/7341/7341949.png'
                                      />
                                      <span
                                        className='tw-uppercase'
                                        style={{ fontWeight: '600', color: colors.primary }}
                                      >
                                        {`${lesson.title}`}
                                      </span>
                                    </div>
                                  }
                                >
                                  <div style={{ marginLeft: 40 }}>
                                    {lesson?.presentations?.map((presentation, presentationIndex) => {
                                      const isFirstPresentation =
                                        folderIndex === 0 && lessonIndex === 0 && presentationIndex === 0;
                                      return (
                                        <Row
                                          key={presentation.id}
                                          gutter={[24, 24]}
                                          style={{ marginTop: presentationIndex > 0 ? 12 : 0 }}
                                        >
                                          <Col>
                                            <img
                                              alt=''
                                              style={{ width: 32 }}
                                              src='https://cdn-icons-png.flaticon.com/512/7341/7341822.png'
                                            />
                                          </Col>
                                          <Col style={{ flex: 1 }}>
                                            {/* {_.every(presentation?.slides, (x) => x.status === 'Locked') && */}
                                            {_.every(presentation?.slides, (x) => x.status === 'Locked') &&
                                              !isFirstPresentation && (
                                                <span
                                                  style={{ fontWeight: '600', color: colors.gray }}
                                                  to={`/${settings.PATHNAME}/student/presentations/${
                                                    presentation.id
                                                  }/slides/${currentSlideId(presentation)}`}
                                                >
                                                  {presentation.title}
                                                </span>
                                              )}
                                            {(_.some(
                                              presentation.slides,
                                              (x) => x.status === 'Completed' || x.status === 'Playing',
                                            ) ||
                                              isFirstPresentation) && (
                                              <Row
                                                className='tw-items-center'
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  justifyContent: 'center',
                                                  borderBottom: '1px solid whitesmoke',
                                                }}
                                              >
                                                <Col span={12}>
                                                  <Link
                                                    style={{ fontWeight: '600', color: colors.primary }}
                                                    to={`/${settings.PATHNAME}/student/presentations/${
                                                      presentation.id
                                                    }/slides/${currentSlideId(presentation)}`}
                                                  >
                                                    {presentation.title}
                                                  </Link>
                                                </Col>
                                                <Col span={3} style={{ textAlign: 'center' }}>
                                                  <span>Status</span>
                                                </Col>
                                                <Col span={3} style={{ textAlign: 'center' }}>
                                                  <span>Skill</span>
                                                </Col>
                                                <Col span={3} style={{ textAlign: 'center' }}>
                                                  <span>Complete</span>
                                                </Col>
                                                <Col span={3} style={{ textAlign: 'center' }}>
                                                  <span>Score</span>
                                                </Col>
                                              </Row>
                                            )}
                                            <ul style={{ marginLeft: 0 }}>
                                              {presentation?.slides?.map((slide) => {
                                                return (
                                                  <li key={slide.id} style={{ marginBlock: 4 }} className='slide-row'>
                                                    <Row
                                                      className='tw-items-center'
                                                      style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center',
                                                        borderBottom: '1px solid whitesmoke',
                                                      }}
                                                    >
                                                      {/* CỘT TIÊU ĐỀ */}
                                                      <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                                        {/* {renderIcon(slide)} */}
                                                        {slide.type === 'Video' && <FiVideo className='tw-mr-2' />}
                                                        {slide.type === 'WorkSheet' && <FiEdit className='tw-mr-2' />}
                                                        {slide.type === 'Word' && <FiFileText className='tw-mr-2' />}
                                                        {slide.type === 'Quiz' && <FiCheckSquare className='tw-mr-2' />}
                                                        {slide.status !== 'Locked' && (
                                                          <>
                                                            <span
                                                              style={{ color: colors.black, cursor: 'pointer' }}
                                                              onClick={() => {
                                                                moveToSlide(presentation?.id, slide);
                                                              }}
                                                            >
                                                              {slide.title}
                                                            </span>
                                                          </>
                                                        )}
                                                        {slide.status === 'Locked' && <span>{slide.title}</span>}
                                                      </Col>
                                                      {/* CỘT TRẠNG THÁI */}
                                                      <Col
                                                        span={3}
                                                        style={{
                                                          textAlign: 'center',
                                                          display: 'flex',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                        }}
                                                      >
                                                        {slide.status === 'Completed' && (
                                                          <AiFillCheckCircle
                                                            color={colors.green}
                                                            size={20}
                                                            className='tw-ml-2'
                                                          />
                                                        )}
                                                        {slide.status === 'Locked' && (
                                                          <AiFillLock
                                                            color={colors.dimGray}
                                                            size={20}
                                                            className='tw-ml-2'
                                                          />
                                                        )}
                                                        {slide.status === 'Playing' && (
                                                          <AiFillPlayCircle
                                                            color={colors.gold}
                                                            size={20}
                                                            className='tw-ml-2'
                                                          />
                                                        )}
                                                      </Col>
                                                      {/* CỘT KỸ NĂNG */}
                                                      <Col span={3} style={{ textAlign: 'center' }}>
                                                        <div className='avatar-group'>
                                                          {slide?.skills &&
                                                            RdIcon(slide?.skills?.split('')).map((item, index) => {
                                                              return (
                                                                <img
                                                                  key={index}
                                                                  style={{ width: 26, height: 26 }}
                                                                  className='avatar avatar-sm rounded-circle'
                                                                  alt='...'
                                                                  src={item.default}
                                                                />
                                                              );
                                                            })}
                                                        </div>
                                                      </Col>
                                                      {/* CỘT TIẾN ĐỘ */}
                                                      <Col span={3} style={{ display: 'inherit' }}>
                                                        {(slide.type === 'WorkSheet' ||
                                                          slide.type === 'Quiz' ||
                                                          (slide.type === 'Video' &&
                                                            slide?.quizAssignmentId !==
                                                              '914B9045-F740-43C1-9ABB-C21DC4899AB1')) && (
                                                          <Row style={{ width: '100%' }}>
                                                            <Col span={15}>
                                                              <Progress
                                                                percent={slide.percentCompleted * 100}
                                                                showInfo={false}
                                                              />
                                                            </Col>
                                                            <Col span={9}>
                                                              <span className='tw-ml-2'>
                                                                {/* <span>Hoàn thành: </span> */}
                                                                {numeral(slide.percentCompleted).format('%')}
                                                              </span>
                                                            </Col>
                                                          </Row>
                                                        )}
                                                      </Col>
                                                      <Col span={3}>
                                                        {slide.status === 'Completed' &&
                                                          (slide.type === 'WorkSheet' ||
                                                            (slide.type === 'Quiz' &&
                                                              slide.quizAssignmentId !==
                                                                '914B9045-F740-43C1-9ABB-C21DC4899AB1')) && (
                                                            <Row>
                                                              <Col span={12} style={{ textAlign: 'right' }}>
                                                                <AiFillStar
                                                                  color={colors.gold}
                                                                  size={20}
                                                                  className='tw-ml-2'
                                                                />
                                                              </Col>
                                                              <Col className='pl-2' span={12}>
                                                                {slide?.score?.toFixed(1)}
                                                              </Col>
                                                            </Row>
                                                          )}
                                                      </Col>
                                                    </Row>
                                                  </li>
                                                );
                                              })}
                                            </ul>
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                  </div>
                                </Panel>
                              );
                            })}
                          </Collapse>
                        </Panel>
                      );
                    })}
                  </Collapse>
                )}
              </Col>
            </Row>
          )}
        </Card>
      </SimpleContainer>

      <Row
        gutter={[12, 12]}
        className='card-responsive'
        // style={{ margin: '0 25px' }}
      >
        <Col xs={24} sm={24} md={12}>
          <CoachingForm
            loggedInUser={loggedInUser}
            setCoachingGuideModalVisible={setCoachingGuideModalVisible}
            reportHeader={reportHeader}
          />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card title='TOEIC exam images' bodyStyles={{ height: 450 }}>
            <Slide images={recordImages} />
          </Card>
        </Col>
      </Row>
      <LearningCommitmentModal loggedInUser={loggedInUser} />
      <CoachingGuideModal visible={coachingGuideModalVisible} setVisible={setCoachingGuideModalVisible} />
    </>
  );
}

export default LGhome;
