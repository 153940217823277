import IndexPresentationAmesOnline from 'pages/AmesOnline';
import React from 'react';
import { useParams } from 'react-router';
import ParticipantInformationForm from './ParticipantInformationForm/ParticipantInformationForm';

const LoginAmesOnline = (props: any) => {

  const { codeExam } = useParams<{ codeExam: string }>();

  const [studentInfors, setStudentInfors] = React.useState<any>();

  //Hàm được gọi khi lưu thông tin tham gia thành công
  const onParticipateExam = (results: any) => {
    setStudentInfors(results);
  };

  return (
    <React.Fragment>
      {studentInfors?.params ? (
        // View làm bài
        <IndexPresentationAmesOnline
          codeId={studentInfors?.codeId ?? 0}
          codeExam={codeExam}
          params={studentInfors?.params}
          studentId={studentInfors?.studentId ?? 0}
        />
      ) : (
        //Form điền thông tin
        <ParticipantInformationForm codeExam={codeExam} onSuccess={onParticipateExam} />
      )}
    </React.Fragment>
  );
};
export default LoginAmesOnline;
