import _ from 'lodash';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Card, CardBody, CardHeader } from 'reactstrap';
import AudioButton from '../AudioButton';
import QuestionHeader from '../QuestionHeader';
import QuestionImage from '../QuestionImage';
import { QuestionComponentProps, QuestionComponentRef } from '../type';
import './styles.css';

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 60,
  minWidth: 40,
  padding: 8,
  marginRight: 8,
  fontWeight: 600,
  fontSize: 18,
  background: isDragging ? '#ced4da' : '#f4f5f7',
  color: '#172b4d',
  borderRadius: '0.25rem',
  border: '1px dashed #adb5bd',
  ...draggableStyle,
});

const Unscramble = (
  {
    data,
    onSubmit,
    userData,
    questionIndex = 0,
    preview = false,
    showUserData = false,
    onSubmitWhenCancel,
    isChildQuestion,
  }: QuestionComponentProps,
  ref: QuestionComponentRef,
) => {
  const [shuffedItems, setShuffedItems] = React.useState<any[]>([]);

  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
    onSubmitWhenCancel() {
      handleSubmitOnCanceling();
    },
  }));

  React.useEffect(() => {
    if (userData && showUserData) {
      setShuffedItems(userData?.answer);
    }
    if (preview) {
      setShuffedItems(data?.answers?.[0]?.answer);
    }
  }, []);
  const handleAnswersNResult = () => {
    let shuffedType = data.settings && data.settings.question && data.settings.question.shuffedType;
    if (shuffedType === null || shuffedType === undefined) {
      shuffedType = 'word';
    }

    let splitCharacter = ' ';
    if (shuffedType === 'character') {
      splitCharacter = '';
    }

    const words = shuffedItems.map((x: any) => x.content);
    const final = _.join(words, splitCharacter);
    const result = final.trim() === data?.answers?.[0]?.answer.split(' ').join('');
    // Callback
    return {
      answers: final,
      result,
    };
  };
  const handleSubmitOnCanceling = () => {
    const { answers, result } = handleAnswersNResult();

    onSubmitWhenCancel?.({id: data.id, answers, result }, data);
  };
  const handleSubmit = () => {
    const { answers, result } = handleAnswersNResult();

    onSubmit?.({id: data.id, answers, result }, data);
  };

  const getListStyle = () => ({
    display: 'flex',
    overflow: 'auto',
    padding: 0,
  });

  const audioRef = React.useRef<any>();
  audioRef.current = new Audio();
  const playAudio = React.useCallback((audioUrl) => {
    audioRef.current.src = audioUrl;
    audioRef.current.play();
  }, []);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(shuffedItems, result.source.index, result.destination.index);
    setShuffedItems(items);
  };

  React.useEffect(() => {
    // SETTINGS
    let shuffedType = data.settings && data.settings.question && data.settings.question.shuffedType;
    if (shuffedType === null || shuffedType === undefined) {
      shuffedType = 'word';
    }

    let splitCharacter = ' ';
    if (shuffedType === 'character') {
      splitCharacter = '';
    }

    // SHUFFER
    if (userData || showUserData) {
      const items = userData.answers
        .trim()
        .split(splitCharacter)
        .map((item: any, index: number) => {
          return {
            id: `item-${index}`,
            content: item,
          };
        });
      setShuffedItems(items);
    } else {
      let shuffle = _.shuffle(data?.questionText?.trim().split(splitCharacter));
      // Nếu trùng với đáp án thì xáo lại.
      shuffle =
        _.join(shuffle, splitCharacter) === data?.answers?.[0].answer
          ? _.shuffle(data.questionText.trim().split(splitCharacter))
          : shuffle;
      const items = shuffle.map((item, index) => {
        return {
          id: `item-${index}`,
          content: item,
        };
      });
      setShuffedItems(items);
    }
    if (preview) {
      let dataPreview = data.questionText?.trim()?.split(splitCharacter);
      const items = dataPreview?.map((item, index) => {
        return {
          id: `item-${index}`,
          content: item,
        };
      });
      setShuffedItems(items);
    }
  }, [data.questionText, data.settings, userData]);

  return (
    <Card>
      {isChildQuestion ? null : (
        <CardHeader>
          <QuestionHeader title={data.title} subTitle={data.subTitle} questionIndex={questionIndex} />
        </CardHeader>
      )}

      <CardBody style={{ minHeight: 300 }}>
        <AudioButton audioUrl={data.audioUrl} />

        <div>
          {data.imageUrl && <QuestionImage imageUrl={data.imageUrl} />}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable' direction='horizontal'>
              {(provided) => (
                <div ref={provided.innerRef} style={getListStyle()} {...provided.droppableProps}>
                  {shuffedItems.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(p, s) => (
                        <div
                          ref={p.innerRef}
                          {...p.draggableProps}
                          {...p.dragHandleProps}
                          style={getItemStyle(s.isDragging, p.draggableProps.style)}
                        >
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </CardBody>
    </Card>
  );
};

export default React.forwardRef(Unscramble);
