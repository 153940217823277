import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, message, Row, Spin, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { sachsoServerUrl } from 'constants/serverUrls';
import settings from 'constants/settings';
import React from 'react';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import axios from 'axios';
import ReactPlayer from 'react-player';
import ReactQuill from 'react-quill';
import formats from 'modules/Presentation/Teacher/Modal/WordModal/formats';
import modules_v2 from 'modules/Presentation/Teacher/Modal/WordModal/modules_v2';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import colors from 'constants/colors';

type Props = {
  layoutHeader: string;
  form: any;
  required: boolean;
};

const LayoutHeader = ({ layoutHeader, form, required }: Props) => {
  const { t } = useTranslation();
  const [urlFileAudio, setUrlFileAudio] = React.useState<string>();
  const [urlFileVideo, setUrlFileVideo] = React.useState<string>();
  const [urlFileImage, setUrlFileImage] = React.useState<string>();
  const [typeUpload, setTypeUpload] = React.useState<string>();
  const [fileList, setFileList] = React.useState<any>();
  const [valueChecked, setValueChecked] = React.useState<boolean>();
  const [valueTextAudio, setValueTextAudio] = React.useState<string>('');
  const [isLoadingGetAudio, setIsLoadingGetAudio] = React.useState(false);
  const [svgHtml, setSvgHtml] = React.useState('');

  let getFieldValues = form.getFieldsValue();

  const props = {
    name: 'files',
    action: `${sachsoServerUrl}/api/v1.0/dynamic/upload-files`,
    headers: {
      Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
      ApplicationName: settings.APPLICATION_NAME,
    },
    data: {
      entityId: '46d30c13-3555-4395-8b83-4b9b2f7402ae',
      subModuleName: 'RESOURCES',
      entityName: 'Slides',
      moduleName: 'SACHSO',
      moduleId: settings.MODULE_ID,
      //   SACHSO_RESOURCES_Questions
    },
    fileList,
    maxCount: 1,
    beforeUpload: (file: any) => {
      const isPNG = file.type?.split('/')?.[0] === typeUpload;
      if (!isPNG) {
        message.error(`${file.name} wrong format`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    // beforeUpload: (file: any) => {
    //   if (file?.type?.split('/')?.[0] !== typeUpload) {
    //     message.warning('Vui chọn file đúng định dạng');
    //   }
    //   return Upload.LIST_IGNORE;
    // },
    onChange(info: any) {
      if (info?.file?.status === 'uploading') {
      }
      if (info.file.status === 'done') {
        setFileList(info?.fileList);
        const typeFile = info?.file.type?.split('/')?.[0];
        if (typeFile === 'image') {
          setUrlFileImage(info.file.response?.files?.[0]?.downloadUrl);
          form.setFieldsValue({
            imageUrl: info.file.response?.files?.[0]?.downloadUrl,
          });
        } else if (typeFile === 'audio') {
          setUrlFileAudio(info.file.response?.files?.[0]?.downloadUrl);
          form.setFieldsValue({
            audioUrl: info.file.response?.files?.[0]?.downloadUrl,
          });
        } else {
          setUrlFileVideo(info.file.response?.files?.[0]?.downloadUrl);
          form.setFieldsValue({
            videoUrl: info.file.response?.files?.[0]?.downloadUrl,
          });
        }
        message.success(`Download  ${info.file.name} folder successfully`);
      } else if (info.file.status === 'error') {
        message.error(`Download  ${info.file.name} folder failed`);
      }
    },
  };

  const onChangeAI = (e: CheckboxChangeEvent) => {
    setValueChecked(e.target.checked);
  };
  const onGetAudio = () => {
    setIsLoadingGetAudio(true);
    setUrlFileAudio(null);
    axios.get('https://toeic.ames.edu.vn/ames/api/AmesApi/AWSTextToSpeech?text=' + valueTextAudio).then((res) => {
      setIsLoadingGetAudio(false);
      setUrlFileAudio(res?.data);
      form.setFieldsValue({ audioUrl: res?.data });
    });
  };

  // const setFieldTypeUpdate = () => {

  //   if (getFieldValues?.audioUrl) {
  //     return setUrlFile(getFieldValues?.audioUrl);
  //   } else if (getFieldValues?.imageUrl) {
  //     return setUrlFile(getFieldValues?.imageUrl);
  //   }
  // };

  const RenderLayout = () => {
    switch (layoutHeader) {
      case 'textArea':
        return (
          //update questions
          <Form.Item
            rules={[
              {
                required: required,
                message: t('please enter', { field: t('questionText') }),
              },
            ]}
            name='questionText'
            // initialValue={' '}
          >
            <TextArea style={{ color: colors.font }} rows={4} placeholder='Question Text' />
          </Form.Item>
        );

      case 'editor':
        return (
          <Form.Item
            rules={[
              {
                required: required,
                message: t('please enter', { field: t('questionText') }),
              },
            ]}
            name='questionText'
            initialValue={''}
          >
            <ReactQuill
              // onChange={(e) => console.log(e)}
              style={{ color: colors.font }}
              placeholder='Content editting...'
              theme='snow'
              modules={modules_v2}
              formats={formats}
            />
            {/* <TextArea rows={4} placeholder='Question Text' /> */}
          </Form.Item>
        );

      case 'questionTextVN':
        return (
          <Form.Item
            rules={[
              {
                required: required,
                message: t('please enter', { field: t('questionTextVN') }),
              },
            ]}
            name='questionTextVN'
            // initialValue={' '}
          >
            <TextArea rows={4} placeholder='Question Text VN' />
          </Form.Item>
        );
      case 'answerText':
        return (
          <Form.Item
            rules={[
              {
                required: required,
                message: t('please enter', { field: t('Answer') }),
              },
            ]}
            name='text'
            label='Answer'
            // initialValue={' '}
          >
            <Input />
          </Form.Item>
        );
      case 'title':
        return (
          <Form.Item
            rules={[
              {
                required: required,
                message: t('please enter', { field: t('Title') }),
              },
            ]}
            name='title'
            label='Tiêu đề'
            // initialValue={' '}
          >
            <Input />
          </Form.Item>
        );
      case 'subTitle':
        return (
          <Form.Item
            rules={[
              {
                required: required,
                message: t('please enter', { field: t('subTitle') }),
              },
            ]}
            name='subTitle'
            label='Sub title'
            // initialValue={' '}
          >
            <Input />
          </Form.Item>
        );
      case 'answerTextVN':
        return (
          <Form.Item
            rules={[
              {
                message: t('please enter', { field: t('Answer') }),
                required: required,
              },
            ]}
            name='textVN'
            label='Answer VN'
            // initialValue={' '}
          >
            <Input />
          </Form.Item>
        );
      case 'input':
        return (
          <Col xs={24}>
            <Form.Item
              rules={[
                {
                  required: required,
                  message: t('please enter', { field: t('Text') }),
                },
              ]}
              style={{ width: '100%' }}
              name={'text'}
              label={`Text`}
            >
              <Input />
            </Form.Item>
          </Col>
        );
      case 'phonetic':
        return (
          <Col xs={24}>
            <Form.Item
              rules={[
                {
                  required: required,
                },
              ]}
              style={{ width: '100%' }}
              name={'phonetic'}
              label={`Phonetic`}
            >
              <Input />
            </Form.Item>
          </Col>
        );
      case 'audio':
        setTypeUpload('audio');
        return (
          <>
            <Row gutter={[12, 12]}>
              <Col lg={18} xs={24}>
                <Form.Item
                  rules={[
                    {
                      required: required,
                      message: t('please enter', { field: 'Audio Url' }),
                    },
                  ]}
                  style={{ width: '100%' }}
                  name={'audioUrl'}
                  label={`Audio Url`}
                >
                  <Input style={{ color: colors.font }} placeholder='https://' />
                </Form.Item>
              </Col>
              <Col lg={6}>
                <Form.Item label={` `}>
                  <Upload {...props}>
                    <DynamicButton style={{ width: '100%' }} block icon={<UploadOutlined />}>
                      {i18next.t('uploadObject', { field: i18next.t('Audio') })}
                      {/* Audio Upload */}
                    </DynamicButton>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            {(urlFileAudio || getFieldValues?.audioUrl) && (
              <audio controls style={{ marginTop: 10, marginBottom: 10 }}>
                <source src={urlFileAudio || getFieldValues?.audioUrl} type='audio/mpeg' />
              </audio>
            )}
          </>
        );
      case 'image':
        setTypeUpload('image');
        return (
          <Row gutter={[12, 12]}>
            <Col lg={18} xs={24}>
              <Form.Item
                rules={[
                  {
                    required: required,
                    message: t('please enter', { field: 'Image Url' }),
                  },
                ]}
                style={{ width: '100%' }}
                name={'imageUrl'}
                label={`Image Url`}
              >
                <Input placeholder='https://' style={{ color: colors.font }} />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item label={` `}>
                <Upload {...props}>
                  <DynamicButton style={{ width: '100%' }} block icon={<UploadOutlined />}>
                    {i18next.t('uploadImage')}
                  </DynamicButton>
                </Upload>
              </Form.Item>
            </Col>
            {(urlFileImage || getFieldValues?.imageUrl) && (
              <img src={urlFileImage || getFieldValues?.imageUrl} style={{ width: 300, height: 200 }} />
            )}
          </Row>
        );
      case 'video':
        setTypeUpload('video');
        return (
          <Row gutter={[12, 12]}>
            <Col lg={18} xs={24}>
              <Form.Item
                rules={[
                  {
                    required: required,
                    message: t('please enter', { field: 'Video Url' }),
                  },
                ]}
                style={{ width: '100%' }}
                name={'videoUrl'}
                label={`Video Url`}
              >
                <Input placeholder='https://' style={{ color: colors.font }} />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item label={` `}>
                <Upload {...props}>
                  <DynamicButton style={{ minWidth: '100%' }} block icon={<UploadOutlined />}>
                    {/* Video Upload */}
                    {i18next.t('uploadObject', { field: 'Video' })}
                  </DynamicButton>
                </Upload>
              </Form.Item>
            </Col>
            {/* <ViewImportExam hiddenTilte={true} /> */}
            {/* {(urlFileImage || getFieldValues?.imageUrl) && (
                <img src={urlFileImage || getFieldValues?.imageUrl} style={{ width: 300, height: 200 }} />
              )} */}
            {urlFileVideo && <ReactPlayer controls url={urlFileVideo} />}
          </Row>
        );
      case 'imagePath':
        return (
          <>
            <Form.Item
              rules={[
                {
                  required: required,
                  message: t('please enter', { field: 'SVG HTML Code' }),
                },
              ]}
              name='imagePath'
            >
              <TextArea
                placeholder='SVG HTML Code'
                style={{ height: 120, marginBottom: 24, color: colors.font }}
                onChange={(e) => {
                  setSvgHtml(e.target.value);
                }}
              />
            </Form.Item>
            {svgHtml && <div id='svg-html' dangerouslySetInnerHTML={{ __html: svgHtml }}></div>}
          </>
        );

      case 'audioByAI':
        setTypeUpload('audio');

        return (
          <>
            <Row gutter={[12, 12]}>
              <Col lg={6}>
                <Checkbox checked={valueChecked} onChange={onChangeAI}>
                  Create Audio by AI
                </Checkbox>
              </Col>
            </Row>
            {(urlFileAudio || getFieldValues?.audioUrl) && (
              <audio controls style={{ marginTop: 10, marginBottom: 10 }}>
                <source src={urlFileAudio || getFieldValues?.audioUrl} type='audio/mpeg' />
              </audio>
            )}
            <Row gutter={[12, 12]} style={{ marginTop: 10 }}>
              {valueChecked ? (
                <>
                  <Col lg={24} xs={24}>
                    <Form.Item
                      rules={[
                        {
                          required: required,
                          message: t('please enter', { field: t('enterCreateAudio') }),
                        },
                      ]}
                      style={{ width: '100%' }}
                      name={'text'}
                      label={`Enter the word to create Audio`}
                    >
                      <Input autoFocus value={valueTextAudio} onChange={(e) => setValueTextAudio(e.target.value)} />
                    </Form.Item>
                  </Col>
                  <Col lg={6}>
                    <Form.Item label={` `}>
                      <DynamicButton
                        style={{ minWidth: '100%' }}
                        loading={isLoadingGetAudio}
                        block
                        onClick={onGetAudio}
                      >
                        Create Audio AI
                      </DynamicButton>
                    </Form.Item>
                  </Col>
                  <Col lg={18} xs={24}>
                    <Form.Item
                      style={{ width: '100%' }}
                      name={'audioUrl'}
                      label={`Audio Url`}
                      rules={[
                        {
                          required: required,
                          message: t('please enter', { field: 'Audio Url' }),
                        },
                      ]}
                    >
                      <Input style={{ color: colors.font }} placeholder='https://' disabled />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <>
                  <Col lg={18} xs={24}>
                    <Form.Item
                      rules={[
                        {
                          required: required,
                          message: t('please enter', { field: 'Audio Url' }),
                        },
                      ]}
                      style={{ width: '100%' }}
                      name={'audioUrl'}
                      label={`Audio Url`}
                    >
                      <Input style={{ color: colors.font }} placeholder='https://' />
                    </Form.Item>
                  </Col>
                  <Col lg={6}>
                    <Form.Item label={` `}>
                      <Upload {...props}>
                        <DynamicButton style={{ minWidth: '100%' }} block icon={<UploadOutlined />}>
                          {i18next.t('uploadObject', { field: i18next.t('Audio') })}
                        </DynamicButton>
                      </Upload>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </>
        );

      default:
        return <></>;
    }
  };
  return <RenderLayout />;
};

export default LayoutHeader;
