import { Button, Col, Row, notification } from 'antd';
import Fireworks from 'components/Firework';
import { APPLICATION_NAME, IS_PT } from 'constants/settings';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import ExercicesTypes from '..';
import QuestionHeader from '../QuestionHeader';
import QuestionImage from '../QuestionImage';
import { QuestionComponentProps, QuestionComponentRef, QuestionProps, RefExercise } from '../type';
import './Combine.scss';
import AudioButton from '../AudioButton';

notification.config({
  duration: 1,
  maxCount: 2,
  placement: 'top',
});

type TypeSubmit = 'submit' | 'submit-cancel';

const Combine = (
  {
    loggedInUser,
    data,
    onSubmit,
    userData,
    questionIndex = 0,
    preview = false,
    setAudio,
    onSubmitWhenCancel,
  }: QuestionComponentProps,
  ref: QuestionComponentRef,
) => {
  // State
  const [typeSubmit, setTypeSubmit] = React.useState<TypeSubmit | null>(null);
  const [childQuestionSubmit, setChildQuestionSubmit] = React.useState<any[]>([]);

  //Ref
  const refFireWork = React.useRef<any>();
  const childRefs = useRef<RefExercise[]>([]);

  // Variables?
  const questions = data?.answers;
  const titleQuestion = data?.title;
  const subTitleQuestion = data?.subTitle;
  const audioQuestion = data?.audioUrl;
  const videoQuestion = data?.videoUrl;
  const imageQuestion = data?.imageUrl;
  const questionText = data?.questionText;
  const count = typeof questions === 'object' ? questions?.length : 0;

  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit('submit');
    },
    onSubmitWhenCancel() {
      handleSubmit('submit-cancel');
    },
  }));

  //Lắng nghe các component con submit xong thì submit của combine
  useEffect(() => {
    if (typeSubmit && count && count === childQuestionSubmit.length) {
      const score = getScoreFromChilds(childQuestionSubmit);
      setChildQuestionSubmit([]);
      if (typeSubmit === 'submit') {
        onSubmit?.({ id: data.id, answers: childQuestionSubmit, result: score }, data);
      }
      if (typeSubmit === 'submit-cancel') {
        onSubmitWhenCancel?.({ id: data.id, answers: childQuestionSubmit, result: score }, data);
      }
    }
  }, [count, childQuestionSubmit.length, typeSubmit]);

  //gọi các component con submit để lấy kết quả
  const getScoreFromChilds = (childResults: { result: boolean }[]) => {
    let countCorrect = childResults.filter((i) => i.result)?.length ?? 0;
    const score =Math.floor((countCorrect / childResults.length) * 100);
    return score;
  };
  //gọi các component con submit để lấy kết quả
  const handleSubmit = (type: TypeSubmit) => {
    setTypeSubmit(type);
    childRefs.current.forEach((ref) => ref.onNext());
  };

  //Lưu kết quả làm bài của các component con
  const onChildSubmit = useCallback(
    (userData: any) => {
      setChildQuestionSubmit((prev) => [...prev, userData]);
    },
    [childQuestionSubmit],
  );

  // Render
  return (
    <div className='type-combine'>
      <QuestionHeader title={titleQuestion} subTitle={subTitleQuestion} questionIndex={questionIndex} />
      <Row gutter={12}>
        <Col xs={24} sm={24} md={12}>
          <div className='question-text-combine'>
            {/* <AudioButton audioUrl={audioQuestion} className='mt-4' /> */}
            <br />
            {audioQuestion && (
              <>
                <audio controls>
                  <source src={audioQuestion} type='audio/mpeg' />
                </audio>
                <br />
              </>
            )}
            {imageQuestion && (
              <>
                <QuestionImage imageUrl={imageQuestion} />
                <br />
              </>
            )}
            {videoQuestion && (
              <>
                <ReactPlayer controls url={videoQuestion} />
                <br />
              </>
            )}
            <div style={{ fontSize: 18 }} dangerouslySetInnerHTML={{ __html: questionText ?? '' }} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <div className='question-component-combine'>
            {(questions === '[]' ? [] : questions)?.map((question: QuestionProps, i: number) => {
              const QuestionComponent =
                ExercicesTypes[question?.componentType?.toUpperCase() as keyof typeof ExercicesTypes];
              const submittedQuestion = _.find(userData ?? [], (x) => {
                return x?.id === question?.id;
              });
              return (
                <div key={question.id}>
                  <QuestionComponent
                    ref={(current: any) => (childRefs.current[i] = current)}
                    data={question}
                    userData={submittedQuestion?.userData}
                    questionIndex={i + 1}
                    preview={preview}
                    studentId={loggedInUser?.id}
                    showUserData={IS_PT}
                    setAudio={setAudio}
                    isChildQuestion={true}
                    onSubmit={onChildSubmit}
                  />
                </div>
              );
            })}
          </div>
        </Col>
      </Row>

      <Fireworks ref={refFireWork} />
    </div>
  );
};
export default React.forwardRef(Combine);
