/* eslint-disable react/jsx-props-no-spreading */
import { useSelector } from 'react-redux';

export default function withTeacherAuthenticator(WrappedComponent) {
  const ComponentWithTeacherAuthenticator = (props) => {
    const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

    return <WrappedComponent loggedInUser={loggedInUser} {...props} />;
  };

  return ComponentWithTeacherAuthenticator;
}
