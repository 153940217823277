import React from 'react';
import OneChoose from './OneChoose/OneChoose';
import MatchThePictures from './Match/MatchThePictures';

const IndexContentAddType = ({ content, fieldC, form }) => {
  const RenderContentOptionType = () => {
    switch (content) {
      case 'ListenAndChoose':
        return <OneChoose form={form} content={content} field_paren={fieldC} />;
      case 'LookAndChoose':
        return (
          <>
            <p>look and choose</p>
          </>
        );
      case 'MatchThePicture':
        return <MatchThePictures form={form} content={content} field_paren={fieldC} />;
      default:
        return null;
    }
  };

  return <RenderContentOptionType />;
};

export default IndexContentAddType;
