import Card from 'components/Containers/Card';
import { PlusOutlined } from '@ant-design/icons';
import Container from 'components/Containers/Container';
import React from 'react';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import { useQuery } from 'react-query';
import tableData from './tableData';
import { Form, Modal, Spin } from 'antd';
import Khoa from './components/Khoa';
import Nganh from './components/Nganh';
import { get } from 'helpers/QueryHelper';
import { getData } from './components/InsertData';
import ViewEdit from './components/ViewEdit';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
const IndexManageNganh = () => {
  const [refresh, setRefresh] = React.useState(0);
  const [dataKhoa, setDataKhoa] = React.useState();
  const [dataTable, setDataTable] = React.useState();
  const [modalStatus, setModalStatus] = React.useState({
    title: '',
    open: false,
    component: null,
    type: '',
  });

  const { data: onGetDataCrud, loading } = useQuery(['VIMARU_METADATA_Category', refresh], () => {
    return get('Category', '*', '', 'CreatedDate', 'METADATA', 'VIMARU');
  });

  const extraColumn = [
    {
      title: 'Thao tác',
      key: 'editInfomation',
      width: '10%',
      sort: false,
      render: (text, record) => {
        return <ViewEdit dataTable={dataTable} data={record} onGetDataCrud={onGetDataCrud} />;
      },
    },
  ];
  const userCallbackCancelModal = React.useCallback(() => {
    setModalStatus({
      title: '',
      open: false,
      component: null,
      type: '',
    });
  });
  const handleShowModal = (type) => {
    if (type === 'khoa') {
      setModalStatus({
        title: 'THÊM KHOA',
        open: true,
        component: <Khoa data={dataTable} buttonCloseModal={userCallbackCancelModal} />,
        type: 'form',
      });
    } else if (type === 'nganh') {
      setModalStatus({
        title: 'THÊM CHUYÊN NGÀNH',
        open: true,
        component: <Nganh data={dataTable} khoa={dataKhoa} buttonCloseModal={userCallbackCancelModal} />,
        type: 'excel',
      });
    }
  };
  const customData = () => {
    const newArrayData = [];
    const filterDataKhoa = onGetDataCrud?.filter((itemKhoa) => {
      return itemKhoa?.parentId === null;
    });
    filterDataKhoa?.map((item, index) => {
      newArrayData.push({
        categoryName: item.categoryName,
        key: item.id,
        id: item.id,
        code: item.code,
        parentId: item.parentId,
        createdDate: item.createdDate,
        children: onGetDataCrud?.filter((itemKhoa) => {
          return itemKhoa?.parentId === item.id;
        }),
      });
    });
    setDataTable(newArrayData);
  };

  React.useEffect(() => {
    if (onGetDataCrud) {
      customData();
      const filterDataKhoa = onGetDataCrud?.filter((itemKhoa) => {
        return itemKhoa?.parentId === null;
      });
      setDataKhoa(filterDataKhoa);
    }
  }, [onGetDataCrud]);

  return (
    <div>
      <Container name='Danh mục khoa, ngành' parentName='Administration'>
        <Card
          title='Danh mục khoa, ngành'
          extra={
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', columnGap: 10 }}>
              <DynamicButton onClick={() => handleShowModal('khoa')}>
                <PlusOutlined /> Thêm khoa
              </DynamicButton>
              <DynamicButton onClick={() => handleShowModal('nganh')}>
                <PlusOutlined /> Thêm chuyên ngành
              </DynamicButton>
            </div>
          }
        >
          <Modal
            title={modalStatus.title}
            footer={null}
            open={modalStatus.open}
            onCancel={() => userCallbackCancelModal()}
          >
            {modalStatus.component}
          </Modal>

          <DynamicTable
            loading={loading}
            dataSource={dataTable}
            initialTableData={tableData}
            extraColumns={extraColumn}
            className='components-table-demo-nested'
            pagination={{ defaultPageSize: 10 }}
            expandIconColumnIndex={4}
            expandIconAsCell={false}
          />
        </Card>
      </Container>
    </div>
  );
};

export default IndexManageNganh;
