import * as actionTypes from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  loggedInUser: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_INIT:
      return {
        ...state,
        loading: false,
        error: null,
        loggedInUser: null,
      };

    case actionTypes.AUTH_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        loggedInUser: action.payload,
      };
    case actionTypes.AUTH_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loggedInUser: null,
      };
    case actionTypes.AUTH_LOGIN_GOOGLE:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.AUTH_LOGIN_GOOGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        loggedInUser: action.payload,
      };

    case actionTypes.AUTH_LOGIN_GOOGLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loggedInUser: null,
      };

    case actionTypes.AUTH_LOG_OUT:
      return {
        ...state,
        loading: false,
        error: null,
        loggedInUser: null,
      };

    default:
      return state;
  }
};

export default authReducer;
