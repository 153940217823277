import { Button, Form, Input, Modal, Row, Upload, Col, InputNumber, Image, message, Spin } from 'antd';
import { useStudentsOrStudentByClassId } from 'modules/Teacher/api/getStudentsOrStudentByClassId';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { query } from 'helpers/QueryHelper';
import { UploadOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import StudentInfomationHeader from 'modules/Student/components/StudentInfomationHeader';
import TextArea from 'antd/lib/input/TextArea';
import Essay from 'modules/Presentation/Viewer/components/ExercicesTypes_MENARD/Essay';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import i18next from 'i18next';
const fetcher = (slideId, ptCode) => {
  return query('p_QUIZCITY_PTTest_GetResult_TypeQuiz_ByStudent', { slideId, ptCode });
};
const MarkingEssay = ({ record, scoreEssay, teacherNoteEssay }) => {
  const scoreWeight = record?.scoreWeight ?? 10;
  const [visible, setVisible] = React.useState(false);
  const queryClient = useQueryClient();
  const KEY = ['p_QUIZCITY_PTTest_GetResult_TypeQuiz_ByStudent', record?.slideId, record?.studentId];
  const { data, isLoading } = useQuery(KEY, () => fetcher(record?.slideId, record?.studentId), { enabled: visible });
  const [form] = Form.useForm();
  // if (!data) return null;

  const audioUrl = data?.[0]?.summaryData?.submittedQuestions?.[0]?.userData?.answers?.audioUrl;
  const imageUrl = data?.[0]?.summaryData?.submittedQuestions?.[0]?.userData?.answers?.imageUrl;
  const textEssay = data?.[0]?.summaryData?.submittedQuestions?.[0]?.userData?.answers?.textEssay;
  const videoUrl = data?.[0]?.summaryData?.submittedQuestions?.[0]?.userData?.answers?.videoUrl;
  const onFinish = (values) => {
    const parameters = {
      ptCode: record?.studentId,
      SlideId: record?.slideId,
      ScoreEssay: values?.scoreEssay,
      TeacherNoteEssay: values?.teacherNoteEssay,
      FolderId: record?.folderId,
      isEssay: true,
    };
    query('p_QUIZCITY_PTTest_Results_Update_Quiz_Essay', parameters)
      .then((res) => {
        queryClient.invalidateQueries('p_QUIZCITY_PTTest_GetAnswerLogs_ByCode');
        queryClient.invalidateQueries('p_QUIZCITY_PTTest_CodeExam_Dashboard');
        message.success('Chấm điểm bài tập tự luận thành công');
        setVisible(false);
      })
      .catch((e) => message.error('Chức năng này đang phát triển'));
  };
  return (
    <React.Fragment>
      <DynamicButton type='text' style={{ color: '#006EE6' }} onClick={() => setVisible(true)}>
        {i18next.t('table:classInformation.Mark')}
      </DynamicButton>
      <Modal
        centered
        width='90vw'
        open={visible}
        title={i18next.t('slide:Essay.Essay work').toUpperCase()}
        onCancel={() => setVisible(false)}
        footer={<DynamicButton onClick={() => setVisible(false)}>{i18next.t('Close')}</DynamicButton>}
        bodyStyle={{ minHeight: '80vh', maxHeight: 'calc(100vh - 110px)', overflowY: 'auto' }}
        destroyOnClose
      >
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin size='large'></Spin>
          </div>
        ) : (
          <Form
            form={form}
            initialValues={{ audioUrl, imageUrl, textEssay, videoUrl, scoreEssay, teacherNoteEssay }}
            onFinish={onFinish}
          >
            <h6 className='mb-4 heading-small text-muted'>Điểm</h6>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item label='Trọng số' wrapperCol={{ span: 21 }} labelCol={{ span: 3 }}>
                  <span>{scoreWeight}</span>
                </Form.Item>
                <Form.Item
                  label='Điểm'
                  name='scoreEssay'
                  rules={[{ required: true, message: 'Bạn chưa nhập điểm' }]}
                  hasFeedback
                  wrapperCol={{ span: 21 }}
                  labelCol={{ span: 3 }}
                >
                  <InputNumber style={{ width: '100%' }} min={0.1} max={scoreWeight} step='0.1' />
                </Form.Item>
                {/* <span className='tw-font-medium tw-ml-2' style={{ color: colors.theme.success }}>
             {`/ ${workItem.assignmentPoints}`}
           </span> */}
                <Form.Item label='Nhận xét' name='teacherNoteEssay' wrapperCol={{ span: 21 }} labelCol={{ span: 3 }}>
                  <TextArea style={{ width: '100%' }} autoSize={{ minRows: 3 }} allowClear />
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col span={3}></Col>
                    <Col span={21}>
                      <DynamicButton type='primary' htmlType='submit'>
                        Lưu thông tin
                      </DynamicButton>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              {/* <Col lg={6}>
                <StudentInfomationHeader studentId={record?.studentId} />
              </Col> */}
            </Row>
            <hr />
            {data &&
              data?.[0]?.summaryData?.submittedQuestions?.map((item, index) => (
                <Essay
                  data={item?.question}
                  userData={item?.userData}
                  showUserData
                  isMarking
                  questionIndex={index + 1}
                  preview={true}
                />
              ))}
          </Form>
        )}
      </Modal>
    </React.Fragment>
  );
};

MarkingEssay.propTypes = {
  classId: PropTypes.string,
};

MarkingEssay.defaultProps = {
  classId: '',
};

export default MarkingEssay;
