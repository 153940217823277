import colors from 'constants/colors';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';

export const supportColumns = {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'Code',
      key: 'code',
      fixed: 'left',
      width: '5%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
    },
    {
      title: 'Category',
      key: 'categoryName',
      fixed: 'left',
      width: '50%',
      sort: false,
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
    },

    // {
    //   title: 'Ngày tạo',
    //   key: 'CreatedDate',
    //   width: '15%',
    //   sort: false,
    //   render: 'renderDateTime',
    //   prefix: <CalendarOutlined className='tw-mr-2' />,
    // },
  ],
};
