import React from 'react';
// react plugin for creating notifications over the dashboard
// import { notificationAlert } from 'variables/common';
// reactstrap components
// import NotificationAlert from 'react-notification-alert'

const Notifications = (type, title, message, notificationAlert) => {
  const options = {
    place: 'tc',
    message: (
      <div className='alert-text'>
        <div className='alert-title' data-notify='title'>
          {title}
        </div>
        <div data-notify='message'>{message}</div>
      </div>
    ),
    type,
    icon: 'ni ni-bell-55',
    autoDismiss: 3,
  };
  // eslint-disable-next-line no-unused-expressions
  notificationAlert?.current?.notificationAlert(options);
  return notificationAlert;
  // type:default,info,success,warning,danger
};

export default Notifications;
