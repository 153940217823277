import colors from 'constants/colors';
import Card from 'components/Containers/Card';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Container from 'components/Containers/Container';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import { Button, Col, Form, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import { query } from 'helpers/QueryHelper';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import { courseColumns } from './tableColumn';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import settings from 'constants/settings';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const IndexManageNganh = () => {
  const { t } = useTranslation();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const [form] = Form.useForm();
  const [selectRow, setSelectRow] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dataKhoaHoc, setDataKhoaHoc] = React.useState([]);

  const [modalStatus, setModalStatus] = React.useState({
    open: false,
    title: '',
    type: 'add',
  });

  const CourseExtraColumns = [
    {
      key: 'editInfomation',
      render: (text, record) => {
        return (
          <Space>
            <Tooltip placement='top' title={'Update'}>
              <Button
                key='edit-file'
                shape='circle'
                type='dashed'
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectRow(record);
                  handleShowModal('edit', record);
                }}
              />
            </Tooltip>
            <Popconfirm
              placement='topRight'
              title={() => {
                return (
                  <div>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>
                      {i18next.t('Are you sure to delete', { object: t('course').toLowerCase() })}
                    </p>
                    <strong style={{ color: '#f5222d' }}>({i18next.t('popconfirm.unrecoverable')})</strong>
                  </div>
                );
              }}
              onConfirm={() => onDelete(record.id)}
              okText='Confirm'
              cancelText={<span>{i18next.t('Close')}</span>}
              disabled={loading}
            >
              <Tooltip placement='top' title={i18next.t('Delete')}>
                <Button
                  key='edit-file'
                  shape='circle'
                  type='dashed'
                  danger
                  style={{ color: '#f5222d' }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
      sort: false,
      title: i18next.t('Action'),
      width: '10%',
    },
  ];

  React.useEffect(() => {
    query('p_AMES247_Course_Select')
      .then((res) => {
        setDataKhoaHoc(res);
      })
      .catch((error) => {});
  }, [refresh]);

  const handleShowModal = (type, record) => {
    form.setFieldsValue(record);
    setModalStatus({
      open: true,
      title:
        type === 'add'
          ? i18next.t('addNew', { field: i18next.t('course') }).toUpperCase()
          : i18next.t('commonTitle.Update information', { object: i18next.t('course') }).toUpperCase(),
      type,
    });
  };

  const onInsert = (value) => {
    setLoading(true);
    const { parentId, title, note, code, sortOrder } = value;
    query('dbo.p_AMES247_Course_InsertUpdate_v2', {
      code: code.trim().replace(/\s+/g, ' '),
      createdBy: loggedInUser.id,
      id: '00000000-0000-0000-0000-000000000000',
      note: note.trim().replace(/\s+/g, ' '),
      parentId: parentId || '00000000-0000-0000-0000-000000000000',
      sortOrder: 1,
      title: title.trim().replace(/\s+/g, ' '),
    })
      .then((res) => {
        message.success('You have add new course!');
        setRefresh(!refresh);
        setModalStatus({ open: false });
      })
      .catch((error) => {
        message.error("Can't add new course!");
      })
      .finally(() => setLoading(false));
  };

  const onUpdate = (value) => {
    setLoading(true);
    const { id } = selectRow;
    const { parentId, title, note, code, sortOrder } = value;
    query('dbo.p_AMES247_Course_InsertUpdate_v2', {
      code: code.trim().replace(/\s+/g, ' '),
      createdBy: loggedInUser.id,
      id,
      note: note.trim().replace(/\s+/g, ' '),
      parentId: parentId || '00000000-0000-0000-0000-000000000000',
      sortOrder: 1,
      title: title.trim().replace(/\s+/g, ' '),
    })
      .then((res) => {
        setRefresh(!refresh);
        setModalStatus({ open: false });
        message.success('Update course information successfully!');
      })
      .catch((error) => {
        message.error('Update failed!');
      })
      .finally(() => setLoading(false));
  };

  const onDelete = (id) => {
    setLoading(true);
    query('p_AMES247_Course_Delete_v2', { id })
      .then((res) => {
        const { status, message: messageRes, data } = res[0];
        if (status) {
          message.success(messageRes);
          setRefresh(!refresh);
        } else {
          Modal.warning({
            title: "This course is being used, can't be deleted!",
            content: (
              <>
                {data && <p>{'Classes in use: ' + data.map((item) => item?.className).toString() + '.'}</p>}

                <div>Remove the course from the classroom and the lecture folder before deleting</div>
              </>
            ),
            // okButtonProps: { className: settings.IS_LG ? 'modal-btn' : '' },
          });
        }
      })
      .catch((error) => {
        message.error('Delete failed!');
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (type, value) => {
    if (type === 'add') onInsert(value);
    else onUpdate(value);
  };
  const validateContent = (rule, value, callback) => {
    if (value.trim() === '') {
      callback(t('please enter', { field: t('value') }));
    } else {
      callback();
    }
  };

  return (
    <div>
      <Container name='Course Category' parentName='Administration'>
        <Card
          title={i18next.t('Course Category')}
          extra={
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', columnGap: 10 }}>
              <DynamicButton
                onClick={() =>
                  handleShowModal('add', {
                    code: GenPasswordModel(1, 6),
                    note: '',
                    title: '',
                  })
                }
              >
                <PlusOutlined /> {i18next.t('addNew', { field: i18next.t('course') })}
              </DynamicButton>
            </div>
          }
        >
          <DynamicTable
            // loading={loading}
            dataSource={dataKhoaHoc}
            initialTableData={courseColumns}
            extraColumns={CourseExtraColumns}
            className='components-table-demo-nested'
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '50', '100'],
            }}
            expandIconColumnIndex={5}
            expandIconAsCell={false}
          />
        </Card>
      </Container>

      <Modal
        title={modalStatus.title}
        okText='Save'
        cancelText='Close'
        onOk={() => {
          form.submit();
        }}
        open={modalStatus.open}
        onCancel={() => setModalStatus({ open: false })}
        footer={null}
      >
        <Form form={form} onFinish={(value) => handleSubmit(modalStatus.type, value)} layout='vertical'>
          <Form.Item
            name='code'
            label={i18next.t('Code')}
            required
            rules={[
              {
                validator: (_, value) => {
                  if (value.trim() === '')
                    return Promise.reject(i18next.t('please enter', { field: i18next.t('Code').toLowerCase() }));
                  else {
                    const checkDuplicate = dataKhoaHoc?.some(
                      (item) =>
                        item?.code.trim().toLowerCase().replace(/\s+/g, ' ') ===
                          value.trim().toLowerCase().replace(/\s+/g, ' ') &&
                        (item?.id !== selectRow?.id || modalStatus.type === 'add'),
                    );
                    if (checkDuplicate)
                      return Promise.reject(i18next.t('unavailable', { field: i18next.t('Code').toLowerCase() }));
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name='title'
            label={i18next.t('Course name')}
            rules={[
              {
                validator: (_, value) => {
                  if (!value || value.trim() === '')
                    return Promise.reject(i18next.t('please enter', { field: i18next.t('Course name').toLowerCase() }));
                  else {
                    const checkDuplicate = dataKhoaHoc?.some(
                      (item) =>
                        item?.title.trim().toLowerCase().replace(/\s+/g, ' ') ===
                          value.trim().toLowerCase().replace(/\s+/g, ' ') &&
                        (item?.id !== selectRow?.id || modalStatus.type === 'add'),
                    );
                    if (checkDuplicate)
                      return Promise.reject(
                        i18next.t('unavailable', { field: i18next.t('Course name').toLowerCase() }),
                      );
                    return Promise.resolve();
                  }
                },
              },
            ]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item name='note' label={i18next.t('table:course.Description')}>
            <Input />
          </Form.Item>
          {/* <Form.Item name='status' label='Trạng thái'>
            <Select>
              <Select.Option value="demo">ACTIVE</Select.Option>
              <Select.Option value="demo2">DEACTIVE</Select.Option>
            </Select>
          </Form.Item> */}
          {/* <Form.Item name='sortOrder' label='Thứ tự hiển thị' rules={[
            { required: true, message: 'Chưa nhập' },
          ]}>
            <InputNumber min={0} defaultValue={0} />
          </Form.Item> */}
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: 20 }}>
            <DynamicButton htmlType='button' onClick={() => setModalStatus({ open: false })}>
              {i18next.t('Close')}
            </DynamicButton>
            <DynamicButton style={{ border: 'none' }} type='primary' htmlType='submit' loading={loading}>
              {i18next.t('Save')}
            </DynamicButton>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default IndexManageNganh;
