import PropTypes from 'prop-types';
import React from 'react';
import MultipleMatchElement from './MultipleMatchElement';

function MultipleMatchEditor({ listObject = [], canvas }) {
  return (
    <div>
      {listObject.map((item, index) => {
        const { data, top, left, width, height, scaleX, scaleY } = item;
        // update reload dependency
        const dependency = JSON.stringify({ top, left, width, height, scaleX, scaleY, data });
        return <MultipleMatchElement key={index} canvas={canvas} obj={item} dependency={dependency} />;
      })}
    </div>
  );
}

MultipleMatchEditor.propTypes = {
  listObject: PropTypes.array,
  canvas: PropTypes.object,
};

MultipleMatchEditor.defaultProps = {
  listObject: [],
  canvas: {},
};

export default MultipleMatchEditor;
