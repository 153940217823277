import Shared_Home from 'pages/Home';
import Profile from 'pages/Profile';
import AccessDenied from 'pages/Errors/AccessDenied';
import { FaCogs, FaTools } from 'react-icons/fa';
import { IoIosBook, IoIosNotifications } from 'react-icons/io';

import colors from 'constants/colors';
import React from 'react';
import ManageNotification from 'pages/Administration/ManageNotifications';
import settings from 'constants/settings';
import Student_Books from 'pages/Student/Books';
export default [
  {
    // collapse: true,
    component: Shared_Home,
    exact: true,
    icon: 'fas fa-home text-info',
    // icon: <img alt='video-icon' src={homePage} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
    layout: `/${settings.PATHNAME}`,
    name: 'Trang chủ',
    path: '/home',
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 0,
    // state: `/${settings.PATHNAME}/home`,
    // views: [],
  },
  {
    path: '/access-denied',
    component: AccessDenied,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['authenticated'],
  },
  {
    path: '/admin/books',
    name: 'Sách điện tử',
    miniName: 'T',
    component: Student_Books,
    icon: 'fas fa-book text-blue',
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['authenticated'],
    // type: ['administrationRouters'],
    sortOrder: 1,
    showInSidebar: settings.IS_HCM,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['authenticated'],
  },
  {
    path: '/home/no-data',
    // name: 'Profile',
    component: Shared_Home,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['authenticated'],
  },
  // {
  //   path: '/notifications',
  //   name: 'Thông báo',
  //   // miniName: 'C',
  //   component: React.lazy(() => import('pages/Vimaru/Notification')),
  //   icon: <IoIosNotifications size={20} className='tw-text-blue-500' />,
  //   // icon: <img alt='video-icon' src={Notification} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
  //   layout: `/${settings.PATHNAME}`,
  //   exact: true,
  //   roles: ['authenticated'],
  //   showInSidebar: true,
  //   sortOrder: 997,
  // },
  // {
  //   path: '/libraries',
  //   name: 'Thư viện, tài nguyên',
  //   // miniName: 'C',
  //   component: React.lazy(() => import('pages/Vimaru/Library')),
  //   // icon: <img alt='video-icon' src={library} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
  //   icon: <IoIosBook size={20} className='tw-text-pink-500' />,
  //   layout: `/${settings.PATHNAME}`,
  //   exact: true,
  //   roles: ['authenticated'],
  //   showInSidebar: true,
  //   sortOrder: 998,
  // },
  {
    collapse: true,
    name: 'Công cụ',
    icon: <FaTools style={{ color: colors.font }} />,
    // icon: <img alt='video-icon' src={Tools} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 999,
    state: `${settings.PATHNAME}/tools`,
    views: [
      {
        path: '/tools/dictionary',
        name: 'Từ điển',
        miniName: 'D',
        component: React.lazy(() => import('pages/Tools/Dictionary')),
        // icon: <img alt='video-icon' src={Dictionary} width={33} style={{ marginLeft: '10px' }} />,
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['authenticated'],
        showInSidebar: true,
        sortOrder: 0,
      },
      {
        path: '/tools/translate',
        name: 'Dịch văn bản',
        miniName: 'D',
        component: React.lazy(() => import('pages/Tools/Translate')),
        // icon: <img alt='video-icon' src={Translator} width={33} style={{ marginLeft: '10px' }} />,
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['authenticated'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },
  // {
  //   component: ManageNotification,
  //   exact: true,
  //   icon: 'fa fa-bell',
  //   layout: `/${settings.PATHNAME}`,
  //   miniName: 'C',
  //   name: 'Thiết lập thông báo',
  //   path: '/admin/quan-ly-thong-bao',
  //   roles: ['administrator'],
  //   showInSidebar: true,
  //   sortOrder: 3,
  //   type: ['administrationRouters'],
  // },
];
