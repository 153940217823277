import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import withAuthenticator from 'HOCs/withAuthenticator';
import { query } from 'helpers/QueryHelper';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import tableData from './tableData';
import settings from 'constants/settings';
import { Tabs } from 'antd';
import i18next from 'i18next';

const fetcher = (userId) => () => {
  return query('p_VIMARU_CLASSES_GetClasses', { userId: userId });
};
const { TabPane } = Tabs;
export default withAuthenticator(({ loggedInUser }) => {
  const history = useHistory();
  const key = ['p_VIMARU_CLASSES_GetClasses'];
  const { data, isLoading } = useQuery(key, fetcher(loggedInUser?.id));
  const [currentTab, setCurrentTab] = useState(1);

  const memoData = useMemo(() => {
    if (!data) return [];
    if (currentTab == 1) {
      return data?.filter((c) => c.classState?.toUpperCase() === 'INPROGRESS' || c.classState === null);
    } else if (currentTab === '2') {
      return data?.filter((c) => c.classState?.toUpperCase() === 'FINISH');
    }
  }, [data, currentTab, isLoading]);
  return (
    <React.Fragment>
      <Tabs defaultActiveKey='1' onChange={(e) => setCurrentTab(e)}>
        <TabPane tab={i18next.t('Learning')} key='1'></TabPane>
        <TabPane tab={i18next.t('Finished')} key='2'></TabPane>
        <TabPane></TabPane>
      </Tabs>
      <DynamicTable
        rowClassName='tw-cursor-pointer'
        rowKey='id'
        loading={isLoading}
        dataSource={memoData}
        initialTableData={tableData}
        onRow={(record) => {
          return {
            onClick: () => {
              if (history.location.pathname.includes('enter-exam-score')) {
                history.push(`/${settings.PATHNAME}/teacher/enter-exam-score/classes/${record.id}`);
              } else {
                if (loggedInUser.role === 'administrator' || loggedInUser.role === 'manager') {
                  history.push(`/${settings.PATHNAME}/admin/classes/${record.id}`);
                } else if (loggedInUser.role === 'teacher') {
                  history.push(`/${settings.PATHNAME}/teacher/classes/${record.id}`);
                }
              }
            },
          };
        }}
        pagination={{ defaultPageSize: 10 }}
      />
    </React.Fragment>
  );
});
