import settings from 'constants/settings';
import React from 'react';
import { HiClipboardList } from 'react-icons/hi';

export default [
  {
    path: '/student/presentations/:presentationId/slides/:slideId',
    component: React.lazy(() => import('pages/Student/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student', 'teacher', 'manager', 'administrator'], // Học sinh và giáo viên đều có thể làm bài.
    type: ['allowedRoutes'],
  },
  {
    path: '/student/presentations/:presentationId/slides/:slideId/questions/:questionId',
    component: React.lazy(() => import('pages/Student/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student', 'teacher', 'manager', 'administrator'], // Học sinh và giáo viên đều có thể làm bài.
    type: ['allowedRoutes'],
  },
  {
    path: '/student/presentations/:presentationId/slides/:slideId/questions/result',
    component: React.lazy(() => import('pages/Student/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student', 'teacher', 'manager', 'administrator'], // Học sinh và giáo viên đều có thể làm bài.
    type: ['allowedRoutes'],
  },
  {
    path: '/student/classes/:classId',
    component: React.lazy(() => import('pages/Student/Classes/ClassDetails')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student'],
    type: ['allowedRoutes'],
  },
  {
    path: '/student/classes/:classId/classwork/assignments/:assignmentId/details',
    component: React.lazy(() => import('pages/Student/Classwork/Assignments/Details')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['student'],
    type: ['allowedRoutes'],
  },
];
