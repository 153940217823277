import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import moment from 'moment';
import React from 'react';
import { Button, DatePicker, Form, Select, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { query } from 'helpers/QueryHelper';
import tableData from './tableData';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import { Columns } from './columns';
import { useQuery } from 'react-query';
import { async } from '@firebase/util';
import DynamicButton from 'packages/DynamicButton/DynamicButton';

const ReportFromWeekToWeek = () => {
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const [loading, setLoading] = React.useState({
    loadingOptionsWeek: true,
    loadingTable: false,
    loadingOptionsHocKy: true,
  });
  const [isDataReport, setDataReport] = React.useState();
  const [options, setOptions] = React.useState({
    options: [],
    optionsWeek: [],
  });
  const [disableSelectWeek, setDisableSelectWeek] = React.useState(true);

  const [form] = Form.useForm();

  const queryDataReportDate = async (startWeek, endWeek) => {
    setLoading((prev) => ({
      ...prev,
      loadingTable: true,
    }));
    return query('p_VIMARU_REPORT_HOMEWORK_DAY', {
      FromWeek: startWeek,
      ToWeek: endWeek,
      teacherId: loggedInUser?.id,
    }).then((res) => {
      setDataReport(res);
      setLoading((prev) => ({
        ...prev,
        loadingTable: false,
      }));
    });
  };
  const queryDataHocKy = async () => {
    const newArrayHocKy = [];
    const data = await query('p_QUIZCITY_Get_FolderMenu', { studentId: loggedInUser.id });
    if (data) {
      data?.map((item) => {
        newArrayHocKy.push({
          value: item.id,
          label: item.name,
        });
      });
    }
    setOptions((prev) => ({
      ...prev,
      options: newArrayHocKy,
    }));
  };
  const queryDataWeek = async (idHocKy) => {
    const newArrayWeek = [];
    const dataWeek = await query('p_QuizCity_getWeek_Semester', { SemesterId: idHocKy });
    if (dataWeek) {
      dataWeek?.map((item) => {
        return newArrayWeek.push({
          value: item.week,
          label: item.name,
        });
      });
      setOptions((prev) => ({
        ...prev,
        optionsWeek: newArrayWeek,
      }));
    }
  };
  const onChangeValueHocKy = (value) => {
    if (value) {
      setDisableSelectWeek(false);
      queryDataWeek(value);
    }
  };
  const onFinish = (values) => {
    queryDataReportDate(values.startWeek, values.endWeek);
  };
  React.useEffect(() => {
    queryDataHocKy();
  }, []);

  return (
    <Container name='Báo cáo & Thống kê' parentName='Administration'>
      <Card title='Báo cáo & Thống kê'>
        <Form form={form} onFinish={onFinish}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 10,
              marginBottom: 20,
            }}
          >
            <Form.Item name='hocKy'>
              <Select
                showSearch
                style={{
                  width: 200,
                }}
                onChange={onChangeValueHocKy}
                placeholder='Chọn học kỳ'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={options.options}
              />
            </Form.Item>
            <Form.Item name='startWeek'>
              <Select
                showSearch
                style={{
                  width: 200,
                }}
                placeholder='Tuần bắt đầu'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                disabled={disableSelectWeek}
                options={options?.optionsWeek}
              />
            </Form.Item>
            <Form.Item name='endWeek'>
              <Select
                showSearch
                style={{
                  width: 200,
                }}
                placeholder='Tuần kết thúc'
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                disabled={disableSelectWeek}
                options={options?.optionsWeek}
              />
            </Form.Item>
            <Form.Item>
              <DynamicButton type='primary' htmlType='submit'>
                Tìm kiếm
              </DynamicButton>
            </Form.Item>
          </div>
        </Form>
        <Table
          scroll={{
            x: 1000,
          }}
          bordered
          size='middle'
          align='center'
          showHeader={true}
          columns={Columns}
          loading={loading.loadingTable}
          dataSource={isDataReport}
        />
      </Card>
    </Container>
  );
};

export default ReportFromWeekToWeek;
