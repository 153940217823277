import { all, fork } from 'redux-saga/effects';
import assignmentSaga from './AssignmentModule/sagas';
import authSagas from './Auth/sagas';
import resultSaga from './ResultModule/sagas';
// import { default as logSaga } from './LogModule/sagas';
// import { default as UpdateProfileSaga } from './profileModule/ChangeProfile/sagas';

import olympicSagas from './Olympic/sagas';
import teacherSaga from './TeacherModule/sagas';
import settingsSaga from './Settings/sagas';

export default function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(assignmentSaga),
    fork(resultSaga),
    fork(olympicSagas),
    fork(teacherSaga),
    fork(settingsSaga),
  ]);
}
