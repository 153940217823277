/* eslint-disable sort-keys */
/* eslint-disable import/prefer-default-export */

import { TypeStateTimeNextSlide } from '../typeProps';

/* eslint-disable no-unused-vars */
export const CheckScoreWorkSheet = (
  score: number,
  percentPass: number,
  setStatusButtonFooter: Function,
  role: string,
  setTimeNextSlide: Function,
  isSubmitted: boolean = false,
) => {
  const percentPassT10: number = Number(percentPass ?? 0) / 10;
  if (role?.toUpperCase() === 'STUDENT') {
    if (score >= percentPassT10) {
      setStatusButtonFooter({
        disableSubmit: true,
        disableNextPresentation: false,
        disablePrev: false,
        hiddenRetry: false,
        hiddenSubmit: false,
      });
      if (isSubmitted) {
        setTimeNextSlide((prev: TypeStateTimeNextSlide) => {
          if (prev?.duration > 0) {
            return {
              ...prev,
              hidden: false,
            };
          }
          return {
            ...prev,
            hidden: true,
          };
        });
      }
      return;
    } else {
      setStatusButtonFooter({
        disableSubmit: true,
        disableNextPresentation: true,
        hiddenRetry: false,
        disablePrev: false,
        hiddenSubmit: false,
      });
    }
  } else {
    setStatusButtonFooter({
      disableSubmit: true,
      disableNextPresentation: false,
      hiddenRetry: false,
      disablePrev: false,
      hiddenSubmit: false,
    });
  }
};
export const CheckScoreQuiz = (score: number, percentPass: number, setStatusButtonFooter: Function) => {};
