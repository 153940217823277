/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { calculateResultMultipleMatch, checkSameGroup } from './LineTo';
import DotSelect from './components/DotSelect';
import RenderLineTo from './components/RenderLineTo';

const MultipleMatch = ({ type, data, againAnswer, isDisableTest, isHiddenShowColor }) => {
  const [state, setState] = React.useState({
    userSelect: [],
    userAnswers: [],
    arrayAnswers: [],
  });
  const [result, setResult] = React.useState([]);

  // tính đáp án đúng mỗi khi data thay đổi. Cấu trúc: [group1, group2 = {color1:[`${min}-${max}`,''], color2}]
  useEffect(() => {
    if (againAnswer) {
      setState((pre) => ({
        ...pre,
        listBoolean: againAnswer.listBoolean,
        userAnswers: againAnswer.userAnswers,
      }));
    } else {
      const newData = data?.map((x) => ({
        text: x.text,
        countMatch: x.countMatch,
        icon: x.icon,
        groupName: x.groupName,
      }));
      // chia data theo groupName
      const groups = _.groupBy(newData, 'groupName');
      const correctAnswers = Object.values(groups).map((groupItem, groupIndex) => {
        // Gom nhóm theo màu trong từng group
        const groupColors = _.groupBy(groupItem, 'text');
        // tính đáp án cho từng màu
        const colorsAnswer = Object.keys(groupColors).map((color) => {
          // lấy các điểm nối có cùng màu
          const itemOfColor = groupColors[color];
          // lưu các điểm thuộc màu đó sau này dùng check khi nối dư
          const indexPointArray = itemOfColor.map((item) => item.countMatch);
          // gom nhóm nguồn (start) và đích (end) để nối đáp đúng theo từng cặp
          const groupPoint = _.groupBy(itemOfColor, 'icon');
          const startPointsArr = groupPoint['fas fa-circle']; // điểm nguồn
          const endPointsArr = groupPoint['far fa-dot-circle']; // điểm đích
          //Nếu không có điểm nguồn và điểm đích thì return về rỗng
          if (!startPointsArr || !endPointsArr)
            return [
              color,
              Object.fromEntries([
                ['lines', []],
                ['points', indexPointArray],
              ]),
            ];
          else {
            // Tính các cặp nối đúng trong câu là ghép từng phần tử điểm nguồn và điểm đích
            const correctColorArr = startPointsArr.map((startPoint) => {
              const correctTwoPointsArr = endPointsArr.map((endPoint) => {
                // xếp đáp án theo thứ tự min - max countMatch cảu điểm nối
                return [startPoint.countMatch, endPoint.countMatch].sort((a, b) => a - b).join('-');
              });
              return correctTwoPointsArr; // đáp án từng cặp nối
            });
            return [
              color,
              Object.fromEntries([
                ['lines', _.flattenDeep(correctColorArr)],
                ['points', indexPointArray],
              ]),
            ]; // đáp án từng nhóm color
          }
        });
        return Object.fromEntries(colorsAnswer); // đáp án từng group
      });
      setState((prev) => ({ ...prev, arrayAnswers: correctAnswers }));
    }
  }, [data?.length,againAnswer]);

  const handleClick = React.useCallback(
    (id, groupName) => {
      if (isDisableTest) return;
      let { userSelect = [], userAnswers = [] } = state;
      userSelect.push(id);
      userSelect = [...new Set(userSelect)]; // loại bỏ trùng lặp
      // Kiểm tra không cùng nhóm => không nối.
      if (!checkSameGroup(userSelect, data)) {
        userSelect = []; // clear
      }
      if (userSelect.length >= 2) {
        const newline = userSelect.sort((a, b) => a - b).join('-');
        if (userAnswers.includes(newline)) {
          // tìm đường đã nối
          userAnswers = userAnswers.filter((x) => x !== newline); // xóa đường nối
        } else {
          userAnswers.push(newline); // thêm đường mới
        }
        userSelect = []; // clear
      }
      setState((pre) => ({ ...pre, userSelect, userAnswers }));
    },
    [data, state],
  );

  useEffect(() => {
    Object.assign(type, {
      submit: () => {
        // handle submit
        if (!data) return {};
        const resultObject = calculateResultMultipleMatch(state.userAnswers ?? [], state.arrayAnswers ?? [], data);
        if (!resultObject) return {};
        resultObject.userAnswers = state.userAnswers;
        setResult(resultObject);
        return { multipleMatchResult: resultObject };
      },
      tryAgain: () => {
        // setState({
        //   userSelect: [],
        //   userAnswers: [],
        // });
        // setResult([]);
        // setIsDone(false);
      },
      setResult,
    });
    return () => {};
  }, [data, type, state]);

  if (!data) return null;

  return (
    <div>
      {data.map((item, index) => {
        const { top, left, width, height, groupName } = item;
        return (
          <div
            key={`multiplematch-${index}`}
            className={`multiplematch-dot-${index}`}
            style={{
              position: 'absolute',
              top,
              left,
              width,
              height,
              border: '2px dotted gray',
              backgroundColor: '#b1d9f533',
              cursor: 'pointer',
              borderRadius: 8,
              cursor: isDisableTest ? 'no-drop' : 'pointer',
            }}
            onClick={() => {
              if (isDisableTest) return {};
              handleClick(index, groupName);
            }}
          >
            <DotSelect id={index} userSelect={state.userSelect} />
          </div>
        );
      })}
        <RenderLineTo
          userAnswers={state.userAnswers}
          isDone={isDisableTest}
          isDisableTest={isDisableTest}
          listBoolean={state?.listBoolean}
          isHiddenShowColor={isHiddenShowColor}
        />
    </div>
  );
};

MultipleMatch.propTypes = {
  // type: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default MultipleMatch;
