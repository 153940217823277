/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { deleteFile, get, remove } from 'helpers/QueryHelper';

import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { getSocket } from 'helpers/SocketHelper';
import tableData from './compactTableData';
import DeletePopconfirm from '../Popconfirm/DeletePopconfirm/DeletePopconfirm';

const socket = getSocket();

type Props = {
  entityId: string;
  entityName: string;
  moduleName?: string;
  subModuleName: string;
  canDelete: boolean;
};

const fetcher = (entityId: string, entityName: string) => (): Promise<any> => {
  const fields = '*';
  const parameters = {
    entityId,
    entityName,
  };
  const sort = 'CreatedDate';
  // Get t_SACHSO_SHARE_Attachements by EntityId and EntityName
  return get('Attachments', fields, parameters, sort, 'SHARE', 'SACHSO');
};

const CompactFiles = ({ moduleName, subModuleName, entityId, entityName, canDelete = false }: Props) => {
  const queryClient = useQueryClient();
  const key = ['t_SHARE_SACHSO_Attachments', entityId, entityName];

  const { data, isLoading, isSuccess } = useQuery(
    key,
    fetcher(entityId, `t_${moduleName}_${subModuleName}_${entityName}`),
    {
      enabled: entityId !== undefined,
    },
  );

  const extraColumns = [
    {
      key: 'action-delete',
      width: '1%',
      sort: false,
      render: (text: string, record: { id: string; fileName: string }, index: number) => {
        const handleConfirmDelete = async () => {
          // DELETE
          await remove('Attachments', record.id, 'SHARE');
          // DELETE FILENAME IN FOLDERS
          await deleteFile(entityName, entityId, record.fileName, subModuleName, moduleName);
          // RELOAD
          queryClient.invalidateQueries(key);

          // SOCKET: EMIT
          socket.emit('send', {
            messageType: 'query',
            payload: { roomId: `t_SHARE_Attachments_${entityName}_${entityId}`.toUpperCase() },
          });
          // console.log('🚀 ~ record', record);
        };
        return <DeletePopconfirm tooltipPlacement='top' onConfirm={handleConfirmDelete} />;
      },
    },
  ];

  React.useEffect(() => {
    // SOCKET: JOIN ROOM
    socket.emit('send', {
      messageType: 'join-room',
      payload: { roomId: `t_SHARE_Attachments_${entityName}_${entityId}`.toUpperCase() },
    });

    // SOCKET: LISTEN
    socket.on('server-message', (response) => {
      switch (response.messageType) {
        case 'query':
          queryClient.invalidateQueries(key);
          break;
        default:
          break;
      }
    });
  }, []);

  return (
    <React.Fragment>
      {isSuccess && (
        <DynamicTable
          loading={isLoading}
          dataSource={data}
          initialTableData={tableData}
          extraColumns={canDelete ? extraColumns : null}
          onReload={undefined}
          pagination={false}
        />
      )}
    </React.Fragment>
  );
};

CompactFiles.defaultProps = {
  moduleName: 'SACHSO',
};

export default CompactFiles;
