import withAuthenticator from 'HOCs/withAuthenticator';
import React from 'react';
import { LoggedInUserType } from 'types';

type Props = {
  loggedInUser?: LoggedInUserType;
  children?: JSX.Element | JSX.Element[];
};

const StudentContainer = ({ loggedInUser, children }: Props) => {
  if (loggedInUser && loggedInUser.role === 'student') {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return null;
};

StudentContainer.defaultProps = {
  loggedInUser: null,
  children: null,
};

export default withAuthenticator(StudentContainer);
