import { Button } from 'antd';
import React from 'react';
// import './DynamicButton.css';

type Props = {
  children?: any;
  disabled?: boolean;
  ghost?: boolean;
  block?: boolean;
  onClick?: () => void;
  loading?: boolean | { delay: number };
  href?: string;
  htmlType?: string | 'button' | 'submit' | 'reset' | undefined;
  icon?: string;
  shape?: 'default' | 'circle' | 'round';
  size?: 'small' | 'large' | 'middle' | 'default' | undefined;
  target?: string;
  type?: 'primary' | 'danger' | 'ghost' | 'default' | 'dashed' | 'link';
  style?: React.CSSProperties;
  classNames?: string;
  key?: string;
};

const DynamicButton = ({
  children,
  type,
  onClick,
  style,
  loading,
  disabled,
  href,
  htmlType,
  icon,
  shape,
  size,
  target,
  block,
  classNames,
  key,
  ...props
}: Props) => {
  return (
    <Button
      // target={target}
      // href={href}
      disabled={disabled}
      size={size}
      htmlType={htmlType}
      key={key}
      shape={shape}
      loading={loading}
      icon={icon}
      // className={`${
      //   settings.IS_LG && type === 'primary' ? 'lg-btn2' : settings.IS_LG && type !== 'primary' ? 'lg-btn' : ''
      // } ${classNames}`}
      className={classNames}
      style={style}
      onClick={onClick}
      block={block}
      type={type}
      {...props}
    >
      {children}
    </Button>
  );
};

DynamicButton.defaultProps = {
  children: null,
  disabled: false,
  ghost: false,
  block: false,
  onClick: null,
  loading: false,
  href: null,
  htmlType: 'button',
  icon: null,
  shape: 'default',
  size: 'default',
  target: '',
  type: 'default',
  style: {},
  classNames: '',
  key: '',
  danger: false,
  tooltipTitle: '',
};

export default DynamicButton;
