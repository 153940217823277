import React from 'react';
import { AiFillIdcard, AiTwotoneShop } from 'react-icons/ai';
import { FaCogs } from 'react-icons/fa';
import { HiClipboardList, HiOutlinePresentationChartBar } from 'react-icons/hi';
import { IoLibraryOutline } from 'react-icons/io5';
import { MdClass } from 'react-icons/md';
import { SiGoogleclassroom } from 'react-icons/si';
import { TbReportAnalytics } from 'react-icons/tb';
import { DOMAIN_IS_USE, IS_BASAO, IS_MENARD, IS_TRONGDONG } from 'constants/settings';

const URL_HuongDanSuDung = IS_TRONGDONG
  ? `${DOMAIN_IS_USE}/templates/pdfs/huong-dan-su-dung-Trong-Dong.pdf`
  : IS_BASAO
  ? `${DOMAIN_IS_USE}/templates/pdfs/huong-dan-su-dung-tristar.pdf`
  : IS_MENARD
  ? 'https://cloud.softech.vn/storage/Data/MENARD/Huong-dan-su-dung-cho-Admin-Menard.pdf'
  : `${DOMAIN_IS_USE}/templates/pdfs/huong-dan-su-dung-demo.pdf`;

const fileHelp = {
  name: 'Hướng dẫn sử dụng',
  href: URL_HuongDanSuDung,
  icon: <HiClipboardList size={20} className='tw-text-blue-500' />,
  layout: '/lms',
  exact: true,
  roles: ['administrator'],
  showInSidebar: true,
  type: ['allowedRoutes'],
  sortOrder: 9999,
};

export default [
  {
    path: '/admin/import-question',
    name: 'Quản lý câu hỏi',
    miniName: 'C',
    icon: 'fas fa-question text-green',
    component: React.lazy(() => import('pages/Administration/ImportQuestion')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 0,
  },

  // {
  //   path: '/admin/insert-type',
  //   name: 'Type & Options Type',
  //   miniName: 'C',
  //   icon: <FaCogs style={{ color: '#EC4899' }} />,
  //   component: IndexCategoryType,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['administrationRouters'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },
  {
    component: React.lazy(() => import('pages/Teacher/Classes/Classes')),
    exact: true,
    icon: <SiGoogleclassroom size={20} className='tw-text-red-500' />,
    layout: '/lms',
    miniName: 'C',
    name: 'Quản lý lớp học',
    path: '/admin/classes',
    roles: ['administrator'],
    showInSidebar: true,
    sortOrder: 0,
    type: ['allowedRoutes'],
  },
  {
    collapse: true,
    path: '/admin/report',
    name: 'Báo cáo, thống kê',
    miniName: 'C',
    icon: <TbReportAnalytics size={20} style={{ color: '#AC81FF' }} />,
    roles: ['administrator', 'teacher'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
    state: 'lms/admin/report',
    views: [
      {
        path: '/admin/courses_report',
        name: 'Báo cáo khoá học',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportCourses')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
      {
        path: '/admin/synthetic_report',
        name: 'Báo cáo tổng hợp',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportSynthetic')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 2,
      },
    ],
  },

  {
    component: React.lazy(() => import('pages/Teacher/Classes/ClassDetails')),
    exact: true,
    layout: '/lms',
    path: '/admin/classes/:classId',
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/danh-muc-chuc-vu',
    name: 'Danh mục chức vụ',
    miniName: 'C',
    icon: <AiFillIdcard color='#3B82F6' size={19} />,
    component: React.lazy(() => import('pages/Administration/Menard/ManagerPositions')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 1,
  },
  {
    path: '/admin/danh-muc-bo-phan',
    name: 'Danh mục bộ phận',
    miniName: 'C',
    icon: <AiTwotoneShop color='#fb7240' size={19} />,
    component: React.lazy(() => import('pages/Administration/Menard/ManagerShopsV2')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 1,
  },
  {
    path: '/admin/users',
    name: 'Quản lý người dùng',
    miniName: 'C',
    icon: 'fa fa-user text-red',
    component: React.lazy(() => import('pages/Administration/Users/index')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 0,
  },
  {
    path: '/admin/danh-muc-khoa-hoc',
    name: 'Khoá học',
    miniName: 'C',
    icon: <MdClass />,
    component: React.lazy(() => import('pages/Administration/ManagerCourse')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 1,
  },
  {
    path: '/admin/add-question',
    name: 'Quản lý câu hỏi',
    miniName: 'C',
    component: React.lazy(() => import('pages/Administration/FormAddQuestions')),
    icon: 'fas fa-book text-green',
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: false,
  },
  // {
  //   path: '/admin/students',
  //   name: 'Quản lý học viên',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Administration/Vimaru/StudentManagement')),
  //   icon: <ClassIcon size={20} className='tw-text-yellow-500' />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['allowedRoutes'],
  //   showInSidebar: true,
  //   sortOrder: 1,
  // },

  {
    path: '/admin/presentations',
    name: 'Quản lý bài giảng',
    miniName: 'C',
    component: React.lazy(() => import('pages/Teacher/Presentations')),
    icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500' />,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 2,
  },

  {
    path: '/admin/presentation/:presentationId/create',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/presentation/:presentationId/edit',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },
  {
    collapse: true,
    path: '/admin/worksheets',
    name: 'Quản lý bài tập - Đề thi',
    miniName: 'C',
    icon: <IoLibraryOutline size={20} className='tw-text-green-600' />,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
    state: 'lms/admin/worksheets',
    views: [
      {
        path: '/admin/worksheets/list/:filter',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
      },
      // {
      //   path: '/admin/worksheets/list/quizcity-manual',
      //   name: 'Bài tập Manual',
      //   miniName: 'D',
      //   component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
      //   layout: '/lms',
      //   exact: true,
      //   roles: ['administrator'],
      //   type: ['allowedRoutes'],

      //   showInSidebar: true,
      //   sortOrder: 0,
      // },
      // {
      //   path: '/admin/worksheets/list/quizcity-worksheet',
      //   name: 'Bài tập Worksheet',
      //   miniName: 'D',
      //   component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
      //   layout: '/lms',
      //   exact: true,
      //   roles: ['administrator'],
      //   type: ['allowedRoutes'],

      //   showInSidebar: true,
      //   sortOrder: 1,
      // },
      {
        path: '/admin/worksheets/list/quizcity-quiz',
        name: 'Bài tập Quiz',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },

  {
    path: '/admin/assigments',
    name: 'Quản lý bài tập',
    miniName: 'C',
    component: React.lazy(() => import('modules/TeacherModule/components/AssignmentsManagement/components')),
    icon: 'fas fa-book text-green',
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: false,
    sortOrder: 4,
  },

  // {
  //   path: '/admin/quizcity/worksheets/update',
  //   name: 'Tạo worksheet',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Teacher/QuizCity/UpdateWorkSheet')),
  //   icon: <ClassIcon size={20} className='tw-text-blue-600' />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },
  // {
  //   path: '/admin/quizcity/worksheets/update/:id',
  //   component: React.lazy(() => import('pages/Teacher/QuizCity/UpdateWorkSheet')),
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  // },

  {
    path: '/admin/classes/:classId/classwork/assignments/add',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/edit/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/details/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/Details')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/configs',
    name: 'Thiết lập thông tin',
    component: React.lazy(() => import('pages/Administration/ConfigSettings')),
    icon: <FaCogs style={{ color: '#EC4899' }} />,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 8,
  },
  fileHelp,
];
