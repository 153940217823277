import { Card, Col, Progress, Row, Space } from 'antd';
import numeral from 'numeral';
import React from 'react';
import { AiFillCheckCircle, AiFillLock, AiFillPlayCircle, AiFillStar } from 'react-icons/ai';
import { FiCheckSquare, FiEdit, FiFileText, FiVideo } from 'react-icons/fi';
import styles from './presentation.module.css';
import './index.css';
import colors from 'constants/colors';
import { Link, useHistory } from 'react-router-dom';
import getFirstQuestionId from 'modules/Presentation/api/getFirstQuestionId';
import AI_slide_icon from 'assets/icon/slidesIcons/AI.png';
import Objective_slide_icon from 'assets/icon/slidesIcons/Objective.png';
import Summary_slide_icon from 'assets/icon/slidesIcons/Summary.png';
import Task_slide_icon from 'assets/icon/slidesIcons/Task.png';
import Video_slide_icon from 'assets/icon/slidesIcons/Video.png';
import Instruction_slide_icon from 'assets/icon/slidesIcons/Instruction.png';
import { PresentationEntity, SlideProps } from 'modules/Presentation/types';
import { query } from 'helpers/QueryHelper';
import WarningModal from 'pages/Student/WarningModal';
import { useSelector } from 'react-redux';
import { MdOutlineNoteAlt } from 'react-icons/md';
import settings from 'constants/settings';

type Props = {
  color: string;
  presentation: any;
};
export default function Presentation({ color, presentation }: Props) {
  const history = useHistory();
  const [visibleWarningModal, setVisibleWarningModal] = React.useState(false);
  const [contentWarningModal, setContentWarningModal] = React.useState('');
  const loggedInUser = useSelector((rootState) => rootState.authReducer.loggedInUser);
  const moveToSlide = (presentation: PresentationEntity, slide: SlideProps) => {
    query('p_QUIZCITY_CheckPermissonLearning', { slideId: slide.id, studentId: loggedInUser?.id }).then(async (res) => {
      if (res?.[0]?.warningMessage) {
        setVisibleWarningModal(true);
        setContentWarningModal(res?.[0]?.warningMessage);
      } else {
        let questionId = await getFirstQuestionId(slide.id);
        if (slide.status !== 'Locked') {
          if (slide.type === 'Quiz')
            return history.push(
              `/${settings.PATHNAME}/student/presentations/${presentation.id}/slides/${slide.id}/questions/${questionId}`,
            );
          return history.push(`/${settings.PATHNAME}/student/presentations/${presentation.id}/slides/${slide.id}`);
        }
      }
    });
  };

  const renderIcon = (slide: any) => {
    if (slide.icon) {
      switch (slide.icon) {
        case 'Video':
          return <img alt='video-icon' src={Video_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Task':
          return <img alt='video-icon' src={Task_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Summary':
          return <img alt='video-icon' src={Summary_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Objective':
          return <img alt='video-icon' src={Objective_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Instruction':
          return <img alt='video-icon' src={Instruction_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'AI':
          return <img alt='video-icon' src={AI_slide_icon} width={30} style={{ marginRight: '5px' }} />;

        default:
          break;
      }
    } else {
      switch (slide.type) {
        case 'Quiz':
          return <img alt='video-icon' src={Task_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'WorkSheet':
          return <img alt='video-icon' src={Task_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Video':
          return <img alt='video-icon' src={Video_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Word':
          return <img alt='video-icon' src={Summary_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        default:
          break;
      }
    }
  };

  const renderSlides = () => {
    const slides: JSX.Element[] = presentation?.slides
      ?.sort((a, b) => a.sortOrder - b.sortOrder)
      ?.map((slide: SlideProps) => (
        <Row
          className={styles.row_presentation_slide}
          onClick={() => moveToSlide(presentation, slide)}
          style={{
            cursor: slide.status !== 'Locked' ? 'pointer' : 'not-allowed',
          }}
        >
          <Col span={1} style={{ backgroundColor: presentation.color ?? color ?? 'rgb(120, 166, 243)' }}></Col>
          <Col className='p-2' span={23} style={{ backgroundColor: '#F5F5F5' }}>
            <Row>
              <Col span={21} style={{ alignItems: 'center', display: 'flex' }}>
                {/* {renderIcon(slide)} */}
                {slide.type === 'Video' && <FiVideo className='tw-mr-2' />}
                {slide.type === 'WorkSheet' && <FiEdit className='tw-mr-2' />}
                {slide.type === 'Word' && <FiFileText className='tw-mr-2' />}
                {slide.type === 'Quiz' && <FiCheckSquare className='tw-mr-2' />}
                {slide.type === 'Note' && <MdOutlineNoteAlt className='tw-mr-2' />}
                {slide?.title}
              </Col>
              <Col span={2} style={{ textAlign: 'center', paddingRight: 5 }}>
                {slide.status === 'Completed' && <AiFillCheckCircle color={colors.green} size={20} />}
                {slide.status === 'Locked' && <AiFillLock color={colors.dimGray} size={20} />}
                {slide.status === 'Playing' && <AiFillPlayCircle color={colors.gold} size={20} />}
              </Col>
            </Row>
            {(slide.type === 'WorkSheet' ||
              slide.type === 'Quiz' ||
              slide.type === 'Note' ||
              (slide.type === 'Video' && slide?.quizAssignmentId !== '914B9045-F740-43C1-9ABB-C21DC4899AB1')) && (
              <Row style={{ display: 'flex', marginTop: 5 }}>
                <Col span={12}>
                  <Progress
                    trailColor={'	#DCDCDC'}
                    strokeColor={presentation.color ?? color ?? 'rgb(120, 166, 243)'}
                    percent={slide.percentCompleted * 100}
                    showInfo={false}
                  />
                </Col>
                <Col span={5} style={{ textAlign: 'center' }}>
                  <span className='tw-ml-2'>{numeral(slide.percentCompleted).format('%')}</span>
                </Col>
                <Col span={7}>
                  {(slide.type === 'WorkSheet' || slide.type === 'Quiz' || slide.type === 'Note') && (
                    <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <AiFillStar color={colors.gold} size={20} className='ml-1 tw-2' />
                      {slide?.score === 10 ? slide?.score : slide?.score?.toFixed(1)}
                    </Row>
                  )}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      ));
    return slides || [];
  };

  return (
    <React.Fragment>
      <Card
        style={{
          border: '1px solid #ccc',
          marginBlock: !settings.IS_LG ? 10 : 0,
          borderRadius: 10,
          borderTop: !settings.IS_LG ? '' : 'none',
          minWidth: 150,
        }}
        title={<div>{presentation.title}</div>}
        headStyle={{
          color: 'white',
          backgroundColor: presentation.color ?? color ?? 'rgb(120, 166, 243)',
        }}
        bodyStyle={{ padding: '0px 10px' }}
        className={'lg-card'}
      >
        {renderSlides()}
      </Card>
      <WarningModal
        visibleWarningModal={visibleWarningModal}
        setVisibleWarningModal={setVisibleWarningModal}
        contentWarningModal={contentWarningModal}
      />
    </React.Fragment>
  );
}
