import React from 'react';
import { Image, Button, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import i18next from 'i18next';

const ImageNote: React.FC = ({ src, style, onPreview, onDelete }: any) => {
  const [hovered, setHovered] = React.useState<boolean>(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <div
        style={{
          position: 'relative',
          display: 'inline-block',
          overflow: 'hidden',
          borderRadius: '4px',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Image preview={false} style={style} src={src} />
        {hovered && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Tooltip title={i18next.t('upload:UploadNote.ImageNote.Look at the photos')}>
              <Button onClick={onPreview} style={{ color: 'white' }} type='text' icon={<EyeOutlined />} />
            </Tooltip>
            <Tooltip title={i18next.t('upload:UploadNote.ImageNote.Delete photos')}>
              <Button onClick={onDelete} style={{ color: 'white' }} type='text' icon={<DeleteOutlined />} />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageNote;
