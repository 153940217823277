import Class_Students from 'modules/Teacher/components/EnterExamScore/Students/index.js';
import Teacher_Classes_Details from 'pages/Teacher/Classes/ClassDetails';
import Teacher_Classes from 'pages/Teacher/Classes/Classes';
import React from 'react';
// Icons
import settings, { DOMAIN_IS_USE, IS_BASAO, IS_MENARD, IS_TRONGDONG } from 'constants/settings';
import IndexCategoryType from 'pages/Administration/Category';
import IndexImportQuestion from 'pages/Administration/ImportQuestion';
import ManagerCourse from 'pages/Administration/ManagerCourse';
import Users from 'pages/Administration/Users/index';
import { AiFillIdcard, AiTwotoneShop } from 'react-icons/ai';
import { HiClipboardList, HiOutlinePresentationChartBar } from 'react-icons/hi';
import { IoMdOptions } from 'react-icons/io';
import { IoLibraryOutline } from 'react-icons/io5';
import { MdClass, MdGrade } from 'react-icons/md';
import { SiGoogleclassroom } from 'react-icons/si';
import { TbReportAnalytics } from 'react-icons/tb';

const URL_HuongDanSuDung = IS_TRONGDONG
  ? `${DOMAIN_IS_USE}/templates/pdfs/huong-dan-su-dung-Trong-Dong.pdf`
  : IS_BASAO
  ? `${DOMAIN_IS_USE}/templates/pdfs/huong-dan-su-dung-tristar.pdf`
  : IS_MENARD
  ? 'https://cloud.softech.vn/storage/Data/MENARD/Huong-dan-su-dung-cho-Quan-tri-vien-Menard.pdf'
  : `${DOMAIN_IS_USE}/templates/pdfs/huong-dan-su-dung-demo.pdf`;

const fileHelp = {
  name: 'Hướng dẫn sử dụng',
  href: URL_HuongDanSuDung,
  icon: <HiClipboardList size={20} className='tw-text-blue-500' />,
  layout: '/lms',
  exact: true,
  roles: ['manager'],
  showInSidebar: true,
  type: ['allowedRoutes'],
  sortOrder: 9999,
};

export default [
  {
    component: Teacher_Classes,
    exact: true,
    icon: <SiGoogleclassroom size={20} className='tw-text-yellow-500' />,
    layout: '/lms',
    miniName: 'C',
    name: 'Quản lý lớp học',
    // icon: <img alt='video-icon' src={classManager} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
    path: '/admin/classes',
    roles: ['manager'],
    showInSidebar: true,
    sortOrder: 2,
    type: ['allowedRoutes'],
  },
  {
    component: Teacher_Classes_Details,
    exact: true,
    layout: '/lms',
    path: '/admin/classes/:classId',
    roles: ['manager'],
    type: ['allowedRoutes'],
  },
  {
    component: Teacher_Classes,
    exact: true,
    icon: <MdGrade size={20} className='tw-text-yellow-500' />,
    layout: '/lms',
    miniName: 'C',
    name: 'Nhập điểm thi offline',
    // icon: <img alt='video-icon' src={classManager} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
    path: '/admin/enter-exam-score/classes',
    roles: ['manager'],
    showInSidebar: settings.IS_VIMARU,
    sortOrder: 6,
    type: ['allowedRoutes'],
  },
  {
    component: Class_Students,
    exact: true,
    layout: '/lms',
    path: '/admin/enter-exam-score/classes/:classId',
    roles: ['manager'],
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/edit/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/presentation/:presentationId/create',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/presentation/:presentationId/edit',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['allowedRoutes'],
  },
  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/details/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/Details')),
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['allowedRoutes'],
  },

  {
    collapse: true,
    path: '/admin/report',
    name: 'Báo cáo, thống kê',
    miniName: 'C',
    icon: <TbReportAnalytics size={20} style={{ color: '#AC81FF' }} />,
    roles: ['manager'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
    state: 'lms/admin/report',
    views: [
      {
        path: '/admin/courses_report',
        name: 'Báo cáo khoá học',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportCourses')),
        layout: '/lms',
        exact: true,
        roles: ['manager'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
      {
        path: '/admin/synthetic_report',
        name: 'Báo cáo tổng hợp',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportSynthetic')),
        layout: '/lms',
        exact: true,
        roles: ['manager'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 2,
      },
    ],
  },
  {
    path: '/admin/presentations',
    name: 'Quản lý bài giảng',
    miniName: 'C',
    component: React.lazy(() => import('pages/Teacher/Presentations')),
    icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500' />,
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 2,
  },
  {
    collapse: true,
    path: '/admin/worksheets',
    name: 'Quản lý bài tập - Đề thi',
    miniName: 'C',
    icon: <IoLibraryOutline size={20} className='tw-text-green-600' />,
    roles: ['manager'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
    state: 'lms/admin/worksheets',
    views: [
      {
        path: '/admin/worksheets/list/:filter',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/lms',
        exact: true,
        roles: ['manager'],
        type: ['allowedRoutes'],
      },
      {
        path: '/admin/worksheets/list/quizcity-quiz',
        name: 'Bài tập Quiz',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/lms',
        exact: true,
        roles: ['manager'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },
  {
    path: '/admin/add-question',
    name: 'Quản lý câu hỏi',
    miniName: 'C',
    component: IndexImportQuestion,
    icon: 'fas fa-book text-green',
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 1,
  },
  {
    path: '/admin/danh-muc-khoa-hoc',
    name: 'Khoá học',
    miniName: 'C',
    icon: <MdClass />,
    component: ManagerCourse,
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 2,
  },
  {
    path: '/admin/users',
    name: 'Quản lý người dùng',
    miniName: 'C',
    icon: 'fa fa-user text-red',
    component: Users,
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 3,
  },
  {
    path: '/admin/danh-muc-chuc-vu',
    name: 'Danh mục chức vụ',
    miniName: 'C',
    icon: <AiFillIdcard color='#3B82F6' size={19} />,
    component: React.lazy(() => import('pages/Administration/Menard/ManagerPositions')),
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 4,
  },
  {
    path: '/admin/danh-muc-bo-phan',
    name: 'Danh mục bộ phận',
    miniName: 'C',
    icon: <AiTwotoneShop color='#fb7240' size={19} />,
    component: React.lazy(() => import('pages/Administration/Menard/ManagerShopsV2')),
    layout: '/lms',
    exact: true,
    roles: ['manager'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 5,
  },
  {
    path: '/admin/insert-type',
    name: 'Type & Options Type',
    miniName: 'C',
    icon: <IoMdOptions color='#2DCE89' />,
    component: IndexCategoryType,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 6,
  },
].concat(URL_HuongDanSuDung ? [fileHelp] : []);
