/* eslint-disable react/jsx-props-no-spreading */
import VimaruNavbar from 'components/Navbars/VimaruNavbar';
import { IS_BASAO, IS_ISE, IS_LG, IS_MENARD, IS_TRONGDONG, IS_TVHH, IS_VIMARU, PATHNAME } from 'constants/settings';
import { init } from 'modules/Auth/actions';
import LoginPage from 'modules/Auth/components/Login';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function QuizCityPage(props) {
  const history = useHistory();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (loggedInUser) {
      // const { role } = loggedInUser;
      history.push(`/${PATHNAME}/home`);
    } else {
      dispatch(init());
    }
  }, [dispatch, history, loggedInUser]);

  return (
    <React.Fragment>
      {IS_VIMARU || IS_MENARD || IS_LG || IS_ISE || IS_TVHH || IS_BASAO || IS_TRONGDONG? (
        <VimaruNavbar />
      ) : (
        <LoginPage {...props} />
      )}
    </React.Fragment>
  );
}
