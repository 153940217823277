import React from 'react';
import Teacher_Classes from 'pages/Teacher/Classes/Classes';
import Teacher_Classes_Details from 'pages/Teacher/Classes/ClassDetails';
import Class_Students from 'modules/Teacher/components/EnterExamScore/Students/index.js';
// Icons
import { MdGroup as ClassIcon, MdDoneOutline, MdOutlineEventNote, MdGrade, MdClass } from 'react-icons/md';
import AssignmentsManagement from 'modules/TeacherModule/components/AssignmentsManagement/components';
import classManager from 'assets/icon/homeIcons/classManagerIcon.png';
import StatisticalReport from 'assets/icon/homeIcons/StatisticalReportIcon.png';
import { HiClipboardList, HiOutlinePresentationChartBar } from 'react-icons/hi';
import settings, { DOMAIN_IS_USE, IS_BASAO, IS_LX, IS_TRONGDONG } from 'constants/settings';
import { SiGoogleclassroom } from 'react-icons/si';
import ManagerCourse from 'pages/Administration/ManagerCourse';
import Users from 'pages/Administration/Users/index';
import IndexImportQuestion from 'pages/Administration/ImportQuestion';
import { IoLibraryOutline } from 'react-icons/io5';

const URL_HuongDanSuDung = settings.IS_VIMARU
  ? 'https://cloud.softech.vn/storage/Data/VIMARU/Huong-dan-su-dung-cho-Giao-vien.pdf'
  : settings.IS_MENARD
  ? 'https://cloud.softech.vn/storage/Data/MENARD/Huong-dan-su-dung-cho-Giao-vien-Menard.pdf'
  : IS_BASAO || IS_TRONGDONG || IS_LX
  ? `${DOMAIN_IS_USE}/templates/pdfs/huong-dan-su-dung-demo.pdf`
  : null;

const fileHelp = {
  name: 'Hướng dẫn sử dụng',
  href: URL_HuongDanSuDung,
  icon: <HiClipboardList size={20} className='tw-text-blue-500' />,
  layout: '/lms',
  exact: true,
  roles: ['teacher'],
  showInSidebar: true,
  type: ['allowedRoutes'],
  sortOrder: 9999,
};

const extraMenardTeacherRoute = settings.IS_MENARD
  ? [
      {
        path: '/admin/presentations',
        name: 'Quản lý bài giảng',
        miniName: 'C',
        component: React.lazy(() => import('pages/Teacher/Presentations')),
        icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500' />,
        layout: '/lms',
        exact: true,
        roles: ['teacher'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 2,
      },
      {
        collapse: true,
        path: '/admin/worksheets',
        name: 'Quản lý bài tập - Đề thi',
        miniName: 'C',
        icon: <IoLibraryOutline size={20} className='tw-text-green-600' />,
        roles: ['teacher'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 3,
        state: 'lms/admin/worksheets',
        views: [
          {
            path: '/admin/worksheets/list/:filter',
            component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
            layout: '/lms',
            exact: true,
            roles: ['teacher'],
            type: ['allowedRoutes'],
          },
          {
            path: '/admin/worksheets/list/quizcity-quiz',
            name: 'Bài tập Quiz',
            miniName: 'D',
            component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
            layout: '/lms',
            exact: true,
            roles: ['teacher'],
            type: ['allowedRoutes'],
            showInSidebar: true,
            sortOrder: 1,
          },
        ],
      },
      {
        path: '/admin/users',
        name: 'Quản lý người dùng',
        miniName: 'C',
        icon: 'fa fa-user text-red',
        component: Users,
        layout: '/lms',
        exact: true,
        roles: ['teacher'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 100,
      },
      {
        path: '/admin/danh-muc-khoa-hoc',
        name: 'Khoá học',
        miniName: 'C',
        icon: <MdClass />,
        component: ManagerCourse,
        layout: '/lms',
        exact: true,
        roles: ['teacher'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 101,
      },
      {
        path: '/admin/add-question',
        name: 'Quản lý câu hỏi',
        miniName: 'C',
        component: IndexImportQuestion,
        icon: 'fas fa-book text-green',
        layout: '/lms',
        exact: true,
        roles: ['teacher'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 102,
      },
    ]
  : [];

export default [
  {
    component: Teacher_Classes,
    exact: true,
    icon: <SiGoogleclassroom size={20} className='tw-text-yellow-500' />,
    layout: '/lms',
    miniName: 'C',
    name: 'Quản lý lớp học',
    // icon: <img alt='video-icon' src={classManager} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
    path: '/teacher/classes',
    roles: ['teacher'],
    showInSidebar: true,
    sortOrder: 2,
    type: ['allowedRoutes'],
  },
  {
    component: Teacher_Classes_Details,
    exact: true,
    layout: '/lms',
    path: '/teacher/classes/:classId',
    roles: ['teacher'],
    type: ['allowedRoutes'],
  },
  {
    component: Teacher_Classes_Details,
    exact: true,
    layout: '/lms',
    path: '/teacher/classes/:classId/studentEmail/:studentEmail',
    roles: ['teacher', 'administrator'],
    type: ['allowedRoutes'],
  },
  {
    component: Teacher_Classes,
    exact: true,
    icon: <MdGrade size={20} className='tw-text-yellow-500' />,
    layout: '/lms',
    miniName: 'C',
    name: 'Nhập điểm thi offline',
    // icon: <img alt='video-icon' src={classManager} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
    path: '/teacher/enter-exam-score/classes',
    roles: ['teacher', 'administrator'],
    showInSidebar: settings.IS_VIMARU,
    sortOrder: 6,
    type: ['allowedRoutes'],
  },
  {
    component: Class_Students,
    exact: true,
    layout: '/lms',
    path: '/teacher/enter-exam-score/classes/:classId',
    roles: ['teacher', 'administrator'],
    type: ['allowedRoutes'],
  },

  // {
  //   path: '/teacher/students',
  //   name: 'Quản lý học viên',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Administration/Vimaru/StudentManagement')),
  //   icon: <ClassIcon size={20} className='tw-text-yellow-500' />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['teacher'],
  //   type: ['allowedRoutes'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },

  // {
  //   path: '/teacher/presentations',
  //   name: 'Quản lý bài giảng',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Teacher/Presentations')),
  //   icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500' />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['teacher'],
  //   showInSidebar: true,
  //   sortOrder: 1,
  // },

  // {
  //   path: '/teacher/presentation/:presentationId/create',
  //   component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['teacher'],
  // },

  // {
  //   path: '/teacher/presentation/:presentationId/edit',
  //   component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['teacher'],
  // },

  // {
  //   collapse: true,
  //   path: '/teacher/worksheets',
  //   name: 'Quản lý thư viện',
  //   miniName: 'C',
  //   icon: <MdDoneOutline size={20} className='tw-text-green-600' />,
  //   roles: ['teacher'],
  //   showInSidebar: true,
  //   sortOrder: 2,
  //   state: 'lms/teacher/worksheets',
  //   views: [
  //     {
  //       path: '/teacher/worksheets/list/:filter',
  //       component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
  //       layout: '/lms',
  //       exact: true,
  //       roles: ['teacher'],
  //     },
  //     {
  //       path: '/teacher/worksheets/list/quizcity-manual',
  //       name: 'Bài tập Manual',
  //       miniName: 'D',
  //       component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
  //       layout: '/lms',
  //       exact: true,
  //       roles: ['teacher'],
  //       showInSidebar: true,
  //       sortOrder: 0,
  //     },
  //     {
  //       path: '/teacher/worksheets/list/quizcity-worksheet',
  //       name: 'Bài tập Worksheet',
  //       miniName: 'D',
  //       component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
  //       layout: '/lms',
  //       exact: true,
  //       roles: ['teacher'],
  //       showInSidebar: true,
  //       sortOrder: 1,
  //     },
  //     {
  //       path: '/teacher/worksheets/list/quizcity-quiz',
  //       name: 'Bài tập Quiz',
  //       miniName: 'D',
  //       component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
  //       layout: '/lms',
  //       exact: true,
  //       roles: ['teacher'],
  //       showInSidebar: true,
  //       sortOrder: 1,
  //     },
  //   ],
  // },

  // {
  //   path: '/teacher/assigments',
  //   name: 'Quản lý bài tập',
  //   miniName: 'C',
  //   component: AssignmentsManagement,
  //   icon: 'fas fa-book text-green',
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['teacher'],
  //   showInSidebar: true,
  //   sortOrder: 4,
  // },
  // {
  //   path: '/teacher/quizcity/worksheets/update',
  //   name: 'Tạo worksheet',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Teacher/QuizCity/UpdateWorkSheet')),
  //   icon: <ClassIcon size={20} className='tw-text-blue-600' />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['teacher'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },
  // {
  //   path: '/teacher/quizcity/worksheets/update/:id',
  //   component: React.lazy(() => import('pages/Teacher/QuizCity/UpdateWorkSheet')),
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['teacher'],
  // },

  // {
  //   path: '/teacher/classes/:classId',
  //   component: Teacher_Classes_Details,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['teacher'],
  // },

  // {
  //   path: '/teacher/classes/:classId/classwork/assignments/add',
  //   component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['teacher'],
  // },

  {
    path: '/teacher/classes/:classId/classwork/assignments/:assignmentId/edit/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/teacher/classes/:classId/classwork/assignments/:assignmentId/details/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/Details')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },
  ...extraMenardTeacherRoute,
].concat(URL_HuongDanSuDung ? [fileHelp] : []);
