import { Button, Tooltip } from 'antd';
import colors from 'constants/colors';
import React, { ReactNode } from 'react';
import './DynamicTooltip.css';
import settings from 'constants/settings';

type Props = {
  children?: JSX.Element | JSX.Element[];
  title?: string;
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | string;
  trigger?: 'hover' | 'focus' | 'click' | 'contextMenu' | Array<string>;
  zIndex?: number;
  arrow?: boolean;
  align?: object;
  color?: string;
  defaultOpen?: boolean;
  className: string;
  open: boolean;
};

const DynamicTooltip = ({
  children,
  title,
  placement,
  trigger,
  zIndex,
  arrow,
  align,
  color,
  defaultOpen,
  className,
  open,
  ...props
}: Props) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      className={className}
      color={color}
      trigger={trigger}
      arrow={arrow}
      align={align}
      // defaultOpen={defaultOpen}
      // open={open}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

DynamicTooltip.defaultProps = {
  children: null,
  title: '',
  placement: 'top',
  trigger: 'hover',
  zIndex: 999,
  arrow: true,
  align: {},
  color: '',
  defaultOpen: false,
  className: '',
  open: false,
};

export default DynamicTooltip;
