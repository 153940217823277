import React from 'react';
import { Image, Button, Tooltip, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import WriteScoreCanvasWithinImage from 'modules/Classwork/components/Assignments/StudentWorkItem/View/components/WriteScoreCanvasWithinImage';
import WriteScoreCanvasWithinImageNote from 'modules/Classwork/components/Assignments/StudentWorkItem/View/components/WriteScoreCanvasWithinImageNote';
import { query } from 'helpers/QueryHelper';
import { useQueryClient } from 'react-query';
import ViewScoreCanvasWithinImage from 'modules/Classwork/components/Assignments/StudentWorkItem/Update/components/ViewScoreCanvasWithinImage';
import settings from 'constants/settings';
import i18next from 'i18next';

const ImageAzota: React.FC = ({ data, style, isStudent }: any) => {
  const [hovered, setHovered] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [currentFile, setCurrentFile] = React.useState<any>();
  const queryClient = useQueryClient();
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = (data: any) => {
    setOpenModal(true);
    query('p_SACHSO_SHARE_Attachments_Get', { attachmentId: data?.attachmentId }).then((res) => {
      setCurrentFile(res[0]);
    });
  };

  return (
    <>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            overflow: 'hidden',
            borderRadius: '4px',
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Image preview={false} style={style} src={data.imageUrl} />
          {hovered && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => handleClick(data)}
            >
              <Tooltip title={isStudent ? i18next.t('Detail') : i18next.t('table:classInformation.Mark')}>
                <Button style={{ color: 'white' }} type='text' icon={isStudent ? <EyeOutlined /> : <EditOutlined />} />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <Modal
        width='90%'
        centered
        footer={null}
        title={data.fileName}
        open={openModal}
        onCancel={() => {
          setOpenModal(false);
          if (!isStudent) queryClient.invalidateQueries('p_QUIZCITY_API_GetQuizSummary_Log');
        }}
        destroyOnClose
      >
        {currentFile &&
          (isStudent ? (
            <ViewScoreCanvasWithinImage data={currentFile} />
          ) : (
            <WriteScoreCanvasWithinImageNote data={currentFile} />
          ))}
      </Modal>
    </>
  );
};

export default ImageAzota;
