/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Space, Tooltip, Upload, message } from 'antd';
import _ from 'lodash';
import Qrcode from 'qrcode';
import React, { CSSProperties, ReactElement } from 'react';
import { useQueryClient } from 'react-query';

import colors from 'constants/colors';
import { sachsoServerUrl } from 'constants/serverUrls';

import withAuthenticator from 'HOCs/withAuthenticator';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import { getSocket } from 'helpers/SocketHelper';
import i18next from 'i18next';
import { useHistory } from 'react-router';
import { LoggedInUserType } from 'types';

type Props = {
  entityId: string;
  entityName: string;
  moduleName?: string;
  subModuleName: string;
  tags?: string;
  maxCount?: number;
  onSuccess: (value?: any) => void;
  showQrCode?: boolean;
  loggedInUser?: LoggedInUserType;
  icon?: ReactElement;
  buttonText?: string;
  buttonStyle?: CSSProperties;
  supportTypeList?: string[];
  preview?: boolean;
};

const socket = getSocket();

function UploadFiles({
  entityId,
  entityName,
  subModuleName,
  moduleName,
  tags,
  loggedInUser,
  showQrCode,
  maxCount,
  onSuccess,
  buttonText,
  icon,
  buttonStyle,
  supportTypeList,
  preview,
}: Props): JSX.Element {
  const queryClient = useQueryClient();
  const key = ['t_SHARE_SACHSO_Attachments', entityId, entityName];

  const history = useHistory();

  const [qrcodeImageUrl, setQrcodeImageUrl] = React.useState('');
  const [modalVisible, setModalVisible] = React.useState(false);

  const [fileList, setFileList] = React.useState([]);

  const uploadSettings = {
    beforeUpload: (file: any) => {
      if (supportTypeList) {
        const isSupport = _.includes(supportTypeList, file.type);
        if (!isSupport) {
          message.error(
            i18next.t('upload:UploadFiles.uploadSettings.beforeUpload.{{file}} file format is not supported', {
              file: file.name,
            }),
          );
        }
        return isSupport || Upload.LIST_IGNORE;
      } else {
        return true;
      }
    },
    name: 'files',
    action: `${sachsoServerUrl}/api/v1.0/dynamic/upload-files`,
    headers: {
      Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
      ApplicationName: settings.APPLICATION_NAME,
    },
    data: {
      entityId,
      entityName,
      subModuleName,
      moduleName,
      tags,
      createdBy: loggedInUser?.id ?? 0,
    },
    fileList,
    maxCount,
    onChange(info: any) {
      setFileList(info.fileList);
      if (info.file.status === 'error') {
        message.error(
          i18next.t('upload:UploadFiles.onChange.The uploaded {{file}} file is corrupted.', { file: info.file.name }),
        );
      }

      // Check completed all.
      const isCompleted = _.every(info.fileList, { status: 'done' });
      if (isCompleted) {
        setFileList([]);
        message.success(`Upload files: ${i18next.t('Complete')}.`);
        const id = info.fileList[0]?.response?.files[0]?.id;
        query('p_SACHSO_SHARE_Attachments_UpdateModule', {
          Id: id,
          // ModuleId: settings.MODULE_ID,
        });
        // RELOAD
        queryClient.invalidateQueries(key);
        // console.log();
        // SOCKET: EMIT
        socket.emit('send', {
          messageType: 'query',
          payload: { roomId: `t_SHARE_Attachments_${entityName}_${entityId}`.toUpperCase() },
        });

        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(info);
        }
      }
    },
  };
  // React.useEffect(() => {
  //   if (!loggedInUser) {
  //     history.push('/#lms');
  //   }
  // }, [history, loggedInUser]);

  React.useEffect(() => {
    if (showQrCode) {
      Qrcode.toDataURL(
        `https://${window.location.host}/upload/qrcode/${moduleName}/${subModuleName}/${entityName}/${entityId}`,

        (err: any, url: string) => {
          setQrcodeImageUrl(url);
        },
      );
    }
  }, [entityId, entityName, showQrCode, subModuleName]);

  React.useEffect(() => {
    // SOCKET: JOIN ROOM
    socket.emit('send', {
      messageType: 'join-room',
      payload: { roomId: `t_SHARE_Attachments_${entityName}_${entityId}`.toUpperCase() },
    });
  }, []);

  return (
    <React.Fragment>
      {entityId && (
        <React.Fragment>
          <Row>
            <Col sm='12' md='6' lg='4'>
              <Upload multiple={maxCount > 1} {...uploadSettings} disabled={preview}>
                <Space>
                  <Tooltip title='Upload files'>
                    <Button disabled={preview} icon={icon} style={{ ...buttonStyle }}>
                      {buttonText}
                    </Button>
                  </Tooltip>
                  {/* <span style={{ fontWeight: 400 }}>{`(Chọn tối đa 5 tập tin)`}</span> */}
                </Space>
              </Upload>
            </Col>
          </Row>
          {showQrCode && qrcodeImageUrl && (
            <React.Fragment>
              <Modal
                centered
                title={i18next.t('upload:UploadFiles.Modal.Turn on the camera on your phone to scan the QRCODE')}
                visible={modalVisible}
                footer={null}
                onCancel={() => {
                  setModalVisible(false);
                }}
              >
                <div>
                  <a
                    href={`https://${window.location.host}/upload/qrcode/${moduleName}/${subModuleName}/${entityName}/${entityId}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img src={qrcodeImageUrl} alt='QRCODE' style={{ width: '100%' }} />
                  </a>
                </div>
              </Modal>
              <div style={{ marginBlock: 12 }}>
                <Space direction='vertical'>
                  <Space>
                    <InfoCircleOutlined />
                    <em>{i18next.t('upload:UploadFiles.You can use your phone')}</em>

                    <Button
                      disabled={preview}
                      type='dashed'
                      onClick={() => {
                        setModalVisible(true);
                      }}
                    >
                      <span style={{ color: colors.primary, fontWeight: 600 }}>
                        {i18next.t('upload:UploadFiles.SCAN QRCODE')}
                      </span>
                    </Button>
                    <em>{i18next.t('upload:UploadFiles.to upload files from your phone.')}</em>
                  </Space>
                </Space>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

UploadFiles.defaultProps = {
  loggedInUser: null,
  maxCount: 5,
  showQrCode: true,
  moduleName: 'SACHSO',
  tags: '',
  icon: <UploadOutlined />,
  buttonText: i18next.t('Upload'),
  buttonStyle: {},
  preview: false,
};

export default withAuthenticator(UploadFiles);
