import { query } from "helpers/QueryHelper";
import { useQuery } from "react-query";

type SearchQuestionParameters = {
    questionText: string,
    type: string,
    optionType: string,
    grade: string,
    tag: string,
    unit?: string,
}

const getQuestions = (parameters: SearchQuestionParameters= {
    questionText: '',
    type: '',
    optionType: '',
    grade: '',
    tag: '',
    unit: '',
}) => {
    return query('p_QUIZCITY_ResourcesQuestion_Search', parameters);
};

const getQuestions_UseQuery = (parameters: SearchQuestionParameters = {
    questionText: '',
    type: '',
    optionType: '',
    grade: '',
    tag: '',
    unit: '',
}) => {
    return useQuery(['p_QUIZCITY_ResourcesQuestion_Search', parameters], () => query('p_QUIZCITY_ResourcesQuestion_Search', parameters));
};

export { getQuestions, getQuestions_UseQuery }
export type { SearchQuestionParameters }