/* eslint-disable react/require-default-props */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Space } from 'antd';
import { InfoCircleOutlined, SaveFilled } from '@ant-design/icons';
import colors from 'constants/colors';
import { keyToolbar, modeName } from '../configuration';
import DynamicButton from 'packages/DynamicButton/DynamicButton';

const { CANCEL, DONE, CHANGEMODE } = keyToolbar;

function ToolBar({ refToolBar, onClickToolBar }) {
  const [mode, setMode] = useState(null);
  const [groupName, setGroupName] = useState(0);
  // ref control
  useEffect(() => {
    Object.assign(refToolBar.current, {
      getMode: () => {
        return mode;
      },
      getGroupName: () => {
        return groupName;
      },
      setGroupName: (value) => {
        setGroupName(value);
      },
    });
    return () => {};
  }, [groupName, mode, refToolBar]);
  //
  const handleClickMode = useCallback(
    (key) => {
      if (mode) return;
      setMode(key);
      onClickToolBar(CHANGEMODE);
    },
    [mode, onClickToolBar],
  );
  //
  const handleClickConfirm = useCallback(
    (key) => {
      setMode('');
      onClickToolBar(key);
    },
    [onClickToolBar],
  );
  //
  const renderMode = useCallback(() => {
    const {
      WRITE,
      CHOICE,
      MULTIPLECHOICE2,
      MATCH,
      MULTIPLEMATCH,
      LISTEN,
      SELECTWORD,
      DROPDOWN,
      DRAGDROP,
      SPEECHRECOGNITION,
      RECORD,
    } = modeName;
    const buttons = [
      { key: WRITE, label: 'Fill in the gaps', title: 'Điền từ vào chỗ trống', color: '#2f54eb' },
      { key: CHOICE, label: 'Choice', title: 'Chọn câu / từ đúng', color: '#722ed1' },
      { key: MULTIPLECHOICE2, label: 'Multiple Choice', title: 'Chọn câu / từ đúng', color: '#300370' },
      { key: MATCH, label: 'Matching', title: 'Nối', color: '#eb2f96' },
      { key: MULTIPLEMATCH, label: 'Multiple Matching', title: 'Nối nhiều', color: '#eb2f96' },
      { key: SELECTWORD, label: 'Select a word', title: 'Chọn', color: '#52c41a' },
      { key: DROPDOWN, label: 'Dropdown', title: 'Drop Down', color: '#faad14' },
      { key: DRAGDROP, label: 'Drag & Drop', title: 'Drag Drop', color: '#13c2c2' },
      { key: LISTEN, label: 'Listening', title: 'Âm thanh', color: '#fa541c' },
      { key: SPEECHRECOGNITION, label: 'Speech Recognition', title: 'Đọc với A.I.', color: '#8B4513' },
      { key: RECORD, label: 'Record', title: 'Ghi âm', color: '#008080' },
    ];
    return buttons.map(({ key, label, color }) => {
      const styleButton = { color, borderColor: color, fontWeight: '500' };
      let isDisabled = false;
      //
      if (mode && key !== mode) {
        isDisabled = true;
        Object.assign(styleButton, { color: colors.gray['500'], borderColor: colors.gray['500'] });
      }
      return (
        <DynamicButton
          key={key}
          type={isDisabled ? 'dashed' : 'default'}
          disabled={isDisabled}
          style={styleButton}
          onClick={() => {
            handleClickMode(key);
          }}
        >
          {label}
        </DynamicButton>
      );
    });
  }, [handleClickMode, mode]);

  return (
    <Space style={{ width: '100%' }} size='small' wrap={true}>
      {renderMode()}
      <a href='https://cloud.softech.vn/storage/Data/VIMARU/HUONG_DAN_SOAN_BAI_WORKSHEET.pdf' target='_blank'>
        <InfoCircleOutlined /> Hướng dẫn soạn bài
      </a>

      {mode && (
        <>
          <DynamicButton type='dashed' danger onClick={() => handleClickConfirm(CANCEL)}>
            Hủy
          </DynamicButton>
          <DynamicButton type='primary' onClick={() => handleClickConfirm(DONE)} icon={<SaveFilled />}>
            Lưu
          </DynamicButton>
        </>
      )}
    </Space>
  );
}

ToolBar.propTypes = {
  refToolBar: PropTypes.object,
  onClickToolBar: PropTypes.any,
};

export default React.memo(ToolBar);
