/* eslint-disable no-param-reassign */
import * as actionTypes from '../actions/types';

// ------------------------------------------------------------------------------------------------
const minute = 1000 * 60;
const initialState = {
  loading: false,
  error: null,
  examination: null,
  submittedQuestions: [],
  countdown: 30 * minute, // 30 minutes
};

// ------------------------------------------------------------------------------------------------

const olympicReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OLYMPIC_START:
      return { ...state, submittedQuestions: [], examination: action.payload, countdown: 30 * minute };
    // --------------------------------------------------------------------------------------------
    case actionTypes.OLYMPIC_UPDATE_COUNTDOWN:
      return { ...state, countdown: action.payload };
    // --------------------------------------------------------------------------------------------
    case actionTypes.OLYMPIC_SUBMIT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const found = state.submittedQuestions.find((item) => item.question.id === action.payload.question.id);

      if (found === undefined) {
        state.submittedQuestions = [...state.submittedQuestions, action.payload];
      } else {
        found.userData = action.payload.userData;
      }
      return {
        ...state,
        submittedQuestions: [...state.submittedQuestions],
      };
    // --------------------------------------------------------------------------------------------
    default:
      return state;
  }
};

export default olympicReducer;
