import { ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Drawer, Progress, Row, Space, Spin, Tag } from 'antd';
import Axios from 'axios';
import Card from 'components/Containers/Card';
import SimpleContainer from 'components/Containers/SimpleContainer';
import NoData from 'components/NoData';
import CountDownTimer from 'components/countdownTimer';
import settings, { DOMAIN_IS_USE, MODULE_ID, PATHNAME } from 'constants/settings';
import { amesSendMail, query, queryFirst } from 'helpers/QueryHelper';
import useWindowDimensions from 'hooks/useWindowDimensions';
import AmesOnlineFooter from 'modules/Auth/components/LoginAmesOnline/buttonFooter';
import SlideVideo from 'modules/Presentation/Viewer/ViewerTypes/Video/SlideVideo';
import SlideWord from 'modules/Presentation/Viewer/ViewerTypes/Word/SlideWord';
import { start } from 'modules/Presentation/Viewer/actions';
import ExcercisePage from 'modules/WorkSheet/CanvasToolPlus/ExcercisePage';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import RenderSlideQuiz from './Quiz';
import DrawerTable from './components/Drawer/DrawerTable';
import ModalWarningClose from './components/Modal/ModalWarningClose';
import { SelectResultExercise } from './components/SelectResultExercise';
import TutorialPT from './components/Tutorials/TutorialPT';
const thankYou = require('assets/img/thank-you.png').default;
const logoUrl =
  window.location.hostname === 'quizcity.io'
    ? require('assets/img/brand/logo-quizcity.png').default
    : require('assets/img/brand/english-city-01.png').default;
const bannerUrl = require('assets/img/introduce/ames-login-BG.png').default;

type Props = {
  params?: any;
  codeExam: string;
  codeId?: number;
  type?: string;
  studentId: number | string;
};
type RefSubmitProps = {
  submit: Function;
  tryAgain?: Function;
};
type TypeTimeExam = {
  hidden: boolean;
  duration: number;
};

const IndexPresentationAmesOnline = ({
  params, // Hiện tại 18/4/2023 param đang truyền vào folderId,presentationId,slideId cho lần đầu gọi p_QUIZCITY_Slides_Loading_CE
  codeId,
  codeExam,
  type = '',
  studentId, //Handout mới dùng
}: Props) => {
  //State
  const [loadingAnswer, setLoadingAnswer] = React.useState<boolean>(false);
  const [isFinalSlide, setFinalSlide] = React.useState<boolean>(false);
  const [isShowModalCompleted, setIsShowModalCompleted] = React.useState<boolean>(false);
  const [isVisibleModalWarning, setIsVisibleModalWarning] = React.useState<boolean>(false);
  const [isOpenDrawer, setIsOpenDrawer] = React.useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
  const [dataResult, setDataResult] = React.useState<any>();
  const [currentResult, setCurrentResult] = React.useState<Object>({});
  const [outlineIndex, setOutlineIndex] = React.useState<number>(0);
  const [isDisableBackBtn, setIsDisableBackBtn] = React.useState<boolean>(false);
  const [isLoadingSubmit, setLoadingSubmit] = React.useState<boolean>(false);
  const [allDataSlideLoading, setAllDataSlideLoading] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<any>(undefined);
  const [isStatusSaveExercise, setStatusSaveExercise] = React.useState<boolean>(false);
  const [timeExamState, setTimeExamState] = React.useState<TypeTimeExam>({
    hidden: true,
    duration: 0,
  });
  const [audio, setAudio] = React.useState(null);
  const refCountdownTimer = React.useRef<any>();
  const [resultWorkSheet, setResultWorkSheet] = React.useState<any>();
  const [resultQuiz, setResultQuiz] = React.useState<any>();
  const [isShowTutorial, setIsShowTutorial] = React.useState<boolean>(true);

  //===QUIZ===//
  const [disableButtonNextSlide, setDisableButtonNextSlide] = React.useState<boolean>(false); // type Quiz
  const [currentQuizIndex, setCurrentQuizIndex] = React.useState<number>(0);
  const [numberQuestions, setNumberQuestions] = React.useState<number>(0);
  const [arraySubmitQuestion, setArraySubmitQuestionQuiz] = React.useState<any>([]);
  const [isDisableContinue, setIsDisableContinue] = useState<boolean>(true);
  const [totalScore, setTotalScore] = React.useState<number>(0);
  const [countQuizCorrectAnswers, setCountQuizCorrectAnswers] = React.useState<number[]>([]); // Quiz Correct answers
  const [isExample, setExample] = React.useState<boolean>(false); // Check có phải bài thi hay không

  const dispatch = useDispatch();
  // Ref quiz
  const refQuizQuestion = React.useRef<any>();
  //Ref worksheet
  const refSubmit = React.useRef<RefSubmitProps>({} as RefSubmitProps);

  const previousPresentation = allDataSlideLoading?.[0]?.presentation?.[0]?.prev?.[0];
  const currentPresentation = allDataSlideLoading?.[0]?.presentation?.[0]?.current?.[0];
  const outline = currentPresentation?.slides?.[0]?.outline;
  const currentSlide = currentPresentation?.slides?.[0]?.current?.[0];
  const nextSlideId = currentPresentation?.slides?.[0]?.nextSlide;
  const previousSlideId = currentPresentation?.slides?.[0]?.prevSlide;
  const nextPresentation = allDataSlideLoading?.[0]?.presentation?.[0]?.next?.[0];
  const jsonData = currentSlide?.jsonData ? JSON.parse(currentSlide?.jsonData ?? '') : '';
  const length = outline?.length;
  const isNodataView = type === 'VIEW' && !length;
  //quiz
  const passScoreQuiz = currentSlide?.percentPass;

  React.useEffect(() => {
    if (!isShowTutorial) {
      if (timeExamState?.duration <= 0) {
        refCountdownTimer.current?.stopTimer();
      } else {
        refCountdownTimer.current?.startTimer();
      }
    }
  }, [timeExamState, isShowTutorial]);

  React.useEffect(() => {
    // lấy slide lần đầu tiên trước khi làm bài
    getSlide(params, 'firstTime');
  }, [params]);

  // Kiểm tra điều kiện và xét trạng thái mỗi khi bấm nút "Bài tiếp" chuyển câu hỏi hoặc slide
  React.useEffect(() => {
    statusButton(currentSlide, currentQuizIndex, numberQuestions, previousPresentation, nextPresentation);
    setAudio(null);
  }, [currentSlide, currentQuizIndex, numberQuestions, previousPresentation, nextPresentation]);

  //Gửi email về cho giáo viên khi học sinh PT làm xong bài
  useEffect(() => {
    if (isShowModalCompleted && settings.IS_PT) {
      queryFirst('p_QUIZCITY_PTTest_Results_Get', { PTCode: codeExam })
        .then((res) => {
          if (res?.teacherEmail) {
            handleSendEmail({ ...res, ptCode: codeExam });
          }
        })
        .catch((err) => {
          console.log('error p_QUIZCITY_PTTest_Results_Get', err);
        });
    }
  }, [isShowModalCompleted]);

  const getResult = async (slideId: string, slideType = 'Worksheet') => {
    let parameter;
    if (settings.IS_HANDOUT) {
      parameter = {
        handoutId: codeId,
        StudentId: studentId,
        SlideId: slideId,
      };
      SelectResultExercise(
        'p_QUIZCITY_Handout_GetResult_TypeWorksheet_ByStudent',
        parameter,
        setResultWorkSheet,
        setLoading,
      );
    } else if (settings.IS_PT) {
      const sqlComand =
        slideType === 'WorkSheet'
          ? 'p_QUIZCITY_PTTest_GetResult_TypeWorksheet_ByStudent'
          : slideType === 'Quiz' && type === 'VIEW' // lúc làm bài, kết quả QUIZ đã lưu ở Local nên k cần gọi API
          ? 'p_QUIZCITY_PTTest_GetResult_TypeQuiz_ByStudent'
          : null;
      parameter = {
        PTcode: codeExam,
        SlideId: slideId,
      };
      if (sqlComand)
        SelectResultExercise(
          sqlComand,
          parameter,
          slideType === 'WorkSheet' ? setResultWorkSheet : setResultQuiz,
          setLoading,
        );
    }
  };

  const getSlide = async (param: any, btnType: string) => {
    setLoading(true);
    const parameter = {
      code: codeExam,
      folderId: param.folderId,
      presentationId: param.presentationId,
      slideId: param.slideId,
    };

    await query('p_QUIZCITY_Slides_Loading_CE', parameter)
      .then((res) => {
        if (type !== 'VIEW') selectTimeExam(res);
        const allSlides = res?.[0]?.presentation?.[0]?.current?.[0].slides?.[0]?.outline;
        const numberSlides = allSlides.length;
        let newSlide = res?.[0]?.presentation?.[0]?.current?.[0].slides?.[0]?.current?.[0];
        if (btnType === 'PreviousPresentation') {
          //Nếu quay lại bài giảng trước đó thì lấy slide cuối của bài giảng và câu hỏi cuối cùng của slide
          newSlide = allSlides[numberSlides - 1];
          setOutlineIndex(numberSlides - 1);
          // Vì gọi lại slide loading để tới vị trí của slide cuối ==> có presentationId bài giảng tiếp theo
          query('p_QUIZCITY_Slides_Loading_CE', { ...parameter, slideId: allSlides[numberSlides - 1].id }).then((res) =>
            setAllDataSlideLoading(res),
          );
        } else setAllDataSlideLoading(res);
        // Nếu back lại slide quiz trước thì thứ tự indexQuiz là chạy từ câu hỏi cuối cùng
        if ((btnType === 'PreviousPresentation' || btnType === 'previousSlide') && newSlide?.type === 'Quiz') {
          const jsonDataQuiz = JSON.parse(newSlide?.jsonData);
          const allQuizsOfSlide = jsonDataQuiz?.[0]?.questions?.[0]?.quizData?.questions;
          setCurrentQuizIndex(allQuizsOfSlide?.length - 1 || 0);
        }
        // PT & Handout cho phép làm lại bài với đáp án cũ nên cần lấy lại kết quả làm bài trước đó
        //Lấy kết quả theo current slide
        getResult(newSlide?.id, newSlide?.type);
        // setTimeout(() => {
        // }, 500);
      })
      .finally(() => setLoading(false));
  };

  const selectTimeExam = async (data: any) => {
    const timeExam = data?.[0]?.presentation?.[0]?.current?.[0]?.TimeExam;

    if (timeExam > 0) {
      setTimeExamState({
        hidden: false,
        duration: timeExam,
      });
    } else {
      setTimeExamState({
        hidden: true,
        duration: 0,
      });
    }
  };

  const statusButton = (
    currentSlide: any,
    currentQuizIndex: number,
    numberQuestions: number,
    previousPresentation: any,
    nextPresentation: any,
  ) => {
    // Không cho back lại bài giảng trước trong lúc làm bài
    // disable button "Bài trước" khi ở slide đầu tiên, câu hỏi quiz đầu tiên
    if (currentSlide?.sortOrder === 1 && currentQuizIndex === 0) {
      if (previousPresentation?.id === '00000000-0000-0000-0000-000000000000') setIsDisableBackBtn(true);
      else if (type !== 'VIEW') {
        setIsDisableBackBtn(true);
      } else setIsDisableBackBtn(false);
    } else {
      setIsDisableBackBtn(false);
    }
    // khi làm bài chỉ có 1 bài giảng
    const useOnePre = currentSlide?.sortOrder === length && currentQuizIndex === numberQuestions - 1;
    // Khi làm bài có nhiều bài giảng
    const useAnyPre =
      currentSlide?.sortOrder === length &&
      (currentSlide?.type === 'Quiz' ? currentQuizIndex === numberQuestions - 1 : true) &&
      nextPresentation?.id === '00000000-0000-0000-0000-000000000000';
    const check = settings.IS_HANDOUT ? useOnePre : settings.IS_PT ? useAnyPre : null;
    if (check) {
      setFinalSlide(true);
    } else {
      setFinalSlide(false);
    }
  };

  const checkNextPresentation = (nextPre: { id: string; slideId: string }) => {
    setOutlineIndex(0);
    setCurrentQuizIndex(0);
    if (nextPre.id !== '00000000-0000-0000-0000-000000000000') {
      selectTimeExam(0);
      setAllDataSlideLoading(undefined);
      getSlide({ ...params, presentationId: nextPre.id, slideId: nextPre.slideId }, 'NextPresentation');
    } else {
      setIsShowModalCompleted(true);
      dispatch(start(null));
    }
  };

  const onNextButton = useCallback(() => {
    if (currentSlide.type === 'Quiz' && currentQuizIndex < numberQuestions - 1) {
      setCurrentQuizIndex((prev) => prev + 1);
    } else {
      if (nextSlideId === '00000000-0000-0000-0000-000000000000') {
        //Handout chỉ làm bài tập trong 1 bài giảng
        if (settings.IS_HANDOUT) {
          setIsShowModalCompleted(true);
        } else checkNextPresentation(nextPresentation);
      } else {
        setOutlineIndex((pre) => pre + 1);
        getSlide({ ...params, slideId: nextSlideId }, 'nextSlide');
      }
      // next qua slide mới nên reset giá trị
      setCurrentQuizIndex(0);
      setIsSubmitted(false);
      setLoadingAnswer(false);
      setResultWorkSheet(undefined);
    }
  }, [nextPresentation, nextSlideId, currentSlide, currentQuizIndex, numberQuestions]);

  const onUseCallBackSubmitButton = React.useCallback(
    (value: boolean) => {
      if (value && type !== 'VIEW') {
        if (currentSlide?.type === 'WorkSheet') {
          setIsSubmitted(true);
          setDataResult([]);
          refSubmit.current?.submit();
        }
        if (currentSlide?.type === 'Quiz') {
          refQuizQuestion.current?.onNext();
        }
      }
    },
    [type, refSubmit, refQuizQuestion, currentSlide?.type],
  );

  const checkPreviousPresentation = (previousPre: { id: string; slideId: string }) => {
    if (previousPre.id !== '00000000-0000-0000-0000-000000000000') {
      selectTimeExam(0);
      // setAllDataSlideLoading(undefined);
      getSlide({ ...params, presentationId: previousPre.id, slideId: previousPre.slideId }, 'PreviousPresentation');
    }
  };

  const onPreviousButton = useCallback(() => {
    if (currentSlide?.type === 'Quiz' && currentQuizIndex > 0 && typeof currentQuizIndex === 'number') {
      setCurrentQuizIndex((prev) => prev - 1);
    } else if (previousSlideId === '00000000-0000-0000-0000-000000000000') {
      checkPreviousPresentation(previousPresentation);
    } else {
      setOutlineIndex((pre) => pre - 1);
      setResultWorkSheet(undefined);
      getSlide({ ...params, slideId: previousSlideId }, 'previousSlide');
    }
  }, [currentQuizIndex, currentSlide, previousSlideId, previousPresentation]);

  const onFinishCountDown = () => {
      onUseCallBackSubmitButton(true);

    query('p_QUIZCITY_Slides_Loading_CE', {
      ...{ code: codeExam },
      ...{ ...params, slideId: outline?.[length - 1].id },
    }).then((res) => {
      let nextPresentation = res?.[0]?.presentation?.[0]?.next?.[0];
      checkNextPresentation(nextPresentation);
    });
  };

  const handleSendEmail = (data: any) => {
    const { studentName, teacherEmail, teacherName, aiScore, essayScore, totalScore, examName, ptCode, examParent } = data;

    if (teacherEmail && ptCode) {
      let dataSendMail;
      let linkResult = `${DOMAIN_IS_USE}/${PATHNAME}/student/codeExam/${ptCode}/preview`;
      const subject = `Kết quả bài test đầu vào HV: ${studentName} (${ptCode})`;

      if ( ['IELTS LIVE CLASS','IELTS SPEAKING PLUS','Luyện thi IELTS'].includes(examParent)) {
        const { grammarScore, listeningScore, readingScore, speakingScore, writingScore, teacherComment } = data;
        const linkChamDiem = `${DOMAIN_IS_USE}/${PATHNAME}/home`;
        const templateName = 'AMES_Mail_PT_StudentResult_IeltsHighup';
        dataSendMail = {
          to: teacherEmail.toLowerCase(),
          isSendFast: true,
          moduleId: MODULE_ID,
          cc: '',
          bcc: '',
          subject: subject,
          body: '',
          bodyHTML: '',
          attachment: '',
          templateName: templateName,
          sourceInfo: `API Save Email - ${templateName}`,
          templateData: JSON.stringify({
            teacherName,
            ptCode,
            studentName,
            examName:examParent,
            grammarScore: grammarScore ?? 0,
            listeningScore: listeningScore ?? 0,
            readingScore: readingScore ?? 0,
            speakingScore: speakingScore ? speakingScore : '<b style="color: #b54747">Giáo viên chấm</b>',
            writingScore: writingScore ? writingScore : '<b style="color: #b54747">Giáo viên chấm</b>',
            teacherComment:teacherComment ?? '' ,
            linkResult,
            linkChamDiem,
          }),
        };
      } else {
        const templateName = 'AMES_Mail_PT_StudentResult';
        dataSendMail = {
          to: teacherEmail.toLowerCase(),
          isSendFast: true,
          moduleId: MODULE_ID,
          cc: '',
          bcc: '',
          subject: subject,
          body: '',
          bodyHTML: '',
          attachment: '',
          templateName: templateName,
          sourceInfo: `API Save Email - ${templateName}`,
          templateData: JSON.stringify({
            studentName: studentName,
            teacherName,
            aiScore: aiScore || 0,
            essayScore: essayScore || 0,
            totalScore: totalScore || 0,
            linkResult: linkResult,
            ptCode,
            examName,
          }),
        };
      }
      amesSendMail('saveEmail', dataSendMail);
    } else {
      console.log('Thiếu thông tin gửi mail');
    }
  };

  return (
    <React.Fragment>
      <div>
        {!isShowModalCompleted ? (
          <div className='tw-pt-4'>
            <Spin spinning={isLoadingSubmit} tip='VUI LÒNG CHỜ ....'>
              <SimpleContainer className={settings.IS_HANDOUT ? 'mt-0 ' : ''}>
                {isShowTutorial && type !== 'VIEW' ? (
                  <TutorialPT code={codeExam} setIsShowTutorial={setIsShowTutorial} />
                ) : (
                  <>
                    <Card
                      title={
                        <>
                          <div>
                            <Breadcrumb style={{ fontSize: '26px' }}>
                              <Breadcrumb.Item>{currentPresentation?.lessonTitle}</Breadcrumb.Item>
                              <Breadcrumb.Item>{currentPresentation?.title}</Breadcrumb.Item>
                              <Breadcrumb.Item>{currentSlide?.title}</Breadcrumb.Item>
                            </Breadcrumb>
                            {!timeExamState?.hidden && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  gap: '5px',
                                  textAlign: 'center',
                                  marginTop: -35,
                                  marginRight: '20px',
                                }}
                              >
                                <ClockCircleOutlined style={{ fontSize: '30px', color: 'green', marginTop: '-2px' }} />
                                <CountDownTimer
                                  color={'green'}
                                  fontSize={'30px'}
                                  seconds={timeExamState?.duration}
                                  ref={refCountdownTimer}
                                  onStopCountDown={onFinishCountDown}
                                  typeCount={''}
                                  urlLocalStorage={`exercise-${settings.PATHNAME}-${codeExam}-${codeId}-${studentId}`}
                                />
                              </div>
                            )}
                          </div>
                          <div className='tw-flex tw-justify-center tw-items-center tw-gap-3 tw-mt-5'>
                            <Tag color='blue' style={{ fontSize: '26px', padding: '10px', marginTop: '5px' }}>
                              TỔNG SỐ PHẦN: {currentPresentation?.sortOrder}/
                              {allDataSlideLoading?.[0]?.presentation?.[0]?.totalPresentation}
                            </Tag>
                            <Progress
                              percent={Math.round(
                                (currentPresentation?.sortOrder /
                                  allDataSlideLoading?.[0]?.presentation?.[0]?.totalPresentation) *
                                  100,
                              )}
                              status='normal'
                              strokeWidth={10}
                              style={{ fontSize: '26px' }}
                            />
                          </div>
                        </>
                      }
                      style={{ height: 'calc(100vh - 32px)' }}
                      bodyStyles={{ overflow: 'auto' }}
                      footer={
                        isNodataView ? null : (
                          <AmesOnlineFooter
                            callBackSubmit={onUseCallBackSubmitButton}
                            callBackNext={onNextButton}
                            callPrevious={onPreviousButton}
                            isFinalSlide={isFinalSlide}
                            currentResult={currentResult}
                            setCurrentResult={setCurrentResult}
                            isDisableBackBtn={isDisableBackBtn}
                            mode={type}
                            audio={audio}
                            currentIndex={currentSlide?.sortOrder}
                            lengthSlide={length}
                          />
                        )
                      }
                      extra={
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '0 10px',
                          }}
                        >
                          {/* <Button
              onClick={() => {
                setIsOpenDrawer(true);
              }}
              icon={<UnorderedListOutlined />}
            /> */}
                          <Button
                            type='primary'
                            danger
                            onClick={() => {
                              if (type !== 'VIEW') setIsVisibleModalWarning(true);
                              else window.close();
                            }}
                            icon={<CloseOutlined />}
                          />
                        </div>
                      }
                    >
                      {/* {!timeExamState?.hidden ? (
      <Affix style={{ position: 'absolute', top: 100, right: 50 }}>
        <Card
          title=''
          className={'card-duration'}
          style={{
            border: '1px solid #e9e9e9',
            boxShadow: 'none',
          }}
        >
          <ViewIndexDuration duration={timeExamState?.duration} onFinishCountDown={onFinishCountDown} />
        </Card>
      </Affix>
    ) : null} */}
                      {isNodataView ? (
                        <NoData description='Không tìm thấy dữ liệu bài làm học sinh' />
                      ) : (
                        currentSlide && (
                          <div className='tw-h-full'>
                            <div className={`${loading ? 'tw-h-full' : ''} tw-flex tw-justify-center tw-items-center`}>
                              <Spin spinning={loading} size={'large'}></Spin>
                            </div>
                            {!loading && currentSlide?.type === 'WorkSheet' && (
                              <>
                                {type !== 'VIEW' ? (
                                  <ExcercisePage
                                    file={JSON.parse(currentSlide?.jsonData) ?? null}
                                    idCurrent={JSON.parse(currentSlide?.jsonData)?.workSheetId}
                                    entityName='Folders'
                                    loadingAnswer={loadingAnswer}
                                    subModuleName='CLASSES'
                                    refSubmit={refSubmit}
                                    studentId={studentId}
                                    codeId={codeId}
                                    isSubmitted={isSubmitted}
                                    isVisibleResult={false}
                                    currentSlideId={currentSlide.id}
                                    exerciseType='TEST'
                                    isHiddenShowColor={true}
                                    resultExercise={result}
                                    handoutCode={codeExam}
                                    userAnswer={resultWorkSheet?.jsonData}
                                    folderIdHandoutPt={params?.folderId}
                                    setAudio={setAudio}
                                    isDisableFrom={false}
                                    isTeacher={false}
                                    scoreWeight={currentSlide?.scoreWeight}
                                  />
                                ) : (
                                  <ExcercisePage
                                    file={JSON.parse(currentSlide?.jsonData) ?? null}
                                    setAudio={setAudio}
                                    typeView={'view'}
                                    idCurrent={JSON.parse(currentSlide?.jsonData)?.workSheetId}
                                    loadingAnswer={false}
                                    isHiddenShowColor={false}
                                    isDisableFrom={true}
                                    userAnswer={resultWorkSheet?.jsonData}
                                    // folderIdHandoutPt={params?.folderId} xem kết quả thì không cần truyền folderId
                                    scoreWeight={resultWorkSheet?.scoreWeight}
                                  />
                                )}
                              </>
                            )}
                            {!loading && currentSlide?.type === 'Quiz' && (
                              <RenderSlideQuiz
                                type={type}
                                resultQuiz={resultQuiz?.summaryData?.submittedQuestions || []}
                                currentSlide={currentSlide}
                                onFinishCountDown={onFinishCountDown}
                                disableButtonNextSlide={type === 'VIEW'}
                                currentQuizIndex={currentQuizIndex}
                                setCurrentQuizIndex={setCurrentQuizIndex}
                                refQuizQuestion={refQuizQuestion}
                                setArraySubmitQuestionQuiz={setArraySubmitQuestionQuiz}
                                isDisableContinue={isDisableContinue}
                                setIsDisableContinue={setIsDisableContinue}
                                totalScore={totalScore}
                                setTotalScore={setTotalScore}
                                percentPass={passScoreQuiz}
                                setCountQuizCorrectAnswers={setCountQuizCorrectAnswers}
                                isExample={isExample}
                                setNumberQuestions={setNumberQuestions}
                                codeExam={codeExam}
                                folderId={params?.folderId}
                                setDisableButtonNextSlide={setDisableButtonNextSlide} //  Không dùng
                                setAudio={setAudio}
                              />
                            )}
                            {!loading && currentSlide?.type === 'Word' && <SlideWord content={jsonData?.word} />}
                            {!loading && currentSlide?.type === 'Video' && (
                              <SlideVideo
                                percentPass={currentSlide.percentPass}
                                loggedInUser={0}
                                videoUrl={jsonData?.videoUrl}
                                coverUrl={jsonData?.coverUrl}
                                // ref={refPercentWatched}
                                // setStatusButtonFooter={setStatusButtonFooter}
                              />
                            )}
                          </div>
                        )
                      )}
                    </Card>
                    <Drawer open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)} size='large'>
                      <DrawerTable currentSlideId={currentSlide?.id} data={outline} setOutlineIndex={setOutlineIndex} />
                    </Drawer>
                  </>
                )}
              </SimpleContainer>
            </Spin>
          </div>
        ) : (
          <div>
            <>
              <div
                style={{
                  backgroundImage: `url(${bannerUrl}) `,
                  backgroundSize: '100% 100%',
                  minHeight: '100vh',
                  padding: '50px 0px',
                  margin: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Row className='justify-content-center' style={{ width: '100%', paddingBottom: 20, margin: 0 }}>
                  <div style={{ width: 300, height: 60 }}>
                    <img alt='English City' className='navbar-brand-img' src={logoUrl} />
                  </div>
                </Row>
                <Row className='justify-content-center' style={{ margin: 0 }}>
                  <Col style={{ maxWidth: 500, width: '95vw' }}>
                    <Card className='mb-0 border-0 bg-secondary ' title={''}>
                      <div className='tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-6'>
                        <img src={thankYou} alt='' style={{ maxHeight: '230px' }} />
                        <b style={{ fontSize: '20px', textTransform: 'uppercase' }}>
                          {settings.IS_PT
                            ? 'Chúc mừng bạn đã hoàn thành bài thi!'
                            : 'Chúc mừng bạn đã hoàn thành bài tập!'}
                        </b>
                        <Space>
                          {/* Anh Hùng bảo bỏ nút xem lại  */}
                          {/* <Col lg={12}>
                              <Button
                                danger
                                onClick={() => {
                                  const parameters = {
                                    codeId: codeId,
                                    codeExam: codeExam,
                                    params: params,
                                    studentId: studentId,
                                  };
                                  localStorage.setItem(
                                    'LocalStorage-student-result-handout-PT',
                                    JSON.stringify(parameters),
                                  );
                                  history.push({
                                    pathname: `/${settings.PATHNAME}/student/${codeExam}/result`,
                                    state: parameters,
                                  });
                                  // history.push(`/handout/student/${codeExam}/result`, parameters);
                                }}
                              >
                                Xem lại bài
                              </Button>
                            </Col> */}
                          <Col>
                            <Button
                              type='primary'
                              onClick={() => {
                                window.location.replace('https://ames.edu.vn/');
                              }}
                              key='console'
                            >
                              Về trang chủ
                            </Button>
                          </Col>
                        </Space>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
          </div>
        )}
        <ModalWarningClose
          isVisible={isVisibleModalWarning}
          onCloseModal={() => {
            setIsVisibleModalWarning(false);
          }}
          onOk={() => {
            onUseCallBackSubmitButton(true);
            window.location.replace('https://ames.edu.vn/');
          }}
        />
          </div>
    </React.Fragment>
  );
};

export default IndexPresentationAmesOnline;
IndexPresentationAmesOnline.defaultProps = {
  refWorkSheetSubmit: {},
};
