import React from 'react';
import { message } from 'antd';
import { sachsoServerUrl } from 'constants/serverUrls';
export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  let downloadUrl = e?.fileList?.[0]?.response?.files?.[0]?.downloadUrl;
  return downloadUrl;
};
export const PropsUploadFile = () => {
  const [fileList, setFileList] = React.useState();
  const props = {
    name: 'files',
    action: `${sachsoServerUrl}/api/v1.0/dynamic/upload-files`,
    headers: {
      Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
      ApplicationName: 'VIMARU',
    },

    data: {
      entityId: '46d30c13-3555-4395-8b83-4b9b2f7402ae',
      subModuleName: 'RESOURCES',
      entityName: 'Olympic_Examinations',
      moduleName: 'VIMARU',
    },
    maxCount: 1,
    onChange(info) {
      setFileList(info.fileList);
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return { props, fileList };
};
