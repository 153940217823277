import { formatDateTimeQuery } from "constants/FormatString";
import moment from "moment"
import { getItemLocalStorage, setItemLocalStorage } from "utils/localStorage"

const getDurationSlide_Seconds = () :number => {
    const {startTimeSlide} = getItemLocalStorage();
    const startTime = moment(startTimeSlide,formatDateTimeQuery);
    //Lấy thời gian từ lúc bắt đầu làm bài đến hiện tại (giây)
    const secondsDifference = moment().diff(startTime,'seconds');
    return secondsDifference;
};

const setStartTimeSlide = () => {
    const timeNow = moment().format(formatDateTimeQuery);
    setItemLocalStorage({startTimeSlide: timeNow});
};

export {getDurationSlide_Seconds,setStartTimeSlide};