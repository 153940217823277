import colors from 'constants/colors';
import _ from 'lodash';
import React from 'react';
import { AiOutlineSound as SpeakerIcon } from 'react-icons/ai';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import QuestionHeader from '../QuestionHeader';

import { submit } from 'modules/Presentation/Viewer/actions';
import { useDispatch } from 'react-redux';
import AudioButton from '../AudioButton';
import QuestionImage from '../QuestionImage';
import { QuestionComponentProps, QuestionComponentRef } from '../type';
import './styles.css';

const alphabet = 'abcdefghijklmnopqrstxyuvz';
const number = '123456789';

function renderUnderline(item: any, data: any) {
  const underlined = data?.settings?.answers?.underlined ?? false;
  if (underlined) {
    const head = item.slice(0, item.length - 2);
    const tail = item.slice(head.length);
    item = `${head}<${tail}>`;
  }

  let flag = false;
  return item?.split('').map((character: any, index: number) => {
    if (character === '<') {
      flag = true;
    }
    if (character === '>') {
      flag = false;
    }
    // Nếu là '<' hoặc '>' thì bỏ qua
    if (character === '<' && flag) return '';
    if (character === '>' && !flag) return '';

    return (
      <React.Fragment key={index}>
        {flag ? <span style={{ textDecoration: 'underline' }}>{character}</span> : character}
      </React.Fragment>
    );
  });
}

function OneChoice(
  {
    data,
    onSubmit,
    userData,
    questionIndex = 0,
    preview = false,
    showUserData = false,
    shuffed = false,
    index,
    isDisableButtonNextSlide,
    setAudio,
    onSubmitWhenCancel,
    isChildQuestion
  }: QuestionComponentProps,
  ref: QuestionComponentRef,
) {
  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
    onSubmitWhenCancel() {
      handleSubmitOnCanceling();
    },
  }));

  const [selectedAnswer, setSelectedAnswer] = React.useState<any>(null);

  // Âm thanh
  const refAudio = React.useRef<any>();
  refAudio.current = new Audio();

  // SHUFFED ANSWERS
  const [shuffedAnswers, setShuffedAnswers] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (userData && showUserData) {
      const array = userData.answers;
      setShuffedAnswers(array);

      const selected = _.find(array, (x) => x.selected);
      setSelectedAnswer(selected);
    } else {
      const answers = data.answers.map((item: any, index: number) => {
        return { ...item, id: index + 1 };
      });
      const array = preview ? answers : _.shuffle(answers);
      setShuffedAnswers(array);
    }

    // Chế độ xem thử
    if (preview) {
      const correctAnswer = _.find(data.answers, (answer) => answer.isCorrect);
      setSelectedAnswer(correctAnswer);
    }
  }, [data, preview, userData]);

  // RENDER FUNCTIONS
  // RENDER QuestionText
  const renderQuestionText = () => {
    // if (data.questionText === null || data.questionText === undefined || data.questionText === '') {
    //   return <React.Fragment />;
    // }
    if (data?.questionText || data?.questionSub) {
      return (
        <div className='mb-3'>
          <span
            className='mb-2'
            style={{ fontSize: 18, color: 'black', lineHeight: 2, textAlign: 'justify', fontWeight: 600 }}
          >
            {index && !isChildQuestion ? index + '.' : ''} {data.questionText || data.questionSub}
          </span>
        </div>
      );
    }
    return '';
  };

  // RENDER IMAGE
  const renderQuestionImage = () => {
    if (!data.imageUrl) {
      return <React.Fragment />;
    }
    return (
      <QuestionImage imageUrl={data.imageUrl} />

      // <div className='mb-4'>
      //   <img alt='' src={data.imageUrl} style={{ height: 200 }} />
      // </div>
    );
  };

  // RENDER Question
  const renderQuestion = () => {
    let renderResult = <React.Fragment />;
    if (data.question === null || data.question === undefined || data.question === '') {
      return renderResult;
    }
    // tìm kiếm '___' và chèn khoảng trắng, replace 1 lần nữa để tìm nếu dư khoảng trắng trong chuỗi.
    data.question = data.question.replace(/___/g, ' ___ ').replace(/\s+/g, ' ');
    data.question = _.replace(data.question, '.......', '___');
    if (data.question.includes('___')) {
      const words = data.question;
      // renderResult = words.replace(/___/g, ' ___ ').replace(/\s+/g, ' ');
      renderResult = words.split(' ').map((word: string, index: number) => {
        const flag = word === '___,';
        return (
          <React.Fragment key={index}>
            <span style={{ whiteSpace: 'nowrap' }}>
              {word === '___' || flag ? (
                selectedAnswer !== null && selectedAnswer !== undefined && selectedAnswer !== null ? (
                  <span style={{ color: colors.theme.success, paddingBottom: 3, borderBottom: '1px dashed #adb5bd' }}>
                    {' '}
                    {selectedAnswer?.answer}
                  </span>
                ) : (
                  <span style={{ paddingLeft: 120, paddingBottom: 3, borderBottom: '1px dashed #adb5bd' }} />
                )
              ) : (
                word
              )}
              {flag && ', '}
              &nbsp;
            </span>
          </React.Fragment>
        );
      });

      return <div className='questionText'>{renderResult}</div>;
    }

    // FOCUSED TEXT
    if (data.focusedWords) {
      const words = data.question;
      renderResult = words.split(' ').map((word: string, index: number) => {
        const found = _.find(data.focusedWords, (x) => {
          const a = x.replace('.', '');
          const b = word.replace('.', '');
          return a === b;
        });

        if (found) {
          return (
            <span key={`word-${index}`}>
              {word.split(found).map((item, i) => {
                return (
                  <React.Fragment key={`part-${i}`}>
                    {i !== 0 && (
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          color: '#f5365c',
                          paddingBottom: 3,
                          borderBottom: '1px dashed #adb5bd',
                        }}
                      >
                        {found}
                      </span>
                    )}
                    {item}
                  </React.Fragment>
                );
              })}
            </span>
          );
        }
        return <span key={`word-${index}`}>{word}&nbsp;</span>;
      });
    } else {
      renderResult = <span>{data.question}</span>;
    }

    return <div className='questionText'>{renderResult}</div>;
  };

  const renderImageAnswers = () => {
    let direction = data?.settings && data?.settings?.answers && data?.settings?.answers?.direction;
    if (direction === null || direction === undefined) {
      direction = 'vertical';
    }

    let bulletType = data?.settings && data?.settings?.answers && data?.settings?.answers?.bulletType;
    if (bulletType === null || bulletType === undefined) {
      bulletType = 'character';
    }

    return (
      <div className='answers-onechoice answers-onechoice-horizontal'>
        {shuffed
          ? shuffedAnswers
          : data.answers.map((item: any, index: number) => {
              const isSelected =
                selectedAnswer && selectedAnswer.id === item.id && selectedAnswer.answer === item.answer;
              return (
                <div
                  key={`answer-${index}`}
                  className='tw-p-4 tw-mr-4'
                  style={{
                    cursor: 'pointer',
                    borderRadius: 8,
                    border: isSelected ? '2px solid #2dce89' : '2px dashed #adb5bd',
                  }}
                >
                  <img
                    alt=''
                    src={item.answer}
                    style={{ height: 220 }}
                    onClick={() => {
                      setSelectedAnswer(item);
                    }}
                  />
                </div>
              );
            })}
      </div>
    );
  };

  const renderAnswers = () => {
    let direction = data?.settings && data?.settings?.answers && data?.settings.answers.direction;
    if (direction === null || direction === undefined) {
      direction = 'vertical';
    }

    let bulletType = data?.settings && data?.settings?.answers && data?.settings.answers.bulletType;
    if (bulletType === null || bulletType === undefined) {
      bulletType = 'character';
    }

    return (
      <div className={`answers-onechoice answers-onechoice-${direction}`}>
        {shuffed
          ? shuffedAnswers
          : data?.answers?.map((item: any, index: number) => {
              const isSelected =
                selectedAnswer && selectedAnswer.id === item.id && selectedAnswer.answer === item.answer;
              return (
                <Button
                  key={`answer-${index}`}
                  className={`answerButton-onechoice answerButton-onechoice-${direction}`}
                  onClick={async () => {
                    setSelectedAnswer(item);

                    if (item.audioUrl) {
                      refAudio.current.pause();
                      refAudio.current.currentTime = 0;
                      refAudio.current.src = item.audioUrl;
                      refAudio.current.play();
                    }
                  }}
                  style={{
                    border: '1px solid #bbb9b9',
                    fontSize: 17,
                    cursor: isDisableButtonNextSlide ? 'not-allowed' : 'pointer',
                  }}
                  color={isSelected ? 'success' : 'secondary'}
                  disabled={isDisableButtonNextSlide}
                >
                  {bulletType === 'character' && `${alphabet[index].toUpperCase()}. `}
                  {bulletType === 'number' && `${number[index].toUpperCase()}. `}
                  {bulletType === 'special characters' ? (
                    <React.Fragment>{item.answer.trim() === 'up' ? '↗' : '↘'}</React.Fragment>
                  ) : (
                    renderUnderline(item.answer, data)
                  )}
                  {item.audioUrl && <SpeakerIcon size={24} style={{ marginTop: -4, verticalAlign: 'middle' }} />}
                </Button>
              );
            })}
      </div>
    );
  };
  const handleAnswersNResult = () => {
    const result = selectedAnswer?.isCorrect;
    // Callback
    const answers = [...shuffedAnswers];
    answers.forEach((x) => {
      if (x.id === selectedAnswer?.id) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });
    return { answers, result };
  };
  const handleSubmitOnCanceling = () => {
    const { answers, result } = handleAnswersNResult();

    onSubmitWhenCancel?.({id: data.id, answers, result }, data);
  };

  // Handle Submit Button
  const handleSubmit = () => {
    const { answers, result } = handleAnswersNResult();
    onSubmit?.({id: data.id, answers, result }, data);
  };

  // React.useEffect(() => {
  //   //Auto submit after selecting for Menard
  //   if (!preview && settings.IS_MENARD && selectedAnswer) {
  //     if (!isDisableContinue) handleSubmit();
  //     if (setIsDisableContinue) setIsDisableContinue(true);
  //   }
  // }, [selectedAnswer, setIsDisableContinue, preview]);

  return (
    <Card>
      {!isChildQuestion && (
        <CardHeader>
          <QuestionHeader title={data.title} subTitle={data.subTitle} questionIndex={questionIndex} />
        </CardHeader>
      )}

      <CardBody style={{ minHeight: 300 }}>
        <AudioButton audioUrl={data.audioUrl} setAudio={setAudio} preview={preview} />
        {renderQuestionText()}
        {renderQuestionImage()}
        {renderQuestion()}
        {/* Answers */}
        {data?.settings?.answers?.imageAnswers ? renderImageAnswers() : renderAnswers()}
      </CardBody>
    </Card>
  );
}

export default React.forwardRef(OneChoice);
