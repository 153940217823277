import { BellOutlined as BellIcon, CalendarOutlined } from '@ant-design/icons';
import settings from 'constants/settings';
import i18next from 'i18next';

type NotificationProps = {
  avatar: string;
  body: string;
  classId: number;
  color: string;
  createdBy: number;
  createdDate: string;
  icon: string;
  id: number;
  name: string;
  receiver: string;
  status: string;
  title: string;
};
const type = settings.IS_LG
  ? [
      {
        title: 'Type',
        key: 'typeName',
        width: '1%',
        render: 'renderNoWrap',
        style: {
          minwidth: 50,
          fontWeight: '400',
        },
      },
    ]
  : [];
export default {
  displayColumns: [
    {
      key: 'title',
      render: (text: string, record: NotificationProps, index: number) => {
        return (
          <div
            style={{
              cursor: 'pointer',
              fontWeight: 600,
            }}
          >
            <BellIcon style={{ color: record.color }} className='tw-mr-2' />
            {text}
          </div>
        );
      },
      sort: false,
      title: i18next.t('table:notification.Title'),
    },
    ...type,
    {
      key: 'createdDate',
      prefix: <CalendarOutlined className='tw-mr-2' />,
      render: 'renderDateTime',
      style: {
        fontWeight: '400',
      },
      title: i18next.t('table:notification.Created date'),
      width: '1%',
    },
    {
      key: 'receiver',
      render: (receiver: string, record: NotificationProps, index: number) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {receiver === 'all' && i18next.t('table:notification.All')}
            {receiver === 'admin' && 'Admin'}
            {receiver === 'manager' && i18next.t('Manager')}
            {receiver === 'teacher' && i18next.t('table:notification.Teacher')}
            {receiver === 'student' && (settings.IS_VIMARU ? 'Sinh viên' : i18next.t('table:notification.Student'))}
          </div>
        );
      },
      style: {
        fontWeight: 400,
      },
      title: i18next.t('table:notification.Send to'),
      width: '5%',
    },
  ],
  rowKey: 'id',
};
