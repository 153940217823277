import { Badge, Modal, Tabs } from 'antd';
import colors from 'constants/colors';
import i18next from 'i18next';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';
import ExamDiaryTab from './ExamDiaryTab';
import { IS_LG } from 'constants/settings';

const ExamDiary = ({ record }) => {
  const [visible, setVisible] = React.useState(false);
  const params = useParams();
  return (
    // 'Learners Management' Nhật ký làm bài
    <React.Fragment>
      <DynamicButton type='text' style={{}} onClick={() => setVisible(true)}>
        <Badge offset={[5, -5]} count={record?.totalHomework} overflowCount={IS_LG ? 5 : 999}>
          <span style={{ color: colors.theme.button }}>{i18next.t('table:classInformation.Exam diary')}</span>
        </Badge>
      </DynamicButton>
      <Modal
        centered
        width='90vw'
        open={visible}
        title={i18next.t('table:classInformation.Exam diary')}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[
          <DynamicButton
            key='close-modal'
            onClick={() => {
              setVisible(false);
            }}
          >
            {i18next.t('Close')}
          </DynamicButton>,
        ]}
        bodyStyle={{
          height: 'calc( 100vh - 120px )',
          overflowY: 'scroll',
          paddingBottom:0
        }}
        destroyOnClose
      >
        <ExamDiaryTab record={record} classId={params.classId} />
      </Modal>
    </React.Fragment>
  );
};

ExamDiary.propTypes = {
  classId: PropTypes.string,
};

ExamDiary.defaultProps = {
  classId: '',
};

export default ExamDiary;
