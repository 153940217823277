/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable sort-keys */
import { InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Select, Space, Spin, Tooltip } from 'antd';
import { getDataGrade, InsertDataQuestion, SelectAllType } from 'modules/Administration/ModuleAddQuestions';
import React from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import TextArea from 'antd/lib/input/TextArea';
import { query, update } from 'helpers/QueryHelper';
import data from 'modules/Azota/Data';
import IndexRenderContentAnswer from './components';
import ViewHeader from './Layouts/ViewHeader';
import axios from 'axios';
import { SettingDefault } from '../SettingDefault';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import settings from 'constants/settings';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import colors from 'constants/colors';
import {
  getQuestionGradeOptions_UseQuery,
  getQuestionOptionTypeOptions,
  getQuestionTypeOptions_UseQuery,
} from '../../api/QuestionCategoriesApi';
import IndexCombine from './components/Combine';

const IndexFormImportQuestion = ({
  buttonCloseModal,
  typeView,
  dataQuestion,
  setIsOpenModalChooseQuestion, //Combine
  arrayDataChooseQuestionCombine, //Combine
}) => {
  const { t } = useTranslation();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [form] = Form.useForm();
  const [selectOptionType, setSelectOptionType] = React.useState();
  const [isDisable, setIsDisable] = React.useState({
    optionType: true,
    inputAnswer: true,
    hiddenHeader: true,
  });
  const [isLoadingCreateQuestion, setIsLoadingCreateQuestion] = React.useState(false);
  const queryClient = useQueryClient();
  const [processing, setProcessing] = React.useState(false);
  const [countAnswerFillBlank, setCountAnswerFillBlank] = React.useState(1);
  const [typeSelected, setTypeSelected] = React.useState('');
  const [optionType, setOptionType] = React.useState('');
  const [valueAnswerInputs, setValueAnswerInputs] = React.useState([]);

  const { data: type } = getQuestionTypeOptions_UseQuery();
  const { data: selectGrade } = getQuestionGradeOptions_UseQuery();

  const initialValues = {
    type: '',
    tags: '',
    optionType: '',
    questionText: '',
  };

  const isHiddenAddFormField = (type) => {
    let listHiddenType = ['FILLINTHEBLANK', 'UNSCRAMBLE', 'RECORD', 'COMBINE', 'FILLCOLOR', 'LISTENANDMATCH'];
    for (const index in listHiddenType) {
      if (type.toUpperCase() === listHiddenType[index]) {
        return true;
      } else {
        continue;
      }
    }
    return false;
  };

  const setFieldValuesUpdate = () => {
    if (typeView === 'update') {
      setIsLoadingCreateQuestion(true);
      setOptionType(dataQuestion?.optionType);
      setTypeSelected(dataQuestion?.type);
      setIsDisable((prev) => ({
        ...prev,
        optionType: true,
        inputAnswer: false,
        hiddenHeader: false,
      }));
      if (dataQuestion?.type?.toUpperCase() === 'RECORD') {
        form.setFieldsValue({
          text: dataQuestion?.answers?.[0]?.text,
          phonetic: dataQuestion?.answers?.[0]?.phonetic,
          type: dataQuestion?.type,
          audioUrl: dataQuestion?.audioUrl,
          imageUrl: dataQuestion?.imageUrl,
          tags: dataQuestion?.tags,
          grade: dataQuestion?.gradeCode,
          questionText: dataQuestion?.questionText,
          optionType: dataQuestion?.optionType,
          videoUrl: dataQuestion?.videoUrl,
        });
      } else {
        form.setFieldsValue({
          type: dataQuestion.type,
          tags: dataQuestion.tags,
          audioUrl: dataQuestion?.audioUrl,
          imageUrl: dataQuestion?.imageUrl,
          grade: dataQuestion.gradeCode,
          questionText: dataQuestion.questionText,
          optionType: dataQuestion?.optionType,
          answers: dataQuestion?.answers === '[]' ? [] : [...dataQuestion?.answers],
          videoUrl: dataQuestion?.videoUrl,
        });
      }
      query('p_QUIZCITY_GetOptionTypeName_ByOptionTypeCode', { OptionTypeCode: dataQuestion?.optionType }).then(
        (res) => {
          setIsLoadingCreateQuestion(false);
          if (dataQuestion?.type?.toUpperCase() === 'RECORD') {
            form.setFieldsValue({
              optionType: res?.[0].questionTypeName,
            });
          } else {
            form.setFieldsValue({
              optionType: res?.[0]?.questionTypeName,
            });
          }
        },
      );
      setOptionType(dataQuestion?.optionType);
      setTypeSelected(dataQuestion?.type);
      setCountAnswerFillBlank(dataQuestion?.answers?.length);
      setIsLoadingCreateQuestion(false);
    } else if (typeView === 'add') {
      form.resetFields();
    }
  };
  React.useEffect(() => {
    setFieldValuesUpdate();
  }, [typeView]);

  const getValueAnswer = () => {
    if (typeView === 'update') {
      return Array.from({ length: countAnswerFillBlank }, (x, i) => {
        if (dataQuestion.type.toUpperCase() === 'FILLINTHEBLANK') {
          const data = dataQuestion.answers[i];
          return {
            answer: data?.answer,
            isCorrect: true,
            id: i + 1,
          };
        }
        return {
          answer: data?.answer,
          isCorrect: data?.isCorrect,
          id: data?.id,
        };
      });
    }
    return Array.from({ length: countAnswerFillBlank }, (x, i) => ({
      answer: valueAnswerInputs?.[i],
      isCorrect: true,
      id: i + 1,
    }));
  };

  const onFinish = (values) => {
    setProcessing(true);
    let svgItems;
    if (typeSelected === 'FILLCOLOR' || optionType.toLocaleUpperCase() === 'LISTENANDMATCH') {
      svgItems = document.querySelector('#svg-html').children;
    }
    let newValue;
    setIsLoadingCreateQuestion(true);
    // const { setting } = SettingParameters(optionType?.toUpperCase());

    setIsLoadingCreateQuestion(false);
    let newAnswers = [];
    if (typeSelected === 'UNSCRAMBLE') {
      newValue = {
        ...values,
        questionText: values?.answers?.[0]?.answer?.toString()?.replaceAll('"', "'"),
      };
      newAnswers.push({
        answer: values?.answers?.[0]?.answer?.toString()?.replaceAll('"', "'"),
      });
    } else if (typeSelected === 'RECORD') {
      if (optionType.toLocaleUpperCase() === 'LISTENANDSPEAK') {
        newAnswers.push({
          questionTextVN: values?.questionTextVN?.toString()?.replaceAll('"', "'"),
          text: values?.text?.toString()?.replaceAll('"', "'"),
          textVN: values?.textVN?.toString()?.replaceAll('"', "'"),
        });
        newValue = {
          ...values,
        };
      } else if (optionType.toUpperCase() === 'LOOKSENTENCEANDIMAGE') {
        newValue = {
          ...values,
        };
      } else {
        newAnswers.push({ text: values?.text?.toString()?.replaceAll('"', "'"), phonetic: values.phonetic });
        newValue = {
          ...values,
        };
      }
    } else if (typeSelected === 'MATCHING') {
      if (optionType.toLocaleUpperCase() === 'LISTENANDMATCH') {
        let width = svgItems?.[0]?.attributes.width?.value | '';
        let height = svgItems?.[0]?.attributes.height?.value | '';
        let viewBox = svgItems?.[0]?.attributes?.viewBox?.value | '';
        let imageHref = svgItems?.[0].getElementsByTagName('image');
        let svgPathItems = svgItems?.[0].getElementsByTagName('path');
        let itemPaths = [];
        for (let i = 0; i < svgPathItems.length; i++) {
          const answer = svgPathItems?.[i].getAttribute('data-answer');
          const image = svgPathItems?.[i].getAttribute('data-image');
          newAnswers.push({ id: i + 1, answer: answer, image: image });
          itemPaths.push({ id: i + 1, data: svgPathItems?.[i].getAttribute('d') });
        }
        newValue = {
          ...values,
          imageSvg: {
            width: width,
            height: height,
            viewBox: viewBox,
            image: {
              width: imageHref?.[0]?.getAttribute('width'),
              href: imageHref?.[0]?.getAttribute('href'),
            },
            itemPaths: itemPaths,
          },
        };
      } else {
        values?.answers?.map((item, index) => {
          const { answer, isCorrect, ...valueAnswer } = item;
          newAnswers.push({
            ...valueAnswer,
            answer: answer?.toString()?.replaceAll('"', "'"),
            isCorrect: true,
            id: index + 1,
          });
        });
        newValue = {
          ...values,
          settings:
            optionType.toLocaleUpperCase() === 'READMATCHSENTENCE'
              ? {
                  showTitleColumn: false, // Hiển thị cột A, B.
                  isDragAColumnDisabled: true,
                }
              : {},
        };
      }
    } else if (typeSelected === 'COMBINE') {
      newAnswers = arrayDataChooseQuestionCombine;
      newValue = {
        ...values,
      };
    } else if (typeSelected === 'FILLCOLOR') {
      let width = svgItems?.[0].attributes.width.value;
      let viewBox = svgItems?.[0].attributes.viewBox.value;
      let imageHref = svgItems?.[0].getElementsByTagName('image');
      let svgPathItems = svgItems?.[0].getElementsByTagName('path');
      let itemPaths = [];
      for (let i = 0; i < svgPathItems.length; i++) {
        const color = svgPathItems?.[i].style.fill;
        newAnswers.push({ id: i + 1, answer: color });
        itemPaths.push({ id: i + 1, data: svgPathItems?.[i].getAttribute('d') });
      }
      newValue = {
        ...values,
        imageSvg: {
          width: width,
          viewBox: viewBox,
          image: {
            width: imageHref?.[0].getAttribute('width'),
            href: imageHref?.[0].getAttribute('href'),
          },
          itemPaths: itemPaths,
        },
      };
    } else {
      values?.answers?.map((item, index) => {
        const { answer, isCorrect, ...valueAnswer } = item;

        newAnswers.push({
          ...valueAnswer,
          answer: answer?.toString()?.replaceAll('"', "'"),
          isCorrect: isCorrect === undefined ? false : isCorrect,
          id: index + 1,
        });
      });
      newValue = {
        ...values,
      };
    }

    const { setting } = SettingDefault(typeSelected.toUpperCase(), optionType.toUpperCase());
    newValue = {
      ...newValue,
      settings: setting,
    };

    const params = {
      type: newValue.type,
      optionType: optionType?.toUpperCase(),
      tags: newValue.tags,
      grade: newValue.grade,
      imageUrl: newValue.imageUrl || '',
      audioUrl: newValue.audioUrl || '',
      questionText: newValue?.questionText?.toString()?.replaceAll('"', "'"),
      answers: JSON.stringify(newAnswers ?? ''),
    };

    if (typeView === 'update') {
      update('Questions', dataQuestion.id, params, 'RESOURCES', 'SACHSO').then(() => {
        setProcessing(false);
        message.success(i18next.t('update success', { field: t('data') }));
        queryClient.invalidateQueries('SACHSO_RESOURCES_Questions');
        setIsLoadingCreateQuestion(false);
        setSelectOptionType();
        setOptionType('');
        setTypeSelected('');
        buttonCloseModal?.();
      });
      return;
    }
    InsertDataQuestion(
      newValue.type,
      newValue.optionType?.toUpperCase(),
      newValue?.questionText?.toString()?.replaceAll('"', "'"),
      JSON.stringify(newAnswers),
      newValue.imageUrl || '',
      newValue.audioUrl || '',
      newValue.videoUrl || '',
      newValue.grade,
      '',
      JSON.stringify(newValue.settings),
      1,
      '',
      newValue.grade.split(' ').join(''),
      loggedInUser?.id,
      newValue.tags,
      JSON.stringify(newValue.imageSvg) || '',
    ).then(() => {
      setProcessing(false);
      message.success(i18next.t('add success', { field: t('data') }));
      queryClient.invalidateQueries('SACHSO_RESOURCES_Questions');
      setIsLoadingCreateQuestion(false);
      setSelectOptionType();
      // form.resetFields();
      setOptionType(' ');
      setTypeSelected(' ');
      buttonCloseModal?.();
    });
  };
  const onChangeValueOptionType = (value) => {
    if (value) {
      setIsDisable((prev) => ({ ...prev, hiddenHeader: false, inputAnswer: false }));
      setOptionType(value);
    }
  };
  const onChangeValueType = async (value, options) => {
    if (!value) return;

    setIsDisable((prev) => ({
      ...prev,
      optionType: false,
    }));
    form.setFieldsValue({
      optionType: '',
      audioUrl: '',
      imageUrl: '',
    });
    setTypeSelected(value.toUpperCase());
    const opts = await getQuestionOptionTypeOptions(options?.id);
    setSelectOptionType(opts);
  };
  const onChangeQuestionTextFilBlank = (value) => {
    if (typeSelected?.toUpperCase() === 'FILLINTHEBLANK') {
      const values = value.target.value;
      let count = 0;
      let position = values.indexOf(' ___');
      while (position !== -1) {
        count++;
        position = values.indexOf(' ___', position + 1);
      }
      // Object.assign(answers[count], { answer: '', isCorrect: true });
      setCountAnswerFillBlank(count);
      form.setFieldsValue({ answers: getValueAnswer() });
    }
  };
  const onFormLayoutChange = ({ layout }) => {};
  return (
    <Spin spinning={isLoadingCreateQuestion}>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onValuesChange={onFormLayoutChange}
        layout='vertical'
      >
        <Row gutter={[12, 12]}>
          <Col lg={4} xs={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('please choose', { field: t('typeQuestions') }),
                },
              ]}
              name='type'
              label={i18next.t('typeQuestions')}
              style={{ width: '100%' }}
              className='label_import_question'
            >
              <Select
                style={{ color: colors.font }}
                showSearch
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                disabled={typeView === 'update'}
                placeholder={i18next.t('typeQuestions')}
                onChange={onChangeValueType}
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={type}
              />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('please choose', { field: t('optionType') }),
                },
              ]}
              name='optionType'
              label={i18next.t('optionType')}
              style={{ width: '100%' }}
            >
              <Select
                showSearch
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                style={{ width: '100%', color: colors.font }}
                disabled={isDisable?.optionType}
                onChange={onChangeValueOptionType}
                placeholder={i18next.t('optionType')}
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                defaultValue={selectOptionType}
                options={selectOptionType}
              />
            </Form.Item>
          </Col>
          <Col lg={4} xs={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('please choose', { field: t('Level') }),
                },
              ]}
              name='grade'
              label={i18next.t('Level')}
              style={{ width: '100%' }}
            >
              <Select
                showSearch
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                style={{ width: '100%', color: colors.font }}
                disabled={false}
                placeholder={i18next.t('Level')}
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={selectGrade}
              />
            </Form.Item>
          </Col>
          <Col lg={4} xs={24}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('please enter', { field: 'Tags' }),
                },
              ]}
              name='tags'
              label='Tags'
              style={{ width: '100%' }}
            >
              <Input style={{ color: colors.font }} />
            </Form.Item>
          </Col>
        </Row>

        {(!isDisable.hiddenHeader || typeView === 'update') && (
          <>
            <ViewHeader type={typeSelected} form={form} optionType={optionType} />
            {typeSelected === 'FILLINTHEBLANK' ? (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('please enter', { field: t('questionText') }),
                  },
                ]}
                name='questionText'
              >
                {/* <Input onChange={onChangeQuestionTextFilBlank} /> */}
                <TextArea
                  rows={4}
                  placeholder='Question Text (Ex: Saranari is a line of anti-wrinkle and anti-aging products ... . )'
                  onChange={onChangeQuestionTextFilBlank}
                  suffix={
                    <Tooltip title='Extra information'>
                      <InfoCircleOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            ) : null}
          </>
        )}
        {typeSelected === 'COMBINE' ? (
          <IndexCombine
            dataQuestion={dataQuestion}
            setIsOpenModalChooseQuestion={setIsOpenModalChooseQuestion}
            arrayDataChooseQuestionCombine={arrayDataChooseQuestionCombine}
          />
        ) : null}

        {/* Import Content */}
        <div style={{ marginTop: 20 }}>
          <Form.List name='answers' initialValue={getValueAnswer()}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => {
                  let type = typeSelected?.toUpperCase() || dataQuestion?.type?.toUpperCase();
                  const isEssay = ['ESSAY', 'SPEAKING', 'WRITING'].includes(optionType?.toUpperCase());

                  return (
                    <>
                      {!isDisable?.inputAnswer && (
                        <>
                          <Row gutter={[12, 12]} key={field.key}>
                            <IndexRenderContentAnswer
                              type={typeView === 'update' ? dataQuestion?.type.toUpperCase() : typeSelected}
                              form={form}
                              field={field}
                              setAddField={add}
                              optionType={optionType}
                              setValueAnswerInputs={setValueAnswerInputs}
                            />
                            {isHiddenAddFormField(type) || isHiddenAddFormField(optionType) || isEssay ? null : (
                              <Col lg={1} xs={24}>
                                <Space>
                                  <Form.Item label=' '>
                                    <Button
                                      type='dashed'
                                      shape='circle'
                                      onClick={() => add()}
                                      icon={<PlusCircleOutlined />}
                                    />
                                  </Form.Item>
                                  {fields?.length > 1 ? (
                                    <Form.Item label=' '>
                                      <Button
                                        icon={<MinusCircleOutlined />}
                                        type='dashed'
                                        shape='circle'
                                        danger
                                        onClick={() => remove(field.name)}
                                      />
                                    </Form.Item>
                                  ) : null}
                                  {/* <PlusCircleOutlined /> */}
                                </Space>
                              </Col>
                            )}
                          </Row>
                        </>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </Form.List>
        </div>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: 20 }}>
            <DynamicButton
              onClick={() => {
                buttonCloseModal?.();
                form.resetFields();
              }}
            >
              {i18next.t('Close')}
            </DynamicButton>
            <DynamicButton style={{ border: 'none' }} loading={processing} htmlType='submit' type='primary'>
              {i18next.t('Save')}
            </DynamicButton>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
};
export default IndexFormImportQuestion;
