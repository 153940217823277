import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import withAuthenticator from 'HOCs/withAuthenticator';
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Space, Tooltip, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import colors from 'constants/colors';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import moment from 'moment';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import ReactQuill from 'react-quill';
import { courseColumns } from './tableColumn';

const ManagerQA = ({ loggedInUser }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const [modalStatus, setModalStatus] = React.useState({
    open: false,
    title: '',
    type: 'add',
    record: {},
  });
  const [searchParameter, setSearchParameter] = React.useState({});
  const [isProcessing, setIsProcessing] = React.useState(false);

  const queryClient = useQueryClient();

  // get data
  const KEY_SELECT_QA = ['p_QUIZCITY_QA_Management_Select', searchParameter];
  const { data: listQAs, isLoading } = useQuery(KEY_SELECT_QA, () =>
    query('p_QUIZCITY_QA_Management_Select', searchParameter),
  );
  //get cattegory
  const { data: categories, isLoadingTeachers } = useQuery('p_QUIZCITY_Request_Category_Select', () =>
    query('p_QUIZCITY_Request_Category_Select'),
  );

  // table action column
  const extraColumns = [
    {
      key: 'editInfomation',
      render: (text, record) => {
        return (
          <Space>
            <Tooltip placement='top' title={i18next.t('Update')}>
              <Button
                key='edit-file'
                shape='circle'
                type='dashed'
                icon={<EditOutlined />}
                onClick={() => {
                  handleShowModal('edit', record);
                }}
              />
            </Tooltip>
            <Popconfirm
              placement='topRight'
              title={() => {
                return (
                  <div>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>
                      {' '}
                      {i18next.t('Are you sure to delete', { object: i18next.t('question').toLowerCase() })}
                    </p>
                    <strong style={{ color: '#f5222d' }}>({i18next.t('popconfirm.unrecoverable')} )</strong>
                  </div>
                );
              }}
              onConfirm={() => onDelete(record.id)}
              okText={i18next.t('Confirm')}
              cancelText={i18next.t('Close')}
            >
              <Tooltip placement='top' title={i18next.t('Delete')}>
                <Button
                  key='edit-file'
                  shape='circle'
                  type='dashed'
                  danger
                  style={{ color: '#f5222d' }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
      fixed: 'right',
      sort: false,
      title: 'Action',
      width: '1%',
    },
  ];

  // open modal insert / update
  const handleShowModal = (type, record) => {
    const title = type === 'add' ? 'ADD NEW' : 'UPDATE INFORMATION';
    form.setFieldsValue(record);
    setModalStatus({
      open: true,
      title,
      type,
      record,
    });
  };

  // insert / update data
  const onInsertUpdate = (type, value, record) => {
    setIsProcessing(true);
    const { id, createdDate, createdBy } = record;
    const { answer, question, status, requestCategoryId } = value;
    const params =
      type === 'add'
        ? {
            Id: 0,
            RequestCategoryId: requestCategoryId,
            Status: status,
            Question: question,
            Answer: answer,
            CreatedDate: moment().toISOString(true),
            CreatedBy: loggedInUser?.id,
            ModifiedDate: moment().toISOString(true),
            ModifiedBy: loggedInUser?.id,
          }
        : {
            Id: id,
            RequestCategoryId: requestCategoryId,
            Status: status,
            Question: question,
            Answer: answer,
            CreatedDate: createdDate,
            CreatedBy: createdBy,
            ModifiedDate: moment().toISOString(true),
            ModifiedBy: loggedInUser?.id,
          };
    query('p_QUIZCITY_QA_Management_InsertUpdate', params)
      .then((res) => {
        queryClient.invalidateQueries(KEY_SELECT_QA);
        setModalStatus({ open: false });
        message.success(i18next.t('update success', { field: i18next.t('data') }));
      })
      .catch((error) => {
        message.error(i18next.t('update failed', { field: i18next.t('data') }));
        console.log(error);
      })
      .finally(() => setIsProcessing(false));
  };

  // search record
  const onSearch = (value) => {
    const newParams = {};
    for (const property in value) {
      if (value[property]) newParams[property] = value[property];
    }
    setSearchParameter(newParams); // change state => query
  };

  // delete record
  const onDelete = (id) => {
    query('p_QUIZCITY_QA_Management_Delete', { Id: id })
      .then((res) => {
        queryClient.invalidateQueries(KEY_SELECT_QA);
        message.success(i18next.t('delete success', { field: i18next.t('data') }));
      })
      .catch((error) => {
        message.error(i18next.t('delete success', { field: i18next.t('data') }));
        console.log(error);
      });
  };

  // format array trước khi hiển thị
  const formatData = (data) => {
    if (data?.length > 0) {
      const sortArr = _.sortBy(data, ['id']).reverse(); // hiển thị record mới nhất đầu tiên
      return sortArr;
    }
    return [];
  };

  return (
    <div>
      <Container name='Q&A managerment' parentName='Administration'>
        <Card
          title='Q&A managerment'
          extra={
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', columnGap: 10 }}>
              <DynamicButton
                onClick={() =>
                  handleShowModal('add', {
                    requestCategoryId: null,
                    status: null,
                    question: null,
                    answer: null,
                  })
                }
              >
                <PlusOutlined /> {i18next.t('addQuestion')}
              </DynamicButton>
            </div>
          }
        >
          {/* Form search record */}
          <Form
            form={formSearch}
            onFinish={onSearch}
            layout='horizontal'
            initialValues={{ requestCategoryId: '', status: '' }}
          >
            <div style={{ display: 'flex', gap: 15, flexWrap: 'wrap', rowGap: 0 }}>
              <Form.Item name='requestCategoryId' label='Category'>
                <Select
                  style={{ width: 200 }}
                  options={[{ label: 'All', value: '' }].concat(
                    categories ? categories.map((i) => ({ value: i.id, label: i.categoryName })) : [],
                  )}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => (option?.label?.toUpperCase() ?? '').includes(input.toUpperCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                />
              </Form.Item>
              <Form.Item name='status' label='Status'>
                <Select
                  options={[
                    { label: 'All', value: '' },
                    { label: 'Active', value: 'ACTIVE' },
                    { label: 'Deactive', value: 'DEACTIVE' },
                  ]}
                  style={{ width: 100 }}
                />
              </Form.Item>
              <Form.Item name='question' label='Question'>
                <Input />
              </Form.Item>
              <Form.Item name='answer' label='Answer'>
                <Input />
              </Form.Item>

              <Form.Item style={{ display: 'flex', alignItems: 'end' }}>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <DynamicButton type='primary' onClick={() => formSearch.submit()}>
                    {i18next.t('search')}
                  </DynamicButton>
                </div>
              </Form.Item>
            </div>
          </Form>

          {/* Table */}
          <DynamicTable
            loading={isLoading}
            dataSource={formatData(listQAs)}
            initialTableData={courseColumns}
            extraColumns={extraColumns}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '50', '100'],
            }}
            expandIconColumnIndex={5}
            expandIconAsCell={false}
          />
        </Card>
      </Container>

      {/* Modal insert / update */}
      <Modal
        title={<div style={{ color: colors.theme.button }}>{modalStatus.title}</div>}
        open={modalStatus.open}
        onCancel={() => setModalStatus({ open: false })}
        footer={[
          <div>
            <DynamicButton onClick={() => setModalStatus({ open: false })} style={{ marginRight: 5 }}>
              {i18next.t('Close')}
            </DynamicButton>
            <DynamicButton
              loading={isProcessing}
              style={{ border: 'none' }}
              type='primary'
              htmlType='submit'
              onClick={() => form.submit()}
            >
              {i18next.t('Save')}
            </DynamicButton>
          </div>,
        ]}
        centered
        destroyOnClose
      >
        <Form
          form={form}
          onFinish={(value) => onInsertUpdate(modalStatus.type, value, modalStatus?.record)}
          layout='vertical'
        >
          <Row gutter={12}>
            <Col span={16}>
              <Form.Item
                name='requestCategoryId'
                label='Category'
                rules={[{ required: true, message: i18next.t('please choose', { field: i18next.t('Category') }) }]}
              >
                <Select
                  style={{ width: '100%' }}
                  options={categories ? categories.map((i) => ({ value: i.id, label: i.categoryName })) : []}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => (option?.label?.toUpperCase() ?? '').includes(input.toUpperCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  dropdownStyle={{ zIndex: 9999 }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='status'
                label='Status'
                rules={[{ required: true, message: i18next.t('please choose', { field: i18next.t('Status') }) }]}
              >
                <Select
                  options={[
                    { label: 'Active', value: 'ACTIVE' },
                    { label: 'Deactive', value: 'DEACTIVE' },
                  ]}
                  style={{ width: '100%' }}
                  dropdownStyle={{ zIndex: 9999 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name='question'
            label='Question'
            rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('question') }) }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name='answer'
            label='Answer'
            rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('Answer') }) }]}
          >
            <ReactQuill theme='snow' style={{ color: colors.font }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default withAuthenticator(ManagerQA);
