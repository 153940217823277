/* eslint-disable react/prop-types */
import React from 'react';
import { Card, CardBody, CardFooter, CardImg } from 'reactstrap';

export default ({ item, onClick, displayFooter = false, displayDescription = true }) => {
  return (
    <React.Fragment>
      <Card
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (onClick && typeof onClick === 'function') {
            onClick();
          }
        }}
      >
        <CardImg alt={item.title} src={item.imageUrl} top />
        <CardBody>
          <div className='text-center'>
            <h4>{`${item.title} ${item.subTitle}`}</h4>
            {displayDescription && <small>{item.description}</small>}
          </div>
        </CardBody>
        {displayFooter && (
          <CardFooter>
            <div className='text-center pt-2'>
              <h5>{item.subTitle}</h5>
            </div>
          </CardFooter>
        )}
      </Card>
    </React.Fragment>
  );
};
