/* eslint-disable consistent-return */
/* eslint-disable no-useless-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, Col, Form, Input } from 'antd';
import React from 'react';
import Layout from '../../Layouts/Layout';
const IndexUnscrambleWords = ({ form, field, setAddField, optionType }) => {
  const RenderLayoutByOptionType = () => {
    switch (optionType.toUpperCase()) {
      case 'UNSCRAMBLEWORDS':
        return (
          <Layout field={field} layout={['input']} setAddField={setAddField} form={form} autoFocus={true} lg={24} />
        );

      default:
        return <></>;
    }
  };

  return <RenderLayoutByOptionType />;
};

export default IndexUnscrambleWords;
