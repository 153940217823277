/* eslint-disable sort-keys */
/* eslint-disable no-unused-vars */

import React from 'react';
import { Button } from 'antd';
import { execute } from 'helpers/QueryHelper';
import colors from 'constants/colors';
import {
  CalendarOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import EditInfomation from './editInfomation';
import settings from 'constants/settings';
import { AiOutlineShop } from 'react-icons/ai';
import { BsShop } from 'react-icons/bs';
import i18next from 'i18next';

export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: i18next.t('table:classInformation.Code'),
      key: 'maSV',
      width: '1%',
      fixed: 'left',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: i18next.t('table:classInformation.Full name'),
      key: 'fullName',
      fixed: 'left',
      width: '20%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
      prefix: <UserOutlined className='tw-mr-2' />,
    },
    {
      title: i18next.t('table:classInformation.Email'),
      key: 'email',
      width: '20%',
      render: 'renderNoWrap',
      style: {
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
      },
      prefix: <MailOutlined className='tw-mr-2' />,
    },
    {
      title: i18next.t('table:classInformation.Phone number'),
      key: 'phone',
      render: 'renderNoWrap',
      width: '20%',
      style: {
        fontWeight: 400,
        color: colors.font,
      },
      prefix: <PhoneOutlined className='tw-mr-2' />,
    },
    {
      title: i18next.t('Part'),
      key: 'shopName',
      width: '20%',
      render: 'renderNoWrap',
      style: {
        fontWeight: '400',
        color: colors.font,
      },
      prefix: <BsShop className='tw-mr-2 mt-2' />,
    },
    // {
    //   title: 'Loại tài khoản',
    //   key: 'roleId',
    //   width: '1%',
    //   render: (_, record) => {
    //     return <span>{record.roleId === 1 ? settings.studentPronouns2 : record.roleId === 3 ? "Quản trị viên" : "Giáo viên"}</span>;
    //   },
    //   style: {
    //     fontWeight: 400,
    //     color: colors.font,
    //   },
    // },
    // {
    //   title: 'Giới tính',
    //   key: 'sex',
    //   style: {
    //     fontWeight: 400,
    //     color: colors.font,
    //   },
    //   render: (_, record) => {
    //     return <span>{record?.sex !== null ? (record?.sex === true ? 'Nam' : 'Nữ') : ''}</span>;
    //   },
    // },
    {
      title: i18next.t('table:home.Status'),
      key: 'status',
      width: '20%',
      render: (text, record) => {
        const { status } = record;
        return (
          <span className={`status__table__user ${status === 'ACTIVE' ? 'active' : 'deactive'}`}>
            {status === 'ACTIVE' ? <UnlockOutlined /> : <LockOutlined />} {status}
          </span>
        );
      },
      soft: false,
      style: {
        fontWeight: '500',
        color: colors.font,
      },
    },
    {
      title: i18next.t('table:notification.Created date'),
      key: 'createdDate',
      width: '1%',
      sort: false,
      render: 'renderDateTime',
      prefix: <CalendarOutlined className='tw-mr-2' />,
    }
  ],
};
