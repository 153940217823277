import React from 'react';
import { Select, Form } from 'antd';
import IndexContentAddType from '../components';
import { DeleteOutlined, CheckOutlined, DashOutlined } from '@ant-design/icons';
const type = [
  {
    value: 'OneChoice',
    label: 'One choice',
    optionType: [
      {
        value: 'ListenAndChoose',
        label: 'Listen and choose',
      },
      {
        value: 'LookAndChoose',
        label: 'Look and choose',
      },
      {
        value: 'LookListenAndChoose',
        label: 'Look, listen and choose',
      },
      {
        value: 'ChooseTheCorrect',
        label: 'Choose the correct words',
      },
      {
        value: 'ReadAndChoose',
        label: 'Read and choose',
      },
    ],
  },
  {
    value: 'MultipleChoose',
    label: 'Multiple choose',
    optionType: [
      {
        value: 'ListenAndChooseTheLetterYouHear',
        label: 'Listen and choose the letter your hear',
      },
      {
        value: 'ListenAndChooseAllTheLetterMentioned',
        label: 'Listen and choose all the letter mentioned',
      },
    ],
  },
  {
    value: 'Matching',
    label: 'Matching',
    optionType: [
      {
        value: 'MatchThePicture',
        label: 'Match the picture',
      },
      {
        value: 'MatchTheSound',
        label: 'Match the sound',
      },
    ],
  },
  {
    value: 'Unscramble',
    label: 'Unscramble',
    optionType: [
      {
        value: 'LookAndUnscramble',
        label: 'Look and Unscramble',
      },
      {
        value: 'PutTheNumberInAscendingOrder',
        label: 'Put the numbers in ascending order',
      },
    ],
  },
  // {
  //   value: 'PuzzleWords',
  //   label: 'Puzzle words',
  // },
  // {
  //   value: 'Write',
  //   label: 'Write',
  // },
  // {
  //   value: 'Dropdown',
  //   label: 'Dropdown',
  // },
];
const SelectTypeOption = ({
  useCallBackSetKey,
  field,
  fields,
  active,
  index,
  setRemoveField,
  setAllValuesQuestion,
  form,
}) => {
  const [isActive, setActive] = React.useState(false);
  const [isOptionsType, setOptionsType] = React.useState();
  const [isDisableOptionsType, setDisableOptionsType] = React.useState(true);
  const [isType, setType] = React.useState();
  const [isContentOptionType, setContentOptionType] = React.useState();

  const handleChooseType = (value, options) => {
    setOptionsType(options?.optionType);
    setDisableOptionsType(false);
    setType(value);
  };
  const handleRenderOptionsType = (value) => {
    setContentOptionType(value);
  };
  const OnClickShowView = (e) => {
    useCallBackSetKey(e);
  };

  React.useEffect(() => {
    setActive(active);
  }, [active]);
  return (
    <div className='header'>
      {/* {isActive && (
        <div className='icon-top'>
          <DashOutlined
            style={{ fontSize: 20, cursor: 'pointer' }}
            onClick={() => {
              OnClickShowView(index);
              console.log(index);
            }}
          />
        </div>
      )} */}
      <div key={index} className={`select_choose_type ${isActive ? 'max-height' : 'min-height'}`}>
        <Form.Item
          {...field}
          name={[field.name, 'type']}
          style={{
            width: '100%',
            cursor: 'pointer',
          }}
        >
          <Select
            className='select_type'
            showSearch
            placeholder='Type'
            value={isType}
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode;
            }}
            onChange={handleChooseType}
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={type}
          />
        </Form.Item>
        <Form.Item
          {...field}
          name={[field.name, 'optionType']}
          style={{
            width: '100%',
            cursor: 'pointer',
          }}
        >
          <Select
            style={{
              width: '100%',
              cursor: 'pointer',
            }}
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode;
            }}
            className='select_type'
            disabled={isDisableOptionsType}
            value={isOptionsType}
            showSearch
            onChange={handleRenderOptionsType}
            placeholder='Options Type'
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={isOptionsType}
          />
        </Form.Item>
      </div>

      <div className={`input__question`}>
        <IndexContentAddType
          setAllValuesQuestion={setAllValuesQuestion}
          content={isContentOptionType}
          index={index}
          fieldC={field}
          form={form}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: 20, marginBottom: 20 }}>
        {/* <CopyOutlined
      className='icon'
      onClick={() => {
        const valueCopyType = valueCopy.answers?.[index]

        setAdd()
      }}
    /> */}
        {fields.length > 1 ? (
          <DeleteOutlined
            className='icon-remove'
            style={{ fontSize: 15 }}
            onClick={() => {
              setRemoveField(field.name);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SelectTypeOption;
