import ReactQuill from 'react-quill';

type SlideWordProps = {
  content: string;
};

export default function SlideWord({ content }: SlideWordProps) {
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <ReactQuill style={{ height: '100%' }} theme='bubble' defaultValue={content} readOnly />
    </div>
  );
}
