/* eslint-disable react/prop-types */
import { InfoCircleFilled } from '@ant-design/icons';
import { Affix, Button, Col, Collapse, Empty, Modal, Row, Spin, notification } from 'antd';
import AI_slide_icon from 'assets/icon/slidesIcons/AI.png';
import Instruction_slide_icon from 'assets/icon/slidesIcons/Instruction.png';
import Objective_slide_icon from 'assets/icon/slidesIcons/Objective.png';
import Summary_slide_icon from 'assets/icon/slidesIcons/Summary.png';
import Task_slide_icon from 'assets/icon/slidesIcons/Task.png';
import Video_slide_icon from 'assets/icon/slidesIcons/Video.png';
import Card from 'components/Containers/Card';
import SimpleContainer from 'components/Containers/SimpleContainer';
import NoData from 'components/NoData';
import colors from 'constants/colors';
import { IS_BASAO, IS_HIGHUP, IS_LG, IS_MENARD, IS_TRONGDONG, IS_VIMARU, PATHNAME } from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import getFirstQuestionId from 'modules/Presentation/api/getFirstQuestionId';
import Header from 'modules/Student/components/Dashboards/Header';
import MenardHeader from 'modules/Student/components/Dashboards/Header/MenardHeader';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AiFillPlayCircle } from 'react-icons/ai';
import { BsCollectionPlayFill } from 'react-icons/bs';
import { FiFileText, FiVideo } from 'react-icons/fi';
import { IoWarning } from 'react-icons/io5';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import LearningCommitmentModal from './LGhome/components/studentHome/LearningCommitmentModal';
import Swimlane2 from './Swimlane/Swimlane2';
import WarningModal from './WarningModal/index';
import './styles.css';

export default function Home({ loggedInUser }) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const selectedFolder = useSelector((state) => state.sidebarReducer.selectedFolder);
  const folderMenuClicked = useSelector((state) => state.sidebarReducer.folderMenuClicked);
  const KEY_PRESENTATIONS = ['p_QUIZCITY_Get_Folders_Presentation_V3', loggedInUser?.id, selectedFolder?.id];
  const [colapseStydyRoutine, setColapseStydyRoutine] = useState(false); // colapse
  const [isQueryStydyRoutine, setIsQueryStydyRoutine] = useState(true); // query 1 lần
  const [visibleWarningModal, setVisibleWarningModal] = useState(false);
  const [contentWarningModal, setContentWarningModal] = useState('');
  const [notificationMobile, setNotificationMobile] = React.useState(false);

  const moveToSlide = async (presentationId, slide) => {
    let questionId = await getFirstQuestionId(slide.id ?? slide?.slideId);

    if (slide.status !== 'Locked') {
      if (slide.type === 'Quiz')
        return history.push(
          `/${PATHNAME}/student/presentations/${presentationId}/slides/${
            slide.id ?? slide?.slideId
          }/questions/${questionId}`,
        );
      return history.push(`/${PATHNAME}/student/presentations/${presentationId}/slides/${slide.id ?? slide?.slideId}`);
    }
  };

  const {
    data: folders,
    isLoading,
    isSuccess,
    error,
  } = useQuery(
    KEY_PRESENTATIONS,
    () => {
      return query('p_QUIZCITY_Get_Folders_Presentation_V3', {
        studentId: loggedInUser?.id,
        folderId: selectedFolder?.id || '00000000-0000-0000-0000-000000000000',
      });
    },
    {
      enabled:
        !!selectedFolder?.id &&
        selectedFolder?.id !== '00000000-0000-0000-0000-000000000000' &&
        isQueryStydyRoutine &&
        colapseStydyRoutine,
      retry: () => false,
    },
  );

  const KEY_CURRENT_SLIDE = ['p_QUIZCITY_Slides_Current', loggedInUser?.id, selectedFolder?.id];

  const { data: currentSlide, isLoading: isCurrentSlideLoading } = useQuery(
    KEY_CURRENT_SLIDE,
    () => query('p_QUIZCITY_Slides_Current', { studentId: loggedInUser?.id, folderId: selectedFolder?.id }),
    {
      enabled:
        loggedInUser.role === 'student' &&
        !!selectedFolder?.id &&
        selectedFolder?.id !== '00000000-0000-0000-0000-000000000000',
      retry: () => false,
    },
  );
  // historyStatus Làm bài lần đầu của khoá học là có lịch sử làm bài hay không, nếu false là lần làm bài đầu tiên
  const isFirstTime = !currentSlide?.[0]?.historyStatus;

  const [guideModalVisible, setGuideModalVisible] = React.useState(false);
  const learningJourneyRef = useRef();
  const currentLessonRef = useRef();
  const carouselImages = [];
  for (let i = 1; i <= 5; i++) {
    let href = '';
    switch (i) {
      case 1:
        href = 'https://menard.vn/collagen/';
        break;
      case 2:
        href = 'https://menard.vn/collagen/';
        break;
      case 3:
        href = 'https://shop.menard.vn/duong-da/dong-san-pham-duong-da/authent-dong-san-pham-duong-da/';
        break;
      case 4:
        href = 'https://shop.menard.vn/trang-diem/embellir-trang-diem/';
        break;
      case 5:
        href = 'https://shop.menard.vn/duong-da/dong-san-pham-duong-da/saranari-dong-san-pham-duong-da/';
        break;
    }
    carouselImages.push(
      <a href={href} target='_blank'>
        <img
          key={`carousel-image-${i}`}
          style={{ width: '100%', objectFit: 'cover', borderRadius: '0.5rem' }}
          className='carousel-image'
          src={`https://cloud.softech.vn/storage/Data/MENARD/Carousel/carousel${i}.jpg`}
        />
      </a>,
    );
  }
  const currentSlideId = (presentation) => {
    let id = presentation?.slides?.[0]?.id;
    presentation?.slides?.forEach((slide) => {
      if (slide?.status === 'Playing') id = slide?.id;
    });
    return id;
  };

  // Vị trí thư mục, lesson đang học.
  const folderIndex = folders?.findIndex((x) => x.id === currentSlide?.[0]?.folderId);
  let lessonIndex = -1;
  if (folderIndex > -1) {
    lessonIndex = folders[folderIndex]?.lessons?.findIndex((x) => x.id.toLowerCase() === currentSlide?.[0]?.lessionId);
  }

  // Khi người dùng truy cập trang web với thiết bị di động thì sẽ hiện thông báo "web khôg tối ưu cho di động"
  React.useEffect(() => {
    if (IS_VIMARU || IS_HIGHUP) setNotificationMobile(window.innerWidth < 576);
  }, []);

  // Khi chọn khoá học khác từ "Trang chủ" thì reset 2 state để kiểm soát query
  React.useEffect(() => {
    setIsQueryStydyRoutine(true);
    setColapseStydyRoutine(false);
  }, [selectedFolder?.id]);

  // Chỉ query colapse một lần khi mở colapse
  useEffect(() => {
    if (folders) setIsQueryStydyRoutine(false);
  }, [folders]);

  const renderIcon = (slide) => {
    if (slide.icon) {
      switch (slide.icon) {
        case 'Video':
          return <img alt='video-icon' src={Video_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Task':
          return <img alt='video-icon' src={Task_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Summary':
          return <img alt='video-icon' src={Summary_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Objective':
          return <img alt='video-icon' src={Objective_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Instruction':
          return <img alt='video-icon' src={Instruction_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'AI':
          return <img alt='video-icon' src={AI_slide_icon} width={30} style={{ marginRight: '5px' }} />;

        default:
          break;
      }
    } else {
      switch (slide.type) {
        case 'Quiz':
          return <img alt='video-icon' src={Task_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'WorkSheet':
          return <img alt='video-icon' src={Task_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Video':
          return <img alt='video-icon' src={Video_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        case 'Word':
          return <img alt='video-icon' src={Summary_slide_icon} width={30} style={{ marginRight: '5px' }} />;
        default:
          break;
      }
    }
  };

  const getQuestionId = async (slideId) => {
    return await getFirstQuestionId(slideId);
  };
  useEffect(() => {
    if (folders?.length === 0 && !isLoading) {
      notification.error({
        message: 'Bạn không ở trong khóa học nào hoặc khóa học hiện tại không có bài học nào ',
        duration: 5,
      });
    }
  }, [selectedFolder, folders, isLoading]);

  const handleScroll = () => {
    if ((folderMenuClicked && IS_MENARD) || IS_BASAO || IS_TRONGDONG) {
      if (loggedInUser?.role === 'student') {
        currentLessonRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
      } else
        learningJourneyRef?.current?.scrollIntoView({
          behavior: 'smooth',
        });
    }
  };
  useLayoutEffect(() => {
    const timoutScroll = setTimeout(handleScroll, 100);

    return () => {
      clearTimeout(timoutScroll);
    };
  }, [folderMenuClicked]);
  return (
    <div>
      {IS_MENARD || IS_BASAO || IS_TRONGDONG ? (
        <MenardHeader selectedFolder={selectedFolder} parentName='Trang chủ' />
      ) : (
        <Header selectedFolder={selectedFolder} parentName='Trang chủ' />
      )}
      {IS_MENARD && (
        <Container fluid className='mt--6' style={{ marginBottom: loggedInUser.role === 'student' ? 50 : 102 }}>
          {/* <Space className='menard-carousel' size='large' style={{ width: '100%' }}>
            <Carousel
              // className='menard-carousel'
              autoplay
              fade
              style={{ width: '100%', maxWidth: '90vw', flex: 1, borderRadius: '0.5rem' }}
            >
              {carouselImages}
            </Carousel>
          </Space> */}
          <a href={'https://menard.vn/'} target='_blank'>
            <img
              style={{ flex: 1, width: '100%', borderRadius: '0.5rem', cursor: 'pointer' }}
              src={'https://cloud.softech.vn/storage/Data/MENARD/Carousel/carousel1.jpg'}
            />
          </a>
        </Container>
      )}
      <React.Fragment>
        {loggedInUser.role === 'student' && (
          <Container fluid className={!IS_MENARD && 'mt--6'} style={{ marginBottom: 102 }}>
            <div className='current-lesson' ref={currentLessonRef}>
              <Card
                title='Bài giảng đang học'
                // extra={
                //   <Switch
                //     checkedChildren={<UnorderedListOutlined />}
                //     unCheckedChildren={<TableOutlined />}
                //     onChange={(e) => setViewMode(e)}
                //   />
                // }
              >
                {selectedFolder && currentSlide && currentSlide?.length !== 0 ? (
                  <Row gutter={[24, 0]} style={{ marginTop: 0 }}>
                    <Col>
                      <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                        <img
                          alt=''
                          style={{ width: 32 }}
                          src='https://cdn-icons-png.flaticon.com/512/7341/7341822.png'
                        />
                      </div>
                    </Col>
                    <Col flex={1}>
                      <div
                        style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                      >
                        <Link
                          style={{ fontWeight: '700', color: colors.theme.button, height: '100%' }}
                          // to={`/lms/student/presentations/${
                          //   currentSlide?.[0]?.presentationId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id
                          // }/slides/${
                          //   currentSlide?.[0]?.slideId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id
                          // }`}
                          to='#'
                          onClick={() => {
                            query('p_QUIZCITY_CheckPermissonLearning', {
                              slideId:
                                currentSlide?.[0]?.slideId ??
                                folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id,
                              studentId: loggedInUser?.id,
                            }).then((res) => {
                              if (res?.[0]?.warningMessage) {
                                setVisibleWarningModal(true);
                                setContentWarningModal(res?.[0]?.warningMessage);
                              } else {
                                history.push(
                                  `/${PATHNAME}/student/presentations/${
                                    currentSlide?.[0]?.presentationId ??
                                    folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id
                                  }/slides/${
                                    currentSlide?.[0]?.slideId ??
                                    folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id
                                  }`,
                                );
                              }
                            });
                          }}
                        >
                          {`${currentSlide?.[0]?.folderTitle} >
                      ${currentSlide?.[0]?.lessonTitle} >
                      ${currentSlide?.[0]?.presentationTitle}`}
                        </Link>
                        <ul style={{ margin: 0 }}>
                          <li style={{ marginBlock: 4 }}>
                            <div className='tw-items-center' style={{ display: 'flex', flexDirection: 'row' }}>
                              {currentSlide?.[0]?.type === 'Video' && <FiVideo className='tw-mr-2' />}
                              {currentSlide?.[0]?.type === 'WorkSheet' && <FiFileText className='tw-mr-2' />}
                              <div>
                                {/* {currentSlide?.[0]?.slideTitle ??
                            folders?.[0]?.lessons?.[0]?.presentations?.[0].slides?.[0].title} */}
                                {currentSlide?.[0]?.slideTitle}
                              </div>
                              <AiFillPlayCircle color={colors.gold} size={20} className='tw-ml-2' />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col>
                      {/* <Link
                      style={{ fontWeight: '600', color: colors.primary }}
                      to={`/lms/student/presentations/${
                        currentSlide?.[0]?.presentationId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id
                      }/slides/${
                        currentSlide?.[0]?.slideId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id
                      }`}
                    > */}

                      <div
                        className={'current-slide-button'}
                        style={{ backgroundColor: colors.theme.button }}
                        onClick={() => {
                          query('p_QUIZCITY_CheckPermissonLearning', {
                            slideId:
                              currentSlide?.[0]?.slideId ??
                              folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id,
                            studentId: loggedInUser?.id,
                          }).then((res) => {
                            if (res?.[0]?.warningMessage) {
                              setVisibleWarningModal(true);
                              setContentWarningModal(res?.[0]?.warningMessage);
                            } else {
                              history.push(
                                `/${PATHNAME}/student/presentations/${
                                  currentSlide?.[0]?.presentationId ??
                                  folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id
                                }/slides/${
                                  currentSlide?.[0]?.slideId ??
                                  folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id
                                }`,
                              );
                              moveToSlide(
                                currentSlide?.[0]?.presentationId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id,
                                currentSlide?.[0],
                              );
                            }
                          });
                        }}
                      >
                        <BsCollectionPlayFill />
                        &ensp;
                        <span> {isFirstTime ? 'Bắt đầu' : ' Học tiếp'}</span>
                      </div>
                      {/* </Link> */}
                    </Col>
                  </Row>
                ) : isCurrentSlideLoading ? (
                  <div style={{ textAlign: 'center' }}>
                    <Spin />
                  </div>
                ) : (
                  <NoData />
                )}
              </Card>
            </div>
          </Container>
        )}

        <SimpleContainer>
          <div className='learning-journey' ref={learningJourneyRef} style={{ paddingBottom: 5 }}>
            <Card
              title={!IS_MENARD && selectedFolder ? `Lộ trình học tập - ${selectedFolder?.name}` : 'Lộ trình học tập'}
              // extra={
              // <Switch
              //   checkedChildren={<TableOutlined />}
              //   unCheckedChildren={<UnorderedListOutlined />}
              //   onChange={(e) => setViewMode(!viewMode)}
              // />
              // }
              onClickHeader={() => setColapseStydyRoutine(!colapseStydyRoutine)}
              extra={
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  {colapseStydyRoutine ? <SlArrowUp size={19} /> : <SlArrowDown size={19} />}
                </div>
              }
              style={{ marginBottom: 10 }}
            >
              {colapseStydyRoutine ? (
                <>
                  <div style={{ textAlign: 'center' }}>
                    <Spin spinning={isLoading}>
                      {error ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Empty description={'Tải dữ liệu không thành công! Vui lòng thử lại!'} />
                          <Button
                            type='primary'
                            onClick={() => {
                              queryClient.invalidateQueries(KEY_PRESENTATIONS);
                            }}
                          >
                            Tải lại
                          </Button>
                        </div>
                      ) : folders && folders.length !== 0 ? (
                        <Swimlane2 folders={folders} />
                      ) : (
                        <NoData></NoData>
                      )}
                    </Spin>
                  </div>
                </>
              ) : null}
            </Card>
          </div>
        </SimpleContainer>

        {(IS_MENARD || IS_BASAO || IS_TRONGDONG) && (
          <Link to={`/${PATHNAME}/libraries`}>
            <SimpleContainer className='tw-mt-6'>
              <Card title='Tài liệu thông tin các khóa học' className='md:tw-w-1/2'>
                <img
                  src={
                    IS_TRONGDONG
                      ? require('assets/img/introduce/trongdong_carousel1.png').default
                      : IS_BASAO
                      ? require('assets/img/introduce/basao_carousel1.png').default
                      : 'https://cloud.softech.vn/storage/SACHSO/t_SACHSO_RESOURCES_Questions/46d30c13-3555-4395-8b83-4b9b2f7402ae/1012b8fb-36df-4240-8b0b-6a1a8991caef/thu-vien-tai-nguyen-image.png'
                  }
                />
              </Card>
            </SimpleContainer>
          </Link>
        )}

        {(IS_MENARD || IS_BASAO || IS_TRONGDONG) && (
          <Affix style={{ position: 'fixed', bottom: 12, right: 12, zIndex: 9999 }}>
            <DynamicButton
              icon={<InfoCircleFilled />}
              type='primary'
              onClick={() => {
                setGuideModalVisible(true);
              }}
            >
              {IS_MENARD ? 'Qui chế học VMT School Online' : 'Quy chế học tập'}
            </DynamicButton>
          </Affix>
        )}

        <Modal
          centered
          open={notificationMobile}
          onCancel={() => setNotificationMobile(false)}
          footer={
            <Button type='primary' key='console' onClick={() => setNotificationMobile(false)}>
              Ok
            </Button>
          }
          width={450}
        >
          <div>
            <div style={{ display: 'flex', gap: 5, marginBottom: 10 }}>
              <IoWarning size={'2rem'} color={colors.theme.warning} />
              <h2>Thiết bị không phù hợp</h2>
            </div>
            <div style={{ marginBottom: 8 }}>
              - Website được thiết kế để sử dụng trên các thiết bị có màn hình lớn (máy tính, laptop, tablet,...) để
              mang lại trải nghiệm người dùng tốt nhất.
            </div>
            <div style={{ marginBottom: 8 }}>
              {` - Website không được tối ưu để sử dụng trên điện thoại và các thiết bị màn hình nhỏ ( <576 pixel).`}
            </div>
            <div>
              <i>Bạn có thể bỏ qua thông báo này và tiếp tục sử dụng !</i>
            </div>
          </div>
        </Modal>

        <Modal
          // title='HƯỚNG DẪN HOÀN THÀNH KHÓA HỌC'
          centered
          open={guideModalVisible}
          width={800}
          style={{ margin: 5 }}
          bodyStyle={{ overflowY: 'auto', maxHeight: '83vh', padding: '5px 10px' }}
          onCancel={() => {
            setGuideModalVisible(false);
          }}
          footer={[
            <Button
              onClick={() => {
                setGuideModalVisible(false);
              }}
              type='primary'
            >
              Tôi đã hiểu
            </Button>,
          ]}
        >
          {IS_MENARD ? (
            <>
              <div style={{ textAlign: 'center', fontSize: '1.2rem', fontWeight: 700, marginBottom: 10 }}>
                QUI CHẾ HỌC VMT SCHOOL ONLINE
              </div>
              <div style={{ display: 'flex', gap: '5px' }}>
                <strong>1.</strong>
                <div>
                  <strong>Nội dung chương trình:</strong>

                  <div style={{ display: 'flex', gap: '10px' }}>
                    <span>-</span>
                    <div>
                      Học viên hoàn thành toàn bộ Video của chương trình học. Và làm bài kiểm tra giữa khóa và cuối
                      khóa.
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', gap: '5px' }}>
                <strong>2.</strong>
                <div>
                  <strong>Yêu cầu khóa học:</strong>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <span>-</span>
                    <span>Sinh viên xem và làm lần lượt các nội dung học tập của chương trình.</span>
                  </div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <span>-</span>
                    <span>
                      Để được chuyển sang nội dung học tập tiếp theo, học viên cần đạt tối thiểu 70% câu trả lời đúng.
                    </span>
                  </div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <span>-</span>
                    <span>Điều kiện để được tham gia làm các bài kiểm tra giữa khóa và cuối khóa: </span>
                  </div>
                  <div>Điểm Chất lượng hoàn thành video đạt từ 50% trở lên.</div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <span>-</span>
                    <div>Điểm Chất lượng hoàn thành video được tính như sau:</div>
                  </div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <span>+</span>
                    <span>Thời lượng học ít nhất 70% Video </span>
                  </div>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <span>+</span>
                    <span>Kết quả bài test nhanh sau từng Video học đúng trên 70%. </span>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', gap: '5px' }}>
                <strong>3.</strong>
                <div>
                  <strong>Lưu ý</strong>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <span>-</span>
                    <span>
                      Học viên sẽ không thể ấn tua Video trong lần học đầu tiên. Từ lần học thứ 2, học viên có thể tua
                      để xem lại.
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div style={{ textAlign: 'center', fontSize: '1.2rem', fontWeight: 700, marginBottom: 10 }}>
                QUI CHẾ HỌC TẬP
              </div>
              <Row justify='center'>
                <b>
                  <i style={{ color: 'blue' }}>Đang cập nhật</i>
                </b>
              </Row>
            </>
          )}
        </Modal>
        <WarningModal
          visibleWarningModal={visibleWarningModal}
          setVisibleWarningModal={setVisibleWarningModal}
          contentWarningModal={contentWarningModal}
        />
        <LearningCommitmentModal loggedInUser={loggedInUser} />
      </React.Fragment>
    </div>
  );
}
