import { Col, Form, Input, Row, TreeSelect } from 'antd';
import { IS_LG, IS_MENARD, studentPronouns1 } from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import _ from 'lodash';
import { useStudentsOrStudentByClassId } from 'modules/Teacher/api/getStudentsOrStudentByClassId';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import {
  addOptionAllShops,
  buildTreeSelectShops,
  filterTreeSelectShops,
  getShopIdsFromIds,
} from 'pages/Administration/Menard/ManagerShopsV2/tableColumn';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { removeAccents } from 'services/Text/formatText';
import { getWidthOfString } from 'services/Text/widthText';
import tableData from './tableData';
import { paginationTable } from 'constants/Pagination';
import NoData from 'components/NoData';

// const buildTreeData = ({ title = 'name', value = 'id', addOptionAll = true, allData = [] }) => {
//   let treeOptions =
//     allData?.map((item) => ({
//       title: item[title],
//       key: item[value],
//       value: item[value],
//     })) ?? [];
//   if (addOptionAll)
//     treeOptions = [
//       { title: i18next.t('All'), key: 'all', value: '00000000-0000-0000-0000-000000000000', children: treeOptions },
//     ];
//   return treeOptions;
// };

const Students = ({ classId }) => {
  const [dataTable, setDataTable] = useState([]);
  const [parameters, setParameters] = useState({
    pageSize: 10,
    pageNumber: 1,
    search: '',
  });
  const { data, isLoading } = useStudentsOrStudentByClassId({
    classId,
    search: parameters.search,
    pageNum: parameters.pageNumber,
    pageSize: parameters.pageSize,
  });
  const { studentEmail } = useParams();
  const totalRows = data?.[0]?.totalRows ?? 0;

  // const { data: shops } = useQuery('p_QUIZCITY_Shop_Category_Select', () => query('p_QUIZCITY_Shop_Category_Select'), {
  //   enabled: IS_MENARD || IS_LG,
  // });
  // const { data: chucVus } = useQuery(
  //   'p_QUIZCITY_Position_Category_Select',
  //   () => query('p_QUIZCITY_Position_Category_Select'),
  //   {
  //     enabled: IS_MENARD,
  //   },
  // );

  // const optionsChucVu = chucVus
  //   ?.filter((i) => i?.isActive === true)
  //   ?.map((item) => ({ label: item.categoryName, value: item.id }));
  // const optionsShop = addOptionAllShops(buildTreeSelectShops(shops));
  // const treeData_ChucVu = buildTreeData({ title: 'label', value: 'value', addOptionAll: true, allData: optionsChucVu });

  // const widthOptionsShop = getWidthOfString(_.maxBy(shops, (i) => i?.categoryName?.length)?.categoryName, '14px') + 70;
  // const widthOptionsChucVu = getWidthOfString(_.maxBy(optionsChucVu, (i) => i?.label?.length)?.label, '14px') + 50;

  useEffect(() => {
    if (data) {
      if (studentEmail) setDataTable(data.filter((item) => isChoose(studentEmail, item.email)));
      else {
        const sorted = _.sortBy(data, ['shopName']);
        setDataTable(sorted);
      }
    }
  }, [data, studentEmail]);

  const isChoose = (text, string) => removeAccents(string)?.includes(removeAccents(text));

  const onSearch = (value) => {
    setParameters((pre) => ({ ...pre, search: value?.searchText ?? '' }));
    // const { studentCode, studentName, chucVuId, shopId } = value;
    // // Nếu không nhập vào field nào thì không lọc field đó
    // let filterList = _.cloneDeep(data);
    // if (studentCode) filterList = filterList.filter((item) => isChoose(studentCode, item.maSV));
    // if (studentName) filterList = filterList.filter((item) => isChoose(studentName, item.fullName));
    // if (chucVuId?.length > 0 && !chucVuId?.includes('00000000-0000-0000-0000-000000000000'))
    //   filterList = filterList.filter((item) => chucVuId === item.chucVuId);
    // if (shopId?.length > 0 && !shopId?.includes('00000000-0000-0000-0000-000000000000')) {
    //   const listId = getShopIdsFromIds(shopId, shops);
    //   const fillList = filterList.filter((item) => listId.includes(item.shopId));
    //   const sorted = _.sortBy(fillList, ['shopName']);
    //   filterList = sorted;
    // }
    // setDataTable(filterList);
  };
  return (
    <React.Fragment>
      <Form layout='inline' onFinish={onSearch}>
        <Row gutter={[12, 12]} className='tree-select-custom'>
          <Col>
            <Form.Item name='searchText' style={{ margin: 0 }}>
              <Input
                placeholder={
                  IS_LG ? `Student's code / Student's name` : `Mã ${studentPronouns1} / Tên ${studentPronouns1}`
                }
                allowClear
              />
            </Form.Item>
          </Col>
          {/* {(IS_LG || IS_MENARD) && (
            <Col>
              <Form.Item name='shopId' style={{ margin: 0 }}>
                <TreeSelect
                  allowClear
                  showSearch
                  treeData={optionsShop}
                  treeCheckable={true}
                  dropdownMatchSelectWidth={true}
                  showCheckedStrategy={'SHOW_PARENT'}
                  dropdownStyle={{
                    maxHeight: 600,
                    overflow: 'auto',
                    minWidth: 500,
                    width: '100%',
                  }}
                  // treeDefaultExpandAll
                  placeholder={i18next.t('Part')}
                  filterTreeNode={filterTreeSelectShops}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  style={{ minWidth: 120, width: widthOptionsShop }}
                />
              </Form.Item>
            </Col>
          )}
          {IS_MENARD && (
            <Col>
              <Form.Item name='chucVuId' style={{ margin: 0 }}>
                <TreeSelect
                  allowClear
                  showSearch
                  treeData={treeData_ChucVu}
                  treeCheckable={true}
                  dropdownMatchSelectWidth={true}
                  showCheckedStrategy={'SHOW_PARENT'}
                  style={{ minWidth: 80, width: widthOptionsChucVu }}
                  dropdownStyle={{
                    maxHeight: 600,
                    overflow: 'auto',
                    minWidth: 500,
                    width: '100%',
                  }}
                  // treeDefaultExpandAll
                  placeholder={i18next.t('All')}
                  filterTreeNode={filterTreeSelectShops}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                />
              </Form.Item>
            </Col>
          )} */}
          <Col>
            <Form.Item style={{ margin: 0 }}>
              <DynamicButton type='primary' htmlType='submit'>
                {i18next.t('search')}
              </DynamicButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <br />

      <DynamicTable
        loading={isLoading}
        dataSource={dataTable}
        initialTableData={tableData}
        onReload={undefined}
        pagination={paginationTable({
          pageSize: parameters.pageSize,
          pageNumber: parameters.pageNumber,
          totalRows: totalRows,
          onChange: (page, pageSize) => {
            setParameters({ pageNumber: page, pageSize: pageSize });
          },
        })}
      />
      {!isLoading && (!data || data?.length === 0) ? <NoData /> : <></>}
    </React.Fragment>
  );
};

Students.propTypes = {
  classId: PropTypes.string,
};

Students.defaultProps = {
  classId: '',
};

export default Students;
