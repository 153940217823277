import MATCHING from './Matching';
import ONECHOICE from './OneChoice';
import UNSCRAMBLE from './Unscramble';
import FILLINTHEBLANK from './FillInTheBlank';
import MATCHINGTWOCOLUMN from './MatchingTwoColumn';
import MULTIPLECHOOSE from './MultipleChoice';
import LISTENANDRECORD from '../../ExercicesTypes/RecordExcercises/ListenAndRecord';
import LISTENANDSPEAK from '../../ExercicesTypes/RecordExcercises/ListenAndSpeak';
import ESSAY from '../../ExercicesTypes_MENARD/Essay';

export default {
  MATCHING,
  ONECHOICE,
  MATCHINGTWOCOLUMN,
  FILLINTHEBLANK,
  MULTIPLECHOOSE,
  UNSCRAMBLE,
  MULTIPLECHOOSE,
  LISTENANDRECORD,
  LISTENANDSPEAK,
  ESSAY,
};
