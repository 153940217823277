/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip, Upload, message, notification } from 'antd';
import ImgCrop from 'antd-img-crop';
import { sachsoServerUrl } from 'constants/serverUrls';
import settings from 'constants/settings';
import i18next from 'i18next';
import React from 'react';
import { FaUser } from 'react-icons/fa';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader, CardImg } from 'reactstrap';
import { updateUserInfors } from '../api';

const imageProfile = require('assets/img/theme/img-1-1000x600.jpg').default;

function Profile({ loggedInUser, data }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [fileList, setFileList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const uploadSettings = {
    name: 'files',
    action: `${sachsoServerUrl}/api/v1.0/dynamic/upload-files`,
    headers: {
      Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
      ApplicationName: settings.APPLICATION_NAME,
    },
    fileList,
    data: { entityId: loggedInUser.rowId },
    showUploadList: false,
    onChange(info) {
      setFileList(info.fileList);
      if (info.file.status === 'error') {
        notification.error({ message: `Tập tin ${info.file.name} tải lên bị lỗi.` });
      } else if (info.file.status === 'done') {
        const parameters = {
          id: data?.[0]?.id,
          maSV: data?.[0]?.maSV,
          email: data?.[0]?.email,
          name: data?.[0]?.fullName,
          phone: data?.[0]?.phone,
          roleId: data?.[0]?.roleId,
          sex: data?.[0]?.sex,
          status: 'ACTIVE',
          avatar: info.file.response.files?.[0]?.downloadUrl,
          birthDay: data?.[0]?.dateofBirth,
          schoolTypeId: data?.[0]?.schoolTypeId,
          khoa: data?.[0]?.khoa,
          nganh: data?.[0]?.nganh,
          chucVu: data?.[0].chucVu,
          shop: data?.[0].shop,
        };
        console.log('🚀 ~ parameters:', parameters);
        updateUserInfors(parameters)
          .then((res) => {
            if (res?.[0]?.res === 1) {
              message.success(i18next.t('update success', { field: i18next.t('avatar') }));
              setLoading(false);
              queryClient.invalidateQueries('Users');
            } else {
              message.success(i18next.t('update failed', { field: i18next.t('avatar') }));
              setLoading(false);
            }
          })
          .catch((err) => {
            //! Error: Connected Fail
            message.error(i18next.t('connectError'));
          });
      }
      // Check completed all.
      // const isCompleted = _.every(info.fileList, { status: 'done' });
      // if (isCompleted) {
      //   _.forEach(info.fileList, (file) => {
      //     if (file.response) {
      //       if (file.response.rowsAffected > 0) {

      //         notification.success({ message: 'Cập nhật thành công.' });
      //         dispatch(login(loggedInUser.email, loggedInUser.password));
      //         setLoading(false);
      //         setFileList([]);
      //       }
      //     }
      //   });
      // }
    },
  };

  return (
    <>
      <Card className='card-profile'>
        <CardImg alt='...' src={imageProfile} top />
        <Row className='justify-content-center'>
          <Col>
            <div className='card-profile-image'>
              {data?.[0]?.avatar ? (
                <img
                  alt='...'
                  className='rounded-circle'
                  src={data?.[0]?.avatar}
                  style={{ backgroundColor: 'white' }}
                />
              ) : (
                <div
                  className='rounded-circle'
                  style={{
                    height: 140,
                    width: 140,
                    backgroundColor: 'white',
                    transform: 'translate(-50%, -50%) scale(1)',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FaUser className='text-primary rounded-circle' size={100} />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <CardHeader className='text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4'>
          <div className='d-flex justify-content-between'>
            {/* <Button className='mr-4' color='info' href='#pablo' onClick={(e) => e.preventDefault()} size='sm'>
              Connect
            </Button>
            <Button className='float-right' color='default' href='#pablo' onClick={(e) => e.preventDefault()} size='sm'>
              Message
            </Button> */}
          </div>
        </CardHeader>
        <CardBody className='pt-0'>
          <div className='tw-flex tw-flex-1 tw-justify-center tw-mt-6'>
            <ImgCrop
              aspect={1 / 1}
              quality={1}
              modalTitle={<h3 className={'project-color'}>{i18next.t('Edit avatar').toUpperCase()}</h3>}
              modalOk={i18next.t('Confirm')}
              modalCancel={i18next.t('Close')}
              grid
            >
              <Upload
                {...uploadSettings}
                beforeUpload={() => {
                  setLoading(true);
                  return true;
                }}
              >
                <Tooltip title={i18next.t('updateAvatar')}>
                  <Button type='text' htmlType='button' icon={<EditOutlined />} loading={loading} />
                </Tooltip>
              </Upload>
            </ImgCrop>
          </div>
          {/* <Row>
            <div className='col'>
              <div className='card-profile-stats d-flex justify-content-center'>
                <div>
                  <span className='heading'>22</span>
                  <span className='description'>Friends</span>
                </div>
                <div>
                  <span className='heading'>10</span>
                  <span className='description'>Photos</span>
                </div>
                <div>
                  <span className='heading'>89</span>
                  <span className='description'>Comments</span>
                </div>
              </div>
            </div>
          </Row> */}
          <div className='text-center'>
            <h5 className='h3'>{data?.[0]?.fullName}</h5>
            <div className='h5 font-weight-300'>
              <i className='ni location_pin mr-2' />
              {data?.[0]?.email}
            </div>
            <div className='h5 mt-4'>
              <i className='ni business_briefcase-24 mr-2' />
              {loggedInUser?.role?.toUpperCase()}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default Profile;
