import { Button, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import settings from 'constants/settings';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTooltip from 'packages/DynamicTooltip/DynamicTooltip';
import React from 'react';

type MoveButtonProps = {
  hasTooltip?: boolean;
  title?: string;
  placement?: TooltipPlacement;
  icon: JSX.Element;
  size?: SizeType;
  style?: React.CSSProperties;
  onClick: () => void;
};

const MoveButton = ({ hasTooltip, title, placement, icon, size, style, onClick }: MoveButtonProps) => {
  return (
    <React.Fragment>
      {hasTooltip ? (
        <DynamicTooltip title={title} placement={placement}>
          <DynamicButton
            key='move-file'
            size={size}
            shape='circle'
            type='dashed'
            icon={icon}
            style={style}
            onClick={onClick}
          />
        </DynamicTooltip>
      ) : (
        <DynamicButton
          key='move-file'
          size={size}
          shape='circle'
          type='dashed'
          icon={icon}
          style={style}
          onClick={onClick}
        />
      )}
    </React.Fragment>
  );
};

export default MoveButton;
