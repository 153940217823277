import { Spin } from 'antd';
import NoData from 'components/NoData';
import { query } from 'helpers/QueryHelper';
import _ from 'lodash';
import IndexPresentationAmesOnline from 'pages/AmesOnline';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

const fetch = (parameter) => {
  return query('p_QUIZCITY_PTTest_GetHistory_v2', parameter);
};
export default function Preview() {
  const params = useParams();
  const { codeExam } = params;
  const parameter = { PTCode: codeExam };
  const KEY = ['p_QUIZCITY_PTTest_GetHistory_v2', parameter];
  const { data, isLoading } = useQuery(KEY, () => fetch(parameter));
  return (
    <>
      <div div style={{ display: 'flex', justifyContent: 'center', alignSelf: 'center' }}>
        <Spin spinning={isLoading} size='large' />
      </div>
      {data?.[0] ? (
        <IndexPresentationAmesOnline codeExam={codeExam} type='VIEW' codeId={data?.[0]?.id} params={data?.[0]} />
      ) : (
        <NoData />
      )}
    </>
  );
}
