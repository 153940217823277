import { Button, Modal, Result } from 'antd';

type Props = {
  isVisible: boolean;
};

const ModalError = ({ isVisible }: Props) => {
  return (
    <Modal open={isVisible} closable={false} footer={null} centered={true}>
      <Result
        status='error'
        title='Mã dự thi không hợp lệ hoặc đã được sử dụng'
        extra={[
          <Button
            type='primary'
            onClick={() => {
              window.location.replace('https://ames.edu.vn/');
            }}
            key='console'
          >
            Về trang chủ
          </Button>,
        ]}
      />
    </Modal>
  );
};

export default ModalError;
