export const SettingQuestionByExcel = (type, optionType, answer, answerIndex) => {
  switch (type) {
    case 'ONECHOICE':
    case 'MULTIPLECHOOSE':
        return {
          result: {
            answer: answer.answer?.replaceAll('"', "'"),
            isCorrect: answer?.isCorrect === 1,
            id: answerIndex + 1,
          },
        };
    case 'MATCHING':
      if (optionType === 'MATCHPICTURESWORD') {
        return {
          result: {
            imageUrl: answer?.answer,
            answer: answer?.answer2?.replaceAll('"', "'"),
            isCorrect: true,
            id: answerIndex + 1,
          },
        };
      } else if (optionType === 'READMATCHSENTENCE') {
        return {
          result: {
            textLeft: answer.answer?.replaceAll('"', "'"),
            textRight: answer?.answer2?.toString()?.replaceAll('"', "'"),
            id: answerIndex + 1,
          },
        };
      }

    default:
      return {};
  }
};
