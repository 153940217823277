/* eslint-disable sort-keys */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-wrap-multilines */
import PropTypes from 'prop-types';
import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

function Listen({ type, data, setAudio, isAutoPlayAudio }) {
  const [AudioUrl, setAudioUrl] = React.useState({});
  const [isPlay, setIsPlay] = React.useState(false);
  const [arrayListened, setArrayListened] = React.useState([]);

  React.useEffect(() => {
    Object.assign(type, {
      submit: () => {
        // handle submit
        const res = handleResult(arrayListened);
        return { listen: res };
      },
    });
  }, [arrayListened, type]);

  React.useEffect(() => {
    setArrayListened((pre) => {
      if (pre.length === 0) {
        return data.map((item) => false);
      }
      return pre;
    });
    return () => {
      //Fix cached lws có listen sang lws ko có
      // if (setAudio && typeof setAudio === 'function') setAudio(null);
    };
  }, [data]);

  React.useEffect(() => {
    if (isAutoPlayAudio) {
      handleClick(data?.[0]?.text, 0);
    }
  }, [isAutoPlayAudio, data?.[0]?.text]);

  const handleClick = (text, index) => {
    setAudioUrl({ text, index });

    setArrayListened((pre) => {
      const newArrayListened = pre.map((item, i) => (i === index ? true : item));
      return newArrayListened;
    });

    if (setAudio && typeof setAudio === 'function') {
      setAudio(
        <div style={{ zIndex: 1 }}>
          <ReactAudioPlayer
            src={text}
            autoPlay
            controls
            controlsList='nodownload'
            style={{ height: 40, marginInline: 'auto' }}
            onPlay={() => setIsPlay(true)}
            onPause={() => setIsPlay(false)}
            onEnded={() => {
              setIsPlay(false);
              setAudioUrl({});
            }}
          />
        </div>,
      );
    }
  };

  if (!data) return null;
  return (
    <React.Fragment>
      {data.map((value, index) => {
        const { top, left, height, width, text } = value;
        const color = AudioUrl.index === index && isPlay ? 'green' : '';
        return (
          <div
            key={`listen-${index}`}
            style={{
              position: 'absolute',
              top,
              left,
              height,
              width,
              fontSize: Math.min(width, height),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => handleClick(text, index)}
          >
            <i className='fas fa-volume-up' style={{ color }} />
          </div>
        );
      })}
    </React.Fragment>
  );
}

Listen.propTypes = {
  type: PropTypes.object,
  data: PropTypes.array,
};
Listen.defaultProps = {
  type: {},
  data: [],
};

export default Listen;

const handleResult = (array) => {
  const total = array.length;
  const count = array.filter((x) => x === true).length;
  const complete = `${count}/${total}`;

  const percent = parseInt((count * 100) / total);
  const star = percent / 20;

  return { percent: percent ?? 0, resultString: complete, star: star ?? 0, complete };
};
