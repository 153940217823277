import { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import FolderItem from './FolderItem';
import UpdateFolder from '../FolderTreeView/components/UpdateFolder';
import { buildSelectTreeData } from 'utils';
import UploadFiles from 'components/UploadFiles';
import { get } from 'helpers/QueryHelper';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const FolderView = ({
  classId,
  setSelectedFolderId,
  data,
  setReloadFolders,
  allFolders,
  selectedFolderId,
  selectedFolder,
  setSelectedFolder,
  KEY_SHARE_ATTACHMENTS,
}) => {
  const { t } = useTranslation('');
  const [updateMode, setUpdateMode] = useState('');
  const [updateModalVisible, setUpdateModalVisible] = useState(false);

  const queryClient = useQueryClient();

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  return (
    <>
      {loggedInUser.role !== 'student' && (
        <>
          <UpdateFolder
            updateMode={updateMode}
            treeData={buildSelectTreeData(allFolders)}
            data={{ classId, ...selectedFolder }}
            visible={updateModalVisible}
            onOk={() => {
              setReloadFolders(new Date());
              setUpdateModalVisible(false);
            }}
            onCancel={() => {
              setUpdateModalVisible(false);
            }}
          />

          <div className='tw-flex tw-justify-end tw-gap-1'>
            {loggedInUser.role !== 'student' && (
              <div className='tw-flex tw-justify-end'>
                <UploadFiles
                  entityId={selectedFolderId}
                  entityName='Folders'
                  subModuleName='LIBRARIES'
                  moduleName='SACHSO'
                  tags='FILE'
                  onSuccess={() => {
                    queryClient.invalidateQueries(KEY_SHARE_ATTACHMENTS);
                  }}
                  buttonStyle={{ background: '#006EE6', color: '#fff', marginBottom: 24 }}
                  buttonText={i18next.t('Upload')}
                />
              </div>
            )}
            <div className='tw-flex tw-justify-end tw-mb-6'>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => {
                  setUpdateMode('add');
                  setUpdateModalVisible(true);
                }}
              >
                {i18next.t('Add folder')}
              </Button>
            </div>
          </div>
        </>
      )}

      {data && data.length > 0 && (
        <Row gutter={[24, 24]}>
          {data.map((item) => {
            return (
              <Col key={`book-${item.id}`} xxl={6} lg={8} md={8} sm={12} xs={24}>
                <FolderItem
                  item={item}
                  displayFooter={false}
                  displayDescription={false}
                  setUpdateMode={setUpdateMode}
                  setUpdateModalVisible={setUpdateModalVisible}
                  setSelectedFolder={setSelectedFolder}
                  setReloadFolders={setReloadFolders}
                  setSelectedFolderId={setSelectedFolderId}
                  loggedInUser={loggedInUser}
                />
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};

export default FolderView;
