import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Row, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import LayoutHeader from './LayoutHeader';

type Props = {
  type: string;
  optionType: string;
  form: any;
};

const ViewHeader = ({ type, optionType, form }: Props) => {
  const RenderLayoutHeader = () => {
    switch (optionType.toUpperCase()) {
      case 'LOOK_CHOICE':
        return (
          <>
                <LayoutHeader required={true} form={form} layoutHeader='image' />
                <LayoutHeader required={true} form={form} layoutHeader='textArea' />
          </>
        );
        case 'READANSWER':
          return (
            <>
              <LayoutHeader required={true} form={form} layoutHeader='textArea' />
            </>
          );
      case 'LISTENSOUND':
        return <LayoutHeader required={true} form={form} layoutHeader='audio' />;
      case 'LISTENPICTURE':
        return <LayoutHeader required={true} form={form} layoutHeader='audio' />;
      case 'LISTENDIFFERENBEGINSOUND':
        return (
          <>
            <LayoutHeader required={true} form={form} layoutHeader='audio' />

            <LayoutHeader required={true} form={form} layoutHeader='textArea' />
          </>
        );
      case 'LISTENPICTUREDESCRIBED':
        return <LayoutHeader required={true} form={form} layoutHeader='audio' />;
      case 'LISTENWRITE':
        return <LayoutHeader required={true} form={form} layoutHeader='audio' />;
      case 'LISTENLOOKANDCOMPLETE':
        return (
          <>
            <Row gutter={[12, 12]}>
              <Col lg={24}>
                <LayoutHeader required={true} form={form} layoutHeader='audio' />
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col lg={24}>
                <LayoutHeader required={true} form={form} layoutHeader='image' />
              </Col>
            </Row>
          </>
        );
      case 'LISTENFIND':
        return (
          <Row gutter={[12, 12]}>
            <Col lg={12}>
              <LayoutHeader required={true} form={form} layoutHeader='audio' />
            </Col>
          </Row>
        );
      case 'UNCRAMBLEWORDS':
        return (
          <Row gutter={[12, 12]}>
            <Col lg={12}>
              <LayoutHeader required={true} form={form} layoutHeader='image' />
            </Col>
          </Row>
        );
      case 'READMATCHSENTENCE':
        return (
          <Row gutter={[12, 12]}>
            <Col lg={24}>
              <LayoutHeader required={true} form={form} layoutHeader='textArea' />
            </Col>
          </Row>
        );
      case 'MATCHPICTUREWORD':
        return (
          <Row gutter={[12, 12]}>
            <Col lg={24}>
              <LayoutHeader required={true} form={form} layoutHeader='textArea' />
            </Col>
          </Row>
        );
      case 'LOOKWRITE':
        return (
          <Row gutter={[12, 12]}>
            <Col lg={24}>
              <LayoutHeader required={true} form={form} layoutHeader='image' />
            </Col>
          </Row>
        );
      case 'SAYTHEWORDSTEXT':
        return (
          <>
            <Row gutter={[12, 12]}>
              <Col lg={24}>
                <LayoutHeader required={true} form={form} layoutHeader='audioByAI' />
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col lg={12}>
                <LayoutHeader required={true} form={form} layoutHeader='input' />
              </Col>
              <Col lg={12}>
                <LayoutHeader required={true} form={form} layoutHeader='phonetic' />
              </Col>
            </Row>
          </>
        );
      case 'LISTENANDREPEAT':
        return (
          <>
            <LayoutHeader required={true} form={form} layoutHeader='audioByAI' />
            <Row gutter={[12, 12]}>
              <Col lg={24}>
                <LayoutHeader required={true} form={form} layoutHeader='phonetic' />
              </Col>
            </Row>
          </>
        );
      case 'UNSCRAMBLEWORDS':
        return (
          <>
            <LayoutHeader required={true} form={form} layoutHeader='image' />
          </>
        );
      case 'MATCHPICTURESWORD':
        return (
          <>
            <LayoutHeader required={true} form={form} layoutHeader='textArea' />
          </>
        );
      case 'LISTENANDSPEAK':
        return (
          <>
            <LayoutHeader required={true} form={form} layoutHeader='audio' />
            <LayoutHeader required={true} form={form} layoutHeader='textArea' />
            <LayoutHeader required={true} form={form} layoutHeader='questionTextVN' />
            <Row gutter={[12, 12]}>
              <Col lg={12}>
                <LayoutHeader required={true} form={form} layoutHeader='answerText' />
              </Col>
              <Col lg={12}>
                <LayoutHeader required={true} form={form} layoutHeader='answerTextVN' />
              </Col>
            </Row>
          </>
        );
      case 'LOOKSENTENCEANDIMAGE':
        return (
          <>
            <LayoutHeader required={true} form={form} layoutHeader='image' />
            <LayoutHeader required={true} form={form} layoutHeader='textArea' />
            <LayoutHeader required={true} form={form} layoutHeader='questionTextVN' />
          </>
        );
      case 'COMBINE_AUDIO':
        return (
          <>
            <LayoutHeader required={false} form={form} layoutHeader='textArea' />
            <LayoutHeader required={false} form={form} layoutHeader='audio' />
            <LayoutHeader required={false} form={form} layoutHeader='image' />
          </>
        );
      case 'COMBINE_VIDEO':
        return (
          <>
            <LayoutHeader required={false} form={form} layoutHeader='textArea' />
            <LayoutHeader required={false} form={form} layoutHeader='video' />
            <LayoutHeader required={false} form={form} layoutHeader='image' />
          </>
        );

      case 'LISTENANDFILLCOLOR':
        return (
          <>
            <LayoutHeader required={true} form={form} layoutHeader='audio' />
            <LayoutHeader required={true} form={form} layoutHeader='imagePath' />
          </>
        );
      case 'ESSAY':
        return (
          <>
            <LayoutHeader required={false} form={form} layoutHeader='editor' />
            <LayoutHeader required={false} form={form} layoutHeader='audio' />
            <LayoutHeader required={false} form={form} layoutHeader='image' />
            <LayoutHeader required={false} form={form} layoutHeader='video' />
          </>
        );
      case 'SPEAKING':
        return (
          <>
            <LayoutHeader required={false} form={form} layoutHeader='editor' />
            <LayoutHeader required={false} form={form} layoutHeader='audio' />
            <LayoutHeader required={false} form={form} layoutHeader='image' />
            <LayoutHeader required={false} form={form} layoutHeader='video' />
          </>
        );
      case 'WRITING':
        return (
          <>
            <LayoutHeader required={false} form={form} layoutHeader='editor' />
            <LayoutHeader required={false} form={form} layoutHeader='audio' />
            <LayoutHeader required={false} form={form} layoutHeader='image' />
            <LayoutHeader required={false} form={form} layoutHeader='video' />
          </>
        );
      case 'LISTENANDMATCH':
        return (
          <>
            <LayoutHeader required={true} form={form} layoutHeader='audio' />
            <LayoutHeader required={true} form={form} layoutHeader='imagePath' />
          </>
        );

      default:
        return <></>;
    }
  };
  return <RenderLayoutHeader />;
};

export default ViewHeader;
