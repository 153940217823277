import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import style from './style.module.css';

const convention = {
  yes: true,
  no: false,
};

// #region Tính điểm.
function handleResult(state) {

  // Điểm tính theo cả bài, không quan tâm groups
  // #region Điểm = số câu chọn đúng / số câu đúng trên cả trang
  const newState = state.map((item) => {
    const { text } = item;
    return { ...item, isCorrect: convention[text] };
  });

  //Các đáp án đúng của bài
  const corrects = newState.filter((item) => item.isCorrect);
  //Lựa chọn được tính điểm đúng
  const correctSelected = corrects.filter((item) => item.isSelected);

  const correct = correctSelected.length;
  const total = corrects.length;

  const percent = parseInt((correct * 100) / total);
  const resultString = `${correct}/${total}`;
  const star = percent / 20;

  return { userData: newState, percent, resultString, star };
}

function MultipleChoice2({ type, data, isDisableTest, againAnswer, isHiddenShowColor }) {
  const [state, setState] = useState([]);
  const [result, setResult] = useState();
  const [isDone, setIsDone] = useState(false);

  //#region handleClick
  const handleClick = (index) => {
    if (isDisableTest) return;
    const { groupName, text, isSelected } = state[index];

    let newState = [...state];

    if (isSelected) {
      //trường hợp click lần 2 => bỏ chọn
      newState[index].isSelected = false;
    } else {
      //trường hợp click lần 1 => chọn
      const currentGroup = newState.filter((item) => item.groupName === groupName);
      const correctInGroup = currentGroup.filter((item) => item.text === 'yes').length; // số câu hỏi đúng của group
      const selectedInGroup = currentGroup.filter((item) => !!item.isSelected);
      if (selectedInGroup.length < correctInGroup) {
        //Nếu số đáp án đã chọn < số đáp đúng
        newState[index].isSelected = true;
      } else {
        //Nếu số đáp án đã chọn >= số đáp đúng
        //Chỉ tích đáp án vừa bấp và bỏ tích các đáp án còn lại
        newState = state.map((e, i) => {
          if (e.groupName === groupName) {
            if (i === index) {
              return { ...e, isSelected: true };
            }
            return { ...e, isSelected: false };
          }
          return e;
        });
      }
    }

    // Lưu lại trang thái chọn
    setState(newState);
  };

  //#region REF
  useEffect(() => {
    Object.assign(type, {
      submit: () => {
        if (data?.length === 0) return {};
        const res = handleResult(state);
        return { multipleChoice2Result: res };
      },
      tryAgain: () => {
        setState(data);
        // setResult();
      },
      setResult,
      setIsDone,
    });
    return () => {};
  }, [data?.length, state]);

  //#region Gán giá trị cho state render
  useEffect(() => {
    setState(againAnswer?.userData ?? data);
  }, [data?.length, againAnswer]);

  //#region return Nodata
  if (!data) return null;

  //#region render
  return state.map((item, index) => {
    const { width, height, top, left, angle, isSelected, isCorrect, text } = item;
    let viewColor = '';

    //COLOR
    if (isDisableTest && !isHiddenShowColor && isSelected) {
      if (convention[text]) {
        viewColor = style.MultipleTrue;
      } else {
        viewColor = style.MultipleFalse;
      }
    }

    return (
      <div
        key={`button-${index}`}
        onClick={() => {
          if (isDisableTest) return null;
          handleClick(index);
        }}
        className={`${style.boxMultiplechoice}
        ${isSelected ? style.boxMultiplechoiceClick : style.boxMultiplechoiceHover} 
        ${viewColor}
        `}
        style={{
          position: 'absolute',
          width,
          height,
          top,
          left,
          transform: `rotate(${angle}deg)`,
          cursor: isDisableTest ? 'no-drop' : 'pointer',
          // border: `2px dotted ${color}`,
        }}
      />
    );
  });
}

MultipleChoice2.propTypes = {
  data: PropTypes.array.isRequired,
};

export default MultipleChoice2;
