export const localStorageKey = {
  logginInUserSGK: 'logginInUserSGK',
};

export const colors = {
  purple: '#6474FC',
  dimGray: '#666666',
  greyChateau: '#A5A8AB',
  dodgerBlue: '#057AFF',
  lightAzure: '#5AA9FF',
  darkChambray: '#365992',
  darkTangerine: '#FFAB1A',
  black: 'black',
  green: '#2ece76',
  warning: '#C63200',
  font: '#32325d',
};

export const administrators = [
  'admin@gmail.com',
  'nmhaof@gmail.com',
  // 'nguyen.hao@ames.edu.vn',
  'tungnt@softech.vn',
  'luannt@aesgroup.edu.vn',
  'testitdn@gmail.com',
  'duongchinh.sunny@gmail.com',
];

const students = [];

for (let i = 1; i < 6; i++) {
  students.push(`hocsinhlop${i}@gmail.com`);
}

export const permission = students;
