import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';

function RecordElement({ canvas, obj, dependency }) {
  if (obj.group) return null; // when selecting multiple objects

  const { data, top, left, width, height, scaleX, scaleY } = JSON.parse(dependency);
  //
  const newWidth = width * scaleX;
  const newHeight = height * scaleY;
  //
  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: top + newHeight / 2,
        left: left + newWidth / 2,
        // width: newWidth,
      }}
    >
      <Tooltip
        title={data.text}
        getPopupContainer={(trigger) => {
          return trigger.parentElement;
        }}
      >
        <Button
          shape='round'
          icon={
            <>
              <i className='fa fa-user' style={{ color: 'gray', marginRight: 5 }} />
              <i className='fa fa-upload' style={{ color: 'gray', marginLeft: 5 }} />
            </>
          }
        />
      </Tooltip>
    </div>
  );
}

RecordElement.propTypes = {
  canvas: PropTypes.object,
  obj: PropTypes.object,
  dependency: PropTypes.string, // update reload dependency
};

export default memo(RecordElement);
