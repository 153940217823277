import React from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Card, CardBody, CardHeader } from 'reactstrap';
import QuestionHeader from '../QuestionHeader';
import { QuestionComponentProps, QuestionComponentRef } from '../type';

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;
const getItemStyle = (isDragging: boolean, draggableStyle: any, height: any, isDisable: boolean) => {
  return {
    height,
    minWidth: 300,
    maxWidth: 450,
    color: 'black',
    userSelect: 'none',
    padding: 10,
    borderRadius: '0.25rem',
    margin: `0 0 ${grid}px 10px`,
    border: isDisable ? '2px solid #adb5bd' : '1px dashed #adb5bd',
    background: isDragging ? '#b2b7c1' : '#f4f5f7',
    cursor: isDisable ? 'default' : 'move',

    ...draggableStyle,
  };
};

const shuffle = (array: any[], data: any): any => {
  let currentIndex = array.length;
  let randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  // không cho item 1 đứng đầu tiên
  if (data.answers[0].textRight === array[0].textRight) {
    return shuffle(array, data);
  }

  return array;
};

function shuffleLeft(array: any, data: any) {
  let currentIndex = array.length;
  let randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  if (data.answers[0].textLeft === array[0].textLeft) {
    return shuffle(array, data);
  }

  return array;
}

const MatchingTwoColumn = (
  {
    data,
    userData,
    onSubmit,
    questionIndex = 0,
    preview = false,
    showUserData = false,
    onSubmitWhenCancel,
  }: QuestionComponentProps,
  ref: QuestionComponentRef,
) => {
  const [state, setState] = React.useState<any>({
    itemsRight: null,
    itemsLeft: null,
    boolArrayLeft: [],
    boolArrayRight: [],
  });

  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
    onSubmitWhenCancel() {
      handleSubmitOnCanceling();
    },
  }));

  const [maxHeight, setMaxHeight] = React.useState(70);
  const handleAnswersNResult = () => {
    let result = true;

    for (let i = 0; i < data.answers.length; i++) {
      const isCorrect = state.itemsLeft[i].match === state.itemsRight[i].match;
      if (!isCorrect) {
        result = false;
        break;
      }
    }
    const answers = { left: state.itemsLeft, right: state.itemsRight };
    return {
      answers,
      result,
    };
  };
  const handleSubmitOnCanceling = () => {
    const { answers, result } = handleAnswersNResult();

    onSubmitWhenCancel?.({ id: data.id, answers, result }, data);
  };
  const handleSubmit = () => {
    const { answers, result } = handleAnswersNResult();

    // Callback
    if (onSubmit && typeof onSubmit === 'function') onSubmit({ answers, result }, data);
  };

  const checkanswersTotal = React.useCallback(() => {
    let result = true;

    for (let i = 0; i < data.answers.length; i++) {
      const isCorrect = state.itemsLeft[i].match === state.itemsRight[i].match;
      if (!isCorrect) {
        result = false;
        break;
      }
    }
    const answers = { left: state.itemsLeft, right: state.itemsRight };
    onSubmit?.({ id: data.id, answers, result }, data);
  }, [data, onSubmit, state.itemsLeft, state.itemsRight]);

  const onDragEnd = React.useCallback(
    (result) => {
      // Cột B
      if (!result.destination) {
        return;
      }
      const itemsRight: any = reorder(state.itemsRight, result.source.index, result.destination.index);

      // Gộp chuỗi
      const addArray = [];
      const addArrayAnswers = [];
      for (let index = 0; index < itemsRight.length; index++) {
        addArray.push(itemsRight[index].content);
        addArrayAnswers.push(data.answers[index].textRight);
      }
      // bài làm
      const convertString = addArray.toString();
      const replace = convertString.replaceAll(',', '');
      // đáp án
      const convertStringAnswers = addArrayAnswers.toString();

      const replaceAnswers = convertStringAnswers.replaceAll(',', '');

      const answersTotal = replace === replaceAnswers;

      state.boolArrayRight.push(answersTotal);

      setState((prevState: any) => ({ ...prevState, itemsRight }));
    },
    [data.answers, state.boolArrayRight, state.itemsRight],
  );

  const onDragEndLeft = React.useCallback(
    (result) => {
      // Cột A
      if (!result.destination) {
        return;
      }
      const itemsLeft: any = reorder(state.itemsLeft, result.source.index, result.destination.index);
      // Gộp chuỗi
      const addArray = [];
      const addArrayAnswers = [];
      for (let index = 0; index < itemsLeft.length; index++) {
        addArray.push(itemsLeft[index].contentLeft);
        addArrayAnswers.push(data.answers[index].textLeft);
      }
      // bài làm
      const convertString = addArray.toString();
      const replace = convertString.replaceAll(',', '');
      // đáp án
      const convertStringAnswers = addArrayAnswers.toString();
      const replaceAnswers = convertStringAnswers.replaceAll(',', '');
      // so sanh
      const answersTotal = replace === replaceAnswers;

      state.boolArrayLeft.push(answersTotal);
      setState((prevState: any) => ({ ...prevState, itemsLeft }));
    },
    [data.answers, state.boolArrayLeft, state.itemsLeft],
  );
  // -------------------- Để trước hàm useEffect --------------------
  let showTitleColumn = data.settings && data.settings.showTitleColumn;
  if (showTitleColumn === null || showTitleColumn === undefined) {
    showTitleColumn = true;
  }

  // Cho phép cột A kéo thả hoặc không
  let isDragAColumnDisabled = data.settings && data.settings.isDragAColumnDisabled;
  if (isDragAColumnDisabled === null || isDragAColumnDisabled === undefined) {
    isDragAColumnDisabled = false;
  }

  // Cho phép cột B kéo thả hoặc không
  let isDragBColumnDisabled = data.settings && data.settings.isDragBColumnDisabled;
  if (isDragBColumnDisabled === null || isDragBColumnDisabled === undefined) {
    isDragBColumnDisabled = false;
  }
  // ----------------------------------------------------------------
  React.useEffect(() => {
    const arrayData = data.answers.map((item: any, index: number) => {
      return { textLeft: item.textLeft, textRight: item.textRight, id: index };
    });

    const itemsRight = shuffle(arrayData, data).map((item: any, index: number) => {
      return {
        id: `item-${index}`,
        content: item.textRight,
        match: item.id,
      };
    });

    const itemsLeft =
      isDragAColumnDisabled || data.answers.length <= 2 // Nếu kéo thả bị vô hiệu hóa (true) hoặc chỉ có 2 lựa chọn thì sắp xếp theo thứ tự, ngược lại xáo trộn
        ? data.answers.map((item: any, index: number) => {
            return {
              id: `item-${index}`,
              contentLeft: item.textLeft,
              match: index,
            };
          })
        : shuffleLeft(arrayData, data).map((item: any, index: number) => {
            return {
              id: `item-${index}`,
              contentLeft: item.textLeft,
              match: item.id,
            };
          });

    if (userData && userData.length !== 0 && userData?.left !== null && userData?.right !== null && showUserData) {
      const { answers } = userData;
      setState((prevState: any) => ({
        ...prevState,
        itemsRight: answers.right,
        itemsLeft: answers.left,
      }));
      return;
    }

    if (preview) {
      setState((prevState: any) => ({
        ...prevState,
        itemsRight: data.answers.textRight,
        itemsLeft: data.answers.textLeft,
      }));
    }

    setState((prevState: any) => ({
      ...prevState,
      itemsRight,
      itemsLeft,
    }));
    // }, [data, data.answers, isDragAColumnDisabled, userData, preview]);
  }, [isDragAColumnDisabled]);

  React.useEffect(() => {
    const { answers } = data;
    const length = answers?.map((item: any) => {
      const left = item.textLeft.length;
      const right = item.textRight.length;
      return left > right ? left : right;
    });
    const max = Math.max(...length);
    const height = max > 68 ? 89 : 70;
    setMaxHeight(height);
  }, [data]);

  if (!state.itemsRight) return null;

  return (
    <Card>
      <CardHeader>
        <QuestionHeader title={data.title} subTitle={data.subTitle} questionIndex={questionIndex} />
      </CardHeader>
      <CardBody
      // className='d-flex justify-content-start'
      >
        {data.questionText ? <div className='questionText'>{data.questionText}</div> : null}
        <div style={{ display: 'flex' }}>
          <DragDropContext onDragEnd={onDragEndLeft}>
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className='d-flex flex-column '>
                  {showTitleColumn && (
                    <div className='d-flex justify-content-start align-items-center '>
                      <span style={{ margin: '0 auto', fontSize: 24 }}>A</span>
                    </div>
                  )}

                  {state.itemsLeft.map((item: any, index: number) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={isDragAColumnDisabled && item.id === `item-${index}`}
                    >
                      {(provided, snapshot) => (
                        <div
                          className='center-mactching'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            maxHeight,
                            isDragAColumnDisabled,
                          )}
                        >
                          {/* {`${index + 1}. ${item.contentLeft}`} */}
                          {item.contentLeft}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className='d-flex flex-column '>
                  {showTitleColumn && (
                    <div className='d-flex justify-content-start align-items-center '>
                      <span style={{ margin: '0 auto', fontSize: 24 }}>B</span>
                    </div>
                  )}

                  {state.itemsRight.map((item: any, index: number) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={isDragBColumnDisabled && item.id === `item-${index}`}
                    >
                      {(provided, snapshot) => (
                        <div
                          className='drag-box center-mactching'
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            maxHeight,
                            isDragBColumnDisabled,
                          )}
                        >
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </CardBody>
      {/* <CardFooter>
        <Button
          color='primary'
          onClick={handleSubmit}
          disabled={state.destination}
        >
          Submit <i className='ml-3 fas fa-arrow-right' />
        </Button>
      </CardFooter> */}
    </Card>
  );
};

export default React.forwardRef(MatchingTwoColumn);
