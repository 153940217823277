/* eslint-disable react/jsx-key */
/* eslint-disable indent */
import notificationWithIcon from 'components/Notification/notificationWithIcon';
import { IS_HANDOUT, IS_PT } from 'constants/settings';
import { get } from 'helpers/QueryHelper';
import i18next from 'i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Result, { totalScore } from './Result';
import WorksheetTypes from './WorksheetTypes';
import { saveToServer } from './api';
import { saveAnswerAcademy } from './api/SaveAcademy';
import { SaveLogForTest } from './api/SaveLogForTest';
import { modeFilter } from './configuration';
import createRef from './exercises';
import { getDurationSlide_Seconds } from 'pages/Student/Presentation/modules/duration';
import { useQuery } from 'react-query';

const getWorksheetDataByID = (attachmentId) => {
  return new Promise(async (resolve) => {
    if (!attachmentId) resolve({});
    const res = await get('Attachments', '*', { id: attachmentId }, 'CreatedDate DESC', 'SHARE');
    return resolve(res?.[0] ?? {});
  });
};

function ExcercisePage(
  {
    isSubmitted,
    setAudio,
    idCurrent,
    studentId,
    file,
    isCompleted,
    isTeacher,
    refSubmit,
    isDisableFrom = false,
    resultExercise,
    userAnswer,
    isVisibleResult = true,
    styles,
    studentData,
    loadingAnswer,
    currentSlideId,
    typeView = '',
    exerciseType = 'LEARN',
    isHiddenShowColor = true, // Hiển thị màu cho kết quả
    handoutCode = '', // handout
    codeId = null | undefined, // handout, PT
    folderIdHandoutPt = null | undefined, // handout, PT
    scoreWeight, // PT
  },
  ref,
) {
  const refExercise = createRef({});

  const listen = useRef({});

  const { assignmentId, slideId } = useParams();

  const [storeCanvas, setStoreCanvas] = useState({});
  const [resultState, setResultState] = useState({});
  const [isDisableTest, setIsDisableTest] = useState(isDisableFrom);

  const selectedFolder = useSelector((state) => state.sidebarReducer.selectedFolder);

  //Trường hợp truyền jsonData từ bên ngoài thì không gọi API
  const isCallApi = !file?.jsonData;
  const { data: dataSlide, isLoading } = useQuery(
    ['GET_DATA_WORKSHEET', idCurrent, isCallApi],
    () => getWorksheetDataByID(idCurrent),
    {
      enabled: isCallApi,
    },
  );

  const handleRetry = useCallback(() => {
    refExercise.tryAgainAll();
    // setResultState({}); làm lại vẫn giữ kết quả cũ nên không set lại, nếu set lại thì conponent con sẽ vĩ xoá kết quả
    setIsDisableTest(false);
  }, [refExercise]);

  const handleSubmit = useCallback(async () => {
    setIsDisableTest(true);
    //
    const result = refExercise.submitAll();
    let resultListen;
    if (listen && typeof listen?.current?.submit === 'function') {
      resultListen = listen?.current?.submit();
      //
    }
    const total = totalScore(result);
    await localStorage.setItem(`ScoreDataUserAnswer-${slideId || currentSlideId}-${studentId}`, JSON.stringify(total));

    // Tài khoản giáo viên, khi submit, chỉ kiểm tra đúng sai, không gửi đáp án lên server.
    setResultState(result);
    if (isTeacher) {
      refExercise.setIsDoneAll(true);
      return null;
    }

    refExercise.setIsDoneAll(false);
    // Lưu đáp án vào local storage, để khi học sinh làm xong, muốn xem lại bài
    // thì không cần gọi api, lấy trong local storage luôn cho nhanh.

    localStorage.setItem(
      `userAnswersAboutAttachmentId-${slideId || currentSlideId}-${studentId}`,
      JSON.stringify({ attachmentId: idCurrent, jsonData: { result, resultListen, ...storeCanvas } }),
    );

    const res =
      exerciseType === 'TEST' ? (
        <>
          {IS_HANDOUT
            ? await saveAnswerAcademy(
                file,
                total || 0,
                { result, resultListen, ...storeCanvas },
                studentId,
                studentData,
                assignmentId,
                currentSlideId,
                folderIdHandoutPt,
                handoutCode,
                codeId,
              )
            : await SaveLogForTest(
                file,
                total || 0,
                { result, resultListen, ...storeCanvas },
                codeId,
                studentData,
                assignmentId,
                currentSlideId,
                folderIdHandoutPt,
                scoreWeight,
              )}
        </>
      ) : (
        await saveToServer(
          file,
          total,
          { result, resultListen, ...storeCanvas },
          studentId,
          studentData,
          assignmentId,
          slideId,
          selectedFolder?.id,
          getDurationSlide_Seconds(),
        )
      );
    if (res) {
      notificationWithIcon(
        'success',
        i18next.t('notification'),
        i18next.t('You have successfully submitted your work'),
      );
    }

    return res;
  }, [refExercise, isTeacher, idCurrent, storeCanvas, file, studentId, studentData, assignmentId]);
  //

  const renderExercises = useCallback(() => {
    const { ExerciseData, backgroundImage } = storeCanvas;
    const ex = modeFilter(ExerciseData);
    return (
      <>
        <img src={backgroundImage} alt='' />
        {Object.keys(WorksheetTypes).map((item, index) => {
          const WorksheetComponent = WorksheetTypes[item];
          if (!ex?.[item]) {
            return null
          }
          return (
            <WorksheetComponent
              type={refExercise?.[item] ?? {}}
              data={ex?.[item]}
              againAnswer={resultState?.[`${item}Result`]}
              isDisableTest={isDisableTest}
              setAudio={setAudio}
              isHiddenShowColor={isHiddenShowColor}
              isAutoPlayAudio={ex.listen?.length === 1 && IS_PT}
            />
          );
        })}
      </>
    );
  }, [refExercise, setAudio, storeCanvas, isDisableTest, resultState, isHiddenShowColor, idCurrent]);
  //

  useEffect(() => {
    if (setAudio && typeof setAudio === 'function') setAudio(null);
    setStoreCanvas({});
  }, []);

  useEffect(() => {
    if (typeView === 'view') {
      setResultState(userAnswer?.result);
      setIsDisableTest(true);
      setStoreCanvas({ ExerciseData: userAnswer?.ExerciseData, backgroundImage: userAnswer?.backgroundImage });
    } else {
      if (IS_HANDOUT || IS_PT || userAnswer) {
        setResultState(userAnswer?.result);
        setIsDisableTest(false);
        setStoreCanvas({ ExerciseData: userAnswer?.ExerciseData, backgroundImage: userAnswer?.backgroundImage });
      } else {
        if (resultExercise) {
          setResultState(resultExercise);
        }
        setIsDisableTest(isDisableFrom);
      }
    }
  }, [resultExercise, isDisableFrom, typeView, userAnswer]);

  useEffect(() => {
    if (file?.jsonData || dataSlide?.jsonData) {
      const jsonData = file?.jsonData || dataSlide?.jsonData;

      if (jsonData) {
        setStoreCanvas(jsonData);
        if (jsonData.result) {
          setResultState({
            multipleMatch: jsonData.result.multipleMatchResult,
            match: jsonData.result.matchResult,
            write: jsonData.result.writeResult,
            oneChoice: jsonData.result.oneChoiceResult,
            multipleChoice: jsonData.result.multipleChoiceResult,
            multipleChoice2: jsonData.result.multipleChoice2Result,
            selectWord: jsonData.result.selectWordResult,
            dragdrop: jsonData.result.dragDropResult,
            dropDown: jsonData.result.dropDownResult,
            speechRecognition: jsonData.result.speechRecognitionResult,
          });
        }
      }
    }
  }, [file, dataSlide]);

  useEffect(() => {
    Object.assign(refSubmit?.current ?? {}, {
      submit: () => {
        return handleSubmit();
      },
      tryAgain: () => {
        return handleRetry();
      },
      result: () => resultState,
      isCompleted,
    });
  }, [refSubmit, handleRetry, handleSubmit]);

  return (
    <>
      {!loadingAnswer && !isLoading && (
        <div key={idCurrent} style={{ height: '92%', ...styles }}>
          {/* Cờ hiện màu đáp án và kết quả đang bị truyền biến tùm lum, cần clear code lại */}
          {!isHiddenShowColor && isDisableTest && isVisibleResult && (
            <Result data={resultState} scoreWeight={scoreWeight || dataSlide?.score} />
          )}
          <div style={{ maxHeight: '100%', overflow: 'auto' }}>
            <div className='match-container' style={{ position: 'relative', width: '1140px' }}>
              {renderExercises()}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

ExcercisePage.propTypes = {
  isTeacher: PropTypes.bool,
  refSubmit: PropTypes.object,
  file: PropTypes.object,
  studentId: PropTypes.any,
  isCompleted: PropTypes.bool,

  isVisibleResult: PropTypes.bool,
  styles: PropTypes.object,
  studentData: PropTypes.object,
  idCurrent: PropTypes.string,
};

ExcercisePage.defaultProps = {
  isTeacher: false,
  refSubmit: {},
  file: {},
  studentId: null,
  isCompleted: false,
  isVisibleResult: true,
  styles: {},
  studentData: {},
  idCurrent: '',
};

export default React.forwardRef(ExcercisePage);
