import { Button, message, Modal, Popconfirm, Space, Spin, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import IndexFormImportQuestion from './FormImportQuestion';
import ViewImportExam from './ViewImportExam';

type Props = {
  record: any;
  onRefreshData: Function;
};

const InsertUpdateQuestion = ({ record, onRefreshData }: Props) => {
    
  const { t } = useTranslation('table');

  const [modalStatus, setModalStatus] = React.useState({
    title: '',
    open: false,
    type: '',
  });

  const [isOpenModalChooseQuestion, setIsOpenModalChooseQuestion] = React.useState<boolean>(false);
  const [arrayDataChooseQuestionCombine, setArrayDataChooseQuestionCombine] = React.useState<any[]>([]);

  useEffect(() => {
    //Mặc định ban đầu record = null , {} thêm mới , {id} update
    if (record) {
      if (record?.id) {
        handleShowModal('update', record);
      } else {
        handleShowModal('add', {});
      }
    }
  }, [record]);

  const handleShowModal = (type: 'add' | 'update', record: any) => {
    if (type === 'add') {
      setArrayDataChooseQuestionCombine([]);
      setModalStatus({
        title: i18next.t('addQuestion'),
        open: true,
        type,
      });
    }
    if (type === 'update') {
      setArrayDataChooseQuestionCombine(record.answers);
      setModalStatus({
        title: i18next.t('UpdateQuestion'),
        open: true,
        type,
      });
    }
  };

  const userCallbackCancelModal = () => {
    onRefreshData();
    setModalStatus({
      title: '',
      open: false,
      type: '',
    });
  };

  const useCallbackQuestion = React.useCallback((arrayQuestion) => {
    if (arrayQuestion) {
      setArrayDataChooseQuestionCombine(arrayQuestion);
    }
  }, []);

  return (
    <div>
      <Modal
        title={modalStatus.title}
        footer={null}
        width={1000}
        open={modalStatus.open}
        onCancel={() => userCallbackCancelModal()}
        destroyOnClose
        centered
      >
        <IndexFormImportQuestion
          buttonCloseModal={userCallbackCancelModal}
          typeView={modalStatus.type}
          dataQuestion={record}
          setIsOpenModalChooseQuestion={setIsOpenModalChooseQuestion}
          arrayDataChooseQuestionCombine={arrayDataChooseQuestionCombine}
        />
      </Modal>
      <Modal
        title={t('questionManagement.selectQuestions')}
        footer={null}
        width={'90%'}
        open={isOpenModalChooseQuestion}
        onCancel={() => setIsOpenModalChooseQuestion(false)}
        centered
        destroyOnClose
      >
        <ViewImportExam
          typeContent={'chooseQuestion'}
          id={null}
          onCancel={() => setIsOpenModalChooseQuestion(false)}
          data={null}
          hiddenTitle={true}
          useCallbackQuestion={useCallbackQuestion}
          arrayDataChooseQuestionCombine={arrayDataChooseQuestionCombine}
        />
      </Modal>
    </div>
  );
};

export default InsertUpdateQuestion;
