import colors from 'constants/colors';
import Card from 'components/Containers/Card';
import { PlusOutlined, DeleteOutlined, EditOutlined, EyeOutlined, CheckOutlined } from '@ant-design/icons';
import Container from 'components/Containers/Container';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import { Button, Col, Form, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import { query } from 'helpers/QueryHelper';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import { supportColumns, teacherColumns } from './tableColumn';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import settings from 'constants/settings';
import i18next from 'i18next';

const ManagerSupport = () => {
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [selectRow, setSelectRow] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);
  const [dataSupport, setDataSupport] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [modalStatus, setModalStatus] = React.useState({
    open: false,
    title: '',
    type: 'add',
  });
  const CourseExtraColumns = [
    {
      key: 'editInfomation',
      render: (text, record) => {
        return (
          <Space>
            <Tooltip placement='top' title={'Update'}>
              <Button
                key='edit-file'
                shape='circle'
                type='dashed'
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectRow(record);
                  handleShowModal('edit', record);
                }}
              />
            </Tooltip>
            <Popconfirm
              placement='topRight'
              title={() => {
                return (
                  <div>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>
                      {i18next.t('Are you sure to delete', { object: i18next.t('Category').toLowerCase() })}
                    </p>
                    <strong style={{ color: '#f5222d' }}>({i18next.t('popconfirm.unrecoverable')} )</strong>
                  </div>
                );
              }}
              onConfirm={() => onDelete(record.id)}
              okText={i18next.t('Confirm')}
              cancelText={i18next.t('Close')}
            >
              <Tooltip placement='top' title={i18next.t('Delete')}>
                <Button
                  key='edit-file'
                  shape='circle'
                  type='dashed'
                  danger
                  style={{ color: '#f5222d' }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          </Space>
        );
      },
      sort: false,
      title: i18next.t('Action'),
      width: '10%',
    },
  ];
  React.useEffect(() => {
    query('p_QUIZCITY_Request_Category_Select')
      .then((res) => {
        setDataSupport(res);
      })
      .catch((error) => {});
  }, [refresh]);

  const handleShowModal = (type, record) => {
    form.setFieldsValue(record);
    setModalStatus({
      open: true,
      title: type === 'add' ? 'ADD NEW SUPPORT CATEGORY' : 'EDIT SUPPORT CATEGORY',
      type,
    });
  };

  const onInsert = (value) => {
    setLoading(true);
    const { code, categoryName } = value;
    query('p_QUIZCITY_Request_Category_InsertUpdate', {
      code: code.trim().replace(/\s+/g, ' '),
      createdBy: loggedInUser.id,
      id: '00000000-0000-0000-0000-000000000000',
      parentId: '00000000-0000-0000-0000-000000000000',
      address: '',
      categoryName: categoryName.trim().replace(/\s+/g, ' '),
    })
      .then((res) => {
        // message.success('New support category has been added!');
        message.success(i18next.t('add success', { field: i18next.t('supportCategory') }));

        setRefresh((f) => f + 1);
        setModalStatus({ open: false });
      })
      .catch((error) => {
        message.error(i18next.t('add failed', { field: i18next.t('supportCategory') }));
      })
      .finally(() => setLoading(false));
  };

  const onUpdate = (value) => {
    setLoading(true);
    const { parentId, id } = selectRow;
    const { code, categoryName } = value;
    query('p_QUIZCITY_Request_Category_InsertUpdate', {
      code: code.trim().replace(/\s+/g, ' '),
      createdBy: loggedInUser.id,
      id,
      parentId: parentId || '00000000-0000-0000-0000-000000000000',
      address: '',
      categoryName: categoryName.trim().replace(/\s+/g, ' '),
    })
      .then((res) => {
        message.success(i18next.t('update success', { field: i18next.t('supportCategory') }));
        setRefresh((f) => f + 1);
        setModalStatus({ open: false });
      })
      .catch((error) => {
        message.error(i18next.t('update failed', { field: i18next.t('supportCategory') }));
      })
      .finally(() => setLoading(false));
  };

  const onDelete = (id) => {
    query('p_QUIZCITY_Request_Category_Delete', { RequestCategoryId: id })
      .then((res) => {
        const { message: messageRes, data } = res[0];
        if (messageRes === '') {
          message.success(i18next.t('delete success', { field: i18next.t('supportCategory') }));
          setRefresh((f) => f + 1);
        } else {
          Modal.warning({
            title: "Can't be deleted!",
            content: (
              <>
                <div>This category has requests</div>
              </>
            ),
            // okButtonProps: { className: settings.IS_LG ? 'modal-btn' : '' },
          });
        }
      })
      .catch((error) => {
        message.error(i18next.t('delete failed', { field: i18next.t('supportCategory') }));
      });
  };

  const handleSubmit = (type, value) => {
    if (type === 'add') onInsert(value);
    else onUpdate(value);
  };
  // const removeAccents = (str) => {
  //   return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  // };
  // const handleSearch = (s) => {
  //   if (s.searchByName || s.searchByCode) {
  //     setDataSearch(
  //       dataGiangVien
  //         .filter((item) => {
  //           const codeContainsSearch =
  //             !s.searchByCode ||
  //             removeAccents(item.code.toLowerCase()).includes(removeAccents(s.searchByCode.toLowerCase()));
  //           return codeContainsSearch;
  //         })
  //         .filter((item) => {
  //           const categoryNameContainsSearch =
  //             !s.searchByName ||
  //             removeAccents(item.categoryName.toLowerCase()).includes(removeAccents(s.searchByName.toLowerCase()));
  //           return categoryNameContainsSearch;
  //         }),
  //     );
  //   } else {
  //     setDataSearch();
  //   }
  // };

  return (
    <div>
      <Container name='Support Category' parentName='Administration'>
        <Card
          title={i18next.t('supportCategory').toUpperCase()}
          extra={
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', columnGap: 10 }}>
              <DynamicButton
                onClick={() =>
                  handleShowModal('add', {
                    code: GenPasswordModel(1, 6),
                    categoryName: '',
                  })
                }
              >
                <PlusOutlined /> {i18next.t('addNew', { field: i18next.t('supportCategory') })}
              </DynamicButton>
            </div>
          }
        >
          <DynamicTable
            // loading
            dataSource={dataSupport}
            initialTableData={supportColumns}
            extraColumns={CourseExtraColumns}
            className='components-table-demo-nested'
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '50', '100'],
            }}
            expandIconColumnIndex={5}
            expandIconAsCell={false}
          />
        </Card>
      </Container>

      <Modal
        title={modalStatus.title}
        okText={i18next.t('Save')}
        cancelText={i18next.t('Close')}
        onOk={() => {
          form.submit();
        }}
        open={modalStatus.open}
        onCancel={() => setModalStatus({ open: false })}
        footer={null}
      >
        <Form form={form} onFinish={(value) => handleSubmit(modalStatus.type, value)} layout='vertical'>
          <div>
            <Form.Item
              name='code'
              label='Category Code'
              rules={[
                {
                  validator: (_, value) => {
                    if (value.trim() === '')
                      return Promise.reject(i18next.t('please enter', { field: i18next.t('code') }));
                    else {
                      const checkDuplicate = dataSupport?.some(
                        (item) =>
                          item?.code.trim().toLowerCase().replace(/\s+/g, ' ') ===
                            value.trim().toLowerCase().replace(/\s+/g, ' ') &&
                          (item?.id !== selectRow?.id || modalStatus.type === 'add'),
                      );
                      if (checkDuplicate) return Promise.reject(i18next.t('unavailable', { field: i18next.t('code') }));
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              required
            >
              <Input />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              name='categoryName'
              label={i18next.t('Category')}
              rules={[
                {
                  validator: (_, value) => {
                    if (value.trim() === '')
                      return Promise.reject(i18next.t('please enter', { field: i18next.t('Category') }));
                    else {
                      const checkDuplicate = dataSupport?.some(
                        (item) =>
                          item?.categoryName.trim().toUpperCase().replace(/\s+/g, ' ') ===
                            value.trim().toUpperCase().replace(/\s+/g, ' ') &&
                          (item?.id !== selectRow?.id || modalStatus.type === 'add'),
                      );
                      if (checkDuplicate) return Promise.reject(i18next.t('unavailable', { field: i18next.t('code') }));
                      return Promise.resolve();
                    }
                  },
                },
              ]}
              required
            >
              <Input />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: 20 }}>
            <DynamicButton htmlType='button' onClick={() => setModalStatus({ open: false })}>
              {i18next.t('Close')}
            </DynamicButton>
            <DynamicButton type='primary' htmlType='submit' loading={loading}>
              {i18next.t('Save')}
            </DynamicButton>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default ManagerSupport;
