import { CalendarOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import colors from 'constants/colors';
import settings from 'constants/settings';
import i18next from 'i18next';

const extraInfo = settings.IS_LG
  ? [
      {
        key: 'shopName',
        // prefix: <PhoneOutlined className='tw-mr-2' />,
        render: 'renderNoWrap',
        style: {
          color: colors.font,
          fontWeight: 400,
        },
        title: i18next.t('Part'),
        width: 135,
      },
    ]
  : [
      {
        key: 'phone',
        prefix: <PhoneOutlined className='tw-mr-2' />,
        render: 'renderNoWrap',
        style: {
          color: colors.font,
          fontWeight: 400,
        },
        title: i18next.t('table:classInformation.Phone number'),
        width: 135,
      },
    ];

export default {
  rowKey: 'id',
  displayColumns: [
    {
      key: 'maSV',
      render: 'renderNoWrap',
      style: {
        color: colors.font,
        fontWeight: 600,
      },
      title: <span style={{ color: colors.font }}>{i18next.t('table:statistic.Code')}</span>,
    },
    {
      key: 'fullName',
      prefix: <UserOutlined className='tw-mr-2' />,
      render: 'renderNoWrap',
      style: {
        color: colors.font,
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      title: <span style={{ color: colors.font }}>{i18next.t('table:statistic.Full name')}</span>,
    },
    {
      key: 'email',
      prefix: <MailOutlined className='tw-mr-2' />,
      render: 'renderNoWrap',
      style: {
        color: colors.font,
        fontWeight: '400',
        textTransform: 'lowercase',
      },
      title: <span style={{ color: colors.font }}> {i18next.t('table:statistic.Email')} </span>,
    },
    ...extraInfo,
    {
      key: 'createdDate',
      prefix: <CalendarOutlined className='tw-mr-2' />,
      render: 'renderDateTime',
      sort: false,
      title: <span style={{ color: colors.font }}> {i18next.t('table:notification.Created date')} </span>,
      style: {
        color: colors.font,
      },
    },
  ],
};
