import { Col, DatePicker, Form, Input, message, Row, Select, Spin, TreeSelect } from 'antd';
import colors from 'constants/colors';
import settings, { IS_BASAO, IS_LX, IS_MENARD, IS_TRONGDONG } from 'constants/settings';
import { generateSHA1 } from 'helpers/CryptoHelper';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import registerNewUser from 'modules/Administration/api/registerNewUser';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import moment from 'moment';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { filterTreeSelectShops, validateSelectShop } from 'pages/Administration/Menard/ManagerShopsV2/tableColumn';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

const configMessageForm = {
  required: 'Vui lòng nhập ${label}',
};

export default function InsertUpdateUser({
  type,
  record = {},
  cancelModal,
  optionsKhoa,
  optionsNganh,
  teachersList,
  optionsClass,
  optionsPosition,
  optionsShop,
  shops,
}) {
  const [loading, setLoading] = useState(false);
  const [informationTeacher, setInformationTeacher] = useState({});
  const [isDisableChangeNganh, setDisableChageNganh] = useState(true);
  const [userTypeId, setUserTypeId] = useState(1);
  const [khoaId, setKhoaId] = useState(null);
  const [classId, setClassId] = useState();

  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const extraRoleId = loggedInUser.role === 'administrator' ? [{ label: i18next.t('Manager'), value: 3 }] : []; // chỉ có admin mới tạo được role manager
  const GenMaSV = useMemo(() => GenPasswordModel(1, 6), [record]);
  // dùng useMemo để thay đổi password mỗi khi mở popup lên, tránh trùng password khi tạo mới
  const GenPassword = IS_MENARD ? '123456' : settings.IS_LG ? GenMaSV : useMemo(() => GenPasswordModel(2, 8), [record]);

  useEffect(() => {
    // ? Set values default cho form Edit/Update user information
    if (type === 'edituser') {
      setKhoaId(record.khoa);
      form.setFieldsValue({
        MaSv: record.maSV,
        firstName: record.fullName.split(' ').slice(0, -1).join(' '),
        lastName: record.fullName.split(' ').slice(-1).join(' '),
        email: record.email,
        userType: record.roleId,
        phone: record.phone,
        sex: typeof record.sex === 'boolean' || typeof record.sex === 'number' ? (record.sex ? 1 : 0) : null,
        class: record.class,
        khoa: record.khoa,
        chuyen_nganh: record.nganh,
        birthday: record.birthday ? moment(record.birthday) : null,
        chucVu: record.chucVu,
        shop: record.shop,
        ngayVaoCongTy: record.ngayVaoCongTy ? moment(record.ngayVaoCongTy) : null,
      });
      if (record.ngayVaoCongTy) form.setFieldValue('ngayVaoCongTy', moment(record.ngayVaoCongTy));
    } else form.resetFields();
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    if (type === 'edituser') {
      const {
        MaSv,
        email,
        firstName,
        lastName,
        userType,
        phone,
        sex,
        chuyen_nganh,
        khoa,
        birthday,
        shop,
        chucVu,
        ngayVaoCongTy,
      } = values;
      const parameters = {
        Id: record.id,
        MaSV: MaSv,
        Email: email,
        Name: `${firstName} ${lastName}`,
        Phone: phone,
        RoleId: userType,
        schoolTypeId: null,
        Sex: sex,
        Status: record.status,
        Avatar: record.avatar,
        BirthDay: birthday ? moment(birthday).format('yyyy-MM-DD') : null,
        nganh: chuyen_nganh || '00000000-0000-0000-0000-000000000000',
        khoa: khoa || '00000000-0000-0000-0000-000000000000',
        classId: classId,
        shop: shop || '00000000-0000-0000-0000-000000000000',
        chucVu: chucVu || '00000000-0000-0000-0000-000000000000',
        ngayVaoCongTy: ngayVaoCongTy || null,
      };
      query('p_VIMARU_Users_Edit', parameters)
        .then((res) => {
          if (res?.[0]?.res === 1) {
            //* Update success
            cancelModal();
            message.success(i18next.t('update success', { field: i18next.t('information') }));
            queryClient.invalidateQueries('Users');
          } else {
            //! Error: Updated fail
            message.error(i18next.t('alreadyExists2', { field: i18next.t('userCode'), field1: i18next.t('Email') }));
          }
        })
        .catch(() => {
          //! Error: Connected Fail
          message.error(i18next.t('connectError'));
        })
        .finally(() => setLoading(false));
    } else {
      const {
        lastName,
        firstName,
        email,
        password,
        userType,
        MaSv,
        phone,
        khoa,
        birthday,
        chuyen_nganh,
        teacherName,
        teacherPhone,
        teacherEmail,
        status,
        sex,
        chucVu,
        shop,
        ngayVaoCongTy,
      } = {
        ...values,
        gender: 1,
        parentFullname: '',
        status: 'ACTIVE',
      };
      const fullName = `${firstName} ${lastName}`;
      registerNewUser({
        email,
        password,
        passwordEncrypt: generateSHA1(password),
        fullName,
        roleId: userType,
        cityId: 0,
        phone: phone,
        maSv: MaSv,
        note: '',
        teacherId: informationTeacher?.id,
        khoa: khoa || '00000000-0000-0000-0000-000000000000',
        nganh: chuyen_nganh || '00000000-0000-0000-0000-000000000000',
        classId: classId,
        birthDay: birthday ? birthday.format('yyyy-MM-DD') : null,
        status: status,
        sex: sex,
        shop: shop || '00000000-0000-0000-0000-000000000000',
        chucVu: chucVu || '00000000-0000-0000-0000-000000000000',
        ngayVaoCongTy: ngayVaoCongTy || null,
      })
        .then((res) => {
          if (res[0].res === 1) {
            message.success(i18next.t('createAccSuccess'));
            queryClient.invalidateQueries('Users');
            cancelModal();
          } else {
            if (res?.[0].email === email) {
              message.error(i18next.t('alreadyExists', { field: i18next.t('Email') }));
            } else if (res?.[0].maSV === MaSv) {
              message.error(
                i18next.t('alreadyExists', {
                  field: IS_MENARD ? i18next.t('employeeCode') : i18next.t('userCode'),
                }),
              );
            } else {
              message.error(i18next.t('createAccFailed'));
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onChangeValueClass = (value, option) => {
    const filterInformationTeacher = teachersList.filter((item) => {
      return item.id === Number(option?.teacherId);
    });

    form.setFieldsValue({
      teacherPhone: filterInformationTeacher?.[0]?.phone,
      teacherEmail: filterInformationTeacher?.[0]?.email,
      teacherName: filterInformationTeacher?.[0]?.fullName,
    });
    setInformationTeacher(filterInformationTeacher?.[0]);
    setClassId(option?.classId);
  };

  const handleChangeKhoa = (value) => {
    if (value) {
      setDisableChageNganh(false);
      setKhoaId(value);
      form.setFieldValue('chuyen_nganh', null);
    }
  };
  const handleChangeUserType = (roleId) => {
    setUserTypeId(roleId);
  };

  return (
    <Spin spinning={loading}>
      <Form
        layout='vertical'
        autoComplete='off'
        form={form}
        onFinish={onFinish}
        validateMessages={configMessageForm}
        initialValues={{ MaSv: GenMaSV, password: GenPassword, confirmPassword: GenPassword }}
      >
        <Row gutter={[12, 12]}>
          <Col lg={8} xs={24}>
            <Form.Item
              // label={IS_MENARD ? i18next.t('employeeCode') : i18next.t('userCode')}
              label={
                <label style={{ color: colors.font }}>
                  {IS_MENARD ? i18next.t('employeeCode') : i18next.t('userCode')}
                </label>
              }
              name='MaSv'
              rules={[
                {
                  required: true,
                  message: i18next.t('please enter', {
                    field: IS_MENARD ? i18next.t('employeeCode') : i18next.t('userCode'),
                  }),
                },
              ]}
            >
              <Input style={{ color: colors.font }} />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              label={<label style={{ color: colors.font }}>{i18next.t('firstName')}</label>}
              name='firstName'
              hasFeedback
              rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('firstName') }) }]}
            >
              <Input style={{ color: 'red' }} />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              label={<label style={{ color: colors.font }}>{i18next.t('lastName')}</label>}
              name='lastName'
              hasFeedback
              rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('lastName') }) }]}
            >
              <Input style={{ color: colors.font }} allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col lg={settings.IS_LG ? 8 : 16} xs={24}>
            <Form.Item
              label={
                <label style={{ color: colors.font }}>{`${i18next.t('Email')} (${i18next.t('Use to sign in')})`}</label>
              }
              name='email'
              hasFeedback
              rules={[
                { type: 'email', message: i18next.t('invalidEmail') },
                { required: true, message: i18next.t('please enter', { field: 'Email' }) },
              ]}
            >
              <Input style={{ color: colors.font }} allowClear />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              label={<label style={{ color: colors.font }}>{i18next.t('birthday')}</label>}
              name='birthday'
              hasFeedback
              // rules={[{ required: true, message: i18next.t('please choose', { field: i18next.t('dob') }) }]}
            >
              <DatePicker
                allowClear
                popupStyle={{
                  zIndex: 999999999999999999,
                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          {settings.IS_LG && (
            <Col lg={8} xs={24}>
              <Form.Item
                style={{ fontWeight: 600 }}
                label={<label style={{ color: colors.font }}>{i18next.t('user:Department')}</label>}
                name='shop'
              >
                <TreeSelect
                  showSearch
                  allowClear
                  treeData={optionsShop}
                  dropdownStyle={{ maxHeight: 800, overflow: 'auto' }}
                  // treeDefaultExpandAll
                  filterTreeNode={filterTreeSelectShops}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        {type !== 'edituser' && (
          <Row gutter={[12, 12]}>
            <Col lg={12} xs={24}>
              <Form.Item
                label={<label style={{ color: colors.font }}>{i18next.t('password')}</label>}
                name='password'
                hasFeedback
                rules={[
                  { required: true, message: i18next.t('please enter', { field: i18next.t('password') }) },
                  {
                    min: 6,
                    // message: settings.IS_LG ? 'Password of at least 6 characters' : 'Mật khẩu ít nhất có 6 ký tự',
                    message: i18next.t('PasswordAtLeast'),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                label={<label style={{ color: colors.font }}>{i18next.t('confirmPassword')}</label>}
                name='confirmPassword'
                dependencies={['password']}
                hasFeedback
                rules={[
                  { required: true, message: i18next.t('please enter', { field: i18next.t('confirmPassword') }) },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(i18next.t('confirm password mismatch')));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={[12, 12]}>
          <Col lg={8} xs={24}>
            <Form.Item
              label={<label style={{ color: colors.font }}>{i18next.t('phoneNumber')}</label>}
              name='phone'
              hasFeedback
              // rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('phoneNumber') }) }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              name='sex'
              label={<label style={{ color: colors.font }}>{i18next.t('gender')}</label>}
              // rules={[{ required: true, message: i18next.t('please choose', { field: i18next.t('gender') }) }]}
            >
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                placeholder={i18next.t('gender')}
                options={[
                  { value: 1, label: i18next.t('male') },
                  { value: 0, label: i18next.t('female') },
                ]}
              />
            </Form.Item>
          </Col>
          <Col lg={8} xs={24}>
            <Form.Item
              name='userType'
              label={<label style={{ color: colors.font }}>{i18next.t('accountType')}</label>}
              rules={[{ required: true, message: i18next.t('please choose', { field: i18next.t('accountType') }) }]}
            >
              <Select
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                placeholder={i18next.t('accountType')}
                options={[
                  { value: 1, label: settings.IS_VIMARU ? i18next.t('Student1') : i18next.t('Student2') },
                  { value: 2, label: i18next.t('Teacher') },
                  ...extraRoleId,
                ]}
                onChange={handleChangeUserType}
              />
            </Form.Item>
          </Col>
        </Row>

        {(IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX) && (
          <Row gutter={[12, 12]}>
            <Col lg={8} xs={24}>
              <Form.Item
                style={{ fontWeight: 600 }}
                label={<label style={{ color: colors.font }}>{i18next.t('Part')}</label>}
                name='shop'
                rules={[
                  {
                    validator: (_, value) => validateSelectShop(value, shops),
                  },
                ]}
              >
                <TreeSelect
                  showSearch
                  treeData={optionsShop}
                  dropdownStyle={{ maxHeight: 800, overflow: 'auto' }}
                  placeholder='Chọn bộ phận'
                  // treeDefaultExpandAll
                  style={{ minWidth: 120, width: '100%', fontWeight: 600 }}
                  filterTreeNode={filterTreeSelectShops}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={8} xs={24}>
              <Form.Item
                style={{ fontWeight: 600 }}
                label={<label style={{ color: colors.font }}>{i18next.t('user:Position')}</label>}
                name='chucVu'
              >
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  placeholder='Chọn chức vụ'
                  optionFilterProp='children'
                  filterOption={(input, option) => option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={optionsPosition}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col lg={8} xs={24}>
              <Form.Item
                label={<label style={{ color: colors.font }}>Ngày vào công ty</label>}
                name='ngayVaoCongTy'
                hasFeedback
                rules={[{ type: 'date', message: 'Ngày không hợp lệ' }]}
              >
                <DatePicker
                  allowClear
                  popupStyle={{
                    zIndex: 999999999999999999,
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {(settings.IS_VIMARU || settings.IS_HIGHUP) && (
          <Row gutter={[12, 12]}>
            <Col lg={12} xs={24}>
              <Form.Item name='khoa' label='Khoa' rules={[{ required: settings.IS_VIMARU }]}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  onChange={handleChangeKhoa}
                  placeholder='Chọn khoa'
                  optionFilterProp='children'
                  filterOption={(input, option) => option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={optionsKhoa}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name='chuyen_nganh' label='Chuyên ngành' rules={[{ required: settings.IS_VIMARU }]}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  placeholder='Chọn chuyên ngành'
                  optionFilterProp='children'
                  filterOption={(input, option) => option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  disabled={settings.IS_VIMARU ? isDisableChangeNganh : false}
                  options={optionsNganh.filter((item) => item?.parentId === khoaId)}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {type !== 'edituser' && !settings.IS_HANDOUT && userTypeId !== 3 && (
          <Row gutter={[12, 12]}>
            <Col lg={6} xs={24}>
              <Form.Item
                name='class'
                label={<label style={{ color: colors.font }}>{i18next.t('Class')}</label>}
                rules={[
                  { required: settings.IS_VIMARU, message: i18next.t('please choose', { field: i18next.t('Class') }) },
                ]}
              >
                <Select
                  // mode='multiple'
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  placeholder={i18next.t('SelectClass')}
                  optionFilterProp='children'
                  onChange={onChangeValueClass}
                  filterOption={(input, option) => option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  disabled={settings.IS_VIMARU ? isDisableChangeNganh : false}
                  options={optionsClass}
                />
              </Form.Item>
            </Col>
            <Col lg={6} xs={24}>
              <Form.Item
                name='teacherName'
                label={<label style={{ color: colors.font }}>{i18next.t('TeacherCharge')}</label>}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col lg={6} xs={24}>
              <Form.Item
                name='teacherPhone'
                label={<label style={{ color: colors.font }}> {i18next.t('TeacherCharge')}</label>}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col lg={6} xs={24}>
              <Form.Item name='teacherEmail' label={<label style={{ color: colors.font }}>Email</label>}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        )}

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', columnGap: 10 }}>
          <DynamicButton onClick={() => cancelModal()}> {i18next.t('Close')}</DynamicButton>
          <DynamicButton loading={loading} htmlType='submit' type='primary'>
            {i18next.t('Save')}
          </DynamicButton>
        </div>
      </Form>
    </Spin>
  );
}
