import { notification } from 'antd';
import notificationWithIcon from 'components/Notification/notificationWithIcon';
import { queryWithoutModuleId } from 'helpers/QueryHelper';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { loginGoogle } from '../actions';
import * as actionTypes from '../actions/types';
import { emailLoginGoogle, hanhtrangsoLogin, sachsoLogin, sachsoRegister } from '../services';
import settings from 'constants/settings';

const alertGoogle = (message = 'Tài khoản email không có quyền hạn') => {
  notification.error({ message: message });
};

// SSO
function* AUTH_LOGIN(action) {
  try {
    const { email, password } = action.payload;
    const loggedInUser = yield sachsoLogin(email, password);
    if (loggedInUser) {
      yield put({
        type: actionTypes.AUTH_LOGIN_SUCCESS,
        payload: loggedInUser,
      });
    } else {
      yield put({ type: actionTypes.AUTH_LOGIN_FAILURE, payload: null });
    }
  } catch (error) {
    yield put({ type: actionTypes.AUTH_LOGIN_FAILURE, payload: error });
  }
}

function* AUTH_LOGIN_GOOGLE(action) {
  try {
    const { email } = action.payload;
    const { loggedInUser, message } = yield emailLoginGoogle(email);
    if (loggedInUser) {
      yield put({
        type: actionTypes.AUTH_LOGIN_GOOGLE_SUCCESS,
        payload: loggedInUser,
      });
    } else {
      alertGoogle(message);
      yield put({ type: actionTypes.AUTH_LOGIN_GOOGLE_FAILURE, payload: null });
    }
  } catch (error) {
    alertGoogle();
    yield put({ type: actionTypes.AUTH_LOGIN_GOOGLE_FAILURE, payload: error });
  }
}
export default function* sagasLogin() {
  yield takeLatest(actionTypes.AUTH_LOGIN, AUTH_LOGIN);
  yield takeLatest(actionTypes.AUTH_LOGIN_GOOGLE, AUTH_LOGIN_GOOGLE);
}
