/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import { Form, Input, Button, Col, Row, Divider, Alert, message } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
// core components

import { useDispatch, useSelector } from 'react-redux';
import colors from 'constants/colors';
// import { hanhtrangsoClient } from 'configs/api';
import React from 'react';
import { sachsoRegister } from 'modules/Auth/services';
import { login } from '../../actions';
import i18next from 'i18next';

export default ({ onClose }) => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.authReducer.error);
  const loading = useSelector((state) => state.authReducer.loading);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const body = {
      ...values,
      gender: 1,
      userType: 1,
      schoolTypeId: 0,
      parentFullname: '',
      birthday: '1990-1-1',
      roleCode: 'PUPIL',
    };
    sachsoRegister(body)
      .then((response) => {
        message.success(i18next.t('Signed up successfully'), 1.5, () => {
          dispatch(login(values.email, values.password));
        });
      })
      .catch((err) => {
        // console.log('🚀 ~ file: RegisterStudentPopup ~ Đăng ký thất bại', err);
        message.error(i18next.t('Sign up failed'));
      });
    // hanhtrangsoClient
    //   .post('/Register', body)
    //   .then((response) => {
    //     if (response.data.status === 'success') {
    //       message.success('Đăng nhập thành công', 1.5, () => {
    //         dispatch(login(values.email, values.password));
    //       });
    //     } else if (response.data.status === 'failed') {
    //       message.error(response.data.errors[0].message);
    //     }
    //   })
    //   .catch(() => {});
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={24} xs={24}>
          <div style={{ paddingTop: 32, paddingInline: 32 }}>
            <span style={{ fontSize: 24, fontWeight: 600, color: colors.font }}>ĐĂNG KÝ TÀI KHOẢN</span>
          </div>
          <Divider />
          <div style={{ paddingInline: 32, paddingBottom: 32 }}>
            <Form layout='vertical' autoComplete='off' form={form} onFinish={onFinish} requiredMark={false}>
              <Row gutter={[12, 12]}>
                <Col lg={16} xs={24}>
                  <Form.Item
                    style={{ fontWeight: 600 }}
                    label='Họ và tên đệm'
                    name='firstName'
                    hasFeedback
                    rules={[
                      { whitespace: true, message: 'Chưa nhập họ và tên đệm' },
                      { required: true, message: 'Chưa nhập họ và tên đệm' },
                    ]}
                  >
                    <Input allowClear size='large' />
                  </Form.Item>
                </Col>
                <Col lg={8} xs={24}>
                  <Form.Item
                    style={{ fontWeight: 600 }}
                    label='Tên'
                    name='lastName'
                    hasFeedback
                    rules={[
                      { whitespace: true, message: 'Chưa nhập tên' },
                      { required: true, message: 'Chưa nhập tên' },
                    ]}
                  >
                    <Input allowClear size='large' />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                style={{ fontWeight: 600 }}
                label='Email'
                name='email'
                hasFeedback
                rules={[
                  { type: 'email', message: 'Email không hợp lệ' },
                  { whitespace: true, message: 'Chưa nhập Email' },
                  { required: true, message: 'Chưa nhập Email' },
                ]}
              >
                <Input allowClear size='large' />
              </Form.Item>
              <Form.Item
                style={{ fontWeight: 600 }}
                label='Mật khẩu'
                name='password'
                hasFeedback
                rules={[
                  { whitespace: true, message: 'Chưa nhập mật khẩu' },
                  { required: true, message: 'Chưa nhập mật khẩu' },
                  { min: 6, message: 'Mật khẩu ít nhất có 6 ký tự' },
                ]}
              >
                <Input.Password allowClear size='large' />
              </Form.Item>
              <Form.Item
                style={{ fontWeight: 600 }}
                label='Xác nhận mật khẩu'
                name='confirmPassword'
                dependencies={['password']}
                hasFeedback
                rules={[
                  { whitespace: true, message: 'Chưa nhập xác nhận mật khẩu' },
                  { required: true, message: 'Chưa nhập xác nhận mật khẩu' },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Xác nhận mật khẩu không khớp'));
                    },
                  }),
                ]}
              >
                <Input.Password allowClear size='large' />
              </Form.Item>

              <Button
                loading={loading}
                size='large'
                className='mt-3 mb-2'
                htmlType='submit'
                type='primary'
                block
                style={{ backgroundColor: '#017AFF', fontWeight: 600, height: 48, borderRadius: '0.25rem' }}
              >
                ĐĂNG KÝ
              </Button>
              {error && error.event && (
                <Alert
                  message='Đăng nhập không thành công'
                  type='error'
                  showIcon
                  style={{ marginBlock: 6, borderRadius: '0.25rem' }}
                />
              )}
              <div style={{ textAlign: 'center' }}>
                <span style={{ color: colors.greyChateau }}>Bạn đã có tải khoản?</span>
                <Button
                  type='link'
                  size='small'
                  onClick={() => {
                    if (onClose && typeof onClose === 'function') {
                      onClose();
                    }
                  }}
                >
                  Đăng nhậ
                </Button>
              </div>
            </Form>
          </div>
          <div style={{ position: 'absolute', top: 0, right: 0, padding: 12 }}>
            <CloseCircleOutlined
              style={{ fontSize: 24, color: 'gray', cursor: 'pointer' }}
              onClick={() => {
                if (onClose && typeof onClose === 'function') {
                  onClose();
                }
              }}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
