import React, { useState, useEffect, useCallback } from 'react';
import withAuthenticator from 'HOCs/withAuthenticator';
import { query } from 'helpers/QueryHelper';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import tableData from './tableData';
import { Form, Input, Button, Modal, InputNumber, Select, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Card from 'components/Containers/Card';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import moment from 'moment';
import notificationWithIcon from 'components/Notification/notificationWithIcon';
import ExportExcel from 'components/Excel/exportExcel/ExportExcel';
import { excelColumns } from './excelColumns';
import { useQueryClient } from 'react-query';
import ListCodes from './components/ListCodes';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import settings from 'constants/settings';
import i18next from 'i18next';

export default withAuthenticator(({ loggedInUser }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [parameters, setParameters] = useState(null);
  const [isOpenModalCreateCode, setIsOpenModalCreateCode] = useState(false);
  const [coursesList, setCoursesList] = useState([]);
  const queryClient = useQueryClient();

  const onSelectData = (value) => {
    const { courseId, code, userId, status } = value;
    const param = {
      UserId: loggedInUser?.id,
      ExamId: courseId || '00000000-0000-0000-0000-000000000000',
      Status: status,
      Code: code,
      ContactName: '',
      ContactEmail: '',
      ContactPhone: '',
      // FromDate: null,
      // ToDate: null,
    };
    setParameters(param);
  };

  useEffect(() => {
    query('p_QUIZCITY_Exam_Category_Select').then((res) => {
      const options = res.map((item) => ({ label: item.name, value: item.id }));
      setCoursesList(options);
    });
  }, []);

  const handleCreateCodeExam = (value) => {
    const { courseId, number, note } = value;
    let arrCode = [];
    for (let i = 0; i < number; i++) {
      arrCode.push(GenPasswordModel(2, 6));
    }
    // console.log("🚀 ~ handleCreateCodeExam", arrCode.toString())
    query('p_QUIZCITY_PTTest_CodeExam_GenerateCode', {
      ExamId: courseId,
      CodeStrings: arrCode.toString(),
      CreatedBy: loggedInUser?.id,
      note,
    })
      .then((res) => {
        if (courseId === parameters?.ExamId) {
          queryClient.invalidateQueries('p_QUIZCITY_PTTest_CodeExam_Select');
        }
        setIsOpenModalCreateCode(false);
        notificationWithIcon('success', 'Thông báo', 'Lưu dữ liệu thành công');
      })
      .catch((err) => {
        console.error('err', err);
        notificationWithIcon('warning', 'Thông báo', 'Lưu dữ liệu thất bại');
      });
  };

  return (
    <React.Fragment>
      <Card
        title='Danh sách mã tham gia test'
        extra={
          <Row gutter={10}>
            <Col>
              <DynamicButton type='primary' onClick={() => setIsOpenModalCreateCode(true)}>
                <PlusOutlined /> Tạo mã test
              </DynamicButton>
            </Col>
            <Col>
              <ExportExcel
                filename='DS_ma-tham-gia-bai-test'
                element={<DynamicButton type='primary'>{i18next.t('excel export')}</DynamicButton>}
                sheets={[
                  {
                    name: 'CodeExam', // sheet name
                    column: excelColumns,
                    data: data,
                  },
                ]}
              />
            </Col>
          </Row>
        }
      >
        <Row justify={'space-between'}>
          <Form layout='inline' onFinish={onSelectData}>
            <Form.Item label='Đề bài test' name='courseId' rules={[{ required: true, message: 'Chưa nhập' }]}>
              <Select
                options={coursesList}
                allowClear
                dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: 10000 }}
                optionFilterProp='children'
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder='Chọn đề bài test'
              />
            </Form.Item>
            <Form.Item name='userId' hasFeedback>
              <Input disabled placeholder='Người tạo mã' allowClear />
            </Form.Item>
            <Form.Item name='code' hasFeedback>
              <Input placeholder='Mã test' allowClear width={100} />
            </Form.Item>
            <Form.Item name='status' hasFeedback>
              <Select
                options={[
                  { label: 'Đã kích hoạt', value: 1 },
                  { label: 'Chưa kích hoạt', value: 0 },
                  { label: 'Tất cả', value: -1 },
                ]}
                placeholder='Trạng thái mã'
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <DynamicButton type='primary' htmlType='submit'>
                Tìm kiếm
              </DynamicButton>
            </Form.Item>
          </Form>
        </Row>

        {parameters && <ListCodes parameters={parameters} />}
      </Card>
      {/* Modal tạo mã test */}
      <Modal
        title='TẠO MÃ TEST'
        open={isOpenModalCreateCode}
        footer={null}
        onCancel={() => setIsOpenModalCreateCode(false)}
      >
        <Form onFinish={handleCreateCodeExam} layout='vertical'>
          <Form.Item label='Đề bài test' name='courseId' rules={[{ required: true, message: 'Chưa nhập' }]}>
            <Select
              options={coursesList}
              allowClear
              dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: 10000 }}
              optionFilterProp='children'
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              placeholder='Chọn khoá học'
            />
          </Form.Item>
          <Form.Item name='number' label='Số lượng mã' rules={[{ required: true, message: 'Chưa nhập' }]}>
            <InputNumber min={1} max={100} />
          </Form.Item>
          <Form.Item name='note' label='Ghi chú'>
            <Input />
          </Form.Item>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', columnGap: 20 }}>
            <DynamicButton type='primary' htmlType='submit'>
              Bắt đầu tạo
            </DynamicButton>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
});
