/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-param-reassign */
import React from 'react';
import { fabric } from 'fabric';
import _ from 'lodash';
import { executeWithoutModuleId } from 'helpers/QueryHelper';
import { Row, Col, Space, Button, Popconfirm } from 'antd';
import numeral from 'numeral';
import { DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

const HTMLCanvasElement = 'quizcity-teacher-canvas';

const CorrectIcon = '/images/icons/correct.png';
const WrongIcon = '/images/icons/wrong.png';

const getScore = (result) => {
  if (!result) return 0;

  let correct = 0;
  let wrong = 0;
  if (result.correct) {
    correct = result.correct;
  }

  if (result.wrong) {
    wrong = result.wrong;
  }

  if (correct + wrong === 0) return 0;

  const output = (correct / (correct + wrong)) * 10;
  return output;
};

const getTotal = (result) => {
  if (!result) return 0;

  let correct = 0;
  let wrong = 0;
  if (result.correct) {
    correct = result.correct;
  }

  if (result.wrong) {
    wrong = result.wrong;
  }

  if (correct + wrong === 0) return 0;

  const output = correct + wrong;
  return output;
};

function WriteScoreCanvasWithinImage({ data }) {
  const imageUrl = `https://cloud.softech.vn/${data.fileUrl}`;
  const [currentCanvas, setCurrentCanvas] = React.useState(null);
  const [result, setResult] = React.useState(null);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const createdBy = loggedInUser.id;

  let activePointer = null;
  let activeObject = null;

  React.useEffect(() => {
    const canvas = new fabric.Canvas(HTMLCanvasElement, {
      isDrawingMode: false,
      renderOnAddRemove: true,
      hoverCursor: 'pointer',
    });

    fabric.Image.fromURL(imageUrl, (img) => {
      canvas.setWidth(img.width);
      canvas.setHeight(img.height);
      canvas.setBackgroundImage(img);
    });

    let mouseEvent = 'create';
    const countBy = _.countBy(canvas.getObjects(), (item) => {
      if (item.type === 'textbox') return 'unknown';
      return item.status === 'correct' ? 'correct' : 'wrong';
    });
    canvas.on({
      'mouse:down': (e) => {
        if (e.target && e.target.type === 'image') {
          mouseEvent = 'change';
        }

        if (e.target && e.target.type === 'textbox') {
          activeObject = e.target;
        }

        if (!e.target) {
          activePointer = e.pointer;
        }
      },
      'mouse:up': (e) => {
        const {
          pointer: { x, y },
        } = e;

        if (!e.target) {
          if (activeObject) {
            activeObject = null;
            return;
          }
          // console.log('pointer', e.pointer);
          // console.log('activePointer', activePointer);

          // CREATE IMAGE
          if (Math.abs(e.pointer.x - activePointer.x) <= 10 && Math.abs(e.pointer.y - activePointer.y) <= 10) {
            fabric.Image.fromURL(CorrectIcon, (image) => {
              image.set({
                top: y,
                left: x,
                scaleY: 0.8,
                scaleX: 0.8,
                originX: 'center',
                originY: 'center',
                hasControls: false,
                hasBorders: false,
              });

              image.set('status', 'correct');
              canvas.add(image);

              const countBy = _.countBy(canvas.getObjects(), (item) => {
                if (item.type === 'textbox') return 'unknown';
                return item.status === 'correct' ? 'correct' : 'wrong';
              });
              setResult(countBy);

              // SAVE JSON
              const jsonData = canvas.toDatalessJSON();
              executeWithoutModuleId('[p_SACHSO_SHARE_Attachments_AZOTA_Update]', {
                attachmentId: data.id,
                jsonData,
                score: getScore(countBy),
                totalQuestion: 0,
                createdBy,
                type: 'Note',
              }).then((response) => {
                // console.log(response);
              });
            });
          } else {
            // TẠO GHI CHÚ
            const textbox = new fabric.Textbox(i18next.t("slide:NewViewer.EssayResult.NoteResult.ImageAzota.Content note"), {
              fontFamily: 'Times',
              left: activePointer.x,
              top: activePointer.y,
              width: e.pointer.x - activePointer.x,
              fontSize: 25,
              fill: 'red',
              backgroundColor: 'yellow',
              borderColor: 'red',
              cornerColor: 'red',
              transparentCorners: false,
              cornerSize: 8,
            });

            // console.log(textbox);
            canvas.add(textbox).setActiveObject(textbox);
            activeObject = textbox;
            // SAVE
            const jsonData = canvas.toDatalessJSON();
            const countBy = _.countBy(canvas.getObjects(), (item) => {
              if (item.type === 'textbox') return 'unknown';
              return item.status === 'correct' ? 'correct' : 'wrong';
            });
            executeWithoutModuleId('[p_SACHSO_SHARE_Attachments_AZOTA_Update]', {
              attachmentId: data.id,
              jsonData,
              score: getScore(countBy),
              totalQuestion: 0,
              createdBy,
              type: 'Note',
            }).then((response) => {
              // console.log(response);
            });
          }
        } else if (e.target && e.target.type === 'image') {
          if (mouseEvent === 'change') {
            if (e.target.status === 'correct') {
              e.target.set('status', 'wrong');
              e.target.setSrc(WrongIcon, () => {
                canvas.renderAll();

                const countBy = _.countBy(canvas.getObjects(), (item) => {
                  if (item.type === 'textbox') return 'unknown';
                  return item.status === 'correct' ? 'correct' : 'wrong';
                });
                setResult(countBy);

                const jsonData = canvas.toDatalessJSON();
                executeWithoutModuleId('[p_SACHSO_SHARE_Attachments_AZOTA_Update]', {
                  attachmentId: data.id,
                  jsonData,
                  score: getScore(countBy),
                  totalQuestion: 0,
                  createdBy,
                  type: 'Note',
                }).then((response) => {
                  // console.log(response);
                });
              });
            } else if (e.target.status === 'wrong') {
              canvas.remove(e.target);
              const countBy = _.countBy(canvas.getObjects(), (item) => {
                if (item.type === 'textbox') return 'unknown';
                return item.status === 'correct' ? 'correct' : 'wrong';
              });
              setResult(countBy);

              const jsonData = canvas.toDatalessJSON();
              executeWithoutModuleId('[p_SACHSO_SHARE_Attachments_AZOTA_Update]', {
                attachmentId: data.id,
                jsonData,
                score: getScore(countBy),
                totalQuestion: 0,
                createdBy,
                type: 'Note',
              }).then((response) => {
                // console.log(response);
              });
            }
          }
        }
      },
      'object:moving': (e) => {
        mouseEvent = 'move';
      },
      'selection:updated': (e) => {
        // console.log('selection:updated');
      },
      'object:modified': (e) => {
        // console.log('object:modified');
        // SAVE
        const jsonData = canvas.toDatalessJSON();
        const countBy = _.countBy(canvas.getObjects(), (item) => {
          if (item.type === 'textbox') return 'unknown';
          return item.status === 'correct' ? 'correct' : 'wrong';
        });
        executeWithoutModuleId('[p_SACHSO_SHARE_Attachments_AZOTA_Update]', {
          attachmentId: data.id,
          jsonData,
          score: getScore(countBy),
          totalQuestion: 0,
          createdBy,
          type: 'Note',
        }).then((response) => {
          // console.log(response);
        });
      },
      'object:selected': (e) => {
        // console.log(e);
      },
      'object:added': (e) => {
        // console.log('adding...');
      },
      'object:removed': (e) => {
        // const countBy = _.countBy(canvas.getObjects(), (item) => {
        //   if (item.type === 'textbox') return 'unknown';
        //   return item.status === 'correct' ? 'correct' : 'wrong';
        // });
        // setResult(countBy);
        // const jsonData = canvas.toDatalessJSON();
        // executeWithoutModuleId('[p_SACHSO_SHARE_Attachments_AZOTA_Update]', {
        //   attachmentId: data.id,
        //   jsonData,
        //   score: getScore(countBy),
        //   totalQuestion: 0,
        //   createdBy,
        //   type: 'Note',
        // }).then((response) => {
        //   console.log(response);
        // });
      },
    });

    // load from json;
    const { jsonData } = data;
    if (jsonData) {
      _.each(jsonData.objects, (x) => {
        if (x.type === 'image') {
          x.hasBorders = false;
          x.hasControls = false;
          if (_.endsWith(x.src, 'correct.png')) {
            x.status = 'correct';
          } else if (_.indexOf(x.src, 'wrong')) {
            x.status = 'wrong';
          }
        } else if (x.type === 'textbox') {
          // console.log(x);
        }
      });

      canvas.loadFromJSON(jsonData);

      const countBy = _.countBy(jsonData.objects, (item) => {
        if (item.type === 'textbox') return 'unknown';
        return item.status === 'correct' ? 'correct' : 'wrong';
      });
      setResult(countBy);
    }
    setCurrentCanvas(canvas);

    return () => {
      canvas.dispose();
    };
  }, [data, imageUrl]);

  return (
    <div>
      <div style={{ marginBottom: 8, display: 'flex', justifyContent: 'space-between' }}>
        <Space size='large'>
          <Row>
            <Col style={{ fontSize: 18, fontWeight: 600 }}>
              <div style={{ height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <strong style={{ fontSize: 18 }}>{`${i18next.t("Score")}: `}</strong>
              </div>
            </Col>
            <Col>
              <div
                style={{
                  marginLeft: 8,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'green',
                  width: 48,
                  height: 48,
                  borderRadius: '50%',
                }}
              >
                <strong style={{ fontSize: 18, color: 'white' }}>{numeral(getScore(result)).format('0.0')}</strong>
              </div>
            </Col>
          </Row>
          <Row>
            <Col style={{ fontSize: 18, fontWeight: 600 }}>
              <div style={{ height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <strong style={{ fontSize: 18 }}>{i18next.t("Correct") + ": "}</strong>
              </div>
            </Col>
            <Col>
              <div
                style={{ marginLeft: 8, display: 'flex', justifyContent: 'center', alignItems: 'center', height: 48 }}
              >
                <strong style={{ fontSize: 18 }}>{result?.correct ? result?.correct : 0}</strong>
                <span className='tw-mx-2'> / </span>
                <strong style={{ fontSize: 18 }}>{numeral(getTotal(result)).format('0')}</strong>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <img src='/images/icons/correct.png' style={{ width: 32 }} alt='correct' />
            </Col>
            <Col>
              <div style={{ marginLeft: 4 }}>
                <strong style={{ fontSize: 18 }}> {result?.correct ? result.correct : 0}</strong>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src='/images/icons/wrong.png' style={{ width: 32 }} alt='correct' />
            </Col>
            <Col>
              <div style={{ marginLeft: 4 }}>
                <strong style={{ fontSize: 18 }}> {result?.wrong ? result.wrong : 0}</strong>
              </div>
            </Col>
          </Row>
        </Space>
        <Space>
          <Button
            type='dashed'
            danger
            onClick={() => {
              if (currentCanvas) {
                const ao = currentCanvas.getActiveObject();

                if (ao && ao.type === 'textbox') {
                  currentCanvas.remove(ao);
                  setCurrentCanvas(currentCanvas);
                  // SAVE
                  const jsonData = currentCanvas.toDatalessJSON();
                  const countBy = _.countBy(currentCanvas.getObjects(), (item) => {
                    if (item.type === 'textbox') return 'unknown';
                    return item.status === 'correct' ? 'correct' : 'wrong';
                  });
                  executeWithoutModuleId('[p_SACHSO_SHARE_Attachments_AZOTA_Update]', {
                    attachmentId: data.id,
                    jsonData,
                    score: getScore(countBy),
                    totalQuestion: 0,
                    createdBy,
                    type: 'Note',
                  }).then((response) => {
                    // console.log(response);
                  });
                }
              }
            }}
          >
            {i18next.t("slide:NewViewer.EssayResult.NoteResult.ImageAzota.Delete note")}
          </Button>

          <Button
            type='primary'
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              if (currentCanvas) {
                // const allObjects = currentCanvas.getObjects();
                // allObjects.forEach((x) => {
                //   currentCanvas.remove(x);
                // });

                setResult(0);

                const jsonData = currentCanvas.toDatalessJSON();
                jsonData.objects = [];
                currentCanvas.loadFromJSON(jsonData);
                setCurrentCanvas(currentCanvas);
                executeWithoutModuleId('[p_SACHSO_SHARE_Attachments_AZOTA_Update]', {
                  attachmentId: data.id,
                  jsonData,
                  score: 0,
                  totalQuestion: 0,
                  createdBy,
                  type: 'Note',
                }).then((response) => {
                  // console.log(response);
                });
              }
            }}
          >
            {i18next.t("slide:NewViewer.EssayResult.NoteResult.ImageAzota.Delete all")}

          </Button>
        </Space>
      </div>
      <div style={{ display: 'flex', padding: 0, border: '1px dashed gray' }}>
        <div style={{ flex: 1, overflow: 'auto', width: '100%', height: window.innerHeight * 0.75 }}>
          <canvas id={HTMLCanvasElement} />
        </div>
      </div>
    </div>
  );
}

export default WriteScoreCanvasWithinImage;
