import React from 'react';
import { Button, Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { normFile, PropsUploadFile } from '../Modules/UploadFile';

const ButtonUpload = ({ fieldC }) => {
  const { props, fileList } = PropsUploadFile();

  return (
    <div>
      <Form.Item {...fieldC} name={[fieldC.name, 'image']} getValueFromEvent={normFile}>
        <Upload listType='picture' multiple={false} {...props}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>
    </div>
  );
};

export default ButtonUpload;
