import { Modal } from 'antd';
import { get } from 'helpers/QueryHelper';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import Footer from '../Footer';
import { KeyLecture, PresentationProps } from '../types';
import Viewer from '../Viewer';
import tableData from './tableData';

const getPresentations = () => (): Promise<any> => {
  const parameters = {};
  const fields = '*';
  const orderBy = ['CreatedDate DESC'].join(',');
  return get('Presentations', fields, parameters, orderBy, '', 'SACHSO');
};

type RefSubmitProps = {
  submit: Function;
};

const Student = () => {
  // Xử lý link.
  const history = useHistory();
  const params = useParams<{ presentationId: string }>();

  // Xử lý đóng mở, hiển thị ở vị trí.
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // Xử lý thông tin bài giảng.
  const [presentation, setPresentation] = useState<PresentationProps>(null);
  const [title, setTitle] = useState<string>('');
  const [type, setType] = useState<KeyLecture>('None');

  // Xử lý submit.
  const refSubmit = useRef<RefSubmitProps>({} as RefSubmitProps);

  // Xử lý lấy dữ liệu.
  const KEY_PRESENTATIONS = ['t_SACHSO_Presentations'];
  const { data, isLoading } = useQuery<PresentationProps[]>(KEY_PRESENTATIONS, getPresentations());

  // Mục đích để lấy presentationId, dùng cho Canvas ExercisePage
  // history.push trong onRow đã chuyển link, nhưng đây là popup
  // nên kiểm tra thấy có params trên link, là bật popup
  useEffect(() => {
    if (params.presentationId) {
      setOpenModal(true);
      const result = data.find((x) => x.id === params.presentationId);
      setPresentation(result);
    }
  }, [data, params.presentationId]);

  const onRow = (record: PresentationProps, rowIndex: number) => {
    return {
      onClick: () => {
        // history.push(`/lms/student/presentation/${record.id}`);
        // setOpenModal(true);
        setPresentation(record);
      },
    };
  };

  return (
    <div>
      <DynamicTable
        loading={isLoading}
        dataSource={data}
        initialTableData={tableData}
        onRow={onRow}
        onReload={undefined}
        pagination={false}
      />

      <Modal
        width='90%'
        centered
        visible={openModal}
        title={presentation?.title ?? 'Bài giảng'}
        destroyOnClose // Unmount child components on onClose.
        onCancel={() => {
          setOpenModal(false);
          setCurrentIndex(0);
          // history.push('/lms/student/presentation');
        }}
        footer={
          <Footer
            presentation={presentation}
            currentIndex={currentIndex}
            total={!isLoading ? data?.length - 1 : 0}
            mode='VIEW'
            setMode={null}
            setCurrentIndex={setCurrentIndex}
            title={title}
            onSubmit={() => refSubmit.current?.submit()}
            type={type}
          />
        }
      >
        <Viewer
          presentationId={presentation?.id}
          presentationIndex={currentIndex}
          getTitle={setTitle}
          refWorkSheetSubmit={refSubmit}
          getType={setType}
        />
      </Modal>
    </div>
  );
};

export default Student;
