import { Empty } from 'antd';
import settings from 'constants/settings';
import i18next from 'i18next';

type Props = {
  description: string;
};

export default function NoData({ description = i18next.t('CommingSoon') }: Props) {
  return (
    <div style={{ display: 'flex', height: 240, justifyContent: 'center', alignItems: 'center' }}>
      <Empty description={description} />
    </div>
  );
}
