import { query } from './QueryHelper';

//Gửi thông báo đến một user
export function sendNotificationToUser(params: {
  title: string;
  body: string; // HTML string
  classId: number | 0;
  avatar: string;
  name: string;
  icon: string;
  color: string;
  createdBy: number;
  receiverId: number;
  type: 0 | 1 | 2 | 3; //0: Common , 1: Support, 2: Booking, 3: Other
}) {
  return new Promise((resolve, reject) => {
    query('p_QUIZCITY_Notification_ToUser', params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//Gửi thông báo đến nhiều users (theo 1 role / Tất cả)
export function sendNotificationToUsersWithRole(params: {
  receiver: 'student' | 'teacher' | 'manager' | 'admin' | 'all'; // Các users được nhận thông báo
  title: string; // tiêu đề
  body: string; // nội dung thông báo HTML string
  classId: number; // lớp học, mặc định là 0
  icon: string; // icon, mặc định là ''
  color: string; // Màu của icon chuông
  createdBy: number; // người tạo thông báo
  name: string; // tên người tạo thông báo
  avatar: string; // ảnh người gửi trên thông báo
  type: 0 | 1 | 2 | 3; //0: Common , 1: Support, 2: Booking, 3: Other
}) {
  return new Promise((resolve, reject) => {
    query('p_VIMARU_Notification_Insert', params)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
