import { Button, Card, Col, Form, Input, Row, Space } from 'antd';
import Container from 'components/Containers/Container';
import NoData from 'components/NoData';
import  { IS_LG, studentPronouns1 } from 'constants/settings';
import vimaruColors from 'constants/vimaru';
import { useStudentsOrStudentByClassId } from 'modules/Teacher/api/getStudentsOrStudentByClassId';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { CardBody } from 'reactstrap';
import EnterModal from './EnterModal';
import tableData from './tableData';
import { paginationTable } from 'constants/Pagination';

const Students = () => {
  const { classId } = useParams();
  const [parameters, setParameters] = useState({
    pageSize: 10,
    pageNumber: 1,
    search: '',
  });
  const history = useHistory();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [visibleEnterModal, setVisibleEnterModal] = useState(false);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const { data, isLoading } = useStudentsOrStudentByClassId({
    classId,
    search: parameters.search,
    pageNum: parameters.pageNumber,
    pageSize: parameters.pageSize,
  });

  const totalRows = data?.[0]?.totalRows ?? 0;

  const extraColumns = [
    {
      key: 'action-delete',
      width: '10%',
      sort: false,
      render: (text, record, index) => {
        const handleClick = () => {
          setSelectedStudent(record);
          setVisibleEnterModal(true);
        };
        return (
          <p
            style={{ color: vimaruColors.tone1, fontWeight: 'bold', textAlign: 'center', cursor: 'pointer', margin: 0 }}
            onClick={() => handleClick(record?.id)}
          >
            Nhập điểm thi
          </p>
        );
      },
    },
  ];

  const onSearch = (value) => {
    setParameters((pre) => ({ ...pre, search: value?.searchText ?? '' }));
  };
  const title = (
    <Space align='center'>
      <Button
        type='text'
        icon={<MdArrowBack size={24} />}
        onClick={() => {
          let router = window.location.pathname;
          router = router.split('/');
          router = router.filter((item, index) => item !== '' && index < router.length - 1);
          let targetRouter = '';
          router.forEach((item) => (targetRouter += '/' + item));
          history.push(targetRouter);
        }}
      />
      <div style={{ alignItem: 'center' }}>DANH SÁCH SINH VIÊN</div>
    </Space>
  );
  return (
    <React.Fragment>
      <Container>
        <Card title={title}>
          <CardBody>
            <Form layout='inline' onFinish={onSearch}>
            <Form.Item name='searchText' style={{ width:'100vw', maxWidth:250}}>
              <Input
                placeholder={
                  IS_LG ? `Student's code / Student's name` : `Mã ${studentPronouns1} / Tên ${studentPronouns1}`
                }
                allowClear
              />
            </Form.Item>
              <Form.Item>
                <DynamicButton type='primary' htmlType='submit'>
                  Tìm kiếm
                </DynamicButton>
              </Form.Item>
            </Form>
            <br/>
            {data?.length === 0 ? (
              <NoData></NoData>
            ) : (
              <DynamicTable
                loading={isLoading}
                dataSource={data}
                initialTableData={tableData}
                onReload={undefined}
                pagination={paginationTable({
                  pageSize: parameters.pageSize,
                  pageNumber: parameters.pageNumber,
                  totalRows: totalRows,
                  onChange: (page, pageSize) => {
                    setParameters({ pageNumber: page, pageSize: pageSize });
                  },
                })}
                extraColumns={extraColumns}
                scroll={{ x: 1800 }}
              />
            )}

            <div style={{ display: 'flex', justifyContent: 'end', fontSize: 14 }}>
              <i>
                Giải thích: "<strong>ĐTGK</strong>" - Điểm thi giữa kì; "<strong>ĐTCK</strong>" - Điểm thi cuối kì.
              </i>
            </div>
          </CardBody>
        </Card>
      </Container>
      {selectedStudent && visibleEnterModal && (
        <EnterModal
          student={selectedStudent}
          visibleEnterModal={visibleEnterModal}
          setVisibleEnterModal={setVisibleEnterModal}
          classId={classId}
          teacherId={loggedInUser?.id}
        />
      )}
    </React.Fragment>
  );
};

Students.propTypes = {
  classId: PropTypes.string,
};

Students.defaultProps = {
  classId: '',
};

export default Students;
