/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Button, Card, Modal, Row, Col, Table, Form, Input, InputNumber } from 'antd';

import Loading from 'components/Loading';
import ReportHelper from 'helpers/ReportHelper';
import { execute } from 'helpers/QueryHelper';
import DynamicButton from 'packages/DynamicButton/DynamicButton';

const layout = null;

const DetailsModal = ({ params, record, text }) => {
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const [form] = Form.useForm();

  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState([]);

  const openModal = () => {
    setVisible(true);
    setLoading(true);

    const parameters = {};

    params.parameters.forEach((p) => {
      parameters[p] = record[p];
    });

    ReportHelper.Query(params.sqlCommand, parameters, params.appName).then((data) => {
      setResults(data);
      // console.log(data);
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <div
        style={{ width: '100%', fontWeight: 700, whiteSpace: 'nowrap', cursor: 'pointer', color: '#1890ff' }}
        onClick={openModal}
      >
        Chấm điểm
      </div>
      <Modal
        centered
        width='960px'
        title='Chấm điểm TOEIC Speaking'
        visible={visible}
        // onCancel={() => {
        //   setVisible(false);
        // }}
        footer={[
          <DynamicButton
            type='primary'
            key='submit'
            onClick={() => {
              form.submit();
            }}
          >
            Lưu thông tin
          </DynamicButton>,
          <DynamicButton
            type='default'
            key='close-modal'
            onClick={() => {
              setVisible(false);
            }}
          >
            Đóng
          </DynamicButton>,
        ]}
      >
        {loading ? (
          <Loading />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '75vh' }}>
            <div style={{ flex: 1, overflowX: 'auto', paddingRight: 12 }}>
              {results.map((x, index) => {
                return (
                  <Card
                    key={`question-${index}`}
                    size='small'
                    title={<div dangerouslySetInnerHTML={{ __html: x.questionHeader }}></div>}
                    style={{ width: '100%', marginBottom: 12 }}
                  >
                    <Row gutter={[12, 12]}>
                      <Col md={16} xs={24}>
                        <div dangerouslySetInnerHTML={{ __html: x.questionContent }} />
                        <div>
                          {x.imageUrl && <img src={`https://ames.edu.vn${x.imageUrl}`} style={{ width: '100%' }} />}
                        </div>
                      </Col>
                      <Col md={8} xs={24}>
                        <audio controls style={{ width: '100%' }}>
                          <source src={x.answer} type='audio/mpeg' />
                        </audio>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
            </div>
            <div style={{ paddingTop: 12 }}>
              <Card title='Giáo viên nhập điểm & nhận xét' size='small'>
                <Form
                  {...layout}
                  form={form}
                  name='basic'
                  initialValues={{}}
                  onFinish={(values) => {
                    // console.log(params);

                    const parameters = {
                      enquiryID: record.enquiryID,
                      speaking: values.speaking,
                      comment: values.comment,
                      nguoiChamDiem: loggedInUser.email,
                    };

                    // console.log(parameters);
                    execute('p_AMES_TEST_SET_ToeicSpeakingAndTotalScore', parameters, 'Office')
                      .then((data) => {
                        setVisible(false);
                      })
                      .catch((error) => {
                        alert('Có lỗi khi lưu thông tin. Liên hệ AMES - IT để được hỗ trợ');
                      });
                  }}
                  // onFinishFailed={(onFinishFailed)}
                >
                  <Form.Item
                    hasFeedback
                    name='speaking'
                    rules={[
                      {
                        required: true,
                        message: 'Please input speaking score!',
                      },
                    ]}
                  >
                    <InputNumber min={0} max={200} placeholder='Input speaking score' style={{ width: '100%' }} />
                  </Form.Item>

                  <Form.Item
                    hasFeedback
                    name='comment'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your comment!',
                      },
                    ]}
                  >
                    <Input.TextArea placeholder='Input your comment' />
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export const renderForm_LMS_ChamDiemToeicSpeaking = (params) => (text, record, index) => {
  return <DetailsModal params={params} record={record} text={text} index={index} />;
};
