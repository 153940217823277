/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Card, CardBody, CardHeader } from 'reactstrap';
import QuestionHeader from '../QuestionHeader';
import { QuestionComponentProps, QuestionComponentRef } from '../type';
import './styles.css';

const Matching = (
  {
    data,
    userData,
    onSubmit,
    questionIndex = 0,
    preview = false,
    showUserData = false,
    onSubmitWhenCancel,
    isChildQuestion,
  }: QuestionComponentProps,
  ref: QuestionComponentRef,
) => {
  const [shuffedItems, setShuffedItems] = React.useState<any[]>([]);

  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
    onSubmitWhenCancel() {
      handleSubmitOnCanceling();
    },
  }));

  const refAudio = React.useRef<any>();
  refAudio.current = new Audio();

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    height: 100,
    backgroundColor: isDragging ? '#ced4da' : '#f4f5f7',
    borderRadius: '0.25rem',
    border: '1px dashed #adb5bd',
    marginBottom: 24,
    paddingLeft: 12,
    paddingRight: 12,
    fontWeight: 600,
    color: '#172b4d',
    cursor: 'move',
    ...draggableStyle,
  });

  const getListStyle = () => ({
    overflow: 'auto',
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(shuffedItems, result.source.index, result.destination.index);
    setShuffedItems(items);
  };

  React.useEffect(() => {
    if (userData && showUserData) {
      setShuffedItems(userData.answers);
    } else {
      const items = _.shuffle(data.answers).map((item, index) => {
        return {
          id: `${index + 1}`,
          content: item.answer,
        };
      });
      setShuffedItems(items);
    }

    if (preview) {
      const items = data.answers.map((item: any, index: number) => {
        return {
          id: `${index + 1}`,
          content: item.answer,
        };
      });
      setShuffedItems(items);
    }
  }, []);
  // }, [data.answers, userData]);

  // TRả về ảnh
  const renderImageItems = () => {
    return data.answers.map((item: any, index: number) => {
      if (item.imageUrl === null || item.imageUrl === undefined || item.imageUrl === '') {
        return null;
      }
      return (
        <div key={`image-${index}`} style={{ width: 'auto' }}>
          <img
            alt='...'
            src={item.imageUrl}
            style={{ height: 100, borderRadius: 10, marginBottom: 24, display: 'block' }}
          />
        </div>
      );
    });
  };
  // TRả về file nhạc
  const renderAudioItems = () => {
    return data.answers.map((item: any, index: any) => {
      if (item.audioUrl === null || item.audioUrl === undefined || item.audioUrl === '') {
        return null;
      }
      return (
        <div
          key={`item-${index}`}
          style={{
            userSelect: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 100,
            width: 120,
            marginBottom: 20,
            background: '#f4f5f7',
            color: '#172b4d',
            borderRadius: '0.25rem',
            border: '1px solid #adb5bd',
            cursor: 'pointer',
          }}
          onClick={() => {
            // let id = userData.length !== 0 ? userData[index].id : index
            const selected = data.answers[index];

            // play audio
            refAudio.current.pause();
            refAudio.current.currentTime = 0;
            refAudio.current.src = selected.audioUrl;
            refAudio.current.play();
          }}
        >
          <i className='fas fa-volume-up' style={{ fontSize: '20px', cursor: 'pointer' }} />
        </div>
      );
    });
  };
  const handleAnswersNResult = () => {
    const userAnswers = shuffedItems.map((x) => {
      return x.content;
    });

    const correctAnswers = data.answers.map((y: any, j: number) => {
      return y.answer;
    });
    const result = _.isEqual(userAnswers, correctAnswers);
    return {
      answers: shuffedItems,
      result,
    };
  };
  const handleSubmitOnCanceling = () => {
    const { answers, result } = handleAnswersNResult();
    onSubmitWhenCancel?.({ id: data.id, answers, result }, data);
  };
  const handleSubmit = () => {
    const { answers, result } = handleAnswersNResult();
    onSubmit?.({ id: data.id, answers, result }, data);
  };

  return (
    <Card>
      {isChildQuestion ? null : (
        <CardHeader>
          <QuestionHeader title={data.title} subTitle={data.subTitle} questionIndex={questionIndex} />
        </CardHeader>
      )}

      <CardBody>
        {data.questionText ? <div className='questionText'>{data.questionText}</div> : null}
        <div className='d-flex'>
          <div style={{ marginRight: 20 }}>
            {renderImageItems()}
            {renderAudioItems()}
          </div>
          <div style={{ maxWidth: '100%' }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
                    {shuffedItems &&
                      shuffedItems.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(p, s) => (
                            <div
                              ref={p.innerRef}
                              {...p.draggableProps}
                              {...p.dragHandleProps}
                              style={getItemStyle(s.isDragging, p.draggableProps.style)}
                            >
                              {item.content}
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
export default React.forwardRef(Matching);
