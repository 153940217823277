/* eslint-disable react/prop-types */
import withAuthenticator from 'HOCs/withAuthenticator';
import { execute, query } from 'helpers/QueryHelper';
import DynamicForm from 'packages/DynamicForm/components/DynamicForm';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import initialFormData from './formData';

const fetchCourse = () => {
  return query('p_AMES247_Course_Select');
};

const CreateClass = ({ loggedInUser }) => {
  const key = ['p_VIMARU_CLASSES_GetClasses'];
  const queryClient = useQueryClient();

  // Redux
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState(null);
  const { data: courses } = useQuery('p_AMES247_Course_Select', () => fetchCourse());
  React.useEffect(() => {
    const courseOptions = courses?.map((course) => ({ label: course?.title, value: course?.id }));
    const cloneFormData = { ...initialFormData };
    query('p_SACHSO_METADATA_NienKhoa_List').then((res) => {
      if (res?.length > 0) {
        const schoolYearOptions = res?.map((year) => ({ label: year?.name, value: year?.id }));
        cloneFormData.formFields[3].inputComponent.props.options = schoolYearOptions;
      }
    });
    cloneFormData.formFields[2].inputComponent.props.options = courseOptions;
    setFormData({ ...cloneFormData });
  }, [courses]);

  return (
    <div>
      {/* --------------------------------------------------------------------------------------------- */}
      {/* FORM */}
      {/* --------------------------------------------------------------------------------------------- */}
      <DynamicForm
        loading={loading}
        initialFormData={formData}
        onSubmit={async (parameters) => {
          setLoading(true);
          await execute(
            formData.storedProcedure,
            {
              ...parameters,
              teacherId: loggedInUser.id,
              nienKhoaId: parameters.nienKhoaId ?? '00000000-0000-0000-0000-000000000000',
            },
            formData.appName,
          );

          setLoading(false);
          // reload data
          queryClient.invalidateQueries(key);
          // reset form
          setFormData({ ...initialFormData });
          // eslint-disable-next-line no-undef
          // window.location.reload();
        }}
      />
    </div>
  );
};
export default withAuthenticator(CreateClass);
