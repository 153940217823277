/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Card, Row, Col, CardBody, CardFooter, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import QuestionHeader from '../QuestionHeader';
import AudioButton from '../AudioButton';
import './styles.css';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 60,
  minWidth: 40,
  padding: 8,
  marginRight: 8,
  fontWeight: 600,
  fontSize: 18,
  background: isDragging ? '#ced4da' : '#f4f5f7',
  color: '#172b4d',
  borderRadius: '0.25rem',
  border: '1px dashed #adb5bd',
  ...draggableStyle,
});

const Unscramble = ({ data, onSubmit, userData, questionIndex = 0, ExplainText }) => {
  const [shuffedItems, setShuffedItems] = React.useState([]);
  const [correctItems, setCorrectItems] = React.useState([]);

  const getListStyle = (isDraggingOver) => ({
    display: 'flex',
    overflow: 'auto',
    padding: 0,
  });

  const ref = React.useRef();
  ref.current = new Audio();
  const playAudio = React.useCallback((audioUrl) => {
    ref.current.src = audioUrl;
    ref.current.play();
  }, []);

  const onDragEnd = (result) => {};

  React.useEffect(() => {
    // SETTINGS
    let shuffedType = data.settings && data.settings.question && data.settings.question.shuffedType;
    if (shuffedType === null || shuffedType === undefined) {
      shuffedType = 'word';
    }

    let splitCharacter = ' ';
    if (shuffedType === 'character') {
      splitCharacter = '';
    }

    // SHUFFER
    if (userData) {
      const correct = data.questionText
        .trim()
        .split(splitCharacter)
        .map((item, index) => {
          return {
            id: `item-${index}`,
            content: item,
          };
        });
      setCorrectItems(correct);
      const items = userData.answers
        .trim()
        .split(splitCharacter)
        .map((item, index) => {
          return {
            id: `item-${index}`,
            content: item,
          };
        });
      setShuffedItems(items);
    } else {
      let shuffle = _.shuffle(data.question.trim().split(splitCharacter));
      // Nếu trùng với đáp án thì xáo lại.
      shuffle =
        _.join(shuffle, splitCharacter) === data.answer
          ? _.shuffle(data.question.trim().split(splitCharacter))
          : shuffle;
      const items = shuffle.map((item, index) => {
        return {
          id: `item-${index}`,
          content: item,
        };
      });
      setShuffedItems(items);
    }
  }, [data.answer, data.question, data.settings, userData]);

  return (
    <Card>
      <CardHeader>
        <QuestionHeader
          title={data.title}
          subTitle={data.subTitle}
          questionIndex={questionIndex}
          tooltip='Unscramble'
        />
      </CardHeader>
      <CardBody style={{ minHeight: 300 }}>
        <AudioButton audioUrl={data.audioUrl} />

        <div>
          {data.imageUrl && (
            <div className='mb-4'>
              <img src={data.imageUrl} alt='' style={{ maxWidth: 300 }} />
            </div>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable' direction='horizontal'>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                  {shuffedItems.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(p, s) => (
                        <div
                          ref={p.innerRef}
                          {...p.draggableProps}
                          {...p.dragHandleProps}
                          style={getItemStyle(s.isDragging, p.draggableProps.style)}
                        >
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </CardBody>
      {ExplainText}
      <CardFooter>
        <h3>Đáp án đúng</h3>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                {correctItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(p, s) => (
                      <div
                        ref={p.innerRef}
                        {...p.draggableProps}
                        {...p.dragHandleProps}
                        style={getItemStyle(s.isDragging, p.draggableProps.style)}
                      >
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </CardFooter>
    </Card>
  );
};

Unscramble.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  userData: PropTypes.any,
  questionIndex: PropTypes.number,
};

Unscramble.defaultProps = {
  onSubmit: null,
  userData: null,
  questionIndex: 0,
};

export default Unscramble;
