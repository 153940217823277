import i18next from 'i18next';

export const genderOptions = [
  // { label: '[Chọn giới tính]', value: 0 },
  { label: i18next.t('male'), value: 1 },
  { label: i18next.t('female'), value: 0 },
];
export const schoolTypeOptions = [
  // { label: '[Chọn cấp dạy]', value: 0 },
  { label: 'Giáo viên Mầm Non', value: 0 },
  { label: 'Giáo viên Cấp 1', value: 1 },
  { label: 'Giáo viên Cấp 2', value: 2 },
  { label: 'Giáo viên Cấp 3', value: 3 },
];
//
export const classOptions = [
  // { label: '[Chọn lớp]', value: 0 },
  { label: 'Lớp 1', value: 1 },
  { label: 'Lớp 2', value: 2 },
  { label: 'Lớp 3', value: 4 },
  { label: 'Lớp 4', value: 8 },
  { label: 'Lớp 5', value: 7 },
  { label: 'Lớp 6', value: 10 },
  { label: 'Lớp 7', value: 11 },
  { label: 'Lớp 8', value: 12 },
  { label: 'Lớp 9', value: 13 },
  { label: 'Lớp 10', value: 14 },
  { label: 'Lớp 11', value: 15 },
  { label: 'Lớp 12', value: 16 },
];
//
export const cityOptions = [
  { value: 0, label: '[Chọn tỉnh / thành]' },
  { value: 2, label: 'Hà Nội' },
  { value: 1, label: 'TP. Hồ Chí Minh' },
  { value: 30, label: 'An Giang' },
  { value: 10, label: 'Bà Rịa Vũng Tàu' },
  { value: 28, label: 'Bắc Giang' },
  { value: 62, label: 'Bắc Kạn' },
  { value: 55, label: 'Bạc Liêu' },
  { value: 17, label: 'Bắc Ninh' },
  { value: 37, label: 'Bến Tre' },
  { value: 25, label: 'Bình Định' },
  { value: 4, label: 'Bình Dương' },
  { value: 23, label: 'Bình Phước' },
  { value: 13, label: 'Bình Thuận' },
  { value: 39, label: 'Cà Mau' },
  { value: 12, label: 'Cần Thơ' },
  { value: 63, label: 'Cao Bằng' },
  { value: 3, label: 'Đà Nẵng' },
  { value: 11, label: 'Đắk Lắk' },
  { value: 38, label: 'Đắk Nông' },
  { value: 58, label: 'Điện Biên' },
  { value: 5, label: 'Đồng Nai' },
  { value: 47, label: 'Đồng Tháp' },
  { value: 22, label: 'Gia Lai' },
  { value: 61, label: 'Hà Giang' },
  { value: 45, label: 'Hà Nam' },
  { value: 46, label: 'Hà Tĩnh' },
  { value: 21, label: 'Hải Dương' },
  { value: 7, label: 'Hải Phòng' },
  { value: 53, label: 'Hậu Giang' },
  { value: 29, label: 'Hòa Bình' },
  { value: 24, label: 'Hưng Yên' },
  { value: 6, label: 'Khánh Hòa' },
  { value: 16, label: 'Kiên Giang' },
  { value: 49, label: 'Kon Tum' },
  { value: 59, label: 'Lai Châu' },
  { value: 14, label: 'Lâm Đồng' },
  { value: 60, label: 'Lạng Sơn' },
  { value: 34, label: 'Lào Cai' },
  { value: 8, label: 'Long An' },
  { value: 35, label: 'Nam Định' },
  { value: 20, label: 'Nghệ An' },
  { value: 41, label: 'Ninh Bình' },
  { value: 43, label: 'Ninh Thuận' },
  { value: 42, label: 'Phú Thọ' },
  { value: 44, label: 'Phú Yên' },
  { value: 50, label: 'Quảng Bình' },
  { value: 9, label: 'Quảng Nam' },
  { value: 36, label: 'Quảng Ngãi' },
  { value: 18, label: 'Quảng Ninh' },
  { value: 51, label: 'Quảng Trị' },
  { value: 48, label: 'Sóc Trăng' },
  { value: 54, label: 'Sơn La' },
  { value: 32, label: 'Tây Ninh' },
  { value: 27, label: 'Thái Bình' },
  { value: 33, label: 'Thái Nguyên' },
  { value: 19, label: 'Thanh Hóa' },
  { value: 15, label: 'Thừa Thiên Huế' },
  { value: 26, label: 'Tiền Giang' },
  { value: 52, label: 'Trà Vinh' },
  { value: 57, label: 'Tuyên Quang' },
  { value: 40, label: 'Vĩnh Long' },
  { value: 31, label: 'Vĩnh Phúc' },
  { value: 56, label: 'Yên Bái' },
];
