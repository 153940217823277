import colors from 'constants/colors';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';
import settings from 'constants/settings';

export const teacherColumns = {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'File name',
      key: 'categoryName',
      fixed: 'left',
      width: '20%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
    },
    // {
    //   title: 'Meeting link',
    //   key: 'address',
    //   width: '25%',
    //   render: 'renderNoWrap',
    //   style: {
    //     fontWeight: 600,
    //     color: colors.font,
    //   },
    // },
    // {
    //   title: 'Email',
    //   key: 'teacherEmail',
    //   width: '30%',
    //   render: 'renderNoWrap',
    //   style: {
    //     fontWeight: 600,
    //     color: colors.font,
    //   },
    // },
    {
      title: 'File link',
      key: 'imageUrl',
      width: '15%',
      sort: false,
      render: (text) => {
        const copyText = () => {
          navigator.clipboard.writeText(text).then(() => {
            message.success('Copied link!');
          });
        };
        return (
          <div style={{ alignItems: 'center' }}>
            <a href={text} target='_blank' style={{ color: '#c2224f' }}>
              {text.length > 25 ? text.slice(0, 25) + '...' : text}
            </a>
            <span target='_blank'>
              <DynamicButton
                style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }}
                icon={<CopyOutlined />}
                onClick={copyText}
              />
            </span>
          </div>
        );
      },
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Preview',
      key: 'imageUrl',
      render: (text) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={text} style={{ maxWidth: 200 }} />
        </div>
      ),
    },
    {
      title: 'Sort order',
      key: 'sortOrder',
      fixed: 'left',
      width: '5%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
    },
    // {
    //   title: 'Trạng thái',
    //   key: 'status',
    //   width: '10%',
    //   render: 'renderNoWrap',
    //   style: {
    //     fontWeight: 600,
    //     color: colors.font,
    //   },
    // },
    // {
    //   title: 'Ngày tạo',
    //   key: 'CreatedDate',
    //   width: '15%',
    //   sort: false,
    //   render: 'renderDateTime',
    //   prefix: <CalendarOutlined className='tw-mr-2' />,
    // },
  ],
};
