import colors from 'constants/colors';
import Card from 'components/Containers/Card';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Container from 'components/Containers/Container';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import { Button, Col, Form, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import { query } from 'helpers/QueryHelper';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import { teacherColumns } from './tableColumn';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import settings from 'constants/settings';
import DynamicTooltip from 'packages/DynamicTooltip/DynamicTooltip';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const ManagerTeacher = () => {
  const { t } = useTranslation();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [selectRow, setSelectRow] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [dataGiangVien, setDataGiangVien] = React.useState([]);
  const [dataSearch, setDataSearch] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [modalStatus, setModalStatus] = React.useState({
    open: false,
    title: '',
    type: 'add',
  });

  const CourseExtraColumns = [
    {
      key: 'editInfomation',
      render: (text, record) => {
        return (
          <Space>
            <DynamicTooltip placement='top' title={'Update'}>
              <DynamicButton
                //
                key='edit-file'
                shape='circle'
                type='dashed'
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectRow(record);
                  handleShowModal('edit', record);
                }}
              />
            </DynamicTooltip>
            <Popconfirm
              placement='topRight'
              title={() => {
                return (
                  <div>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>
                      {i18next.t('Are you sure to delete', { object: i18next.t('teacher') })}
                    </p>
                    <strong style={{ color: '#f5222d' }}>({i18next.t('popconfirm.unrecoverable')} )</strong>
                  </div>
                );
              }}
              onConfirm={() => onDelete(record.id)}
              okText={t('Confirm')}
              cancelText={t('Close')}
            >
              <DynamicTooltip placement='top' title={'Delete'}>
                <DynamicButton
                  key='edit-file'
                  shape='circle'
                  type='dashed'
                  danger
                  style={{ color: '#f5222d' }}
                  icon={<DeleteOutlined />}
                />
              </DynamicTooltip>
            </Popconfirm>
          </Space>
        );
      },
      sort: false,
      title: 'Action',
      width: '10%',
    },
  ];
  React.useEffect(() => {
    query('p_QUIZCITY_Teacher_Category_Select')
      .then((res) => {
        setDataGiangVien(res);
      })
      .catch((error) => {});
  }, [refresh]);

  const handleShowModal = (type, record) => {
    form.setFieldsValue(record);
    setModalStatus({
      open: true,
      // title: type === 'add' ? 'ADD NEW TEACHER' : "UPDATE TEACHER'S INFORMATION",
      title:
        type === 'add'
          ? i18next.t('addNew', { field: i18next.t('teacher') }).toUpperCase()
          : i18next.t('updateObject', { object: i18next.t('TeacherInformation') }).toUpperCase(),

      type,
    });
  };

  const onInsert = (value) => {
    setLoading(true);
    const { code, categoryName, address } = value;
    query('p_QUIZCITY_Teacher_Category_InsertUpdate', {
      code: code.trim().replace(/\s+/g, ' '),
      createdBy: loggedInUser.id,
      id: '00000000-0000-0000-0000-000000000000',
      parentId: '00000000-0000-0000-0000-000000000000',
      address: address.trim().split(' ').join(''),
      categoryName: categoryName.trim().replace(/\s+/g, ' '),
    })
      .then((res) => {
        message.success(i18next.t('add success', { field: i18next.t('teacher') }));
        setRefresh(!refresh);
        setModalStatus({ open: false });
      })
      .catch((error) => {
        message.error(i18next.t('add failed', { field: i18next.t('teacher') }));
      })
      .finally(() => setLoading(false));
  };

  const onUpdate = (value) => {
    setLoading(true);
    const { parentId, id } = selectRow;
    const { code, categoryName, address } = value;
    query('p_QUIZCITY_Teacher_Category_InsertUpdate', {
      code: code.trim().replace(/\s+/g, ' '),
      createdBy: loggedInUser.id,
      id,
      parentId: parentId || '00000000-0000-0000-0000-000000000000',
      address: address.trim().split(' ').join(''),
      categoryName: categoryName.trim().replace(/\s+/g, ' '),
    })
      .then((res) => {
        message.success(i18next.t('update success', { field: i18next.t('TeacherInformation') }));
        setRefresh(!refresh);
        setModalStatus({ open: false });
      })
      .catch((error) => {
        message.error(i18next.t('update failed', { field: i18next.t('TeacherInformation') }));
      })
      .finally(() => setLoading(false));
  };

  const onDelete = (id) => {
    const status = true;
    if (status) {
      query('p_QUIZCITY_Teacher_Category_Delete', { teacherId: id })
        .then((res) => {
          const { message: messageRes, data } = res[0];
          if (messageRes === '') {
            message.success(i18next.t('delete success', { field: i18next.t('teacher') }));
            setRefresh(!refresh);
          } else {
            Modal.warning({
              title: i18next.t('cantdelete'),
              content: (
                <>
                  {data && <p>{'The teacher is in class: ' + data.map((item) => item?.className).toString() + '.'}</p>}

                  <div>{i18next.t('pleaseRemove')}</div>
                </>
              ),
              // okButtonProps: { className: settings.IS_LG ? 'modal-btn' : '' },
            });
          }
        })
        .catch((error) => {
          message.error(i18next.t('delete failed', { field: i18next.t('teacher') }));
        });
    }
  };

  const handleSubmit = (type, value) => {
    if (type === 'add') onInsert(value);
    else onUpdate(value);
  };
  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const handleSearch = (s) => {
    if (s.searchByName || s.searchByCode) {
      setDataSearch(
        dataGiangVien
          .filter((item) => {
            const codeContainsSearch =
              !s.searchByCode ||
              removeAccents(item.code.toLowerCase()).includes(removeAccents(s.searchByCode.toLowerCase().trim()));
            return codeContainsSearch;
          })
          .filter((item) => {
            const categoryNameContainsSearch =
              !s.searchByName ||
              removeAccents(item.categoryName.toLowerCase()).includes(
                removeAccents(s.searchByName.toLowerCase().trim()),
              );
            return categoryNameContainsSearch;
          }),
      );
    } else {
      setDataSearch();
    }
  };
  const validateContent = (rule, value, callback) => {
    if (value.trim() === '') {
      callback('Please enter Code');
    } else {
      callback();
    }
  };

  return (
    <div>
      <Container name='Teacher Category' parentName='Administration'>
        <Card
          title={i18next.t('TeacherCategory')}
          extra={
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', columnGap: 10 }}>
              <DynamicButton
                onClick={() =>
                  handleShowModal('add', {
                    code: GenPasswordModel(1, 6),
                    categoryName: '',
                    address: '',
                  })
                }
              >
                <PlusOutlined /> {i18next.t('addNew', { field: i18next.t('teacher') })}
              </DynamicButton>
            </div>
          }
        >
          <Form form={searchForm} onFinish={(s) => handleSearch(s)}>
            <div style={{ display: 'flex' }}>
              <Form.Item
                name='searchByCode'
                label={i18next.t('searchBy', { field: i18next.t('code') })}
                style={{ marginInline: 3 }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='searchByName'
                label={i18next.t('searchBy', { field: i18next.t('name') })}
                style={{ marginInline: 3 }}
              >
                <Input />
              </Form.Item>
              <DynamicButton style={{ border: 'none', marginInline: 3 }} type='primary' htmlType='submit'>
                {i18next.t('search')}
              </DynamicButton>
            </div>
          </Form>
          <DynamicTable
            // loading
            dataSource={dataSearch ?? dataGiangVien}
            initialTableData={teacherColumns}
            extraColumns={CourseExtraColumns}
            className='components-table-demo-nested'
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '50', '100'],
            }}
            expandIconColumnIndex={5}
            expandIconAsCell={false}
          />
        </Card>
      </Container>

      <Modal
        title={modalStatus.title}
        onOk={() => {
          form.submit();
        }}
        open={modalStatus.open}
        onCancel={() => setModalStatus({ open: false })}
        footer={[
          <DynamicButton htmlType='button' onClick={() => setModalStatus({ open: false })}>
            {i18next.t('Close')}
          </DynamicButton>,
          <DynamicButton
            style={{ border: 'none' }}
            type='primary'
            onClick={(value) => form.submit(value)}
            loading={loading}
          >
            {i18next.t('Save')}
          </DynamicButton>,
        ]}
      >
        <Form form={form} onFinish={(value) => handleSubmit(modalStatus.type, value)} layout='vertical'>
          {/* <Form.Item name='teacherCode' label='Mã giảng viên' rules={[{ required: true, message: 'Chưa nhập' }]}>
            <Input disabled />
          </Form.Item> */}
          <Form.Item
            name='code'
            label="Teacher's code"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (value.trim() === '')
                    return Promise.reject(i18next.t('please enter', { field: i18next.t('code') }));
                  else {
                    const checkDuplicate = dataGiangVien?.some(
                      (item) =>
                        item?.code.trim().toLowerCase().replace(/\s+/g, ' ') ===
                          value.trim().toLowerCase().replace(/\s+/g, ' ') &&
                        (item?.id !== selectRow?.id || modalStatus.type === 'add'),
                    );
                    if (checkDuplicate) return Promise.reject(i18next.t('unavailable', { field: i18next.t('code') }));
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='categoryName'
            label={i18next.t('teacherName')}
            rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('teacherName') }) }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item name='teacherEmail' label='Email' rules={[{ required: true, message: "" }, {validator: validateContent}]}>
            <Input />
          </Form.Item> */}
          <Form.Item
            name='address'
            label='Meeting link'
            rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('linkMeeting') }) }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item name='sortOrder' label='Thứ tự hiển thị' rules={[
            { required: true, message: 'Chưa nhập' },
          ]}>
            <InputNumber min={0} defaultValue={0} />
          </Form.Item> */}
        </Form>
      </Modal>
    </div>
  );
};

export default ManagerTeacher;
