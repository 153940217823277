import { Col, Progress, Row } from 'antd';
import colors from 'constants/colors';
import numeral from 'numeral';
import React from 'react';
import { AiFillCheckCircle, AiFillLock, AiFillPlayCircle, AiFillStar } from 'react-icons/ai';
import { FiCheckSquare, FiEdit, FiFileText, FiVideo } from 'react-icons/fi';

const DrawerTable = ({ currentSlideId, data, setOutlineIndex }: any) => {
  return (
    <Row>
      <Col span={24}>
        <table style={{ textAlign: 'left', width: '100%' }}>
          <thead>
            <tr style={{ height: 50 }}>
              <th>
                <span className='' style={{ color: colors.primary, fontWeight: '600' }}>
                  Tiêu đề
                </span>
              </th>
              <th>
                <span className='' style={{ color: colors.primary, fontWeight: '600' }}>
                  Hoàn thành
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((slide: any, slideIndex: number) => {
              return (
                <tr
                  key={slide.id}
                  style={{ height: '30px' }}
                  className={`'hover__table' ${slide.id === currentSlideId && 'active__table'}`}
                  onClick={() => {
                    setOutlineIndex(slideIndex);
                  }}
                >
                  <td
                    style={{
                      fontSize: '14px',
                      textAlign: 'left',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      cursor: slide.status !== 'Locked' ? 'pointer' : 'no-drop',
                    }}
                  >
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      {slide.status === 'Completed' && (
                        <AiFillCheckCircle
                          color={colors.green}
                          size={20}
                          className='tw-ml-2'
                          style={{ marginRight: '5px' }}
                        />
                      )}
                      {slide.status === 'Locked' && (
                        <AiFillLock
                          color={colors.dimGray}
                          size={20}
                          className='tw-ml-2'
                          style={{ marginRight: '5px' }}
                        />
                      )}
                      {slide.status === 'Playing' && (
                        <AiFillPlayCircle
                          color={colors.gold}
                          size={20}
                          className='tw-ml-2'
                          style={{ marginRight: '5px' }}
                        />
                      )}
                      {slide.type === 'Video' && <FiVideo className='tw-mr-2' />}
                      {slide.type === 'WorkSheet' && <FiEdit className='tw-mr-2' />}
                      {slide.type === 'Word' && <FiFileText className='tw-mr-2' />}
                      {slide.type === 'Quiz' && <FiCheckSquare className='tw-mr-2' />}
                      <div
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          width: 200,
                          wordBreak: 'break-all',
                        }}
                      >
                        <span style={{ color: '#000', cursor: slide.status !== 'Locked' ? 'pointer' : 'no-drop' }}>
                          {slide.title}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: 'center',
                      width: '30%',
                      cursor: slide.status !== 'Locked' ? 'pointer' : 'no-drop',
                    }}
                  >
                    {(slide.type === 'WorkSheet' ||
                      slide.type === 'Quiz' ||
                      (slide.type === 'Video' &&
                        slide.quizAssignmentId !== '914B9045-F740-43C1-9ABB-C21DC4899AB1')) && (
                      <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Col span={15}>
                          <Progress percent={slide.percentCompleted * 100} showInfo={false} />
                        </Col>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: '500',
                            letterSpacing: 1,
                            marginLeft: 5,
                          }}
                        >
                          {`${numeral(slide.percentCompleted).format('%')}`}
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Col>
    </Row>
  );
};

export default DrawerTable;
