import { Form, Input, Spin, message } from 'antd';
import colors from 'constants/colors';
import settings from 'constants/settings';
import { generateSHA1 } from 'helpers/CryptoHelper';
import { amesSendMail, query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import moment from 'moment';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { useEffect, useState } from 'react';

export default function FormEditPassword({ record, cancelModal, templateResetPass, mailer }) {
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const [formUpdatePassword] = Form.useForm(); //* Form Update password

  const colorLabel = (text) => <label style={{ color: colors.font }}>{text}</label>;

  useEffect(() => {
    formUpdatePassword.setFieldsValue({
      email: record.email,
      password: GenPasswordModel(2, 6),
    });
  }, [record]);

  const handleFinishUpdatePassword = (values) => {
    setLoadingResetPassword(true);
    const { email, password } = values;
    const parameters = {
      Email: email,
      Password: generateSHA1(password),
    };
    query('p_VIMARU_UserPassword_RESET', parameters)
      .then((res) => {
        if (res?.[0].res === 1) {
          // *if success  password update => notification success password update to user => Send email new password information to users via
          message.success(`Update email password: ${email} successfully.`);
          const dateNo = moment().format('YYYYMMDDhhmm');
          const dataSendMail = {
            to: email.toLowerCase(),
            isSendFast: true,
            moduleId: settings.MODULE_ID,
            cc: '',
            bcc: '',
            subject: `Thay đổi mật khẩu #${dateNo}`,
            body: '',
            bodyHTML: '',
            attachment: '',
            templateName: templateResetPass,
            sourceInfo: `API Save Email - ${templateResetPass}`,
            templateData: JSON.stringify({
              email: `${email}`,
              password: password,
              dateNo: dateNo,
              studentName: record.fullName,
            }),
            mailer: mailer,
          };
          amesSendMail('saveEmail', dataSendMail).then((responseSendEmail) => {
              message.success(`Password sent via Email: ${parameters.Email}`);
          });
          // *Close Modal
          cancelModal();
        } else {
          //! Error: Update password fail
          return message.warning(i18next.t('update failed', { field: i18next.t('password') }));
        }
      })
      .catch(() => message.error(i18next.t('connectError')))
      .finally(() => setLoadingResetPassword(false));
  };

  return (
    <>
      <Spin spinning={loadingResetPassword}>
        <Form onFinish={handleFinishUpdatePassword} form={formUpdatePassword} layout='vertical' autoComplete='off'>
          <Form.Item name='email' label={colorLabel('Email')}>
            <Input disabled className='input__editInfomation' />
          </Form.Item>
          <Form.Item
            name='password'
            label={colorLabel(i18next.t('password'))}
            rules={[
              {
                required: true,
                message: i18next.t('please enter', { field: i18next.t('password') }),
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', columnGap: 20 }}>
            <DynamicButton key='back' onClick={cancelModal}>
              {i18next.t('Close')}
            </DynamicButton>
            <DynamicButton type='primary' htmlType='submit'>
              {i18next.t('Save')}
            </DynamicButton>
          </div>
        </Form>
      </Spin>
    </>
  );
}
