import colors from 'constants/colors';
import Card from 'components/Containers/Card';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Container from 'components/Containers/Container';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import { Button, Col, Form, Input, InputNumber, message, Modal, Popconfirm, Row, Select, Space, Tooltip } from 'antd';
import { query } from 'helpers/QueryHelper';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import { teacherColumns } from './tableColumn';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import settings from 'constants/settings';
import DynamicTooltip from 'packages/DynamicTooltip/DynamicTooltip';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useQuery, useQueryClient } from 'react-query';
import UploadFiles from 'components/UploadFiles';

const getAttachments = (folderId) => () => {
  if (!folderId) {
    return new Promise((resolve) => {
      resolve([]);
    });
  }
};

const ManagerTOEICExamImage = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [selectRow, setSelectRow] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [dataImage, setDataImage] = React.useState([]);
  const [dataSearch, setDataSearch] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [modalStatus, setModalStatus] = React.useState({
    open: false,
    title: '',
    type: 'add',
  });
  const KEY_SHARE_ATTACHMENTS = ['p_QUIZCITY_ExamImage_Category_Select', '2FAE24D0-E489-422F-BC5E-B35DF08AEE12'];
  const CourseExtraColumns = [
    {
      key: 'editInfomation',
      render: (text, record) => {
        return (
          <Space>
            <DynamicTooltip placement='top' title={'Update'}>
              <DynamicButton
                //
                key='edit-file'
                shape='circle'
                type='dashed'
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectRow(record);
                  handleShowModal('edit', record);
                }}
              />
            </DynamicTooltip>
            <Popconfirm
              placement='topRight'
              title={() => {
                return (
                  <div>
                    <p style={{ fontSize: 14, fontWeight: 400 }}>
                      {i18next.t('Are you sure to delete', { object: i18next.t('Image').toLowerCase() })}
                    </p>
                    <strong style={{ color: '#f5222d' }}>({i18next.t('popconfirm.unrecoverable')} )</strong>
                  </div>
                );
              }}
              onConfirm={() => onDelete(record.id)}
              okText={t('Confirm')}
              cancelText={t('Close')}
            >
              <DynamicTooltip placement='top' title={'Delete'}>
                <DynamicButton
                  key='edit-file'
                  shape='circle'
                  type='dashed'
                  danger
                  style={{ color: '#f5222d' }}
                  icon={<DeleteOutlined />}
                />
              </DynamicTooltip>
            </Popconfirm>
          </Space>
        );
      },
      sort: false,
      title: 'Action',
      width: '10%',
    },
  ];
  React.useEffect(() => {
    query('p_QUIZCITY_ExamImage_Category_Select')
      .then((res) => {
        setDataImage(res);
      })
      .catch((error) => {});
  }, [refresh]);

  const handleShowModal = (type, record) => {
    form.setFieldsValue(record);
    setModalStatus({
      open: true,
      title: type === 'add' ? 'ADD NEW IMAGE' : 'UPDATE IMAGE',
      type,
    });
  };

  const onInsert = (value) => {
    setLoading(true);
    const { sortOrder, categoryName, imageUrl } = value;
    query('p_QUIZCITY_ExamImage_Category_InsertUpdate', {
      code: GenPasswordModel(1, 6),
      createdBy: loggedInUser.id,
      id: '00000000-0000-0000-0000-000000000000',
      parentId: '00000000-0000-0000-0000-000000000000',
      imageUrl,
      categoryName: categoryName.trim().replace(/\s+/g, ' '),
      sortOrder,
    })
      .then((res) => {
        message.success('Added new image successfully!');
        setRefresh(!refresh);
        setModalStatus({ open: false });
      })
      .catch((error) => {
        message.error('Added new image failed!');
      })
      .finally(() => setLoading(false));
  };

  const onUpdate = (value) => {
    setLoading(true);
    const { parentId, id, code } = selectRow;
    const { categoryName, imageUrl, sortOrder } = value;
    query('p_QUIZCITY_ExamImage_Category_InsertUpdate', {
      code,
      createdBy: loggedInUser.id,
      id,
      parentId: parentId || '00000000-0000-0000-0000-000000000000',
      imageUrl,
      categoryName: categoryName.trim().replace(/\s+/g, ' '),
      sortOrder,
    })
      .then((res) => {
        message.success('Update image successfully!');
        setRefresh(!refresh);
        setModalStatus({ open: false });
      })
      .catch((error) => {
        message.error('Update image failed!');
      })
      .finally(() => setLoading(false));
  };

  const onDelete = (id) => {
    const status = true;
    if (status) {
      query('p_QUIZCITY_ExamImage_Category_Delete', { id: id })
        .then((res) => {
          const { message: messageRes, data } = res[0];
          if (messageRes === '') {
            message.success('The image has been deleted successfully!');
            setRefresh(!refresh);
          } else {
            Modal.warning({
              title: i18next.t('cantdelete'),
              content: <></>,
              // okButtonProps: { className: settings.IS_LG ? 'modal-btn' : '' },
            });
          }
        })
        .catch((error) => {
          message.error('Delete failed!');
        });
    }
  };

  const handleSubmit = (type, value) => {
    if (type === 'add') onInsert(value);
    else onUpdate(value);
  };
  const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const handleSearch = (s) => {
    if (s.searchByName || s.searchByCode) {
      setDataSearch(
        dataImage
          .filter((item) => {
            const codeContainsSearch =
              !s.searchByCode ||
              removeAccents(item.code.toLowerCase()).includes(removeAccents(s.searchByCode.toLowerCase().trim()));
            return codeContainsSearch;
          })
          .filter((item) => {
            const categoryNameContainsSearch =
              !s.searchByName ||
              removeAccents(item.categoryName.toLowerCase()).includes(
                removeAccents(s.searchByName.toLowerCase().trim()),
              );
            return categoryNameContainsSearch;
          }),
      );
    } else {
      setDataSearch();
    }
  };
  const validateContent = (rule, value, callback) => {
    if (value.trim() === '') {
      callback('Please enter Code');
    } else {
      callback();
    }
  };
  const formatData = (data) => {
    if (data?.length > 0) {
      const sortArr = _.sortBy(data, ['sortOrder']);
      return sortArr;
    } else return [];
  };

  return (
    <div>
      <Container name='TOEIC Exam Image' parentName='Administration'>
        <Card
          title='TOEIC Exam Image'
          extra={
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', columnGap: 10 }}>
              <DynamicButton
                onClick={() =>
                  handleShowModal('add', {
                    code: GenPasswordModel(1, 6),
                    categoryName: '',
                    sortOrder: '',
                    imageUrl: '',
                  })
                }
              >
                <PlusOutlined /> {i18next.t('addNew', { field: i18next.t('Image').toLowerCase() })}
              </DynamicButton>
            </div>
          }
        >
          {/* <Form form={searchForm} onFinish={(s) => handleSearch(s)}>
            <div style={{ display: 'flex' }}>
              <Form.Item name='searchByCode' label='Search by code' style={{ marginInline: 3 }}>
                <Input />
              </Form.Item>
              <Form.Item name='searchByName' label='Search by name' style={{ marginInline: 3 }}>
                <Input />
              </Form.Item>
              <DynamicButton style={{ border: 'none', marginInline: 3 }} type='primary' htmlType='submit'>
                {i18next.t('search')}
              </DynamicButton>
            </div>
          </Form> */}
          <DynamicTable
            // loading
            dataSource={formatData(dataSearch ?? dataImage)}
            initialTableData={teacherColumns}
            extraColumns={CourseExtraColumns}
            className='components-table-demo-nested'
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '50', '100'],
            }}
            expandIconColumnIndex={5}
            expandIconAsCell={false}
          />
        </Card>
      </Container>

      <Modal
        title={modalStatus.title}
        onOk={() => {
          form.submit();
        }}
        open={modalStatus.open}
        onCancel={() => setModalStatus({ open: false })}
        footer={[
          <DynamicButton htmlType='button' onClick={() => setModalStatus({ open: false })}>
            {i18next.t('Close')}
          </DynamicButton>,
          <DynamicButton
            style={{ border: 'none' }}
            type='primary'
            onClick={(value) => form.submit(value)}
            loading={loading}
          >
            {i18next.t('Save')}
          </DynamicButton>,
        ]}
      >
        <Form form={form} onFinish={(value) => handleSubmit(modalStatus.type, value)} layout='vertical'>
          {/* <Form.Item name='teacherCode' label='Mã giảng viên' rules={[{ required: true, message: 'Chưa nhập' }]}>
            <Input disabled />
          </Form.Item> */}
          <Form.Item
            name='sortOrder'
            label='Sort Order'
            rules={[
              {
                required: true,
                validator: (_, v) => {
                  if (v.trim() === '')
                    return Promise.reject(i18next.t('please enter', { field: i18next.t('sortOrder') }));
                  else {
                    const checkDuplicate = dataImage?.some(
                      (item) =>
                        parseFloat(item?.sortOrder) - parseFloat(v) === 0 &&
                        (item?.id !== selectRow?.id || modalStatus.type === 'add'),
                    );
                    if (checkDuplicate)
                      return Promise.reject(i18next.t('unavailable', { field: i18next.t('sortOrder') }));
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item
            name='categoryName'
            label='File name'
            rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('fileName') }) }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item name='teacherEmail' label='Email' rules={[{ required: true, message: "" }, {validator: validateContent}]}>
            <Input />
          </Form.Item> */}
          <Row gutter={[24]}>
            <Col lg={18}>
              <Form.Item
                name='imageUrl'
                label='File link'
                rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('Link') }) }]}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col lg={6} style={{ top: 29 }}>
              <UploadFiles
                entityId={'2FAE24D0-E489-422F-BC5E-B35DF08AEE12'}
                entityName='Images'
                subModuleName='TOEICEXAM'
                moduleName='SACHSO'
                tags='FILE'
                onSuccess={(value) => {
                  form.setFieldsValue({
                    imageUrl: value.file.response?.files?.[0]?.downloadUrl,
                  });
                  queryClient.invalidateQueries(KEY_SHARE_ATTACHMENTS);
                }}
                buttonStyle={{
                  marginBottom: 24,
                }}
                buttonText={i18next.t('Upload')}
                showQrCode={false}
                supportTypeList={['image/jpeg', 'image/jpg', 'image/png']}
              />
            </Col>
          </Row>
          {/* <Form.Item name='sortOrder' label='Thứ tự hiển thị' rules={[
            { required: true, message: 'Chưa nhập' },
          ]}>
            <InputNumber min={0} defaultValue={0} />
          </Form.Item> */}
        </Form>
      </Modal>
    </div>
  );
};

export default ManagerTOEICExamImage;
