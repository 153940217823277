import { query } from 'helpers/QueryHelper';

const registerNewUser = ({
  email = '',
  password = '',
  passwordEncrypt = '',
  fullName = '',
  roleId = 1,
  cityId = 0,
  phone = '',
  maSv = '',
  note = '',
  teacherId,
  khoa = '',
  nganh = '',
  classId = '',
  birthDay = '',
  status = '',
  sex = 1,
  shop = '',
  chucVu = '',
  ngayVaoCongTy = '',
}: {
  email: string;
  password: string;
  passwordEncrypt: string;
  fullName: string;
  roleId: number;
  cityId: number;
  phone: string;
  maSv: string;
  note: string;
  teacherId: number;
  khoa: string;
  nganh: string;
  classId: string;
  birthDay: string;
  status: string;
  sex: number;
  shop: string;
  chucVu: string;
  ngayVaoCongTy: string;
}) => {
  const parameters = {
    email,
    password,
    passwordEncrypt,
    fullName,
    roleId,
    cityId,
    phone,
    maSv,
    note,
    teacherId,
    khoa,
    nganh,
    classId,
    birthDay,
    status,
    sex,
    shop,
    chucVu,
    ngayVaoCongTy,
  };
  return query('p_VIMARU_Users_Register', parameters);
};

export default registerNewUser;
