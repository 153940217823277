import React from 'react';
import { Row, Col } from 'antd';
import Container from 'components/Containers/Container';
import Card from 'components/Containers/Card';
import Books from 'modules/Book/components/Books/demoHcm';
// import Books from 'modules/Book/components/Books';

// components
import ActivateBook from 'modules/Book/components/ActivateBook';
import DeactiveBook from 'modules/Book/components/DeactiveBook';

export default () => {
  return (
    <React.Fragment>
      <Container name='Sách điện tử' parentName='Sách số'>
        <Row gutter={[24, 24]}>
          <Col lg={18} xs={24}>
            <Card title='Sách điện tử đã kích hoạt'>
              <Books />
            </Card>
          </Col>
          {/* <Col lg={6} xs={24}>
            <Card title='Kích hoạt sách'>
              <ActivateBook />
            </Card>
            <Card title='Hủy kích hoạt sách'>
              <DeactiveBook />
            </Card>
          </Col> */}
        </Row>
      </Container>
    </React.Fragment>
  );
};
