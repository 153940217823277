import settings from 'constants/settings';
import AccessDenied from 'pages/Errors/AccessDenied';
import Shared_Home from 'pages/Home';
import LgUserManualInstructions from 'pages/LGinnotek/LgUserManualInstruction';
import LgUserManualQA from 'pages/LGinnotek/LgUserManualQA';
import LearningCommitmentContent from 'pages/LearningCommitmentContent';
import Profile from 'pages/Profile';
import React from 'react';
import { AiOutlineBell } from 'react-icons/ai';
import { BsBook } from 'react-icons/bs';
import { FaRegHandshake } from 'react-icons/fa';
import { GiHelp } from 'react-icons/gi';
import { IoHomeOutline } from 'react-icons/io5';
import { VscTools } from 'react-icons/vsc';

export default [
  {
    component: Shared_Home,
    exact: true,
    icon: <IoHomeOutline size={20} className='project-color' />,
    layout: `/${settings.PATHNAME}`,
    name: 'Home',
    path: '/home',
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 0,
    collapse: true,
    state: `${settings.PATHNAME}/home`,
    views: [],
  },
  {
    path: '/access-denied',
    component: AccessDenied,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['authenticated'],
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['authenticated'],
  },
  {
    path: '/home/no-data',
    component: Shared_Home,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['authenticated'],
  },
  {
    path: '/libraries',
    name: 'Reference',
    // miniName: 'C',
    component: React.lazy(() => import('pages/Vimaru/Library')),
    icon: <BsBook size={20} className='tw-text-pink-500 project-color' />,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 10,
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: React.lazy(() => import('pages/Vimaru/Notification')),
    icon: <AiOutlineBell size={20} className='tw-text-blue-500 project-color' />,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 50,
  },
  {
    collapse: true,
    name: 'Support Tool',
    icon: <VscTools size={16} className='project-color' />,
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 51,
    state: 'lms/tools',
    views: [
      {
        path: '/tools/dictionary',
        name: 'Dictionary',
        miniName: 'D',
        component: React.lazy(() => import('pages/Tools/Dictionary')),
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['authenticated'],
        showInSidebar: true,
        sortOrder: 0,
      },
      // {
      //   path: '/tools/ai-application',
      //   name: 'AI application',
      //   miniName: 'D',
      //   component: PageTemp,
      //   layout: `/${settings.PATHNAME}`,
      //   exact: true,
      //   roles: ['authenticated'],
      //   showInSidebar: true,
      //   sortOrder: 1,
      // },
    ],
  },
  {
    path: '/user-manual',
    name: 'User Manual',
    icon: <GiHelp size={20} className='tw-text-blue-500  project-color' />,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['authenticated'],
    showInSidebar: true,
    state: `${settings.PATHNAME}/user-manual`,
    sortOrder: 53,
    collapse: true,
    views: [
      {
        path: '/user-manual/instructions',
        name: 'Instructions',
        miniName: 'D',
        component: LgUserManualInstructions,
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['authenticated'],
        showInSidebar: true,
        sortOrder: 0,
      },
      {
        path: '/user-manual/q&a',
        name: 'Q & A',
        miniName: 'D',
        layout: `/${settings.PATHNAME}`,
        component: LgUserManualQA,
        exact: true,
        roles: ['authenticated'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },
  {
    path: '/committed-learn',
    name: 'Learning Commitment',
    component: LearningCommitmentContent,
    // miniName: 'C',
    icon: <FaRegHandshake size={20} className='project-color' />,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 54,
  },
];
