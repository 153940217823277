import colors from "constants/colors";
import { removeAccents } from "services/Text/formatText";

export const testTopicColumns = {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'Tên',
      key: 'name',
      fixed: 'left',
      width: '20%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        // textTransform: 'uppercase',
        color: colors.font,
      },
    },
    {
      title: 'Ghi chú',
      key: 'notes',
      width: '20%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Ngày tạo',
      key: 'createdDate',
      width: '15%',
      render: 'renderDateTime',
      formatString: 'DD/MM/YYYY HH:MM',
      style: {
        minWidth: 100,
        fontWeight: 600,
        color: colors.font,
      },
    },
  ],
};

// Code mới dùng đệ quy - không giới hạn các cấp thư mục, thư mục cấp 1 có parentId = '00000000-0000-0000-0000-000000000000',
export function buildTreeDataTestTopics(records, parentId = '00000000-0000-0000-0000-000000000000', level = 1) {
  if (records && records.length > 0) {
    const nodes = [];
    const filteredRecords = records.filter((record) => record.parentId === parentId);
    for (const record of _.sortBy(filteredRecords, ['name'])) {
      const children = buildTreeDataTestTopics(records, record.id, level + 1);
      const node = {
        key: record.id,
        title: record.name,
        children,
        level: level,
      };
      nodes.push(node);
    }
    return nodes;
  }
  return [];
}
// Code mới dùng đệ quy - không giới hạn các cấp thư mục, thư mục cấp 1 có parentId = '00000000-0000-0000-0000-000000000000',
export function buildTreeSelectTestTopics(records, parentId = '00000000-0000-0000-0000-000000000000', level = 1) {
  if (records && records.length > 0) {
    const nodes = [];
    const filteredRecords = records.filter((record) => record.parentId === parentId);
    for (const record of _.sortBy(filteredRecords, ['name'])) {
      const children = buildTreeSelectTestTopics(records, record.id, level + 1);
      const node = {
        value: record.id,
        title: record.name,
        name: record.name,
        children,
        level: level,
      };
      nodes.push(node);
    }
    return nodes;
  }
  return [];
}

// Thêm option "Tất cả" cho SelectTree ở các vị trí cần filter
export const addOptionAllTestTopics = (options) =>
([{
  title: <strong>Tất cả</strong>, value: '00000000-0000-0000-0000-000000000000', name: 'Tất cả', children: []
}, ...options]);


export const filterTreeSelectTestTopics = (inputValue, treeNode) =>
  removeAccents(treeNode?.name ?? '')
    ?.includes(removeAccents(inputValue ?? ''));

// khi filter, nếu chọn "Khối" thì hiển thị luôn cả cấp con, chọn cấp con thì chỉ hiện cấp con đó
//id: là id của "Khối" hoặc "Bộ phận", allData là mảng phẳng lấy từ API
export const getTestTopicIdForFilterData = (id = '', allData = []) => {
  const filterArr = allData?.filter(i => i?.id === id || i?.parentId === id);
  // listId dùng để filter dữ liệu theo bộ phận
  return filterArr.map(i => i.id);
}

//validate ở rules (Form.Item) chỉ cho phép chọn đề test là cấp cuối cùng
export const validateSelectTestTopic = (value, allShops) => {
  if (allShops?.some(i => i?.parentId === value))
    return Promise.reject('Vui lòng chọn đề test là cấp cuối cùng');
  else return Promise.resolve();
};
