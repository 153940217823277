import { Progress, Rate } from 'antd';
import i18next from 'i18next';
import SlideDetails from '../SlideDetails';

export default {
  rowKey: 'id',
  displayColumns: [
    {
      key: 'createdDate',
      render: 'renderDateTime',
      title: i18next.t('table:classInformation.Date'),
      width: '10%',
    },
    {
      key: 'lessonTitle',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('table:classInformation.Lesson'),
      width: '10%',
    },

    {
      key: 'presentationTitle',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('table:classInformation.Part'),
      width: '10%',
    },
    {
      key: 'title',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('table:classInformation.Title'),
      width: '10%',
    },
    {
      key: 'total',
      render: 'renderText',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('table:classInformation.Completed quantity'),
      width: '10%',
      dataType: 'math',
    },
    {
      key: 'qualityPercent',
      dataType: 'number',
      render: (qualityPercent, record, index) => {
        return (
          <div id={`completed-quality-${record.id}`}>
            <Progress percent={`${Number.parseFloat(qualityPercent ?? 0).toFixed(0)}`} status='active' />
          </div>
        );
      },
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('table:classInformation.Completed quality'),
      width: '10%',
    },
    {
      key: 'countPlayedTimes',
      render: 'renderText',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('table:classInformation.Number of times'),
      width: '10%',
      dataType: 'number',
    },
    {
      key: 'details',
      render: (text, record) => {
        return <SlideDetails record={record} />;
      },
      sort: false,
      style: {
        fontWeight: 400,
      },
      title: '',
      width: '1%',
    }
  ],
};
