import _ from 'lodash';
import iseAdminRoutes from './iseAdminRoutes';
import iseManagerRoutes from './iseManagerRoutes';
import isePublicRoutes from './isePublicRoutes';
import iseStudentRoutes from './iseStudentRoutes';
import iseTeacherRoutes from './iseTeacherRoutes';

const iseRoutes = _.concat(isePublicRoutes, iseStudentRoutes, iseTeacherRoutes, iseManagerRoutes, iseAdminRoutes);

export default iseRoutes;
