import { Button, Tooltip } from 'antd';
import React, { FC } from 'react';

type ColorButtonsProps = {
  colors: string[];
  handleChange: Function;
  icon: string;
  title: string;
};

const ColorButtons: FC<ColorButtonsProps> = ({ colors, handleChange, icon, title }) => {
  return (
    <>
      <div style={{ fontSize: 16, fontWeight: 'bold' }}>{title}</div>
      {colors.map((color, index) => {
        return (
          <Tooltip color={color} key={index} title={color} zIndex={9999}>
            {/* UI 1 */}
            {/* <Button type='text' onClick={() => handleChange(color)}>
              <i className={icon} style={{ color }} />
            </Button> */}
            {/* UI 2 */}
            <Button style={{ margin: 5 }} onClick={() => handleChange(color, icon)} icon={<i className={icon} style={{ color }} />} />
          </Tooltip>
        );
      })}
    </>
  );
};

export default ColorButtons;
