import * as actionTypes from '../actions/types';

type State = {
  selectedFolder: any;
  folderMenuClicked: number;
};

const initialState: State = {
  selectedFolder: null,
  folderMenuClicked: 0,
};

type Action = {
  type: string;
  payload: any;
};

const sidebarReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case actionTypes.SIDEBAR_SELECT_FOLDER:
      return {
        ...state,
        selectedFolder: action.payload,
      };
    case actionTypes.FOLDER_MENU_CLICKED:
      const temp = { ...state };
      return {
        ...state,
        folderMenuClicked: temp.folderMenuClicked + 1,
      };
    case actionTypes.FOLDER_MENU_CLICKED_RESET:
      return {
        ...state,
        folderMenuClicked: 0,
      };
    default:
      return state;
  }
};

export default sidebarReducer;
