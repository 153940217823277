import { Button, Tooltip } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

const FileDownloading = ({
  downloadUrl,
  buttonText,
  buttonIcon,
  buttonType,
  buttonShape,
  toolTipTitle,
  toolTipPlacement,
  fileName = 'filedownload',
}) => {
  function downloadFile(url) {
    axios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => console.error(error));
  }

  const createButton = (buttonText, buttonIcon, buttonType, buttonShape) => (
    <Button
      icon={buttonIcon}
      type={buttonType}
      shape={buttonShape}
      onClick={(e) => {
        e.preventDefault();
        if (toolTipTitle === 'Tải về') downloadFile(downloadUrl);
        else window.open(downloadUrl, '_blank');
      }}
    >
      {buttonText}
    </Button>
  );

  return (
    <>
      {toolTipTitle ? (
        <Tooltip title={toolTipTitle} placement={toolTipPlacement}>
          {createButton(buttonText, buttonIcon, buttonType, buttonShape)}
        </Tooltip>
      ) : (
        createButton(buttonText, buttonIcon, buttonType, buttonShape)
      )}
    </>
  );
};

FileDownloading.defaultProps = {
  downloadUrl: '',
  buttonText: '',
  buttonIcon: <CloudDownloadOutlined />,
  buttonType: 'primary',
  buttonShape: 'default',
  toolTipTitle: '',
  toolTipPlacement: 'top',
};

export default FileDownloading;
