import { Button, Form, Input, Modal, Row, Upload, Col, InputNumber, Image, message, Spin, List, Card } from 'antd';
import { useStudentsOrStudentByClassId } from 'modules/Teacher/api/getStudentsOrStudentByClassId';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { amesSendMail, query, queryWithoutModuleId } from 'helpers/QueryHelper';
import { UploadOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import StudentInfomationHeader from 'modules/Student/components/StudentInfomationHeader';
import TextArea from 'antd/lib/input/TextArea';
import Essay from 'modules/Presentation/Viewer/components/ExercicesTypes_MENARD/Essay';
import ImageNote from 'modules/Presentation/Viewer/ViewerTypes/SlideNote/Image';
import ImageAzota from './ImageAzota';
import StudentInfomationHeaderv2 from 'modules/Student/components/StudentInfomationHeaderv2';
import moment from 'moment';
import settings, { MODULE_ID } from 'constants/settings';
import getSettings from 'pages/Administration/api/getSettings';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

const fetcher = (slideId, studentId) => {
  return query('p_QUIZCITY_API_GetQuizSummary_Log', { slideId, studentId, type: 'Note' });
};

const fetchStudent = (studentId) => () => {
  return queryWithoutModuleId('p_SACHSO_USERS_GetById', { id: studentId });
};
const MarkingNote = ({ record, scoreEssay, teacherNoteEssay }) => {
  const { t } = useTranslation('');
  const [visible, setVisible] = React.useState(false);
  const [openModalImage, setOpenModalImage] = React.useState(false);
  const queryClient = useQueryClient();
  const KEY = ['p_QUIZCITY_API_GetQuizSummary_Log', record?.slideId, record?.studentId];
  const { data, isLoading } = useQuery(KEY, () => fetcher(record?.slideId, record?.studentId), { enabled: visible });
  const QUERY_KEY = ['p_SACHSO_USERS_GetById', record?.studentId];
  const { data: student } = useQuery(QUERY_KEY, fetchStudent(record?.studentId));
  const [form] = Form.useForm();

  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);
  
  const MAILER = ConfigSettings?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value;
  const CC = ConfigSettings?.find((x) => x?.key.toUpperCase() === 'CC')?.value;
  const BCC = ConfigSettings?.find((x) => x?.key.toUpperCase() === 'BCC')?.value;

  const audioUrl = data?.[0]?.jsonData?.submittedQuestions?.[0]?.userData?.answers?.audioUrl;
  const imageUrl = data?.[0]?.jsonData?.submittedQuestions?.[0]?.userData?.answers?.imageUrl;
  const textEssay = data?.[0]?.jsonData?.submittedQuestions?.[0]?.userData?.answers?.textEssay;
  const videoUrl = data?.[0]?.jsonData?.submittedQuestions?.[0]?.userData?.answers?.videoUrl;
  const onFinish = async (values) => {
    const parameters = {
      studentId: record?.studentId,
      SlideId: record?.slideId,
      ScoreEssay: values?.scoreEssay,
      TeacherNoteEssay: values?.teacherNoteEssay,
      FolderId: record?.folderId,
      type: 'Note',
    };
    query('p_QUIZCITY_Student_Examination_Results_Update_Essay', parameters)
      .then((res) => {
        queryClient.invalidateQueries('p_VIMARU_GetAnswerLogs_ByStudentId_Note');
        message.success('Chấm điểm bài tập Note thành công');
        setVisible(false);
        const today = moment(new Date()).format('DD/MM/YYYY');
        const dateNo = moment().format('YYYYMMDDhhmm');
        const { email, fullName, shopName, positionName } = student?.[0];
        const dataSendMail = {
          to: email,
          isSendFast: true,
          moduleId: MODULE_ID,
          cc: CC,
          bcc: BCC,
          subject: `Menard Class Note ${dateNo}`,
          body: '',
          bodyHTML: '',
          attachment: '',
          templateName: 'MENARD_Mail_MarkingNote',
          sourceInfo: 'API Save Email - MENARD_Mail_MarkingNote',
          templateData: JSON.stringify({
            studentName: fullName,
            hotline: '0379010101',
            submittedDate: today,
            noteScore: values?.scoreEssay,
            noteNote: values?.teacherNoteEssay,
          }),
          mailer: MAILER, // Lấy từ phần 'Thiết lập thôg tin của admin'
        };
        amesSendMail('saveEmail', dataSendMail);
      })
      .catch((e) => console.error(e));
  };
  useEffect(() => {
    form.setFieldsValue({
      scoreEssay: data?.[0]?.scoreEssay,
      teacherNoteEssay: data?.[0]?.teacherNoteEssay,
    });
  }, [data]);
  return (
    <React.Fragment>
      <DynamicButton type='text' onClick={() => setVisible(true)}>
        {i18next.t('Detail')}
      </DynamicButton>
      <Modal
        centered
        width='90vw'
        bodyStyle={{ height: '80vh', overflow: 'auto' }}
        open={visible}
        title={i18next.t('slide:Essay.Exercise Notes')}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[
          <DynamicButton
            key='close-modal'
            onClick={() => {
              setVisible(false);
            }}
          >
            {t('Close')}
          </DynamicButton>,
        ]}
      >
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin size='large'></Spin>
          </div>
        ) : (
          <Form
            form={form}
            initialValues={{ audioUrl, imageUrl, textEssay, videoUrl, scoreEssay, teacherNoteEssay }}
            onFinish={onFinish}
          >
            <h6 className='mb-4 heading-small text-muted'>Điểm</h6>
            <Row gutter={[24, 24]}>
              <Col lg={18}>
                <Form.Item
                  label='Điểm'
                  name='scoreEssay'
                  rules={[{ required: true, message: 'Bạn chưa nhập điểm' }]}
                  hasFeedback
                  wrapperCol={{ span: 21 }}
                  labelCol={{ span: 3 }}
                >
                  <InputNumber style={{ width: '100%' }} min={1} max={10} step='0.1' />
                </Form.Item>
                {/* <span className='tw-font-medium tw-ml-2' style={{ color: colors.theme.success }}>
             {`/ ${workItem.assignmentPoints}`}
           </span> */}
                <Form.Item label='Nhận xét' name='teacherNoteEssay' wrapperCol={{ span: 21 }} labelCol={{ span: 3 }}>
                  <TextArea style={{ width: '100%' }} autoSize={{ minRows: 3 }} allowClear />
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col span={3}></Col>
                    <Col span={21}>
                      <DynamicButton type='primary' htmlType='submit'>
                        Lưu thông tin
                      </DynamicButton>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col lg={6}>
                <StudentInfomationHeaderv2 student={student} />
              </Col>
            </Row>

            <hr />
            <h6 className='mb-4 heading-small text-muted'>Bài làm Class Note</h6>
            {data && data.length !== 0 && (
              <List
                grid={{ gutter: 16 }}
                dataSource={data?.[0]?.jsonData}
                renderItem={(item) => (
                  <List.Item>
                    <Card
                      style={{
                        border: '1px dashed #d9d9d9',
                        borderRadius: '2px',
                        width: 200,
                        height: 200,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 10,
                      }}
                      hoverable
                      cover={
                        <ImageAzota
                          // onPreview={() => handlePreview(item)}
                          // onDelete={() => handleDelete(item)}
                          openModal={openModalImage}
                          setopenModal={setOpenModalImage}
                          data={item}
                          style={{ width: 180, height: 180, objectFit: 'contain' }}
                        />
                      }
                    />
                  </List.Item>
                )}
              />
            )}
          </Form>
        )}
      </Modal>
    </React.Fragment>
  );
};

MarkingNote.propTypes = {
  classId: PropTypes.string,
};

MarkingNote.defaultProps = {
  classId: '',
};

export default MarkingNote;
