/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { message, Form, Input, Modal, TreeSelect, InputNumber } from 'antd';
import React from 'react';

import { add, update } from 'helpers/QueryHelper';
import settings from 'constants/settings';

import ColorPicker from 'components/ColorPicker';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import colors from 'constants/colors';

const UpdateFolder = ({ updateMode, data, visible, onOk, onCancel, treeData }) => {
  const { t } = useTranslation('');
  const initialValues = {
    parentId: updateMode === 'add' ? data?.id : data?.parentId,
    name: updateMode === 'add' ? '' : data?.name,
    color: updateMode === 'add' ? '#32325d' : data && data.color ? data.color : '#32325d',
    sortOrder: updateMode === 'add' ? 0 : data?.sortOrder,
  };

  const [form] = Form.useForm();
  //
  React.useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, visible]);

  //
  const onFinish = async (values) => {
    const parameters = {
      ...values,
      classId: 0, // id = 0 là của giáo viên không thuộc lớp nào
      createdBy: data.createdBy,
    };

    if (updateMode === 'add') {
      const response = await add('Folders', parameters, 'CLASSES');
      if (response) {
        message.success(i18next.t('popconfirm.createDatasuccess'));
        onOk();
      } else {
        message.error(i18next.t('popconfirm.oops'));
      }
    } else if (updateMode === 'edit') {
      const response = await update('Folders', data.id, parameters, 'CLASSES');
      if (response) {
        message.success(i18next.t('update success', { field: i18next.t('data') }));
        onOk();
      } else {
        message.error(i18next.t('popconfirm.oops'));
      }
    }
  };

  return (
    <Modal
      maskClosable={false}
      centered
      title={
        updateMode === 'add'
          ? i18next.t('commonTitle.Add new', { object: '' }).toUpperCase()
          : i18next.t('Update').toUpperCase()
      }
      visible={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={onCancel}
      okText={updateMode === 'add' ? i18next.t('commonTitle.Add new', { object: '' }) : i18next.t('Update')}
      cancelText={t('Close')}
    >
      <Form form={form} layout='vertical' onFinish={onFinish} requiredMark={false} initialValues={initialValues}>
        <Form.Item label={i18next.t('reference:Parentfolder')} name='parentId'>
          <TreeSelect
            style={{ color: colors.font }}
            treeData={treeData}
            allowClear
            dropdownStyle={{ maxHeight: 400, overflow: 'auto', zIndex: 10000 }}
            treeDefaultExpandAll
          />
        </Form.Item>

        <Form.Item
          label={i18next.t('reference:Foldername')}
          name='name'
          rules={[{ required: true, message: t('please enter', { field: 'folder name' }) }]}
        >
          <Input style={{ color: colors.font }} />
        </Form.Item>

        <Form.Item
          label={i18next.t('pages:lectureManagement.Order')}
          name='sortOrder'
          rules={[{ required: true, message: t('please enter', { field: t('sortOrder') }) }]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item label={i18next.t('commonTitle.Color')} name='color'>
          <ColorPicker />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateFolder;
