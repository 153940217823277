import { Modal } from 'antd';
import {
  DESTINATE_MULTIPLEMATCH_ICON,
  SOURCE_MULTIPLEMATCH_ICON,
} from 'modules/WorkSheet/CanvasToolPlus/CanvasToolPage/configuration';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import colorBoard from './colorBoard';
import ColorButtons from './ColorButtons';
import i18next from 'i18next';

const ColorModal = ({ handleChange, isModalVisible, position, setIsModalVisible }) => {
  const { top, left, width, height } = position;
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Modal
        title={i18next.t('Color panel')}
        style={{
          position: 'absolute',
          top: top + height,
          left: left + width + 100,
        }}
        width={260}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <ColorButtons colors={colorBoard} handleChange={handleChange} icon={SOURCE_MULTIPLEMATCH_ICON} title='Nguồn:' />
        <hr />
        <ColorButtons
          colors={colorBoard}
          handleChange={handleChange}
          icon={DESTINATE_MULTIPLEMATCH_ICON}
          title='Đích:'
        />
      </Modal>
    </Fragment>
  );
};

ColorModal.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isModalVisible: PropTypes.func.isRequired,
  position: PropTypes.object.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
};

export default ColorModal;
