/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import _ from 'lodash';
import QuestionHeader from '../QuestionHeader';
import AudioButton from '../AudioButton';
import './styles.css';

const MultipleChoice = (
  { data, onSubmit, userData, questionIndex = 0, preview = false, showUserData = false },
  ref,
) => {
  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
  }));

  const [state, setState] = React.useState({
    data: null,
    words: [],
    selectedAnswers: [],
  });
  const [key, setKey] = React.useState(null);
  const refAudio = useRef();
  refAudio.current = new Audio();

  const handleSubmit = () => {
    if (userData && showUserData) {
      onSubmit({ id: state.data.id, answers: state.selectedAnswers });
    }
    const correctAnswers = _.filter(state.words, (x) => x.isCorrect === true);
    const result = _.isEqual(_.sortBy(correctAnswers, ['id']), _.sortBy(state.selectedAnswers, ['id']));
    // Callback
    if (onSubmit && typeof onSubmit === 'function') onSubmit({ answers: state.selectedAnswers, result });
  };

  const playAudio = React.useCallback((audioUrl) => {
    refAudio.current.src = audioUrl;
    refAudio.current.play();
  }, []);

  refAudio.current.addEventListener('ended', () => {
    // setState((prevState) => ({ ...prevState, isAudioPlaying: false }));
  });

  React.useEffect(() => {
    const words = data.answers.map((item, index) => {
      return { id: index + 1, text: item.answer, isCorrect: item.isCorrect };
    });
    const correctAnswer = _.filter(data.answers, (answer) => answer.isCorrect);
    if (userData) {
      setState((prevState) => ({ ...prevState, words, data, selectedAnswers: userData.answers }));
      setKey(correctAnswer);
    } else {
      setState((prevState) => ({ ...prevState, words, data }));
    }
    // Chế độ xem thử
    if (preview) {
      setState((prevState) => ({ ...prevState, words, data, selectedAnswers: correctAnswer }));
    }
  }, [data, preview, userData]);

  if (!data) return null;

  const renderAnswer = (selected) => {
    let direction = data.settings && data.settings.answers && data.settings.answers.direction;
    if (direction === null || direction === undefined) {
      direction = 'vertical';
    }

    let bulletType = data.settings && data.settings.answers && data.settings.answers.bulletType;
    if (bulletType === null || bulletType === undefined) {
      bulletType = 'character';
    }
    return (
      // <div className={`answers-onechoice answers-onechoice-${direction}`}>
      <div className={`answers-onechoice tw-grid tw-grid-cols-2 tw-gap-4 tw-max-w-5xl`}>
        {state.words.map((word, index) => {
          const found = _.find(selected, (x) => {
            return x.id === word.id;
          });
          {
            /* const isSelected = selectedAnswer && selectedAnswer.id === item.id && selectedAnswer.answer === item.answer; */
          }
          return (
            <Button
              // size='small'
              key={`word-${index}`}
              className={`answerButton-onechoice answerButton-onechoice-${direction}`}
              color={found === undefined ? 'secondary' : 'success'}
              style={{ border: '1px solid #bbb9b9', fontSize: 17 }}
              onClick={() => {
                // let found = _.find(selected, (x) => {
                //   return x.id === word.id;
                // });

                if (found === undefined) {
                  const array = [...selected];
                  array.push(word);
                  setState((prevState) => ({ ...prevState, selectedAnswers: array }));
                } else {
                  const array = _.filter(selected, (x) => x.id !== word.id);
                  setState((prevState) => ({ ...prevState, selectedAnswers: array }));
                }
              }}
            >
              {word.text}
            </Button>
          );
        })}
      </div>
    );
  };

  return (
    <Card>
      <CardHeader>
        <QuestionHeader
          title={data.title}
          subTitle={data.subTitle}
          questionIndex={questionIndex}
          tooltip='SelectWordsInSentence'
        />
      </CardHeader>
      <CardBody style={{ minHeight: 300 }}>
        <div className='questionText'>{data.questionText}</div>

        <AudioButton audioUrl={data.audioUrl} />
        {renderAnswer(state.selectedAnswers)}
      </CardBody>

      <CardFooter>
        <h3>Đáp án đúng</h3>
        {renderAnswer(key)}
      </CardFooter>
    </Card>
  );
};

export default React.forwardRef(MultipleChoice);
