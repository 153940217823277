import settings from 'constants/settings';
import React from 'react';
import { Card as BSCard, CardBody, CardHeader } from 'reactstrap';

type Props = {
  title: string | JSX.Element | JSX.Element[] | null;
  subTitle?: string;
  style?: React.CSSProperties;
  bodyStyles?: React.CSSProperties;
  children?: JSX.Element | JSX.Element[];
  className?: string;
  headerClassName?: string;
  titleClassName?: string;
  footer?: React.ReactNode;
  extra?: React.ReactNode;
  onClickHeader?: Function | null;
};

const Card = ({
  title,
  subTitle,
  children,
  footer,
  style,
  bodyStyles,
  className,
  headerClassName,
  titleClassName,
  extra,
  onClickHeader,
}: Props) => {
  //khi Card không chứa children thì bo tròn góc dưới
  const styleNoChildren = children ? {} : { borderRadius: 'calc(0.375rem - 1px)', borderBottom: 'none' };
  // Khi có onClick CardHeader thì thêm css cursor
  const styleOnClickHeader = typeof onClickHeader === 'function' ? { cursor: 'pointer' } : {};
  const styleHeader = { flexWrap: 'wrap', ...styleNoChildren, ...styleOnClickHeader };
  return (
    <BSCard style={style} className={`${className}`}>
      {title && (
        <CardHeader style={styleHeader} className={headerClassName} onClick={onClickHeader}>
          <div className='tw-flex'>
            <div className='tw-flex-1'>
              {subTitle && (
                <h6
                  style={settings.IS_LG ? { display: 'none' } : {}}
                  className={`mb-1 text-uppercase text-muted ls-1 tw-text-base project-color`}
                >
                  {subTitle.toUpperCase()}
                </h6>
              )}
              <h3 className={`mb-0 tw-text-base project-color ${titleClassName}`}>
                {typeof title === 'string' ? title.toUpperCase() : title}
              </h3>
            </div>
            <div className={`tw-flex-shrink-0 card-header-responsive`}>{extra}</div>
          </div>
        </CardHeader>
      )}
      {children && (
        <CardBody style={bodyStyles} className='tw-h-full'>
          {children}
        </CardBody>
      )}
      {footer}
    </BSCard>
  );
};

Card.defaultProps = {
  subTitle: null,
  style: null,
  bodyStyles: null,
  children: null,
  className: 'classname-card',
  headerClassName: null,
  titleClassName: null,
  footer: null,
  extra: null,
  onClickHeader: null,
};

export default Card;
