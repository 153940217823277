import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import settings from 'constants/settings';

function LearningCommitmentContent() {
  return (
    <div>
      <Container name={'Commitment to learning'} parentName='Trang chủ'>
        <Card title={'Commitment to learning'.toUpperCase()}>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '0 24px' }}>
            {/* <span style={{ maxWidth: 916 }}> */}
            <span style={{ width: '100%' }}>
              <img
                alt='picture cover'
                // src={require('assets/img/LG/instruction/coverPicture.jpg').default}
                src={
                  settings.IS_LG
                    ? settings.DOMAIN_IS_USE + '/images/LG/LG_learningcommitment.jpg'
                    : settings.IS_TVHH
                    ? require('assets/img/introduce/bg_thuyenvien_popup.png').default
                    : require('assets/img/ISE/bg_ies_intro_popup.jpeg').default
                }
                width={'100%'}
              />
            </span>
          </div>
          <div style={{ padding: '16px 24px 0 24px', fontWeight: '400', fontSize: 16 }}>
            <div>
              <strong>1.</strong> Complete <strong>100%</strong> of the course content (including theory videos,
              exercises and tests) based on learning journey.
            </div>
            <div>
              <strong>2.</strong> The maximum time to complete the course is <strong>05 months.</strong> After this
              time, the account will be suspended without being able to view the incomplete content.
            </div>
            <div>
              <strong>3.</strong> Any actions to copy the course content in any forms and share it with third parties
              (take pictures, video lessons ...) are <strong>prohibited.</strong>
            </div>
            <div>
              <strong>4.</strong> Do not use learning account of others, do not study for others, complete tests for
              others.
            </div>
            <div>
              <strong>5.</strong>
              {` It is strictly forbidden to use the same account for many people (>= 2 people)`}
            </div>
            <div>
              <strong>6.</strong> In case there is any technical or course error, learners are responsible for
              responding in the Support section in the website for support, absolutely do not fix error by yourself
              which may lead to negative impacts on the website.
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
}

export default LearningCommitmentContent;
