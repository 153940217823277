/* eslint-disable consistent-return */
/* eslint-disable no-useless-return */
import { FileImageOutlined, SoundOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, message, Modal, Row, Spin, Tooltip, Upload } from 'antd';
import { sachsoServerUrl } from 'constants/serverUrls';
import settings from 'constants/settings';
import { getAllTagsQuestion, getDataGrade } from 'modules/Administration/ModuleAddQuestions';
import React from 'react';
import SearchDataQuestion from '../../Modules/SearchDataQuestion';
import ViewImportExam from '../../ViewImportExam';
import { useTranslation } from 'react-i18next';
type Props = {
  field: {
    name: number;
    key: number;
    isListField: boolean;
    fieldKey: number;
  };
  layout: any;
  setAddField: Function;
  form: any;
  lg: number;
  autoFocus: boolean;
  typeAllSelect: any[];
  setValueAnswerInputs: any;
};

const Layout = ({ layout, field, setAddField, form, lg, autoFocus, typeAllSelect, setValueAnswerInputs }: Props) => {
  const { t } = useTranslation();
  const [typeUpload, setTypeUpload] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [tempAnswers, setTempAnswer] = React.useState<any>([]);
  const fieldsForm = form.getFieldsValue();
  const props = {
    name: 'files',
    action: `${sachsoServerUrl}/api/v1.0/dynamic/upload-files`,
    headers: {
      Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
      ApplicationName: settings.APPLICATION_NAME,
    },
    data: {
      entityId: '46d30c13-3555-4395-8b83-4b9b2f7402ae',
      subModuleName: 'RESOURCES',
      entityName: 'Questions',
      moduleName: 'SACHSO',
      moduleId: settings.MODULE_ID,
      //   SACHSO_RESOURCES_Questions
    },
    // fileList,
    maxCount: 1,
    beforeUpload: (file: any) => {
      const isPNG = file.type?.split('/')?.[0] === typeUpload;
      if (!isPNG) {
        message.error(`${file.name} không đúng định dạng`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    onChange(info: any) {
      // form.resetFields();
      if (info?.file?.status === 'uploading') {
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} tải thư mục thành công`);

        const isPNG = info?.file.type?.split('/')?.[0] === 'image';
        if (isPNG) {
          const { answers } = fieldsForm;
          answers[field.name] = { imageUrl: info?.file?.response?.files?.[0]?.downloadUrl, ...tempAnswers[field.name] };
          form.setFieldsValue({ answers });
        } else {
          const { answers } = fieldsForm;
          answers[field.name] = { audioUrl: info?.file?.response?.files?.[0]?.downloadUrl };
          form.setFieldsValue({ answers });
        }
        // form.setFieldsValue({ imageUrl: info.file.response?.files?.[0]?.downloadUrl });
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} tải thư mục thất bại.`);
      }

      setIsLoading(false);
    },
  };

  //
  // const { data: dataOptionGrade, isLoading: loadingGrade } = getDataGrade();
  // const { data: dataAllTagsQuestion, isLoading: loadingTagsQuestion } = getAllTagsQuestion();

  const RenderLayout = () => {
    return (
      <>
        {layout?.map((item: any) => {
          switch (item) {
            case 'checkbox':
              return (
                <Col lg={1} xs={24}>
                  <Form.Item {...field} label=' ' name={[field.name, 'isCorrect']} valuePropName='checked'>
                    <Checkbox />
                  </Form.Item>
                </Col>
              );
            case 'input':
              return (
                <Col lg={lg} xs={24}>
                  <Form.Item
                    {...field}
                    rules={[
                      {
                        required: true,
                        message: t('please enter', { field: t('Answer') }),
                      },
                    ]}
                    style={{ width: '100%' }}
                    name={[field.name, 'answer']}
                    label={`Answer ${field.name + 1}`}
                  >
                    <Input
                      // autoFocus={autoFocus}
                      // onPressEnter={(e: any) => {
                      //   e.preventDefault();
                      //   setAddField?.();
                      // }}
                      // onChange={(e) => {
                      //   setTimeout(() => {
                      //     setTempAnswer(form.getFieldsValue().answers);
                      //     setValueAnswerInputs?.((prev) => {
                      //       const temp = [...prev];
                      //       temp[field.name] = e.target.value;
                      //       return temp;
                      //     });
                      //   }, 1500);
                      // }}
                    />
                  </Form.Item>
                </Col>
              );
            case 'phonetic':
              return (
                <Col lg={lg} xs={24}>
                  <Form.Item
                    {...field}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    style={{ width: '100%' }}
                    name={[field.name, 'phonetic']}
                    label={`Phonetic ${field.name + 1}`}
                  >
                    <Input
                      autoFocus={autoFocus}
                      onPressEnter={(e: any) => {
                        e.preventDefault();
                        setAddField?.();
                      }}
                    />
                  </Form.Item>
                </Col>
              );
            case 'textLeft':
              return (
                <Col lg={lg} xs={24}>
                  <Form.Item
                    {...field}
                    rules={[
                      {
                        required: true,
                        message: t('please enter', { field: t('textLeft') }),
                      },
                    ]}
                    style={{ width: '100%' }}
                    name={[field.name, 'textLeft']}
                    label={`Text Left ${field.name + 1}`}
                  >
                    <Input
                      autoFocus={autoFocus}
                      onPressEnter={(e: any) => {
                        e.preventDefault();
                        setAddField?.();
                      }}
                    />
                  </Form.Item>
                </Col>
              );
            case 'textRight':
              return (
                <Col lg={lg} xs={24}>
                  <Form.Item
                    {...field}
                    rules={[
                      {
                        required: true,
                        message: t('please enter', { field: t('textLeft') }),
                      },
                    ]}
                    style={{ width: '100%' }}
                    name={[field.name, 'textRight']}
                    label={`Text Right ${field.name + 1}`}
                  >
                    <Input
                      autoFocus={autoFocus}
                      onPressEnter={(e: any) => {
                        e.preventDefault();
                        setAddField?.();
                      }}
                    />
                  </Form.Item>
                </Col>
              );
            case 'audio':
              setTypeUpload('audio');
              return (
                <>
                  <Col lg={lg} xs={24}>
                    <Form.Item
                      {...field}
                      rules={[
                        {
                          required: true,
                          message: t('please enter', { field: 'Audio Url' }),
                        },
                      ]}
                      style={{ width: '100%' }}
                      name={[field.name, 'audioUrl']}
                      label={`Audio Url ${field.name + 1}`}
                    >
                      <Input placeholder='https://' />
                    </Form.Item>
                  </Col>
                  <Col lg={1}>
                    <Form.Item label={` `}>
                      <Upload {...props}>
                        <Button type='dashed' shape='circle' icon={<SoundOutlined />} />
                      </Upload>
                    </Form.Item>
                  </Col>
                </>
              );
            case 'image':
              setTypeUpload('image');
              return (
                <>
                  <Col lg={lg} xs={24}>
                    <Form.Item
                      {...field}
                      rules={[
                        {
                          required: true,
                          message: t('please enter', { field: 'Image Url' }),
                        },
                      ]}
                      style={{ width: '100%' }}
                      name={[field.name, 'imageUrl']}
                      label={`Image Url ${field.name + 1}`}
                    >
                      <Input placeholder='https://' />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label={` `}>
                      <Upload {...props}>
                        <Button type='dashed' shape='circle' icon={<FileImageOutlined />} />
                      </Upload>
                    </Form.Item>
                  </Col>
                </>
              );
            // case 'searchQuestion':
            //   return (
            //     <>
            //       <Modal
            //         open={isOpenModalChooseQuestion}
            //         centered
            //         width={'90%'}
            //         footer={null}
            //         onCancel={handleModalCreateExam}
            //         title={'Chọn câu hỏi'}
            //       >
            //         <ViewImportExam
            //           typeContent={'chooseQuestion'}
            //           id={null}
            //           onCancel={handleModalCreateExam}
            //           data={null}
            //           hiddenTilte={true}
            //         />
            //       </Modal>
            //       <Button
            //         onClick={() => {
            //           setIsOpenChooseQuestion(true);
            //         }}
            //       >
            //         Danh sách câu hỏi
            //       </Button>
            //     </>
            //   );
            default:
              return;
          }
        })}
      </>
    );
  };
  return (
    <>
      <RenderLayout />
    </>
  );
};

export default Layout;
