/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable sort-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-wrap-multilines */
import { EditOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Input, Modal, Row, Select, Spin, message } from 'antd';
import ConditionsContainer from 'components/Containers/ConditionsContainer';
import settings, { IS_BASAO, IS_LX, IS_MENARD, IS_TRONGDONG } from 'constants/settings';
import { generateSHA1 } from 'helpers/CryptoHelper';
import { amesSendMail, query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import _ from 'lodash';
import { AUTH_LOG_OUT } from 'modules/Login/actions/types';
import moment from 'moment';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { getData } from 'pages/Administration/ManageKhoaNganh/components/InsertData';
import getSettings from 'pages/Administration/api/getSettings';
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { updateUserInfors } from '../api';
import { genderOptions } from './data';

const getCategoryOption = (tag, dataList) => {
  const newOptions = [];
  dataList.forEach((item) => {
    if (item.notes === tag)
      newOptions.push({
        value: item.id,
        label: item.categoryName,
      });
  });
  return newOptions;
};

export default ({ loggedInUser, data, isLoading }) => {
  const [form] = Form.useForm();
  const [formUpdatePassword] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [isStatusModalUpdatePassword, setStatusModalUpdatePassword] = useState(false);
  const [optionKhoa, setOptionKhoa] = useState([]);
  const [optionNganh, setOptionNganh] = useState([]);
  const [optionShop, setOptionShop] = useState([]);
  const [optionPositon, setOptionPosition] = useState([]);

  const { data: dataKhoaNganh } = getData();
  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);

  const mailer = ConfigSettings?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value;
  const templateResetPass = ConfigSettings?.find((x) => x?.key.toUpperCase() === 'TEMPLATE_RESETPASSWORD')?.value;

  React.useEffect(() => {
    if (dataKhoaNganh && dataKhoaNganh !== []) {
      const activeCategory = dataKhoaNganh.filter((position) => position.isActive === true);
      if (settings.IS_VIMARU || settings.IS_HIGHUP) {
        setOptionKhoa(getCategoryOption('Khoa', activeCategory));
        setOptionNganh(getCategoryOption('Nganh', activeCategory));
      } else if (IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX) {
        setOptionShop(getCategoryOption('Shop', activeCategory));
        setOptionPosition(getCategoryOption('ChucVu', activeCategory));
      } else if (settings.IS_LG) {
        setOptionShop(getCategoryOption('Shop', activeCategory));
      }
    }
  }, [dataKhoaNganh]);
  React.useEffect(() => {
    if (data) {
      form.setFieldsValue({
        maSv: data?.[0]?.maSV,
        email: data?.[0]?.email,
        firstName: data?.[0]?.fullName.split(' ').slice(0, -1).join(' '),
        lastName: data?.[0]?.fullName.split(' ').slice(-1).join(' '),
        phoneNumber: data?.[0]?.phone,
        gender: data?.[0]?.sex === false ? 0 : 1,
        schoolTypeId: data?.[0]?.schoolTypeId,
        class: data?.[0]?.className,
        khoa: data?.[0]?.khoa === '00000000-0000-0000-0000-000000000000' ? null : data?.[0]?.khoa,
        nganh: data?.[0]?.nganh === '00000000-0000-0000-0000-000000000000' ? null : data?.[0]?.nganh,
        birthday: data?.[0]?.dateofBirth ? moment(data?.[0]?.dateofBirth) : null,
        teacherName: data?.[0]?.teacherName,
        teacherPhone: data?.[0]?.teacherPhone,
        teacherEmail: data?.[0]?.teacherEmail,
        chucVu: data?.[0]?.chucVu === '00000000-0000-0000-0000-000000000000' ? null : data?.[0]?.chucVu,
        shop: data?.[0]?.shop === '00000000-0000-0000-0000-000000000000' ? null : data?.[0]?.shop,
      });
    }
  }, [data]);

  const onFinish = (values) => {
    const newValues = {
      ...values,
      birthday: values.birthday ? values.birthday?.format('YYYY-MM-DD') : null,
      fullName: `${values.firstName} ${values.lastName}`,
    };
    const parameters = {
      id: data?.[0]?.id,
      maSV: data?.[0]?.maSV,
      email: newValues.email,
      name: newValues?.fullName,
      phone: newValues?.phoneNumber ?? null,
      roleId: data?.[0]?.roleId,
      sex: newValues.gender,
      status: 'ACTIVE',
      avatar: data?.[0].avatar || '',
      birthDay: newValues?.birthday ?? null,
      schoolTypeId: newValues?.schoolTypeId,
      khoa: values?.khoa || '00000000-0000-0000-0000-000000000000',
      nganh: values?.nganh || '00000000-0000-0000-0000-000000000000',
      chucVu: values.chucVu || '00000000-0000-0000-0000-000000000000',
      shop: values.shop || '00000000-0000-0000-0000-000000000000',
    };
    updateUserInfors(parameters)
      .then((res) => {
        if (res?.[0]?.res === 1) {
          message.success(i18next.t('update success', { field: i18next.t('information') }));
          queryClient.invalidateQueries('Users');
        } else {
          message.success(i18next.t('update failed', { field: i18next.t('information') }));
        }
      })
      .catch((err) => {
        //! Error: Connected Fail
        console.log(err);
        message.error(i18next.t('connectError'));
      });
  };

  const onOpenModalUpdatePasword = () => {
    setStatusModalUpdatePassword(true);
  };
  const onCloseUpdatePassword = () => {
    setStatusModalUpdatePassword(false);
  };
  const onHandleUpdatePassword = (values) => {
    const parameters = {
      Email: loggedInUser?.email,
      Password: generateSHA1(values.password),
    };
    onUpdatePassword(parameters, values.password);
  };

  const onUpdatePassword = async (parameters, password) => {
    await query('p_VIMARU_UserPassword_RESET', parameters)
      .then((res) => {
        if (res?.[0].res === 1) {
          // message.success(`Cập nhật mật khẩu email: ${parameters.Email} thành công.`);
          message.success(i18next.t('updatePassEmailSuccess', { field: parameters.Email }));

          const dateNo = moment().format('YYYYMMDDhhmm');
          const dataSendMail = {
            to: parameters.Email.toLowerCase(),
            isSendFast: true,
            moduleId: settings.MODULE_ID,
            cc: '',
            bcc: '',
            subject: `Quên mật khẩu #${dateNo}`,
            body: '',
            bodyHTML: '',
            attachment: '',
            templateName: templateResetPass,
            sourceInfo: `API Save Email - ${templateResetPass}`,
            templateData: JSON.stringify({
              email: `${parameters.Email}`,
              password: `${password}`,
              dateNo: `${dateNo}`,
              studentName: data?.[0]?.fullName,
            }),
            mailer: mailer,
          };
          amesSendMail('saveEmail', dataSendMail);
          localStorage.clear();
          history.push('/');
          dispatch({ type: AUTH_LOG_OUT });
        } else {
          return message.warning(i18next.t('update failed', { field: i18next.t('password') }));
        }
      })
      .catch(() => message.error(i18next.t('connectError')));
  };
  const handleChangeKhoa = (value) => {
    if (value) {
      const newOptions = [];
      dataKhoaNganh.forEach((item) => {
        if (item.parentId === value)
          newOptions.push({
            value: item.id,
            label: item.categoryName,
          });
      });
      setOptionNganh(newOptions);
    } else setOptionNganh([]);
    form.setFieldValue('nganh', null);
  };

  return (
    <React.Fragment>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          name='update-profile'
          onFinish={onFinish}
          layout='vertical'
          requiredMark={false}
          style={{ width: '100%' }}
        >
          <Card>
            <CardHeader>
              <h3 className={`mb-0 project-color`}>{i18next.t('PersonalPage').toLocaleUpperCase()}</h3>
            </CardHeader>
            <CardBody>
              <h6 className={`mb-4 heading-small`}>{i18next.t('Profile')}</h6>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Form.Item
                    name='email'
                    label={
                      settings.IS_LG || settings.IS_ISE || settings.IS_TVHH
                        ? 'Email (for login)'
                        : 'Email (dùng để đăng nhập)'
                    }
                  >
                    <Input disabled size='large' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {settings.IS_LG ? (
                    <Form.Item name='maSv' label={i18next.t('table:managerCoachingSchedule.Employee code')}>
                      <Input size='large' disabled />
                    </Form.Item>
                  ) : (
                    <Form.Item name={'phoneNumber'} label={i18next.t('table:userManagement.Phone number')}>
                      <Input size='large' />
                    </Form.Item>
                  )}
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Form.Item
                    name='firstName'
                    label={i18next.t('firstName')}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && value.trim() !== '') {
                            return Promise.resolve();
                          }
                          return Promise.reject(i18next.t('please enter', { field: i18next.t('firstName') }));
                        },
                      },
                    ]}
                  >
                    <Input size='large' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='lastName'
                    label={i18next.t('lastName')}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && value.trim() !== '') {
                            return Promise.resolve();
                          }
                          return Promise.reject(i18next.t('please enter', { field: i18next.t('lastName') }));
                        },
                      },
                    ]}
                  >
                    <Input size='large' />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <Form.Item name='gender' label={i18next.t('gender')}>
                    <Select
                      placeholder={i18next.t('please choose', { field: i18next.t('gender') })}
                      options={genderOptions}
                      size='large'
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {!settings.IS_LG ? (
                    <Form.Item name='birthday' label={i18next.t('auth:Birthday')}>
                      <DatePicker size='large' format='YYYY-MM-DD' style={{ width: '100%' }} />
                    </Form.Item>
                  ) : (
                    <Form.Item name='shop' label={i18next.t('Part')}>
                      <Select showSearch options={optionShop} allowClear disabled size='large' />
                    </Form.Item>
                  )}
                </Col>
              </Row>

              {(IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX) && (
                <Row gutter={[24, 24]}>
                  <Col lg={12}>
                    <Form.Item name='shop' label={i18next.t('Part')}>
                      <Select showSearch options={optionShop} allowClear disabled />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item name='chucVu' label='Chức vụ'>
                      <Select showSearch options={optionPositon} allowClear disabled />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <ConditionsContainer roles={['teacher', 'student']} conditions={settings.IS_VIMARU || settings.IS_HIGHUP}>
                <hr className='my-4' />
                <h6 className='mb-4 heading-small text-muted'>Thông tin Khoa / Chuyên ngành lớp</h6>
                <Row gutter={[24, 24]}>
                  <Col lg={12}>
                    <Form.Item name='khoa' label='Khoa'>
                      <Select onChange={handleChangeKhoa} placeholder='Chọn khoa' options={optionKhoa} allowClear />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    {/* <Form.Item name='city' label='Sở giáo dục (Tỉnh / Thành phố TW)'> */}
                    <Form.Item name='nganh' label='Chuyên ngành'>
                      <Select showSearch placeholder='Chọn ngành' options={optionNganh} allowClear />
                    </Form.Item>
                  </Col>
                </Row>
              </ConditionsContainer>
              {loggedInUser.role === 'student' && (
                <Row gutter={[24, 24]}>
                  {!settings.IS_LG ? (
                    <>
                      <Col lg={6}>
                        <Form.Item name='class' label={i18next.t('Class')}>
                          {/* <Form.Item name='classId' label='Lớp học'> */}
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col lg={6}>
                        <Form.Item name='teacherName' label={i18next.t('TeacherCharge')}>
                          {/* <Form.Item name='classId' label='Lớp học'> */}
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col lg={6}>
                        <Form.Item name='teacherPhone' label={i18next.t('phoneNumber')}>
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col lg={6}>
                        <Form.Item name='teacherEmail' label={i18next.t('TeacherEmail')}>
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </Row>
              )}
            </CardBody>
            <CardFooter>
              <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
                <DynamicButton style={{ border: 'none' }} type='primary' htmlType='submit' icon={<EditOutlined />}>
                  {i18next.t('Update')}
                </DynamicButton>
                <DynamicButton onClick={onOpenModalUpdatePasword}>{i18next.t('updatePassword')}</DynamicButton>
              </div>
            </CardFooter>
          </Card>
        </Form>
      </Spin>
      <Modal
        title={i18next.t('updatePassword')}
        open={isStatusModalUpdatePassword}
        footer={null}
        onCancel={onCloseUpdatePassword}
      >
        <Form onFinish={onHandleUpdatePassword} form={formUpdatePassword} layout='vertical' autoComplete='off'>
          <Form.Item
            name='password'
            label={i18next.t('newPassword')}
            rules={[
              {
                required: true,
                message: i18next.t('please enter', { field: i18next.t('newPassword') }),
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name='c_passowrd'
            label={i18next.t('confirmPassword')}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: i18next.t('please enter', { field: i18next.t('confirmPassword') }),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(i18next.t('confirm password mismatch')));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', columnGap: 20 }}>
            <DynamicButton key='back' onClick={onCloseUpdatePassword}>
              {i18next.t('Close')}
            </DynamicButton>
            <DynamicButton style={{ border: 'none' }} type='primary' htmlType='submit'>
              {i18next.t('Update')}
            </DynamicButton>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
