/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import RenderLineTo, { calculateResultMatch, checkSameGroup } from './LineTo';

const Match = ({ type, data, againAnswer, isDisableTest, isHiddenShowColor}) => {
  const [state, setState] = React.useState({
    userSelect: [], // bài nối của student
    userAnswers: [], // kết quả làm
    arrayAnswers: [], // list để chấm điểm theo userAnswers
  });
  const [isDone, setIsDone] = React.useState(false);
  const [result, setResult] = React.useState(null);

  const handleClick = React.useCallback(
    (id) => {
      if (isDone) return;
      let { userSelect = [], userAnswers = [] } = state;
      userSelect.push(id);
      userSelect = [...new Set(userSelect)]; // loại bỏ trùng lặp
      // Kiểm tra không cùng nhóm => không nối.
      if (!checkSameGroup(userSelect, data)) {
        userSelect = []; // clear
      }
      if (userSelect.length >= 2) {
        const newline = userSelect.sort().join('-');
        const multipleLine = false;
        if (multipleLine) {
          // chọn  nhiều
          if (userAnswers.includes(newline)) {
            // tìm đường đã nối
            userAnswers = userAnswers.filter((x) => x !== newline); // xóa đường nối
          } else {
            userAnswers.push(newline); // thêm đường mới
          }
        } else {
          // chọn 1-1
          userAnswers = userAnswers.filter((item) => {
            const selected_point = item.split('-'); // điểm đã chọn
            const isRepetition = userSelect.some((x) => {
              return selected_point.includes(`${x}`);
            }); // phát hiện ít nhất 1 điểm trùng lặp
            return !isRepetition; // lọc lấy những đường ko trùng lặp
          });
          userAnswers.push(newline);
        }
        userSelect = []; // clear
      }
      // update
      // const arrayAnswers = data.map((x) => x.text);
      setState((pre) => ({ ...pre, userSelect, userAnswers }));
    },
    [data, isDone, state],
  );
  useEffect(() => {
    if (againAnswer) {
      setState((pre) => ({
        ...pre,
        userAnswers: againAnswer?.userAnswers,
        listBoolean: againAnswer?.listBoolean,
        arrayAnswers: data?.map((x) => x.text),
      }));
    } else {
      // Khi làm bài
      setState((pre) => ({
        ...pre,
        arrayAnswers: data?.map((x) => x.text), // dựa vào arrayAnswers để chấp điểm
      }));
    }
    // Khi có Count Down thì useEffect gọi lại liên tục vì data truyền vào nên để data?.length
  }, [data?.length, againAnswer]);

  useEffect(() => {
    Object.assign(type, {
      submit: () => {
        // handle submit
        if (!data) return {};
        const resultObject = calculateResultMatch(state.userAnswers, state.arrayAnswers, data);
        if (!resultObject) return {};
        resultObject.userAnswers = state.userAnswers;
        setIsDone(true);
        setResult(resultObject);
        return { matchResult: resultObject };
      },
      tryAgain: () => {
        setIsDone(false);
        setState((pre) => ({
          ...pre,
          arrayAnswers: data?.map((x) => x.text), // dựa vào arrayAnswers để chấp điểm
        }));
      },
      setResult,
      setIsDone,
    });
    return () => {};
  }, [data, type, state.arrayAnswers, state.userAnswers]);

  if (!data) return null;

  //Lấy đáp án chính xác
  const correctAnswer = [];
  let tempAnswer = new Set(
    state.arrayAnswers.filter((v, i, a) => {
      return a.indexOf(v) !== i;
    }),
  );

  Array.from(tempAnswer).map((item, index) => {
    let arr = [];
    state.arrayAnswers.map((answer, index) => {
      if (item === answer) {
        arr.push(index);
      }
    });
    correctAnswer.push(arr.join('-'));
  });

  return (
    <div>
      {data.map((item, index) => {
        const { top, left, width, height, countMatch, groupName, text } = item;
        return (
          <div
            key={`match-${index}`}
            className={`dot-${index}`}
            style={{
              position: 'absolute',
              top,
              left,
              width,
              height,
              border: '2px dotted gray',
              backgroundColor: '#b1d9f533',
              cursor: isDisableTest ? 'no-drop' : 'pointer',
              borderRadius: 8,
            }}
            onClick={() => {
              if (isDisableTest) return {};
              handleClick(index);
            }}
          >
            {dotSelect(index, state.userSelect)}
          </div>
        );
      })}
        <RenderLineTo
          userAnswers={state.userAnswers}
          isDone={isDisableTest}
          isDisableTest={isDisableTest}
          listBoolean={result?.listBoolean || state?.listBoolean}
          isHiddenShowColor={isHiddenShowColor}
        />
    </div>
  );
};

Match.propTypes = {
  match: PropTypes.object.isRequired,
  data: PropTypes.array,
};

Match.defaultProps = {
  data: [],
};

export default Match;

const dotSelect = (id, userSelect) => {
  if (!userSelect?.includes(id)) return null;
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 16,
        height: 16,
        borderRadius: '50%',
        backgroundColor: '#1890ff',
      }}
    />
  );
};
