import { Col, Form, Input, Row, Select, notification } from 'antd';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

import Container from 'components/Containers/Container';
import i18next from 'i18next';
import { fetchSettings } from 'modules/Settings/actions';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getSettings from '../api/getSettings';
import updateSettings from '../api/updateSettings';

export default function ConfigSettings() {
  const [form] = Form.useForm();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const dispatch = useDispatch();
  useEffect(() => {
    getSettings()
      .then((res) => {
        form.setFieldsValue({
          MAILER: res?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value,
          TEMPLATE_REGISTER: res?.find((x) => x?.key === 'TEMPLATE_REGISTER')?.value,
          TEMPLATE_REGISTER_TEACHER: res?.find((x) => x?.key === 'TEMPLATE_REGISTER_TEACHER')?.value,
          TEMPLATE_RESETPASSWORD: res?.find((x) => x?.key === 'TEMPLATE_RESETPASSWORD')?.value,
          PASS_PERCENT_VIDEO: res?.find((x) => x?.key === 'PASS_PERCENT_VIDEO')?.value,
          PASS_SCORE_WORKSHEET: res?.find((x) => x?.key === 'PASS_SCORE_WORKSHEET')?.value,
          PASS_SCORE_QUIZ: res?.find((x) => x?.key === 'PASS_SCORE_QUIZ')?.value,
          SHUFFLE_QUESTION: res?.find((x) => x?.key === 'SHUFFLE_QUESTION')?.value,
          TEMPLATE_VERIFY_EMAIL: res?.find((x) => x?.key === 'TEMPLATE_VERIFY_EMAIL')?.value,
          TEMPLATE_WELCOME_USER: res?.find((x) => x?.key === 'TEMPLATE_WELCOME_USER')?.value,
          IS_BLOCK_SEQUENTIALLY_SLIDE: res?.find((x) => x?.key === 'IS_BLOCK_SEQUENTIALLY_SLIDE')?.value,
          IS_SKIP_VIDEO: res?.find((x) => x?.key === 'IS_SKIP_VIDEO')?.value,
          IS_SKIP_QUIZ_ESSAY: res?.find((x) => x?.key === 'IS_SKIP_QUIZ_ESSAY')?.value,
          IS_LOCKED_ALL: res?.find((x) => x?.key === 'IS_LOCKED_ALL')?.value,
          Cc: res?.find((x) => x?.key.toUpperCase() === 'CC')?.value,
          Bcc: res?.find((x) => x?.key.toUpperCase() === 'BCC')?.value,
          // lgAdminMails: res?.find((x) => x?.key.toUpperCase() === 'LGADMINMAILS')?.value,
          // amesAdminMails: res?.find((x) => x?.key.toUpperCase() === 'AMESADMINMAILS')?.value,
        });
      })
      .catch((e) => console.log(e));
  }, []);

  const onFinish = (values) => {
    let errorCount = 0;
    Object.entries(values).forEach(([key, value], index) => {
      const parameters = {
        key: key,
        value: value ? value : '',
        createdBy: loggedInUser?.id,
      };
      updateSettings(parameters)
        .then()
        .catch((e) => {
          errorCount++;
          notification.error({ message: `Update failed ${key}. Please try again` });
        });
    });
    if (!errorCount)
      notification.success({
        message: i18next.t('update success', { field: i18next.t('Config') }),
        onClose: () => {
          dispatch(fetchSettings());
        },
      });
  };

  return (
    <Container>
      <Form form={form} onFinish={onFinish} layout='vertical'>
        <Card>
          <CardHeader>
            <h3 className={'project-color'}>{i18next.t('Config')}</h3>
          </CardHeader>
          <CardBody>
            <h6 className='mb-4 heading-small text-muted'>Mail</h6>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label='Mailer' name='MAILER'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label='Cc' name='Cc'>
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label='Bcc' name='Bcc'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={[24, 24]}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label='LG Admin mails' name='lgAdminMails'>
                  <Input  />
                </Form.Item>
              </Col>
             
              <Col xs={24} sm={12} md={12}>
                <Form.Item label='Ames Admin mails' name='amesAdminMails'>
                  <Input  />
                </Form.Item>
              </Col>
            </Row> */}

            <hr className='my-4' />
            <h6 className='mb-4 heading-small text-muted'>Template Mail</h6>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label='Template Register Teacher' name='TEMPLATE_REGISTER_TEACHER'>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12}>
                <Form.Item label='Template Reset Password' name='TEMPLATE_RESETPASSWORD'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label='Template Verify Email' name='TEMPLATE_VERIFY_EMAIL'>
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12}>
                <Form.Item label='Template Welcome User' name='TEMPLATE_WELCOME_USER'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label='Template Register' name='TEMPLATE_REGISTER'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <hr className='my-4' />
            <h6 className='mb-4 heading-small text-muted'> {i18next.t('table:lectureManagement.Lecture')}</h6>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label={i18next.t('SequentialSlides')} name='IS_BLOCK_SEQUENTIALLY_SLIDE'>
                  <Select
                    options={[
                      { value: '0', label: i18next.t('Non-sequential') },
                      { value: '1', label: i18next.t('Sequential') },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12}>
                <Form.Item label={i18next.t('LockAll')} name='IS_LOCKED_ALL'>
                  <Select
                    options={[
                      { value: '0', label: i18next.t('NoLock') },
                      { value: '1', label: i18next.t('Lock') },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label='Skip video' name='IS_SKIP_VIDEO'>
                  <Select
                    options={[
                      { value: '0', label: i18next.t('NoSkip') },
                      { value: '1', label: 'Skip' },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label='Skip bài quiz tự luận' name='IS_SKIP_QUIZ_ESSAY'>
                  <Select
                    options={[
                      { value: '0', label: i18next.t('NoSkip') },
                      { value: '1', label: 'Skip' },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  hasFeedback
                  rules={[
                    {
                      max: 100,
                      message: i18next.t('qualityCantHigher100'),
                    },
                    {
                      max: 100,
                      message: i18next.t('quiz'),
                    },
                    {
                      min: 0,
                      message: i18next.t('qualityCantLower0'),
                    },
                  ]}
                  label={
                    i18next.t('Quality...AssignmentCompletion(%)', { field: i18next.t('Video') })
                    // settings.IS_LG
                    //   ? 'Quality of Video Assignment Completion(%)'
                    //   : 'Chất lượng hoàn thành bài tập Video (%)'
                  }
                  name='PASS_PERCENT_VIDEO'
                >
                  <Input type='number' min={0} max={100} maxLength={3} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  hasFeedback
                  rules={[
                    {
                      max: 100,
                      message: i18next.t('qualityCantHigher100'),
                    },
                    {
                      min: 0,
                      message: i18next.t('qualityCantLower0'),
                    },
                  ]}
                  label={i18next.t('Quality...AssignmentCompletion(%)', { field: i18next.t('Quiz') })}
                  name='PASS_SCORE_QUIZ'
                >
                  <Input type='number' min={0} max={100} maxLength={3} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  hasFeedback
                  rules={[
                    {
                      max: 100,
                      message: i18next.t('qualityCantHigher100'),
                    },
                    {
                      min: 0,
                      message: i18next.t('qualityCantLower0'),
                    },
                  ]}
                  label={i18next.t('Quality...AssignmentCompletion(%)', { field: i18next.t('Worksheet') })}
                  name='PASS_SCORE_WORKSHEET'
                >
                  <Input type='number' min={0} max={100} maxLength={3} />
                </Form.Item>
              </Col>
            </Row>
            <hr className='my-4' />
            <h6 className='mb-4 heading-small text-muted'> {i18next.t('question')} </h6>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item label={i18next.t('RandomOrder')} name='SHUFFLE_QUESTION'>
                  <Select
                    options={[
                      { value: 'FALSE', label: i18next.t('NotRandom') },
                      { value: 'TRUE', label: i18next.t('Random') },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className='px-4 py-3'>
            <Form.Item
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginBottom: 0,
                // padding: '0.25rem',
                paddingBlock: 0,
              }}
            >
              <DynamicButton style={{ border: 'none' }} type='primary' htmlType='submit'>
                {i18next.t('SaveConfig')}
              </DynamicButton>
            </Form.Item>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  );
}
