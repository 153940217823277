import { Button, Space, Steps, message } from 'antd';
import Card from 'components/Containers/Card';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ReactMic } from 'react-mic';
import './index.css';
import Tour from 'reactour';
import { query } from 'helpers/QueryHelper';
import { CaretRightOutlined } from '@ant-design/icons';
const audioTestUrl = require('assets/img/pttest/audio-test-1.png').default;
const theClassRoomSongUrl = require('assets/img/pttest/the-classroom-song.jpg').default;
const areYouReadyUrl = require('assets/img/pttest/are-you-ready.png').default;
const moment = require('moment');

type Props = { code: string; setIsShowTutorial: Dispatch<SetStateAction<boolean>> };

const TutorialPT = ({ code, setIsShowTutorial }: Props) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [record, setRecord] = useState<boolean>(false);
  const [audio, setAudio] = useState<any>(null);
  const [microphoneStream, setMicrophoneStream] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [testInfo, setTestInfo] = useState<any>([]);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);

  const [state, setState] = useState<any>({
    isTourOpen: false,
    isShowingMore: false,
  });

  const closeTour = () => {
    setState({ isTourOpen: false });
  };

  const openTour = () => {
    setState({ isTourOpen: true });
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const startRecording = () => {
    requestPermission();
    if (!microphoneStream) {
      setRecord(false);
      return;
    }
    setAudio(null);
    setRecord(true);
  };

  const stopRecording = () => {
    setRecord(false);
  };

  const onStop = (recordedBlob: any) => {
    setAudio(recordedBlob.blobURL);
  };

  const isPermissionsApiSupported = () => {
    return navigator.permissions && navigator.permissions.query;
  };
  const requestMicrophonePermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
      if (permissionStatus.state === 'granted') {
        console.log('Microphone permission granted');
        // Proceed to access the microphone
        return await navigator.mediaDevices.getUserMedia({ audio: true });
      } else if (permissionStatus.state === 'denied') {
        console.log('Microphone permission denied');
      } else {
        console.log('Microphone permission request is pending');
        return await navigator.mediaDevices.getUserMedia({ audio: true });
      }
    } catch (error) {
      console.error('Error requesting microphone permission:', error);
      message.error('Không tìm thấy thiết bị microphone');
    }
  };

  const requestMicrophonePermissionLegacy = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Microphone permission granted (legacy)');
      return stream;
    } catch (error: any) {
      if (error.code === DOMException.PERMISSION_DENIED) {
        console.error('Microphone permission denied (legacy)');
      } else {
        console.error('Error accessing microphone:', error);
      }
    }
  };

  const requestPermission = async () => {
    if (isPermissionsApiSupported()) {
      try {
        const stream = await requestMicrophonePermission();
        setMicrophoneStream(stream);
      } catch (err) {
        setError(err);
      }
    } else {
      try {
        const stream = await requestMicrophonePermissionLegacy();
        setMicrophoneStream(stream);
      } catch (err) {
        setError(err);
      }
    }
  };

  const accentColor = '#5cb7b7';

  const tourSoundConfig = [
    {
      selector: '[data-tut="reactour__button"]',
      position: 'right',
      content: (
        <div className='tw-text-center '>
          <p className='tw-text-lg tw-font-bold'>Click here to listen to the audio and adjust your computer volume</p>
          <i className='tw-text-lg tw-font-bold'>Nhấn vào biểu tượng này để nghe audio và điều chỉnh âm lượng</i>
        </div>
      ),
    },
  ];

  const tourPlayConfig = [
    {
      selector: '[data-tut="reactour__playbutton"]',
      position: 'left',
      content: (
        <div className='tw-text-center'>
          <p className='tw-text-lg tw-font-bold'> Click here to start and stop the audio</p>
          <i className='tw-text-lg tw-font-bold'>Nhấn vào biểu tượng này để nghe và dừng audio</i>
        </div>
      ),
    },
  ];

  const tourStartTestConfig = [
    {
      selector: '[data-tut="reactour__starttestbutton"]',
      position: 'top',
      content: (
        <div className='tw-text-center'>
          <span className='tw-text-lg tw-font-bold'>Click "YES" để bắt đầu bài kiểm tra.</span>
        </div>
      ),
    },
  ];

  const onBeforeClose = () => {
    console.log(1);
  };

  const onAfterOpen = () => {
    setDisableBtn(true);
  };

  useEffect(() => {
    if (!state.isTourOpen) {
      setDisableBtn(false);
    }
  }, [state.isTourOpen]);

  const steps = [
    {
      title: '1',
      content: (
        <>
          <h2 className='tw-text-2xl tw-font-extrabold'>TEST INFORMATION / THÔNG TIN CHUNG</h2>
          <div>
            {testInfo?.[0]?.testInfo ? (
              <div
                dangerouslySetInnerHTML={{ __html: testInfo?.[0]?.testInfo }}
                className='test-info-content tw-text-left tw-pt-4 tw-px-32'
              />
            ) : (
              <div className='test-info-content tw-text-left tw-pt-4 tw-px-32'>
                <p className='tw-font-bold'>
                  <strong className='tw-font-bold'>Test/ Tên bài test: </strong>
                  {testInfo?.[0]?.name}
                </p>
                <p className='tw-font-bold'>
                  <strong className='tw-font-bold'>Course / Khóa học:</strong> {testInfo?.[0]?.parentName}
                </p>
                {testInfo?.[0]?.totalTime !== 0 && (
                  <>
                    <p className='tw-font-bold'>
                      <strong className='tw-font-bold'>Allocated time/Tổng thời lượng: </strong>
                      {testInfo?.[0]?.totalTime / 60} phút
                    </p>
                    <br />
                  </>
                )}
              </div>
            )}
            <br />
            <span className='tw-text-lg tw-font-bold'>
              Could you spend the next <b>{testInfo?.[0]?.totalTime / 60} minutes</b> to complete the test?
            </span>
            <br />
            <span className='tw-text-lg tw-font-bold'>If you are ready, please click here to start!</span>
            <br />
            <br />
            <i className='tw-text-lg tw-font-bold'>
              Bạn có thể dành ra <b>{testInfo?.[0]?.totalTime / 60} phút</b> tiếp theo để hoàn thành bài test không?
            </i>{' '}
            <br />
            <i className='tw-text-lg tw-font-bold'>Nếu bạn đã sẵn sàng, hãy click vào nút START để bắt đầu ngay nhé!</i>
          </div>
        </>
      ),
    },
    {
      title: '2',
      content: (
        <>
          <div>
            <span className='tw-text-lg tw-font-bold'>
              For matching questions, click to choose the corresponding pictures/ words.
            </span>
            <br />
            <i className='tw-text-lg tw-font-bold'>
              Đối với dạng bài nối, nhấn chọn hai hình ảnh/ từ tương ứng để nối đáp án.
            </i>
          </div>
          <Space className='tw-mt-3'>
            <div className='tw-text-center'>
              <img src={theClassRoomSongUrl} alt='' style={{ height: '400px', marginBottom: '5px' }} />
            </div>
          </Space>
        </>
      ),
    },
    {
      title: '3',
      content: (
        <div className='tw-flex tw-flex-col tw-justify-center tw-items-center'>
          <b className='tw-text-2xl tw-mt-14'>
            I. Listen and complete the dialogue. <i className='fas fa-volume-up' data-tut='reactour__button' />
          </b>
          <Tour
            onRequestClose={closeTour}
            steps={tourSoundConfig}
            isOpen={state.isTourOpen}
            maskClassName='mask'
            className='helper'
            rounded={5}
            accentColor={accentColor}
            onAfterOpen={onAfterOpen}
            onBeforeClose={onBeforeClose}
            showButtons={false}
            showNumber={false}
            showNavigation={false}
          />
        </div>
      ),
    },
    {
      title: '4',
      content: (
        <div className='tw-flex tw-flex-col tw-justify-center tw-items-center'>
          <b className='tw-text-xl tw-mt-14'>Movers Placement Test - Part 1.png</b>
          <div className='tw-relative'>
            {state.isTourOpen && (
              <CaretRightOutlined
                className='tw-absolute tw-z-30 tw-text-xl'
                data-tut='reactour__playbutton'
                style={{ top: '28px', left: '17px' }}
              />
            )}
            <audio controls className='tw-mt-3'>
              <source src='' />
            </audio>
          </div>
          <Tour
            onRequestClose={closeTour}
            steps={tourPlayConfig}
            isOpen={state.isTourOpen}
            maskClassName='mask'
            className='helper'
            rounded={5}
            accentColor={accentColor}
            onAfterOpen={onAfterOpen}
            onBeforeClose={onBeforeClose}
            showButtons={false}
            showNumber={false}
            showNavigation={false}
          />
        </div>
      ),
    },

    {
      title: '5',
      content: (
        <div className='tw-mt-10'>
          <span className='tw-text-lg tw-font-bold'>
            Please click on this button to check if your microphone is working.
          </span>
          <br />
          <i className='tw-text-lg tw-font-bold'>
            Vui lòng nhấn vào đây để kiểm tra mic của bạn có hoạt động hay không.
          </i>
          <div className='tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-6'>
            <Button
              onClick={record ? stopRecording : startRecording}
              style={{ height: '100px', width: '100px', borderRadius: '50%', border: record ? '1px solid red' : '' }}
            >
              {record ? (
                <i style={{ fontSize: '25px', color: record ? 'red' : 'black' }} className='fas fa-stop' />
              ) : (
                <i style={{ fontSize: '25px', color: 'black' }} className='fas fa-microphone' />
              )}
            </Button>
            <ReactMic
              record={record}
              className='sound-wave'
              onStop={onStop}
              strokeColor='#000000'
              backgroundColor='#FFFFFF'
            />
            {audio && (
              <audio controls className='tw-mt-6'>
                <source src={audio} type='audio/webm'></source>
              </audio>
            )}
          </div>
        </div>
      ),
    },
    {
      title: '6',
      content: (
        <div className='tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-mt-10'>
          <span style={{ fontSize: '35px' }}>
            <img src={areYouReadyUrl} alt='' />
          </span>
        </div>
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const getTestInfo = async () => {
    try {
      const res = await query('p_QUIZCITY_PTTest_Infor_Get', { code: code });
      setTestInfo(res);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    getTestInfo();
  }, []);

  useEffect(() => {
    if (currentStep === 2 || currentStep === 3 || currentStep === 5) {
      openTour();
    }
    if (currentStep === 4) {
      requestPermission();
    }
  }, [currentStep]);

  return (
    <Card
      title={<span className='tw-text-2xl tw-font-extrabold tw-text-white tw-shadow-2xl'>Hướng dẫn làm bài</span>}
      style={{ margin: '50px auto', maxWidth: '1140px' }}
      className='tutorial-card'
    >
      <Steps current={currentStep} items={items} style={{ display: 'none' }} />
      <div className='steps-content'>{steps[currentStep].content}</div>
      <div className={currentStep !== steps.length - 1 ? 'steps-action' : 'steps-action steps-action-center'}>
        {currentStep > 0 && (
          <Button className='tutorial-btn' disabled={disableBtn} onClick={() => prevStep()}>
            Previous
          </Button>
        )}
        {currentStep == 0 && (
          <Button type='primary' disabled={disableBtn} className='tutorial-btn' onClick={() => nextStep()}>
            Start
          </Button>
        )}
        {currentStep < steps.length - 1 && currentStep != 0 && (
          <Button type='primary' disabled={disableBtn} className='tutorial-btn tw-font-bold' onClick={() => nextStep()}>
            Next
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <>
            <Button
              type='primary'
              className='start-test-btn'
              onClick={() => {
                message.success('Chúc bạn hoàn thành tốt bài kiểm tra!');
                setIsShowTutorial(false);
              }}
              data-tut='reactour__starttestbutton'
            >
              YES
            </Button>
            <Tour
              onRequestClose={closeTour}
              steps={tourStartTestConfig}
              isOpen={state.isTourOpen}
              maskClassName='mask'
              className='helper'
              rounded={5}
              accentColor={accentColor}
              onAfterOpen={onAfterOpen}
              onBeforeClose={onBeforeClose}
              showButtons={false}
              showNumber={false}
              showNavigation={false}
            />
          </>
        )}
      </div>
    </Card>
  );
};

export default TutorialPT;
