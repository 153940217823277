import colors from "constants/colors";
import moment from "moment";

export const courseColumns = {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'Niên khóa',
      key: 'name',
      fixed: 'left',
      width: '30%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
    },
    {
      title: 'Giai đoạn 1',
      key: 'schoolYear1',
      fixed: 'left',
      width: '15%',
      render: (text, record)=> 
        <span>{moment(record.giaiDoan1Start).format('DD/MM/YYYY')}-{moment(record.giaiDoan1End).format('DD/MM/YYYY')}</span>
      ,
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
    },
    {
      title: 'Giai đoạn 2',
      key: 'schoolYear2',
      fixed: 'left',
      width: '15%',
      render: (text, record)=> 
        <span>{moment(record.giaiDoan2Start).format('DD/MM/YYYY')}-{moment(record.giaiDoan2End).format('DD/MM/YYYY')}</span>
      ,
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
    },
    {
      title: 'Ghi chú',
      key: 'notes',
      width: '30%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    // {
    //   title: 'Trạng thái',
    //   key: 'status',
    //   width: '10%',
    //   render: 'renderNoWrap',
    //   style: {
    //     fontWeight: 600,
    //     color: colors.font,
    //   },
    // },
    // {
    //   title: 'Ngày tạo',
    //   key: 'CreatedDate',
    //   width: '15%',
    //   sort: false,
    //   render: 'renderDateTime',
    //   prefix: <CalendarOutlined className='tw-mr-2' />,
    // },
  ],
};