import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Space, Tooltip, message } from 'antd';
import Card from 'components/Containers/Card';
import ConditionsContainer from 'components/Containers/ConditionsContainer';
import Container from 'components/Containers/Container';
import colors from 'constants/colors';
import { sendNotificationToUser } from 'helpers/NotificationHelper';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { supportColumns } from './tableColumn';

const ManagerRequestSupport = () => {
  const { requestId } = useParams();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();
  const [selectRow, setSelectRow] = React.useState(null);
  const [modalStatus, setModalStatus] = React.useState({
    open: false,
    title: '',
    type: 'add',
  });
  const [loading, setLoading] = React.useState(false);
  const [searchParam, setSearchParam] = React.useState(requestId ? { content: requestId } : {}); // nếu có truyền id thì tìm theo id k thì mặc định lấy tất cả
  const queryClient = useQueryClient();
  const KEY_SELECT_REQUEST = ['p_QUIZCITY_Request_Management_Select', searchParam];
  const { data: dataRequest, isLoading } = useQuery(KEY_SELECT_REQUEST, () =>
    query('p_QUIZCITY_Request_Management_Select', searchParam),
  );

  const { data: dataSupport } = useQuery('p_QUIZCITY_Request_Category_Select', () =>
    query('p_QUIZCITY_Request_Category_Select'),
  );

  const extraColumns = [
    {
      key: 'editInfomation',
      render: (text, record) => {
        return (
          <Space style={{ width: 40 }}>
            <Tooltip placement='top' title={'Reply'}>
              <Button
                key='edit-file'
                shape='circle'
                type='dashed'
                icon={<EditOutlined />}
                onClick={() => {
                  handleShowModal('edit', record);
                }}
              />
            </Tooltip>
          </Space>
        );
      },
      sort: false,
      title: '',
      width: '1%',
      fixed: 'right',
    },
  ];

  const handleShowModal = (type, record) => {
    setSelectRow(record);
    form.setFieldsValue(record);
    setModalStatus({
      open: true,
      title: 'REPLY REQUEST',
      type,
    });
  };

  const bodyNotification = (data) => {
    return `
          <div>Question: ${data?.questionContent}</div>
          <div>Answer: ${data?.notes}</div>
        `;
  };

  const onUpdate = (value) => {
    setLoading(true);
    const { id, questionContent, requestCategoryId, createdBy, createdDate, studentId } = selectRow;
    const { notes } = value;
    const timeNow = moment().toISOString();
    const params = {
      responedDate: timeNow,
      modifiedBy: loggedInUser.id,
      id,
      status: 'Replied',
      notes: notes.trim().replace(/\s+/g, ' '),
      questionContent,
      requestCategoryId,
      createdBy,
      createdDate,
      studentId,
      modifiedDate: timeNow,
    };
    query('p_QUIZCITY_Request_Management_InsertUpdate', params)
      .then((res) => {
        queryClient.invalidateQueries(KEY_SELECT_REQUEST);
        message.success('Modified your answer successfully!');
        setModalStatus({ open: false });
        sendNotificationToUser({
          title: 'Your support request has been answered',
          body: bodyNotification(params),
          classId: 0,
          avatar: loggedInUser?.avatarUrl ?? '',
          name: loggedInUser?.fullName,
          icon: '',
          color: colors.theme.button,
          createdBy: loggedInUser?.id,
          receiverId: studentId,
          type: 1, //0: Common , 1: Support, 2: Booking, 3: Other
        });
      })
      .catch((error) => {
        message.error("Can't modify!");
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = (value) => {
    const { requestCategoryId, status, notes, questionContent } = value;
    const newParams = {};
    for (const property in value) {
      if (value[property]) newParams[property] = value[property].trim();
    }
    setSearchParam(newParams);
  };
  const validateContent = (rule, value, callback) => {
    if (value.trim() === '') {
      callback(i18next.t('please enter', { field: i18next.t('table:requestSupportManagement.Response') }));
    } else {
      callback();
    }
  };

  const formatData = (data) => {
    if (data?.length > 0) {
      const sortArr = _.sortBy(data, ['createdDate']).reverse();
      return sortArr;
    } else return [];
  };

  return (
    <ConditionsContainer roles={['administrator', 'manager', 'teacher']}>
      <Container name='Request Support' parentName='Administration'>
        <Card title='Request Support'>
          <Form form={searchForm} onFinish={(s) => handleSearch(s)}>
            <div style={{ display: 'flex', gap: 5, rowGap: 5, flexWrap: 'wrap' }}>
              <Form.Item name='requestCategoryId' label='Category' style={{ marginInline: 3 }}>
                <Select
                  defaultValue={null}
                  options={dataSupport
                    ?.map((item) => ({ value: item.id, label: item.categoryName }))
                    .concat([{ label: 'All', value: null }])}
                  style={{ width: 200 }}
                />
              </Form.Item>
              <Form.Item name='status' label='Status' style={{ marginInline: 3 }}>
                <Select
                  defaultValue={''}
                  options={[
                    { value: 'Waiting', label: 'Waiting' },
                    { value: 'Replied', label: 'Replied' },
                  ].concat([{ label: 'All', value: '' }])}
                  style={{ width: 200 }}
                />
              </Form.Item>
              <Form.Item name='Content' label='Question/ Answer' style={{ marginInline: 3 }}>
                <Input />
              </Form.Item>
              <DynamicButton style={{ border: 'none', marginInline: 3 }} type='primary' htmlType='submit'>
                {i18next.t('search')}
              </DynamicButton>
            </div>
          </Form>
          <DynamicTable
            loading={isLoading}
            dataSource={formatData(dataRequest)}
            initialTableData={supportColumns}
            extraColumns={extraColumns}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '50', '100'],
            }}
          />
        </Card>
      </Container>

      <Modal
        title={modalStatus.title}
        okText={i18next.t('Save')}
        cancelText={i18next.t('Close')}
        onOk={() => {
          form.submit();
        }}
        open={modalStatus.open}
        onCancel={() => setModalStatus({ open: false })}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: 20 }}>
            <DynamicButton htmlType='button' onClick={() => setModalStatus({ open: false })}>
              {i18next.t('Close')}
            </DynamicButton>
            <DynamicButton style={{ border: 'none' }} type='primary' onClick={() => form.submit()}>
              {i18next.t('Save')}
            </DynamicButton>
          </div>,
        ]}
      >
        <Form form={form} onFinish={onUpdate} layout='vertical'>
          <div className='row' style={{ display: 'block' }}>
            <div className='col-12 row' style={{ display: 'flex' }}>
              <div className='col-3'>
                <h3 style={{ marginBottom: -3 }}>{i18next.t('Requestor')}: </h3>
              </div>
              <span>{selectRow?.createdByName}</span>
            </div>
            <div className='col-12 row' style={{ display: 'flex' }}>
              <div className='col-3'>
                <h3 style={{ marginBottom: -3 }}>{i18next.t('Date')}: </h3>
              </div>
              <span>{moment(selectRow?.createdDate).format('DD/MM/YYYY')}</span>
            </div>
            <div className='col-12 row' style={{ display: 'flex' }}>
              <div className='col-3'>
                <h3 style={{ marginBottom: -3 }}>{i18next.t('Category')}: </h3>
              </div>
              <span>{selectRow?.requestCategoryName}</span>
            </div>
            <div className='col-12'>
              <h3 style={{ marginBottom: -3 }}>{i18next.t('question')}:</h3>
              <span>{selectRow?.questionContent}</span>
            </div>
          </div>
          <div>
            <Form.Item name='notes' label='Answer' rules={[{ validator: validateContent }]}>
              <Input.TextArea />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </ConditionsContainer>
  );
};

export default ManagerRequestSupport;
