import { object } from 'prop-types';
import * as actionTypes from '../actions/types';

type State = {
  openModalName: any;
};

const initialState: State = {
  openModalName: null,
};

type Action = {
  type: string;
  payload: any;
};

const settingReducer = (state = initialState, action: Action): State => {
  switch (action.type) {
    case actionTypes.SETTING_OPEN_MODAL:
      return {
        ...state,
        openModalName: action.payload,
      };

    case actionTypes.SETTING_CLOSE_MODAL:
      return {
        ...state,
        openModalName: null,
      };

    default:
      return state;
  }
};

export default settingReducer;
