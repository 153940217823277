import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';

const changeStatusNotificationManagement = (Id: number) => {
  const sqlCommand = 'p_QUIZCITY_DeactiveAndActive_NotificationManagement';
  const parameters = { Id };
  const applicationName = settings.APPLICATION_NAME;
  return query(sqlCommand, parameters, applicationName);
};

export default changeStatusNotificationManagement;
