export const Columns = [
  {
    title: 'Mã SV',
    dataIndex: 'maSV',
    key: 'maSV',
    width: 100,
    align: 'center',
    fixed: 'left',
    // sorter: (a, b) => a.maSV.length - b.maSV.length,
  },
  {
    title: 'Họ Tên',
    dataIndex: 'studentName	',
    key: 'studentName	',
    width: 100,
    align: 'center',
    fixed: 'left',
    sorter: (a, b) => a.studentName.length - b.studentName.length,
  },
  {
    title: 'SĐT',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    width: 100,
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 100,
    align: 'center',
    fixed: 'left',
    sorter: (a, b) => a.email.length - b.email.length,
  },
  {
    title: 'Lớp',
    dataIndex: 'class',
    key: 'class',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.class.length - b.class.length,
  },
  {
    title: 'Giáo viên',
    dataIndex: 'teacherName',
    key: 'teacherName',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.teacherName.length - b.teacherName.length,
  },
  {
    title: 'Số lượng hoàn thành',
    dataIndex: 'percentCompleted',
    key: 'percentCompleted',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.percentCompleted - b.percentCompleted,
  },
  {
    title: 'Chất lượng hoàn thành',
    dataIndex: 'qualityPercentCompleted',
    key: 'qualityPercentCompleted',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.qualityPercentCompleted - b.qualityPercentCompleted,
  },
  {
    title: 'Chất lượng hoàn thành tư cách',
    dataIndex: 'qualificationCompletedPercent',
    key: 'qualificationCompletedPercent',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.qualityPercentCompleted - b.qualityPercentCompleted,
  },
  {
    title: 'Tiến độ hoàn thành',
    dataIndex: 'progress',
    key: 'progress',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.progress - b.progress,
  },
  {
    title: 'Điểm tư cách giữa kỳ',
    dataIndex: 'scoresQualificationMidSemester',
    key: 'scoresQualificationMidSemester',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.scoresQualificationMidSemester - b.scoresQualificationMidSemester,
  },
  {
    title: 'Điêm thi giữa kỳ',
    dataIndex: 'scoresMidSemester',
    key: 'scoresMidSemester',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.scoresMidSemester - b.scoresMidSemester,
  },
  {
    title: 'Điểm tư cách cuối kỳ',
    dataIndex: 'scoresQualificationEndSemester',
    key: 'scoresQualificationEndSemester',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.scoresQualificationEndSemester - b.scoresQualificationEndSemester,
  },
  {
    title: 'Điểm thi cuối kỳ',
    dataIndex: 'scoresEndSemester',
    key: 'scoresEndSemester',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.scoresEndSemester - b.scoresEndSemester,
  },
  {
    title: 'Điểm tổng kết',
    dataIndex: 'scoresTotal',
    key: 'scoresTotal',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.scoresTotal - b.scoresTotal,
  },
  {
    title: 'Ghi chú',
    dataIndex: 'notes',
    key: 'notes',
    width: 100,
    align: 'center',
    sorter: (a, b) => a.notes.length - b.notes.length,
  },
];
