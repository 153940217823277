import { CloseCircleOutlined } from '@ant-design/icons';
import settings from 'constants/settings';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import { useState } from 'react';
import { Carousel, CarouselItem, Modal, ModalBody, ModalFooter } from 'reactstrap';
import './modal.modules.css';

export default function LGHomeModal({ setModalVisible }) {
  const [showModal, setShowModal] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const items = settings.IS_TVHH
    ? [
        {
          src: require('assets/img/introduce/bg_thuyenvien_popup.png').default,
          altText: 'Slide 1',
          caption: 'Slide 1',
          key: 1,
        },
      ]
    : settings.IS_ISE
    ? [
        {
          src: require('assets/img/ISE/bg_ies_intro_popup.jpeg').default,
          altText: 'Slide 1',
          caption: 'Slide 1',
          key: 1,
        },
      ]
    : settings.IS_LG
    ? [
        {
          src: '/images/LG/SLIDE/SLIDE_LOGIN/slide1.PNG',
          altText: 'Slide 1',
          caption: 'Slide 1',
          key: 1,
        },
        {
          src: '/images/LG/SLIDE/SLIDE_LOGIN/slide2.jpg',
          altText: 'Slide 2',
          caption: 'Slide 2',
          key: 2,
        },
        {
          src: '/images/LG/SLIDE/SLIDE_LOGIN/slide3.jpg',
          altText: 'Slide 3',
          caption: 'Slide 3',
          key: 3,
        },
      ]
    : [];
  const slides = items.map((item) => {
    return (
      <CarouselItem
        style={{ paddingTop: '30px' }}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={item.src} alt={item.altText} />
        </div>

        {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
      </CarouselItem>
    );
  });

  return (
    <Modal
      centered
      size='xl'
      className='intro-modal'
      isOpen={showModal}
      toggle={() => setShowModal(!showModal)}
      backdrop={false}
      contentClassName='intro-modal-content '
    >
      <ModalBody>
        <CloseCircleOutlined
          style={{
            cursor: 'pointer',
            fontSize: 24,
            position: 'absolute',
            right: 20,
            top: 10,
            zIndex: 1,
          }}
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Carousel
          className='intro-carousel '
          activeIndex={activeIndex}
          ride='carousel'
          next={next}
          previous={previous}
          interval={3000}
        >
          {slides}

          {settings.IS_LG ? (
            <span className='intro-modal-title tw-text-base lg:tw-text-2xl'>
              Welcome To LG Innotek Study Programs
              <br />
              <span className='intro-modal-subtitle tw-text-sm lg:tw-text-xl'>Let's Get Started!</span>
            </span>
          ) : (
            ''
          )}
        </Carousel>
      </ModalBody>
      <ModalFooter style={{ paddingTop: 0, paddingBottom: '1.2rem' }}>
        <DynamicButton
          style={{ height: 43 }}
          type='primary'
          onClick={() => {
            setShowModal(false);
            setModalVisible(true);
          }}
        >
          OK Let's go
        </DynamicButton>
      </ModalFooter>
    </Modal>
  );
}
