import { LeftOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import React from 'react';

type Props = {
  isFinalSlide: boolean;
  callBackSubmit: Function;
  callBackNext: Function;
  callPrevious: Function;
  callSaveExercise: Function;
  currentResult: any;
  setCurrentResult: any;
  isDisableBackBtn: boolean;
  mode?: string;
  audio: any;
  currentIndex: number;
  lengthSlide: number;
};
const AmesOnlineFooter = ({
  isFinalSlide,
  callBackSubmit,
  callBackNext,
  callPrevious,
  callSaveExercise,
  currentResult,
  setCurrentResult,
  isDisableBackBtn,
  mode,
  audio,
  currentIndex, // thứ tự slide đang hiển thị
  lengthSlide, // số slide của bài giảng đang làm
}: Props) => {
  return (
    <Row style={{ padding: 12, backgroundColor: '#f4f4f8 ', borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}>
      <Col span={8} style={{ fontWeight: 'bold', textAlign: 'left' }}>
        <Button
          icon={<LeftOutlined />}
          disabled={isDisableBackBtn}
          onClick={() => {
            if (mode !== 'VIEW') callBackSubmit(true);
            callPrevious();
            setCurrentResult(0);
          }}
        >
          Bài trước
        </Button>
      </Col>
      <Col flex={1} span={8}>
        <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          {audio && <div id='Footer-ExcercisePage-Audio'>{audio}</div>}
          {/* {audio ? <div id='Footer-ExcercisePage-Audio'>{audio}</div> : `${currentIndex}/${lengthSlide}`} */}
        </div>
      </Col>

      <Col span={8} style={{ fontWeight: '600', textAlign: 'right' }}>
        <Space>
          <Button
            type='primary'
            onClick={() => {
              if (mode !== 'VIEW') callBackSubmit(true);
              callBackNext();
            }}
            disabled={isFinalSlide && mode === 'VIEW'}
          >
            {isFinalSlide ? 'Kết thúc' : 'Bài tiếp'}
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default AmesOnlineFooter;
