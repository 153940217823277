import withAuthenticator from 'HOCs/withAuthenticator';
import React from 'react';
import { LoggedInUserType } from 'types';

type Props = {
  loggedInUser?: LoggedInUserType;
  children?: JSX.Element | JSX.Element[];
  roles?: Array<'administrator' | 'manager' | 'teacher' | 'student'>;
  conditions: boolean;
};

//Dùng để check quyền sử dụng các chức năng bị hạn chế sử dụng và điều kiện hiển thị
const ConditionsContainer = ({ loggedInUser, children, roles, conditions }: Props): JSX.Element | null => {
  if (loggedInUser && roles?.some((role) => role === loggedInUser?.role && conditions)) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return null;
};

ConditionsContainer.defaultProps = {
  loggedInUser: null,
  children: null,
  roles: ['administrator', 'manager', 'teacher', 'student'],
  conditions: true,
};

export default withAuthenticator(ConditionsContainer);
