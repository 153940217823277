/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import colors from 'constants/colors';
import _ from 'lodash';
import LineTo from 'react-lineto';

export default function RenderLineTo({
  userAnswers,
  isDone,
  listBoolean,
  isHiddenShowColor,
}) {
  if (!userAnswers) return null;
  // const Anchor = { fromAnchor: 'center', toAnchor: 'center' };
  return userAnswers.map((item, index) => {
    const [first, second] = item.split('-');

    let color = colors.black;
    if (isHiddenShowColor) {
      // Hiển thị màu đúng sai
      color = isDone ? colors?.theme.primary : colors.black;
    } else {
      if (isDone && listBoolean) {
        const isCorrect = listBoolean[`${first}-${second}`];
        color = isCorrect ? colors.theme.success : colors.theme.danger;
      }
    }

    return (
      <LineTo
        key={index}
        fromAnchor='center'
        toAnchor='center'
        from={`dot-${first}`}
        to={`dot-${second}`}
        within='match-container'
        borderColor={color}
        borderWidth={3}
        delay={500}
      />
    );
  });
}
export function calculateResultMatch(userAnswers = [], arrayAnswers, data) {
  // nếu không có data thì không chấm điểm
  if (!data || data.length === 0) return;
  //total phải là kiểu int thì mưới truyền vào new Array được
  const countAnswers = Math.floor(arrayAnswers.length / 2) ;
  //
  const total = Math.max(userAnswers.length, countAnswers); // tổng số đáp án hoặc tổng số đã chọn;
  const booleanArray = new Array(total);
  const listBoolean = {}; // biến hiển thị css đúng/sai
  userAnswers.forEach((item, index) => {
    const [first, second] = item.split('-');
    const isCorrect = arrayAnswers[first] === arrayAnswers[second];
    booleanArray[index] = isCorrect;
    Object.assign(listBoolean, { [item]: isCorrect });
  });
  // Tính điểm theo câu.
  const score = checkScoreByGroup(listBoolean, data);
  const totalScore = score.length;
  const correctScore = score.reduce((previousValue, currentValue) => previousValue + currentValue.score, 0).toFixed(2);

  // params
  // const correct = booleanArray.reduce((total, item) => (total += item ? 1 : 0), 0);
  // const correct = booleanArray.filter((x) => x === true).length;
  const percent = parseInt((correctScore * 100) / totalScore); // tính %
  const resultString = `${correctScore}/${arrayAnswers?.length / 2}`; // điểm / tổng
  const star = percent / 20;
  //
  const complete = `${Math.min(userAnswers.length, countAnswers)}/${countAnswers}`;
  return { listBoolean, booleanArray, percent, resultString, star, complete };
}

export const checkSameGroup = (userSelect, data) => {
  if (userSelect.length < 2) return true; // Không đủ 1 cặp => Không xử lý.
  // userSelect.length===2
  const [groupFirst, groupSecond] = userSelect.map((item) => data[item].groupName);

  return groupFirst === groupSecond;
};

const checkScoreByGroup = (listBoolean, data) => {
  const listKey = Object.keys(listBoolean);

  const newData = data.map(({ groupName }, index) => {
    const key = listKey.find((item) => {
      const array = item.split('-');
      return array.some((x) => x === `${index}`);
    });
    const isCorrect = !!listBoolean[key];
    return { groupName, isCorrect };
  });

  const listGroup = _.groupBy(newData, 'groupName');
  const res = _.map(listGroup, (o) => {
    const name = o[0].groupName;
    // Trong 1 câu có nhiều vị trí cần nối, chỉ nối 1 chỗ đúng, vẫn tính điểm vị trí đó.
    const countTrue = _.countBy(o, (item) => item.isCorrect === true).true ?? 0;

    return { groupName: name, score: countTrue / 2 };
  });
  return res;
};
