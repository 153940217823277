import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
// import { Button } from 'reactstrap';
import colors from 'constants/colors';
import { query } from 'helpers/QueryHelper';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Presentation from './Presentation/LGPresentation';
import styles from './swimlaneISE.module.css';

type Props = {
  folders: Array<any>;
};

const lessonColors = ['#6EC7D7', '#3DB4C9', '#0DA1BC', '#0A798D'];

const fetchFolder = (folderId, studentId) => () => {
  if (!folderId) return;

  const parameters = {
    studentId: studentId,
    folderId: folderId,
  };
  return query('p_QUIZCITY_Get_Folders_Presentation_ByLesson', parameters);
};
export default function SwimlaneISE({ folders }: Props) {
  const storedSelectedFolderIndex = localStorage.getItem('folderIndexSwimlane');
  const storedSelectedLessonIndex = localStorage.getItem('lessonIndexSwimlane');
  const [selectedFolder, setSelectedFolder] = useState<any>(
    storedSelectedFolderIndex ? folders[storedSelectedFolderIndex] : null,
  );

  const [widthScreen, setWidthScreen] = useState<number>(window.outerWidth);
  const [selectedLessonIndex, setSelectedLessonIndex] = useState<number>(parseInt(storedSelectedLessonIndex) ?? null);
  const [visible, setVisible] = useState<boolean>(false);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const folderId = selectedFolder?.lessons?.[selectedLessonIndex]?.id ?? selectedFolder?.id;

  const KEY_FETCH_FOLDER = ['p_QUIZCITY_Get_Folders_Presentation_ByLesson', folderId, loggedInUser?.id];
  const { data, isLoading } = useQuery(KEY_FETCH_FOLDER, fetchFolder(folderId, loggedInUser?.id));
  const rowRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    const handleResize = (e) => {
      // Xử lý sự kiện thay đổi kích thước màn hình ở đây
      setWidthScreen(e.target.outerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Dependency array rỗng để đảm bảo hiệu chỉnh chỉ xảy ra khi component được gắn kết

  const handleWeekClick = (folder, folderIndex) => {
    setVisible(true);
    localStorage.setItem('folderIndexSwimlane', folderIndex);
    setSelectedFolder(folder);
  };
  const WeekCircle = ({ folder, folderIndex }) => {
    const percent = folder?.percentCompleted * 100 ?? 0;
    const circleColor = percent < 50 ? colors.warning : colors.green;
    return (
      <div
        className={styles.circle_container}
        style={{
          cursor: 'pointer',
          fontWeight: 600,
          color: 'black',
        }}
        onClick={() => handleWeekClick(folder, folderIndex)}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgressbarWithChildren
            strokeWidth={5}
            styles={{
              root: {
                width: '8vw',
                height: '8vw',
                maxWidth: 150,
                maxHeight: 150,
                minWidth: 50,
                minHeight: 50,
              },
              path: {
                stroke: circleColor,
              },
            }}
            value={percent}
          >
            <span style={{ fontSize: 20, color: circleColor }}>{percent + '%'}</span>
          </CircularProgressbarWithChildren>
        </div>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: 17,
          }}
        >
          {folder?.title}
        </div>
      </div>
    );
  };
  const ArrowRight = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -25 }}>
      <div style={{ flex: 1, minWidth: 35, maxWidth: 150, height: 5, backgroundColor: 'grey' }}></div>
      <div className={styles.triangleRight}></div>
    </div>
  );
  const renderBody = () => {
    return data?.[0]?.lessons
      ?.sort((a, b) => a.sortOrder - b.sortOrder)
      ?.map((lesson, index: number) => {
        const { id: lessonId } = lesson;

        return (
          <div style={{ position: 'relative' }}>
            <Row
              className={styles.col_folder_lesson}
              wrap={widthScreen < 576}
              style={{
                gap: 3,
                backgroundColor: `${index % 2 === 1 ? '' : '#f2f1ed '}`,
                // overflow: 'hidden',
              }}
              ref={(ref) => (rowRefs.current[index] = ref)}
            >
              <Col
                className={styles.col_lesson_content}
                style={{
                  backgroundColor: lesson.color ?? lessonColors[index],
                  // height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  width: '9vw',
                  borderRadius: 10,
                  fontWeight: 600,
                }}
              >
                {lesson.title}
              </Col>

              {lesson.presentations
                ?.sort((a, b) => a.sortOrder - b.sortOrder)
                ?.map((presentation) => {
                  const { id: presentationId } = presentation;
                  return (
                    <Col
                      key={presentationId}
                      style={{
                        width: widthScreen > 576 ? '14.4vw' : '100%',
                      }}
                    >
                      <Presentation presentation={presentation} color={lesson?.color ?? lessonColors[index]} />
                    </Col>
                  );
                })}
            </Row>
          </div>
        );
      });
  };

  const renderWeeks = () => {
    return (
      <Row align='middle' justify='space-between' gutter={[12, 12]} wrap={false}>
        {folders.map((folder, folderIndex) => (
          <>
            <Col flex={2}>
              <WeekCircle folder={folder} folderIndex={folderIndex} />
            </Col>
            {folderIndex < folders.length - 1 ? (
              <Col flex={1}>
                <ArrowRight />
              </Col>
            ) : (
              <></>
            )}
          </>
        ))}
      </Row>
    );
  };
  return (
    <>
      <div>{renderWeeks()}</div>
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
          localStorage.removeItem('lessonIndexSwimlane'); // Nhấn ra ngoài thì xóa storage.
          localStorage.removeItem('folderIndexSwimlane'); // Nhấn ra ngoài thì xóa storage.
        }}
        width='100vw'
        footer={null}
        closable={false}
        centered
        bodyStyle={{ padding: 0 }}
      >
        <Card
          title={<div style={{ color: colors.theme.button }}>{data?.[0]?.title}</div>}
          style={{ padding: 0, minHeight: '90vh' }}
          headStyle={{ fontSize: 21 }}
          bodyStyle={{ overflow: 'scroll', height: '90vh' }}
          extra={
            <Button
              type='primary'
              icon={<CloseOutlined />}
              danger
              onClick={() => {
                setVisible(false);
                localStorage.removeItem('folderIndexSwimlane'); // Nhấn nút đóng thì xóa storage.
                localStorage.removeItem('lessonIndexSwimlane'); // Nhấn nút đóng thì xóa storage.
              }}
            />
          }
        >
          {isLoading ? (
            <div
              style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Spin size='large' spinning={isLoading} />
            </div>
          ) : (
            <Col>{renderBody()}</Col>
          )}
        </Card>
      </Modal>
    </>
  );
}
