import axios from 'axios';

const authorizationKey = 'Basic 12C1F7EF9AC8E288FBC2177B7F54D';

function GetSideBarMenuItems() {
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://strapi.softech.cloud/menus?disabled=0&_sort=sortOrder',
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function GetForms() {
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://strapi.softech.cloud/forms/',
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function GetForm(id) {
  return new Promise((resolve, reject) => {
    axios({
      url: `https://strapi.softech.cloud/forms/${id}`,
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Trường hợp dùng file JSON tĩnh
function GetMenuItemsByJson() {
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://strapi.softech.cloud/menus.json',
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Trường hợp dùng file JSON tĩnh
function GetFormByJson(id) {
  return new Promise((resolve, reject) => {
    axios({
      url: `https://strapi.softech.cloud/forms/${id}.json`,
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function Query(sqlCommand, parameters = {}, applicationName = 'Default') {
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://server.sachso.edu.vn/api/v1.0/dynamic/query',
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters,
      },
    })
      .then((response) => {
        resolve(response.data.results);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function GetDataFromApi(url) {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'GET',
      headers: {
        Authorization: authorizationKey,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function QueryFirst(sqlCommand, parameters = {}, applicationName = 'Default') {
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://server.sachso.edu.vn/api/v1.0/dynamic/query/first',
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: applicationName,
      },
      data: {
        sqlCommand,
        parameters,
      },
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function GetProfileOfEBM(email) {
  return new Promise((resolve, reject) => {
    axios({
      url: 'https://server.sachso.edu.vn/api/v1.0/dynamic/query/first',
      method: 'POST',
      headers: {
        Authorization: authorizationKey,
        ApplicationName: 'Report',
      },
      data: {
        sqlCommand: 'p_API_EBM_GetUserProfile',
        parameters: {
          email,
        },
      },
    })
      .then((response) => {
        resolve(response.data.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default {
  Query,
  QueryFirst,
  GetForms,
  GetForm,
  GetFormByJson,
  GetSideBarMenuItems,
  GetMenuItemsByJson,
  GetDataFromApi,
  GetProfileOfEBM,
};
