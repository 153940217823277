import { Button, Col, Form, Input, message, Row, Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { InsertData } from './InsertData';
import { useQueryClient } from 'react-query';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import i18next from 'i18next';

const Nganh = ({ khoa, buttonCloseModal, data }) => {
  const [isOptionsKhoa, setOptionsKhoa] = React.useState();
  const [isDisableInput, setDisableInput] = React.useState(true);
  const loggedInUse = useSelector((state) => state.authReducer.loggedInUser);
  const queryClient = useQueryClient();
  const [proccessing, setProccessing] = React.useState(false);
  const [selectKhoa, setSelectKhoa] = React.useState(data[0]);

  const [form] = Form.useForm();
  const onFormatData = () => {
    const newArray = [];
    khoa?.map((item) => {
      newArray.push({
        value: item.id,
        label: item.categoryName,
      });
    });
    setOptionsKhoa(newArray);
  };
  const dataCheck = data.flatMap((item) => {
    return item.children || [];
  });

  const onFinish = async (values) => {
    setProccessing(true);
    const parameters = {
      ParentId: values.id_khoa,
      CategoryName: values.name,
      CreatedBy: loggedInUse?.id,
      ModeifiedBy: loggedInUse?.id,
      Notes: 'Nganh',
      code: values.code,
    };
    const response = await InsertData(parameters);
    form.resetFields();
    if (response) {
      queryClient.invalidateQueries('VIMARU_METADATA_Category').finally(() => {
        setTimeout(() => {
          setDisableInput(true);
          setProccessing(false);
        }, 1000);
      });
      message.success('Thêm dữ liệu thành công');
    }
    setTimeout(() => {
      buttonCloseModal?.();
    }, 500);
  };
  const handleChangeKhoa = (value) => {
    if (value) {
      setSelectKhoa(value);
      return setDisableInput(false);
    }
    return;
  };
  React.useEffect(() => {
    onFormatData();
  }, []);
  return (
    <Form onFinish={onFinish} form={form} layout='vertical'>
      <Form.Item name='id_khoa' label='Chọn khoa'>
        <Select
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
          showSearch
          onChange={handleChangeKhoa}
          placeholder='Chọn khoa'
          optionFilterProp='children'
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={isOptionsKhoa}
        />
      </Form.Item>
      <Row gutter={[12, 12]}>
        <Col lg={12} xs={24}>
          <Form.Item
            name='code'
            label='Mã chuyên ngành'
            rules={[
              {
                validator: (_, value) => {
                  if (value === undefined || value.trim() === '')
                    return Promise.reject(i18next.t('please enter', { field: i18next.t('code') }));
                  else {
                    const checkDuplicate = dataCheck?.some(
                      (item) =>
                        item?.code.trim().toLowerCase().replace(/\s+/g, ' ') ===
                          value.trim().toLowerCase().replace(/\s+/g, ' ') && item.parentId === selectKhoa,
                    );
                    if (checkDuplicate) return Promise.reject(i18next.t('unavailable', { field: i18next.t('Code') }));
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input disabled={isDisableInput} />
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          <Form.Item
            name='name'
            label='Tên chuyên ngành'
            rules={[{ required: true, message: 'Vui lòng nhập tên Chuyên Ngành' }]}
          >
            <Input disabled={isDisableInput} />
          </Form.Item>
        </Col>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: 20 }}>
        <DynamicButton
          htmlType='button'
          onClick={() => {
            form.resetFields();
            buttonCloseModal?.();
          }}
        >
          Đóng
        </DynamicButton>
        <DynamicButton type='primary' htmlType='submit' loading={proccessing}>
          Lưu
        </DynamicButton>
      </div>
    </Form>
  );
};

export default Nganh;
