import { Button, Drawer, Modal, Space, Table } from 'antd';
import { columnQuestion } from '../../../column';
import { useTranslation } from 'react-i18next';

//Vì đặt Modal bên trên modal không được nên phải chuyển Modal chọn caia hỏi Combine ra ngoài
const IndexCombine = ({
  dataQuestion,
  setIsOpenModalChooseQuestion,
  arrayDataChooseQuestionCombine,
}) => {

  const { t } = useTranslation('table');

  return (
    <div>
      <Space style={{ marginBottom: 5 }}>
        <Button
          onClick={() => {
            setIsOpenModalChooseQuestion(true);
          }}
        >
          {t('questionManagement.listQuestions')}
        </Button>
      </Space>
      <Table bordered style={{ width: '100%' }} columns={columnQuestion} dataSource={arrayDataChooseQuestionCombine} />
    </div>
  );
};

export default IndexCombine;
