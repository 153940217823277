import settings, { DOMAIN_IS_USE, IS_BASAO, IS_LX, IS_TRONGDONG } from 'constants/settings';
import React from 'react';
import { HiClipboardList } from 'react-icons/hi';
import { MdGroup as ClassIcon } from 'react-icons/md';

const URL_HuongDanSuDung = settings.IS_VIMARU
  ? 'https://cloud.softech.vn/storage/Data/VIMARU/Huong-dan-su-dung-cho-Sinh-vien.pdf'
  : settings.IS_MENARD
  ? 'https://cloud.softech.vn/storage/Data/MENARD/Huong-dan-su-dung-cho-Hoc-vien-Menard.pdf'
  : IS_BASAO || IS_TRONGDONG || IS_LX
  ? `${DOMAIN_IS_USE}/templates/pdfs/huong-dan-su-dung-demo.pdf`
  : null;

const fileHelp = {
  name: 'Hướng dẫn sử dụng',
  href: URL_HuongDanSuDung,
  icon: <HiClipboardList size={20} className='tw-text-blue-500' />,
  layout: '/lms',
  exact: true,
  roles: ['student'],
  showInSidebar: true,
  type: ['allowedRoutes'],
  sortOrder: 9999,
};

export default [
  {
    path: '/student/classes',
    name: 'Lớp học',
    miniName: 'C',
    component: React.lazy(() => import('pages/Student/Classes/Classes')),
    icon: <ClassIcon size={20} className='tw-text-yellow-500' />,
    layout: '/lms',
    exact: true,
    roles: ['student'],
    type: ['allowedRoutes'],
    showInSidebar: settings.IS_HCM,
    sortOrder: 10,
  },
  // GIAO DIỆN CŨ
  // {
  //   path: '/student/presentation',
  //   name: 'Bài giảng',
  //   miniName: 'C',
  //   component: React.lazy(() => import('modules/Presentation/Assignments')),
  //   icon: 'fas fa-book text-primary',
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['student'],
  //   showInSidebar: true,
  //   sortOrder: 15,
  // },
  // {
  //   path: '/student/presentation/:presentationId',
  //   component: React.lazy(() => import('modules/Presentation/Assignments')),
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['student'],
  // },
  // GIAO DIỆN MỚI
  {
    path: '/student/presentations',
    name: 'Bài giảng',
    miniName: 'C',
    component: React.lazy(() => import('pages/Student/Presentation/List')),
    icon: 'fas fa-book text-primary',
    layout: '/lms',
    exact: true,
    roles: ['student'],
    type: ['allowedRoutes'],
    showInSidebar: false,
    // sortOrder: 16,
    sortOrder: 15,
  },
  // {
  //   path: '/student/presentations/:presentationId',
  //   component: React.lazy(() => import('pages/Student/Presentation')),
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['student'],
  // },
  {
    path: '/student/presentations/:presentationId/slides/:slideId',
    component: React.lazy(() => import('pages/Student/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['student', 'teacher', 'manager', 'administrator'], // Học sinh và giáo viên đều có thể làm bài.
    type: ['allowedRoutes'],
  },
  {
    path: '/student/presentations/:presentationId/slides/:slideId/questions/:questionId',
    component: React.lazy(() => import('pages/Student/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['student', 'teacher', 'manager', 'administrator'], // Học sinh và giáo viên đều có thể làm bài.
    type: ['allowedRoutes'],
  },
  {
    path: '/student/presentations/:presentationId/slides/:slideId/questions/result',
    component: React.lazy(() => import('pages/Student/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['student', 'teacher', 'manager', 'administrator'], // Học sinh và giáo viên đều có thể làm bài.
    type: ['allowedRoutes'],
  },
  // {
  //   path: '/student/assignments',
  //   name: 'Bài tập',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Student/Assignments')),
  //   icon: <AssignmentIcon size={20} className='tw-text-green-500' />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['student'],
  //   showInSidebar: true,
  //   sortOrder: 20,
  // },
  {
    path: '/student/classes/:classId',
    component: React.lazy(() => import('pages/Student/Classes/ClassDetails')),
    layout: '/lms',
    exact: true,
    roles: ['student'],
    type: ['allowedRoutes'],
  },
  {
    path: '/student/classes/:classId/classwork/assignments/:assignmentId/details',
    component: React.lazy(() => import('pages/Student/Classwork/Assignments/Details')),
    layout: '/lms',
    exact: true,
    roles: ['student'],
    type: ['allowedRoutes'],
  },
].concat(URL_HuongDanSuDung ? [fileHelp] : []);
