import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { useQueryClient, useQuery } from 'react-query';
import { Button, Col, Form, Input, message, Row, Space, Spin, Table, TimePicker } from 'antd';
import { query } from 'helpers/QueryHelper';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { getDataGrade, SelectDataQuestion } from 'modules/Administration/ModuleAddQuestions';
import { columnQuestion } from './column';
import SearchDataQuestion from './Modules/SearchDataQuestion';
import settings from 'constants/settings';
import moment from 'moment';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import i18next from 'i18next';
import { getQuestions, getQuestions_UseQuery } from '../api/QuestionApi';

const ViewImportExam = ({ id, onCancel, typeContent, data, hiddenTitle, useCallbackQuestion,arrayDataChooseQuestionCombine }) => {
  const [formSaveExam] = Form.useForm();
  const queryClient = useQueryClient();
  const [isDisable, setIsDisable] = React.useState({
    buttonSelected: true,
    buttonUnselected: true,
    grade: true,
    hiddenForm: true,
    optionType: true,
  });
  const [isLoadingPage, setLoadingPage] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [unSelectedRowKeys, setUnSelectedRowKeys] = React.useState([]);
  const [dataTable, setDataTable] = React.useState([]); // Các câu hỏi lấy về từ Search
  const [arrayDataChoose, setArrayDataChoose] = React.useState([]); // Các câu hỏi được chọn
  const [isLoadingSaveExam, setIsLoadingSaveExam] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const [processing, setProcessing] = React.useState(false);

  const { data: dataOptionGrade, isLoading: loadingGrade } = getDataGrade();

  // Khi search câu hỏi mới, chỉ hiện những câu chưa được chọn
  const filterSearchQuestion = useMemo(
    () => dataTable.filter((ques) => !arrayDataChoose.some((i) => i.id === ques.id)),
    [dataTable, arrayDataChoose],
  );

  React.useEffect(() => {
    if (typeContent === 'update') {
      formSaveExam.setFieldsValue({
        title: data.quizTitle,
        duration: moment.utc(data?.duration * 1000),
      });
      setDuration(data?.duration);
      formatDataTableRight();
    } else if (typeContent === 'chooseQuestion') {
      setArrayDataChoose(arrayDataChooseQuestionCombine);
    } else {
      formSaveExam.setFieldsValue({
        title: '',
      });
      setArrayDataChoose([]);
    }
  }, [typeContent, data,arrayDataChooseQuestionCombine]);

  const onChooseQuestion = () => {
    selectedRowKeys.map((key) => {
      const filterQuestionById = dataTable.filter((item) => {
        return item.id === key;
      });
      const { isActive, ...newQuestionSelected } = filterQuestionById?.[0];
      setArrayDataChoose((prev) => [...prev, { ...newQuestionSelected, key: filterQuestionById?.[0]?.id }]);
    });
    setIsDisable((prev) => ({
      ...prev,
      buttonSelected: true,
    }));
    const unSelected = dataTable.filter((ar) => !selectedRowKeys.find((rm) => rm === ar.key));
    setDataTable(unSelected);
    setSelectedRowKeys([]);
    setUnSelectedRowKeys([]);
  };

  const onRemoveSelected = () => {
    const unSelected = arrayDataChoose.filter((ar) => !unSelectedRowKeys.find((rm) => rm === ar.key)); // Xóa các dữ liệu được chọn
    const selected = arrayDataChoose.filter((ar) => unSelectedRowKeys.find((rm) => rm === ar.key)); // Hiển thị các dữ liệu ban đầu
    setArrayDataChoose(unSelected);
    setDataTable((prev) => [...prev, ...selected]);
    setIsDisable((prev) => ({
      ...prev,
      buttonUnselected: true,
    }));
    setSelectedRowKeys([]);
    setUnSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setUnSelectedRowKeys([]);
    setIsDisable((prev) => ({
      ...prev,
      buttonSelected: false,
    }));
    if (newSelectedRowKeys?.length === 0) {
      return setIsDisable((prev) => ({
        ...prev,
        buttonSelected: true,
      }));
    }
  };

  const onChangeRemoveItem = (newSelectedRowKeys) => {
    setUnSelectedRowKeys(newSelectedRowKeys);
    setSelectedRowKeys([]);
    setIsDisable((prev) => ({
      ...prev,
      buttonUnselected: false,
    }));
    if (newSelectedRowKeys?.length === 0) {
      return setIsDisable((prev) => ({
        ...prev,
        buttonUnselected: true,
      }));
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_NONE],
  };

  const removeChecked = {
    selectedRowKeys: unSelectedRowKeys,
    onChange: onChangeRemoveItem,
    selections: [Table.SELECTION_NONE],
  };
  const onFinishSaveExam = (values) => {
    setIsLoadingSaveExam(true);
    const { title } = values;
    const TotalQuestion = arrayDataChoose?.length;
    const questionIdArray = JSON.stringify(arrayDataChoose.map((item) => item.id));
    let parameters = {
      QuizTitle: title,
      questionIdArray,
      TotalQuestion,
      timeExam: duration,
    };
    if (typeContent === 'update') {
      const objEditQuestion = { ...parameters, id: data?.id };
      query('p_QUIZCITY_EditQuiz_Examination', objEditQuestion).then(() => {
        message.success(
          i18next.t('update success', { field: i18next.t('table:assignmentManagement_worksheet.Excercise') }),
        );
        queryClient.invalidateQueries('p_QUIZCITY_GetQuizExamination');
        onCancel();
        formSaveExam.resetFields();
        setDataTable([]);
        setArrayDataChoose([]);
        setTimeout(() => setIsLoadingSaveExam(false), 3000);
      });
    } else if (typeContent === 'chooseQuestion') {
      // Trường hợp chọn câu hỏi cho type combine
      useCallbackQuestion(arrayDataChoose);
      formSaveExam.resetFields();
      setArrayDataChoose([]);
      setDataTable([]);
      setIsLoadingSaveExam(false);
        onCancel();
      } else {
      parameters = {
        ...parameters,
        // duration: duration,
      };

      const objAddQuestion = { ...parameters, ResourcesFolderId: id, ModuleId: settings.MODULE_ID };
      query('p_QUIZCITY_CreateQuiz_Examination', objAddQuestion).then(() => {
        message.success(
          i18next.t('create success', { field: i18next.t('table:assignmentManagement_worksheet.Excercise') }),
        );
        queryClient.invalidateQueries('p_QUIZCITY_GetQuizExamination');
        onCancel();
        formSaveExam.resetFields();
        setDataTable([]);
        setArrayDataChoose([]);
        setTimeout(() => setIsLoadingSaveExam(false), 3000);
      });
    }
  };
  const formatDataTableRight = () => {
    const newArray = [];
    data?.jsonData?.questions?.map((item) => {
      const filter = dataOptionGrade?.filter((grade) => {
        return grade?.code?.toUpperCase() === item.grade?.toUpperCase();
      });
      newArray.push({
        ...item,
        gradeName: filter?.[0]?.name,
        key: item.id,
      });
    });

    setArrayDataChoose(newArray);
  };

  const onSearchQuestion = async (values) => {
    setProcessing(true)
    const { grade, optionType, type, questionText, tag } = values;
    const data = await getQuestions({
      questionText: questionText,
      type,
      optionType,
      grade,
      unit: '',
      tag,
    })
    setDataTable(data);
    setProcessing(false);
  };

  return (
    <Spin spinning={isLoadingPage}>
      <Form form={formSaveExam} layout='vertical' onFinish={onFinishSaveExam}>
        {!hiddenTitle && (
          <>
            <Form.Item
              name='title'
              label='Title:'
              rules={[
                {
                  required: true,
                  message: i18next.t('please enter', { field: i18next.t('Title') }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}
      </Form>

      <SearchDataQuestion
        key={`search-question-form-import-exam`}
        isLoading={processing}
        onFinish={onSearchQuestion}
      />
      <div className='view-created-quiz' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Table
        rowKey={'id'}
          style={{ width: '45%' }}
          rowSelection={rowSelection}
          columns={columnQuestion}
          dataSource={filterSearchQuestion}
          loading={processing}
        />
        <div style={{ height: '100%', display: 'block' }}>
          <Button
            disabled={isDisable?.buttonSelected}
            onClick={() => onChooseQuestion()}
            icon={<DoubleRightOutlined />}
            style={{ width: '100%' }}
            type={!isDisable?.buttonSelected && 'primary'}
          />

          <Button
            disabled={isDisable?.buttonUnselected}
            onClick={() => onRemoveSelected()}
            icon={<DoubleLeftOutlined />}
            style={{ width: '100%', marginTop: 20 }}
            type={!isDisable?.buttonUnselected && 'primary'}
          />
        </div>
        <Table
        rowKey={'id'}
        style={{ width: '45%' }}
          rowSelection={removeChecked}
          columns={columnQuestion}
          dataSource={arrayDataChoose}
          loading={processing}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Space>
          <DynamicButton
            onClick={() => {
              onCancel();
            }}
          >
            {i18next.t('Close')}
          </DynamicButton>

          <DynamicButton
            type='primary'
            onClick={() => {
              formSaveExam.submit();
            }}
            loading={isLoadingSaveExam}
          >
            {i18next.t('Save')}
          </DynamicButton>
        </Space>
      </div>
    </Spin>
  );
};

export default ViewImportExam;
