/* eslint-disable react/prop-types */
import React from 'react';
import { useParams } from 'react-router';
import notificationWithIcon from 'components/Notification/notificationWithIcon';

import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { pdfToJPG } from '../../api';

const imgType = ['jpg', 'png', 'jpeg', 'pdf'];

function FileUpload({ onChange, setLoading }) {
  const params = useParams();
  const imgUpload = React.useRef();

  const handlePreview = async (e) => {
    const file = e.target.files[0];

    if (!file) return null;

    const type = file.type.split('/')[1];
    if (imgType.includes(type)) {
      // const defaultId = '358d8538-628d-471e-8ed5-5c337f4aa8cb';
      notificationWithIcon('warning', 'Thông báo', 'Ảnh đang được tải, xin vui lòng đợi!');
      setLoading(true);
      // const fetched = await fetcher(params.id);
      // if (fetched[0]?.id) {
      //   await removeFile(fetched[0].id);
      // }
      const pngFile = await pdfToJPG(file, params.id);
      if (!pngFile) {
        setLoading(false);
        return notificationWithIcon('warning', 'Thông báo', 'Tệp này bị lỗi hoặc kích thước quá nhỏ!');
      }
      localStorage.setItem('currentWorkSheetId', pngFile.id);
    } else {
      setLoading(false);
      return notificationWithIcon('warning', 'Thông báo', 'Tệp tin không phải là ảnh hoặc PDF!');
    }

    // // Xóa hình cũ, load hình mới
    // CanvasStorage.remove('CanvasObjects');
    onChange();
    notificationWithIcon('success', 'Thông báo', 'Tải ảnh hoàn tất.');
    setLoading(false);
    return null;
  };

  // React.useEffect(() => {
  //   fetcher(params.id);
  // });

  return (
    <div>
      <Button onClick={() => imgUpload.current.click()} icon={<UploadOutlined />} type='dashed' className='ml-2'>
        Upload image
      </Button>
      <input ref={imgUpload} style={{ display: 'none' }} type='file' onChange={handlePreview} />
    </div>
  );
}

export default FileUpload;
