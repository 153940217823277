/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get, query } from 'helpers/QueryHelper';
import { cdnServerUrl } from 'constants/serverUrls';
import { updateJsonData, updateWorksheetData } from './api';
import {
  addRect,
  canvasID,
  canvasToJson,
  configDeleteControl,
  configCopyControl,
  createCanvas,
  createExerciseData,
  keyToolbar,
  modeName,
} from './configuration';
import EditorElement from './EditorElement';
import Mouse from './models/mouse';
import ToolBar from './ToolBar';
import UploadFile from './UploadFile';
import { useSelector } from 'react-redux';
import { Button, Modal, Space } from 'antd';
import i18next from 'i18next';

const { SAVE, REMOVEALL, CANCEL, DONE, CHANGEMODE } = keyToolbar;
const getDataByID = (attachmentId, subModuleName, entityName) => {
  if (!attachmentId) return [];
  return get('Attachments', '*', { id: attachmentId }, 'CreatedDate DESC', 'SHARE').then((res) => res[0]);
};

// Trong module TeacherModul, File được tạo từ MYAMES, nên sách số phải gọi qua bên đó lấy dữ liệu.
const getDataByIDFromMyAMES = (attachmentsId) => {
  if (!attachmentsId) return [];
  return get(
    'Attachments',
    '*',
    { id: attachmentsId, entityName: 't_AMES247_Sessions' },
    'CreatedDate DESC',
    'SHARE',
    'MYAMES',
    'SmartEducation',
  ).then((res) => res[0]);
};

// useMyAMESData: cho phần quản lý bài tập, dữ liễu được lấy từ bên đó về.
function CanvasToolPage({
  idCurrent,
  entityId,
  subModuleName = 'CLASSWORK',
  entityName = 'Assignments',
  mode,
  worksheetFile,
  teacherId,
  useMyAMESData,
  setIdCurrent,
}) {
  const refMouse = useRef(new Mouse());
  const refToolBar = useRef({});
  const refEditorElement = useRef({});

  const [storeCanvas, setStoreCanvas] = useState({});
  const [canvas, setCanvas] = useState();

  const loggedInUser = useSelector((rootState) => rootState.authReducer.loggedInUser);

  //Lấy data từ api hoặc props
  useEffect(() => {
    const loadData = async () => {
      if (idCurrent) {
        //EDIT
        let dataByID;
        if (worksheetFile?.id) {
          dataByID = worksheetFile;
        } else {
          dataByID = useMyAMESData
            ? await getDataByIDFromMyAMES(idCurrent)
            : await getDataByID(idCurrent, subModuleName, entityName);
        }

        if (dataByID?.id) {
          const { id, fileUrl, jsonData } = dataByID;
          setStoreCanvas((pre) => {
            return { ...pre, id, backgroundImage: `${cdnServerUrl}${fileUrl}`, canvasJson: jsonData?.canvasJson };
          });
        }
      } else {
        //CREATE
        setStoreCanvas((pre) => ({
          ...pre,
          backgroundImage: '',
          canvasJson: '',
        }));
      }
    };
    //
    loadData();
  }, [idCurrent, worksheetFile]);

  //Render canvas
  useEffect(() => {
    const { backgroundImage, canvasJson } = storeCanvas;
    const canvi = createCanvas(backgroundImage, canvasJson);
    //
    setCanvas(canvi);
    configDeleteControl();
    configCopyControl();

    return () => {
      if (canvi) {
        canvi.dispose();
      }
    };
  }, [storeCanvas]);

  // canvas event - lắng nghe hành động chỉnh sửa để auto save
  useEffect(() => {
    const toolBar = refToolBar.current;
    const mouse = refMouse.current;
    if (typeof toolBar.getMode !== 'function') return undefined;
    // On: để khởi tạo lắng nghe sự kiện
    canvas?.on({
      // Chuột được nhấn
      'mouse:down': (e) => {
        mouse.eventDown(e);
      },
      // Chuột được thả
      'mouse:up': (e) => {
        const up = mouse.eventUp(e);
        const down = mouse.eventDown();
        const countActiveObjects = canvas.getActiveObjects().length;
        //
        if (!(down.target || up.target || countActiveObjects > 0)) {
          addRect(canvas, mouse, toolBar);
        }
      },
      'mouse:out': (e) => {
        if (e.target === null && toolBar.getMode() === '') {
          //
        }
      },
      'object:modified': () => {
        if (toolBar.getMode() === '') {
        }
      },
      //re-render
      'after:render': () => {
        const arrayObject = canvas.getObjects('rect');
        refEditorElement.current.setList(arrayObject);
      },
      // Tạo
      'object:added': () => {},
      // Xoá
      'object:removed': (e) => {
        handleSave();
      },
    });
    return () => {
      // Xóa khởi tạo lắng nghe sự kiện
      // removes all handlers for all events
      canvas?.off();
    };
  }, [canvas]);
  //
  const handleChangeImage = useCallback(
    (fileInfor) => {
      const { imgURL, id, fileName, fileSize, contentType } = fileInfor;

      const attachmentId = storeCanvas?.id ? storeCanvas?.id : id;
      const newStoreCanvas = { ...storeCanvas, backgroundImage: imgURL, id: attachmentId };
      if (!!newStoreCanvas?.canvasJson?.backgroundImage?.src) {
        //Cập nhật đường dẫn hình ảnh bên trong canvasJson
        newStoreCanvas.canvasJson.backgroundImage.src = imgURL;
        newStoreCanvas.ExerciseData = createExerciseData(newStoreCanvas.canvasJson.objects);
      }

      setStoreCanvas(newStoreCanvas);

      //Cập nhật hình mới
      updateWorksheetData({
        id: attachmentId,
        jsonData: JSON.stringify(newStoreCanvas),
        modifiedBy: loggedInUser?.id,
        fileName,
        FileUrl: imgURL.replace(cdnServerUrl, ''),
        fileSize,
        contentType,
        tags: 'QUIZCITY-WORKSHEET',
      });
      if (!idCurrent) {
        //Nếu đang tạo bài WS thì lưu id này ra ngoài
        setIdCurrent?.(id);
      }
    },
    [storeCanvas],
  );
  //
  const handleSave = useCallback(() => {
    // save and send data
    const canvasJson = canvasToJson(canvas);
    const ExerciseData = createExerciseData(canvasJson.objects);
    const newStoreCanvas = { ...storeCanvas, canvasJson, ExerciseData };
    // send
    const jsonData = JSON.stringify(newStoreCanvas);

    const TotalQuestion = newStoreCanvas?.ExerciseData?.length;
    updateWorksheetData({
      id: storeCanvas.id,
      jsonData,
      totalQuestion: TotalQuestion,
      modifiedBy: loggedInUser?.id,
    });
  }, [canvas, storeCanvas]);
  //
  const handleClickToolBar = useCallback(
    (key) => {
      const toolBar = refToolBar.current;
      switch (key) {
        case CANCEL:
          // delete objects belonging to the current group
          const groupCurrent = toolBar.getGroupName();
          const Objects = canvas.getObjects().filter((x) => x.data.groupName === groupCurrent);
          canvas.remove(...Objects);
          break;
        case DONE:
          handleSave();
          break;
        case CHANGEMODE:
          const groupMax = Math.max(...canvas.getObjects().map((x) => x.data.groupName), 0);
          toolBar.setGroupName(groupMax + 1);
          break;
        case REMOVEALL:
          canvas.remove(...canvas.getObjects());
          break;
        case SAVE:
          handleSave();
          break;

        default:
          break;
      }
    },
    [canvas, handleSave],
  );
  //
  return (
    <div className='tw-h-full'>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <div className='mr-2'>
          <UploadFile
            attachmentId={storeCanvas?.id}
            entityId={entityId}
            onChange={handleChangeImage}
            userId={teacherId}
            subModuleName={subModuleName}
            entityName={entityName}
          />
        </div>
        {storeCanvas.backgroundImage && <ToolBar refToolBar={refToolBar} onClickToolBar={handleClickToolBar} />}
      </div>
      <div style={{ maxHeight: '90%', overflow: 'auto' }}>
        <div style={{ position: 'relative' }}>
          <canvas id={canvasID} />
          <EditorElement refEditorElement={refEditorElement} canvas={canvas} />
        </div>
      </div>
    </div>
  );
}

CanvasToolPage.propTypes = {
  entityId: PropTypes.string,
  worksheetFile: PropTypes.object,
  teacherId: PropTypes.string | PropTypes.number,
  idCurrent: PropTypes.string,
  subModuleName: PropTypes.string,
  entityName: PropTypes.string,
  useMyAMESData: PropTypes.bool,
  setIdCurrent: PropTypes.func,
};

CanvasToolPage.defaultProps = {
  entityId: '',
  worksheetFile: {},
  teacherId: PropTypes.number,
  idCurrent: '',
  subModuleName: 'CLASSWORK',
  entityName: 'Assignments',
  useMyAMESData: false,
};

export default CanvasToolPage;
