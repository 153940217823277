/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import colors from 'constants/colors';
import _ from 'lodash';
import React from 'react';
import { AiOutlineSound as SpeakerIcon } from 'react-icons/ai';
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import QuestionHeader from '../QuestionHeader';

import AudioButton from '../AudioButton';
import './styles.css';

const alphabet = 'abcdefghijklmnopqrstxyuvz';
const number = '123456789';

function renderUnderline(item, data) {
  const underlined = data?.settings?.answers?.underlined ?? false;
  if (underlined) {
    const head = item.slice(0, item.length - 2);
    const tail = item.slice(head.length);
    item = `${head}<${tail}>`;
  }

  let flag = false;
  return item.split('').map((character, index) => {
    if (character === '<') {
      flag = true;
    }
    if (character === '>') {
      flag = false;
    }
    // Nếu là '<' hoặc '>' thì bỏ qua
    if (character === '<' && flag) return '';
    if (character === '>' && !flag) return '';

    return (
      <React.Fragment key={index}>
        {flag ? <span style={{ textDecoration: 'underline' }}>{character}</span> : character}
      </React.Fragment>
    );
  });
}

export default function OneChoice({ data, userData, questionIndex = 0, preview = false, ExplainText }) {
  const [selectedAnswer, setSelectedAnswer] = React.useState(null);
  const [correctedAnswer, setCorrectedAnswer] = React.useState(null);
  // Âm thanh
  const ref = React.useRef();
  ref.current = new Audio();

  // SHUFFED ANSWERS
  const [shuffedAnswers, setShuffedAnswers] = React.useState([]);
  React.useEffect(() => {
    if (userData) {
      const array = userData.answers;
      setShuffedAnswers(array);
      const corrected = _.find(array, (x) => x.isCorrect);
      setCorrectedAnswer(corrected);
      const selected = _.find(array, (x) => x.selected);
      setSelectedAnswer(selected);
    } else {
      const answers = data.answers.map((item, index) => {
        return { ...item, id: index + 1 };
      });
      const array = preview ? answers : _.shuffle(answers);
      setShuffedAnswers(array);
    }

    // Chế độ xem thử
    if (preview) {
      const correctAnswer = _.find(data.answers, (answer) => answer.isCorrect);
      setSelectedAnswer(correctAnswer);
    }
  }, [data, preview, userData]);

  // RENDER FUNCTIONS
  // RENDER QuestionText
  const renderQuestionText = () => {
    // if (data.questionText === null || data.questionText === undefined || data.questionText === '') {
    //   return <React.Fragment />;
    // }
    return (
      <div>
        <span style={{ fontSize: 18, color: 'black', lineHeight: 1, textAlign: 'justify' }}>
          {data?.questionText || data?.questionSub}
        </span>
      </div>
    );
  };

  // RENDER IMAGE
  const renderQuestionImage = () => {
    if (data.imageUrl === null || data.imageUrl === undefined || data.imageUrl === '') {
      return <React.Fragment />;
    }
    return (
      <div className='mb-4'>
        <img alt='' src={data.imageUrl} style={{ height: 200 }} />
      </div>
    );
  };

  // RENDER Question
  const renderQuestion = (selected) => {
    let renderResult = <React.Fragment />;
    if (data.question === null || data.question === undefined || data.question === '') {
      return renderResult;
    }
    // tìm kiếm '___' và chèn khoảng trắng, replace 1 lần nữa để tìm nếu dư khoảng trắng trong chuỗi.
    data.question = data.question.replace(/___/g, ' ___ ').replace(/\s+/g, ' ');
    data.question = _.replace(data.question, '.......', '___');
    if (data.question.includes('___')) {
      const words = data.question;
      // renderResult = words.replace(/___/g, ' ___ ').replace(/\s+/g, ' ');
      renderResult = words.split(' ').map((word, index) => {
        const flag = word === '___,';
        return (
          <React.Fragment key={index}>
            <span style={{ whiteSpace: 'nowrap' }}>
              {word === '___' || flag ? (
                selected !== null && selected !== undefined && selected !== null ? (
                  <span style={{ color: colors.theme.success, paddingBottom: 3, borderBottom: '1px dashed #adb5bd' }}>
                    {' '}
                    {selected?.answer}
                  </span>
                ) : (
                  <span style={{ paddingLeft: 120, paddingBottom: 3, borderBottom: '1px dashed #adb5bd' }} />
                )
              ) : (
                word
              )}
              {flag && ', '}
              &nbsp;
            </span>
          </React.Fragment>
        );
      });

      return <div className='questionText'>{renderResult}</div>;
    }

    // FOCUSED TEXT
    if (data.focusedWords) {
      const words = data.question;
      renderResult = words.split(' ').map((word, index) => {
        const found = _.find(data.focusedWords, (x) => {
          const a = x.replace('.', '');
          const b = word.replace('.', '');
          return a === b;
        });

        if (found) {
          return (
            <span key={`word-${index}`}>
              {word.split(found).map((item, i) => {
                return (
                  <React.Fragment key={`part-${i}`}>
                    {i !== 0 && (
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          color: '#f5365c',
                          paddingBottom: 3,
                          borderBottom: '1px dashed #adb5bd',
                        }}
                      >
                        {found}
                      </span>
                    )}
                    {item}
                  </React.Fragment>
                );
              })}
            </span>
          );
        }
        return <span key={`word-${index}`}>{word}&nbsp;</span>;
      });
    } else {
      renderResult = <span>{data.question}</span>;
    }

    return <div className='questionText'>{renderResult}</div>;
  };

  const renderImageAnswers = (selected) => {
    let direction = data.settings && data.settings.answers && data.settings.answers.direction;
    if (direction === null || direction === undefined) {
      direction = 'vertical';
    }

    let bulletType = data.settings && data.settings.answers && data.settings.answers.bulletType;
    if (bulletType === null || bulletType === undefined) {
      bulletType = 'character';
    }

    return (
      <div className='answers-onechoice answers-onechoice-horizontal'>
        {shuffedAnswers.map((item, index) => {
          const isSelected = selected && selected.id === item.id && selected.answer === item.answer;
          return (
            <div
              key={`answer-${index}`}
              className='tw-p-4 tw-mr-4'
              style={{
                cursor: 'pointer',
                borderRadius: 8,
                border: isSelected ? '2px solid #2dce89' : '2px dashed #adb5bd',
              }}
            >
              <img alt='' src={item.answer} style={{ height: 150 }} />
            </div>
          );
        })}
      </div>
    );
  };

  const renderAnswers = (selected) => {
    let direction = data.settings && data.settings.answers && data.settings.answers.direction;
    if (direction === null || direction === undefined) {
      direction = 'vertical';
    }

    let bulletType = data.settings && data.settings.answers && data.settings.answers.bulletType;
    if (bulletType === null || bulletType === undefined) {
      bulletType = 'character';
    }

    return (
      <div className={`answers-onechoice answers-onechoice-${direction}`}>
        {shuffedAnswers.map((item, index) => {
          const isSelected = selected && selected.id === item.id && selected.answer === item.answer;
          return (
            <Button
              key={`answer-${index}`}
              className={`answerButton-onechoice answerButton-onechoice-${direction}`}
              onClick={() => {
                if (item.audioUrl) {
                  ref.current.pause();
                  ref.current.currentTime = 0;
                  ref.current.src = item.audioUrl;
                  ref.current.play();
                }
              }}
              color={isSelected ? 'success' : 'secondary'}
            >
              {bulletType === 'character' && `${alphabet[index].toUpperCase()}. `}
              {bulletType === 'number' && `${number[index].toUpperCase()}. `}
              {bulletType === 'special characters' ? (
                <React.Fragment>{item.answer.trim() === 'up' ? '↗' : '↘'}</React.Fragment>
              ) : (
                renderUnderline(item.answer, data)
              )}
              {item.audioUrl && <SpeakerIcon size={24} style={{ marginTop: -4, verticalAlign: 'middle' }} />}
            </Button>
          );
        })}
      </div>
    );
  };

  return (
    <Card className='m-0'>
      <CardHeader>
        <QuestionHeader title={data.title} subTitle={data.subTitle} questionIndex={questionIndex} tooltip='OneChoice' />
      </CardHeader>

      <CardBody style={{ minHeight: 300 }}>
        {/* Question */}
        <AudioButton audioUrl={data.audioUrl} />
        {/* Question */}
        {renderQuestionText()}
        {renderQuestionImage(selectedAnswer)}
        {renderQuestion(selectedAnswer)}
        {/* Answers */}
        {data?.settings?.answers?.imageAnswers ? renderImageAnswers(selectedAnswer) : renderAnswers(selectedAnswer)}
      </CardBody>
      {ExplainText}
      <CardFooter>
        <h3>Đáp án đúng</h3>
        {data?.settings?.answers?.imageAnswers ? renderImageAnswers(correctedAnswer) : renderAnswers(correctedAnswer)}
      </CardFooter>
    </Card>
  );
}
