/* eslint-disable jsx-a11y/media-has-caption */
import moment from 'moment';
import React, { Dispatch, SetStateAction } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { Button } from 'reactstrap/lib';

type Props = {
  audioUrl?: string;
  className?: string;
  setAudio?: Dispatch<SetStateAction<any>>;
  preview?: boolean;
};

export default function AudioButton({ audioUrl, className, setAudio, preview = false }: Props) {
  const refAudio = React.useRef<HTMLAudioElement>(null);
  // const playAudio = React.useCallback(() => {
  //   if (preview) refAudio?.current?.play();
  // }, []);

  if (!audioUrl) return null;
  const handleSetAudio = () => {
    const keyElement = moment().format('YYYYMMDDHHmmss'); // thêm key nàyy để render lại audio khi ngườii dùng click lần2
    if (setAudio && typeof setAudio === 'function') {
      setAudio(
        <div key={keyElement} style={{ zIndex: 1 }}>
          <ReactAudioPlayer
            src={audioUrl}
            autoPlay
            controls
            controlsList='nodownload'
            style={{ height: 40, marginInline: 'auto' }}
            // onPlay={() => setIsPlay(true)}
            // onPause={() => setIsPlay(false)}
            // onEnded={() => {
            //   setIsPlay(false);
            // }}
          />
        </div>,
      );
    } else refAudio?.current?.play();
  };
  return (
    <div className={`mb-4 ${className}`}>
      <audio ref={refAudio} src={audioUrl} />
      <Button
        color={'warning'}
        onClick={() => {
          // playAudio();
          handleSetAudio();
        }}
      >
        <i className='fas fa-play' />
        &nbsp;&nbsp;Click to listen
      </Button>
    </div>
  );
}
