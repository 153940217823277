import Teacher_Classes_Details from 'pages/Teacher/Classes/ClassDetails';
import Teacher_Classes from 'pages/Teacher/Classes/Classes';
import React from 'react';
// Icons
import AssignmentsManagement from 'modules/TeacherModule/components/AssignmentsManagement/components';
import Users from 'pages/Administration/Users/index';
import { MdDoneOutline, MdGroup as ClassIcon, MdClass } from 'react-icons/md';
import IndexAddQuestion from 'pages/Administration/FormAddQuestions';
import IndexManageKhoaNganh from 'pages/Administration/ManageKhoaNganh';
import ManagerCourse from 'pages/Administration/ManagerCourse';
import StatisticalReport from 'assets/icon/homeIcons/StatisticalReportIcon.png';
import classManager from 'assets/icon/homeIcons/classManagerIcon.png';
import ReportFromWeekToWeek from 'pages/Administration/Reports';
import IndexImportQuestion from 'pages/Administration/ImportQuestion';
import IndexCategoryType from 'pages/Administration/Category';
import ConfigSettings from 'pages/Administration/ConfigSettings';
import { FaCogs } from 'react-icons/fa';
import colors from 'constants/colors';
import settings from 'constants/settings';
import { SiGoogleclassroom } from 'react-icons/si';
import { HiOutlinePresentationChartBar } from 'react-icons/hi';
import { IoLibraryOutline } from 'react-icons/io5';
import { TbReportAnalytics } from 'react-icons/tb';

export default [
  // {
  //   collapse: true,
  //   path: '/admin/report',
  //   name: 'Báo cáo, thống kê',
  //   miniName: 'C',
  //   icon: <TbReportAnalytics size={20} style={{ color: '#AC81FF' }} />,
  //   roles: ['administrator'],
  //   type: ['allowedRoutes'],
  //   showInSidebar: true,
  //   sortOrder: 4,
  //   state: `${settings.PATHNAME}/admin/report`,
  //   views: [
  //     {
  //       path: '/admin/report/quality',
  //       name: 'Chất lượng làm bài',
  //       miniName: 'D',
  //       component: React.lazy(() => import('pages/Administration/Reports/ReportPTTest/ReportQuality/index')),
  //       layout: `/${settings.PATHNAME}`,
  //       exact: true,
  //       roles: ['administrator'],
  //       type: ['allowedRoutes'],
  //       showInSidebar: true,
  //       sortOrder: 0,
  //     },
  //   ],
  // },
  {
    path: '/admin/users',
    name: 'Quản lý người dùng',
    miniName: 'C',
    icon: 'fa fa-user text-red',
    component: Users,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 0,
  },
  {
    path: '/admin/presentations',
    name: 'Quản lý bài giảng',
    miniName: 'C',
    component: React.lazy(() => import('pages/Teacher/Presentations')),
    icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500' />,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
  },

  {
    path: '/admin/presentation/:presentationId/create',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/presentation/:presentationId/edit',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    collapse: true,
    path: '/admin/worksheets',
    name: 'Quản lý thư viện',
    miniName: 'C',
    icon: <IoLibraryOutline size={20} className='tw-text-green-600' />,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
    state: `${settings.PATHNAME}/admin/worksheets`,
    views: [
      {
        path: '/admin/worksheets/list/:filter',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
      },
      // {
      //   path: '/admin/worksheets/list/quizcity-manual',
      //   name: 'Bài tập Manual',
      //   miniName: 'D',
      //   component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
      //   layout: `/${settings.PATHNAME}`,
      //   exact: true,
      //   roles: ['administrator'],
      //   type: ['allowedRoutes'],

      //   showInSidebar: true,
      //   sortOrder: 0,
      // },
      {
        path: '/admin/worksheets/list/quizcity-worksheet',
        name: 'Bài tập Handout',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],

        showInSidebar: true,
        sortOrder: 1,
      },
      // {
      //   path: '/admin/worksheets/list/quizcity-quiz',
      //   name: 'Bài tập Quiz',
      //   miniName: 'D',
      //   component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
      //   layout: `/${settings.PATHNAME}`,
      //   exact: true,
      //   roles: ['administrator'],
      //   type: ['allowedRoutes'],
      //   showInSidebar: true,
      //   sortOrder: 1,
      // },
      // {
      //   path: '/admin/worksheets/list/quizcity-exercise',
      //   name: 'Thư viện đề',
      //   miniName: 'D',
      //   component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
      //   layout: `/${settings.PATHNAME}`,
      //   exact: true,
      //   roles: ['administrator'],
      //   type: ['allowedRoutes'],
      //   showInSidebar: true,
      //   sortOrder: 1,
      // },
    ],
  },
  {
    path: '/admin/classes/:classId/classwork/assignments/add',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/edit/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/details/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/Details')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },
  // {
  //   path: '/admin/configs',
  //   name: 'Thiết lập thông tin',
  //   component: ConfigSettings,
  //   icon: <FaCogs style={{ color: '#EC4899' }} />,
  //   layout: `/${settings.PATHNAME}`,
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['administrationRouters'],
  //   showInSidebar: true,
  //   sortOrder: 5,
  // },
];
