import React from 'react';

import { Button, Form, Space, Tooltip, Tree } from 'antd';
import { PlusCircleOutlined, FileAddOutlined, CheckOutlined, DownOutlined } from '@ant-design/icons';
import SelectTypeOption from './Modules/SelectTypeOption';
import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import { SettingType } from './Modules/SettingTypeQuestion';
import { InsertData } from './Modules/Data';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import i18next from 'i18next';
const IndexAddQuestion = ({ buttonCloseModal }) => {
  const [form] = Form.useForm();
  const [keyActive, setKeyActive] = React.useState(0);
  const nameValue = Form.useWatch('name', form);
  const [activeField, setActiveField] = React.useState([]);
  const [isViewTreeData, setViewTreeData] = React.useState();

  React.useEffect(() => {
    scrollRef.current?.scrollIntoView({ behaviour: 'smooth' });
  }, [keyActive]);
  const useCallBackSetKey = React.useCallback((value) => {
    setKeyActive(value);
  }, []);

  const scrollRef = React.useRef();

  const onClickAddField = () => {
    setKeyActive((prev) => prev);
  };

  const viewTreeData = [];
  const renderTreeView = (value, key_array, index) => {
    viewTreeData.push({
      title: `Question ${index + 1}`,
      key: index,
      children: key_array?.map((key, indexKey) => {
        return {
          title: key,
          key: `${index}-${indexKey + 1}`,
          children: Array.isArray(value[key])
            ? value[key].map((valueChildren, indexValueChildren) => {
                return {
                  title: 'answer',
                  key: `${index}-${indexKey + 1}-${indexValueChildren + 1}`,
                  children: Object.keys(valueChildren)?.map((title, indexChildren) => ({
                    title: `${valueChildren[title] || false}`,
                    key: `${index}-${indexKey + 1}-${indexValueChildren + 1}-${indexChildren + 1}`,
                  })),
                };
              })
            : [
                {
                  title: value[key],
                  key: `${index}-${indexKey + 1}-${indexKey + 1}`,
                },
              ],
        };
      }),
    });
  };

  const onFinish = (values) => {
    values?.answers?.map((value, index) => {
      const settingType = SettingType(value?.type);
      let newArrayQuestion = {
        ...value,
        setting: settingType,
      };
      InsertData();
      const key_array = Object.keys(value);
      renderTreeView(value, key_array, index);
    });
    setViewTreeData(viewTreeData);
  };
  return (
    <div className='form__add--question'>
      <div className='form_display'>
        <Form form={form} onFinish={onFinish} name='dynamic_form_nest_item'>
          <div>
            <Form.List name='answers' initialValue={[{}]}>
              {(fields, { add, remove, move }) => (
                <div className='field-view'>
                  <div>
                    {fields?.map((field, key) => (
                      <Space ref={scrollRef} key={field.key}>
                        <SelectTypeOption
                          field={field}
                          fields={fields}
                          active={keyActive === key}
                          useCallBackSetKey={useCallBackSetKey}
                          index={key}
                          setRemoveField={remove}
                          setMove={move}
                          form={form}
                          setAdd={add}
                        />
                      </Space>
                    ))}
                  </div>
                </div>
              )}
            </Form.List>
          </div>
          <Form.Item>
            <DynamicButton onClick={() => buttonCloseModal?.()}>{i18next.t('Close')}</DynamicButton>
            <DynamicButton type='primary' onClick={() => form.submit()}>
              {i18next.t('Save')}
            </DynamicButton>
          </Form.Item>
        </Form>
      </div>
      <div className='preview-json'>
        <Tree showLine switcherIcon={<DownOutlined />} treeData={isViewTreeData} />
      </div>
    </div>
  );
};

export default IndexAddQuestion;
