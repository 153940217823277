import moment from "moment";

export const excelColumns = [
  {
    label: 'STT',
    value: 'index',
  },
  {
    label: 'Mã tham gia', // column name
    value: 'code', // column value
  },
  {
    label: 'Tên đề',
    value: 'examName',
  },
  {
    label: 'Ngày tạo đề',
    value: 'createdDate',
    // value: (col) => col.createdDate ? moment(col.createdDate).format('YYYY-MM-DD HH:mm') : '',
  },
  {
    label: 'Người tạo',
    value: 'createdBy',
  },
  {
    label: 'Trạng thái cấp',
    value: (col) => (col.statusAssigned ? 'Đã cấp' : 'Chưa cấp'),
  },
  {
    label: 'Tên người lấy mã',
    value: 'userGetPTCodeName',
  },
  {
    label: 'Mail người lấy mã',
    value: 'userGetPTCodeEmail',
  },
  {
    label: 'Chi nhánh',
    value: 'divisionName',
  },
  {
    label: 'Trạng thái kích hoạt',
    value: (col) => (col.statusActived ? 'Đã kích hoạt' : 'Chưa kích hoạt'),
  },
  {
    label: 'Người dùng',
    value: 'contactName',
  },
  {
    label: 'Số điện thoại người dùng',
    value: 'contactPhone',
  },
  {
    label: 'Email người dùng',
    value: 'contactEmail',
  },
  {
    label: 'Mã nhân viên (người dùng)',
    value: 'contactCode',
  },
  {
    label: 'Ngày kích hoạt',
    value: (col) => col.activeDate ? moment(col.activeDate).format('DD/MM/YYYY HH:mm') : '',
  },
  // {
  //   label: 'Số câu đúng /tổng số câu',
  //   value: 'result',
  // },
  {
    label: 'Tổng điểm',
    value: (col) => `${col.score?.toFixed(2)??''}`,
  },
  {
    label: 'Điểm tự luận',
    value: (col) => col.scoreEssay ? `${col.scoreEssay?.toFixed(2)??''}` : '',
  },
  {
    label: 'Điểm nghe',
    value: (col) => (
      `Online: ${col.listenScoreOnline?.toFixed(2)??'  '};
Offline: ${col.listenScoreOffline?.toFixed(2)??''}
      `
    ),
  },
  {
    label: 'Điểm nói',
    value: (col) => (
      `Online: ${col.speakScoreOnline?.toFixed(2)??'  '};
Offline: ${col.speakScoreOffline?.toFixed(2)??''}
      `
    ),
  },
  {
    label: 'Điểm đọc',
    value: (col) => (
      `Online: ${col.readScoreOnline?.toFixed(2)??'  '};
Offline: ${col.readScoreOffline?.toFixed(2)??''}
      `
    ),
  },
  {
    label: 'Điểm viết',
    value: (col) => (
      `Online: ${col.writeScoreOnline?.toFixed(2)??'  '};
Offline: ${col.writeScoreOffline?.toFixed(2)??''}
      `
    ),
  },
  {
    label: 'Ghi chú',
    value: 'note',
  },
];