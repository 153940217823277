import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';

const editUser = ({
  id,
  maSV,
  email,
  name,
  phone,
  status,
  roleId,
  sex,
  avatar,
  birthDay,
  schoolTypeId,
  khoa,
  nganh,
  classId,
  chucVu,
  shop,
  ngayVaoCongTy,
}: {
  id: number;
  maSV: string;
  email: string;
  name: string;
  phone: string;
  status: string;
  roleId: number;
  sex: 1 | 0;
  avatar: string;
  birthDay: string | null;
  schoolTypeId: number;
  khoa: string | null;
  nganh: string | null;
  classId: number | null;
  chucVu: string | null;
  shop: string | null;
  ngayVaoCongTy: string | null;
}) => {
  const parameters = {
    id,
    maSV,
    email,
    name,
    phone,
    status,
    roleId,
    sex,
    avatar, // không xoá được avatar, nếu truyền lên null || "" thì sẽ lấy avatar cũ
    birthDay,
    schoolTypeId,
    khoa,
    nganh,
    classId,
    chucVu,
    shop,
    ngayVaoCongTy,
  };
  return query('p_VIMARU_Users_Edit', parameters, settings.APPLICATION_NAME);
};

export default editUser;
//[p_VIMARU_Users_Edit]
// @Id INT,
// @MaSV VARCHAR(100),
// @Email NVARCHAR(250),
// @Name NVARCHAR(250),
// @Phone VARCHAR(50),
// @Status VARCHAR(50),
// @RoleId INT,
// @Sex BIT,
// @Avatar NVARCHAR(500),
// @BirthDay DATE = NULL,
// @schoolTypeId INT = NULL,
// @ModuleId UNIQUEIDENTIFIER,
// @Khoa UNIQUEIDENTIFIER = NULL,
// @Nganh UNIQUEIDENTIFIER = NULL,
// @ClassId INT = NULL,
// @ChucVu UNIQUEIDENTIFIER = NULL,
// @Shop UNIQUEIDENTIFIER = NULL,
// @NgayVaoCongTy DATE = NULL
