// const data = [
//   {
//     answers: [
//       { answer: 'n', isCorrect: false, },
//       { answer: 'l', isCorrect: false, },
//       { answer: 'm', isCorrect: true },
//     ],
//     audioUrl: 'https://cloud.softech.vn/storage/Data/Vocabulary/Sounds/US_Azure/march/march.mp3',
//     id: 1,
//     notes: 'Type40Dev',
//     option: 'MONOPOLYPHONIC',
//     settings: {
//       answers: {
//         bulletType: 'none',
//         direction: 'horizontal',
//       },
//     },
//     subTitle: 'Nghe và chọn âm trong có từ',
//     title: 'Listen and choose the correct sound',
//     type: 'OneChoice',
//   },
//   {
//     id: 2,
//     title: 'Fill the suitable word in the blank.',
//     subTitle: 'Điền từ thích hợp vào chỗ trống',
//     question: 'My ___ are collecting stamps and playing board games.',
//     answers: [
//       { id: 1, answer: 'subjects', isCorrect: false, },
//       { id: 2, answer: 'hobbies', isCorrect: true },
//       { id: 3, answer: 'sports', isCorrect: false, },
//     ],
//     settings: {
//       answers: {
//         // direction: 'horizontal',
//         direction: 'vertical',
//       },
//     },
//     type: 'OneChoice',
//   },
//   {
//     id: 3,
//     title: 'Choose the correct definition of the word.',
//     subTitle: 'Chọn định nghĩa đúng của từ.',
//     question: 'Pencil',
//     type: 'OneChoice',
//     answers: [
//       { id: 1, answer: 'a piece of furniture that consists of a flat top supported by legs', isCorrect: false, },
//       { id: 2, answer: 'a type of soft white stone', isCorrect: false, },
//       { id: 3, answer: 'a narrow pice of woord, of a metal of plastic case, containing a black or coloured substance,user for drawing of writing', isCorrect: true },
//     ],
//   },
// ];

// export default data;

const data = [
  {
    id: 1,
    title: 'Question 1. Read question and answer.',
    subTitle: 'What your name?',
    type: 'OneChoice',
    option: '',
    answers: [
      {
        answer: 'A. It test',
        isCorrect: true,
      },
      {
        answer: 'B. Test',
        isCorrect: false,
      },
      {
        answer: 'C. Do',
        isCorrect: false,
      },
      {
        answer: 'D. Answer',
        isCorrect: false,
      },
    ],
    Notes: '',
    explainText: ' Explain Text 1',
  },
  {
    id: 2,
    title: 'Question 2. Read question and answer.',
    subTitle: 'How old are you?',
    type: 'OneChoice',
    option: '',
    answers: [
      {
        answer: 'A. It test',
        isCorrect: false,
      },
      {
        answer: 'B. Test',
        isCorrect: true,
      }, {
        answer: 'C. Do',
        isCorrect: false,
      },
      {
        answer: 'D. Answer',
        isCorrect: false,
      },
    ],
    Notes: '',
    explainText: ' Explain Text 2',
  },
  {
    id: 3,
    title: 'Question 3. Read question and answer.',
    subTitle: 'Where are you from?',
    type: 'OneChoice',
    option: '',
    answers: [
      {
        answer: 'A. It test',
        isCorrect: false,
      },
      {
        answer: 'B. Test',
        isCorrect: false,
      },
      {
        answer: 'C. Do',
        isCorrect: true,
      },
      {
        answer: 'D. Answer',
        isCorrect: false,
      },
    ],
    Notes: '',
    explainText: ' Explain Text 3',
  },
  {
    id: 4,
    title: 'Question 4. Read question and answer',
    subTitle: 'What your name?',
    type: 'OneChoice',
    option: '',
    answers: [
      {
        answer: 'A. It test',
        isCorrect: true,
      },
      {
        answer: 'B. Test',
        isCorrect: false,
      },
      {
        answer: 'C. Do',
        isCorrect: false,
      },
      {
        answer: 'D. Answer',
        isCorrect: false,
      },
    ],
    Notes: '',
    explainText: ' Explain Text 4',
  },
  {
    id: 5,
    title: 'Question 5. Read question and answer',
    subTitle: 'How old are you?',
    type: 'OneChoice',
    option: '',
    answers: [
      {
        answer: 'A. It test',
        isCorrect: false,
      },
      {
        answer: 'B. Test',
        isCorrect: true,
      },
      {
        answer: 'C. Do',
        isCorrect: false,
      },
      {
        answer: 'D. Answer',
        isCorrect: false,
      },
    ],
    Notes: '',
    explainText: ' Explain Text 5',
  },
  {
    id: 6,
    title: 'Question 6. Read question and answer',
    subTitle: 'Where are you from?',
    type: 'OneChoice',
    option: '',
    answers: [
      {
        answer: 'A. It test',
        isCorrect: false,
      },
      {
        answer: 'B. Test',
        isCorrect: false,
      },
      {
        answer: 'C. Do',
        isCorrect: true,
      },
      {
        answer: 'D. Answer',
        isCorrect: false,
      },
    ],
    Notes: '',
    explainText: ' Explain Text 6',
  },
];

export default data;
