import React from 'react';
import { useQuery } from 'react-query';

import { query } from 'helpers/QueryHelper';
import DynamicForm from 'packages/DynamicForm/components/DynamicForm';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import formData from './formData';
import tableData from './tableData';
import { message, Spin } from 'antd';
import UserSearchForm from './searchForm';
import { IS_BASAO, IS_LX, IS_MENARD, IS_TRONGDONG } from 'constants/settings';
import tableDataMenard from './tableDataMenard';
import { getWidthOfString } from 'services/Text/widthText';
import { buildTreeSelectShops } from 'pages/Administration/Menard/ManagerShopsV2/tableColumn';
import _ from 'lodash';
import { queryExcel } from 'helpers/ExcelHelper';

type Parameters = {
  name: string;
  email: string;
  phone: string;
  roleId: number;
  status: string;
  maSV: string;
};

const fetcher = (parameters: Parameters) => () => {
  return query('p_VIMARU_USERS_Search', parameters);
};

type UsersProps = {
  isActive?: boolean;
  isTeacherRole?: boolean;
  paginationUsersTable?: any;
  rowSelectionUsersTable?: any;
  searchForm?: object;
  usersTable?: object;
  exportExcel: boolean;
  mode?: string;
  studentsId?: any;
};

const ClassUsers = ({
  isActive,
  isTeacherRole,
  paginationUsersTable,
  rowSelectionUsersTable,
  searchForm,
  usersTable,
  exportExcel,
  mode,
  studentsId,
}: UsersProps) => {
  const [parameters, setParameters] = React.useState<Parameters>({
    email: '',
    name: '',
    phone: '',
    maSV: '',
    roleId: isTeacherRole ? 2 : 1,
    status: isActive ? 'ACTIVE' : 'ACTIVE',
  });

  const [isLoadingPage, setIsLoadingPage] = React.useState<boolean>(false);
  const [handledData, setHandledData] = React.useState<any>(null);

  const key = ['Users', parameters];
  const { data, isLoading } = useQuery(key, fetcher(parameters));
  const [isHandling, setIsHandling] = React.useState<boolean>(true);

  const { data: shops } = useQuery('p_QUIZCITY_Shop_Category_Select', () => query('p_QUIZCITY_Shop_Category_Select'));
  const shopOptions = buildTreeSelectShops(shops);
  const widthOptionsShop = getWidthOfString(_.maxBy(shops, (i) => i?.categoryName?.length)?.categoryName, '14px') + 80;

  React.useEffect(() => {
    const handledShopData = IS_MENARD  || IS_BASAO || IS_TRONGDONG
      ? data?.map((item: any) => {
          const shopName = shops?.find((i) => i.id === item?.shop)?.categoryName || null;
          return {
            ...item,
            shopName,
          };
        })
      : data;
    switch (mode) {
      case 'ADD': {
        setHandledData(handledShopData?.filter((user: any) => !studentsId?.includes(user.id)));
        break;
      }
      case 'DELETE': {
        setHandledData(handledShopData?.filter((user: any) => studentsId?.includes(user.id)));
        break;
      }
    }
  }, [mode, data, studentsId]);

  React.useEffect(() => {
    if (handledData) setIsHandling(false);
  }, [handledData]);
  const exportToExcel = (storedProcedure: any, parameters: any, appName: string) => {
    return new Promise((resolve, reject) => {
      setIsLoadingPage(true);
      try {
        message.loading({ content: 'Đang xử lý dữ liệu...', key: 'updatable' });
        queryExcel(storedProcedure, parameters, appName)
          .then((data: any) => {
            message.success({
              content: `Kết quả: ${data?.results?.length} dữ liệu`,
              key: 'updatable',
              duration: 3,
            });
            setIsLoadingPage(false);
            resolve(data);
          })
          .catch((error) => {
            message.error({ content: `Có lỗi xảy ra: ${error}`, key: 'updatable', duration: 3 });
            setIsLoadingPage(false);
            reject(error);
          });
      } catch (error) {
        message.error({ content: `Có lỗi xảy ra: ${error}`, key: 'updatable', duration: 3 });
        setIsLoadingPage(false);
        reject(error);
      }
    });
  };
  return (
    <React.Fragment>
      <Spin spinning={isLoadingPage || isHandling}>
        <UserSearchForm setParameters={setParameters} shopOptions={shopOptions} widthOptionsShop={widthOptionsShop} />
        <DynamicTable
          rowKey='id'
          loading={isLoading && isHandling}
          dataSource={handledData}
          initialTableData={usersTable ?? (IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX ? tableDataMenard : tableData)}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            position: paginationUsersTable ?? ['topRight', 'bottomRight'],
          }}
          onReload={undefined}
          rowSelection={rowSelectionUsersTable}
        />
      </Spin>
    </React.Fragment>
  );
};

ClassUsers.defaultProps = {
  isActive: null,
  isTeacherRole: null,
  paginationUsersTable: null,
  rowSelectionUsersTable: null,
  searchForm: null,
  usersTable: null,
};

export default ClassUsers;
