export const excelColumns = [
  {
    label: 'Mã tham gia', // column name
    value: 'code', // column value
  },
  {
    label: 'Tên đề',
    value: 'examName',
  },
  {
    label: 'Người tạo',
    value: 'createdBy',
  },
  {
    label: 'Người dùng',
    value: 'contactName',
  },
  {
    label: 'Số điện thoại người dùng',
    value: 'contactPhone',
  },
  {
    label: 'Email người dùng',
    value: 'contactEmail',
  },
  {
    label: 'Ngày tạo đề',
    value: 'createdDate',
  },
  {
    label: 'Ngày kích hoạt',
    value: 'activeDate',
  },
  {
    label: 'Trạng thái',
    value: (col) => (col.status ? 'Đã kích hoạt' : 'Chưa kích hoạt'),
  },
  {
    label: 'Ghi chú',
    value: 'note',
  },
];