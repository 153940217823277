/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckOutlined,
  EditOutlined,
  EyeOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Modal, Popover, Row, Space, Tooltip } from 'antd';
import Axios from 'axios';
import notificationWithIcon from 'components/Notification/notificationWithIcon';
import colors from 'constants/colors';
import { add, get, query } from 'helpers/QueryHelper';
import withTeacherAuthenticator from 'HOCs/withTeacherAuthenticator';
import ExerciseForStudent from 'modules/AssignmentsManagement/components/Modal/ExerciseForStudent';
import CanvasToolPage from 'modules/WorkSheet/CanvasToolPlus/CanvasToolPage';
import ExcercisePage from 'modules/WorkSheet/CanvasToolPlus/ExcercisePage';
import Footer, { footerKey } from 'modules/WorkSheet/CanvasToolPlus/ExcercisePage/Footer';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React, { useCallback, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import FolderTreeView from './components/FolderTreeView';
import tableData from './tableData';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import i18next from 'i18next';

const fetcherAttachmentsFromMyAMES = (folderId) => () => {
  if (!folderId) return [];
  return query('dbo.p_MYAMES_SHARE_Attachments', { SessionId: folderId }, 'SmartEducation');
};

const fetcherAttachment = (attachmentId) => {
  return get('Attachments', '*', { id: attachmentId }, 'CreatedDate DESC', 'SHARE');
};

const addAttachment = (parameters) => {
  return add('Attachments', parameters, 'SHARE');
};

const Folders = ({ classId, loggedInUser }) => {
  const history = useHistory();
  const [refresh, setRefresh] = React.useState(new Date());
  const [selectedFolderId, setSelectedFolderId] = React.useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);
  const [worksheetFile, setWorksheetFile] = React.useState(null);

  const key = ['t_MYAMES_SHARE_Attachments', selectedFolderId, refresh];
  const { data, isLoading } = useQuery(key, fetcherAttachmentsFromMyAMES(selectedFolderId));

  const [modalVisible, setModalVisible] = React.useState(false);

  const [fileType, setFileType] = React.useState('worksheet');
  const [exerciseModalVisible, setExerciseModalVisible] = React.useState(false);
  const [isAttachmentExists, setIsAttachmentExists] = useState(false);

  // GIÁO VIÊN xem trước bài tập sẽ giao.
  const [previewExercise, setPreviewExercise] = useState(false);
  const [file, setFile] = React.useState(null);
  // Dùng trong Preview Exercise Modal.
  const refSubmit = useRef({});

  const [userRole, setUserRole] = React.useState({
    IsAdd: false,
    IsApproved: false,
    IsDelete: false,
    IsEdit: false,
    IsView: false,
    IsAdmin: false,
  });

  const [footerMode, setFooterMode] = useState('');
  //
  const [idCurrent, setidCurrent] = useState('');
  //
  const openEditor = React.useCallback((id, delay = 0) => {
    setTimeout(() => {
      setidCurrent(id);
      setFileType('worksheet');
      setFooterMode(id ? footerKey.EDIT : footerKey.CREATE); // id==='' => CREATE
      setModalVisible(true);
    }, delay);
  }, []);

  const { userId } = loggedInUser;

  const { IsApproved, IsAdmin } = userRole;

  const onChange = React.useCallback(
    async (id, status) => {
      const parameters = {
        id, // chính là attachmentId
        status: status ? 0 : 1,
        userId,
        sessionId: selectedFolderId, // chính là folderId
        assignmentId: 'CD14B8FA-4F63-4290-8EA6-3ED5C85B3562', // id live worksheet
      };
      setRefresh(new Date());
      query('p_MYAMES_SHARE_Attachments_Approved_V2', parameters);
    },
    [selectedFolderId, userId],
  );

  const renderCheckbox = useCallback(
    (text, record) => {
      if (IsAdmin) {
        if (IsApproved) {
          return <Checkbox defaultChecked={text} onChange={() => onChange(record.id, text)} />;
        }
        return text ? <CheckOutlined style={{ color: 'green', fontSize: 20 }} /> : null;
      }
      return text ? <CheckOutlined style={{ color: 'green', fontSize: 20 }} /> : null;
    },
    [IsAdmin, IsApproved, onChange],
  );

  const extraColumns = React.useMemo(() => {
    return [
      {
        key: 'status',
        title: 'Đã duyệt',
        width: '1%',
        sort: false,
        render: (text, record, index) => {
          return <div style={{ minWidth: '60px', textAlign: 'center' }}>{renderCheckbox(text, record)}</div>;
        },
      },
    ];
  }, [renderCheckbox]);

  const checkAttachmentIsExists = (id) => {
    fetcherAttachment(id).then((res) => setIsAttachmentExists(res.length !== 0));
  };

  return (
    <div>
      <Row gutter={[12, 0]}>
        <Col span={24} lg={8}>
          <div
            style={{ border: 'dashed 1px', borderColor: colors.border, padding: 12, minHeight: 400 }}
            className='rounded'
          >
            <FolderTreeView
              classId={classId}
              onSelect={(folder) => {
                if (folder) {
                  setSelectedFolderId(folder.id);
                } else {
                  setSelectedFolderId(undefined);
                }
              }}
            />
          </div>
        </Col>
        <Col span={24} lg={16}>
          <div
            style={{ border: 'dashed 1px', borderColor: colors.border, padding: 12, minHeight: 400 }}
            className='rounded'
          >
            {/* <div>
              {selectedFolderId && (
                <Row gutter={[16, 16]}>
                  <Col>
                    <UploadFiles
                      folderId={selectedFolderId}
                      onSuccess={() => {
                        setRefresh(new Date());
                      }}
                    />
                  </Col>
                  THẬN TRỌNG VÀ LƯU Ý, NHỮNG BÀI NÀY ĐÃ ĐƯỢC TẠO VÀ DUYỆT BÊN MYAMES, NẾU TẠO MỚI, DỮ LIỆU SẼ THAY ĐỔI BÊN MYAMES
                  NẾU TẠO: CẦN KIỂM TRA API VÀ THAM SỐ ĐẦU VÂOF BÊN MYAMES ĐỂ TRUYỀN CHO PHÙ HỢP
                  <Col>
                    <Button
                      type='primary'
                      onClick={() => {
                        openEditor('');
                      }}
                    >
                      Tạo bài tập chấm tự động
                    </Button>
                  </Col>
                </Row>
              )}
            </div> */}
            <DynamicTable
              loading={isLoading}
              dataSource={data}
              initialTableData={tableData}
              // extraColumns={extraColumns}
              expandable={{
                expandIconColumnIndex: 999,
                expandedRowKeys,
                columnWidth: '1%',
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <CaretUpOutlined onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                  ),
                expandRowByClick: true,
                onExpand: (expanded, record) => {
                  const keys = [];
                  if (expanded) {
                    keys.push(record.id);
                  }
                  setExpandedRowKeys(keys);
                },

                expandedRowRender: ({ id, fileName, fileUrl, tags, jsonData, createdBy }) => {
                  checkAttachmentIsExists(id);
                  return (
                    <div style={{}} className='d-flex'>
                      <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                        <div className='ml-2'>
                          <Tooltip title='Xem trước bài tập' placement='bottom'>
                            <Button
                              key='download-file'
                              icon={<EyeOutlined />}
                              onClick={() => {
                                if (tags === 'LIVEWORKSHEET') {
                                  setPreviewExercise(true);
                                  setFile({ id, fileName, jsonData });
                                } else {
                                  notificationWithIcon('warning', 'Thông báo', 'Tập tin này không thể xem trước !');
                                }
                              }}
                            >
                              {i18next.t('Preview')}
                            </Button>
                          </Tooltip>
                        </div>
                        <div className='ml-2'>
                          <Button
                            onClick={() => {
                              setExerciseModalVisible(true);
                              setWorksheetFile({ id, imgURL: `https://cloud.softech.vn${fileUrl}`, tags });
                            }}
                            key='Assignment'
                            type='default'
                            icon={<FileTextOutlined />}
                          >
                            {i18next.t('Assignment')}
                          </Button>
                        </div>
                        {/* THẬN TRỌNG VÀ LƯU Ý, NHỮNG BÀI NÀY ĐÃ ĐƯỢC DUYỆT BÊN MYAMES, NẾU SỬA TÊN BÀI, SẼ THAY ĐỔI BÊN MYAMES */}
                        {/* <div className='ml-2'>
                        <UpdateTitle entityId={id} setRefresh={setRefresh} userId={loggedInUser.userId} />
                      </div> */}
                      </div>

                      <div>
                        <div style={{ flex: 0, display: 'flex', justifyContent: 'flex-end' }}>
                          <Space>
                            {/* THẬN TRỌNG VÀ LƯU Ý, NHỮNG BÀI NÀY ĐÃ ĐƯỢC DUYỆT BÊN MYAMES, NẾU SỬA, BÀI SẼ THAY ĐỔI BÊN MYAMES */}
                            {/* <Tooltip title='Chỉnh sửa' placement='bottom'>
                            <Button
                              key='move-file'
                              shape='circle'
                              type='dashed'
                              icon={<EditOutlined />}
                              onClick={() => {
                                if (tags === 'LIVEWORKSHEET') {
                                  setWorksheetFile({ id, imgURL: `https://cloud.softech.vn${fileUrl}`, jsonData });
                                  setFile({ id, fileName, jsonData }); // Hỗ trợ chức năng xem trước của phần chỉnh sửa.
                                  //
                                  openEditor(id);
                                } else {
                                  // TODO: Move file to another folder
                                  // eslint-disable-next-line no-alert
                                  notificationWithIcon('warning', 'Thông báo', 'Tập tin này không thể chỉnh sửa !');
                                }
                              }}
                            />
                          </Tooltip> */}

                            {/* NẾU XÓA, THÌ PHẢI MỞ PROJECT MYAMES WEB, XEM THỬ HÀM XÓA BÊN KIA TRUYỀN THAM SỐ GÌ, Ở ĐÂY CHƯA CẬP NHẬT
                          NHƯNG THẬN TRỌNG VÀ LƯU Ý, NHỮNG BÀI NÀY ĐÃ ĐƯỢC DUYỆT BÊN MYAMES, NẾU XÓA, MẤT BÀI */}
                            {/* <Popconfirm
                            title='Bạn có muốn xóa tập tin này không?'
                            onConfirm={async () => {
                              // DELETE
                              await remove('Attachments', id, 'SHARE');
                              // DELETE FILENAME IN FOLDERS
                              // await deleteFile('Folders', selectedFolderId, fileName, 'CLASSES', 'SACHSO');
                              setRefresh(new Date());
                            }}
                            okText='Xác nhận'
                            cancelText='Không'
                          >
                            <Tooltip title='Xóa tập tin' placement='bottom'>
                              <Button key='delete-file' shape='circle' type='dashed' icon={<DeleteOutlined />} danger />
                            </Tooltip>
                          </Popconfirm> */}
                          </Space>
                        </div>
                      </div>
                    </div>
                  );
                },
              }}
            />
          </div>
        </Col>
      </Row>

      {exerciseModalVisible && (
        <ExerciseForStudent
          exerciseModalVisible={exerciseModalVisible}
          setExerciseModalVisible={setExerciseModalVisible}
          selectedFolderId={selectedFolderId}
          worksheetFile={worksheetFile}
        />
      )}

      {modalVisible && (
        <Modal
          width='95%'
          style={{ marginTop: 24 }}
          bodyStyle={{ padding: '10px 24px' }}
          centered
          visible={modalVisible}
          title='LIVE WORKSHEET'
          onCancel={() => {
            setModalVisible(false);
            setRefresh(new Date());
            setWorksheetFile(null);
          }}
          footer={
            <Footer
              onSubmit={() => refSubmit.current.submit()}
              tryAgain={() => refSubmit.current.tryAgain()}
              footerMode={footerMode}
              setFooterMode={setFooterMode}
            />
          }
        >
          <div style={{ height: '80vh' }}>
            {(() => {
              switch (true) {
                case fileType !== 'worksheet': {
                  return null;
                }
                case footerMode === footerKey.CREATE: {
                  return (
                    <CanvasToolPage
                      idCurrent=''
                      entityId={selectedFolderId}
                      worksheetFile={null}
                      teacherId={loggedInUser.userId}
                      onUpdateImage={(id) => openEditor(id, 1000)}
                    />
                  );
                }
                case footerMode === footerKey.EDIT: {
                  return (
                    <CanvasToolPage
                      idCurrent={idCurrent}
                      entityId={selectedFolderId}
                      worksheetFile={null}
                      teacherId={loggedInUser.userId}
                      onUpdateImage={(id) => openEditor(id, 1000)}
                      subModuleName='SHARE'
                      useMyAMESData
                    />
                  );
                }
                case footerMode === footerKey.PREVIEW: {
                  return <ExcercisePage file={null} refSubmit={refSubmit} idCurrent={idCurrent} isTeacher />;
                }
                default:
                  return null;
              }
            })()}
          </div>
        </Modal>
      )}

      {previewExercise && (
        <Modal
          width='95%'
          style={{ marginTop: 24 }}
          centered
          visible={previewExercise}
          className='workshetModal'
          title='BÀI TẬP LIVE WORKSHEET'
          onCancel={() => {
            setPreviewExercise(false);
          }}
          // footer={loggedInUser.userId && !refSubmit.current.isCompleted ? <Footer onSubmit={() => refSubmit.current.submit()} tryAgain={() => refSubmit.current.tryAgain()} text='Kiểm tra' /> : null}
          footer={
            <Footer
              onSubmit={() => refSubmit.current.submit()}
              tryAgain={() => refSubmit.current.tryAgain()}
              footerMode={footerKey.VIEW}
              setFooterMode={setFooterMode}
            />
          }
        >
          <div style={{ height: 'calc(80vh - 44px)' }}>
            {previewExercise && (
              <ExcercisePage
                file={file}
                studentId={loggedInUser.id}
                isCompleted={false}
                isTeacher
                refSubmit={refSubmit}
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default withTeacherAuthenticator(Folders);
//
const instance = Axios.create({
  baseURL: 'https://server.sachso.edu.vn/api/v1.0/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
    ApplicationName: 'SmartEducation',
  },
});
//
const UpdateTitle = ({ entityId, setRefresh, userId }) => {
  const [title, setTitle] = useState();
  //
  const handleSubmit = async () => {
    const parameters = {
      sqlCommand: '[dbo].[p_MYAMES_SHARE_Attachments_UpdateTitle]',
      parameters: {
        AttachmentId: entityId,
        title,
        userId,
      },
    };
    await instance.post('/dynamic/execute', parameters);
    setRefresh(new Date());
  };

  return (
    <Popover
      content={
        <div>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          <div className='d-flex justify-content-end mt-1'>
            <DynamicButton size='small' type='primary' onClick={handleSubmit}>
              Cập nhật
            </DynamicButton>
          </div>
        </div>
      }
      title='Cập nhật tiêu đề '
      trigger='click'
    >
      <DynamicButton icon={<EditOutlined />}>Chỉnh sửa tên file</DynamicButton>
    </Popover>
  );
};
