import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Space } from 'antd';
import TeacherContainer from 'components/Containers/TeacherContainer';
import StudentContainer from 'components/Containers/StudentContainer';
import { ReloadOutlined, SendOutlined } from '@ant-design/icons';
import AdministratorContainer from 'components/Containers/AdministratorContainer';
import settings from 'constants/settings';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import i18next from 'i18next';

function Footer({ onSubmit, tryAgain, footerMode, setFooterMode, audio, loggedInUser }) {
  const [isDoing, setIsDoing] = useState(true);

  const handleSubmit = () => {
    onSubmit();
    setIsDoing(false);
  };
  //
  const handletryAgain = () => {
    tryAgain();
    setIsDoing(true);
  };

  const renderButton = () => {
    const { EDIT, PREVIEW, VIEW, CREATE } = footerKey;
    switch (footerMode) {
      case EDIT: {
        return (
          <DynamicButton
            onClick={() => {
              setIsDoing(true);
              setFooterMode(PREVIEW);
            }}
          >
            {i18next.t('Preview')}
          </DynamicButton>
        );
        // break;
      }
      case PREVIEW: {
        return (
          <Space>
            <DynamicButton type='primary' onClick={() => setFooterMode(EDIT)}>
              {i18next.t('Edit')}
            </DynamicButton>

            {isDoing === false ? (
              <DynamicButton type='primary' onClick={handletryAgain}>
                {i18next.t('Remake')}
              </DynamicButton>
            ) : (
              <DynamicButton type='primary' onClick={handleSubmit}>
                {i18next.t('Check')}
              </DynamicButton>
            )}
          </Space>
        );
        // break;
      }
      case VIEW: {
        return (
          <Space>
            {/* <Button Button type='primary' onClick={() => setFooterMode(EDIT)}>Chỉnh sửa</Button> */}

            {isDoing === false ? (
              <DynamicButton type='primary' onClick={handletryAgain}>
                {i18next.t('Remake')}
              </DynamicButton>
            ) : (
              <DynamicButton type='primary' onClick={handleSubmit}>
                {i18next.t('Check')}
              </DynamicButton>
            )}
          </Space>
        );
        // break;
      }
      case CREATE: {
        return null;
        // break;
      }
      default: {
        return (
          <DynamicButton type='primary' onClick={handleSubmit}>
            {i18next.t('Submit')}
          </DynamicButton>
        );
        // break;
      }
    }
  };

  const renderStudentButton = () => {
    const { VIEW } = footerKey;
    switch (footerMode) {
      case VIEW: {
        return (
          <Space>
            {isDoing === false && (
              <DynamicButton type='dashed' onClick={handletryAgain} icon={<ReloadOutlined />}>
                {i18next.t('Remake')}
              </DynamicButton>
            )}
            <DynamicButton type='primary' onClick={handleSubmit} icon={<SendOutlined />}>
              {i18next.t('Submit')}
            </DynamicButton>
          </Space>
        );
        // break;
      }
      default: {
        return (
          <DynamicButton type='primary' onClick={handleSubmit}>
            {i18next.t('Submit')}
          </DynamicButton>
        );
        // break;
      }
    }
  };

  return (
    <>
      <Row>
        <Col span={8}>
          <div />
        </Col>
        <Col span={8} className='d-flex justify-content-center align-items-center'>
          {footerMode === footerKey.PREVIEW && <div id='Footer-ExcercisePage-Audio'>{audio}</div>}
          {footerMode === footerKey.VIEW && <div id='Footer-ExcercisePage-Audio'>{audio}</div>}
        </Col>
        <Col span={8}>
          {(loggedInUser?.role === 'administrator' ||
            loggedInUser?.role === 'manager' ||
            ((settings.IS_PT || settings.IS_HANDOUT || settings.IS_LG) && loggedInUser?.role === 'teacher')) && (
            <div>{renderButton()}</div>
          )}
          <StudentContainer>
            <div>{renderStudentButton()}</div>
          </StudentContainer>
        </Col>
      </Row>
    </>
  );
}

Footer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  tryAgain: PropTypes.func.isRequired,
  footerMode: PropTypes.string,
  setFooterMode: PropTypes.func,
};

Footer.defaultProps = {
  footerMode: PropTypes.string,
  setFooterMode: PropTypes.func,
};

export default Footer;

//
export const footerKey = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  PREVIEW: 'PREVIEW',
};
