import colors from 'constants/colors';
import vimaruColors from 'constants/vimaru';
import { query } from 'helpers/QueryHelper';
import withAuthenticator from 'HOCs/withAuthenticator';
import React from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';

function HandoutHeader({ loggedInUser, name, parentName }) {
  return (
    <>
      <div
        className='header pb-6'
        style={{ backgroundColor: colors.theme.background, borderTop: `1px solid ${colors.theme.background}` }}
      >
        <Container fluid>
          <div className='header-body'>
            <Row className='align-items-center py-4'>
              <Col lg='6' xs='7'>
                <h6 className='h2 text-white d-inline-block mb-0'>{name}</h6>
                <Breadcrumb
                  className='d-none d-md-inline-block ml-md-4'
                  listClassName='breadcrumb-links breadcrumb-dark'
                >
                  <BreadcrumbItem>
                    <a href='#pablo' onClick={(e) => e.preventDefault()}>
                      <i className='fas fa-home' />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href='#pablo' onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default withAuthenticator(HandoutHeader);
