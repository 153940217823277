import React from 'react';
import { Descriptions } from 'antd';
import { queryWithoutModuleId } from 'helpers/QueryHelper';
import { useQuery } from 'react-query';
import colors from 'constants/colors';
import i18next from 'i18next';

type Props = {
  studentId: string | number;
};

const fetcher = (studentId: string | number) => () => {
  return queryWithoutModuleId('p_SACHSO_USERS_GetById', { id: studentId });
};

const StudentInfomationHeader = ({ studentId }: Props) => {
  const QUERY_KEY = ['p_SACHSO_USERS_GetById', studentId];
  const { data, isSuccess } = useQuery(QUERY_KEY, fetcher(studentId));

  return (
    <React.Fragment>
      {isSuccess && (
        <Descriptions
          labelStyle={{ fontWeight: 600, color: colors.font, width: '1%', whiteSpace: 'nowrap' }}
          layout='vertical'
          bordered
          column={1}
          size='small'
        >
          <Descriptions.Item label={i18next.t("Student's full name")}>{data?.[0]?.fullName}</Descriptions.Item>
          {/* <Descriptions.Item label='Điện thoại liên lạc'>{data?.[0].phone ?? ''}</Descriptions.Item> */}
          <Descriptions.Item label='Email'>{data?.[0]?.email ?? ''}</Descriptions.Item>
        </Descriptions>
      )}
    </React.Fragment>
  );
};
export default StudentInfomationHeader;
