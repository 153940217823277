/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import colors from 'constants/colors';
import settings, { IS_PT } from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

function Result({ data, scoreWeight = 0 }) {
  const showResult = (type) => {
    if (!type) return <b>0</b>;
    return <b>{type.resultString}</b>;
  };
  //
  const renderResult = useCallback(() => {
    const list = [
      { title: 'Fill in the gaps:', name: 'writeResult', color: '#006EE6' },
      { title: 'Multiple choice:', name: 'multipleChoiceResult', color: '#d93434' },
      { title: 'Multiple choice 2:', name: 'multipleChoice2Result', color: '#300370' },
      { title: 'One choice:', name: 'oneChoiceResult', color: '#d93434' },
      { title: 'Matching:', name: 'matchResult', color: '#4DA5AD' },
      { title: 'Multiple matching:', name: 'multipleMatchResult', color: '#4DA5AD' },
      { title: 'SelectWord:', name: 'selectWordResult', color: '#4DA5AD' },
      { title: 'DropDown:', name: 'dropDownResult', color: '#4D05AD' },
      { title: 'DragDrop:', name: 'dragdropResult', color: '#4DA5AD' },
      { title: 'SpeechRecognition:', name: 'speechRecognitionResult', color: '#8b4513' },
    ];
    return list.map(({ title, name, color }) => {
      if (!data?.[name]) return null;
      return (
        <div
          key={name}
          className='mr-2 mb-0 py-1 px-2'
          style={{ fontSize: 15, borderRadius: 3, fontWeight: 600, border: `1px dashed ${color}` }}
        >
          <span style={{ marginRight: 10 }}>{title}</span>
          <span style={{ color }}>{showResult(data[name])}</span>
        </div>
      );
    });
  }, [data]);
  // Nếu điểm là 6.56454 => 6.56 , 6.001 => 6
  const formatScore = (score) => {
    const a = (score * 100).toFixed(0);
    if ((a % 100) === 0) return score.toFixed(0)
    else if ((a % 10) === 0) return score.toFixed(1)
    else return score.toFixed(2);
  };
  // tính điểm theo kết quả từng type trong data
  const totalScores = totalScore(data);
  const totalScoresPT = (totalScores / 10) * scoreWeight;

  return (
    <div className='d-flex flex-md-row flex-column mt-2 align-items-center' style={{ width: '93%' }}>
      <div
        className='mr-2 mb-0 py-1 px-2 d-flex justify-content-center align-items-center'
        style={{ fontSize: 15, fontWeight: 600 }}
      >
        <span className='mr-2'>Score: </span>
        <div
          className='d-flex justify-content-center align-items-center'
          style={{
            color: 'white',
            borderRadius: '50%',
            width: settings.IS_PT ? 'auto' : 60,
            minWidth: 60,
            height: 60,
            fontFamily: 'HandWriting',
            fontSize: 24,
            fontWeight: 600,
            overflowX: 'auto',
            maxWidth: 100,
            background: totalScores < 5 ? colors.theme.danger : colors.theme.success,
          }}
        >
          <b style={{ padding: 5 }}>{IS_PT ? formatScore(totalScoresPT) : formatScore(totalScores)}</b>
        </div>
      </div>
      {renderResult()}
    </div>
  );
}

Result.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Result;
//
export const totalScore = (data) => {
  const total = SumStringToArray(data, 'resultString');

  const score = (total[0] * 10) / total[1];
  //Không làm tròn để tính điểm bước sau cho chính xác nhất
  return Number.isNaN(score) ? 0 : score;
};

export const SumStringToArray = (data, key) => {
  if (!data) return [0, 0];
  // 1/2 + 0/1 +2/3 = [3,6]
  return Object.values(data).reduce(
    (total, item) => {
      const res = item[key].split('/');
      return total.map((x, i) => x + Number(res[i]));
    },
    [0, 0],
  );
};
