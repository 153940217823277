const formatDate = 'DD/MM/YYYY';
const formatDateTime = 'DD/MM/YYYY HH:mm';
const formatDateQuery = 'YYYY-MM-DD';
const formatDateTimeQuery = 'YYYY-MM-DD HH:mm:ss';
const formatNumber = '0,0'; // 1500 => 1.500
const formatDecimal = '0.00';

export {
    //Date
    formatDate,
    formatDateTime,
    formatDateQuery,
    formatDateTimeQuery,
    //Numeral
    formatNumber,
    formatDecimal
};