import { Modal } from 'antd';
import colors from 'constants/colors';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import './index.css';
import settings from 'constants/settings';
import { steps } from './stepsBooking';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
const CoachingGuideModal = ({ visible, setVisible }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Modal mở lên luôn hiện trang đầu tiên trước
  useEffect(() => {
    if (visible) setCurrentSlide(0);
  }, [visible]);

  // const modalContent = () => {
  //   return (
  //     <>
  //       <div className='coaching-guide-modal'>
  //         <p>
  //           To make sure you experience the coaching session most conveniently and effectively, let’s keep in mind the
  //           following guidelines:
  //         </p>
  //         <ol style={{ listStyle: 'decimal', paddingLeft: 24, marginBottom: 0 }}>
  //           <li>
  //             <p>The learner shall book coaching ONLY after completion of learning and test sessions.</p>
  //           </li>
  //           <li>
  //             <p>
  //               Each learner has 01 session of <span style={{ fontWeight: 'bold' }}>30 – minute</span> coaching during
  //               the course.{' '}
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               {' '}
  //               The learner should book coaching schedule at least{' '}
  //               <span style={{ fontWeight: 'bold' }}>10 days in advance</span> and give several options of schedule for
  //               TOEIC Trainers to choose one easily.{' '}
  //             </p>
  //           </li>
  //           <li>
  //             <p> The learner may check booking status in this session to get the progress.</p>
  //           </li>
  //           <li>
  //             <p>
  //               {' '}
  //               Results of coaching booking will be sent via company email or displayed in booking status, including
  //               coach’s information, time and platforms to coach within 07 working days.
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               {' '}
  //               <span style={{ fontWeight: 'bold' }}>Coaching 1-1:</span> 1 learner – 1 TOEIC Trainer
  //             </p>
  //           </li>
  //           <li>
  //             <p>
  //               {' '}
  //               Coaching sessions shall be carried out on one of the following platforms:{' '}
  //               <span style={{ fontWeight: 'bold' }}>Microsoft Team, Google Meet, Zoom</span> based on convenience and
  //               agreement of both learners and coach. Each coaching session shall be recorded full time for admin’s
  //               supervision and support and the learner takes it as reference for later practice.{' '}
  //             </p>
  //           </li>
  //         </ol>
  //       </div>
  //     </>
  //   );
  // };
  return (
    <Modal
      title={i18next.t('CoachingGuide')}
      open={visible}
      onCancel={() => setVisible(false)}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* <DynamicButton
            
            onClick={() => setCurrentSlide(f => f - 1)}
          >
            {currentSlide > 0 ? 'Back' : ''}

          </DynamicButton>
          <DynamicButton
            
            onClick={() => {
              if (currentSlide + 1 === steps.length) setVisible(false);
              else setCurrentSlide(f => f + 1);
            }}
            type='primary'
          >
            {currentSlide + 1 === steps.length ? 'Ok, Got it!' : 'Next'}
          </DynamicButton> */}
        </div>
      }
      centered
      width={700}
      // width={'80vw'}
      bodyStyle={{ maxHeight: 'calc(100vh - 130px)', overflow: 'auto' }}
    >
      {steps[currentSlide]?.content}
      {/* {modalContent()} */}
    </Modal>
  );
};

export default CoachingGuideModal;
