import { Image } from 'antd';

/* eslint-disable jsx-a11y/media-has-caption */
type Props = {
  imageUrl: string;
};

export default function QuestionImage({ imageUrl }: Props) {
  if (!imageUrl) return null;
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginBlock: 10 }}>
      <Image src={imageUrl} alt='Sách số - Images' style={{ height: '44vh' }} />
    </div>
  );
}
