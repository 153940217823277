import { Form, Input, Radio, Space } from 'antd';
import { Item } from 'rc-menu';
import React from 'react';
import InputForm from './InputForm';

const AddInput = ({ form, content, fieldC, view }) => {
  const [isCorrect, setCorrect] = React.useState();
  const [selected, setSelected] = React.useState(0);
  const [value, setValue] = React.useState(1);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className='items-question '>
      <Form.List {...fieldC} name={[fieldC.name, 'question']} initialValue={[{}]}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields?.map((field, key) => {
                return (
                  <>
                    <InputForm
                      setCorrect={setCorrect}
                      isCorrect={isCorrect}
                      form={form}
                      fields={fields}
                      field={field}
                      setAdd={add}
                      setRemove={remove}
                      index={key}
                      selected={selected}
                      onSelected={setSelected}
                      view={view}
                    />
                  </>
                );
              })}
            </>
          );
        }}
      </Form.List>
    </div>
  );
};

export default AddInput;
