import { Input, Form, Checkbox, Radio } from 'antd';

import React from 'react';
import * as IconAntd from '@ant-design/icons';
import { normFile, PropsUploadFile } from './UploadFile';

const InputForm = ({ isCorrect, setCorrect, fields, index, field, setAdd, setRemove, onSelected, view }) => {
  const [isFocus, setFocus] = React.useState(false);
  const { props } = PropsUploadFile();

  const onChangeValue = (e) => {
    setCorrect(e.target.value);
    onSelected(e.target.value);

    var checkBox = document.getElementsByName('isCorrect2');
    Array.prototype.forEach.call(checkBox, function (el) {
      el.checked = false;
    });
    e.target.checked = true;
  };

  const RenderView = () => {
    return (
      <>
        {view.map((item, indexView) => {
          switch (item) {
            case 'checkBox':
              return (
                <Form.Item name={[field.name, 'isCorrect']} valuePropName='checked'>
                  <Checkbox
                    name='isCorrect2'
                    defaultChecked={false}
                    checked={Number(isCorrect) === index}
                    value={index}
                    onChange={(e) => onChangeValue(e)}
                  />
                </Form.Item>
              );
            case 'input':
              return (
                <Form.Item style={{ width: '100%' }} name={[field.name, 'answer']}>
                  <Input
                    autoFocus={true}
                    onPressEnter={(e) => {
                      e.preventDefault();
                      setAdd?.();
                      setFocus(!isFocus);
                    }}
                    placeholder='Answer'
                    className='input'
                    style={{ outline: 'none', fontSize: 18 }}
                  />
                </Form.Item>
              );
            case 'image':
              return (
                <Form.Item name={[field.name, 'image']} getValueFromEvent={normFile}>
                  <Upload name='files' listType='picture' multiple={false} {...props}>
                    <Button icon={<FileImageOutlined />}></Button>
                  </Upload>
                </Form.Item>
              );

            default:
              return;
          }
        })}
      </>
    );
  };
  return (
    <div className='list_question' key={index}>
      <RenderView />
      {fields?.length > 1 ? (
        <IconAntd.CloseOutlined style={{ fontSize: 20 }} onClick={() => setRemove(field?.name)} />
      ) : (
        <div style={{ width: 20 }}></div>
      )}
    </div>
  );
};

export default InputForm;
