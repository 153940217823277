import { Button, Col, Modal, Row, Spin, notification } from 'antd';
import Card from 'components/Containers/Card';
import SimpleContainer from 'components/Containers/SimpleContainer';
import StudentContainer from 'components/Containers/StudentContainer';
import NoData from 'components/NoData';
import colors from 'constants/colors';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import getFirstQuestionId from 'modules/Presentation/api/getFirstQuestionId';
import React, { useEffect, useState } from 'react';
import { AiFillPlayCircle } from 'react-icons/ai';
import { FiFileText, FiVideo } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import color from '../../../constants/colors';
import CoachingForm from '../LGhome/components/CoachingForm/CoachingForm';
import LGHeader from '../LGhome/components/LGHeader';
import Slide from '../LGhome/components/Slide';
import LearningCommitmentModal from '../LGhome/components/studentHome/LearningCommitmentModal';
import SwimlaneISE from '../Swimlane/SwimlaneISE';
import './styles.css';

const images = [require('assets/img/home/FUJI3297.JPG').default];

function ISEhome({ loggedInUser }) {
  const selectedFolder = useSelector((state) => state.sidebarReducer.selectedFolder);
  const KEY_PRESENTATIONS = ['p_QUIZCITY_Get_Folders_Presentation_V3', loggedInUser?.id, selectedFolder?.id];
  const [coachingGuideModalVisible, setCoachingGuideModalVisible] = useState(false);
  const [reportHeader, setReportHeader] = useState({}); // lấy kết quả từ header truyền qua coachingForm
  const history = useHistory();
  const [recordImages, setRecordImages] = React.useState(images);
  const moveToSlide = async (presentationId, slide) => {
    const questionId = await getFirstQuestionId(slide.id ?? slide?.slideId);

    if (slide.status !== 'Locked') {
      if (slide.type === 'Quiz')
        return history.push(
          `/${settings.PATHNAME}/student/presentations/${presentationId}/slides/${
            slide.id ?? slide?.slideId
          }/questions/${questionId}`,
        );
      return history.push(
        `/${settings.PATHNAME}/student/presentations/${presentationId}/slides/${slide.id ?? slide?.slideId}`,
      );
    }
  };

  const {
    data: folders,
    isLoading,
    isSuccess,
  } = useQuery(
    KEY_PRESENTATIONS,
    () => {
      return query('p_QUIZCITY_Get_Folders_Presentation_V3', {
        studentId: loggedInUser?.id,
        folderId: selectedFolder?.id || '00000000-0000-0000-0000-000000000000',
      });
    },
    { enabled: !!selectedFolder?.id && selectedFolder?.id !== '00000000-0000-0000-0000-000000000000' },
  );
  const [selectedFolderId, setSelectedFolderId] = React.useState(null);

  const KEY_CURRENT_SLIDE = ['p_QUIZCITY_Slides_Current', loggedInUser?.id, selectedFolder?.id];

  const { data: currentSlide, isLoading: isCurrentSlideLoading } = useQuery(
    KEY_CURRENT_SLIDE,
    () => {
      if (loggedInUser.role === 'student') {
        return query('p_QUIZCITY_Slides_Current', { studentId: loggedInUser?.id, folderId: selectedFolder?.id });
      } else {
        return;
      }
    },
    { enabled: !!selectedFolder?.id && selectedFolder?.id !== '00000000-0000-0000-0000-000000000000' },
  );

  const carouselImages = [];
  for (let i = 1; i <= 5; i++) {
    let href = '';
    switch (i) {
      case 1:
        href = 'https://menard.vn/collagen/';
        break;
      case 2:
        href = 'https://menard.vn/collagen/';
        break;
      case 3:
        href = 'https://shop.menard.vn/duong-da/dong-san-pham-duong-da/authent-dong-san-pham-duong-da/';
        break;
      case 4:
        href = 'https://shop.menard.vn/trang-diem/embellir-trang-diem/';
        break;
      case 5:
        href = 'https://shop.menard.vn/duong-da/dong-san-pham-duong-da/saranari-dong-san-pham-duong-da/';
        break;
    }
    carouselImages.push(
      <a href={href} target='_blank'>
        <img
          key={`carousel-image-${i}`}
          style={{ width: '100%', objectFit: 'cover', borderRadius: '0.5rem' }}
          className='carousel-image'
          src={`https://cloud.softech.vn/storage/Data/MENARD/Carousel/carousel${i}.jpg`}
        />
      </a>,
    );
  }

  // Vị trí thư mục, lesson đang học.
  const folderIndex = folders?.findIndex((x) => x.id === currentSlide?.[0]?.folderId);
  let lessonIndex = -1;
  if (folderIndex > -1) {
    lessonIndex = folders[folderIndex]?.lessons?.findIndex((x) => x.id.toLowerCase() === currentSlide?.[0]?.lessionId);
  }

  useEffect(() => {
    if (folders?.length === 0 && !isLoading) {
      notification.error({
        message: i18next.t('home:notify.You are not in any course or the current course has no lessons'),
        duration: 5,
      });
    }
  }, [selectedFolder, folders, isLoading]);
  useEffect(() => {
    query('p_QUIZCITY_ExamImage_Category_Select').then((res) => {
      const sortRecord = res.sort((a, b) => a.sortOrder - b.sortOrder);
      const mapArr = sortRecord.map((item) => item.imageUrl);

      setRecordImages(mapArr.length === 0 ? images : mapArr);
    });
  }, []);
  return (
    <>
      <LGHeader selectedFolder={selectedFolder} setReportHeader={setReportHeader} />
      <StudentContainer>
        <Container fluid className='mt--6' style={{ marginBottom: 102 }}>
          {/* Current Lecture */}
          <Card className={`${settings.IS_LG ? 'lg-card-container' : ''}`} style={{ justifyContent: 'center' }}>
            {selectedFolder && currentSlide && currentSlide?.length !== 0 ? (
              <Row gutter={[24, 24]} style={{ alignItems: 'center' }}>
                <Col>
                  <h3
                    className='mb-0 tw-text-base null'
                    style={{ color: settings.IS_LG ? color.theme.button : '#32325d' }}
                  >
                    {settings.IS_LG ? 'CURRENT LECTURE' : 'Current Lecture'}
                  </h3>
                </Col>
                <Col>
                  <img alt='' style={{ width: 32 }} src='https://cdn-icons-png.flaticon.com/512/7341/7341822.png' />
                </Col>
                <Col flex={1} style={{ paddingTop: 16 }}>
                  <Link
                    style={{ fontWeight: '700', color: colors.theme.button }}
                    to='#'
                    onClick={() => {
                      query('p_QUIZCITY_CheckPermissonLearning', {
                        slideId:
                          currentSlide?.[0]?.slideId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id,
                        studentId: loggedInUser?.id,
                      }).then((res) => {
                        if (res?.[0]?.warningMessage) {
                        } else {
                          history.push(
                            `/${settings.PATHNAME}/student/presentations/${
                              currentSlide?.[0]?.presentationId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id
                            }/slides/${
                              currentSlide?.[0]?.slideId ??
                              folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id
                            }`,
                          );
                        }
                      });
                    }}
                  >
                    {`${currentSlide?.[0]?.folderTitle} >
                      ${currentSlide?.[0]?.lessonTitle} >
                      ${currentSlide?.[0]?.presentationTitle}`}
                  </Link>
                  <ul style={{ marginLeft: 0 }}>
                    <li style={{ marginBlock: 4 }}>
                      <div className='tw-items-center' style={{ display: 'flex', flexDirection: 'row' }}>
                        {currentSlide?.[0]?.type === 'Video' && <FiVideo className='tw-mr-2' />}
                        {currentSlide?.[0]?.type === 'WorkSheet' && <FiFileText className='tw-mr-2' />}
                        <div>{currentSlide?.[0]?.slideTitle}</div>
                        <AiFillPlayCircle color={colors.gold} size={20} className='tw-ml-2' />
                      </div>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <Button
                    type='primary'
                    onClick={() => {
                      query('p_QUIZCITY_CheckPermissonLearning', {
                        slideId:
                          currentSlide?.[0]?.slideId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id,
                        studentId: loggedInUser?.id,
                      }).then((res) => {
                        if (res?.[0]?.warningMessage) {
                        } else {
                          history.push(
                            `/${settings.PATHNAME}/student/presentations/${
                              currentSlide?.[0]?.presentationId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id
                            }/slides/${
                              currentSlide?.[0]?.slideId ??
                              folders?.[0]?.lessons?.[0]?.presentations?.[0]?.slides?.[0]?.id
                            }`,
                          );
                          moveToSlide(
                            currentSlide?.[0]?.presentationId ?? folders?.[0]?.lessons?.[0]?.presentations?.[0]?.id,
                            currentSlide?.[0],
                          );
                        }
                      });
                    }}
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            ) : isCurrentSlideLoading ? (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            ) : (
              <NoData />
            )}
          </Card>
        </Container>
      </StudentContainer>
      <SimpleContainer>
        <Card title={`LEARNING JOURNEY - ${selectedFolder?.name}`}>
          {isLoading && selectedFolder && (
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          )}
          {!selectedFolder ? <NoData></NoData> : folders && folders.length !== 0 && <SwimlaneISE folders={folders} />}
        </Card>
      </SimpleContainer>
      <Row gutter={[12, 12]} className='card-responsive' style={{ margin: '0 25px' }}>
        <Col xs={24} sm={24} md={12}>
          <CoachingForm
            loggedInUser={loggedInUser}
            setCoachingGuideModalVisible={setCoachingGuideModalVisible}
            reportHeader={reportHeader}
          />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card title='IELTS EXAM IMAGES' bodyStyles={{ height: 450 }}>
            <Slide images={recordImages} />
          </Card>
        </Col>
      </Row>
      <Modal
        open={coachingGuideModalVisible}
        title={i18next.t('CoachingGuide')}
        onCancel={() => setCoachingGuideModalVisible(false)}
        footer={null}
        centered
        width={700}
        bodyStyle={{ maxHeight: 'calc(100vh - 130px)', overflowY: 'auto' }}
      >
        <div style={{ color: colors.font }}>
          <div style={{ marginBottom: 15 }}>
            <div
              style={{
                color: 'white',
                backgroundColor: '#3078F1 ',
                fontSize: 16,
                fontWeight: 600,
                paddingLeft: 5,
                marginBottom: 5,
              }}
            >
              <span> Coaching Guidelines </span>
            </div>
            <div style={{ color: colors.font }}>
              To make sure you experience the coaching session most conveniently and effectively, let’s keep in mind the
              following guidelines:
            </div>
            <span>
              <strong>1.</strong> &emsp;The learner shall book coaching ONLY after completion of learning and test
              sessions.
            </span>{' '}
            <br />
            <span>
              <strong>2.</strong> &emsp; Each learner has 01 session of <b> 30 – minute </b> coaching during the course.
            </span>
            <br />
            <span>
              <strong>3.</strong> &emsp; The learner should book coaching schedule at least <b>10 days in advance</b>{' '}
              and give several options of schedule for TOEIC Trainers to choose one easily.
            </span>
            <br />
            <span>
              <strong>4.</strong>&emsp; The learner may check booking status in this session to get the progress.
            </span>
            <br />
            <span>
              <strong>5.</strong> &emsp; Results of coaching booking will be sent via company email or displayed in
              booking status, including coach’s information, time and platforms to coach within 07 working days.
            </span>
            <br />
            <span>
              <strong>6.</strong> &emsp; <b> Coaching 1-1 </b>: 1 learner – 1 TOEIC Trainer
            </span>
            <br />
            <span>
              <strong>7.</strong> &emsp; Coaching sessions shall be carried out on one of the following platforms:{' '}
              <b>Microsoft Team, Google Meet, Zoom </b> based on convenience and agreement of both learners and coach.
              Each coaching session shall be recorded full time for admin’s supervision and support and the learner
              takes it as reference for later practice. 8. Results of coaching sessions (brief comment) will be uploaded
              in the website.
            </span>
            <br />
            <span>
              <strong>8.</strong>&emsp; Results of coaching sessions (brief comment) will be uploaded in the website.
            </span>
            <br />
          </div>
          <br />

          <div>
            <div
              style={{
                color: 'white',
                backgroundColor: '#3078F1 ',
                fontSize: 16,
                fontWeight: 600,
                paddingLeft: 5,
                marginBottom: 5,
              }}
            >
              <span> Coaching Booking Step By Step </span>
            </div>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              <strong>1.</strong> &emsp; Your informations includes your employee code and full name will be autofill by
              the system.
            </div>
          </div>
          <div>
            <div>
              <strong>2.</strong> &emsp; Choose coaching schedules which are suitable with your work. You can choose
              more than one for your coach to choose:
            </div>
            <div style={{ marginLeft: 15, display: 'flex', alignItems: 'end' }}>
              {' '}
              ● You click button
              <img
                src={require('assets/img/dashboard/bookingGuide/step2_btn1.png').default}
                alt='picture02-btn1'
                width={30}
              />
              to add a coaching date and coaching time.
            </div>
            <div style={{ marginLeft: 15, display: 'flex', alignItems: 'end', marginBottom: 10 }}>
              {' '}
              ● You click button
              <img
                src={require('assets/img/dashboard/bookingGuide/step2_btn2.png').default}
                alt='picture02-btn2'
                width={30}
              />
              to delete a coaching date and coaching time.
            </div>
          </div>

          <div>
            <div
              style={{
                marginBottom: 10,
              }}
            >
              <strong>3.</strong> &emsp; Click to button Booking to confirm your booking:
            </div>
          </div>
          <div>
            <div>
              <strong>4.</strong> &emsp; Follow your booking status to update your booking as:
            </div>
            <div style={{ marginLeft: 15 }}>● Pending: Booking is waiting for confirmation</div>
            <div style={{ marginLeft: 15 }}>● Accept: Booking is confirmed</div>
            <div style={{ marginLeft: 15, marginBottom: 10 }}>● Cancel: Booking is not confirmed.</div>
            <div style={{ width: '100%' }}>
              <img src={require('assets/img/dashboard/bookingGuide/step4.png').default} alt='picture05' />
            </div>

            <div style={{ marginLeft: 15, display: 'flex', alignItems: 'end' }}>
              {' '}
              -
              <img
                src={require('assets/img/dashboard/bookingGuide/step4_btn1.png').default}
                alt='picture04-btn1'
                width={30}
              />
              : Review coaching booking detail
            </div>
            <div style={{ marginLeft: 15, display: 'flex', alignItems: 'end' }}>
              {' '}
              -
              <img
                src={require('assets/img/dashboard/bookingGuide/step4_btn2.png').default}
                alt='picture04-btn2'
                width={30}
              />
              : Cancel coaching booking
            </div>
            <div style={{ marginLeft: 15, display: 'flex', alignItems: 'end' }}>
              {' '}
              -
              <img
                src={require('assets/img/dashboard/bookingGuide/step4_btn3.png').default}
                alt='picture04-btn3'
                width={60}
              />
              : Show coaching booking history
            </div>
            <div style={{ marginLeft: 15, marginTop: 10 }}>
              <b style={{ fontStyle: 'italic', color: 'white', backgroundColor: colors.theme.button, padding: 3 }}>
                Notes
              </b>
            </div>
            <div style={{ marginLeft: 30, fontStyle: 'italic' }}>
              <div> - Coaching booking list just display latest coaching booking.</div>
              <div> - Coaching booking just be canceled if its status is pending. </div>
              <div> - Each employee is just allowed to have one coaching session with trainer.</div>
            </div>
          </div>
          <div>
            <div>
              <strong>5.</strong> &emsp;After coaching booking successful, user can view coaching booking detail as:
            </div>
            <div style={{ width: '100%', marginBottom: 15 }}>
              <img src={require('assets/img/dashboard/bookingGuide/step5.png').default} alt='picture05' />
            </div>
          </div>
        </div>
      </Modal>

      {/* <CoachingGuideModal visible={coachingGuideModalVisible} setVisible={setCoachingGuideModalVisible} /> */}
      <LearningCommitmentModal loggedInUser={loggedInUser} />
    </>
  );
}

export default ISEhome;
