import settings from 'constants/settings';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import AUTH_EN from '../locales/en/auth.json';
import CLASS_EN from '../locales/en/class.json';
import HOME_EN from '../locales/en/home.json';
import INDEX_EN from '../locales/en/index.json';
import LECTURE_EN from '../locales/en/lecture.json';
import PAGES_EN from '../locales/en/pages.json';
import REFERENCE_EN from '../locales/en/reference.json';
import REPORT_EN from '../locales/en/report.json';
import SLIDE_EN from '../locales/en/slide.json';
import TABLE_EN from '../locales/en/table.json';
import UPLOAD_EN from '../locales/en/upload.json';
import USER_EN from '../locales/en/user.json';
import AUTH_VI from '../locales/vi/auth.json';
import CLASS_VI from '../locales/vi/class.json';
import HOME_VI from '../locales/vi/home.json';
import INDEX_VI from '../locales/vi/index.json';
import LECTURE_VI from '../locales/vi/lecture.json';
import PAGES_VI from '../locales/vi/pages.json';
import REFERENCE_VI from '../locales/vi/reference.json';
import REPORT_VI from '../locales/vi/report.json';
import SLIDE_VI from '../locales/vi/slide.json';
import TABLE_VI from '../locales/vi/table.json';
import UPLOAD_VI from '../locales/vi/upload.json';
import USER_VI from '../locales/vi/user.json';

const isUseEN = settings.IS_LG || settings.IS_TVHH || settings.IS_ISE;

export const resources = {
  en: {
    auth: AUTH_EN,
    class: CLASS_EN,
    home: HOME_EN,
    index: INDEX_EN,
    table: TABLE_EN,
    pages: PAGES_EN,
    slide: SLIDE_EN,
    upload: UPLOAD_EN,
    user: USER_EN,
    report: REPORT_EN,
    reference: REFERENCE_EN,
    lecture: LECTURE_EN,
  },
  vi: {
    auth: AUTH_VI,
    class: CLASS_VI,
    home: HOME_VI,
    index: INDEX_VI,
    table: TABLE_VI,
    pages: PAGES_VI,
    slide: SLIDE_VI,
    upload: UPLOAD_VI,
    user: USER_VI,
    report: REPORT_VI,
    reference: REFERENCE_VI,
    lecture: LECTURE_VI,
  },
};
export const defaultNS = 'index';
i18n.use(initReactI18next).init({
  resources,
  lng: isUseEN ? 'en' : 'vi',
  ns: ['auth', 'home', 'class', 'index', 'pages', 'reference'],
  defaultNS,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
