/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import { submit } from 'modules/Presentation/Viewer/actions';
import { CheckScoreWorkSheet } from './components/CheckScoreExercise';
import { getDurationSlide_Seconds } from './duration';

/* eslint-disable sort-keys */
export const SubmitExerciseWorkSheet = async (
  refSubmit: any,
  slideId: string,
  studentId: string | number,
  setResultJsonDataExerciser: Function,
  setStatusButtonFooter: Function,
  timeExamSlide: number,
  percentPass: number,
  setTimeExamSlide: Function,
  roleStudent: string,
  setTimeNextSlide: Function,
  setIsDisableFrom: Function,
) => {
  refSubmit?.current?.submit?.();
  //   Lấy kết quả ra từ Local
  const userAnswerLocal: any = await localStorage.getItem(`userAnswersAboutAttachmentId-${slideId}-${studentId}`);
  // Lấy điểm ra từ Local
  const scoreLocal: any = await localStorage.getItem(`ScoreDataUserAnswer-${slideId}-${studentId}`);
  const scoreNumber = Number(JSON.parse(scoreLocal));
  setResultJsonDataExerciser({
    userData: JSON.parse(userAnswerLocal)?.jsonData?.result,
    typeExercise: 'WorkSheet',
  });
  setIsDisableFrom(true);
  //   Nếu có countdown  => không check điểm
  if (timeExamSlide > 0) {
    setStatusButtonFooter({
      disableSubmit: true,
      disableNextPresentation: false,
      hiddenRetry: true,
      disablePrev: false,
      hiddenSubmit: false,
    });
    setTimeExamSlide(0);
  } else {
    CheckScoreWorkSheet(scoreNumber, percentPass, setStatusButtonFooter, roleStudent, setTimeNextSlide, true);
  }
};
export const SubmitExerciseQuiz = (refSubmit: any) => {
    refSubmit?.current?.onNext?.();
};
export const SubmitExerciseQuizWhenCancel = (refSubmit: any) => {
    refSubmit?.current?.onSubmitWhenCancel?.();
};

export const SaveUserAnswerQuiz = (
  question: any,
  slideId: string,
  score: number,
  userData: any,
  studentId: number | string | undefined,
  takeExamTime: string | undefined,
  totalQuestion: number,
  appName: string,
  folderId: string,
  setArraySubmitQuestion?: Function | undefined,
) => {
  const paramters = {
    examinationId: question?.examinationId,
    questionId: question?.id,
    slideId,
    studentChoice: {
      score,
      wordShows: userData?.answers?.wordShows ?? null,
      userData: JSON.stringify(userData),
      answers: JSON.stringify(question?.answers),
    },
    duration: getDurationSlide_Seconds(),
    studentId,
    takeExamTime,
    totalQuestion,
    questionType: question?.type,
    optionType: question?.optionType,
    notes: '',
    appName,
    folderId,
  };
  setArraySubmitQuestion?.((prev: any) => {
    return [...prev, paramters];
  });

  query('dbo.p_QUIZCITY_API_SaveStudentAnswer_AndStudentQuestionLog', paramters).catch((err: any) => {});
};

export const SaveUserAnswerQuizPT = (
  ptCode: string,
  question: any,
  slideId: string,
  userData: any,
  submittedQuestions: any,
  folderId: string,
  takeExamTime: any,
  score: number,
  scoreWeight: number,
  totalQuestion: number,
  isEssay: boolean,
) => {
  // Lọc lại nếu đã lưu trước đó
  const filter = submittedQuestions.filter((ques: any) => ques.question.id !== question.id);
  //Đếm số câu trả lời đúng trong slide
  const countCorrectAnswer = isEssay
    ? 0
    : [...filter, { question, userData }].filter((ques: any) => ques.userData.result === true).length;
  const totalScore =
    countCorrectAnswer > 0 && typeof countCorrectAnswer === 'number'
      ? ((countCorrectAnswer / totalQuestion) * scoreWeight).toFixed(2)
      : 0;
  const paramters = {
    ptCode,
    slideId,
    questionId: question?.id,
    studentChoice: JSON.stringify({
      score: score,
      wordShows: userData?.answers?.wordShows ?? null,
      userData: JSON.stringify(userData),
      answers: JSON.stringify(question?.answers),
    }),
    questionType: question?.type,
    optionType: question?.optionType,
    takeExamTime,
    duration: 0,
    notes: '',
    appName: settings.APPLICATION_NAME,
    folderId,
    isEssay: isEssay,
    jsonData: JSON.stringify({ submittedQuestions: [...filter, { question, userData }], totalScore: totalScore }),
  };
  query('p_QUIZCITY_PTTest_SaveQuestionLog', paramters).catch((err: any) => {});
};

export const SubmitExerciseQuizTimeExam = (
  shuffleQuestions: any,
  arraySubmitQuestion: any,
  slideId: string,
  studentId: number | string,
  takeExamTime: string,
  totalQuestion: number,
  appName: string,
  folderId: string,
  dispatch: any,
) => {
  const listQuestionNotSubmit = shuffleQuestions?.filter(
    (ar: any) => !arraySubmitQuestion?.find((rm: any) => rm.questionId === ar.id),
  );

  listQuestionNotSubmit?.map((question: any) => {
    const userData = { answers: 'NoData' };
    dispatch(submit({ question, userData }));
    SaveUserAnswerQuiz(
      question,
      slideId,
      0, // score,
      userData,
      studentId,
      takeExamTime,
      totalQuestion,
      appName,
      folderId,
    );
  });
};
