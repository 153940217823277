/* eslint-disable react/prop-types */
import { Col, Row } from 'antd';
import Container from 'components/Containers/Container';
import React from 'react';

import withAuthenticator from 'HOCs/withAuthenticator';
import { query } from 'helpers/QueryHelper';
import Profile from 'modules/Auth/components/UserProfile/components/Profile';
import UpdateProfile from 'modules/Auth/components/UserProfile/components/UpdateProfile';
import { useQuery } from 'react-query';

export default withAuthenticator(({ loggedInUser }) => {
  const { data, isLoading } = useQuery(['Users', loggedInUser?.id], () =>
    query('p_QUIZCITY_GetUserInformation', {
      userId: loggedInUser?.id,
    }),
  );
  return (
    <React.Fragment>
      <Container name='Trang chủ' parentName='Thông tin tài khoản'>
        <Row gutter={[24, 24]}>
          <Col lg={16} style={{width:"100%"}}>
            <UpdateProfile data={data} isLoading={isLoading} loggedInUser={loggedInUser} />
          </Col>
          <Col lg={8} >
            <Profile data={data} isLoading={isLoading} loggedInUser={loggedInUser}   />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
});
