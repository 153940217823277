/* eslint-disable no-unused-vars */
import { Button, Modal, Space } from 'antd';
import _ from 'lodash';
import React from 'react';
import { ChromePicker, CirclePicker } from 'react-color';
import './index.css';
import { IS_MENARD } from 'constants/settings';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import i18next from 'i18next';
import colors from 'constants/colors';

type Props = {
  value?: string;
  colorList?: string[];
  onChange?: (color: string) => void;
  moreColors?: string[];
};

const colorsDefault = [
  '#32325d',
  '#000000',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
  colors.theme.button
];

function ColorPicker({ value = IS_MENARD ? '#5D094E' : '#000', onChange, colorList, moreColors = [] }: Props) {
  const [chromeVisible, setChromeVisible] = React.useState<boolean>(false);
  const colorArray = colorList ? colorList : _.concat(colorsDefault, moreColors);
  return (
    <Space style={{ width: '100%' }}>
      <CirclePicker
        colors={colorArray}
        width={''}
        circleSize={24}
        color={value}
        onChange={(color) => {
          onChange(color.hex);
        }}
      />
      <DynamicButton type='primary' onClick={() => setChromeVisible(true)}>
        {i18next.t('Color board')}
      </DynamicButton>
      <div
        style={{
          backgroundColor: value,
          width: 32,
          height: 32,
          color: value,
          borderRadius: 4,
          padding: '4px 15px',
        }}
      />
      <Modal
        bodyStyle={{ padding: 0 }}
        open={chromeVisible}
        footer={
          <DynamicButton size='small' type='primary' style={{ fontSize: 12 }} onClick={() => setChromeVisible(false)}>
            {i18next.t('Select')}
          </DynamicButton>
        }
        onCancel={() => setChromeVisible(false)}
        centered
        width={225}
        closable={false}
        className='modal-color-picker'
      >
        <ChromePicker
          className='chrome-picker'
          color={value}
          onChange={(color) => {
            onChange(color.hex);
          }}
        />
      </Modal>
    </Space>
  );
}

ColorPicker.defaultProps = {
  value: '#32325d',
  onChange: null,
  moreColors: [],
};

export default ColorPicker;
