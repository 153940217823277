import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Popover } from 'antd';
import { ListGroupItem, ListGroup } from 'reactstrap';
import './styles.css';

const propTypes = {
  text: PropTypes.string.isRequired,
};
const defaultProps = {};

class DictionaryText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      words: [],
      wordDictionary: undefined,

      isModalVisible: false,
    };
  }

  showModal = (word) => {
    this.setState(() => {
      this.getVocabulary(_.startCase(word));

      return { isModalVisible: true };
    });
  };

  hideModal = () => {
    this.setState({ isModalVisible: false });
  };

  getWords = () => {
    let text = this.props.text;
    let words = _.split(text, ' ');
    return words;
  };

  getVocabulary = (vocabulary) => {
    fetch(`https://cloud.softech.cloud/mobile/ames/api/dictionary/${vocabulary}`)
      .then((res) => res.json())
      .then((data) => {
        const { ok, result } = data;

        if (ok) {
          this.setState({ wordDictionary: result });
        } else {
          this.setState({ wordDictionary: undefined });
        }
      })
      .catch((error) => {
        alert('Error!', JSON.stringify(error));
      });
  };

  render = () => {
    const words = this.getWords();

    return (
      <React.Fragment>
        {words.map((word, index) => {
          return (
            <Popover
              id='tu-vung'
              key={index}
              placement='right'
              content={this._renderContentDictionary()}
              title='Từ điển'
              trigger='click'
              style={{ zIndex: 99 }}
            >
              <span
                key={index}
                style={{ fontSize: '24px', marginRight: 5, cursor: 'pointer' }}
                onClick={() => this.showModal(word)}
              >
                {`${word}`}
              </span>
            </Popover>
          );
        })}
      </React.Fragment>
    );
  };

  _renderContentDictionary = () => {
    let { wordDictionary } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        {wordDictionary && (
          <ListGroup style={{ flexWrap: 'wrap', maxWidth: 500 }}>
            <ListGroupItem className='text-white active'>Vocabulary: {wordDictionary.text}</ListGroupItem>
            <ListGroupItem>Phonetic: {wordDictionary.phonetic} </ListGroupItem>
            <ListGroupItem>
              Means:{' '}
              {wordDictionary.types.map(({ wordType, means }, index) => (
                <div key={index}>
                  <span>{wordType}</span>
                  {means.map((mean) => (
                    <div key={mean} style={{ flexDirection: 'row' }}>
                      <i className='fas fa-hand-point-right'></i>
                      <span> {mean}</span>
                    </div>
                  ))}
                </div>
              ))}{' '}
            </ListGroupItem>
          </ListGroup>
        )}
      </div>
    );
  };
}

DictionaryText.propTypes = propTypes;
DictionaryText.defaultProps = defaultProps;

export { DictionaryText };
