import settings from 'constants/settings';
import i18next from 'i18next';

export default {
  appName: settings.APPLICATION_NAME,
  formFields: [
    {
      hasFeedback: false,
      initialValue: '',
      inputComponent: {
        props: {
          style: {},
        },
        type: 'Input',
      },
      label: i18next.t('table:statistic.Full name'),
      name: 'name',
    },
    {
      hasFeedback: false,
      initialValue: '',
      inputComponent: {
        props: {
          style: {},
        },
        type: 'Input',
      },
      label: i18next.t('table:statistic.Email'),
      name: 'email',
    },
    {
      hasFeedback: false,
      initialValue: '',
      inputComponent: {
        props: {
          style: {},
        },
        type: 'Input',
      },
      label: i18next.t('table:classInformation.Phone number'),
      name: 'phone',
    },
  ],
  layout: {
    type: 'inline',
  },
  requiredMark: false,
  submitButtonText: i18next.t('search'),
  tailLayout: {},
};
