/* eslint-disable react/no-array-index-key */
import _ from 'lodash';
import React, { useRef } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import AudioButton from '../AudioButton';
import QuestionHeader from '../QuestionHeader';
import { QuestionComponentProps, QuestionComponentRef } from '../type';
import './styles.css';

const MultipleChoice = (
  {
    data,
    onSubmit,
    userData,
    questionIndex = 0,
    preview = false,
    showUserData = false,
    setAudio,
    onSubmitWhenCancel,
    isChildQuestion,
  }: QuestionComponentProps,
  ref: QuestionComponentRef,
) => {
  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
    onSubmitWhenCancel() {
      handleSubmitOnCanceling();
    },
  }));

  const [state, setState] = React.useState<any>({
    data: null,
    words: [],
    selectedAnswers: [],
  });

  const refAudio = useRef<any>();
  refAudio.current = new Audio();
  const handleAnswersNResult = () => {
    const correctAnswers = _.filter(state.words, (x) => x.isCorrect === true);
    const result = _.isEqual(_.sortBy(correctAnswers, ['id']), _.sortBy(state.selectedAnswers, ['id']));
    return {
      answers: state.selectedAnswers,
      result,
    };
  };
  const handleSubmitOnCanceling = () => {
    const { answers, result } = handleAnswersNResult();
    onSubmitWhenCancel?.({id: data.id, answers, result }, data);
  };
  const handleSubmit = () => {
    if (userData && showUserData) {
      onSubmit?.({ id: data.id, answers: state.selectedAnswers }, data);
    }
    const { answers, result } = handleAnswersNResult();
    onSubmit?.({id: data.id, answers, result }, data);
  };

  const playAudio = React.useCallback((audioUrl) => {
    refAudio.current.src = audioUrl;
    refAudio.current.play();
  }, []);

  refAudio.current.addEventListener('ended', () => {
    // setState((prevState) => ({ ...prevState, isAudioPlaying: false }));
  });

  React.useEffect(() => {
    const words = data.answers.map((item: any, index: number) => {
      return { id: index + 1, text: item.answer, isCorrect: item.isCorrect };
    });
    if (userData) {
      setState((prevState: any) => ({ ...prevState, words, data, selectedAnswers: userData.answers }));
    } else {
      setState((prevState: any) => ({ ...prevState, words, data }));
    }
    // Chế độ xem thử
    if (preview) {
      const correctAnswer = _.filter(data.answers, (answer) => answer.isCorrect);
      setState((prevState: any) => ({ ...prevState, words, data, selectedAnswers: correctAnswer }));
    }
  }, [data, preview, userData]);

  if (!data) return null;

  const renderAnswer = () => {
    let direction = data.settings && data.settings.answers && data.settings.answers.direction;
    if (direction === null || direction === undefined) {
      direction = 'vertical';
    }

    let bulletType = data.settings && data.settings.answers && data.settings.answers.bulletType;
    if (bulletType === null || bulletType === undefined) {
      bulletType = 'character';
    }
    return (
      // <div className={`answers-onechoice answers-onechoice-${direction}`}>
      <div className={`answers-onechoice tw-grid tw-grid-cols-2 tw-gap-4 tw-max-w-5xl`}>
        {state.words.map((word: any, index: number) => {
          const found = _.find(state.selectedAnswers, (x) => {
            return x.id === word.id;
          });
          {
            /* const isSelected = selectedAnswer && selectedAnswer.id === item.id && selectedAnswer.answer === item.answer; */
          }
          return (
            <Button
              // size='small'
              key={`word-${index}`}
              className={`answerButton-onechoice answerButton-onechoice-${direction}`}
              color={found === undefined ? 'secondary' : 'success'}
              style={{ border: '1px solid #bbb9b9', fontSize: 17 }}
              onClick={() => {
                // let found = _.find(state.selectedAnswers, (x) => {
                //   return x.id === word.id;
                // });

                if (found === undefined) {
                  const array = [...state.selectedAnswers];
                  array.push(word);
                  setState((prevState: any) => ({ ...prevState, selectedAnswers: array }));
                } else {
                  const array = _.filter(state.selectedAnswers, (x) => x.id !== word.id);
                  setState((prevState: any) => ({ ...prevState, selectedAnswers: array }));
                }
              }}
            >
              {word.text}
            </Button>
          );
        })}
      </div>
    );
  };

  return (
    <Card>
      {isChildQuestion ? null : (
        <CardHeader>
          <QuestionHeader title={data.title} subTitle={data.subTitle} questionIndex={questionIndex} />
        </CardHeader>
      )}
      <CardBody style={{ minHeight: 300 }}>
        <div className='questionText'>{data.questionText}</div>

        <AudioButton audioUrl={data.audioUrl} setAudio={setAudio} preview={preview} />
        {renderAnswer()}
      </CardBody>
    </Card>
  );
};

export default React.forwardRef(MultipleChoice);
