import React from 'react';

export const refNotification = React.createRef();

export const notify = (type, title, message) => {
  const options = {
    place: 'tc',
    message: (
      <div className='alert-text'>
        <span className='alert-title' data-notify='title'>
          {' '}
          {title}
        </span>
        <span data-notify='message'>{message}</span>
      </div>
    ),
    type,
    icon: 'ni ni-bell-55',
    autoDismiss: 700,
  };
  refNotification?.current.notificationAlert(options);
};
