export const AUTH_INIT = 'AUTH_INIT';
// ------------------------------------------------------------------------------------------------
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';
// ------------------------------------------------------------------------------------------------
export const AUTH_SET_LOGGED_IN_USER = 'AUTH_SET_LOGGED_IN_USER';
// ------------------------------------------------------------------------------------------------
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_GOOGLE = 'AUTH_LOGIN_GOOGLE';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_GOOGLE_SUCCESS = 'AUTH_LOGIN_GOOGLE_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const AUTH_LOGIN_GOOGLE_FAILURE = 'AUTH_LOGIN_GOOGLE_FAILURE';
// ------------------------------------------------------------------------------------------------
