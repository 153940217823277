import { UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Upload, message } from 'antd';
import Recorder, { statusRecordingValue } from 'components/Recording/react_mic_upload';
import { uuid } from 'components/functions';
import settings, { IS_PT } from 'constants/settings';
import React from 'react';
import { useParams } from 'react-router';
import { CardBody, CardHeader } from 'reactstrap';
import QuestionImage from '../../ExercicesTypes/QuestionImage';
// import RecorderTypeContent from '../../ExercisesTypes/RecorderTypeContent';
import i18next from 'i18next';
import formats from 'modules/Presentation/Teacher/Modal/WordModal/formats';
import modules from 'modules/Presentation/Teacher/Modal/WordModal/modules';
import ReactQuill from 'react-quill';
import AudioButton from '../../ExercicesTypes/AudioButton';
import QuestionHeader from '../../ExercicesTypes/QuestionHeader';
import QuestionVideo from '../../ExercicesTypes/QuestionVideo';

function Essay(
  {
    data,
    questionIndex = 1,
    setIsDisableContinue,
    isDisableButtonNextSlide,
    onSubmit,
    userData,
    showUserData,
    preview,
    slideId,
    setAudio,
    isMarking,
    studentId,
  },
  ref,
) {
  const [urlFileAudio, setUrlFileAudio] = React.useState();
  const [urlFileVideo, setUrlFileVideo] = React.useState();
  const [urlFileImage, setUrlFileImage] = React.useState();
  const [typeUpload, setTypeUpload] = React.useState();
  const [fileList, setFileList] = React.useState();
  const [isContentReady, setIsContentReady] = React.useState(false); // Fix React Quill load lần đầu không đủ nội dung

  const [form] = Form.useForm();
  const params = useParams();

  // * Hiển thị phần Record
  const [hasRecord, setHasRecord] = React.useState(true);
  // Trạng thái record
  const [statusRecord, setStatusRecord] = React.useState({ status: statusRecordingValue.mount, fileUrl: null }); //"recording", "uploading","upload-done"
  // Khi nộp bài mà record vẫn còn đang recording thì chặn hàm submit lại và gọi submit sau khi record được upload xong
  const [isAutoSubmitRecord, setIsAutoSubmitRecord] = React.useState(false);

  const refRecorder = React.createRef(null);
  const audioRef = React.useRef();
  // const refCountdownTimer = React.createRef(null);

  const isWriting = data?.optionType === 'WRITING';
  const isSpeaking = data?.optionType === 'SPEAKING';

  //Ban đầu chỉ có menard cần upload audio từ máy, nhưng đổi follow k cần nữa
  const isDisableInput = (IS_PT ? isDisableButtonNextSlide : preview || showUserData) || isMarking;
  // const isDisableInput = true;

  const recordParams = {
    questionId: data?.id,
    questionText: data?.questionText,
    studentId,
    slideId: settings.IS_PT ? slideId : params.slideId,
    takeExamTime: uuid(),
  };

  // const props = {
  //   name: 'files',
  //   action: `${sachsoServerUrl}/api/v1.0/dynamic/upload-files`,
  //   headers: {
  //     Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
  //     ApplicationName: settings.APPLICATION_NAME,
  //   },
  //   data: {
  //     entityId: '46d30c13-3555-4395-8b83-4b9b2f7402ae',
  //     subModuleName: 'RESOURCES',
  //     entityName: 'Questions',
  //     moduleName: 'SACHSO',
  //     moduleId: settings.MODULE_ID,
  //     //   SACHSO_RESOURCES_Questions
  //   },
  //   // fileList,
  //   maxCount: 1,
  //   beforeUpload: (file) => {
  //     const isPNG = file.type?.split('/')?.[0] === typeUpload;
  //     if (!isPNG) {
  //       message.error(`${file.name} ${i18next.t('upload:UploadFiles.is not in the correct format')}`);
  //     }
  //     return isPNG || Upload.LIST_IGNORE;
  //   },
  //   // beforeUpload: (file) => {
  //   //   if (file?.type?.split('/')?.[0] !== typeUpload) {
  //   //     message.warning('Vui chọn file đúng định dạng');
  //   //   }
  //   //   return Upload.LIST_IGNORE;
  //   // },
  //   onChange(info) {
  //     if (info?.file?.status === 'uploading') {
  //     }
  //     if (info.file.status === 'done') {
  //       setFileList(info?.fileList);
  //       const typeFile = info?.file.type?.split('/')?.[0];
  //       if (typeFile === 'audio') {
  //         setUrlFileAudio(info.file.response?.files?.[0]?.downloadUrl);
  //         form.setFieldsValue({
  //           audioUrl: info.file.response?.files?.[0]?.downloadUrl,
  //         });
  //       }
  //       message.success(
  //         i18next.t('upload:UploadFiles.onChange.File {{file}} uploaded successfully.', { file: info.file.name }),
  //       );
  //     } else if (info.file.status === 'error') {
  //       message.error(
  //         i18next.t('upload:UploadFiles.onChange.The uploaded {{file}} file is corrupted.', { file: info.file.name }),
  //       );
  //     }
  //   },
  // };

  //Record upload sẽ trả về cùng 1 url dù up nhiều lần
  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
    onSubmitWhenCancel() {
      handleSubmit();
    },
  }));

  React.useEffect(() => {
    setIsContentReady(true);
  }, [data?.question]);

  React.useEffect(() => {
    // Ở PT
    const check = settings.IS_PT ? window.location.pathname.includes('/preview') : userData && showUserData;
    if (check) {
      setHasRecord(false);
    }
  }, []);

  React.useEffect(() => {
    if (userData && showUserData) {
      form.setFieldsValue(userData.answers);
      setUrlFileAudio(userData?.answers?.audioUrl ?? '');
      setUrlFileVideo(userData?.answers?.videoUrl ?? '');
      setUrlFileImage(userData?.answers?.imageUrl ?? '');
    }
  }, [userData, showUserData]);

  // auto submit record
  // React.useEffect(() => {
  //   if (
  //     isAutoSubmitRecord &&
  //     [statusRecordingValue.upload_done, statusRecordingValue.upload_fail].includes(statusRecord.status)
  //   ) {
  //     const answers = {
  //       textEssay: form.getFieldValue('textEssay'),
  //       audioUrl: statusRecord.fileUrl,
  //     };
  //     onSubmit({ answers }, data);
  //     setIsAutoSubmitRecord(false);
  //   }
  // }, [isAutoSubmitRecord, statusRecord]);

  const onRecording = () => {
    setIsDisableContinue?.(true);
  };

  const onEndCountDown = () => {
    refRecorder.current?.onStopCountdown();
  };

  const onStopRecording = (result) => {
    setIsDisableContinue?.(false);
    onEndCountDown();
    setIsDisableContinue?.(false);
  };
  const getStatusRecording = (status, fileUrl = '') => {
    form.setFieldsValue({ audioUrl: fileUrl });
    setUrlFileAudio(fileUrl);
    setStatusRecord({ status, fileUrl });
  };

  const handleSubmit = () => {
    // if (statusRecord.status === statusRecordingValue.recording) {
    //   //Nếu record còn đang ghi âm thì dừng ghi âm để upload trước
    //   refRecorder.current.onRecord();
    //   setIsAutoSubmitRecord(true);
    // } else {
    const answers = {
      textEssay: form.getFieldValue('textEssay'),
      audioUrl: form.getFieldValue('audioUrl'),
    };
    onSubmit?.({ id: data.id, answers }, data);
    // }
  };

  return (
    <Card>
      <CardHeader>
        <QuestionHeader title={data?.title} subTitle={data?.subTitle} questionIndex={questionIndex} tooltip='Essay' />
      </CardHeader>
      <CardBody>
        <div style={{ marginBlock: 10 }}>
          {isContentReady && data?.questionText?.trim() && (
            <ReactQuill modules={modules} formats={formats} theme='bubble' value={data?.questionText} readOnly />
          )}
        </div>
        <AudioButton audioUrl={data?.audioUrl} setAudio={setAudio} preview={preview} />
        <QuestionImage imageUrl={data?.imageUrl} />
        <QuestionVideo videoUrl={data?.videoUrl} />
        <Form
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={() => {
            setIsDisableContinue?.((prev) => !prev);
          }}
        >
          {!isSpeaking && (
            <>
              <h6 className='mb-4 heading-small text-muted'>{i18next.t('slide:Essay.Essay work')}</h6>
              <Form.Item name='textEssay'>
                <Input.TextArea style={{ color: '#333' }} rows={6} disabled={isDisableInput}/>
              </Form.Item>
            </>
          )}
          <hr />
          {!isWriting && (
            <>
              <h6 className='mb-4 heading-small text-muted'>{i18next.t('slide:Essay.Audio work')}</h6>
              <Row gutter={[12, 12]}>
                <Col span={18}>
                  <Form.Item name='audioUrl' hidden={true}>
                    <Input placeholder='Audio URL: http://' />
                  </Form.Item>
                </Col>
                {/* {!isDisableInput ||
                  (!settings?.IS_LG && (
                    <Col span={6}>
                      <Form.Item>
                        <Upload {...props}>
                          <Button
                            disabled={isDisableInput}
                            block
                            type='dashed'
                            icon={<UploadOutlined />}
                            onClick={() => setTypeUpload('audio')}
                          >
                            Audio Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  ))} */}
              </Row>
              {!preview && (
                <Row justify='center'>
                  {/* <AudioRecorder
                    recordParams={recordParams}
                    onStopRecording={onStopRecording}
                    onRecording={onRecording}
                    audioRef={audioRef}
                  /> */}

                  <Recorder
                    ref={refRecorder}
                    __className={'question-type__recordType02'}
                    __onRecording={onRecording}
                    __onStopRecording={onStopRecording}
                    __statusRecording={getStatusRecording}
                    recordParams={recordParams}
                    hasRecord={hasRecord}
                  >
                    <span style={{ fontSize: 16, fontWeight: 600 }}>
                      {i18next.t('slide:record.Press here to record')}
                    </span>
                  </Recorder>
                </Row>
              )}
              <Row justify='center'>
                {urlFileAudio && (
                  <>
                    <br />
                    <audio
                      key={`audio_key_${urlFileAudio}`}
                      ref={audioRef}
                      controls
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <source src={urlFileAudio} type='audio/mpeg' />
                    </audio>
                  </>
                )}
              </Row>
              <hr />
            </>
          )}
        </Form>
      </CardBody>
    </Card>
  );
}
export default React.forwardRef(Essay);
