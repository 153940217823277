/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sort-keys */
/* eslint-disable array-callback-return */
import Card from 'components/Containers/Card';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import Container from 'components/Containers/Container';
import React from 'react';
import { Button, Col, message, Modal, Popconfirm, Row, Space, Spin, Tree } from 'antd';
import { useQuery, useQueryClient } from 'react-query';
import ContentModal from './components/ContentModal';
import { query } from 'helpers/QueryHelper';
import { deleteProc, keyFetch, selectProc } from './components/config';
import settings, { IS_VIMARU } from 'constants/settings';
import i18next from 'i18next';

const { DirectoryTree } = Tree;

const fetch = () => () => {
  return query(selectProc);
};

const IndexCategoryType = () => {
  const [dataTreeTypeAndOption, setDataTreeTypeAndOption] = React.useState();
  const [hiddenEditAndRemove, setHiddenEditAndRemove] = React.useState(true);
  const [idValueChange, setIdValueChange] = React.useState();
  const [allChangeValues, setAllChangeValues] = React.useState();
  const [isHiddenCreateFolder, setIsHiddenCreateFolder] = React.useState(false);
  const queryClient = useQueryClient();

  const [optionModal, setOptionModal] = React.useState({
    type: '',
    open: false,
  });

  const { data: onGetDataCrud, isLoading } = useQuery(keyFetch, fetch());

  const formatData = () => {
    const dataParent = onGetDataCrud.filter((item) => {
      return item.parentId === null;
    });
    const newArrData = [];
    dataParent?.map((item) => {
      newArrData.push({
        title: item.questionTypeName,
        key: item.id,
        dataDefault: item,
        children: onGetDataCrud
          ?.filter((itemChildren) => {
            return itemChildren?.parentId?.toUpperCase() === item?.id?.toUpperCase();
          })
          ?.map((mapChildren) => ({
            title: mapChildren?.questionTypeName,
            key: mapChildren?.id,
            dataDefault: mapChildren,
          })),
      });
    });
    setDataTreeTypeAndOption(newArrData);
  };

  const onDeleteFolder = async () => {
    let responseDelete;
    // Nếu 1 type thì xóa tất cả optionType của type đó
    if (allChangeValues?.dataDefault?.parentId === null) {
      // Xóa Type
      responseDelete = await query(deleteProc, {
        id: allChangeValues?.dataDefault?.id,
      });
      allChangeValues?.children?.forEach(async (item) => {
        // Xóa các optionType
        responseDelete = await query(deleteProc, {
          id: item?.dataDefault?.id,
        });
      });
    } else {
      responseDelete = await query(deleteProc, {
        id: allChangeValues?.dataDefault?.id,
      });
    }
    if (responseDelete) {
      queryClient.invalidateQueries(keyFetch);
      message.success(
        `Xóa ${!allChangeValues?.dataDefault?.parentId ? 'option type' : 'type'}: ${
          allChangeValues?.dataDefault?.questionTypeName
        } thành công.`,
      );
    }
  };

  React.useEffect(() => {
    if (!isLoading) formatData();
  }, [isLoading, onGetDataCrud]);

  const userCallbackCancelModal = React.useCallback(() => {
    setAllChangeValues(null);
    setOptionModal({
      type: '',
      open: false,
    });
  });
  const onSelect = (keys, info) => {
    if (keys) {
      setHiddenEditAndRemove(false);
      setIdValueChange(keys);
      setAllChangeValues(info?.node);
      if (info?.node?.dataDefault?.parentId !== null) return setIsHiddenCreateFolder(true);
      setIsHiddenCreateFolder(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Container name='Quản lý QuizType' parentName='Administration'>
        <Card
          title='Quản lý QuizType'
        >
          <Modal
            title={optionModal?.type === 'update' ? 'CẬP NHẬT' : 'THÊM MỚI'}
            footer={null}
            width='60%'
            open={optionModal?.open}
            onCancel={() => userCallbackCancelModal()}
          >
            <ContentModal
              dataAllType={onGetDataCrud}
              optionContent={optionModal?.type}
              id={idValueChange}
              allValue={allChangeValues}
              onCancel={userCallbackCancelModal}
            />
          </Modal>

          <Row gutter={[12, 12]} style={{ marginTop: 20 }}>
            <Col lg={8} xs={24}>
            {
              IS_VIMARU ? (
                <Space wrap>
                  {!isHiddenCreateFolder && (
                    <Button
                      type='dashed'
                      shape='circle'
                      onClick={() => {
                        setOptionModal((prev) => ({
                          ...prev,
                          type: 'created',
                          open: true,
                        }));
                      }}
                      icon={<PlusOutlined />}
                    />
                  )}
                  {!hiddenEditAndRemove && (
                    <>
                      <Button
                        type='dashed'
                        shape='circle'
                        icon={<EditOutlined />}
                        onClick={() => {
                          setOptionModal((prev) => ({
                            ...prev,
                            type: 'update',
                            open: true,
                          }));
                        }}
                      />
                      <Popconfirm
                        placement='topLeft'
                        title={`${i18next.t('Are you sure to delete', {
                          object: `${!allChangeValues?.dataDefault?.parentId ? 'type' : 'option type'}: ${
                            allChangeValues?.dataDefault?.questionTypeName
                          }`,
                        })}`}
                        onConfirm={onDeleteFolder}
                        okText={i18next.t('Confirm')}
                        cancelText={i18next.t('Close')}
                      >
                        <Button danger type='dashed' shape='circle' icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </>
                  )}
                </Space>):
                (
                  <i style={{color:'red'}}>Danh mục đang dùng chung cho các site. cho phép chỉnh sửa bởi admin Vimaru</i>
                )
            }
              <DirectoryTree
                multiple
                style={{ marginTop: 20 }}
                defaultExpandAll
                onSelect={onSelect}
                // onExpand={onExpand}
                treeData={dataTreeTypeAndOption}
              />
            </Col>
            <Col lg={14} xs={24} />
          </Row>
        </Card>
      </Container>
    </Spin>
  );
};
export default IndexCategoryType;
