import React from 'react';
// react library for routing
import { useQuery } from 'react-query';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar';
import AdminFooter from 'components/Footers/AdminFooter';
import Sidebar from 'components/Sidebar/Sidebar';

import allRoutes from 'routes';
import { hanhtrangsoClient } from 'configs/api';
import { login } from 'modules/Auth/actions';
import withAuthenticator from 'HOCs/withAuthenticator';
// import { useHistory } from 'react-router';
import { notification } from 'antd';
import background_img from 'assets/img/olympic/background_bottom.png';
import background_lg from 'assets/img/introduce/background.PNG';
import background_basao from 'assets/img/introduce/bg_tristar_bottom.png';
import background_trongdong from 'assets/img/introduce/bg_trongdong_bottom.png';
import _ from 'lodash';
import { administrators, permission } from '../constants';
import settings, { IS_BASAO, IS_LG, IS_MENARD, IS_TRONGDONG } from 'constants/settings';
import colors from 'constants/colors';

const logoUrl =
  window.location.hostname === 'quizcity.io'
    ? require('assets/sachso/images/logo-quizcity.png').default
    : require('assets/img/brand/english-city-02.png').default;

const openNotificationWithIcon = (type) => {
  notification[type]({
    message: 'LƯU Ý',
    description: 'Yêu cầu cập nhật đầy đủ thông tin cá nhân!',
  });
};

const Authenticated = ({ loggedInUser }) => {
  const dispatch = useDispatch();
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);

  // const history = useHistory();

  React.useEffect(() => {
    if (sidenavOpen) {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    } else {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location, sidenavOpen]);

  const allowedRoutes = _.filter(allRoutes, (route) => {
    return (
      _.find(route.roles, (x) => x === 'authenticated') ||
      _.find(route.roles, (x) => loggedInUser.role === x) ||
      (_.find(route.roles, (x) => x === 'permission') && _.find(permission, (email) => email === loggedInUser.email))
    );
  });

  // TODO: Tạm thời dùng users để xác định Administrators
  const administrationRouters = _.filter(allRoutes, (route) => {
    return (
      _.find(route.roles, (x) => x === 'administrator')
    );
  });
  const availableRoutes = _.concat(allowedRoutes, administrationRouters);

  // Filter by
  const getRoutes = React.useCallback((routes) => {
    return routes?.map((route, index) => {
      if (route.collapse) {
        return getRoutes(route.views);
      }
      if (route.layout === `/${settings.PATHNAME}`) {
        return <Route path={route.layout + route.path} component={route.component} key={index} exact={route.exact} />;
      }
      return null;
    });
  }, []);

  // toggles collapse between mini sidenav and normal
  // eslint-disable-next-line no-unused-vars
  const toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };

  const getNavbarTheme = () => {
    return location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';
  };

  // useQuery(
  //   ['login', loggedInUser?.id],
  //   async () => {
  //     const response = await hanhtrangsoClient.post('/login', { email: loggedInUser.email, password: loggedInUser.password });
  //     if (response.data.status === 'success') {
  //       dispatch(login(loggedInUser.email, loggedInUser.password));
  //     }
  //     return response.data;
  //   },
  //   {
  //     refetchInterval: 1000 * 60 * 20, // auto login after 20 minutest
  //     refetchIntervalInBackground: true,
  //     refetchOnWindowFocus: false,
  //   },
  // );

  React.useEffect(() => {
    const bg = document.createElement('div');
    bg.style.position = 'fixed';
    bg.style.top = '0px';
    bg.style.backgroundImage = `url(${!IS_MENARD && (IS_LG ? require('assets/img/brand/background-LG.png').default : IS_BASAO ? background_basao : IS_TRONGDONG ? background_trongdong : background_img)}`;
    bg.style.backgroundColor = IS_MENARD && colors.gray[100];
    bg.style.backgroundSize = '100% 100%';
    bg.style.height = '100vh';
    bg.style.width = '100vw';
    bg.style.zIndex = '-999';
    document.body.appendChild(bg);
    return () => {
      document.body.removeChild(bg);
    };
  }, []);

  return (
    <React.Fragment>
      <div className='main-content' ref={mainContentRef}>
        <AdminNavbar theme={getNavbarTheme()} toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} />
        <Switch>{getRoutes(availableRoutes)}</Switch>
        <AdminFooter />
      </div>
      {/* {sidenavOpen ? <div className='backdrop d-xl-none' onClick={toggleSidenav} /> : null} */}
    </React.Fragment>
  );
};

const Admin = ({ loggedInUser }) => {
  if (!loggedInUser) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  // * -- force information update -- new feature *
  const { pathname, href } = window.location;
  const dev = href.includes('localhost') || href.includes(':3000');
  if (!dev) {
    const profilePathname = `/${settings.PATHNAME}/profile`;
    const { school, firstName } = loggedInUser;
    if (firstName) {
      if (profilePathname !== pathname) {
        if (!school) {
          openNotificationWithIcon('warning');
          // history.push('/lms/profile');
          return <Redirect to={{ pathname: profilePathname }} />;
        }
      }
    }
  }

  return <Authenticated loggedInUser={loggedInUser} />;
};

export default withAuthenticator(Admin);
