import { useQueryClient } from 'react-query';
import CompactFiles from 'components/Files/CompactFiles';
import UploadFiles from 'components/UploadFiles';

import Card from 'components/Containers/Card';
import UploadNote from 'modules/Presentation/Viewer/ViewerTypes/SlideNote/UploadNote';

type Props = {
  match: {
    params: {
      moduleName: string;
      subModuleName: string;
      entityName: string;
      entityId: string;
    };
  };
};

export default function UploadWithQrCode({ match }: Props) {
  const queryClient = useQueryClient();

  const { moduleName, subModuleName, entityName, entityId } = match.params;
  const key = [`t_${moduleName}_${subModuleName}_${entityName}`, entityId];

  return (
    <Card title='Tải lên tập tin' subTitle='FILES'>
      {entityName === 'QuizNote_WorkItems' ? (
        entityId && <UploadNote workItemId={entityId} showQrCode={false}></UploadNote>
      ) : (
        <>
          <UploadFiles
            moduleName={moduleName}
            subModuleName={subModuleName}
            entityName={entityName}
            entityId={entityId}
            showQrCode={false}
            onSuccess={() => {
              queryClient.invalidateQueries(key);
            }}
          />
          <CompactFiles
            moduleName={moduleName}
            subModuleName={subModuleName}
            entityName={entityName}
            entityId={entityId}
            canDelete
          />
        </>
      )}
    </Card>
  );
}
