import ViewReport from './ViewReport';
import { Button, Space, Tag } from 'antd';
import SelectCode from 'modules/Teacher/CodesExam/components/SelectCode';
import settings from 'constants/settings';
import moment from 'moment';
import EssayList from './component/EssayList';
import EnterOfflineScore from './component/EnterOfflineScore/EnterOfflineScore';
export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'Mã tham gia',
      key: 'code',
      width: '5%',
      fixed: 'left',
      render: (text, record) => <SelectCode record={record} />,
    },
    {
      title: 'Tên đề',
      key: 'examName',
      width: '10%',
      fixed: 'left',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    {
      title: 'Người lấy mã',
      key: 'selectBy',
      width: '10%',
      render: (text) => {
        if (!text) return '';
        const textArray = text?.split('/');
        return (
          <div style={{ fontWeight: '600' }}>
            <div>{textArray?.[0] ?? ''}</div>
            <div>{textArray?.[1] ?? ''}</div>
          </div>
        );
      },
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    // {
    //   title: 'Chi nhánh',
    //   key: 'branch',
    //   width: '5%',
    //   render: 'renderNoWrap',
    //   style: {
    //     fontWeight: 600,
    //     minWidth: 160,
    //   },
    // },
    {
      title: 'Người dùng',
      key: 'user',
      width: '10%',
      // fixed: 'left',
      render: (text, record) => {
        if (!text) return null;
        const textArray = text?.split('/');
        return (
          <div style={{ fontWeight: '600' }}>
            <div>{textArray?.[3] ?? ''}</div>
            {textArray?.[0] && (
              <Button
                type='link'
                style={{ paddingLeft: 0 }}
                onClick={() => window.open(`/${settings.PATHNAME}/student/codeExam/${record?.code}/preview`, '_blank')}
              >
                {textArray?.[0]}
              </Button>
            )}
            <div>{textArray?.[1] ?? ''}</div>
            <div>{textArray?.[2] ?? ''}</div>
          </div>
        );
      },
      style: {
        fontWeight: 600,
      },
    },
    {
      title: 'Ngày làm bài',
      key: 'activeDate',
      width: '10%',
      render: (text, record) => (text ? moment(text).format('YYYY-MM-DD HH:mm') : ''),
      style: {
        fontWeight: 600,
      },
    },
    {
      title: 'Trạng thái',
      key: 'status',
      render: (text, record) => {
        if (!text || typeof text !== 'string') return null;
        const textArray = text?.split('/');
        return (
          <Space wrap style={{width:100}}>
            <Tag color={textArray?.[0] === 'true' ? 'red' : 'blue'}>
              {textArray?.[0] === 'true' ? 'Đã cấp' : 'Chưa cấp'}
            </Tag>
            <Tag color={textArray?.[1] === 'true' ? 'red' : 'blue'}>
              {textArray?.[1] === 'true' ? 'Đã kích hoạt' : 'Chưa kích hoạt'}
            </Tag>
          </Space>
        );
      },
      style: {
        fontWeight: 600,
      },
    },
    // {
    //   title: 'Số câu đúng/ tổng số câu',
    //   key: 'result',
    //   width: '10%',
    //   render: 'renderNoWrap',
    //   style: {
    //     fontWeight: 600,
    //   },
    // },
    {
      title: 'Tổng điểm',
      key: 'score',
      render: (text) => (<div style={{width: 60}}>{`${text?.toFixed(2) ?? ''}`}</div>),
      style: {
        fontWeight: 600,
      },
      dataType: 'number',
    },
    {
      title: 'Điểm tự luận',
      key: 'scoreEssay',
      render: (text) => (<div style={{width: 70}}>{text ? `${text?.toFixed(2) ?? ''}` : 0}</div>),
      style: {
        fontWeight: 600,
      },
      dataType: 'number',
    },
    {
      title: 'Nghe',
      key: 'listeningScore',
      dataType: 'number',
      render: (text, record) => (
        <div style={{ width: 110, fontWeight: 600 }}>
          <span>- Online: {record.listenScoreOnline?.toFixed(2)}</span>
          <br />
          <span>- Offline: {record.listenScoreOffline?.toFixed(2)}</span>
        </div>
      ),
      sorter:false,
    },
    {
      title: 'Nói',
      key: 'speakingScore',
      dataType: 'number',
      render: (text, record) => (
        <div style={{ width: 110, fontWeight: 600 }}>
          <span>- Online: {record.speakScoreOnline?.toFixed(2)}</span>
          <br />
          <span>- Offline: {record.speakScoreOffline?.toFixed(2)}</span>
        </div>
      ),
      sorter:false,
    },
    {
      title: 'Đọc',
      key: 'readingScore',
      dataType: 'number',
      render: (text, record) => (
        <div style={{ width: 110, fontWeight: 600 }}>
          <span>- Online: {record.readScoreOnline?.toFixed(2)}</span>
          <br />
          <span>- Offline: {record.readScoreOffline?.toFixed(2)}</span>
        </div>
      ),
      sorter:false,
    },
    {
      title: 'Viết',
      key: 'writingScore',
      dataType: 'number',
      render: (text, record) => (
        <div style={{ width: 110, fontWeight: 600 }}>
          <span>- Online: {record.writeScoreOnline?.toFixed(2)}</span>
          <br />
          <span>- Offline: {record.writeScoreOffline?.toFixed(2)}</span>
        </div>
      ),
      sorter:false,
    },
    {
      title: 'Thao tác',
      key: 'essayList',
      render: (text, record) => (
        <div style={{width:140}}>
          <EssayList key={'essay-exercise-list-' + record.id} record={record} />
          <br />
          <EnterOfflineScore key={'enter-offline-score-' + record.id} record={record} />
        </div>
      ),
      sorter: false,
      style: {
        fontWeight: 400,
      },
    },
  ],
};
