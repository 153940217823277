import { EditOutlined } from '@ant-design/icons';
import withAuthenticator from 'HOCs/withAuthenticator';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, TimePicker, Tooltip, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import colors from 'constants/colors';
import settings from 'constants/settings';
import { sendNotificationToUser, sendNotificationToUsersWithRole } from 'helpers/NotificationHelper';
import { amesSendMail, query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import getSettings from '../api/getSettings';
import {
  CoachingDateTag,
  RenderBookingDateTags,
  courseColumns,
  formatDate,
  formatDateQuery,
  formatDateTime,
  formatDateTimeQuery,
  optionsStatus,
  statusRequest,
  statusValues,
} from './tableColumn';
import { useSelector } from 'react-redux';
const { RangePicker } = DatePicker;

const ManagerCoachingSchedule = ({ loggedInUser }) => {
  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const [statusBooking, setStatusBooking] = React.useState('');
  const [modalStatus, setModalStatus] = React.useState({
    open: false,
    type: 'add',
    record: {},
  });
  const [proccessing, setProccessing] = React.useState(false);
  const [searchParameter, setSearchParameter] = React.useState({
    StudentName: '',
    Status: '',
    FromDate: '2000-01-01',
    ToDate: '3000-01-01',
  });

  const queryClient = useQueryClient();
  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);


  const mailer =
    ConfigSettings?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value ?? 'AMES ENGLISH <mailer@ames.edu.vn>';
  const mailerCC = ConfigSettings?.find((x) => x?.key.toUpperCase() === 'CC')?.value ?? '';
  const KEY_SELECT_BOOKINGS = ['p_QUIZCITY_CoachingBooking_Request_Search', searchParameter];
  const { data: bookings, isLoading } = useQuery(KEY_SELECT_BOOKINGS, () =>
    query('p_QUIZCITY_CoachingBooking_Request_Search', searchParameter),
  );
  const { data: teachers } = useQuery('p_QUIZCITY_Teacher_Category_Select', () =>
    query('p_QUIZCITY_Teacher_Category_Select'),
  );

  const CourseExtraColumns = [
    {
      key: 'editInfomation',
      render: (text, record) => {
        return (
          <Space>
            <Tooltip placement='top' title={i18next.t('Update')}>
              <Button
                key='edit'
                shape='circle'
                type='dashed'
                icon={<EditOutlined />}
                onClick={() => {
                  handleShowModal('edit', record);
                }}
              />
            </Tooltip>
          </Space>
        );
      },
      sort: false,
      title: '',
      width: '1%',
    },
  ];

  const handleShowModal = async (type, record) => {
    const {
      studentCode,
      status,
      commitedDateTeacher,
      linkMeeting,
      notes,
      teacherId,
      reasonCancel, // status = 'Cancel'
      finishedDate, // status = 'Done'
      finishedCommentTeacher, // status = 'Done'
      urlCoachingForm,
      urlCoachingVideo,
    } = record;
    setStatusBooking(status);
    form.setFieldsValue({
      coachingDate: commitedDateTeacher ? moment(commitedDateTeacher).format(formatDate) : null,
      coachingTime: commitedDateTeacher ? moment(commitedDateTeacher) : null,
      teacherId,
      linkMeeting,
      notes,
      reasonCancel, // status = 'Cancel'
      finishedDate: finishedDate ? moment(finishedDate) : null, // status = 'Done'
      finishedCommentTeacher, // status = 'Done'
      urlCoachingForm,
      urlCoachingVideo,
    });
    setModalStatus({
      open: true,
      type,
      record,
    });
  };

  const bodyNotification = (status, data) => {
    if (status === statusValues.accept) {
      const teacherInfor = teachers?.find((i) => i.id === data?.TeacherId);
      return `
          <div>Time: ${moment(data?.CommitedDateTeacher).format(formatDateTime)}</div>
          <div>Teacher: ${teacherInfor?.categoryName}</div>
          <div>Link meeting: ${teacherInfor?.address}</div>
          <div>Notes: ${data?.Notes}</div>
        `;
    } else if (status === statusValues.completed) {
      return `
          <div>Finish date: ${moment(data?.FinishedDate).format(formatDate)}</div>
          <div>Coaching comment: ${data?.FinishedCommentTeacher}</div>
        `;
    } else if (status === statusValues.cancel) {
      return `
          <div>Reason cancel: ${data?.ReasonCancel}</div>
          <div>You need to reschedule and create a new coaching booking</div>
        `;
    } else return '';
  };

  const handleSendNotification = (status, data) => {
    if (status === statusValues.completed || status === statusValues.accept || status === statusValues.cancel) {
      const titleNotification = `${
        status === statusValues.accept
          ? 'Announcement of a coaching appointment with teachers Ames'
          : status === statusValues.completed
          ? 'Notification of coaching results'
          : status === statusValues.cancel
          ? 'Unable to schedule a coaching appointment'
          : null
      } - ${data.studentName}`; //Nối thêm tên học viên vô để manager có thể theo dõi được thông báo đó cho học viên nào

      const bodyNoti = bodyNotification(status, data);

      sendNotificationToUser({
        title: titleNotification,
        body: bodyNoti, // HTML string
        classId: 0,
        avatar: loggedInUser?.avatarUrl ?? '',
        name: loggedInUser?.fullName,
        icon: '',
        color: colors.theme.button,
        createdBy: loggedInUser?.id,
        receiverId: data.studentId,
        type: 2, //0: Common , 1: Support, 2: Booking, 3: Other
      });
      //  if ([statusValues.accept, statusValues.completed].includes(status)) {
      ['teacher', 'manager'].forEach((role) => {
        sendNotificationToUsersWithRole({
          receiver: role, // Các users được nhận thông báo
          title: titleNotification, // tiêu đề
          body: bodyNoti, // nội dung thông báo HTML string
          classId: 0, // lớp học, mặc định là 0
          icon: '', // icon
          color: colors.theme.button, // Màu của icon chuông
          createdBy: loggedInUser?.id, // người tạo thông báo
          name: loggedInUser?.fullName, // tên người tạo thông báo
          avatar: loggedInUser?.avatarUrl ?? '', // ảnh người gửi trên thông báo
          type: 2, //0: Common , 1: Support, 2: Booking, 3: Other
        });
      });

      //  }
    }
  };

  const handleSendMail = async (values, record, status) => {
    const dateNo = moment().format('YYYYMMDDhhmm');
    let dataSendMail;
    if (status === statusValues.accept) {
      const teacherInfor = teachers?.find((i) => i.id === values?.TeacherId);
      dataSendMail = {
        to: record?.studentEmail?.toLowerCase(),
        isSendFast: true,
        moduleId: settings.MODULE_ID,
        cc: mailerCC,
        bcc: '',
        subject: `Coaching appointment #${dateNo}`,
        body: '',
        bodyHTML: '',
        attachment: '',
        templateName: 'LG_Mail_CoachingAppointment',
        sourceInfo: `API Save Email - LG_Mail_CoachingAppointment`,
        templateData: JSON.stringify({
          dateNo: dateNo,
          studentName: record.studentName,
          teacher: teacherInfor?.categoryName,
          linkMeeting: values.linkMeeting,
          time: moment(values.CommitedDateTeacher).format(formatDateTime),
          note: values.Notes,
        }),
        mailer: mailer,
      };
    } else if (status === statusValues.completed) {
      dataSendMail = {
        to: record?.studentEmail?.toLowerCase(),
        isSendFast: true,
        moduleId: settings.MODULE_ID,
        cc: mailerCC,
        bcc: '',
        subject: `Coaching Result #${dateNo}`,
        body: '',
        bodyHTML: '',
        attachment: '',
        templateName: 'LG_Mail_CoachingResults',
        sourceInfo: `API Save Email - LG_Mail_CoachingResults`,
        templateData: JSON.stringify({
          videoLink: values.urlCoachingVideo,
          formLink: values.urlCoachingForm,
          dateNo: dateNo,
          studentName: record.studentName,
        }),
        mailer: mailer,
      };
    }
    if (dataSendMail) {
      amesSendMail('saveEmail', dataSendMail).then((responseSendEmail) => {
          message.success('Sent mail to student');
      });
    }
  };
  const onUpdate = (values, record, status) => {
    if (!proccessing) {
      setProccessing(true);
      try {
        const { id, studentId, studentName, createdDate } = record;
        const {
          teacherId,
          notes,
          linkMeeting,
          coachingTime,
          coachingDate,
          reasonCancel,
          finishedDate,
          finishedCommentTeacher,
          urlCoachingForm,
          urlCoachingVideo,
        } = values;
        const commitedDateTeacher = coachingDate
          ? moment(coachingDate, formatDate)
              .set({
                hour: moment(coachingTime).get('hour'),
                minute: moment(coachingTime).get('minute'),
                second: 0,
              })
              .format(formatDateTimeQuery)
          : null;

        // Thông tin từ form không có thì lấy từ dữ liệu cũ, dữ liệu cũ không có thì điền trống
        const params = {
          Id: id,
          StudentId: studentId,
          TeacherId: teacherId ?? record.teacherId ?? '00000000-0000-0000-0000-000000000000',
          Status: status,
          ReasonCancel: reasonCancel ?? record.reasonCancel ?? '',
          CommitedDateTeacher: commitedDateTeacher ?? record.commitedDateTeacher ?? null,
          Notes: notes ?? record.notes ?? '',
          FinishedDate: moment(finishedDate).format(formatDateQuery) ?? record.finishedDate ?? null,
          FinishedCommentTeacher: finishedCommentTeacher ?? record.finishedCommentTeacher ?? '',
          UrlCoachingVideo: urlCoachingVideo ?? record.urlCoachingVideo ?? '',
          UrlCoachingForm: urlCoachingForm ?? record.urlCoachingForm ?? '',
          CreatedDate: createdDate ?? null,
          CreatedBy: studentId,
          ModifiedDate: moment().format(formatDateTimeQuery),
          ModifiedBy: loggedInUser?.id,
        };
        query('dbo.p_QUIZCITY_CoachingBooking_Request_InsertUpdate', params)
          .then((res) => {
            queryClient.invalidateQueries(KEY_SELECT_BOOKINGS);
            setModalStatus({ open: false });
            message.success(i18next.t('update success', { field: i18next.t('data') }));
            handleSendNotification(status, { ...record, ...params });
            handleSendMail({ ...values, ...params }, record, status);
          })
          .catch((error) => {
            message.error(i18next.t('update failed', { field: i18next.t('data') }));
          });
      } catch (error) {
        message.error(i18next.t('update failed', { field: i18next.t('data') }));
      } finally {
        setProccessing(false);
      }
    }
  };

  const onSearch = (value) => {
    const { status, studentName, rangeDate } = value;
    setSearchParameter({
      StudentName: studentName ? studentName : '',
      Status: status ? status : '',
      FromDate: rangeDate?.[0] ? moment(rangeDate?.[0]).format(formatDateQuery) : '2000-01-01',
      ToDate: rangeDate?.[1] ? moment(rangeDate?.[1]).format(formatDateQuery) : '3000-01-01',
    });
  };

  const formatDataBooking = (data) => {
    if (data?.length > 0) {
      const sortArr = _.sortBy(data, ['createdDate']).reverse();
      const formatData = sortArr.map((item) => ({
        ...item,
        commitedDateTeacher: item.commitedDateTeacher === '1900-01-01T00:00:00' ? null : item.commitedDateTeacher,
        teacherId: item.teacherId === '00000000-0000-0000-0000-000000000000' ? null : item.teacherId,
      }));
      return formatData;
    } else return [];
  };

  return (
    <div>
      <Container name='Manager coaching schedule' parentName='Administration'>
        <Card title='Manager coaching schedule'>
          <Form form={formSearch} onFinish={onSearch} layout='horizontal' initialValues={{ status: '' }}>
            <div style={{ display: 'flex', gap: 15, flexWrap: 'wrap', rowGap: 0 }}>
              <Form.Item label="Employee's name" name='studentName'>
                <Input />
              </Form.Item>
              <Form.Item name='rangeDate' label='Coaching date'>
                <RangePicker format={formatDate} style={{ width: 220 }} />
              </Form.Item>
              <Form.Item name='status' label='Status'>
                <Select
                  options={[{ label: 'All status', value: '' }].concat(
                    statusRequest.map((item) => ({ label: item.status, value: item.status })),
                  )}
                  style={{ width: 150 }}
                />
              </Form.Item>
              <Form.Item style={{ display: 'flex', alignItems: 'end' }}>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <DynamicButton style={{ border: 'none' }} type='primary' htmlType='submit'>
                    {i18next.t('search')}
                  </DynamicButton>
                </div>
              </Form.Item>
            </div>
          </Form>

          <DynamicTable
            loading={isLoading}
            dataSource={formatDataBooking(bookings)}
            initialTableData={courseColumns}
            // Chỉ có admin với teacher(Ames) mưới có quyền chỉnh sửa
            extraColumns={
              loggedInUser.role === 'administrator' || loggedInUser.role === 'teacher' ? CourseExtraColumns : null
            }
          />
        </Card>
      </Container>

      <Modal
        title={
          <div style={{ color: colors.theme.button }}>
            {statusBooking === statusValues.completed ? 'COACHING RESULTS' : 'COACHING INFORMATION'}
          </div>
        }
        open={modalStatus.open}
        onCancel={() => setModalStatus({ open: false })}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 20 }}>
            <div></div>
            <div>
              <DynamicButton onClick={() => setModalStatus({ open: false })} style={{ marginRight: 5 }}>
                {i18next.t('Close')}
              </DynamicButton>
              <DynamicButton
                style={{ border: 'none' }}
                type='primary'
                htmlType='submit'
                onClick={() => form.submit()}
                loading={proccessing}
              >
                {i18next.t('Save')}
              </DynamicButton>
            </div>
          </div>,
        ]}
        centered
        destroyOnClose
        bodyStyle={{ paddingBottom: statusBooking === statusValues.pending ? 24 : 0 }} // css cho đều khoảng trắng
      >
        <Row>
          <Col span={5}>
            <strong style={{ color: colors.font }}>{i18next.t('employeeCode')}</strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>{modalStatus.record?.studentCode}</Col>
        </Row>
        <Row>
          <Col span={5}>
            <strong style={{ color: colors.font }}>{i18next.t('fullName')}</strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>{modalStatus.record?.studentName}</Col>
        </Row>
        {statusBooking === statusValues.completed ? (
          <>
            <Row>
              <Col span={5}>
                <strong style={{ color: colors.font }}>Coaching date</strong>
              </Col>
              <Col span={1}>:</Col>
              <Col span={18}>
                <CoachingDateTag value={modalStatus.record?.commitedDateTeacher} />
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <strong style={{ color: colors.font }}>Teacher</strong>
              </Col>
              <Col span={1}>:</Col>
              <Col span={18}>{modalStatus.record?.teacherName}</Col>
            </Row>
            <Row>
              <Col span={5}>
                <strong style={{ color: colors.font }}>Link meeting</strong>
              </Col>
              <Col span={1}>:</Col>
              <Col span={18}>{modalStatus.record?.linkMeeting}</Col>
            </Row>
            <Row>
              <Col span={5}>
                <strong style={{ color: colors.font }}>Notes</strong>
              </Col>
              <Col span={1}>:</Col>
              <Col span={18}>{modalStatus.record?.notes}</Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col span={5}>
              <strong style={{ color: colors.font }}> {i18next.t('Booking')}</strong>
            </Col>
            <Col span={1}>:</Col>
            <Col span={18}>
              <RenderBookingDateTags bookingDates={modalStatus.record?.booking} />
            </Col>
          </Row>
        )}

        <Row style={{ marginTop: 10 }}>
          <Col span={5}>
            <strong style={{ color: colors.font }}>{i18next.t('Status')}</strong>
          </Col>
          <Col span={1}>:</Col>
          <Col span={18}>
            {/* <b>{renderStatus(statusBooking)}</b> */}
            <Select
              value={statusBooking}
              // options={statusBooking === statusValues.completed ? optionFinish : optionEdit}
              options={optionsStatus}
              style={{ width: 150 }}
              dropdownStyle={{ zIndex: 99999999 }}
              onChange={(status) => setStatusBooking(status)}
            />
            {/* <br /> */}
            {/* &emsp;
            <i>Select Coaching status</i> */}
          </Col>
        </Row>

        <Form form={form} onFinish={(value) => onUpdate(value, modalStatus?.record, statusBooking)} layout='vertical'>
          {statusBooking !== statusValues.pending && <hr style={{ margin: 10 }} />}
          {statusBooking === statusValues.accept && (
            <>
              <Row gutter={12}>
                <Col span={16}>
                  <Form.Item
                    name='coachingDate'
                    label='Coaching date'
                    rules={[
                      { required: true, message: i18next.t('please choose', { field: i18next.t('coachingDate') }) },
                    ]}
                  >
                    <Select
                      style={{ color: colors.font }}
                      disabled={loggedInUser.role === 'manager'}
                      dropdownStyle={{ zIndex: 9999 }}
                      options={modalStatus.record?.booking?.map((option, index) => ({
                        label: `${moment(option?.dateRequest).format(formatDate)}: ${option?.notes}`,
                        value: moment(option?.dateRequest).format(formatDate),
                      }))}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        (option?.label?.toUpperCase() ?? '').includes(input.toUpperCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name='coachingTime'
                    label='Coaching time'
                    rules={[
                      { required: true, message: i18next.t('please choose', { field: i18next.t('coachingTime') }) },
                    ]}
                  >
                    <TimePicker
                      use12Hours
                      format='h:mm a'
                      // format={'HH:mm'}
                      popupStyle={{
                        zIndex: 99999,
                      }}
                      style={{ width: '100%', color: colors.font }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name='teacherId'
                label="Teacher's name"
                rules={[{ required: true, message: i18next.t('please choose', { field: i18next.t('teacherName') }) }]}
              >
                <Select
                  style={{ color: colors.font }}
                  dropdownStyle={{ zIndex: 9999 }}
                  options={teachers?.map((teacher) => ({
                    value: teacher?.id,
                    label: teacher.categoryName,
                    ...teacher,
                  }))}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => (option?.label?.toUpperCase() ?? '').includes(input.toUpperCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  onChange={(value, node) => {
                    form.setFieldValue('linkMeeting', node?.address);
                  }}
                />
              </Form.Item>
              <Form.Item
                name='linkMeeting'
                label='Link meeting'
                // rules={[{ required: true, message:i18next.t('please enter', { field:i18next.t('linkMeeting') }) }]}
              >
                <Input style={{ color: colors.font }} />
              </Form.Item>
              <Form.Item name='notes' label='Notes'>
                <TextArea style={{ color: colors.font }} />
              </Form.Item>
            </>
          )}
          {statusBooking === statusValues.completed && (
            <>
              <Form.Item
                name='finishedDate'
                label='Finish date'
                rules={[
                  {
                    required: true,
                    message: i18next.t('please choose', { field: i18next.t('Time range') }),
                  },
                ]}
              >
                <DatePicker format={formatDate} style={{ width: '100%' }} popupStyle={{ zIndex: 9999 }} />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 3 }}
                name='finishedCommentTeacher'
                label='Coaching comment'
                rules={[{ required: true, message: i18next.t('please enter', { field: 'Coaching Comment' }) }]}
              >
                <TextArea style={{ color: colors.font }} />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 3 }}
                name='urlCoachingForm'
                label='Link Coaching Form'
                rules={[{ required: true, message: i18next.t('please enter', { field: 'link coaching form' }) }]}
              >
                <Input style={{ color: colors.font }} />
              </Form.Item>
              <Form.Item
                name='urlCoachingVideo'
                label='Link Coaching Video'
                rules={[{ required: true, message: i18next.t('please enter', { field: 'link coaching video' }) }]}
              >
                <Input style={{ color: colors.font }} />
              </Form.Item>
            </>
          )}
          {statusBooking === statusValues.cancel && (
            <Form.Item
              name='reasonCancel'
              label='Reason'
              rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('reason') }) }]}
            >
              <TextArea style={{ color: colors.font }} />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default withAuthenticator(ManagerCoachingSchedule);
