/* eslint-disable react/prop-types */
import Tooltip from 'antd/es/tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// import { captureUserMedia } from './utils';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactMic } from '@cleandersonlobo/react-mic';
import { Button } from 'antd';
import axios from 'axios';
import notification from 'components/Notification';
import { sachsoServerUrl } from 'constants/serverUrls';
import settings from 'constants/settings';
import { authorizationKey, deleteVideoByUrl } from 'helpers/QueryHelper';
import i18next from 'i18next';
import moment from 'moment';
import { formatDateTime } from 'constants/FormatString';

export const statusRecordingValue = {
  mount: 'mount',
  recording: 'recording',
  uploading: 'uploading',
  upload_done: 'upload_done',
  upload_fail: 'upload_fail',
  deleted_file: 'deleted_file',
};

let source = axios.CancelToken.source();
class Recorder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false,
      recognizer: null,
      recordAudio: undefined,
      statusRecord: 'start',
      classNames__btnRecord: null,
      disabled: true,
      statusText: i18next.t('slide:record.Recording error'),
      uploadedUrl:''
    };
  }

  componentDidMount() {
    this.initialRecord();
    this.props.__statusRecording?.(statusRecordingValue.mount);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { recordParams } = this.props;
    if (recordParams.questionId !== nextProps.recordParams.questionId) {
      this.setState({ isRecord: false });
    }
  };

  initialRecord = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      this.setState({ disabled: false, statusText: i18next.t('slide:record.Start recording') });
    } catch (error) {
      // console.log(error);
      notification(
        'danger',
        i18next.t('notification'),
        i18next.t('slide:record.Your browser does not support recording, please set the settings.'),
      );
      // setRecordEnable(false);
    }
  };

  onRecord = () => {
    if (this.state.statusRecord === 'start') {
      this.recordStart();
    } else {
      this.recordStop();
    }
  };

  recordStart = () => {
    if (this.state.uploadedUrl) {
      //Xoá file cũ nếu có
      this.props.__statusRecording?.(statusRecordingValue.deleted_file);
      deleteVideoByUrl(this.state.uploadedUrl);
    }
    this.setState({
      record: true,
      statusRecord: 'stop',
      classNames__btnRecord: ['pulse', 'infinite'],
      statusText: i18next.t('Stop'),
    });
    this.props.__onRecording?.();
    this.props.__statusRecording?.(statusRecordingValue.recording);
  };

  recordStop = () => {
    this.setState({
      record: false,
      statusRecord: 'cal',
      classNames__btnRecord: null,
      isRecord: true,
    });
  };

  onData() {
    // console.log('chunk of real-time data is: ', recordedBlob);
  }

  recognize = async (audioFile, studentId, questionId = '', takeExamTime = '', slideId = '') => {
    const formData = new FormData();
    formData.append('files', audioFile);
    formData.append('moduleName', settings.APPLICATION_NAME);
    formData.append('subModuleName', 'CLASSWORK');
    formData.append('entityName', 'SLIDE');
    formData.append('entityId', slideId ?? '00000000-0000-0000-0000-000000000000');
    formData.append('tags', 'RECORD');
    formData.append('createdBy', studentId ?? 0);

    const response = await axios.post(`${sachsoServerUrl}/api/v1.0/dynamic/upload-files`, formData, {
      headers: {
        Authorization: authorizationKey,
        ApplicationName: settings.APPLICATION_NAME,
      },
      cancelToken: source.token,
    });

    if (response.data.files?.[0]?.downloadUrl) {
      this.setState({uploadedUrl: response.data.files?.[0]?.downloadUrl});
      this.props.__onStopRecording({ ...response.data.files?.[0] });
      this.props.__statusRecording?.(statusRecordingValue.upload_done, response.data.files?.[0]?.downloadUrl);
    } else {
      this.props.__onStopRecording(undefined);
      notification(
        'danger',
        i18next.t('notification'),
        `${i18next.t('slide:record.Recording error')}, ${i18next.t('slide:record.Please re-record!')}`,
      );
      this.props.__statusRecording?.(statusRecordingValue.upload_fail);
    }
  };

  onStop = async (recordedBlob) => {
    const { recordParams } = this.props;
    const { questionId, studentId, takeExamTime, slideId } = recordParams;
    this.props.__statusRecording?.(statusRecordingValue.uploading);
    try {
      // Chuyển đổi recordedBlob thành file audio, tên file thêm chuỗi ngày giờ để tránh trùng với record cũ nếu ghi âm lại
      const audioFile = new File([recordedBlob.blob], `audio${moment().format('YYYYMMDDHHmmss')}.mp3`, {
        type: 'audio/mpeg',
      });
      var reader = new FileReader();
      reader.readAsDataURL(recordedBlob?.blob);
      reader.onloadend = async () => {
        this.recognize(audioFile, studentId, questionId, takeExamTime, slideId);
      };
    } catch (error) {
      notification(
        'danger',
        i18next.t('notification'),
        `${i18next.t('slide:record.Recording error')}, ${i18next.t('slide:record.Please re-record!')}`,
      );
      this.props.__statusRecording?.(statusRecordingValue.upload_fail);
    } finally {
      this.setState({
        statusRecord: 'start',
        statusText: i18next.t('slide:record.Re-recording'),
      });
    }
  };

  render() {
    let { statusRecord, classNames__btnRecord, disabled, statusText, isRecord } = this.state;
    let { __className } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {this.props.hasRecord && (
          <>
          <div className='tw-gap-5 d-flex justify-content-center align-items-center'>
            <Tooltip placement='top' title={statusText}>
              <Button
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '80px',
                  width: '80px',
                }}
                className={classNames([
                  'animated',
                  classNames__btnRecord,
                  __className,
                  (statusRecord === 'stop' || statusRecord === 'cal') && 'button-recording-pulse',
                ])}
                onClick={disabled ? null : this.onRecord}
              >
                {statusRecord === 'cal' ? (
                  <LoadingOutlined style={{ fontSize: 30, color: 'black' }} />
                ) : statusRecord === 'start' ? (
                  isRecord ? (
                    <i style={{ fontSize: '25px', color: 'black' }} className='fas fa-undo' />
                  ) : (
                    <i style={{ fontSize: '25px', color: 'black' }} className='fas fa-microphone' />
                  )
                ) : (
                  <i style={{ fontSize: '25px', color: 'black' }} className='fas fa-stop'></i>
                )}
              </Button>
            </Tooltip>
          </div>
            <div>{this.props.children}</div>
            </>
        )}

        <div>
          <ReactMic
            record={this.state.record}
            visualSetting='none'
            className='sound-wave d-none'
            onStop={this.onStop}
            onData={this.onData}
            audioBitsPerSecond={128000}
            mimeType={'audio/mp3'}
          />
        </div>
      </div>
    );
  }
}

Recorder.propTypes = {
  __className: PropTypes.string,
  __onRecording: PropTypes.func,
  __onStopRecording: PropTypes.func,
  __statusRecording: PropTypes.func,
  recordParams: PropTypes.instanceOf(Object),
  hasRecord: PropTypes.bool,
};

export default Recorder;
