import { Tag } from 'antd';
import SelectCode from './components/SelectCode';

export default {
  displayColumns: [
    {
      title: 'Mã tham gia',
      key: 'code',
      width: '5%',
      fixed: 'left',
      render: (text, record) => <SelectCode record={record} />,
    },
    {
      title: 'Tên đề',
      key: 'examName',
      width: '10%',
      fixed: 'left',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    {
      title: 'Người lấy mã',
      key: 'selectBy',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    {
      title: 'Chi nhánh',
      key: 'branch',
      width: '5%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    // {
    //   title: 'Người tạo',
    //   key: 'createdBy',
    //   width: '15%',
    //   render: 'renderNoWrap',
    //   style: {
    //     fontWeight: 600,
    //     minWidth: 160,
    //   },
    // },
    {
      title: 'Người dùng',
      key: 'contactName',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    {
      title: 'SĐT người dùng',
      key: 'contactPhone',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    {
      title: 'Email người dùng',
      key: 'contactEmail',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    {
      title: 'Ngày tạo đề',
      key: 'createdDate',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    {
      title: 'Ngày kích hoạt',
      key: 'activeDate',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    {
      title: 'Trạng thái cấp',
      key: 'statusSelect',
      width: '5%',
      render: (text, record) => (
        <Tag color={record.status ? 'red' : 'blue'}>{record.status ? 'Đã cấp' : 'Chưa cấp'}</Tag>
      ),
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    {
      title: 'Trạng thái',
      key: 'status',
      width: '10%',
      render: (text, record) => (
        <Tag color={record.status ? 'red' : 'blue'}>{record.status ? 'Đã kích hoạt' : 'Chưa kích hoạt'}</Tag>
      ),
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
    {
      title: 'Ghi chú',
      width: '4%',
      key: 'note',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 160,
      },
    },
  ],
};
