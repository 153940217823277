import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Modal, notification } from 'antd';
import MoveButton from 'components/Button/MoveButton/MoveButton';
import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import DeletePopconfirm from 'components/Popconfirm/DeletePopconfirm/DeletePopconfirm';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import deleteNotificationManagement from './api/deleteNotificationManagement';
import getNotificationManagement from './api/getNotificationManagement';
import AddContentNotification from './components/addContentNotification';
import tableData from './tableData';
import settings from 'constants/settings';
import i18next from 'i18next';
import DynamicButton from 'packages/DynamicButton/DynamicButton';

const ManageNotification = () => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [openModalNotification, setOpenModalNotification] = React.useState(false);
  const [statusModal, setStatusModal] = React.useState(null);
  const KEY_VIMARU_QUIZCITY_Notification_Management = 't_QUIZCITY_Notification_Management';
  const { data, isLoading } = useQuery(KEY_VIMARU_QUIZCITY_Notification_Management, () => getNotificationManagement());
  const [notificationId, setNotificationId] = React.useState();
  const [dataTable, setDataTable] = React.useState();
  React.useEffect(() => {
    setDataTable(data);
  }, [data]);

  const onCancelModalNotification = React.useCallback(() => {
    form.resetFields();
    if (notificationId) {
      setNotificationId();
    }
    setOpenModalNotification(false);
    queryClient.invalidateQueries(KEY_VIMARU_QUIZCITY_Notification_Management);
  }, [notificationId, queryClient]);

  const handleAddNotification = () => {
    // Thiết lập giá trị mặc định khi tạo mới.
    form.setFieldsValue({ content: '', notes: '', notificateCode: '', notificationName: '', status: false });
    setStatusModal('add');
    setOpenModalNotification(true);
  };

  const extractModal = [
    {
      key: 'action-edit',
      render: (text, record, index) => {
        return (
          <MoveButton
            hasTooltip
            title={i18next.t('Edit')}
            placement='top'
            icon={<EditOutlined />}
            onClick={() => {
              const { content, id, notes, notificateCode, notificationName, status } = record;
              // Thiết lập giá trị mặc định khi chỉnh sửa.
              form.setFieldsValue({ content, notes, notificateCode, notificationName, status });
              setStatusModal('edit');
              setOpenModalNotification(true);
              setNotificationId(id);
            }}
          />
        );
      },
      sort: false,
      width: '1%',
    },
    {
      key: 'action-delete',
      render: (text, record, index) => {
        const { id } = record;
        const handleConfirmDelete = async () => {
          deleteNotificationManagement(id)
            .then((response) => {
              notification.success({
                description: i18next.t('delete success', { field: i18next.t('content') }),
                message: i18next.t('notification'),
              });
              queryClient.invalidateQueries(KEY_VIMARU_QUIZCITY_Notification_Management);
            })
            .catch((error) => {
              notification.error({
                description: i18next.t('delete failed', { field: i18next.t('content') }),
                message: i18next.t('notification'),
              });
            });
        };
        return <DeletePopconfirm hasTooltip tooltipPlacement='top' onConfirm={handleConfirmDelete} />;
      },
      sort: false,
      width: '1%',
    },
  ];

  return (
    <div>
      <Container name='Quản lý nội dung thông báo' parentName='Administration'>
        <Card
          title={i18next.t('ManageNotiContent')}
          extra={
            <div style={{ alignContent: 'center', columnGap: 10, display: 'flex', justifyContent: 'center' }}>
              <DynamicButton onClick={handleAddNotification} onKeyUp={handleAddNotification} role='button' tabIndex={0}>
                <PlusOutlined /> {i18next.t('AddNotification ')}
              </DynamicButton>
            </div>
          }
        >
          <Modal
            footer={null}
            width={800}
            open={openModalNotification}
            onCancel={onCancelModalNotification}
            title={statusModal === 'add' ? i18next.t('AddNotification ') : i18next.t('EditNoti')}
          >
            <AddContentNotification
              cancelModalNotification={onCancelModalNotification}
              form={form}
              notificationId={notificationId}
              data={dataTable}
              statusModal={statusModal}
            />
          </Modal>
          <DynamicTable
            loading={isLoading}
            dataSource={dataTable}
            initialTableData={tableData}
            onReload={undefined}
            pagination={{ defaultPageSize: 10 }}
            extraColumns={extractModal}
          />
        </Card>
      </Container>
    </div>
  );
};

export default ManageNotification;
