import { Progress, Rate } from 'antd';
import colors from 'constants/colors';
import { studentColumns } from 'constants/displayColumns';
import settings from 'constants/settings';
import StudentsEssay from '.';
import MarkingEssay from '../MarkingEssay';
import MarkingNote from '../MarkingNote';

export default {
  rowKey: 'id',
  displayColumns: [
    {
      key: 'createdDate',
      render: 'renderDateTime',
      title: 'Thời gian',
      width: '10%',
    },
    {
      key: 'lessonTitle',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: 'Lesson',
      width: '10%',
    },

    {
      key: 'presentationTitle',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: 'Part',
      width: '10%',
    },
    {
      key: 'slideTitle',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: 'Tên bài',
      width: '10%',
    },
    {
      key: 'completedPercent',
      // render: 'renderNoWrap',
      dataType: 'number',
      render: (qualityPercent, record, index) => {
        return (
          <Progress
            style={{ paddingRight: 10 }}
            percent={`${Number.parseFloat(qualityPercent ?? 0).toFixed(0)}`}
            status='active'
          />
        );
      },
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: 'Hoàn thành',
      width: '11%',
    },
    {
      key: 'score',
      dataType: 'number',
      render: (scoreEssay, record, index) => {
        return (
          <div
            style={{
              color: scoreEssay > 5 ? (scoreEssay >= 8 ? '#4ADA92' : '#FEC12D') : '#EE444C',
              fontWeight: 'bold',
            }}
          >
            {scoreEssay ? scoreEssay.toFixed(2) : 'Chưa chấm điểm'}
          </div>
        );
      },
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: 'Điểm',
      width: '10%',
    },
    {
      key: 'Essay',
      render: (text, record) => {
        return <MarkingNote record={record} scoreEssay={record?.score} teacherNoteEssay={record?.teacherNote} />;
      },
      sort: false,
      style: {
        fontWeight: 400,
      },
      title: '',
      width: '1%',
    },
  ],
};
