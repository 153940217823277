import { io, Socket } from 'socket.io-client';
import { socketServerUrl } from 'constants/serverUrls';

let socket: Socket = null;

const config = {
  secure: true,
  reconnection: true,
  reconnectionDelay: 5000,
  reconnectionAttempts: 20,
  // transports: ['polling'],
};

const initSocket = () => {
  if (socket === null) {
    socket = io(socketServerUrl, config);
    socket.on('connect', () => {
      // eslint-disable-next-line no-console
      // console.log(`Socket is connected with id: ${socket.id}`);
    });
  }
};

const getSocket = () => {
  if (!socket) {
    initSocket();
  }
  return socket;
};

export { initSocket, getSocket };
