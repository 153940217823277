import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';

const getScoresByMonth = ({ month, classId }: { month: number; classId: string }) => {
  const sqlCommand = 'p_SACHSO_EBOOKS_GetScores_ByClassId';
  const parameters = { month, classId };
  const applicationName = settings.APPLICATION_NAME;
  return query(sqlCommand, parameters, applicationName);
};

export default getScoresByMonth;
