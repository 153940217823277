import { Popconfirm, Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { deleteFile, execute, get, remove } from 'helpers/QueryHelper';
import { useQuery, useQueryClient } from 'react-query';
import settings from 'constants/settings';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const FileDeleting = ({
  id,
  selectedFolderId,
  fileName,
  KEY_SHARE_ATTACHMENTS,
  buttonText,
  buttonIcon,
  buttonType,
  buttonShape,
  toolTipTitle,
  toolTipPlacement,
  isDanger,
}) => {
  const { t } = useTranslation('');
  const queryClient = useQueryClient();

  const onDeleteConfirm = async () => {
    await remove('Attachments', id, 'SHARE');
    await deleteFile('Folders', selectedFolderId, fileName, 'CLASSES', 'SACHSO');
    queryClient.invalidateQueries(KEY_SHARE_ATTACHMENTS);
  };

  const createButton = (buttonText, buttonIcon, buttonType, buttonShape) => (
    <Button danger={isDanger} icon={buttonIcon} type={buttonType} shape={buttonShape}>
      {buttonText}
    </Button>
  );

  return (
    <Popconfirm
      title={i18next.t('Are you sure to delete', { object: t('file') })}
      okText={t('Confirm')}
      cancelText={t('Close')}
      onConfirm={onDeleteConfirm}
    >
      {toolTipTitle ? (
        <Tooltip title={toolTipTitle} placement={toolTipPlacement}>
          {createButton(buttonText, buttonIcon, buttonType, buttonShape)}
        </Tooltip>
      ) : (
        createButton(buttonText, buttonIcon, buttonType, buttonShape)
      )}
    </Popconfirm>
  );
};

FileDeleting.defaultProps = {
  id: '',
  selectedFolderId: '',
  fileName: '',
  KEY_SHARE_ATTACHMENTS: [],
  buttonText: '',
  buttonIcon: <DeleteOutlined />,
  buttonType: 'primary',
  buttonShape: 'default',
  toolTipTitle: '',
  toolTipPlacement: 'top',
  isDanger: true,
};

export default FileDeleting;
