/* eslint-disable sort-keys */
/* eslint-disable react/no-array-index-key */
import { Tag } from 'antd';
import i18next from 'i18next';
import PreviewQuestion from './PreviewQuestion';

const columns = [
  {
    title: i18next.t('question'),
    dataIndex: 'questionText',
    key: 'questionText',
    render: (_, record) => {
      // return <span>{record?.questionText || record?.answers?.[0]?.text}</span>;
      return <div dangerouslySetInnerHTML={{ __html: record?.questionText || record?.answers?.[0]?.text || '' }} />;
    },
  },
  {
    title: i18next.t('typeQuestions'),
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
  },
  {
    title: i18next.t('Status'),
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => {
      return <Tag color={record?.color}>{record?.status}</Tag>;
    },
  },
  {
    title: i18next.t('Preview'),
    dataIndex: 'preview',
    key: 'preview',
    render: (text, record) => {
      return <PreviewQuestion data={record} />;
    },
  },
];
export const tableAllQuestion = {
  rowKey: 'id',
  displayColumns: [
    {
      title: i18next.t('question'),

      key: 'questionText',
      dataIndex: 'questionText',
      width: '75%',
      render: (text, record) => {
        // const trimString = (string) => {
        //   return string.length > 100 ? `${string.substring(0, 100)}...` : string;
        // };
        // return <div>{trimString(text)}</div>;
        // return <div>{record?.questionText || record?.answers?.[0]?.text}</div>;
        return <div dangerouslySetInnerHTML={{ __html: record?.questionText || record?.answers?.[0]?.text || '' }} />;
      },
      sort: false,
    },
    {
      title: i18next.t('typeQuestions'),

      key: 'type',
      dataIndex: 'type',
      width: '5%',
      render: 'renderNoWrap',
      sort: false,
    },
    {
      title: i18next.t('Level'),
      key: 'gradeName',
      dataIndex: 'gradeName',
      width: '5%',
      render: 'renderNoWrap',
      sort: false,
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'tags',
      width: '10%',
      style: {
        minWidth: 100,
      },
      render: 'renderNoWrap',
      sort: false,
    },
    
  ],
};

export const columnQuestion = [
  {
    title: i18next.t('question'),

    dataIndex: 'questionText',
    render: (_, record) => {
      // return <span>{record?.questionText || record?.answers?.[0]?.text}</span>;
      return <div dangerouslySetInnerHTML={{ __html: record?.questionText || record?.answers?.[0]?.text || '' }} />;
    },
  },
  {
    title: i18next.t('typeQuestions'),

    dataIndex: 'type',
  },
  {
    title: i18next.t('optionType'),
    dataIndex: 'optionType',
  },
  {
    title: i18next.t('Level'),
    dataIndex: 'gradeName',
  },
];

export default columns;

const getColor = (value) => {
  let color = '';
  switch (value) {
    case 'success':
      color = 'green';
      break;
    case 'failed':
      color = 'red';
      break;
    case 'new':
      color = 'blue';
      break;
    default:
      break;
  }
  return color;
};
