import { notification } from 'antd';
import Listen from 'components/Listening';
import PronunciationAssessment from 'components/Modal/PronunciationAssessment';
import Recorder from 'components/Recording/react_mic_no_score';
import functions, { uuid } from 'components/functions';
import React from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import QuestionHeader from '../../QuestionHeader';
import { QuestionComponentProps, QuestionComponentRef } from '../../type';
import RecorderTypeContent from '../components/RecorderTypeContent';
import { ResultRecordProps } from '../types/recordType';
import QuestionBlockQuote from './QuestionBlockQuote';

notification.config({
  placement: 'top',
  maxCount: 2,
  duration: 1,
});

const ListenAndSpeak = (
  {
    studentId,
    startRecord,
    data,
    questionIndex,
    onSubmit,
    setIsDisableContinue,
    userData,
    showUserData = false,
    onSubmitWhenCancel,
  }: QuestionComponentProps,
  ref: QuestionComponentRef,
) => {
  // * Kết quả record
  const [resultRecord, setResultRecord] = React.useState<any>(undefined);

  // * Hiển thị phần Record
  const [hasRecord, setHasRecord] = React.useState<boolean>(true);

  // * Hiển thị phần nghe
  const [hasListen, setHasListen] = React.useState<boolean>(false);

  // * Kiểm tra trạng thái nghe
  const [isListen, setIsListen] = React.useState<boolean>(true);

  // * Hiển thị Question
  const [showQuestion, setShowQuestion] = React.useState<boolean>(false);

  const refRecorder = React.createRef<any>();
  const refCountdownTimer = React.createRef<any>();

  React.useImperativeHandle(ref, () => ({
    onNext() {
      handleSubmit();
    },
    onSubmitWhenCancel() {
      handleSubmitOnCanceling();
    },
  }));

  React.useEffect(() => {
    if (userData && showUserData) {
      setHasListen(false);
      setHasRecord(false);
      setResultRecord(userData.answers);
      setShowQuestion(true);
    } else {
      setHasListen(true);
    }
  }, []);

  const onRecording = () => {
    if (typeof startRecord === 'function') {
      setResultRecord(undefined);
    }
    refCountdownTimer.current.startTimer();
    setIsDisableContinue(true);
  };

  const onStopTimer = () => {
    if (typeof refCountdownTimer.current?.stopTimer === 'function') {
      refCountdownTimer.current?.stopTimer();
      setResultRecord(undefined);
    }
  };

  const onEndCountDown = () => {
    refRecorder.current?.onStopCountdown();
  };

  const onStopRecording = (result: ResultRecordProps) => {
    setIsDisableContinue(false);
    setResultRecord({
      recordUrl: result?.recordUrl,
    });
    onEndCountDown();
    setShowQuestion(true);
  };

  const onListenStart = () => {
    setIsListen(true);
  };
  const onListenEnded = () => {
    setTimeout(() => {
      setIsListen(false);
    }, 500);
  };

  const isDoneCal = (status: boolean) => {
    if (status) {
      setIsDisableContinue(false);
    }
  };

  const renderRecorder = () => {
    const recordParams = {
      questionId: data?.id,
      questionText: data?.questionText,
      studentId,
      takeExamTime: uuid(),
    };

    const renderListen = (
      <>
        {hasListen && (
          <CardTitle className='mb-0'>
            <Listen audioURL={data?.audioUrl} onAuto={false} onListened={onListenStart} onEnded={onListenEnded} />
          </CardTitle>
        )}
      </>
    );

    return (
      <Recorder
        ref={refRecorder}
        __custom
        __className={'question-type__recordType02'}
        __onRecording={onRecording}
        __onStopRecording={onStopRecording}
        recordParams={recordParams}
        onStopTimer={onStopTimer}
        assessment={true}
        listen={renderListen}
        isListen={isListen}
        __isDoneCal={isDoneCal}
        hasRecord={hasRecord}
      >
        <RecorderTypeContent
          seconds={15}
          refCountdownTimer={refCountdownTimer}
          onStopRecording={onStopRecording}
          onEndCountdown={onEndCountDown}
          resultRecord={resultRecord}
          functions={functions}
          hasScore={false} //* Chấm điểm
          hasRecord={hasRecord}
        />
        {showQuestion && <QuestionBlockQuote question={data}></QuestionBlockQuote>}
      </Recorder>
    );
  };

  const resetRecord = () => {
    refRecorder.current?.stopOnNext();
  };

  const handleAnswersNResult = () => {
    const answers = resultRecord;
    const result = resultRecord ? resultRecord?.score : 0;
    resetRecord();
    return {
      answers,
      result,
    };
  };
  const handleSubmitOnCanceling = () => {
    const { answers, result } = handleAnswersNResult();

    onSubmitWhenCancel?.({id: data.id, answers, result }, data);
  };
  const handleSubmit = () => {
    const { answers, result } = handleAnswersNResult();

    onSubmit?.({id: data.id, answers, result }, data);
    setResultRecord(undefined);
    refRecorder.current?.cancelRequest();
    setShowQuestion(false);
    setIsListen(true);
  };

  return (
    <Card>
      <CardHeader>
        <QuestionHeader title={data.title} subTitle={data.subTitle} questionIndex={questionIndex} />
      </CardHeader>
      <CardBody style={{ flex: 'none', textAlign: 'center', minHeight: 300 }}>{renderRecorder()}</CardBody>
      <PronunciationAssessment assessment={undefined} isSentence={false} />
    </Card>
  );
};

export default React.forwardRef(ListenAndSpeak);
