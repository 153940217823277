/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable sort-keys */
import { WarningFilled, WarningOutlined } from '@ant-design/icons';
import { Card, Modal } from 'antd';
import settings from 'constants/settings';
import i18next from 'i18next';
import React from 'react';
const BACKGROUND_COLOR = '#F7F7F7';
export default function WarningModal({ visibleWarningModal, setVisibleWarningModal, contentWarningModal }) {
  const title = (
    <div style={{ fontSize: 24, color: 'red', height: '100%' }}>
      <WarningOutlined />
    </div>
  );
  return (
    <Modal
      onCancel={() => setVisibleWarningModal(false)}
      open={visibleWarningModal}
      style={{ padding: '0 !important' }}
      centered
      bodyStyle={{ backgroundColor: BACKGROUND_COLOR }}
      closable={false}
      footer={
        <div
          style={{
            textAlign: 'center',
            backgroundColor: '#F65656',
            color: 'white',
            margin: '-10px -16px',
            padding: '0.5rem 1rem',
            fontFamily: 'Poppins',
            fontSize: 24,
            cursor: 'pointer',
          }}
          onClick={() => setVisibleWarningModal(false)}
        >
          {i18next.t('Close')}
        </div>
      }
    >
      <Card>
        <div style={{ backgroundColor: BACKGROUND_COLOR }}>
          <div
            style={{
              fontSize: 60,
              color: '#F65656',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '2rem',
            }}
          >
            <WarningFilled />
          </div>
          <div style={{ textAlign: 'center', fontFamily: 'Poppins' }}>
            <p style={{ marginBottom: 0, fontSize: 30, color: '#475D6D', fontWeight: '500' }}>
              {i18next.t('slide:WarningModal.warning')}
            </p>
            <p style={{ marginBottom: 0, fontSize: 24, color: '#6C7D8A' }}>{contentWarningModal}</p>
          </div>
        </div>
      </Card>
    </Modal>
  );
}
