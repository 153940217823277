import { PaperClipOutlined } from '@ant-design/icons';
import React from 'react';
import colors from 'constants/colors';
import settings from 'constants/settings';
import i18next from 'i18next';

function truncate(text: string, n: number) {
  if (text) return text.length > n ? `${text.substr(0, n - 1)} ...` : text;
  return '';
}

export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: i18next.t('table:reference.attachedFile'),
      key: 'fileName',
      render: 'renderPreviewFile',
      sort: false,
      style: {
        fontWeight: 500,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        color: colors.font,
      },
      prefix: <PaperClipOutlined className='tw-mr-2' />,
    },
    // {
    //   title: 'Tập tin đính kèm',
    //   key: 'fileName',
    //   render: (text: string, record: { fileUrl: string }) => {
    //     return (
    //       <React.Fragment>
    //         <a href={`https://cloud.softech.vn/${record.fileUrl}`} target='_blank' rel='noreferrer' style={{ color: colors.font, fontWeight: 500 }}>
    //           {truncate(text, 40)}
    //         </a>
    //       </React.Fragment>
    //     );
    //   },
    //   sort: false,
    //   style: {
    //     fontWeight: 600,
    //     cursor: 'pointer',
    //   },
    //   prefix: <PaperClipOutlined className='tw-mr-2' />,
    // },
  ],
};
