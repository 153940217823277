/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { fileToImgUrl } from '../configuration';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import i18next from 'i18next';

function UploadFile({ onChange, entityId, subModuleName = 'CLASSWORK', entityName = 'Assignments' ,attachmentId}) {
  const refFile = React.useRef();
  const [loading, setLoading] = useState(false);
  //
  const handleChange = async (e) => {
    const file = e.target.files[0];
    //data.fullName.split(' ').slice(-1).join(' ')
    if (!file) return null;
    setLoading(true);
    const imgObject = await fileToImgUrl(file, entityId, subModuleName, entityName , attachmentId);

    if (imgObject?.id) {
      onChange?.(imgObject);
    }
    setLoading(false);
  };

  return (
    <div>
      <DynamicButton
        style={{ border: 'none' }}
        onClick={() => refFile.current.click()}
        icon={loading ? <LoadingOutlined /> : <UploadOutlined />}
        type='primary'
      >
        {i18next.t('Choose a file')}
      </DynamicButton>
      <input ref={refFile} style={{ display: 'none' }} type='file' onChange={handleChange} />
    </div>
  );
}

UploadFile.propTypes = {
  onChange: PropTypes.func,
  entityId: PropTypes.string,
  subModuleName: PropTypes.string,
  entityName: PropTypes.string,
};

export default UploadFile;
