import { query, queryFirst } from 'helpers/QueryHelper';

type EnterOffLinePTScore_Parameters = {
  PTCode: string;
  ListenScoreOffline: number;
  SpeakScoreOffline: number;
  ReadScoreOffline: number;
  WriteScoreOffline: number;
  UpdateBy: number;
};

const enterOffLinePTScore = (parameters: EnterOffLinePTScore_Parameters) => {
  // @ModuleId UNIQUEIDENTIFIER,
  // @PTCode VARCHAR(20),
  // @ListenScoreOffline DECIMAL(6, 2) = 0,
  // @SpeakScoreOffline DECIMAL(6, 2) = 0,
  // @ReadScoreOffline DECIMAL(6, 2) = 0,
  // @WriteScoreOffline DECIMAL(6, 2) = 0,
  // @UpdateBy INT
  return new Promise(async(resolve, reject)=>{
    const res = await queryFirst('p_QUIZCITY_PTTest_ScoreOffline_InsertUpdate', parameters);
    //status = 1 là thành công, 0 là thất bại
    if (res.status === 1) {
      return resolve(res);
    } else {
      return reject(res);
    }
  })
};

export { enterOffLinePTScore };
