import { Button, Form, notification } from 'antd';
import colors from 'constants/colors';
import settings from 'constants/settings';
import vimaruColors from 'constants/vimaru';
import { amesSendMail, query } from 'helpers/QueryHelper';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Card, CardBody, CardHeader } from 'reactstrap';
import './index.css';

export default function Verify(props) {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const state = props.location.state;
  // Khi chuyển từ page đăng kí sang verify thì truyền email qua prop, còn từ page login và forgot password thì truền qua href
  const email = searchParams.get('email') ? searchParams.get('email') : state?.email;
  const [form] = Form.useForm();
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [isSendEmailVerify, setIsSendEmailVerify] = useState(true);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const isEmptyOtp = otp.filter((o) => o === '');
  const isDisabled = !!isEmptyOtp.length;
  const otpInputs = useRef([]);
  const history = useHistory();

  React.useEffect(() => {
    if (!email) history.push('/');
  }, []);

  const sendEmailVerifiCode = (userEmail) => {
    amesSendMail(
      'SendMailFastVerifyQuizcity',
      { To: userEmail.toLowerCase(), ModuleID: settings.MODULE_ID },
      settings.APPLICATION_NAME,
    )
      .then((res) => {
        const { Message, UserId } = res;
        if (UserId) notification.success({ message: Message, duration: 10 });
        else {
          setIsSendEmailVerify(false);
          notification.error({ message: Message, duration: 10 });
        }
      })
      .catch((err) => console.log('Err SendMailFastVerifyQuizcity', err));
  };

  const handleFinish = () => {
    const inputCode = otp.join('');
    const parameters = {
      email: email,
      verifyCode: inputCode,
    };
    query('p_QUIZCITY_User_VerifyEmail', parameters).then((res) => {
      if (res?.[0]?.userId) {
        notification.success({ message: res?.[0]?.message, duration: 10 });
        history.push('/');
      } else {
        notification.error({ message: res?.[0]?.message, duration: 10 });
      }
    });
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').slice(0, 6).split('');

    setOtp(pastedData);
  };

  const handleKeyDown = (e, index) => {
    const value = e.key;
    if ('0123456789'.includes(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (otpInputs.current[index + 1]) otpInputs.current[index + 1].focus();
    } else if (e.code === 'Backspace' && otpInputs.current[index - 1]) {
      const newOtp = otp.map((item, itemIndex) => {
        if (itemIndex < index) return item;
        else return otp[itemIndex + 1] ?? '';
      });
      setOtp(newOtp);
      if (otpInputs.current[index - 1]) otpInputs.current[index - 1].focus();
    }
  };

  const disabledButton = _.debounce(() => {
    // Perform your click logic here
    // Enable the button again after the click logic is done
    setButtonDisabled(false);
  }, 5000); // Set a suitable delay time

  const handleButtonClick = () => {
    if (!isButtonDisabled) {
      setButtonDisabled(true);
      sendEmailVerifiCode(email);
      disabledButton();
    }
  };

  return (
    <Card className='bg-secondary border-0 mb-0'>
      <CardHeader className='bg-transparent pb-3'>
        <span style={{ fontSize: 20, fontWeight: 600, color: colors.font, textTransform: 'uppercase' }}>
          Xác thực tài khoản
        </span>
      </CardHeader>
      <CardBody className='px-lg-2 py-lg-2'>
        <div style={{ paddingInline: 32, paddingBlock: 20 }}>
          <Form
            autoComplete='off'
            form={form}
            onFinish={handleFinish}
            requiredMark={false}
            // labelCol={{ span: 7 }}
            // wrapperCol={{ span: 17 }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {otp.map((digit, index) => (
                <input
                  className='verify-otp-input mx-lg-2 mx-md-2 mx-sm-1 px-sm-0 px-xs-0'
                  type='text' // Sử dụng type 'text' thay vì 'number'
                  inputMode='numeric' // Sử dụng inputMode để chỉ cho phép nhập số
                  pattern='^[0-9]*' // Sử dụng pattern để chỉ cho phép ký tự số
                  style={{
                    flex: 1,
                    aspectRatio: 1,
                    fontSize: '1.5rem',
                    fontWeight: 400,
                    padding: 0,
                    textAlign: 'center',
                  }}
                  value={digit}
                  maxLength={1}
                  placeholder='0'
                  ref={(ref) => (otpInputs.current[index] = ref)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  autoFocus={index === 0}
                />
              ))}
            </div>
            <Button
              disabled={isDisabled}
              style={{ width: '100%', marginTop: 20, backgroundColor: '#1480EC', color: 'white' }}
              type='primary'
              htmlType='submit'
              size='large'
            >
              Xác thực
            </Button>

            <div className='text-light' style={{ marginTop: 5 }}>
              {isSendEmailVerify ? (
                <>
                  <span>Chưa nhận được mã ?</span>
                  <Button type='text' onClick={handleButtonClick} disabled={isButtonDisabled}>
                    {' '}
                    <span style={{ fontWeight: 'bold', color: vimaruColors.tone1 }}> Gửi lại mã</span>
                  </Button>
                </>
              ) : (
                <span>Vui lòng kiểm tra email và sử dụng mã xác thực đã gửi trước đó.</span>
              )}
            </div>
          </Form>
        </div>
      </CardBody>
    </Card>
  );
}
