import { formatDateTimeQuery } from 'constants/FormatString';
import { MODULE_ID } from 'constants/settings';
import moment from 'moment';

//Lẫy key theo moduleId để không trùng khi sử dụng localhost
const KEY_LOCAL_STORAGE = 'storage_infors_' + MODULE_ID ;

type Options = {
  label: any;
  value: any;
  availableTime?: string;
  [key: string]: any;
};

type Props = {
  questionGrade?: Options[];
  questionTag?: Options[];
  questionType?: Options[];
  startTimeSlide?:string; //thời gian bắt đầu làm slide (YYYY-MM-DD HH:mm:ss)
};

function getTimeNo() {
  return moment().format(formatDateTimeQuery);
}

function checkAvailableTime({ time, availableTime = 300 }: { time: string; availableTime?: number }): boolean {
  //Dùng để kiểm tra tính khả dụng của dữ liệu
  //rangeTime thời gian khả dụng (giây)
  const timeNo = moment().subtract(availableTime, 'seconds').format(formatDateTimeQuery);
  if (timeNo < time) {
    return true;
  } else {
    return false;
  }
}

function getItemLocalStorage(): Props {
  const jsonValues = localStorage.getItem(KEY_LOCAL_STORAGE);
  const values = jsonValues ? JSON.parse(jsonValues) : {};
  return values;
}

function setItemLocalStorage(data: Props) {
  const oldValues = getItemLocalStorage();
  const newValues = JSON.stringify({ ...oldValues, ...data });
  localStorage.setItem(KEY_LOCAL_STORAGE, newValues);
}

function removeItemLocalStorage() {
  localStorage.removeItem(KEY_LOCAL_STORAGE);
}

export { setItemLocalStorage, getItemLocalStorage, removeItemLocalStorage, getTimeNo, checkAvailableTime };
export type {Options};