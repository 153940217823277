import { message } from 'antd';
import { get, query } from 'helpers/QueryHelper';
import { useQuery } from 'react-query';
const entityName = 'Category';
const subModuleName = 'QuestionType';
const moduleName = 'QUIZCITY';

export const InsertDataQuestion = (
  type,
  optionType,
  questionText,
  answers,
  imageUrl,
  audioUrl,
  videoUrl,
  Grade,
  unit,
  settings,
  isActive,
  notes,
  gradeCode,
  createdBy,
  tags,
  imageSVG,
) => {
  const parameters = {
    type,
    optionType,
    questionText,
    answers,
    imageUrl,
    audioUrl,
    videoUrl,
    Grade,
    unit,
    settings,
    isActive,
    notes,
    gradeCode,
    createdBy,
    tags,
    imageSVG,
  };

  // console.log(parameters);
  return query('p_QUIZCITY_ImportQuiz_ByExel', parameters);
};
export const SelectAllType = () => {
  return useQuery('p_QUIZCITY_QuestionType_Category_Select', () => {
    return query('p_QUIZCITY_QuestionType_Category_Select');
  });
};
export const getDataGrade = () => {
  return useQuery('v_ENUM_Grade', () => {
    return query('p_QUIZCITY_GetGrade');
  });
};
export const SelectDataQuestion = (questionText = '', type = '', optionType = '', Grade = '', Unit = '', tag = '') => {
  const parameters = {
    questionText,
    type,
    optionType,
    Grade,
    Unit,
    tag,
  };
  return query('p_QUIZCITY_ResourcesQuestion_Search', parameters);
};
export const getAllTagsQuestion = () => {
  return useQuery('p_QUIZCITY_GetQuestionTag', () => {
    return query('p_QUIZCITY_GetQuestionTag');
  });
};

// crudimport { useQuery } from 'react-query';
