import { Progress, Rate } from 'antd';
import colors from 'constants/colors';
import { studentColumns } from 'constants/displayColumns';
import settings from 'constants/settings';
import StudentsEssay from '.';
import MarkingEssay from '../MarkingEssay';
import i18next from 'i18next';

export default {
  rowKey: 'id',
  displayColumns: [
    {
      key: 'createdDate',
      render: 'renderDateTime',
      title: i18next.t('Date'),
      width: '10%',
    },
    {
      key: 'lessonTitle',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: 'Lesson',
      width: '10%',
    },

    {
      key: 'presentationTitle',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: 'Part',
      width: '10%',
    },
    {
      key: 'title',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('Title'),
      width: '10%',
    },
    {
      key: 'scoreEssay',
      dataType: 'number',
      render: (scoreEssay, record, index) => {
        return (
          <div
            style={{
              color: scoreEssay > 5 ? (scoreEssay >= 8 ? '#4ADA92' : '#FEC12D') : '#EE444C',
              fontWeight: 'bold',
            }}
          >
            {scoreEssay ? scoreEssay.toFixed(2) : i18next.t('Not graded yet')}
          </div>
        );
      },
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('Score'),
      width: '10%',
    },
    {
      key: 'Essay',
      render: (text, record) => {
        return (
          <MarkingEssay record={record} scoreEssay={record?.scoreEssay} teacherNoteEssay={record?.teacherNoteEssay} />
        );
      },
      sort: false,
      style: {
        fontWeight: 400,
      },
      title: '',
      width: '1%',
    },
  ],
};
