import { Button, Form, Input, Modal, Row, Upload, Col, InputNumber, Image, message } from 'antd';
import { useStudentsOrStudentByClassId } from 'modules/Teacher/api/getStudentsOrStudentByClassId';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import tableData from './tableData';
import { useQuery, useQueryClient } from 'react-query';
import { query } from 'helpers/QueryHelper';
import tableData from './tableData';
import StudentInfomationHeader from 'modules/Student/components/StudentInfomationHeader';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import i18next from 'i18next';
const fetcher = (exerciseId, studentId) => {
  return query('p_SACHSO_EBOOKS_GetAnswerLogs_ByStudentId_Essay', { exerciseId, studentId });
};
const StudentsEssay = ({ record }) => {
  const [visible, setVisible] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({ scoreEssay: 0, teacherNoteEssay: '', textEssay: '' });
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const KEY = ['p_SACHSO_EBOOKS_GetAnswerLogs_ByStudentId_Essay', record?.exerciseId, record?.studentId];
  const { data, isLoading } = useQuery(KEY, () => fetcher(record?.exerciseId, record?.studentId), {
    enabled: visible,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        scoreEssay: data[0].score,
        teacherNoteEssay: data[0].teacherNoteEssay,
        textEssay: data[0].studentAnswer.slice(2, -2).replace(/\\n/g, '\r\n'),
      });
    }
  }, [data]);
  const onFinish = (values) => {
    const param = {
      studentId: record?.studentId,
      exerciseId: record?.exerciseId,
      score: values.scoreEssay,
      teacherNoteEssay: values.teacherNoteEssay,
    };
    query('p_SACHSO_EBOOKS_SaveScoreEssay', param)
      .then(() => {
        queryClient.invalidateQueries('p_SACHSO_EBOOKS_GetScores_ByClassIdAndStudentId');
        setVisible(false);
        message.success('Chấm điểm thành công');
      })
      .error((err) => {
        console.error(err);
        message.error('Chức năng này đang phát triển');
      });
  };
  return (
    <React.Fragment>
      <DynamicButton type='text' style={{ color: '#006EE6' }} onClick={() => setVisible(true)}>
        {i18next.t('slide:Essay.Essay work')}
      </DynamicButton>
      <Modal
        centered
        width='90vw'
        open={visible}
        title={i18next.t('slide:Essay.Essay work').toUpperCase()}
        onCancel={() => setVisible(false)}
      >
        <Form
          form={form}
          // initialValues={{ audioUrl, imageUrl, textEssay, videoUrl, scoreEssay, teacherNoteEssay }}
          initialValues={initialValues}
          onFinish={onFinish}
        >
          <h6 className='mb-4 heading-small text-muted'>{i18next.t('Score')}</h6>
          <Row gutter={[24, 24]}>
            <Col lg={18}>
              <Form.Item
                label={i18next.t('Score')}
                name='scoreEssay'
                rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('Score') }) }]}
                hasFeedback
                wrapperCol={{ span: 21 }}
                labelCol={{ span: 3 }}
              >
                <InputNumber style={{ width: '100%' }} min={1} max={10} step='0.1' />
              </Form.Item>
              {/* <span className='tw-font-medium tw-ml-2' style={{ color: colors.theme.success }}>
             {`/ ${workItem.assignmentPoints}`}
           </span> */}
              <Form.Item
                label={i18next.t('Comment')}
                name='teacherNoteEssay'
                wrapperCol={{ span: 21 }}
                labelCol={{ span: 3 }}
              >
                <Input.TextArea style={{ width: '100%' }} autoSize={{ minRows: 3 }} allowClear />
              </Form.Item>
              <Form.Item>
                <Row>
                  <Col span={3}></Col>
                  <Col span={21}>
                    <DynamicButton type='primary' htmlType='submit'>
                      {i18next.t('Save')}
                    </DynamicButton>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col lg={6}>
              <StudentInfomationHeader studentId={record?.studentId} />
            </Col>
          </Row>
          <h6 className='mb-4 heading-small text-muted'>Bài làm tự luận</h6>
          <Form.Item
            name='textEssay'
            rules={[
              {
                required: true,
                message: 'Vui lòng điền bài làm tự luận.',
              },
            ]}
          >
            <Input.TextArea style={{ color: '#000' }} rows={6} />
          </Form.Item>
          <hr />
        </Form>
      </Modal>
    </React.Fragment>
  );
};

StudentsEssay.propTypes = {
  classId: PropTypes.string,
};

StudentsEssay.defaultProps = {
  classId: '',
};

export default StudentsEssay;
