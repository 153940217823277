import { Col, Form, Input } from 'antd';
import React from 'react';
import Layout from '../../Layouts/Layout';

const IndexFillInTheBlank = ({ form, field, setAddField, countAnswerFillBlank, optionType, setValueAnswerInputs }) => {
  const RenderLayoutAnswer = () => {
    switch (optionType?.toUpperCase()) {
      case 'LOOKWRITE':
        return (
          <Layout setValueAnswerInputs={setValueAnswerInputs} field={field} layout={['input']} setAddField={setAddField} form={form} autoFocus={false} lg={24} />
        );
      case 'LISTENFIND':
        return (
          <Layout setValueAnswerInputs={setValueAnswerInputs} field={field} layout={['input']} setAddField={setAddField} form={form} autoFocus={false} lg={24} />
        );
      case 'LISTENWRITE':
        return (
          <Layout setValueAnswerInputs={setValueAnswerInputs} field={field} layout={['input']} setAddField={setAddField} form={form} autoFocus={false} lg={24} />
        );
      case 'LISTENLOOKANDCOMPLETE':
        return (
          <Layout setValueAnswerInputs={setValueAnswerInputs} field={field} layout={['input']} setAddField={setAddField} form={form} autoFocus={false} lg={24} />
        );
      case 'FILLINTHEBLANKWITHASUITABLEWORD':
        return (
          <Layout setValueAnswerInputs={setValueAnswerInputs} field={field} layout={['input']} setAddField={setAddField} form={form} autoFocus={false} lg={24} />
        );

      default:
        return <></>;
    }
  };
  return <RenderLayoutAnswer />;
};

export default IndexFillInTheBlank;
