import { Button, Modal, Space, notification } from 'antd';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import PropTypes from 'prop-types';
import React from 'react';
import tableData from './tableData';
import { useQuery } from 'react-query';
import { amesSendMail, query, queryFirst } from 'helpers/QueryHelper';
import NoData from 'components/NoData';
import { useParams } from 'react-router';
import i18next from 'i18next';
import  { DOMAIN_IS_USE, PATHNAME, studentPronouns2 } from 'constants/settings';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
const fetcher = (ptCode) => {
  return query('p_QUIZCITY_PTTest_GetAnswerLogs_ByCode', { ptCode, isEssay: true });
};
const StudentsEssay = ({ record }) => {
  const [visible, setVisible] = React.useState(false);
  const KEY = ['p_QUIZCITY_PTTest_GetAnswerLogs_ByCode', record?.code];
  const { data, isLoading } = useQuery(KEY, () => fetcher(record?.code), { enabled: visible });

  return (
    <React.Fragment>
      <Button type='text' style={{ color: '#006EE6' }} onClick={() => setVisible(true)}>
        Chấm bài tự luận
      </Button>
      <Modal
        centered
        width='90vw'
        open={visible}
        title={i18next.t('slide:Essay.Essay work')}
        onCancel={() => setVisible(false)}
        bodyStyle={{
          minHeight: '80vh',
        }}
        destroyOnClose
        footer={
          <Space>
            <DynamicButton onClick={() => setVisible(false)}>{i18next.t('Close')}</DynamicButton>
          </Space>
        }
      >
        {isLoading || (data && data.length) ? (
          <DynamicTable loading={isLoading} initialTableData={tableData} dataSource={data} />
        ) : (
          !isLoading && (
            <NoData
              description={i18next.t('slide:Essay.{{student}} who have not submitted their essays yet', {
                student: studentPronouns2,
              })}
            />
          )
        )}
      </Modal>
    </React.Fragment>
  );
};

StudentsEssay.propTypes = {
  classId: PropTypes.string,
};

StudentsEssay.defaultProps = {
  classId: '',
};

export default StudentsEssay;
