import { Button, Form, Input, notification, Select } from 'antd';
import Container from 'components/Containers/Container';
import settings from 'constants/settings';
import { getWithoutModuleId, query, queryWithoutModuleId } from 'helpers/QueryHelper';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

const fetchModules = () => {
  return getWithoutModuleId('Module', '*', {}, 'createdDate DESC', '', 'SACHSO');
};

const fetchCurrentModule = () => {
  return queryWithoutModuleId('p_QUIZCITY_GetModuleTest', {}, settings.APPLICATION_NAME);
};
export default function SetModule() {
  const { data } = useQuery('dbo.t_SACHSO_Module', () => fetchModules());
  const { data: currentModule } = useQuery('p_QUIZCITY_GetModuleTest', () => fetchCurrentModule());
  const [options, setOptions] = React.useState([]);
  const history = useHistory();
  const [form] = Form.useForm();
  const [displayCurrentModule, setDisplayCurrentModule] = React.useState(null);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (currentModule) {
      form.setFieldsValue({
        moduleId: currentModule?.[0]?.id,
      });
      setDisplayCurrentModule(currentModule?.[0]?.moduleName);
    }
  }, [currentModule]);

  React.useEffect(() => {
    if (data) {
      const handledData = data.map((a) => ({
        value: a.id,
        label: a.moduleName,
      }));
      setOptions(handledData);
    }
  }, [data]);

  React.useEffect(() => {
    const user = localStorage.getItem('it');
    if (user !== '849222') {
      history.push('/login/setmodule');
    }
  });
  const onFinish = async (values) => {
    const { moduleId } = values;
    const parameters = {
      moduleId: moduleId,
    };
    queryWithoutModuleId('p_QUIZCITY_Module_UpdateIsTest', parameters, settings.APPLICATION_NAME).then((res) =>
      queryClient.invalidateQueries(['p_QUIZCITY_GetModuleTest']),
    );

    if (moduleId) {
      notification.success({ message: 'Chọn Module thành công' });
    } else {
      notification.error({ message: 'Vui lòng chọn Module' });
    }
  };
  return (
    <Container>
      <Form form={form} onFinish={onFinish}>
        <Card>
          <CardHeader>SET MODULE</CardHeader>
          <CardBody className='py-0'>
            <Row className='my-3'>
              <Col sm='8'>
                <div className='text-light'>
                  <span>{`Module hiện tại: ${displayCurrentModule ?? ''}`}</span>
                </div>
              </Col>
            </Row>
            <Form.Item name='moduleId' label='Module' labelCol={{ span: 2 }} wrapperCol={{ span: 4 }}>
              <Select options={options} />
            </Form.Item>
            <Form.Item style={{ paddingLeft: '8.33%' }}>
              <Button type='primary' htmlType='submit'>
                Chọn
              </Button>
            </Form.Item>
            <Form.Item style={{ paddingLeft: '8.33%' }}>
              <Button type='primary' onClick={() => history.push(`/${settings?.PATHNAME}/home`)}>
                Về trang chủ
              </Button>
            </Form.Item>
          </CardBody>
        </Card>
      </Form>
    </Container>
  );
}
