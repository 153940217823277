/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery } from 'react-query';
import { Redirect } from 'react-router-dom';
import ClassDetails from 'modules/Teacher/components/ClassDetails';
import Container from 'components/Containers/Container';
import Card from 'components/Containers/Card';
import withAuthenticator from 'HOCs/withAuthenticator';
import { queryFirst } from 'helpers/QueryHelper';
import ClassInfomationHeader from 'pages/Administration/Vimaru/ClassInfomationHeader';
import i18next from 'i18next';
export default withAuthenticator(({ loggedInUser, match }) => {
  const { classId } = match.params;

  // p_SACHSO_CLASSES_CheckTeacher
  const KEY = ['p_SACHSO_CLASSES_CheckTeacher', loggedInUser.id, classId];
  const { data, isSuccess } = useQuery(KEY, () => {
    return queryFirst('p_SACHSO_CLASSES_CheckTeacher', { classId, teacherId: loggedInUser.id });
  });

  if (isSuccess) {
    if (!data) return <Redirect to='/lms/access-denied' />;
  }

  return (
    <Container name={i18next.t('ClassInformation')} parentName='LMS'>
      <ClassInfomationHeader classId={classId} />
      <Card>
        <ClassDetails classId={classId} />
      </Card>
      {/* <ClassComponent /> */}
    </Container>
  );
});
