import React from 'react';
import ButtonUpload from '../../Layout/ButtonUpload';
import AddInput from '../../Modules/AddInput';
import InputTitle from '../../Modules/FormHeader';

const MatchThePictures = ({ form, content, field_paren }) => {
  return (
    <>
      {/* <ButtonUpload buttonText={'Upload Audio File'} /> */}
      <InputTitle fieldC={field_paren} />
      <AddInput form={form} content={content} fieldC={field_paren} view={['image', 'input']} />
    </>
  );
};

export default MatchThePictures;
