/* eslint-disable react/no-array-index-key */
import { Select } from 'antd';
import colors from 'constants/colors';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { WorksheetTypesProps } from '../../type';

// againAnswer={resultState?.dropDownResult}
// isDisableTest={isDisableTest}
function DropDown({ type, data, againAnswer, isDisableTest, isHiddenShowColor }: WorksheetTypesProps) {
  const [state, setState] = useState<any>([]);
  const [result, setResult] = useState<any>(undefined);

  const handleSelect = (e: any, indexGroup: number, index: number) => {
    const array: any = _.cloneDeep(state);
    array[indexGroup][index].userAnswers = e;
    setState(array);
  };

  const handleChange = (e: any, indexGroup: any, index: any) => {
    const array: any = _.cloneDeep(state);
    array[indexGroup][index].userAnswers = e;
    setState(array);
  };

  //data đang là mảng phẳng, cần format về dạng gom nhóm để render
  const formatData = (answers?: any[]) => {
    if (!data) {
      return [];
    }
    const floatArray = answers ? _.flatten(answers) : [];
    //Nếu có kết quả làm bài thì tìm input tương ứng để lấy đáp án
    const matchAnswerToData = answers
      ? data.map((item: any) => {
          const userAnswers = floatArray.find((i) => i.left === item.left && i.top === item.top)?.userAnswers;
          return { ...item, userAnswers };
        })
      : _.cloneDeep(data);
    //gom nhóm các input cùng group
    const group = _.groupBy(matchAnswerToData, 'groupName');
    const newData = _.cloneDeep(Object.values(group));
    return newData;
  };

  useEffect(() => {
    setState(formatData(againAnswer?.userData));
  }, [data?.length, againAnswer]);

  useEffect(() => {
    Object?.assign(type, {
      submit: () => {
        if (!data) return {};
        // const res = handleResult(state);
        const res = handleResultByGroup(state);
        // setState(res.userData);
        // setIsDone(true);
        return { dropDownResult: res };
      },
      tryAgain: () => {
        // setResult(undefined);
        setState(formatData(state));
      },
      setResult,
    });
    return () => {};
  }, [data, state]);

  if (!data) return null;

  return (
    <React.Fragment>
      {state.map((item: any, indexGroup: number) => {
        return (
          <React.Fragment key={indexGroup}>
            {item.map((value: any, index: number) => {
              const { width, top, left, angle, isCorrect, userAnswers, text } = value;
              // const correctAnswer = text?.split(';')?.filter((t: any) => t.includes('*'))?.[0]?.[0] ?? '';
              let color = colors.font;
              if (isDisableTest && !isHiddenShowColor) {
                if (isCorrect) {
                  color = colors.theme.success;
                } else if (isCorrect === false) {
                  color = colors.theme.danger;
                }
              }

              return (
                <Select
                  key={index}
                  style={{
                    position: 'absolute',
                    width,
                    top,
                    left,
                    transform: `rotate(${angle}deg)`,
                    fontSize: 20,
                    color,
                    fontWeight: 600,
                    borderColor: color,
                    cursor: isDisableTest ? 'no-drop' : 'pointer',
                  }}
                  onChange={(e) => {
                    handleChange(e, indexGroup, index);
                  }}
                  open={isDisableTest ? false : undefined}
                  value={userAnswers}
                  getPopupContainer={(triggerNode: any) => document.getElementsByClassName('match-container')[0]}
                >
                  {value.text.split(';').map((x: any, i: number) => (
                    <Select.Option key={i} value={x}>
                      {x.replace('*', '')}
                    </Select.Option>
                  ))}
                </Select>
              );
            })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

DropDown.propTypes = {
  type: PropTypes.object,
  data: PropTypes.array,
};

export default DropDown;

// #region Tính điểm theo nhóm (sai 1 chỗ, vẫn tính điểm chỗ khác)
const handleResultByGroup = (state: any) => {
  const newState = state.map((value: any) =>
    value.map((item: any) => Object.assign(item, { isCorrect: item.userAnswers?.includes('*') ?? false })),
  );

  // #region Tính điểm.
  const resultByGroup = newState.map((item: any) => {
    const correctByGroup = item.filter((x: any) => x.isCorrect).length;
    const totalByGroup = item.length;
    const score = correctByGroup / totalByGroup;
    // #region Kiểm tra câu hiện tại đã làm chưa?, không quan tâm đúng sai. Phía dưới dùng.
    const completedByGroup = item.some((x: any) => x.userAnswers);
    // #endregion
    return { groupName: item[0].groupName, score, completed: completedByGroup };
  });
  // #endregion

  const correct = resultByGroup
    .reduce((previousValue: any, currentValue: any) => previousValue + currentValue.score, 0)
    .toFixed(2);
  const total = resultByGroup.length;
  const percent = (parseInt(correct) * 100) / total;
  const resultString = `${correct}/${total}`;
  const star = percent / 20;
  // #region Đếm số câu đã làm
  const countSelected = resultByGroup.filter((x: any) => x.completed).length;
  const complete = `${countSelected}/${total}`;

  // #endregion

  return { userData: newState, percent, resultString, star, complete };
};
// #endregion

const handleResult = (state: any) => {
  const newState = state.map((value: any) =>
    value.map((item: any) => Object.assign(item, { isCorrect: item.userAnswers?.includes('*') ?? false })),
  );
  const correct = newState
    .map((value: any) => value.every((item: any) => item.isCorrect))
    .filter((item: any) => item).length;
  const total = state.length;
  const percent = (parseInt(correct) * 100) / total;
  const resultString = `${correct}/${total}`;
  const star = percent / 20;
  //
  const countSelected = newState.filter((group: any) => group.some((x: any) => x.userAnswers)).length;
  const complete = `${countSelected}/${newState.length}`;
  return { userData: newState, percent, resultString, star, complete };
};
