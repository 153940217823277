/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Form, Input } from 'antd';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import QuestionHeader from '../QuestionHeader';
import AudioButton from '../AudioButton';
import './styles.css';
import QuestionImage from '../QuestionImage';

const FillInTheBlank = ({ data, userData, questionIndex = 0, ExplainText }) => {
  const [form] = Form.useForm();
  const [resultForm] = Form.useForm();
  const [DATA, setDATA] = React.useState(null);

  React.useEffect(() => {
    if (userData) {
      const correctAnswers = {};
      data.answers.forEach((item, index) => {
        correctAnswers[index + 1] = item.answer;
      });
      form.setFieldsValue(userData.answers);
      resultForm.setFieldsValue(correctAnswers);
    }
    const answers = data.answers.map((item, index) => {
      const answer = item.answer.split('|');
      const id = index + 1;
      return { ...item, id, answer };
    });
    setDATA({ ...data, answers });
  }, [data, form, resultForm, userData]);

  // Trả về đoạn văn hoặc câu hỏi dài
  const questionText = () => {
    if (!DATA.questionText) return null;
    return (
      <React.Fragment>
        <div className='questionText'>{DATA.questionText}</div>
      </React.Fragment>
    );
  };

  const renderQuestion = React.useCallback(() => {
    const text = DATA?.questionText?.split(/(___|\r\n)/g); // cắt bởi '___' hoặc '\r\n'
    const answers = Array.from(DATA.answers);
    const inputLength = Math.max(...answers.map((x) => x.answer.map((y) => y.length)).flat());
    return text.map((item, index) => {
      if (item === '\r\n') return <br key={index} />; // xuống dòng
      if (item === '___') {
        // input
        const answers_id = answers.shift().id;
        return (
          <div key={index} style={{ display: 'inline-block' }}>
            <Form.Item name={`${answers_id}`}>
              <Input
                disabled
                className='input-fill-in-the-blank'
                style={{ minWidth: 100, width: inputLength * 15, maxWidth: 400 }}
              />
            </Form.Item>
          </div>
        );
      }
      return <span key={index}>{item}</span>; // text
    });
  }, [DATA]);

  if (!DATA) return null;

  return (
    <Card className='m-0'>
      <CardHeader>
        <QuestionHeader
          title={DATA.title}
          subTitle={DATA.subTitle}
          questionIndex={questionIndex}
          tooltip='FillInTheBlank'
        />
      </CardHeader>
      <CardBody style={{ minHeight: 300 }}>
        <AudioButton audioUrl={DATA.audioUrl} />
        <QuestionImage imageUrl={DATA.imageUrl} />
        {/* {questionText()} */}
        <Form form={form}>
          <div className='questionText'>{renderQuestion()}</div>
        </Form>
      </CardBody>
      {ExplainText}
      <CardFooter>
        <h3>Đáp án đúng</h3>
        <Form form={resultForm}>
          <div className='questionText'>{renderQuestion()}</div>
        </Form>
      </CardFooter>
    </Card>
  );
};

FillInTheBlank.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FillInTheBlank;
