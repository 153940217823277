/* eslint-disable consistent-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sort-keys */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import { Col, DatePicker, Form, Input, message, Row, Select, Spin } from 'antd';
import settings, { IS_BASAO, IS_LX, IS_TRONGDONG } from 'constants/settings';
import { generateSHA1 } from 'helpers/CryptoHelper';
import { get, query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import registerNewUser from 'modules/Administration/api/registerNewUser';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

const configMessageForm = {
  required: 'Vui lòng nhập ${label}',
};

const fetcherAllClass = (userId) => () => {
  return query('p_VIMARU_CLASSES_GetClasses', { userId: userId });
};

const IndexFormAddUser = ({ buttonCloseModal, contentForm, type, useCallbackEditInformationUser }) => {
  const [loading, setLoading] = useState(false);
  const [dataKhoa, setDataKhoa] = useState([]);
  const [dataAllKhoaNganh, setDataAllKhoaNganh] = useState([]);
  const [isInformationTeacher, setInformationTeacher] = useState();
  const [isInformationTeacherById, setInformationTeacherById] = useState();
  const [isDisableChangeNganh, setDisableChageNganh] = useState(true);
  // const [isDisableInputClass, setDisableInputClass] = useState(true);
  const [optionsNganh, setOptionsNganh] = useState([]);
  const [optionsClass, setOptionsClass] = useState([]);
  const [optionsPosition, setOptionsPosition] = useState([]);
  const [optionsShop, setOptionsShop] = useState([]);
  const [classId, setClassId] = useState();
  const [form] = Form.useForm();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const queryClient = useQueryClient();

  const key = ['p_VIMARU_CLASSES_GetClasses'];
  const { data: dataAllClass, isLoading: loadingOptionClass } = useQuery(key, fetcherAllClass(loggedInUser?.id));

  React.useEffect(() => {
    if (settings.IS_VIMARU || settings.IS_HIGHUP) onGetDataCrud();
    else if (settings.IS_MENARD|| IS_BASAO || IS_TRONGDONG || IS_LX) {
      query('p_QUIZCITY_Position_Category_Select').then((res) => {
        const activePositions = res.filter((position) => position.isActive === true);
        setOptionsPosition(activePositions.map((pos) => ({ label: pos.categoryName, value: pos.id })));
      });
      query('p_QUIZCITY_Shop_Category_Select').then((res) => {
        const activeShops = res.filter((position) => position.isActive === true);
        setOptionsShop(activeShops.map((pos) => ({ label: pos.categoryName, value: pos.id })));
      });
    }
    getDataUserAll();
  }, []);
  React.useEffect(() => {
    const arrNewDataAll = dataAllClass?.map((item) => ({
      value: item.code,
      label: item.className,
      teacherId: item.teacherId,
      classId: item.id,
    }));
    setOptionsClass(arrNewDataAll);
  }, [dataAllClass]);
  const onFinish = (values) => {
    if (type === 'update') {
      useCallbackEditInformationUser?.(values, classId);
    } else {
      setLoading(true);
      const body = {
        ...values,
        gender: 1,
        parentFullname: '',
        status: 'ACTIVE',
      };
      const {
        lastName,
        firstName,
        email,
        password,
        userType,
        MaSv,
        phone,
        khoa,
        birthday,
        chuyen_nganh,
        name_teacher,
        phone_teacher,
        email_teacher,
        status,
        sex,
        chucVu,
        shop,
      } = body;
      const fullName = `${firstName} ${lastName}`;
      registerNewUser({
        email,
        password,
        passwordEncrypt: generateSHA1(password),
        fullName,
        roleId: userType,
        cityId: 0,
        phone: phone,
        maSv: MaSv,
        note: '',
        teacherId: isInformationTeacherById?.id,
        khoa: khoa || '00000000-0000-0000-0000-000000000000',
        nganh: chuyen_nganh || '00000000-0000-0000-0000-000000000000',
        classId: classId,
        birthDay: birthday ? birthday.format('yyyy-MM-DD') : null,
        status: status,
        sex: sex,
        shop: shop || '00000000-0000-0000-0000-000000000000',
        chucVu: chucVu || '00000000-0000-0000-0000-000000000000',
        ngayVaoCongTy: ngayVaoCongTy || null,
      }).then((res) => {
        if (res[0].res === 1) {
          message.success(i18next.t('createAccSuccess'));
          queryClient.invalidateQueries('Users');
          setLoading(false);
          buttonCloseModal?.();
        } else {
          if (res?.[0].email === email) {
            setLoading(false);
            message.error(i18next.t('alreadyExists'));
          } else if (res?.[0].maSV === MaSv) {
            message.error(i18next.t('alreadyExists'));
            setLoading(false);
          } else {
            message.error(i18next.t('createAccFailed'));
            setLoading(false);
          }
        }
      });
      form.resetFields();
      setLoading(false);
    }
  };
  const GenPassword = GenPasswordModel(2, 6);
  const onChangeValueClass = (value, option) => {
    const filterInformationTeacher = isInformationTeacher.filter((item) => {
      return item.id === Number(option?.teacherId);
    });

    form.setFieldsValue({
      phone_teacher: filterInformationTeacher?.[0]?.phone,
      email_teacher: filterInformationTeacher?.[0]?.email,
      name_teacher: filterInformationTeacher?.[0]?.fullName,
    });
    setInformationTeacherById(filterInformationTeacher?.[0]);
    setClassId(option?.classId);
  };
  const onGetDataCrud = () => {
    get('Category', '*', '', 'CreatedDate', 'METADATA', 'VIMARU').then((res) => {
      setDataAllKhoaNganh(res);
      const filterDataAllKhoa = res?.filter((item) => {
        return item.parentId === null;
      });
      setDataKhoa(
        filterDataAllKhoa?.map((item) => ({
          value: item?.id,
          label: item?.categoryName,
        })),
      );
      setOptionsNganh(
        res.map((item) => ({
          value: item?.id,
          label: item?.categoryName,
        })),
      );
    });
  };
  const getDataUserAll = () => {
    const parameter = {
      email: '',
      name: '',
      phone: '',
      maSV: '',
      roleId: 2,
      status: 'ACTIVE',
    };
    query('p_VIMARU_USERS_Search', parameter).then((res) => {
      setInformationTeacher(res);
    });
  };
  const handleChangeKhoa = (value) => {
    if (value) {
      setDisableChageNganh(false);
      const filterNganhByParentId = dataAllKhoaNganh.filter((item) => {
        return item?.parentId === value;
      });
      setOptionsNganh(
        filterNganhByParentId?.map((item) => ({
          value: item.id,
          label: item.categoryName,
        })),
      );
    }
  };
  // const onChangeValueNganh = (value) => {
  //   if (value) return setDisableInputClass(false);
  //   setDisableInputClass(true);
  // };
  // const onChangeValueTeacher = (value) => {
  //   const filterInformationTeacher = isInformationTeacher.filter((item) => {
  //     return item.id === value;
  //   });
  //   form.setFieldsValue({
  //     phone_teacher: filterInformationTeacher?.[0]?.phone,
  //     email_teacher: filterInformationTeacher?.[0]?.email,
  //     name_teacher: filterInformationTeacher?.[0]?.fullName,
  //   });
  //   setInformationTeacherById(filterInformationTeacher?.[0]);
  // };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Form
          layout='vertical'
          autoComplete='off'
          form={type === 'update' ? contentForm : form} // ? Check type
          onFinish={onFinish}
          requiredMark={false}
          validateMessages={configMessageForm}
          initialValues={{ MaSv: GenPasswordModel(1, 6), password: GenPassword, confirmPassword: GenPassword }}
        >
          <Form.Item style={{ fontWeight: 600 }} label='Mã người dùng' name='MaSv'>
            <Input />
          </Form.Item>
          <Row gutter={[12, 12]}>
            <Col lg={16} xs={24}>
              <Form.Item
                style={{ fontWeight: 600 }}
                label='Họ và tên đệm'
                name='firstName'
                hasFeedback
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8} xs={24}>
              <Form.Item
                style={{ fontWeight: 600 }}
                label='Tên'
                name='lastName'
                hasFeedback
                rules={[{ required: true }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col lg={16} xs={24}>
              <Form.Item
                style={{ fontWeight: 600 }}
                label='Email (Dùng để đăng nhập)'
                name='email'
                hasFeedback
                rules={[{ type: 'email', message: 'Email không hợp lệ' }, { required: true }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col lg={8} xs={24}>
              <Form.Item
                style={{ fontWeight: 600 }}
                label='Ngày sinh'
                name='birthday'
                hasFeedback
                rules={[{ required: true }, { type: 'date', message: 'Ngày không hợp lệ' }]}
              >
                <DatePicker
                  allowClear
                  popupStyle={{
                    zIndex: 999999999999999999,
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          {type !== 'update' && (
            <Row gutter={[12, 12]}>
              <Col lg={12} xs={24}>
                <Form.Item
                  style={{ fontWeight: 600 }}
                  label='Mật khẩu'
                  name='password'
                  hasFeedback
                  rules={[{ required: true }, { min: 6, message: 'Mật khẩu ít nhất có 6 ký tự' }]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item
                  style={{ fontWeight: 600 }}
                  label='Xác nhận mật khẩu'
                  name='confirmPassword'
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    { required: true },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Xác nhận mật khẩu không khớp'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={[12, 12]}>
            <Col lg={8} xs={24}>
              <Form.Item style={{ fontWeight: 600 }} label='Số điện thoại' name='phone' hasFeedback>
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col lg={8} xs={24}>
              <Form.Item name='sex' label='Giới tính' rules={[{ required: true }]}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  placeholder='Giới tính'
                  options={[
                    { value: 1, label: 'Nam' },
                    { value: 0, label: 'Nữ' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col lg={8} xs={24}>
              <Form.Item name='userType' label='Loại tài khoản' rules={[{ required: true }]}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  placeholder='Chọn loại tài khoản'
                  options={[
                    { value: 1, label: settings.studentPronouns2 },
                    { value: 2, label: 'Giáo viên' },
                    { label: 'Quản trị viên', value: 3 },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          {(settings.IS_MENARD|| IS_BASAO || IS_TRONGDONG || IS_LX) && (
            <Row gutter={[12, 12]}>
              <Col lg={16} xs={24}>
                <Form.Item style={{ fontWeight: 600 }} label='Bộ phận' name='shop'>
                  <Select
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    placeholder='Chọn bộ phận'
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={optionsShop}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col lg={8} xs={24}>
                <Form.Item style={{ fontWeight: 600 }} label='Chức vụ' name='chucVu'>
                  <Select
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    placeholder='Chọn chức vụ'
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={optionsPosition}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {(settings.IS_VIMARU || settings.IS_HIGHUP) && (
            <Row gutter={[12, 12]}>
              <Col lg={12} xs={24}>
                <Form.Item name='khoa' label='Khoa' rules={[{ required: settings.IS_VIMARU }]}>
                  <Select
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    onChange={handleChangeKhoa}
                    placeholder='Chọn khoa'
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={dataKhoa}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item name='chuyen_nganh' label='Chuyên ngành' rules={[{ required: settings.IS_VIMARU }]}>
                  <Select
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    placeholder='Chọn chuyên ngành'
                    optionFilterProp='children'
                    // onChange={onChangeValueNganh}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    disabled={settings.IS_VIMARU ? isDisableChangeNganh : false}
                    options={optionsNganh}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {type !== 'update' && !settings.IS_HANDOUT && (
            <Row gutter={[12, 12]}>
              <Col lg={6} xs={24}>
                <Form.Item name='class' label='Lớp' rules={[{ required: settings.IS_VIMARU, message: 'Chưa nhập' }]}>
                  <Select
                    // mode='multiple'
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                    showSearch
                    placeholder='Chọn lớp'
                    optionFilterProp='children'
                    loading={loadingOptionClass}
                    onChange={onChangeValueClass}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    disabled={settings.IS_VIMARU ? isDisableChangeNganh : false}
                    options={optionsClass}
                  />
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item name='name_teacher' label='Tên giảng viên phụ trách'>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item name='phone_teacher' label='Số điện thoại'>
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col lg={6} xs={24}>
                <Form.Item name='email_teacher' label='Email'>
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          )}

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', columnGap: 10 }}>
            <DynamicButton onClick={() => buttonCloseModal?.()}>Thoát</DynamicButton>
            <DynamicButton style={{ border: 'none' }} loading={loading} htmlType='submit' type='primary'>
              Lưu
            </DynamicButton>
          </div>
        </Form>
      </Spin>
    </React.Fragment>
  );
};
export default IndexFormAddUser;
