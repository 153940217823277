import moment from 'moment';

export const GenPasswordModel = (type, lengthPassword) => {
  //* Type: 1/2; Độ dài ghi gen password
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'; //* Type: 1
  var chars_2 = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'; //* Type: 2
  var chars_3 = '0123456789'; //* Type: 2
  var passwordLength = lengthPassword; //
  var GenPassword = ''; //? value default password
  if (type === 1) {
    for (var i = 1; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      GenPassword += chars.substring(randomNumber, randomNumber + 1);
    }
  } else if (type === 2) {
    for (var i = 1; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars_2.length);
      GenPassword += chars_2.substring(randomNumber, randomNumber + 1);
    }
  } else if (type === 3) {
    for (var i = 1; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars_3.length);
      GenPassword += chars_3.substring(randomNumber, randomNumber + 1);
    }
  }
  return GenPassword;
};
export const DateNowModel = () => {
  const nowDate = moment().format('YYYY/MM/DD HH:mm:ss');
  return nowDate;
};
