import { Button, Col, Form, Input, message, Modal, Popconfirm, Row, Space, Tooltip } from 'antd';
import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getData, RemoveData, UpdateData } from './InsertData';
import { useQueryClient } from 'react-query';
import settings from 'constants/settings';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const ViewEdit = ({ data, onGetDataCrud = [], dataTable }) => {
  const { t } = useTranslation('');
  const [isOpenModal, setOpenModal] = React.useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const filterDataParentByParentId = onGetDataCrud.filter((item) => {
    return item.id === data.parentId;
  });
  const dataCheck = dataTable?.flatMap((item) => {
    return item.children || [];
  });
  const onRemove = async () => {
    let response;
    if (data.parentId === null) {
      response = await RemoveData(data.key);
      data?.children?.forEach(async (item) => {
        response = await RemoveData(item.id);
      });
    } else {
      response = await RemoveData(data.id);
    }
    if (response) {
      queryClient.invalidateQueries('VIMARU_METADATA_Category');
      message.success(i18next.t('delete success', { field: i18next.t('data') }));
    }
  };
  const onUpdateData = () => {
    setOpenModal(true);
  };
  const onCancel = () => {
    setOpenModal(false);
  };
  const onUpdateInformation = async (values) => {
    const { name_khoa, name_nganh, code_khoa, code_nganh } = values;
    const parameters = {
      categoryName: data.parentId === null ? name_khoa : name_nganh,
      code: data.parentId === null ? code_khoa : code_nganh,
    };
    const responseUpdateData = await UpdateData(data.id, parameters);
    if (responseUpdateData.rowsAffected === 1) {
      queryClient.invalidateQueries('VIMARU_METADATA_Category');
      message.success(i18next.t('update success', { field: i18next.t('data') }));
    }
    setTimeout(() => {
      onCancel();
    }, 500);
  };

  React.useEffect(() => {
    form.setFieldsValue({
      name_khoa: data?.parentId !== null ? filterDataParentByParentId?.[0]?.categoryName : data?.categoryName,
      name_nganh: data?.categoryName,
      code_khoa: data?.parentId !== null ? filterDataParentByParentId?.[0]?.code : data?.code,
      code_nganh: data?.code,
    });
  }, []);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Modal
        title={`Cập nhật thông tin ${data.parentId === null ? 'Khoa' : 'Chuyên Ngành'}`}
        okText='Lưu'
        cancelText={t('Close')}
        onOk={() => {
          form.submit();
        }}
        open={isOpenModal}
        onCancel={onCancel}
      >
        <Form form={form} onFinish={onUpdateInformation} layout='vertical'>
          <Form.Item
            name='code_khoa'
            label='Mã Khoa'
            rules={[
              {
                validator: (_, value) => {
                  if (value.trim() === '')
                    return Promise.reject(i18next.t('please enter', { field: i18next.t('code') }));
                  else {
                    const checkDuplicate = dataTable?.some(
                      (item) =>
                        item?.code.trim().toLowerCase().replace(/\s+/g, ' ') ===
                          value.trim().toLowerCase().replace(/\s+/g, ' ') && item?.id !== data?.id,
                    );
                    if (checkDuplicate) return Promise.reject(i18next.t('unavailable', { field: i18next.t('Code') }));
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input disabled={data.parentId !== null} />
          </Form.Item>
          <Form.Item name='name_khoa' label='Tên Khoa'>
            <Input disabled={data.parentId !== null} />
          </Form.Item>
          {data.parentId !== null && (
            <Row gutter={[12, 12]}>
              <Col lg={12} xs={24}>
                <Form.Item
                  name='code_nganh'
                  label='Mã chuyên ngành'
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value.trim() === '')
                          return Promise.reject(i18next.t('please enter', { field: i18next.t('code') }));
                        else {
                          const checkDuplicate = dataCheck.some(
                            (item) =>
                              item?.code.trim().toLowerCase().replace(/\s+/g, ' ') ===
                                value.trim().toLowerCase().replace(/\s+/g, ' ') &&
                              item.parentId === data.parentId &&
                              item?.id !== data?.id,
                          );
                          if (checkDuplicate)
                            return Promise.reject(i18next.t('unavailable', { field: i18next.t('Code') }));
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item name='name_nganh' label='Tên Chuyên ngành'>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
      <Space>
        <Tooltip placement='top' title={'Cập nhật'}>
          <Button
            key='edit-file'
            shape='circle'
            type='dashed'
            style={{ color: '#006EE6' }}
            icon={<EditOutlined />}
            onClick={onUpdateData}
          />
        </Tooltip>
        <Popconfirm
          placement='topRight'
          title={() => {
            return (
              <div>
                <p style={{ fontSize: 14, fontWeight: 400 }}>
                  {i18next.t('Are you sure to delete', { object: t('file') })}?
                  <strong>
                    {data.parentId === null ? 'Khoa' : 'Chuyên Ngành'}: {data.categoryName}
                  </strong>
                </p>
                <strong style={{ color: '#f5222d' }}>({t('popconfirm.unrecoverable')})</strong>
              </div>
            );
          }}
          onConfirm={onRemove}
          okText={t('Confirm')}
          cancelText={t('Close')}
        >
          <Tooltip placement='top' title={'Xóa'}>
            <Button
              key='edit-file'
              shape='circle'
              type='dashed'
              danger
              style={{ color: '#f5222d' }}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Popconfirm>
      </Space>
    </div>
  );
};

export default ViewEdit;
