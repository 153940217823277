import { Col, Form, InputNumber, Modal, Row, Spin, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Recorder from 'components/Recording/react_mic_upload';
import { uuid } from 'components/functions';
import { IS_HIGHUP } from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import Essay from 'modules/Presentation/Viewer/components/ExercicesTypes_MENARD/Essay';
import StudentInfomationHeader from 'modules/Student/components/StudentInfomationHeader';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import PropTypes from 'prop-types';
import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
const fetcher = (slideId, studentId) => {
  return query('p_QUIZCITY_API_GetQuizSummary_Log', { slideId, studentId });
};
const MarkingEssay = ({ record, scoreEssay, teacherNoteEssay }) => {
  const [visible, setVisible] = React.useState(false);
  const queryClient = useQueryClient();
  const KEY = ['p_QUIZCITY_API_GetQuizSummary_Log', record?.slideId, record?.studentId];
  const { data, isLoading } = useQuery(KEY, () => fetcher(record?.slideId, record?.studentId), { enabled: visible });
  const [form] = Form.useForm();
  const refRecorder = React.createRef(null);
  const audioRef = React.useRef();
  const [resultRecord, setResultRecord] = React.useState(undefined);
  // if (!data) return null;
  
  const teacherRecordEssay = data?.[0]?.teacherRecordEssay;
  const audioUrl = data?.[0]?.jsonData?.submittedQuestions?.[0]?.userData?.answers?.audioUrl;
  const imageUrl = data?.[0]?.jsonData?.submittedQuestions?.[0]?.userData?.answers?.imageUrl;
  const textEssay = data?.[0]?.jsonData?.submittedQuestions?.[0]?.userData?.answers?.textEssay;
  const videoUrl = data?.[0]?.jsonData?.submittedQuestions?.[0]?.userData?.answers?.videoUrl;
  const onFinish = (values) => {
    const parameters = {
      studentId: record?.studentId,
      FolderId: record?.folderId,
      SlideId: record?.slideId,
      ScoreEssay: values?.scoreEssay,
      TeacherNoteEssay: values?.teacherNoteEssay,
      TeacherRecordEssay: resultRecord?.recordUrl ?? '',
    };
    query('p_QUIZCITY_Student_Examination_Results_Update_Essay', parameters)
      .then((res) => {
        queryClient.invalidateQueries('p_VIMARU_GetAnswerLogs_ByStudentId_Essay');
        message.success('Chấm điểm bài tập tự luận thành công');
        setVisible(false);
      })
      .catch((e) => message.error('Chức năng này đang phát triển'));
  };

  const renderRecorder = () => {
    const recordParams = {
      questionId: data?.id,
      questionText: data?.questionText,
      studentId: record?.studentId,
      slideId: record?.slideId,
      takeExamTime: uuid(),
    };
    const onStopRecording = (result) => {
      setResultRecord({
        recordUrl: result?.downloadUrl,
        recordData: result?.recordData,
      });
    };
    const onRecording = () => {
        setResultRecord(undefined);
    };

    return (
      <>
        <Row justify='space-around' align='top' gutter={[24, 24]}>
          <Col>
            <Recorder
              ref={refRecorder}
              __className={'question-type__recordType02'}
              __onRecording={onRecording}
              __onStopRecording={onStopRecording}
              recordParams={recordParams}
              hasRecord={true}
            >
              {/* <span style={{ fontSize: 16, fontWeight: 600 }}>{i18next.t('slide:record.Press here to record')}</span> */}
            </Recorder>
          </Col>
          <Col>
            {(resultRecord?.recordUrl || teacherRecordEssay) && (
              <audio
                key={`${resultRecord?.recordUrl}`}
                ref={audioRef}
                controls
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <source src={resultRecord?.recordUrl ?? teacherRecordEssay} type='audio/mpeg' />
              </audio>
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <React.Fragment>
      <DynamicButton type='text' onClick={() => setVisible(true)}>
        {i18next.t('Detail')}
      </DynamicButton>
      <Modal
        centered
        width='90vw'
        bodyStyle={{ height: '80vh', overflow: 'auto' }}
        open={visible}
        title={i18next.t('slide:Essay.Essay work').toUpperCase()}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[
          <DynamicButton
            key='close-modal'
            onClick={() => {
              setVisible(false);
            }}
          >
            {i18next.t('Close')}
          </DynamicButton>,
        ]}
      >
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin size='large'></Spin>
          </div>
        ) : (
          <Form
            form={form}
            initialValues={{ audioUrl, imageUrl, textEssay, videoUrl, scoreEssay, teacherNoteEssay }}
            onFinish={onFinish}
          >
            <h6 className='mb-4 heading-small text-muted'>{i18next.t('Score')}</h6>
            <Row gutter={[24, 24]}>
              <Col md={18}>
                <Form.Item
                  label={i18next.t('Score')}
                  name='scoreEssay'
                  rules={[{ required: true, message: i18next.t('please enter', { field: i18next.t('Score') }) }]}
                  hasFeedback
                  wrapperCol={{ span: 21 }}
                  labelCol={{ span: 3 }}
                >
                  <InputNumber style={{ width: '100%' }} min={1} max={10} step='0.1' />
                </Form.Item>
                {/* <span className='tw-font-medium tw-ml-2' style={{ color: colors.theme.success }}>
             {`/ ${workItem.assignmentPoints}`}
           </span> */}
                <Form.Item
                  label={i18next.t('Comment')}
                  name='teacherNoteEssay'
                  wrapperCol={{ span: 21 }}
                  labelCol={{ span: 3 }}
                >
                  <TextArea style={{ width: '100%' }} autoSize={{ minRows: 3 }} allowClear />
                </Form.Item>
                {/* <Form.Item> */}
                <Row justify='space-between' style={{ marginLeft: 100 }}>
                  <Col>{IS_HIGHUP ? renderRecorder():''}</Col>
                  <Col>
                    <DynamicButton type='primary' htmlType='submit'>
                      {i18next.t('Save')}
                    </DynamicButton>
                  </Col>
                </Row>
                {/* </Form.Item> */}
              </Col>
              <Col md={6}>
                <StudentInfomationHeader studentId={record?.studentId} />
              </Col>
            </Row>
            <hr />
            {data &&
              data?.[0]?.jsonData?.submittedQuestions?.map((item, index) => (
                <Essay
                  data={item?.question}
                  userData={item?.userData}
                  showUserData
                  isMarking
                  questionIndex={index + 1}
                  preview={true}
                />
              ))}
          </Form>
        )}
      </Modal>
    </React.Fragment>
  );
};

MarkingEssay.propTypes = {
  classId: PropTypes.string,
};

MarkingEssay.defaultProps = {
  classId: '',
};

export default MarkingEssay;
