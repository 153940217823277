import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Progress, Row, Spin } from 'antd';
import SimpleContainer from 'components/Containers/SimpleContainer';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import { AiFillCheckCircle, AiFillLock, AiFillPlayCircle, AiOutlineClose, AiOutlineLock } from 'react-icons/ai';
// import { Button } from 'reactstrap';
import Presentation from './Presentation';
import styles from './swimlane2.module.css';
import colors from 'constants/colors';
import { query } from 'helpers/QueryHelper';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { IS_MENARD } from 'constants/settings';

type Props = {
  folders: any;
};
const theme = [
  '#95DABB',
  '#1DD5EE',
  '#42F7C0',
  '#FE9666',
  '#F64F5C',
  '#01B8AA',
  '#374649',
  '#FD625E',
  '#F2C80F',
  '#5F6B6D',
  '#8AD4EB',
  '#A66999',
  '#73B761',
  '#4A588A',
  '#CD4C46',
];

const lessonColors = ['#6EC7D7', '#3DB4C9', '#0DA1BC', '#0A798D'];

const progressBarColor = (backgroundColor: string) => {
  switch (backgroundColor.toUpperCase()) {
    case '#03A9F4':
      return '#0285C2';
    case '#000000':
      return '#999999';
    case '#F44336':
      return '#C2352B';
    case '#E91E63':
      return '#B5184C';
    case '#9C27B0':
      return '#6E1B7D';
    case '#673AB7':
      return '#4B2A85';
    case '#3F51B5':
      return '#2E3A82';
    case '#2196F3':
      return '#1B75BF';
    case '#00BCD4':
      return '#008EA1';
    case '#009688':
      return '#00635A';
    case '#4CAF50':
      return '#367D38';
    case '#8BC34A':
      return '#658F36';
    case '#CDDC39':
      return '#9CA82C';
    case '#FFEB3B':
      return '#CCBC2F';
    case '#FFC107':
      return '#CC9B06';
    case '#FF9800':
      return '#CC7A00';
    case '#FF5722':
      return '#CC441B';
    case '#795548':
      return '#453129';
    case '#607D8B':
      return '#3E5059';
    case '#00BCD4':
      return '#008EA1';
    default:
      return 'gray';
  }
};
function lightenColor(color, amount = 0.3) {
  // convert the hex color to RGB
  const rgb = hexToRgb(color);
  // calculate the new RGB values
  const newRgb = {
    r: Math.min(Math.round(rgb.r + (255 - rgb.r) * amount), 255),
    g: Math.min(Math.round(rgb.g + (255 - rgb.g) * amount), 255),
    b: Math.min(Math.round(rgb.b + (255 - rgb.b) * amount), 255),
  };
  // convert the new RGB values back to hex
  return rgbToHex(newRgb.r, newRgb.g, newRgb.b);
}

function hexToRgb(hex) {
  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);
  return { r, g, b };
}

function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}
const fetchFolder = (folderId: any, studentId: any) => () => {
  if (!folderId) return;

  const parameters = {
    studentId: studentId,
    folderId: folderId,
  };
  return query('p_QUIZCITY_Get_Folders_Presentation_ByLesson', parameters);
};
export default function Swimlane2({ folders }: Props) {
  const [selectedFolderId, setSelectedFolderId] = useState<any>();

  const [widthScreen, setWidthScreen] = useState<number>(window.outerWidth);
  const [visible, setVisible] = useState<boolean>(false);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const KEY_FETCH_FOLDER = ['p_QUIZCITY_Get_Folders_Presentation_ByLesson', selectedFolderId, loggedInUser?.id];
  const { data, isLoading } = useQuery(KEY_FETCH_FOLDER, fetchFolder(selectedFolderId, loggedInUser?.id), {
    enabled: !!selectedFolderId,
  });

  useEffect(() => {
    const handleResize = (e) => {
      // Xử lý sự kiện thay đổi kích thước màn hình ở đây
      // console.log('Kích thước màn hình đã thay đổi', e.target.outerWidth);
      setWidthScreen(e.target.outerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Dependency array rỗng để đảm bảo hiệu chỉnh chỉ xảy ra khi component được gắn kết

  const handleFolderTitle = (title: string) => {
    const splittedTitle = title.split('(');
    const folderTitle = splittedTitle[0];
    const deadline = splittedTitle[1];
    return (
      <div>
        <div>{folderTitle}</div>
        <div>{deadline && `(${deadline}`}</div>
      </div>
    );
  };
  const renderBody = () => {
    return data?.[0]?.lessons
      ?.sort((a, b) => a.sortOrder - b.sortOrder)
      ?.map((lesson, index: number) => {
        const { id: lessonId } = lesson;
        return (
          <Row
            className={styles.col_folder_lesson}
            key={lessonId}
            style={{
              backgroundColor: `${index % 2 === 1 ? '' : '#f2f1ed '}`,
              width: '100%',
            }}
            wrap={widthScreen < 576}
            gutter={[5, 5]}
          >
            <Col style={{ width: widthScreen > 576 ? '150px' : '100%' }}>
              <div
                className={styles.col_lesson_content}
                style={{
                  backgroundColor: lesson.color ?? lessonColors[index],
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {lesson.title.toUpperCase()}
              </div>
            </Col>
            {lesson.presentations
              ?.sort((a, b) => a.sortOrder - b.sortOrder)
              ?.map((presentation) => {
                const { id: presentationId } = presentation;
                return (
                  <Col key={presentationId} style={{ width: widthScreen > 576 ? '270px' : '100%' }}>
                    <Presentation presentation={presentation} color={lesson?.color ?? lessonColors[index]} />
                  </Col>
                );
              })}
          </Row>
        );
      });
  };

  const handleClick = (folderId: any) => {
    setVisible(true);
    setSelectedFolderId(folderId);
  };

  const renderWeekCard = (folder: any) => {
    const folderColor = folder?.color ?? '#81dff0';
    return (
      <div
        className={styles.arrow_tag}
        style={{
          height: IS_MENARD ? '100%' : 63,
          minHeight: 63,
          width: widthScreen > 576 ? 350 : '100%',
          backgroundColor: !IS_MENARD ? folderColor : undefined,
          backgroundImage: IS_MENARD
            ? `linear-gradient(to right, ${folderColor}, ${lightenColor(folderColor, 0.3)})`
            : '',
          borderRadius: IS_MENARD ? 15 : 0,
          borderRight: '1px solid white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: widthScreen > 576 ? 'default' : 'pointer',
        }}
        onClick={() => {
          if (widthScreen <= 576) {
            handleClick(folder?.id);
          }
        }}
      >
        {handleFolderTitle(folder.title)}
      </div>
    );
  };
  const renderWeeks = () => {
    const weeks = folders.map((folder: any, folderIndex: number) => {
      return (
        <Row
          key={folder?.id}
          className={styles.row_week}
          wrap={false}
        >
          {widthScreen > 576 ? (
            <>
              <Col>{renderWeekCard(folder)}</Col>
              <Col style={{ display: 'flex' }}>{renderArrowLessons(folder?.lessons)}</Col>
            </>
          ) : (
            <Col span={24}>{renderWeekCard(folder)}</Col>
          )}
        </Row>
      );
    });
    return weeks;
  };

  const renderArrowLessons = (lessons: Array<any>) => {
    const arrowLessons = lessons?.map((lesson, index) => {
      const lessonColor = lesson?.color ?? lessonColors[index];
      return (
        <div
          key={lesson?.id}
          onClick={() => {
            handleClick(lesson?.id);
          }}
          style={{ position: 'relative' }}
        >
          <div
            className={styles.arrow_tag}
            style={{
              height: IS_MENARD ? '100%' : 63,
              width: widthScreen > 576 ? 350 : '100%',
              zIndex: 100 - index * 2,
              backgroundColor: !IS_MENARD && lessonColor,
              backgroundImage: IS_MENARD
                ? `linear-gradient(to right, ${lessonColor}, ${lightenColor(lessonColor, 0.3)})`
                : '',
              borderRadius: IS_MENARD ? 15 : 0,
              borderRight: IS_MENARD ? '1px solid white' : '',
            }}
          >
            <div style={{ display: 'flex', padding: '5px 10px 0 30px' }}>
              <div style={{ flex: 5, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {lesson.title}
              </div>
              <div style={{ flex: 1 }}>
                {' '}
                {lesson.status === 'Completed' && (
                  <AiFillCheckCircle
                    style={{ backgroundColor: colors.green, borderRadius: '50%' }}
                    color='white'
                    size={20}
                    className='tw-ml-2'
                  />
                )}
                {lesson.status === 'Locked' && (
                  <AiFillLock
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      border: '1px solid',
                      borderColor: colors.dimGray,
                      padding: 1,
                    }}
                    color={colors.dimGray}
                    size={20}
                    className='tw-ml-2'
                  />
                )}
                {lesson.status === 'Playing' && (
                  <AiFillPlayCircle
                    style={{ backgroundColor: colors.gold, borderRadius: '50%' }}
                    color='white'
                    size={20}
                    className='tw-ml-2'
                  />
                )}
              </div>
            </div>
            <div style={{ display: 'flex', paddingInline: '30px 10px', fontSize: 16 }}>
              <div style={{ flex: 5 }}>
                <Progress
                  trailColor={'white'}
                  strokeColor={progressBarColor(lesson?.color ?? lessonColors[index])}
                  percent={lesson.percentCompleted * 100}
                  showInfo={false}
                />
              </div>
              <div style={{ textAlign: 'center', flex: 1 }}>
                <span className='tw-ml-2'>{numeral(lesson.percentCompleted).format('%')}</span>
              </div>
            </div>
          </div>
          {!IS_MENARD && (
            <div
              className={styles.arrow_part}
              style={{ zIndex: 99 - index * 2, backgroundColor: lesson?.color ?? lessonColors[index] }}
            ></div>
          )}
        </div>
      );
    });
    return arrowLessons;
  };

  return (
    <>
      <div
        style={{
          overflowX: 'auto',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        {renderWeeks()}
      </div>
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
        }}
        width='100vw'
        footer={null}
        closable={false}
        bodyStyle={{ padding: 0 }}
        centered
      >
        <Card
          title={data?.[0]?.title}
          style={{ padding: 0, minHeight: '90vh' }}
          extra={
            <Button
              type='primary'
              icon={<CloseOutlined />}
              danger
              onClick={() => {
                setVisible(false);
              }}
            />
          }
        >
          {isLoading ? (
            <div
              style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Spin size='large' spinning={isLoading} />
            </div>
          ) : (
            <div className={styles.row_lesson}>{renderBody()}</div>
          )}
        </Card>
      </Modal>
    </>
  );
}
