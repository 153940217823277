import { Button } from 'antd';
import { SwitchViewModeButton } from './SwitchModeButton.tsx';

const Buttons = ({
  allFolders,
  setSelectedFolder,
  selectedFolder,
  setSelectedFolderId,
  selectedFolderId,
  viewMode,
  setViewMode,
}) => {
  return (
    <>
      {selectedFolderId && viewMode === 'card' ? (
        <div className='tw-flex tw-justify-between tw-mb-5'>
          <Button
            type='primary'
            onClick={() => {
              const parentFolder = allFolders.find((i) => i.id === selectedFolder?.parentId);
              setSelectedFolder(parentFolder);
              setSelectedFolderId(selectedFolder?.parentId);
            }}
          >
            <span className='tw-mr-1'>
              <i className='fas fa-chevron-left' />
            </span>
            <span>Quay lại</span>
          </Button>
          <SwitchViewModeButton viewMode={viewMode} setViewMode={setViewMode} />
        </div>
      ) : (
        <div className='tw-flex tw-justify-end tw-mb-5'>
          <SwitchViewModeButton viewMode={viewMode} setViewMode={setViewMode} />
        </div>
      )}
    </>
  );
};

export default Buttons;
