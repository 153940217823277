import ONECHOICE from './OneChoice';
import UNSCRAMBLE from './Unscramble';
import MATCHING from './Matching';
import FILLINTHEBLANK from './FillInTheBlank';
import MATCHINGTWOCOLUMN from './MatchingTwoColumn';
import MULTIPLECHOOSE from './MultipleChoice';
import LISTENANDRECORD from './RecordExcercises/ListenAndRecord';
import LISTENANDSPEAK from './RecordExcercises/ListenAndSpeak';
import COMBINE from './Combine/Combine';
import FILLCOLOR from './FillColor';
import LINETO from '../ExercicesTypes/LineTo';
import ESSAY from '../ExercicesTypes_MENARD/Essay';
export default {
  MATCHING,
  ONECHOICE,
  MATCHINGTWOCOLUMN,
  FILLINTHEBLANK,
  MULTIPLECHOOSE,
  LISTENANDRECORD,
  LISTENANDSPEAK,
  UNSCRAMBLE,
  ESSAY,
  FILLCOLOR,
  LINETO,
  COMBINE,
};
