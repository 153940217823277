import { Button, Col, Form, Input, Row, TreeSelect } from 'antd';
import { IS_BASAO, IS_LG, IS_LX, IS_MENARD, IS_TRONGDONG } from 'constants/settings';
import i18next from 'i18next';
import { filterTreeSelectShops } from 'pages/Administration/Menard/ManagerShopsV2/tableColumn';
import React from 'react';

type Props = {
  setParameters: React.Dispatch<React.SetStateAction<any>>;
  shopOptions?: Array<any>;
  widthOptionsShop?: number;
};
type FormValues = {
  email: string;
  name: string;
  phone: string;
  shop?: string;
  shopId?: string;
};
export default function UserSearchForm({ setParameters, shopOptions, widthOptionsShop }: Props) {
  const onFinish = (values: FormValues) => {
    setParameters((prev: any) => ({ ...prev, ...values }));
  };
  return (
    <Form layout='vertical' onFinish={onFinish}>
      <Row gutter={[16, 16]} align='bottom'>
        <Col>
          <Form.Item name='maSV' label={i18next.t('table:managerCoachingSchedule.Employee code')}>
            <Input style={{ width: 100 }} allowClear />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name='name' label={i18next.t('table:classInformation.Full name')}>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name='email' label={i18next.t('table:classInformation.Email')}>
            <Input allowClear />
          </Form.Item>
        </Col>
        {!IS_LG && (
          <Col>
            <Form.Item name='phone' label={i18next.t('table:classInformation.Phone number')}>
              <Input style={{ width: 120 }} allowClear />
            </Form.Item>
          </Col>
        )}

        {(IS_MENARD || IS_LG || IS_BASAO || IS_TRONGDONG || IS_LX) && (
          <Col>
            <Form.Item name='shopId' label={i18next.t('Part')}>
              <TreeSelect
                showSearch
                treeData={shopOptions}
                dropdownStyle={{ maxHeight: 800, overflow: 'auto' }}
                // treeDefaultExpandAll
                style={{ minWidth: 120, width: widthOptionsShop }}
                filterTreeNode={filterTreeSelectShops}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                allowClear
                placeholder={i18next.t('Part')}
              />
            </Form.Item>
          </Col>
        )}
        <Col>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              {i18next.t('search')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
