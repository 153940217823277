// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// VIMARU
const firebaseConfig = {
  apiKey: 'AIzaSyDQs0IoaDXGFqg_zzknr3kUtpqZGhhRJ78',
  authDomain: 'quiz-city-project.firebaseapp.com',
  databaseURL: 'https://quiz-city-project-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'quiz-city-project',
  storageBucket: 'quiz-city-project.appspot.com',
  messagingSenderId: '970836020263',
  appId: '1:970836020263:web:9cd93eece2d87297a81202',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
