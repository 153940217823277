import ConfigSettings from 'pages/Administration/ConfigSettings';
import getSettings from 'pages/Administration/api/getSettings';
import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/types';

function* FETCH_SETTINGS() {
  try {
    const settings = yield getSettings();
    if (settings?.length) {
      yield put({
        type: actionTypes.FETCH_SETTINGS_SUCCESS,
        payload: settings,
      });
    } else {
      yield put({
        type: actionTypes.FETCH_SETTINGS_FAILED,
        payload: null,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_SETTINGS_FAILED,
      payload: error,
    });
  }
}
export default function* settingsSaga() {
  yield takeLatest(actionTypes.FETCH_SETTINGS, FETCH_SETTINGS);
}
