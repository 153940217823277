import React from 'react';
import './styles.css';
import CountUp from 'react-countup';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';

//sử dụng template ở file src/assets/css/argon-dashboard-pro-react.css

export default function CartReportHeader({
  title = 'Chưa truyền title',
  value = { value: 0, color: '#F54A49' },
  icon = { name: 'ni ni-active-40', color: 'bg-gradient-red' },
}) {
  return (
    <>
      <Card className='card-stats'>
        <CardBody className='pb-2'>
          <Row style={{ height: '100%' }}>
            <Col>
              <div
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
              >
                <CardTitle tag='h5' className='text-uppercase text-muted mb-0 dashboard-child-card-title'>
                  {title}
                </CardTitle>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <CountUp
                    useEasing={true}
                    className='dashboard-countup'
                    // decimals={2}
                    style={{ color: value.color }}
                    start={0}
                    duration={5}
                    end={value.value}
                  ></CountUp>
                </div>
              </div>
            </Col>
            <Col className='col-auto'>
              <div className={`icon icon-shape ${icon.color} text-white rounded-circle shadow`}>
                <i className={icon.name} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
