/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable sort-keys */
import { Button, Col, Form, Input, message, Row, Space, Spin } from 'antd';
import { uuid } from 'components/functions';
import { query } from 'helpers/QueryHelper';
import moment from 'moment';
import React from 'react';
import { useQueryClient } from 'react-query';
import { insertProc, keyFetch, updateProc } from './config';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import i18next from 'i18next';

const ContentModal = ({ optionContent, allValue, dataAllType, onCancel }) => {
  const [form] = Form.useForm();
  const [isLoadingPage, setLoadingPage] = React.useState(false);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    form.resetFields();
    if (optionContent === 'update') {
      const filterDataParent = dataAllType?.filter((item) => {
        return item?.id?.toUpperCase() === allValue?.dataDefault?.parentId?.toUpperCase();
      });
      form.setFieldsValue({
        type: filterDataParent?.[0]?.questionTypeName,
        questionTypeName: allValue?.dataDefault?.questionTypeName,
        questionTypeCode: allValue?.dataDefault?.questionTypeCode,
        subTypeName: allValue?.dataDefault?.subTypeName,
        componentType: allValue?.dataDefault?.componentType,
      });
    } else if (optionContent === 'created') {
      form.setFieldsValue({
        type: allValue?.dataDefault?.questionTypeName,
      });
    }
  }, [optionContent, allValue]);

  const onFinish = async (values) => {
    const dateNow = moment().format('MM-DD-YYYY hh:mm:ss');

    setLoadingPage(true);
    if (optionContent === 'created') {
      let parameters = {};
      const { questionTypeName, questionTypeCode, subTypeName, componentType } = values;
      if (!allValue) {
        parameters = {
          QuestionTypeCode: questionTypeCode,
          QuestionTypeName: questionTypeName,
          Notes: 'type',
          CreatedDate: dateNow,
          ModifiedDate: dateNow,
          SubTypeName: subTypeName || null,
          ComponentType: componentType || null,
          Id: uuid(),
        };
      } else {
        parameters = {
          parentId: allValue?.dataDefault?.id,
          questionTypeCode: questionTypeCode || null,
          questionTypeName: questionTypeName || null,
          notes: 'optionType',
          createdDate: dateNow,
          ModifiedDate: dateNow,
          SubTypeName: subTypeName || null,
          ComponentType: componentType || null,
          Id: uuid(),
        };
      }
      query(insertProc, parameters).then((response) => {
        if (response) {
          message.success('Thêm dữ liệu thành công');
        } else {
          message.success('Thêm dữ liệu thất bại');
        }
        queryClient.invalidateQueries(keyFetch);
        onCancel();
      });
      setLoadingPage(false);
    } else if (optionContent === 'update') {
      const { type, ...newValues } = values;
      let parameters = {
        ...newValues,
        id: allValue?.dataDefault?.id,
        Notes: allValue?.dataDefault?.parentId === null ? 'type' : 'optionType',
        CreatedDate: allValue?.dataDefault?.createdDate,
        ModifiedDate: dateNow,
      };

      if (allValue?.dataDefault?.parentId !== null) {
        parameters = {
          ...parameters,
          ParentId: allValue?.dataDefault?.parentId,
        };
      }
      query(updateProc, parameters).then(() => {
        queryClient.invalidateQueries(keyFetch);
        message.success(i18next.t('update success', { field: i18next.t('data') }));
        onCancel();
      });
      setLoadingPage(false);
    }
    form.resetFields();
  };
  return (
    <Spin spinning={isLoadingPage}>
      <Form form={form} onFinish={onFinish} layout='vertical'>
        {allValue ? (
          <Form.Item name='type' label='Type:'>
            <Input disabled />
          </Form.Item>
        ) : null}
        <Row gutter={[12, 12]}>
          <Col lg={12} sx={24}>
            <Form.Item name='questionTypeName' label='Name:'>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} sx={24}>
            <Form.Item name='questionTypeCode' label='Code:'>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ width: '100%' }} name='subTypeName' label='Sub Title:'>
          <Input />
        </Form.Item>

        <Form.Item style={{ width: '100%' }} name='componentType' label='Component'>
          <Input />
        </Form.Item>

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Form.Item>
            <Space>
              <DynamicButton onClick={() => onCancel()}>Đóng</DynamicButton>
              <DynamicButton type='primary' htmlType='submit'>
                Lưu
              </DynamicButton>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </Spin>
  );
};

export default ContentModal;
