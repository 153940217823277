import React, { useState, useEffect } from 'react';

const Slide = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [images.length, isHovered]);

  return (
    <div
      className='slide-container'
      style={{ display: 'flex', justifyContent: 'center', height: 400, maxWidth: '100%' }}
    >
      <img
        src={images[currentImageIndex]}
        alt='Slide'
        width={'100%'}
        height={'100%'}
        onMouseOver={() => {
          setIsHovered(true);
        }}
        onMouseOut={() => {
          setIsHovered(false);
        }}
      />
    </div>
  );
};

export default Slide;
