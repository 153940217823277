/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import QuestionHeader from '../QuestionHeader';

import './styles.css';

const Matching = ({ ExplainText, data, userData, questionIndex = 0 }) => {
  const [shuffedItems, setShuffedItems] = React.useState([]);

  const ref = React.useRef();
  ref.current = new Audio();

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    height: 60,
    backgroundColor: isDragging ? '#ced4da' : '#f4f5f7',
    borderRadius: '0.25rem',
    border: '1px dashed #adb5bd',
    marginBottom: 20,
    paddingLeft: 12,
    paddingRight: 12,
    fontWeight: 600,
    color: '#172b4d',
    ...draggableStyle,
  });

  const getListStyle = () => ({
    overflow: 'auto',
  });

  React.useEffect(() => {
    if (userData) {
      setShuffedItems(userData.answers);
    } else {
      const items = _.shuffle(data.answers).map((item, index) => {
        return {
          id: `${index + 1}`,
          content: item.answer,
        };
      });
      setShuffedItems(items);
    }
  }, [data.answers, userData]);

  // TRả về ảnh
  const renderImageItems = () => {
    return data.answers.map((item, index) => {
      if (item.imageUrl === null || item.imageUrl === undefined || item.imageUrl === '') {
        return null;
      }
      return (
        <div key={`image-${index}`} style={{ width: 100 }}>
          <img alt='...' src={item.imageUrl} style={{ height: 60, borderRadius: 10, marginBottom: 24, display: 'block' }} />
        </div>
      );
    });
  };
  // TRả về file nhạc
  const renderAudioItems = () => {
    return data.answers.map((item, index) => {
      if (item.audioUrl === null || item.audioUrl === undefined || item.audioUrl === '') {
        return null;
      }
      return (
        <div
          key={`item-${index}`}
          style={{
            userSelect: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 60,
            width: 60,
            marginBottom: 20,
            background: '#f4f5f7',
            color: '#172b4d',
            borderRadius: '0.25rem',
            border: '1px solid #adb5bd',
            cursor: 'pointer',
          }}
          onClick={() => {
            // console.log('userData[index]: ', userData);
            // let id = userData.length !== 0 ? userData[index].id : index
            const selected = data.answers[index];

            // play audio
            ref.current.pause();
            ref.current.currentTime = 0;
            ref.current.src = selected.audioUrl;
            ref.current.play();
          }}
        >
          <i className='fas fa-volume-up' style={{ fontSize: '20px', cursor: 'pointer' }} />
        </div>
      );
    });
  };

  const renderContent = (items) => {
    return (
      <div className='d-flex'>
        <div style={{ marginRight: 20 }}>
          {renderImageItems()}
          {renderAudioItems()}
        </div>
        <div style={{ maxWidth: '100%' }}>
          <DragDropContext onDragEnd={() => null}>
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(p, s) => (
                        <div ref={p.innerRef} {...p.draggableProps} {...p.dragHandleProps} style={getItemStyle(s.isDragging, p.draggableProps.style)}>
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  };

  return (
    <Card className="m-0">
      <CardHeader>
        <QuestionHeader title={data.title} subTitle={data.subTitle} questionIndex={questionIndex} tooltip='Matching' />
      </CardHeader>
      <CardBody>
        {renderContent(shuffedItems)}
      </CardBody>
      {ExplainText}
      <CardFooter>
        <h3>Đáp án đúng</h3>
        {renderContent(data.answers.map((x) => { return { ...x, id: typeof x.id === 'number' ? x.id.toString() : x.id, content: x.answer }; }))}
      </CardFooter>
    </Card>
  );
};
export default Matching;
