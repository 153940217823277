import React, { useEffect, useRef, useState } from 'react';
import { uuid } from 'components/functions';
import { StyleQuiz } from 'modules/Presentation/Viewer/ViewerTypes/Quiz/SlideQuiz/styleQuizComponents';
import SlideQuiz from 'modules/Presentation/Viewer/ViewerTypes/Quiz/SlideQuiz';
import { Col, Row } from 'antd';
import _ from 'lodash';

type RenderSlideQuizProps = {
  currentSlide: any;
  studentId: number;
  onFinishCountDown?: Function;
  type?: string;
  disableButtonNextSlide: boolean;
  setDisableButtonNextSlide: Function;
  refExercise?: any;
  currentQuizIndex: number;
  setCurrentQuizIndex: Function;
  refQuizQuestion: Function;
  setArraySubmitQuestionQuiz: Function;
  isDisableContinue: boolean;
  setIsDisableContinue: Function;
  totalScore: number;
  percentPass: number;
  setTotalScore: Function;
  setCountQuizCorrectAnswers: Function;
  isExample: boolean;
  setNumberQuestions: Function;
  codeExam: string;
  folderId: string;
  resultQuiz: any;
  setAudio: Function;
};

function RenderSlideQuiz({
  currentSlide,
  studentId,
  onFinishCountDown,
  type,
  disableButtonNextSlide,
  setDisableButtonNextSlide,
  currentQuizIndex,
  setCurrentQuizIndex,
  refQuizQuestion,
  setArraySubmitQuestionQuiz,
  isDisableContinue,
  setIsDisableContinue,
  totalScore,
  percentPass,
  setTotalScore,
  setCountQuizCorrectAnswers,
  isExample,
  setNumberQuestions,
  codeExam,
  folderId,
  resultQuiz,
  setAudio,
}: RenderSlideQuizProps) {
  const [takeExamTime, setTakeExamTime] = React.useState<string>(uuid());

  const jsonDataQuiz = JSON.parse(currentSlide?.jsonData);
  const examination = jsonDataQuiz?.[0]?.questions?.[0]?.quizData;
  const questionType = examination?.questions?.[0]?.type;
  const dataQuiz = examination?.questions;
  const passScoreQuiz = currentSlide?.percentPass;
  const total = dataQuiz?.length;
  const scoreWeight = Number(currentSlide?.scoreWeight);
  const isEssay = currentSlide?.isEssay || false;

  useEffect(() => {
    setNumberQuestions(total);
  }, [currentSlide]);

  return (
    <React.Fragment>
      <StyleQuiz>
        <Row gutter={16} className='tw-h-full'>
          <Col span={24} className='tw-h-full'>
            <SlideQuiz
              data={dataQuiz} // array các câu hỏi
              currentQuizIndex={currentQuizIndex} // index của câu hỏi trong 1 slide quiz
              setCurrentQuizIndex={setCurrentQuizIndex}
              currentSlide={jsonDataQuiz}
              refQuizQuestion={refQuizQuestion}
              loggedInUser={studentId}
              setCountQuizCorrectAnswers={setCountQuizCorrectAnswers}
              setTotalScore={setTotalScore}
              setIsDisableContinue={setIsDisableContinue}
              setArraySubmitQuestion={setArraySubmitQuestionQuiz}
              isDisableContinue={isDisableContinue}
              totalScore={totalScore}
              percentPass={percentPass}
              onFinishCountDown={onFinishCountDown}
              setDisableButtonNextSlide={setDisableButtonNextSlide}
              isDisableButtonNextSlide={disableButtonNextSlide}
              isExample={isExample}
              takeExamTime={takeExamTime}
              type={type}
              codeExam={codeExam}
              folderId={folderId}
              slideId={currentSlide?.id}
              scoreWeight={scoreWeight}
              resultQuiz={resultQuiz}
              isEssay={isEssay}
              setAudio={setAudio}
            />
          </Col>
        </Row>
      </StyleQuiz>
    </React.Fragment>
  );
}

export default RenderSlideQuiz;
