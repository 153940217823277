/* eslint-disable import/prefer-default-export */
import { add, query } from 'helpers/QueryHelper';
import _ from 'lodash';
import moment from 'moment';
import { SumStringToArray } from '../Result';
export const SaveLogForTest = async (file, score, data, PTCode, studentData, assignmentId, slideId, folderId, scoreWeight) => {
  const typesResult = {
    writeResult: '',
    oneChoiceResult: '',
    multipleChoiceResult: '',
    matchResult: '',
    multipleMatchResult: '',
    selectWordResult: '',
    dropDownResult: '',
    dragdropResult: '',
    speechRecognitionResult: '',
    record: null,
  };

  const { result, resultListen } = data;
  const isEmpty = JSON.stringify(result) === '{}';

  for (const temp in result) {
    typesResult[temp] = result[temp].resultString;
  }

  const resultData = isEmpty ? resultListen : result;
  //
  const resultArray = SumStringToArray(resultData, 'resultString');
  const [completed, totalCompleted] = SumStringToArray(resultData, 'complete');
  const newScore = isEmpty ? (completed * 10) / totalCompleted : score;
  const totalCompletedAnswer = (completed * 100) / totalCompleted;
  // nếu có điểm trọng số scoreWeight thì tính theo công thức mới (tỷ lệ hoàn thành * scoreWeight), không thì dùng điểm theo công thức cũ tính theo thang điểm 10
  const endScore = scoreWeight ? (parseFloat(newScore) * scoreWeight / 10) : parseFloat(newScore);

  let dateNow = moment().format('MM-DD-YYYY hh:mm:ss');

  return query(
    'p_QUIZCITY_PTTest_Worksheet_Answer_Insert',
    {
      ...studentData,
      PTCodeId: PTCode,
      AttachmentId: file?.id ?? file?.attachmentId ?? file?.workSheetId,
      AssignmentId: assignmentId,
      SlideId: slideId,
      JsonData: JSON.stringify(data),
      Type: 'QUIZCITY-WORKSHEET',
      Score: endScore || 0,
      // Score: Math.round(newScore) || 0,
      CreatedDate: dateNow,
      TeacherNoteS: '',
      Notes: '',
      Star: Number((newScore * 10).toFixed(1)) || 0,
      // completedPercent: Math.round((completed / totalCompleted[1]) * 100),
      completedPercent: totalCompletedAnswer || 0,
      TotalQuestion: totalCompleted,
      TotalAnswer: completed,
      PassedQuestion: parseInt(resultArray[0].toFixed()),
      folderId: folderId,
      ...typesResult,
    },
    // 'CLASSWORK',
  );
};
