import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Space, Tag, message } from 'antd';
import colors from 'constants/colors';
import moment from 'moment';
import { FaRegClock } from 'react-icons/fa';

export const formatDateTimeQuery = 'YYYY-MM-DD HH:mm:ss';
export const formatDateQuery = 'YYYY-MM-DD';
export const formatDate = 'DD/MM/yyyy';
export const formatDateTime = 'DD/MM/yyyy h:mm A';

// màu của thẻ tag
export const tagColors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
];

//Value của trạng thái booking
// Trạng thái "Done" đang cập nhật bằng proc p_QUIZCITY_CoachingBooking_Request_Update_Finished bên server
export const statusValues = {
  pending: 'Pending',
  accept: 'Accept',
  completed: 'Done',
  cancel: 'Cancel',
};

// Trạng thái của booking
export const statusRequest = [
  {
    status: statusValues.pending,
    color: 'default',
    icon: <ExclamationCircleOutlined />,
    tag: (
      <b>
        <Tag color={'default'} icon={<ExclamationCircleOutlined />}>
          {statusValues.pending}
        </Tag>
      </b>
    ),
  },
  {
    status: statusValues.accept,
    color: 'gold',
    icon: <FaRegClock />,
    tag: (
      <b>
        <Tag color={'gold'} icon={<FaRegClock style={{ marginRight: 7, marginBottom: -2 }} />}>
          {statusValues.accept}
        </Tag>{' '}
      </b>
    ),
  },
  {
    status: statusValues.completed,
    color: 'success',
    icon: <CheckCircleOutlined />,
    tag: (
      <b>
        <Tag color={'success'} icon={<CheckCircleOutlined />}>
          {statusValues.completed}
        </Tag>
      </b>
    ),
  },
  {
    status: statusValues.cancel,
    color: 'error',
    icon: <CloseCircleOutlined />,
    tag: (
      <b>
        <Tag color={'error'} icon={<CloseCircleOutlined />}>
          {statusValues.cancel}
        </Tag>
      </b>
    ),
  },
];
// tag hiển thị ngày được chọn để meeting => Accept
export const CoachingDateTag = ({ value }) =>
  value ? <Tag color={'gold'}>{moment(value).format(formatDateTime)}</Tag> : '';
// tag hiển thị ngày được chọn để meeting => Accept
export const FinishedCoachingDateTag = ({ value }) =>
  value ? <Tag color={'success'}>{moment(value).format(formatDate)}</Tag> : '';

// opton chọn status đưa vào Select
export const optionsStatus = statusRequest.map((status) => ({ label: status.tag, value: status.status }));

export const RenderBookingDateTags = ({ bookingDates }) => {
  return (
    <Space size={[0, 8]} wrap>
      {bookingDates.map((option, index) => (
        <Tag color={tagColors[index % tagColors.length]}>
          <div>{`${moment(option?.dateRequest).format(formatDate)}: ${option?.timeRequest}`}</div>
          <div>{option?.notes}</div>
        </Tag>
      ))}
    </Space>
  );
};

export const courseColumns = {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'Employee code',
      key: 'studentCode',
      // fixed: 'left',
      width: '1%',
      render: 'renderNoWrap',
      style: {
        minWidth: 80,
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
      sort: false,
    },
    {
      title: 'Fullname',
      key: 'studentName',
      // fixed: 'left',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        minWidth: 100,
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: 'Create date',
      key: 'createdDate',
      // fixed: 'left',
      width: '10%',
      render: 'renderDateTime',
      style: {
        minWidth: 100,
        fontWeight: 600,
      },
    },
    {
      title: 'Booking',
      key: 'booking',
      width: '30%',
      sort: false,
      render: (value, record) => <RenderBookingDateTags bookingDates={value} />,
    },
    {
      title: 'Status',
      key: 'status',
      width: '1%',
      render: (status, record) => {
        const statusDisplay = statusRequest.find((item) => item.status.toUpperCase() === status.toUpperCase());
        return statusDisplay.tag;
      },
    },
    {
      title: 'Booking results',
      key: 'bookingResults',
      width: '15%',
      sort: false,
      render: (status, record) => {
        const copyText = (text) => {
          navigator.clipboard.writeText(text).then(() => {
            message.success('Copied link!');
          });
        };
        let displayInfor = <></>;
        if (record?.status?.toUpperCase() === statusValues.pending?.toUpperCase()) {
          displayInfor = <></>;
        } else if (
          record?.status?.toUpperCase() === statusValues.accept?.toUpperCase() ||
          record?.status?.toUpperCase() === statusValues.completed?.toUpperCase()
        ) {
          displayInfor = (
            <>
              <div>
                <span style={{ fontWeight: 500 }}>Coaching date:</span>{' '}
                <CoachingDateTag value={record?.commitedDateTeacher} />{' '}
              </div>
              <div>
                <span style={{ fontWeight: 500 }}>Teacher:</span> {record?.teacherName}
              </div>
              <div>
                <span style={{ fontWeight: 500 }}>Link meeting:</span>{' '}
                <a onClick={() => copyText(record?.linkMeeting)} style={{ textDecoration: 'underline' }}>
                  {record?.linkMeeting?.length > 25 ? record?.linkMeeting.slice(0, 25) + '...' : record?.linkMeeting}
                </a>
              </div>
              <div>
                <span style={{ fontWeight: 500 }}>Notes:</span> {record?.notes}
              </div>
            </>
          );
        } else if (record?.status?.toUpperCase() === statusValues.cancel?.toUpperCase()) {
          displayInfor = (
            <>
              <div>
                <span style={{ fontWeight: 500 }}>Reason cancel:</span> {record?.reasonCancel}
              </div>
            </>
          );
        }
        return displayInfor;
      },
    },
    {
      title: 'Coaching comment',
      key: 'coachingComment',
      width: '35%',
      sort: false,
      render: (_, record) => {
        const content =
          record.status === statusValues.completed ? (
            <>
              <div>
                <span style={{ fontWeight: 500 }}>Finish date:</span>{' '}
                <FinishedCoachingDateTag value={record?.finishedDate} />{' '}
              </div>
              <div>
                <span style={{ fontWeight: 500 }}>Coaching comment:</span>{' '}
                {record?.finishedCommentTeacher.length > 80
                  ? `${record?.finishedCommentTeacher.substr(0, 79)} ...`
                  : record?.finishedCommentTeacher}
              </div>
            </>
          ) : (
            <div style={{ minWidth: 100 }}>{''}</div>
          );
        return content;
      },
    },
    {
      title: 'Modified by',
      key: 'modifiedByName',
      width: '1%',
      sort: false,
      render: (value, record) => {
        return <div style={{ minWidth: 100 }}>{value}</div>;
      },
    },
  ],
};
