import settings from 'constants/settings';
import i18next from 'i18next';
import { removeAccents } from 'services/Text/formatText';

export default {
  layout: {
    type: 'inline',
  },
  tailLayout: {},
  requiredMark: false,
  submitButtonText: i18next.t('search'),
  appName: settings.APPLICATION_NAME,
  formFields: [
    {
      label: i18next.t('Role'),
      name: 'roleId',
      initialValue: 1,
      hasFeedback: false,
      inputComponent: {
        type: 'Select',
        props: {
          showSearch: true,
          allowClear: true,
          optionFilterProp: 'children',
          filterOption: (input, option) => removeAccents(option?.label)?.includes(removeAccents(input)),
          style: { minWidth: 120 },
          options: [
            // { label: 'Tất cả', value: '' },
            { label: settings.studentPronouns2, value: 1 },
            { label: i18next.t('Teacher'), value: 2 },
            { label: i18next.t('Admin'), value: 3 },
          ],
        },
      },
    },
    {
      label: i18next.t('userCode'),
      name: 'maSV',
      initialValue: '',
      hasFeedback: false,
      inputComponent: {
        type: 'Input',
        props: {
          style: {},
        },
      },
    },
    {
      label: i18next.t('fullName'),
      name: 'name',
      initialValue: '',
      hasFeedback: false,
      inputComponent: {
        type: 'Input',
        props: {
          style: {},
        },
      },
    },
    {
      label: 'Email',
      name: 'email',
      initialValue: '',
      hasFeedback: false,
      inputComponent: {
        type: 'Input',
        props: {
          style: {},
        },
      },
    },
    {
      label: i18next.t('phoneNumber'),
      name: 'phone',
      initialValue: '',
      hasFeedback: false,
      inputComponent: {
        type: 'Input',
        props: {
          style: {},
        },
      },
    },

    {
      label: i18next.t('Status'),
      name: 'status',
      initialValue: 'ACTIVE',
      inputComponent: {
        type: 'Select',
        props: {
          showSearch: true,
          allowClear: true,
          optionFilterProp: 'children',
          filterOption: (input, option) => removeAccents(option?.label)?.indexOf(removeAccents(input)) >= 0,
          style: { minWidth: 120 },
          options: [
            { label: i18next.t('All'), value: '' },
            { label: 'Active', value: 'ACTIVE' },
            { label: 'Deactive', value: 'DEACTIVE' },
          ],
        },
      },
    },
  ],
};
