import { get } from 'helpers/QueryHelper';

const getSettings = async () => {
  const entityName = 'Module_Setting';
  const fields = '*';
  const sort = ['CreatedDate DESC'].join(',');
  const subModuleName = '';
  const moduleName = 'SACHSO';

  return get(entityName, fields, {}, sort, subModuleName, moduleName);
};

export default getSettings;
