import { Tag } from 'antd';

export default [
  {
    title: '* Mã lớp',
    dataIndex: 'classCode',
  },
  {
    title: '* Email',
    dataIndex: 'email',
  },
  {
    title: 'Điểm X',
    dataIndex: 'scoreGDI',
  },
  {
    title: 'Điểm Y',
    dataIndex: 'scoreGDII',
  },
  {
    title: 'Ghi chú',
    dataIndex: 'notes',
  },
  {
    title: 'Trạng thái',
    dataIndex: 'statusData',
    render: (text, record) => {
      return <Tag color={record.color}>{record.statusData}</Tag>;
    },
  },
  {
    title: 'Trạng thái update',
    dataIndex: 'excelStatus',
    render: (text, record) => {
      return record.excelStatus && <Tag color={record.excelColor}>{record.excelStatus}</Tag>;
    },
  },
];
