import { CalendarOutlined, FileDoneOutlined } from '@ant-design/icons';
import { TimePicker } from 'antd';
import settings from 'constants/settings';
import i18next from 'i18next';
import useTimer from 'modules/Presentation/Header/hooks/useTimer';
import moment from 'moment';

export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: i18next.t('table:lectureManagement.Lecture'),
      key: 'title',
      width: '74%',
      sort: false,
      render: (title: string) => {
        return (
          <div
            className='tw-cursor-pointer'
            style={{
              whiteSpace: 'nowrap',
              fontWeight: 600,
            }}
          >
            <FileDoneOutlined
              className='tw-mr-2'
              // style={{ color: record.tags === 'QUIZCITY-WORKSHEET' ? colors.theme.success : colors.font }}
            />
            {title}
          </div>
        );
      },
      style: {
        fontWeight: 600,
        cursor: 'pointer',
      },
    },
    // {
    //   title: 'Thời hạn',
    //   key: 'duration',
    //   sort: false,
    //   render: (duration: { begin: number; end: number }) => {
    //     const { secondsToHoursAndMinutes } = useTimer();
    //     const begin = secondsToHoursAndMinutes(duration?.begin ?? 0);
    //     const end = secondsToHoursAndMinutes(duration?.end ?? 0);
    //     return (
    //       <TimePicker.RangePicker
    //         allowClear={false}
    //         defaultValue={[moment(begin, 'HH:mm'), moment(end, 'HH:mm')]}
    //         format='HH:mm'
    //         inputReadOnly
    //         open={false}
    //       />
    //     );
    //   },
    //   width: '26%',
    // },
    // {
    //   title: 'Ngày tạo',
    //   key: 'createdDate',
    //   sort: false,
    //   render: 'renderDate',
    //   style: {
    //     minWidth: 100,
    //   },
    //   prefix: <CalendarOutlined className='tw-mr-2' />,
    // },
  ],
};
