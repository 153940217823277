/* eslint-disable react/prop-types */
import { Form, Input, notification, Select, Button, DatePicker, Spin } from 'antd';
import Card from 'components/Containers/Card';
import { query } from 'helpers/QueryHelper';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import NoData from 'components/NoData';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import colors from 'constants/colors';
import tableData from './tableData';
import HandoutHeader from './component/HandoutHeader';
import { useQuery } from 'react-query';

const fetchLink = (userId, filterParams) => {
  const parameters = {
    userId,
    status: filterParams?.status ?? -1,
    divisionId: filterParams?.divisionId,
    teacherName: filterParams?.teacherName ?? '',
    teacherEmail: filterParams?.teacherEmail ?? '',
  };
  return query('p_QUIZCITY_Academy_LinkHandout_Dashboard', parameters);
};

const fetchDivision = () => {
  return query('p_QUIZCITY_Academy_LinkHandout_Divisions');
};
export default function Home({ loggedInUser }) {
  const [divisionOptions, setDivisionOptions] = useState([{ value: null, label: 'Tất cả chi nhánh' }]);
  const [filterParams, setFilterParams] = useState(null);

  const KEY_FETCH_LINK = ['p_QUIZCITY_Academy_LinkHandout_Dashboard', loggedInUser?.id, filterParams];
  const { data, isLoading } = useQuery(KEY_FETCH_LINK, () => fetchLink(loggedInUser?.id, filterParams));

  const KEY_FETCH_DIVISION = ['p_QUIZCITY_Academy_LinkHandout_Divisions'];
  const { data: divisions } = useQuery(KEY_FETCH_DIVISION, () => fetchDivision());

  const initialValues = {
    divisionId: null,
    status: -1,
  };

  const onSearchData = (values) => {
    setFilterParams(values);
  };

  useEffect(() => {
    if (divisions) {
      const resOptions = divisions.map((item) => ({ value: item?.id, label: item?.divisionName }));
      setDivisionOptions([{ value: null, label: 'Tất cả chi nhánh' }, ...resOptions]);
    }
  }, [divisions]);
  return (
    <React.Fragment>
      <HandoutHeader parentName='Trang chủ' />
      {(loggedInUser.role === 'teacher' || loggedInUser.role === 'administrator') && (
        <Container fluid className='mt--6' style={{ marginBottom: 102 }}>
          <Card title='Quản lý đường dẫn'>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: 20, marginBottom: 20 }}>
              <Form layout='inline' onFinish={(values) => onSearchData(values)} initialValues={initialValues}>
                <Form.Item name='status' hasFeedback style={{ marginBottom: 10, minWidth: 155 }}>
                  <Select
                    options={[
                      { label: 'Đã sử dụng', value: 1 },
                      { label: 'Chưa sử dụng', value: 0 },
                      { label: 'Tất cả', value: -1 },
                    ]}
                    placeholder='Trạng thái mã'
                    allowClear
                  />
                </Form.Item>
                <Form.Item name='divisionId' hasFeedback style={{ marginBottom: 10, minWidth: 175 }}>
                  <Select options={divisionOptions} placeholder='Chi nhánh' allowClear />
                </Form.Item>
                <Form.Item name='teacherName' hasFeedback style={{ marginBottom: 10 }}>
                  <Input placeholder='Tên giáo viên' allowClear />
                </Form.Item>
                <Form.Item name='teacherEmail' hasFeedback style={{ marginBottom: 10 }}>
                  <Input placeholder='Email giáo viên' allowClear />
                </Form.Item>

                <Form.Item>
                  <Button type='primary' htmlType='submit' loading={isLoading}>
                    Tìm kiếm
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin spinning={isLoading} />
            </div>
            {data?.length === 0 && <NoData />}
            <DynamicTable
              rowClassName='tw-cursor-pointer'
              rowKey='id'
              loading={isLoading}
              dataSource={data}
              initialTableData={tableData}
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '30', '50', '100'] }}
              scroll={{ x: 1800 }}
            />
          </Card>
        </Container>
      )}
    </React.Fragment>
  );
}
