import React, { Fragment } from 'react';
import { Button, Form, notification, Row, Col } from 'antd';
import colors from 'constants/colors';
import { query } from 'helpers/QueryHelper';
import { start } from 'modules/Presentation/Viewer/actions';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { IS_HANDOUT, IS_PT, PATHNAME } from 'constants/settings';
import ModalError from '../Modal/ModalError';
import HandoutFormContent from './components/HandoutFormContent';
import PTFormContent from './components/PTFormContent';
import { useLocation, useParams } from 'react-router';
import './styles.scss';

const logoUrl =
  window.location.hostname === 'quizcity.io'
    ? require('assets/img/brand/logo-quizcity.png').default
    : require('assets/img/brand/english-city-01.png').default;
const bannerUrl = require('assets/img/introduce/ames-login-BG.png').default;
const logoStudent = {
  logotrungDoan: require('assets/img/brand/trungdoan-logo.png').default,
  logotrungUongHoi: require('assets/img/brand/trunguonghoi-logo.png').default,
  logoboGiaoDuc: require('assets/img/brand/bogiaoducdt-logo.png').default,
  logoboLaoDongThuongBinh: require('assets/img/brand/bothuongbinhxh-logo.png').default,
  logoDeAnNgoaiNgu: require('assets/img/brand/deanngoaingu-logo.png').default,
  logoDonViThucHien: require('assets/img/brand/vsdl-logo.png').default,
  logoAmesDongHanh: require('assets/img/brand/amesdonghanh-logo.png').default,
};
type Props = {
  codeExam: string;
  onSuccess: (result: { studentId: number; codeId: number; params: any }) => void;
};

const ParticipantInformationForm = ({ codeExam, onSuccess }: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation();

  const [isShowModalError, setIsShowModalError] = React.useState<boolean>(false);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const showLogo = location.pathname.includes(`/${PATHNAME}/student/twd`) && codeExam && codeExam !== '';

  const handleSubmit = async (values: any) => {
    try {
      setIsProcessing(true);

      //KIỂM TRA TRẠNG THÁI MÃ THAM GIA
      const results: any = {};
      const res1 = IS_HANDOUT
        ? await query('p_QUIZCITY_Academy_LinkHandout_CodeExam_CheckCode', { HandoutCode: codeExam })
        : await query('p_QUIZCITY_PTTest_CodeExam_CheckCode', { PTCode: codeExam });
      if (res1?.[0].status === 0) {
        //Mã không sử dụng được
        setIsShowModalError(true);
        setIsProcessing(false);
        return;
      }

      //Mã hợp lệ
      results.codeId = res1?.[0].codeId;
      results.params = res1?.[0].jsonResult?.[0];
      dispatch(start(null)); // Xoá câu trả lời được lưu trước khi làm bài

      //CẬP NHẬT TRẠNG THÁI ĐÃ SỬ DỤNG CHO MÃ
      const res2 = IS_HANDOUT
        ? await query('p_QUIZCITY_Academy_LinkHandout_CodeExam_Insert_ByUser', {
            Code: codeExam,
            ContactName: values.userName,
            ContactPhone: values.phone || '',
            ContactEmail: values.email || '',
            ContactCompany: values.company,
            ContactAddress: values.branch,
            ContactDepartment: values.department,
            ContactCode: values.employyeeCode,
          })
        : await query('p_QUIZCITY_PTTest_CodeExam_Update_ByUser', {
            Code: codeExam,
            ContactName: values.userName,
            ContactPhone: values.phone || '',
            ContactEmail: values.email || '',
            ContactCompany: values.company,
            ContactAddress: values.branch,
            ContactDepartment: values.department,
            ContactCode: values.employyeeCode,
            tinh_thanh_id: values.cityId,
            quan_huyen_id: values.dictrictId,
            phuong_xa_ten: values.ward,
            ten_khoa: values.faculty,
            khoi_lop: values.grade ?? 0,
            ten_truong: values.schoolName,
            ten_lop: values.className,
          });

      if (res2?.[0].status === 1) {
        results.studentId = res2?.[0].studentId;
        onSuccess(results);
      } else {
        notification.error({
          message: 'Có lỗi xảy ra!',
          description: 'Vui lòng thử lại',
          duration: 20,
        });
      }
      setIsProcessing(false);
    } catch (error) {
      setIsProcessing(false);
      notification.error({
        message: 'Có lỗi xảy ra!',
        description: 'Vui lòng thử lại',
        duration: 20,
      });
    }
  };

  return (
    <div
      id='participant-form'
      style={{
        backgroundImage: `url(${bannerUrl}) `,
      }}
    >
      {showLogo && (
        <div>
          <div className='logo-container-1'>
            <img alt='Trung Ương Đoàn TNCS Hồ Chí Minh' src={logoStudent.logotrungDoan} />
            <img alt='Trung Ương Hội Sinh Viên Việt Nam' src={logoStudent.logotrungUongHoi} />
            <img alt='Bộ Giáo Dục Và Đào Tạo' src={logoStudent.logoboGiaoDuc} />
            <img alt='Bộ Lao Động Và Thương Binh Xã Hội' src={logoStudent.logoboLaoDongThuongBinh} />
          </div>
          <div className='logo-container-2'>
            <img alt='Đề Án Ngoại Ngữ' src={logoStudent.logoDeAnNgoaiNgu} />
            <img alt='Đơn Vị Thực Hiện' src={logoStudent.logoDonViThucHien} />
            <img alt='Đơn Vị Đồng Hành Ames English' src={logoStudent.logoAmesDongHanh} />
          </div>
        </div>
      )}
      <div className='form-register'>
        <div>
          <img className='logo-img' alt='English City' src={logoUrl} />
        </div>
        <Card
          className='card-form mb-0 border-0 bg-secondary'
          style={{ maxWidth: IS_HANDOUT ? 500 : 700, width: '95vw' }}
        >
          <CardHeader className='pb-3 bg-transparent'>
            <div className='mt-2 mb-1 text-center'>
              <span style={{ fontSize: 24, fontWeight: 600, color: colors.font }}>
                {IS_PT ? 'THÔNG TIN DỰ THI' : 'THÔNG TIN HỌC SINH'}
              </span>
            </div>
            <div className='text-center'>
              <small>
                <span className='tw-text-xl'>Điền thông tin vào form trước khi vào làm bài</span>
              </small>
            </div>
          </CardHeader>
          <CardBody className='px-lg-5 py-lg-5'>
            <Form autoComplete='on' onFinish={handleSubmit} form={form} initialValues={{ codeExam: codeExam }}>
              {IS_HANDOUT ? <HandoutFormContent /> : <PTFormContent formRef={form} />}
              <div className='text-center'>
                <Button className='mt-2' type='primary' htmlType='submit' size='large' block loading={isProcessing}>
                  {IS_PT ? 'Bắt đầu bài thi' : 'Bắt đầu'}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
      <ModalError isVisible={isShowModalError} />
    </div>
  );
};

export default ParticipantInformationForm;
