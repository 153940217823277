import React from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import AdminFooter from 'components/Footers/AdminFooter';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Sidebar from 'components/Sidebar/Sidebar';
import withAuthenticator from 'HOCs/withAuthenticator';
import { notification } from 'antd';
import background_lg from 'assets/img/introduce/background.PNG';
import background_basao from 'assets/img/introduce/bg_tristar_bottom.png';
// import background_trongdong from 'assets/img/introduce/bg_trongdong_bottom2.png';
import background_trongdong from 'assets/img/introduce/bg_trongdong_bottom.png';
import background_img from 'assets/img/olympic/background_bottom.png';
import colors from 'constants/colors';
import { IS_BASAO, IS_CDP, IS_ISE, IS_LG, IS_MENARD, IS_TRONGDONG, IS_TVHH, PATHNAME } from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import _ from 'lodash';
import SendRequest from 'modules/RequestSupport/SendRequest/SendRequest';
import Shared_Home from 'pages/Home';
import allRoutes from 'routes';
import { permission } from '../constants';
import NotFound from '404';

const logoUrl = IS_TVHH
  ? require('assets/img/brand/tvhh_logo.png').default
  : IS_ISE
  ? require('assets/img/brand/dhhh_logo.png').default
  : IS_LG
  ? require('assets/img/brand/logo-LG-Innotek.png').default
  : window.location.hostname === 'quizcity.io'
  ? require('assets/sachso/images/logo-quizcity.png').default
  : IS_MENARD
  ? require('assets/img/brand/logo-menard.png').default
  : IS_CDP
  ? require('assets/img/brand/cdp-logo.png').default
  : IS_BASAO
  ? require('assets/img/brand/logo-basao.png').default
  : IS_TRONGDONG
  ? require('assets/img/brand/logo_trongdong.png').default
  : require('assets/img/brand/english-city-02.png').default;

const openNotificationWithIcon = (type) => {
  notification[type]({
    description: 'Yêu cầu cập nhật đầy đủ thông tin cá nhân!',
    message: 'LƯU Ý',
  });
};

const Authenticated = ({ loggedInUser }) => {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const history = useHistory();
  const { data } = useQuery(['dbo.p_QUIZCITY_Get_FolderMenu', loggedInUser.id], () =>
    query('p_QUIZCITY_Get_FolderMenu', { studentId: loggedInUser.id }),
  );

  React.useEffect(() => {
    const homeRoute = allRoutes?.find((x) => {
      return x.state === `${PATHNAME}/home`;
    });
    if (homeRoute) {
      homeRoute.views = data
        //Chỉ hiển thị 2 khóa học 'fa7381c0-16b2-438b-b8d0-4cea733a1153', '3abfb603-b5c1-4cd3-848c-15ef8c64adc0' đối với tài khoản 'hahtt.nn@vimaru.edu.vn', 'anhnh.nn@vimaru.edu.vn'
        ?.filter(
          (item) =>
            !(
              ['hahtt.nn@vimaru.edu.vn', 'anhnh.nn@vimaru.edu.vn'].includes(loggedInUser?.email) &&
              !['fa7381c0-16b2-438b-b8d0-4cea733a1153', '3abfb603-b5c1-4cd3-848c-15ef8c64adc0'].includes(item.id)
            ),
        )
        ?.map((item, index) => {
          const { name, sortOrder } = item;
          return {
            component: Shared_Home,
            exact: true,
            // icon: 'fas fa-calendar-alt',
            layout: `/${PATHNAME}`,
            miniName: '',
            name,
            path: '/home',
            roles: ['authenticated'],
            semester: index + 1,
            showInSidebar: true,
            sortOrder,
          };
        });
    }
  }, [data]);

  React.useEffect(() => {
    if (data) {
      if (sidenavOpen) {
        document.body.classList.add('g-sidenav-pinned');
        document.body.classList.remove('g-sidenav-hidden');
      } else {
        document.body.classList.remove('g-sidenav-pinned');
        document.body.classList.add('g-sidenav-hidden');
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContentRef.current.scrollTop = 0;
    }
  }, [location, sidenavOpen, data]);

  const allowedRoutes = _.filter(allRoutes, (route) => {
    return (
      _.find(route.roles, (x) => x === 'authenticated') ||
      (_.find(route.roles, (x) => loggedInUser.role === x) && _.find(route.type, (x) => x === 'allowedRoutes')) ||
      (_.find(route.roles, (x) => x === 'permission') && _.find(permission, (email) => email === loggedInUser.email))
    );
  });

  // TODO: Tạm thời dùng users để xác định Administrators
  const administrationRouters = _.filter(allRoutes, (route) => {
    let routeAdmin = [];
    if (loggedInUser?.role === 'administrator') {
      routeAdmin =
        _.find(route.type, (x) => x === 'administrationRouters') && _.find(route.roles, (x) => x === 'administrator');
    } else {
      if (loggedInUser?.role === 'manager')
        routeAdmin =
          _.find(route.type, (x) => x === 'administrationRouters') && _.find(route.roles, (x) => x === 'manager');
    }
    return routeAdmin;
  });
  const rolesRouters = _.filter(allRoutes, (route) => {
    let routeAdmin = [];
    if (loggedInUser?.role === 'administrator') {
      routeAdmin = _.find(route.type, (x) => x === 'rolesRouters') && _.find(route.roles, (x) => x === 'administrator');
    } else {
      if (loggedInUser?.role === 'manager')
        routeAdmin = _.find(route.type, (x) => x === 'rolesRouters') && _.find(route.roles, (x) => x === 'manager');
    }
    return routeAdmin;
  });
  const availableRoutes = _.concat(allowedRoutes, administrationRouters, rolesRouters);

  // Filter by
  const getRoutes = React.useCallback((routes) => {
    return routes?.map((route, index) => {
      if (route.collapse) {
        return getRoutes(route.views);
      }
      if (route.layout === `/${PATHNAME}`) {
        return <Route path={route.layout + route.path} component={route.component} key={index} exact={route.exact} />;
      }
      return null;
    });
  }, []);

  // toggles collapse between mini sidenav and normal
  // eslint-disable-next-line no-unused-vars
  const toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };

  const getNavbarTheme = () => {
    return location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';
  };

  React.useEffect(() => {
    const bg = document.createElement('div');
    bg.style.position = 'fixed';
    bg.style.top = '0px';
    bg.style.backgroundImage = `url(${
      !IS_MENARD &&
      (IS_LG ? background_lg : IS_BASAO ? background_basao : IS_TRONGDONG ? background_trongdong : background_img)
    }`;
    bg.style.backgroundColor = IS_MENARD ? colors.gray[100] : IS_LG && '#6c6c6c';
    bg.style.backgroundSize = '100% 100%';
    bg.style.height = '100vh';
    bg.style.width = '100vw';
    bg.style.zIndex = '-999';
    document.body.appendChild(bg);
    return () => {
      document.body.removeChild(bg);
    };
  }, []);

  // khi data thay đổi sẽ rerender khiến component con bị gọi lại useQuery
  return data ? (
    <div>
      <Sidebar
        routes={allowedRoutes}
        administrationRouters={administrationRouters}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        rolesRouters={rolesRouters}
        logo={{
          imgAlt: 'English City',
          imgSrc: logoUrl,
          innerLink: '/',
        }}
        semesters={data}
      />
      <div className='main-content ' ref={mainContentRef}>
        {(IS_LG || IS_ISE || IS_TVHH) && <SendRequest />}
        {/* <SendRequest /> */}
        <AdminNavbar theme={getNavbarTheme()} toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} />
        <Switch>{getRoutes(availableRoutes)}</Switch>
        <AdminFooter />
      </div>
      {sidenavOpen ? <div className='backdrop d-xl-none' onClick={toggleSidenav} /> : null}
    </div>
  ) : (
    <></>
  );
};

const Admin = ({ loggedInUser }) => {
  if (!loggedInUser) {
    // nếu đường dẫn có thêm /... phía sau thì trả về 404
    if (window.location.pathname !== `/${PATHNAME}` && window.location.pathname !== `/${PATHNAME}/`) {
      return <NotFound />;
    }
    return <Redirect to={{ pathname: `/${PATHNAME === 'lms' ? '' : `auth/${PATHNAME}`}` }} />;
  }

  // * -- force information update -- new feature *
  const { pathname, href } = window.location;
  const dev = href.includes('localhost') || href.includes(':3000');
  if (!dev) {
    const profilePathname = '/lms/profile';
    const { school, firstName } = loggedInUser;
    if (firstName) {
      if (profilePathname !== pathname) {
        if (!school) {
          openNotificationWithIcon('warning');
          // history.push('/lms/profile');
          return <Redirect to={{ pathname: '/lms/profile' }} />;
        }
      }
    }
  }

  return <Authenticated loggedInUser={loggedInUser} />;
};

export default withAuthenticator(Admin);
