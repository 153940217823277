import React from 'react';
import IndexFillInTheBlank from './FillInTheBlank';
import IndexMatching from './Matching';
import IndexMultipleChoice from './MultipleChoice';
import IndexOneChoice from './OneChoice';
import IndexUnscrambleWords from './UnscrambleWords';

const IndexRenderContentAnswer = ({
  type,
  form,
  field,
  setAddField,
  optionType,
  setValueAnswerInputs,
}) => {
  const RenderContentOptionType = () => {
    switch (type?.toUpperCase()) {
      case 'ONECHOICE':
        return <IndexOneChoice form={form} field={field} setAddField={setAddField} optionType={optionType} />;
      case 'MULTIPLECHOOSE':
        return <IndexMultipleChoice form={form} field={field} setAddField={setAddField} optionType={optionType} />;
      case 'MATCHING':
        return <IndexMatching form={form} field={field} setAddField={setAddField} optionType={optionType} />;
      case 'FILLINTHEBLANK':
        return (
          <IndexFillInTheBlank
            setValueAnswerInputs={setValueAnswerInputs}
            form={form}
            field={field}
            setAddField={setAddField}
            optionType={optionType}
          />
        );
      case 'UNSCRAMBLE':
        return <IndexUnscrambleWords form={form} field={field} setAddField={setAddField} optionType={optionType} />;
      default:
        return <></>;
    }
  };
  return <RenderContentOptionType />;
};

export default IndexRenderContentAnswer;
