import {
  CalendarOutlined,
  MailOutlined,
  PaperClipOutlined,
  PhoneOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import colors from 'constants/colors';
import { IS_BASAO, IS_HIGHUP, IS_LG, IS_LX, IS_MENARD, IS_TRONGDONG, IS_VIMARU, studentPronouns1, studentPronouns2 } from 'constants/settings';
import i18next from 'i18next';

export const reviewColumns = [
  {
    title: i18next.t('SkillAssessment'),
    key: 'review',
    width: '1%',
    children: [
      {
        title: i18next.t('Listening'),
        key: 'listeningSkill',
        width: '1%',
        render: 'renderNumber',
        formatString: '0.00',
        style: {
          fontWeight: 600,
          color: colors.theme.danger,
          minWidth: 80,
        },
        formatConditions: [
          {
            condition: 'record.listeningSkill >= 6.5',
            style: {
              color: colors.theme.success,
            },
          },
        ],
      },
      {
        title: i18next.t('Speaking'),

        key: 'speakingSkill',
        width: '1%',
        render: 'renderNumber',
        formatString: '0.00',
        style: {
          fontWeight: 600,
          color: colors.theme.danger,
          minWidth: 80,
        },
        formatConditions: [
          {
            condition: 'record.speakingSkill >= 6.5',
            style: {
              color: colors.theme.success,
            },
          },
        ],
      },
      {
        title: i18next.t('Reading'),
        key: 'readingSkill',
        width: '1%',
        render: 'renderNumber',
        formatString: '0.00',
        style: {
          fontWeight: 600,
          color: colors.theme.danger,
          minWidth: 80,
        },
        formatConditions: [
          {
            condition: 'record.readingSkill >= 6.5',
            style: {
              color: colors.theme.success,
            },
          },
        ],
      },
      {
        title: i18next.t('Writing'),

        key: 'writtingSkill',
        width: '1%',
        render: 'renderNumber',
        formatString: '0.00',
        style: {
          fontWeight: 600,
          color: colors.theme.danger,
          minWidth: 80,
        },
        formatConditions: [
          {
            condition: 'record.writtingSkill >= 6.5',
            style: {
              color: colors.theme.success,
            },
          },
        ],
      },
    ],
  },
];

const categoryColumn =
  IS_VIMARU || IS_HIGHUP
    ? [
        {
          title: 'Khoa',
          key: 'khoa',
          // fixed: 'left',
          width: '10%',
          render: 'renderNoWrap',
          style: {
            fontWeight: 600,
            minWidth: 160,
          },
        },
        {
          title: 'Ngành',
          key: 'nganh',
          // fixed: 'left',
          width: '10%',
          render: 'renderNoWrap',
          style: {
            fontWeight: 600,
            minWidth: 160,
          },
        },
      ]
    : IS_MENARD  || IS_BASAO || IS_TRONGDONG || IS_LX
    ? [
        {
          title: i18next.t('table:classInformation.Department'),
          key: 'shopName',
          // fixed: 'left',
          width: '10%',
          render: 'renderNoWrap',
          style: {
            fontWeight: 600,
            minWidth: 160,
          },
        },
        {
          title: i18next.t('table:classInformation.Major'),
          key: 'positionName',
          // fixed: 'left',
          width: '10%',
          render: 'renderNoWrap',
          style: {
            fontWeight: 600,
            minWidth: 160,
          },
        },
      ]
    : [];
export const studentColumns = [
  {
    title: studentPronouns2,
    key: 'student-info',
    width: '1%',
    children: [
      {
        title: i18next.t('table:classInformation.Code'),
        key: 'maSV',
        width: '1%',
        fixed: 'left',
        render: 'renderNoWrap',
        style: {
          fontWeight: 600,
          textAlign: 'right',
        },
        prefix: <UserOutlined className='tw-mr-2' />,
      },
      {
        title: i18next.t('table:classInformation.Full name'),
        key: 'fullName',
        fixed: 'left',
        width: '1%',
        render: 'renderNoWrap',
        style: {
          fontWeight: 600,
          minWidth: 160,
        },
      },
      // {
      //   title: 'Lớp',
      //   key: 'class',
      //   fixed: 'left',
      //   width: '5%',
      //   render: 'renderNoWrap',
      //   style: {
      //     fontWeight: 600,
      //     minWidth: 160,
      //   },
      // },
      ...categoryColumn,
    ],
  },
  {
    title: i18next.t('table:classInformation.Contact information'),
    key: 'contact-info',
    width: '1%',
    children: [
      {
        title: i18next.t('table:classInformation.Email'),
        key: 'email',
        width: '1%',
        render: 'renderNoWrap',
        style: {
          fontWeight: 400,
        },
        prefix: <MailOutlined className='tw-mr-2' />,
      },
      {
        title: i18next.t('table:classInformation.Phone number'),
        key: 'phone',
        width: '10%',
        render: 'renderNoWrap',
        style: {
          fontWeight: 400,
        },
        prefix: <PhoneOutlined className='tw-mr-2' />,
      },
      // {
      //   title: 'Địa chỉ',
      //   key: 'address',
      //   width: '10%',
      //   render: 'renderNoWrap',
      //   style: {
      //     fontWeight: 400,
      //   },
      //   prefix: <PhoneOutlined className='tw-mr-2' />,
      // },
    ],
  },

  // {
  //   title: 'Ghi chú',
  //   key: 'description',
  //   width: '',
  //   render: 'renderText',
  //   soft: false,
  //   style: {
  //     fontWeight: '400',
  //   },
  // },
];

export const eBookColumns = [
  {
    title: i18next.t('Book'),
    key: 'book',
    width: '1%',
    render: 'renderNoWrap',
    filter: true,
    style: {
      fontWeight: 600,
      minWidth: 90,
    },
  },
  {
    title: 'Unit',
    key: 'unit',
    width: '1%',
    render: 'renderNoWrap',
    filter: true,
    style: {
      minWidth: 90,
    },
  },
  {
    title: 'Exercise',
    key: 'exercise',
    width: '1%',
    render: 'renderNoWrap',
    filter: true,
    style: {
      minWidth: 110,
    },
  },
  {
    title: 'Page',
    key: 'page',
    render: 'renderNoWrap',
    filter: true,
    style: {
      minWidth: 90,
    },
  },
];

export const eBookReviewColumns = [
  {
    title: i18next.t('TestResult'),
    key: 'results',
    children: [
      {
        title: i18next.t('TheLowest'),

        key: 'minScore',
        render: 'renderNumber',
        formatString: '0.00',
        style: {
          fontWeight: 600,
          color: colors.theme.danger,
          minWidth: 100,
        },
        formatConditions: [
          {
            condition: 'record.minScore >= 6.5',
            style: {
              color: colors.theme.success,
            },
          },
        ],
      },
      {
        title: i18next.t('Average'),
        key: 'avgScore',
        width: '1%',
        render: 'renderNumber',
        formatString: '0.00',
        style: {
          fontWeight: 600,
          color: colors.theme.danger,
          minWidth: 100,
        },
        formatConditions: [
          {
            condition: 'record.avgScore >= 6.5',
            style: {
              color: colors.theme.success,
            },
          },
        ],
      },
      {
        title: i18next.t('TheHighest'),
        key: 'maxScore',
        render: 'renderNumber',
        formatString: '0.00',
        style: {
          fontWeight: 600,
          color: colors.theme.danger,
          minWidth: 100,
        },
        formatConditions: [
          {
            condition: 'record.maxScore >= 6.5',
            style: {
              color: colors.theme.success,
            },
          },
        ],
      },
    ],
  },
];

export const eBookReviewDetailedColumns = [
  {
    title: i18next.t('TestResult'),
    children: [
      {
        title: i18next.t('Score'),
        key: 'score',
        render: 'renderNumber',
        formatString: '0.00',
        style: {
          fontWeight: 600,
          color: colors.theme.danger,
          minWidth: 80,
        },
        formatConditions: [
          {
            condition: 'record.score >= 6.5',
            style: {
              color: colors.theme.success,
            },
          },
        ],
      },
      {
        title: i18next.t('table:statistic.Result'),
        key: 'result',
        render: 'renderText',
        style: {
          fontWeight: 600,
          minWidth: 80,
          textAlign: 'center',
        },
      },
      {
        title: i18next.t('Rate'),
        key: 'rate',
        render: 'renderRateDisabled',
        style: {
          fontSize: 14,
          whiteSpace: 'nowrap',
        },
      },
    ],
  },
];
const schoolYear = IS_VIMARU
  ? {
      key: 'name',
      // prefix: <TeamOutlined className='tw-mr-2' />,
      render: 'renderNoWrap',
      style: {
        color: colors.font,
        fontWeight: 600,
      },
      title: 'Niên khóa',
    }
  : {};
const teacherColumn = IS_LG
  ? []
  : [
      {
        key: 'fullName',
        prefix: <TeamOutlined className='tw-mr-2' />,
        render: 'renderNoWrap',
        style: {
          color: colors.font,
          fontWeight: 600,
        },
        title: i18next.t('table:classroomManagement.Teacher'),
      },
    ];
export const classColumns = [
  {
    fixed: 'left',
    key: 'code',
    render: 'renderNoWrap',
    sort: false,
    style: {
      color: colors.font,
      fontWeight: 600,
    },
    title: i18next.t('table:classroomManagement.Class code'),
    width: '5%',
  },
  {
    key: 'className',
    prefix: <TeamOutlined className='tw-mr-2' />,
    render: 'renderNoWrap',

    style: {
      color: colors.font,
      fontWeight: 600,
    },
    title: i18next.t('table:classroomManagement.Class name'),
  },
  schoolYear,
  {
    key: 'courseName',
    prefix: <TeamOutlined className='tw-mr-2' />,
    render: 'renderNoWrap',
    style: {
      color: colors.font,
      fontWeight: 600,
    },
    title: i18next.t('table:classroomManagement.Course'),
  },
  ...teacherColumn,
  {
    key: 'numberOfStudent',
    prefix: <TeamOutlined className='tw-mr-2' />,
    render: (text) => {
      return <div style={{ width: 100 }}>{`${text} (${studentPronouns1})`}</div>;
    },
    style: {
      color: colors.font,
      fontWeight: 600,
    },
    title: IS_VIMARU ? 'Số lượng sinh viên' : i18next.t('table:classroomManagement.Number of students'),
    // width: '15%',
  },
  // {
  //   key: 'fullName',
  //   prefix: <UserOutlined className='tw-mr-2' />,
  //   render: 'renderNoWrap',
  //   style: {
  //     color: colors.font,
  //     fontWeight: 600,
  //   },
  //   title: 'Giáo viên',
  //   width: '1%',
  // },
  // {
  //   key: 'createdDate',
  //   prefix: <CalendarOutlined className='tw-mr-2' />,
  //   render: 'renderDateTime',
  //   style: {
  //     fontWeight: '400',
  //   },
  //   title: 'Ngày tạo',
  //   width: '1%',
  // },
  // {
  //   key: 'numberOfStudent',
  //   render: 'renderNumber',
  //   sort: false,
  //   style: {
  //     color: colors.font,
  //     fontWeight: 400,
  //     minWidth: 50,
  //   },
  //   title: 'Sĩ số',
  //   width: '1%',
  // },
  {
    key: 'note',
    render: 'renderNoWrap',
    sort: false,
    style: {
      color: colors.font,
      fontWeight: 400,
    },
    title: i18next.t('Notes'),
    width: '10%',
  },
];

export const fileColumns = [
  {
    title: i18next.t('table:reference.attachedFile'),
    key: 'fileName',
    render: 'renderPreviewFile',
    sort: false,
    style: {
      fontWeight: 500,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      color: colors.font,
    },
    prefix: <PaperClipOutlined className='tw-mr-2' />,
  },
  {
    title: i18next.t('table:reference.Size'),
    key: 'fileSize',
    render: 'renderNumber',
    formatString: '0.0 b',
    width: '1%',
    sort: false,
    style: {
      fontWeight: 400,
      minWidth: 100,
    },
  },
  {
    title: i18next.t('table:reference.Type'),
    key: 'contentType',
    width: '1%',
    render: 'renderNoWrap',
    sort: false,
    style: {
      fontWeight: 400,
    },
  },
  {
    title: i18next.t('table:reference.Created date'),
    key: 'createdDate',
    width: '1%',
    render: 'renderDate',
    sort: false,
    style: {
      fontWeight: 400,
    },
    prefix: <CalendarOutlined className='tw-mr-2' />,
  },
];

export const azotaColumns = [
  {
    title: i18next.t('table:reference.attachedFile'),
    key: 'title',
    render: 'renderPreviewFile',
    sort: false,
    style: {
      fontWeight: 500,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      color: colors.font,
    },
    prefix: <PaperClipOutlined className='tw-mr-2' />,
  },
  // {
  //   title: 'Loại',
  //   key: 'type',
  //   width: '1%',
  //   render: 'renderNoWrap',
  //   sort: false,
  //   style: {
  //     fontWeight: 400,
  //   },
  // },
  {
    title: i18next.t('table:reference.Created date'),
    key: 'createdDate',
    width: '1%',
    render: 'renderDateTime',
    sort: false,
    style: {
      fontWeight: 400,
    },
    prefix: <CalendarOutlined className='tw-mr-2' />,
  },
];
