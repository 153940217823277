import { Button, Form, Input, Upload } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import React from 'react';
import ReactQuill from 'react-quill';
import { normFile, PropsUploadFile } from './UploadFile';
const InputTitle = ({ fieldC }) => {
  const { props } = PropsUploadFile;
  return (
    <Form.Provider>
      <div style={{ display: 'flex', marginTop: 30, columnGap: 20, width: '100%' }}>
        <Form.Item {...fieldC} name={[fieldC.name, 'title']} style={{ width: '100%' }}>
          <ReactQuill theme='snow' style={{ width: '100%', height: '5%' }} />
        </Form.Item>
        <Form.Item {...fieldC} name={[fieldC.name, 'titleImage']} getValueFromEvent={normFile}>
          <Upload name='files' listType='picture' {...props}>
            <Button icon={<FileImageOutlined />}></Button>
          </Upload>
        </Form.Item>
      </div>
      <Form.Item {...fieldC} name={[fieldC.name, 'subTitle']} style={{ width: 'calc(100% - 40px)' }}>
        <Input autoFocus={false} placeholder='Sub title' className='input' />
      </Form.Item>
    </Form.Provider>
  );
};

export default InputTitle;
