import settings from 'constants/settings';
import i18next from 'i18next';
import { removeAccents } from 'services/Text/formatText';

const nienkhoaColumn = settings.IS_VIMARU
  ? [
      {
        label: 'Niên khóa',
        name: 'nienKhoaId',
        initialValue: '',
        rules: [
          {
            required: true,
            message: 'Chưa chọn niên khóa',
          },
        ],
        inputComponent: {
          type: 'Select',
          props: {
            // style: { width: 120 },
            placeholder: 'Chọn niên khóa',
            options: [],
            showSearch: true,
            allowClear: true,
            optionFilterProp: 'children',
            filterOption: (input, option) => removeAccents(option?.label)?.includes(removeAccents(input)),
          },
        },
      },
    ]
  : [];

export default {
  appName: settings.APPLICATION_NAME,
  formFields: [
    // {
    //   label: 'Khối lớp',
    //   name: 'courseId',
    //   initialValue: null,
    //   hasFeedback: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: 'Chưa chọn khối lớp',
    //     },
    //   ],
    //   inputComponent: {
    //     type: 'Select',
    //     dataSource: 'p_SACHSO_Courses_OPTIONS',
    //     dataSourceAppName: 'SachSo',
    //     props: {
    //       style: {},
    //     },
    //   },
    // },
    {
      hasFeedback: true,
      initialValue: '',
      inputComponent: {
        props: {
          style: {},
        },
        type: 'Input',
      },
      label: i18next.t('className'),
      name: 'className',
      rules: [
        {
          message: i18next.t('ClassNameNotEntered'),
          required: true,
        },
      ],
    },
    {
      initialValue: '',
      inputComponent: {
        props: {
          style: {},
        },
        type: 'Input',
      },
      label: i18next.t('Notes'),
      name: 'note',
    },
    {
      label: i18next.t('course'),
      name: 'courseId',
      initialValue: '',
      rules: [
        {
          required: true,
          message: i18next.t('HaveNotSelectedCourse'),
        },
      ],
      inputComponent: {
        type: 'Select',
        props: {
          // style: { width: 120 },
          placeholder: i18next.t('report:ReportCourse.Choose a course'),
          options: [],
          showSearch: true,
          allowClear: true,
          optionFilterProp: 'children',
          filterOption: (input, option) => removeAccents(option?.label)?.includes(removeAccents(input)),
        },
      },
    },
    ...nienkhoaColumn,
  ],
  layout: {
    type: 'vertical',
  },
  requiredMark: false,
  storedProcedure: 'p_VIMARU_Classes_CreateClass',
  // submitButtonText: settings.IS_LG ? 'Add new class' : 'Tạo lớp học',
  submitButtonText: i18next.t('addNew', { field: i18next.t('Class').toLowerCase() }),
  tailLayout: {},
};
