import settings, { IS_BASAO, IS_HIGHUP, IS_LX, IS_MENARD, IS_TRONGDONG, IS_VIMARU } from "constants/settings";
import i18next from "i18next";
import moment from "moment";

const vimaruColumns = [
  {
    label: 'Mã SV', // column name
    value: 'maSV', // column value
  },
  {
    label: 'Họ và tên',
    value: 'fullName',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Số điện thoại',
    value: 'phone',
  },
  {
    label: 'Lớp',
    value: 'className',
  },
  {
    label: 'Khoa',
    value: 'khoa',
  },
  {
    label: 'Ngành',
    value: 'nganh',
  },
  {
    label: 'Giới tính',
    value: (col) => (col?.sex === true ? 'Nam' : col?.sex === false ? 'Nữ' : ''),
  },
  {
    label: 'Ngày sinh',
    value: (col) => (col?.birthday ? moment(col?.birthday).format('yyyy-MM-DD') : ''),
  },
  {
    label: 'Loại tài khoản',
    value: (col) => (col?.roleId === 1 ? settings.studentPronouns2 : col?.roleId === 2 ? "Giáo viên" : col?.roleId === 3 ? "Quản trị viên" : ''),
  },
  {
    label: 'Trạng thái',
    value: 'status',
  },
  {
    label: 'Ngày tạo',
    value: (col) => (col?.createdDate ? moment(col?.createdDate).format('yyyy-MM-DD HH:mm') : ''),
  },
  {
    label: 'Ghi chú',
    value: 'description',
  },
];

const menardColumns = [
  {
    label: 'Mã nhân viên', // column name
    value: 'maSV', // column value
  },
  {
    label: 'Họ và tên',
    value: 'fullName',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Số điện thoại',
    value: 'phone',
  },
  {
    label: 'Lớp',
    value: 'className',
  },
  {
    label: 'Giới tính',
    value: (col) => (col?.sex === true ? 'Nam' : col?.sex === false ? 'Nữ' : ''),
  },
  {
    label: 'Ngày sinh',
    value: (col) => (col?.birthday ? moment(col?.birthday).format('yyyy-MM-DD') : ''),
  },
  {
    label: 'Loại tài khoản',
    value: (col) => (col?.roleId === 1 ? settings.studentPronouns2 : col?.roleId === 2 ? "Giáo viên" : col?.roleId === 3 ? "Quản trị viên" : ''),
  },
  {
    label: 'Mã bộ phận',
    value: 'shopCode',
  },
  {
    label: 'Bộ phận',
    value: 'shopName',
  },
  {
    label: 'Mã chức vụ',
    value: 'positionCode',
  },
  {
    label: 'Chức vụ',
    value: 'positionName',
  },
  {
    label: 'Ngày vào công ty',
    value: (col) => (col?.ngayVaoCongTy ? moment(col?.ngayVaoCongTy).format('yyyy-MM-DD') : ''),
  },
  {
    label: 'Trạng thái',
    value: 'status',
  },
  {
    label: 'Ngày tạo',
    value: (col) => (col?.createdDate ? moment(col?.createdDate).format('yyyy-MM-DD HH:mm') : ''),
  },
  {
    label: 'Ghi chú',
    value: 'description',
  },
];

const defaultColumns = [
  {
    label: settings.IS_LG ? i18next.t("employeeCode") : i18next.t("userCode"), // column name
    value: 'maSV', // column value
  },
  {
    label: i18next.t("fullName"),
    value: 'fullName',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: i18next.t("phoneNumber"),
    value: 'phone',
  },
  {
    label: i18next.t("Class"),
    value: 'className',
  },
  {
    label: i18next.t("gender"),
    value: (col) => (col?.sex === true ? i18next.t("male") : col?.sex === false ? i18next.t("female") : ''),
  },
  {
    label: i18next.t("auth:Birthday"),
    value: (col) => (col?.birthday ? moment(col?.birthday).format('yyyy-MM-DD') : ''),
  },
  {
    label: i18next.t("Role"),
    value: (col) => (col?.roleId === 1 ? settings.studentPronouns2 : col?.roleId === 2 ? i18next.t("teacher") : col?.roleId === 3 ? i18next.t("Manager") : ''),
  },
  {
    label: i18next.t("Status"),
    value: 'status',
  },
  {
    label: i18next.t("table:userManagement.Created date"),
    value: (col) => (col?.createdDate ? moment(col?.createdDate).format('yyyy-MM-DD HH:mm') : ''),
  },
  {
    label: i18next.t("Notes"),
    value: 'description',
  },
];


const excelColumns = IS_VIMARU || IS_HIGHUP
  ? vimaruColumns
  : IS_MENARD  || IS_BASAO || IS_TRONGDONG || IS_LX
    ? menardColumns
    : defaultColumns;

export default excelColumns;