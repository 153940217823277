import { FaBell, FaPencilRuler, FaTools } from 'react-icons/fa';
import { IoIosBook, IoIosNotifications } from 'react-icons/io';
import colors from 'constants/colors';
import { IS_BASAO, IS_HCM, IS_VIMARU } from 'constants/settings';
import React from 'react';

const HDHT_KhoaHoc = IS_VIMARU
  ? {
      name: 'Quy định và đánh giá',
      href: 'https://cloud.softech.vn/storage/Data/VIMARU/quy_dinh_ve_hoc_tap_va_danh_gia_ket_qua_VIMARU.pdf',
      icon: <FaPencilRuler size={16} className='tw-text-red-500' />,
      layout: '/lms',
      exact: true,
      roles: ['authenticated'],
      showInSidebar: true,
      sortOrder: 10000,
    }
  : {};

export default [
  {
    collapse: true,
    component: React.lazy(() => import('pages/Home')),
    exact: true,
    icon: `fas fa-home ${IS_BASAO ? 'text-green' : 'text-info'}`,
    // icon: <img alt='video-icon' src={homePage} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
    layout: '/lms',
    name: 'Trang chủ',
    path: '/home',
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 0,
    state: 'lms/home',
    views: [],
  },
  {
    path: '/access-denied',
    component: React.lazy(() => import('pages/Errors/AccessDenied')),
    layout: '/lms',
    exact: true,
    roles: ['authenticated'],
  },
  {
    path: '/admin/books',
    name: 'Sách điện tử',
    miniName: 'T',
    component: React.lazy(() => import('pages/Student/Books')),
    icon: 'fas fa-book text-blue',
    layout: '/lms',
    exact: true,
    roles: ['authenticated'],
    // type: ['administrationRouters'],
    sortOrder: 1,
    showInSidebar: IS_HCM,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: React.lazy(() => import('pages/Profile')),
    layout: '/lms',
    exact: true,
    roles: ['authenticated'],
  },
  {
    path: '/home/no-data',
    component: React.lazy(() => import('pages/Home')),
    layout: '/lms',
    exact: true,
    roles: ['authenticated'],
  },
  {
    path: '/notifications',
    name: 'Thông báo',
    // miniName: 'C',
    component: React.lazy(() => import('pages/Vimaru/Notification')),
    icon: <IoIosNotifications size={20} className='tw-text-blue-500' />,
    // icon: <img alt='video-icon' src={Notification} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
    layout: '/lms',
    exact: true,
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 97,
  },
  {
    path: '/libraries',
    name: 'Thư viện, tài nguyên',
    // miniName: 'C',
    component: React.lazy(() => import('pages/Vimaru/Library')),
    // icon: <img alt='video-icon' src={library} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
    icon: <IoIosBook size={20} className='tw-text-pink-500' />,
    layout: '/lms',
    exact: true,
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 98,
  },
  {
    collapse: true,
    name: 'Công cụ',
    icon: <FaTools style={{ color: colors.font }} />,
    // icon: <img alt='video-icon' src={Tools} width={40} style={{ marginRight: '0.5rem', marginLeft: '-1rem' }} />,
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 99,
    state: 'lms/tools',
    views: [
      {
        path: '/tools/dictionary',
        name: 'Từ điển',
        miniName: 'D',
        component: React.lazy(() => import('pages/Tools/Dictionary')),
        // icon: <img alt='video-icon' src={Dictionary} width={33} style={{ marginLeft: '10px' }} />,
        layout: '/lms',
        exact: true,
        roles: ['authenticated'],
        showInSidebar: true,
        sortOrder: 0,
      },
      {
        path: '/tools/translate',
        name: 'Dịch văn bản',
        miniName: 'D',
        component: React.lazy(() => import('pages/Tools/Translate')),
        // icon: <img alt='video-icon' src={Translator} width={33} style={{ marginLeft: '10px' }} />,
        layout: '/lms',
        exact: true,
        roles: ['authenticated'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },
  HDHT_KhoaHoc,
  {
    component: React.lazy(() => import('pages/Administration/ManageNotifications')),
    exact: true,
    icon: <FaBell color='#00B7C2' />,
    layout: '/lms',
    miniName: 'C',
    name: 'Thiết lập thông báo',
    path: '/admin/quan-ly-thong-bao',
    roles: ['administrator'],
    showInSidebar: true,
    sortOrder: 7,
    type: ['administrationRouters'],
  },
];
