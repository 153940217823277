import { query } from 'helpers/QueryHelper';

export const updateUserInfors = (parameters) => {
  return query('p_VIMARU_Users_Edit', {
    id: parameters?.id,
    maSV: parameters?.maSV,
    email: parameters.email,
    name: parameters?.name,
    phone: parameters?.phone ?? null,
    roleId: parameters.roleId,
    sex: parameters.sex,
    status: 'ACTIVE',
    avatar: parameters.avatar || '',
    birthDay: parameters?.birthDay ?? null,
    schoolTypeId: parameters?.schoolTypeId,
    khoa: parameters?.khoa || '00000000-0000-0000-0000-000000000000',
    nganh: parameters?.nganh || '00000000-0000-0000-0000-000000000000',
    chucVu: parameters.chucVu || '00000000-0000-0000-0000-000000000000',
    shop: parameters.shop || '00000000-0000-0000-0000-000000000000',
  });
};
