import React from 'react';
import { useQuery } from 'react-query';
import { query } from 'helpers/QueryHelper';
import DynamicForm from 'packages/DynamicForm/components/DynamicForm';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import formData from './formData';
import tableData from './tableData';
import { message, Spin } from 'antd';
import EditInfomation from './editInfomation';
import { CalendarOutlined } from '@ant-design/icons';
import getSettings from 'pages/Administration/api/getSettings';
import _ from 'lodash';
import ExportExcel from 'components/Excel/exportExcel/ExportExcel';
import excelColumns from './excelColumns';
import settings from 'constants/settings';
import i18next from 'i18next';
import { queryExcel } from 'helpers/ExcelHelper';
import { useSelector } from 'react-redux';

type Parameters = {
  name: string;
  email: string;
  phone: string;
  roleId: number;
  status: string;
  maSV: string;
};

const fetcher = (parameters: Parameters) => () => {
  return query('p_VIMARU_USERS_Search', parameters);
};

type UsersProps = {
  isActive?: boolean;
  isTeacherRole?: boolean;
  paginationUsersTable?: any;
  rowSelectionUsersTable?: any;
  searchForm?: object;
  usersTable?: object;
  exportExcel: boolean;
};

const Users = ({
  isActive,
  isTeacherRole,
  paginationUsersTable,
  rowSelectionUsersTable,
  searchForm,
  usersTable,
  exportExcel,
}: UsersProps) => {
  const [parameters, setParameters] = React.useState<Parameters>({
    email: '',
    name: '',
    phone: '',
    maSV: '',
    roleId: isTeacherRole ? 2 : 1,
    status: isActive ? 'ACTIVE' : 'ACTIVE',
  });

  const [isLoadingPage, setIsLoadingPage] = React.useState<boolean>(false);

  const usersTypeList =
    parameters?.roleId === 1
      ? settings.IS_VIMARU || settings.IS_HIGHUP
        ? 'sinh_vien'
        : settings.IS_PT || settings.IS_HANDOUT
        ? 'hoc_sinh'
        : 'hoc_vien'
      : parameters?.roleId === 2
      ? 'giao_vien'
      : parameters?.roleId === 3
      ? 'quan_tri_vien'
      : '';

  const key = ['Users', parameters];
  const { data, isLoading } = useQuery(key, fetcher(parameters));

  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);

  const mailer = ConfigSettings?.find((x:any) => x?.key.toUpperCase() === 'MAILER')?.value;
  const templateResetPass = ConfigSettings?.find((x:any) => x?.key.toUpperCase() === 'TEMPLATE_RESETPASSWORD')?.value;

  const extraColumn = [
    {
      title: i18next.t('Action'),
      key: 'editInfomation',
      width: '10%',
      sort: false,
      render: (text:any, record:any) => {
        return <EditInfomation data={record} mailer={mailer} templateResetPass={templateResetPass} />;
      },
    },
  ];
  
  const exportToExcel = (storedProcedure: any, parameters: any, appName: string) => {
    return new Promise((resolve, reject) => {
      setIsLoadingPage(true);
      try {
        message.loading({ content: 'Đang xử lý dữ liệu...', key: 'updatable' });
        queryExcel(storedProcedure, parameters, appName)
          .then((data: any) => {
            message.success({
              content: `Kết quả: ${data?.results?.length} dữ liệu`,
              key: 'updatable',
              duration: 3,
            });
            setIsLoadingPage(false);
            resolve(data);
          })
          .catch((error) => {
            message.error({
              content: i18next.t('auth:An error occurred, please try again'),
              key: 'updatable',
              duration: 3,
            });
            setIsLoadingPage(false);
            reject(error);
          });
      } catch (error) {
        message.error({
          content: i18next.t('auth:An error occurred, please try again'),
          key: 'updatable',
          duration: 3,
        });
        setIsLoadingPage(false);
        reject(error);
      }
    });
  };
  return (
    <React.Fragment>
      <Spin spinning={isLoadingPage}>
        <DynamicForm
          initialFormData={searchForm ?? formData}
          loading={isLoading}
          onSubmit={(values: Parameters) => {
            setParameters({ ...parameters, ...values });
          }}
          onError={undefined}
          initialValues={undefined}
          onValuesChange={undefined}
          formInstance={undefined}
          style={{ gap: '10px' }}
          // onExportToExcel={
          //   exportExcel
          //     ? undefined
          // : async (values: Parameters) => {
          //     setIsLoadingPage(true);
          //     const result: any = await exportToExcel('p_QUIZCITY_ExportUser', values, formData.appName);
          //     var link = document.createElement('a');
          //     link.href = result.excelDownloadUrl;
          //     link.download = result.excelDownloadUrl;
          //     link.click();
          //     setIsLoadingPage(false);
          //   }
          // }
          // extraItem={[
          //   <ExportExcel
          //     filename={`Danh_sach_nguoi_dung(${usersTypeList})`}
          //     sheets={[
          //       {
          //         name: usersTypeList, // sheet name
          //         column: excelColumns,
          //         data: data,
          //       },
          //     ]}
          //   />,
          // ]}
        />
        <br />
        <DynamicTable
          loading={isLoading}
          dataSource={data}
          initialTableData={usersTable ?? tableData}
          extraColumns={usersTable ?? extraColumn}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
          }}
          // pagination={{ defaultPageSize: 10, position: paginationUsersTable ?? ['topRight', 'bottomRight'] }}
          onReload={undefined}
          rowSelection={rowSelectionUsersTable}
        />
      </Spin>
    </React.Fragment>
  );
};

Users.defaultProps = {
  isActive: null,
  isTeacherRole: null,
  paginationUsersTable: null,
  rowSelectionUsersTable: null,
  searchForm: null,
  usersTable: null,
};

export default Users;
