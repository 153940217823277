/* eslint-disable sort-keys */
/* eslint-disable no-unused-vars */

import React from 'react';
import { Button } from 'antd';
import { execute } from 'helpers/QueryHelper';
import colors from 'constants/colors';
import {
  CalendarOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import EditInfomation from './editInfomation';
import settings from 'constants/settings';
import i18next from 'i18next';

const extraInfo = settings.IS_LG
  ? []
  : [
      {
        title: i18next.t('table:classInformation.Phone number'),
        key: 'phone',
        render: 'renderNoWrap',
        width: 135,
        style: {
          fontWeight: 400,
          color: colors.font,
        },
        prefix: <PhoneOutlined className='tw-mr-2' />,
      },

      {
        title: i18next.t('Role'),
        key: 'roleId',
        render: 'renderExpression',
        expression: `record.roleId === 1 ? "${i18next.t(
          'table:notification.Student',
        )}" : record.roleId === 3 ? "${i18next.t('Admin')}:${i18next.t('table:notification.Teacher')}"`,
        width: 135,
        style: {
          fontWeight: 400,
          color: colors.font,
        },
      },
      {
        title: i18next.t('table:userManagement.Gender'),
        key: 'sex',
        render: (_, record) => {
          return (
            <span>{record?.sex !== null ? (record?.sex === true ? i18next.t('Male') : i18next.t('Female')) : ''}</span>
          );
        },
      },
    ];
export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: i18next.t('table:statistic.Code'),
      key: 'maSV',
      width: '1%',
      fixed: 'left',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        color: colors.font,
      },
    },
    {
      title: i18next.t('table:statistic.Full name'),
      key: 'fullName',
      fixed: 'left',
      width: '1%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
      prefix: <UserOutlined className='tw-mr-2' />,
    },
    {
      title: i18next.t('table:statistic.Email'),
      key: 'email',
      width: '10%',
      render: 'renderNoWrap',
      style: {
        fontWeight: '400',
        textTransform: 'lowercase',
        color: colors.font,
      },
      prefix: <MailOutlined className='tw-mr-2' />,
    },
    ...extraInfo,
    {
      title: i18next.t('table:userManagement.Note'),
      key: 'description',
      render: (text, record) => {
        return <span>{record.note}</span>;
      },
      soft: false,
      style: {
        fontWeight: '400',
        color: colors.font,
      },
    },
    {
      title: i18next.t('table:Q&A.Status'),
      key: 'status',
      width: '10%',
      render: (text, record) => {
        const { status } = record;
        return (
          <span className={`status__table__user ${status === 'ACTIVE' ? 'active' : 'deactive'}`}>
            {status === 'ACTIVE' ? <UnlockOutlined /> : <LockOutlined />} {status}
          </span>
        );
      },
      soft: false,
      style: {
        fontWeight: '500',
        color: colors.font,
      },
    },
    {
      title: i18next.t('table:notification.Created date'),
      key: 'createdDate',
      width: '1%',
      sort: false,
      render: 'renderDateTime',
      prefix: <CalendarOutlined className='tw-mr-2' />,
    },
  ],
};
