import axios from 'axios';

const AppName = 'WEB_MY_AMES';
// const authorizationKey = 'Basic 12C1F7EF9AC8E288FBC2177B7F54D';

export const API_ = 'https://cloud.softech.cloud/mobile/api';
export const API_AMES = 'https://cloud.softech.cloud/mobile/ames/api';
export const API_MYAMES = 'https://cloud.softech.cloud/myames/myamesapi';
export const API_FIREBASE_CLOUD_FUNCTION = 'https://us-central1-imm-crm.cloudfunctions.net';

const TOKEN = localStorage.getItem('TOKEN');

export const axiosAMES = axios.create({
  baseURL: `${API_AMES}`,
  headers: {
    'Content-Type': 'application/json',
    Platform: 'WEB',
    AppName,
  },
});

export const axiosAPI = axios.create({
  baseURL: `${API_}`,
  headers: {
    'Content-Type': 'application/json',
    Platform: 'WEB',
    AppName,
  },
});

export const axiosMYAMES = axios.create({
  baseURL: `${API_MYAMES}`,
  headers: {
    'Content-Type': 'application/json',
    Platform: 'WEB',
    AppName,
  },
});

export const axiosMYAMESPUT = axios.create({
  baseURL: API_MYAMES,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
  },
});

export const dynamicApiAxios = {
  query: axios.create({
    baseURL: 'https://cloud.softech.cloud/mobile/ames/api/query/dynamic',
    headers: {
      AppName,
      Application: 'SGK',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
    },
  }),
  execute: axios.create({
    baseURL: 'https://cloud.softech.cloud/mobile/ames/api/execute/dynamic',
    headers: {
      AppName,
      Application: 'SGK',
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
    },
  }),
};

export const ames247Axios = axios.create({
  baseURL: 'https://cloud.softech.cloud/mobile/sgk',
  headers: {
    Platform: 'WEB',
    AppName,
    Authorization: `Bearer ${TOKEN}`,
    'Content-Type': 'application/json; charset=utf-8',
  },
});

export const sachsoClient = axios.create({
  baseURL: 'https://webhook.softech.cloud/api/sachso',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Platform: 'WEB',
    ApplicationName: 'sachso.edu.vn',
    Authorization:
      'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InVzZW5hbWUiOiJzYWNoc28ifSwiaWF0IjoxNjIzOTQ3NTE5LCJleHAiOjE3ODE2Mjc1MTksImF1ZCI6IndlYmhvb2suc29mdGVjaC5jbG91ZCIsImlzcyI6InNvZnRlY2guY2xvdWQiLCJzdWIiOiJzYWNoc28ifQ.g5cijByPRAaLxHp1kv1YXrtlMbKvJjQtpaQTqmpo24hVyw9P7rMH8lLYskfmsT3tSkVbmew7SihaBns2ILcGTQ',
  },
});

export const hanhtrangsoClient = axios.create({
  timeout: 15000,
  timeoutErrorMessage: 'Cannot connect to hanhtrangso server',
  baseURL: 'https://apihanhtrangso.nxbgd.vn/api',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    ClientId: 'SachSo_6JY98PxbiZ9',
    ClientSecret: '3b0a515fgRjigcHJoSrSVWud9ZV0e4341a387d076de2b54d509Io6UiEq0MrkwRSuF8mTrpYw',
  },
});
