/* eslint-disable no-unused-vars */

import colors from 'constants/colors';
import { CalendarOutlined } from '@ant-design/icons';

export default {
  rowKey: 'id',
  displayColumns: [
    {
      title: 'Mã',
      key: 'code',
      fixed: 'left',
      width: '5%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
    },
    {
      title: 'Tên',
      key: 'categoryName',
      fixed: 'left',
      width: '50%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        textTransform: 'uppercase',
        color: colors.font,
      },
    },

    {
      title: 'Ngày tạo',
      key: 'createdDate',
      width: '35%',
      sort: false,
      render: 'renderDateTime',
      prefix: <CalendarOutlined className='tw-mr-2' />,
    },
  ],
};
