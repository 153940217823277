import React from 'react';
import { Tooltip } from 'antd';
import { IS_LG } from 'constants/settings';

export default function QuestionHeader({ title, subTitle, questionIndex }) {
  return (
    <div className='d-flex flex-grow-1'>
      <Tooltip>
        <div
          className='mr-3 border d-flex justify-content-center align-items-center bg-secondary text-default rounded-circle border-default'
          style={{ height: 48, width: 48, fontWeight: 600 }}
        >
          {questionIndex}
        </div>
      </Tooltip>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div className='title'>{title}</div>
        {/* <div className="subTitle">{subTitle}</div> */}
        {/* LG không muốn hiện subTitle tiếng Việt nên ẩn đi */}
        {IS_LG ? <></> : <div style={{ fontSize: 15 }}>{subTitle}</div>}
      </div>
    </div>
  );
}
