/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button, Space } from 'antd';
import { LoggedInUserType } from 'types';
import { add, query } from 'helpers/QueryHelper';
import withAuthenticator from 'HOCs/withAuthenticator';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import TeacherContainer from 'components/Containers/TeacherContainer';
import moment from 'moment';
import colors from 'constants/colors';
import numeral from 'numeral';
import { EditFilled } from '@ant-design/icons';
import tableData from './tableData';
import settings, { IS_BASAO, IS_LX, IS_MENARD, IS_TRONGDONG } from 'constants/settings';

type Props = {
  loggedInUser?: LoggedInUserType;
  classId: number | string;
};

// REACT COMPONENT
const Assignments = ({ loggedInUser, classId }: Props) => {
  const history = useHistory();
  // const queryClient = useQueryClient();
  // const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

  const KEY = React.useMemo(() => ['p_SACHSO_CLASSWORK_Assignments_GetByClassId', classId], [classId]);
  const { data: records, isLoading } = useQuery(KEY, () => {
    if (isTeacher) {
      return query('p_SACHSO_CLASSWORK_Assignments_GetByClassId', { classId });
    }

    return query('p_SACHSO_CLASSWORK_Assignments_GetByClassIdAndStudentId', { classId, studentId: loggedInUser.id });
  });

  // console.log(records);

  const toHours = React.useCallback((dueDate: moment.MomentInput, date: moment.MomentInput): number => {
    return moment.duration(moment(dueDate).diff(date)).asHours();
  }, []);

  const renderDuration = React.useCallback(
    (dueDate: moment.MomentInput, date: moment.MomentInput): React.ReactNode => {
      const hours = Math.abs(toHours(dueDate, date));
      if (hours > 0 && hours < 24) {
        return (
          <React.Fragment>
            <span>{numeral(hours).format('0')}</span>
            <span>&nbsp;(giờ)</span>
            <span>&nbsp;</span>
            <span>{numeral((hours - Math.floor(hours)) * 60).format('0')}</span>
            <span>&nbsp;(phút)</span>
          </React.Fragment>
        );
      }
      if (hours > 24) {
        const days = hours / 24;
        return (
          <React.Fragment>
            <span>{numeral(days).format('0')}</span>
            <span>&nbsp;(ngày)</span>
            <span>&nbsp;</span>
            <span>{numeral((days - Math.floor(days)) * 24).format('0')}</span>
            <span>&nbsp;(giờ)</span>
          </React.Fragment>
        );
      }
      return null;
    },
    [toHours],
  );

  // const handleUpdate = (record: any) => () => {
  //   setSelectedAssignment(record);
  //   setUpdateModalVisible(true);
  // };

  // const handleDelete = React.useCallback(
  //   (id: string) => async () => {
  //     await remove('Assignments', id, 'CLASSWORK');
  //     queryClient.invalidateQueries(KEY);
  //   },
  //   [KEY, queryClient],
  // );

  const isStudent = loggedInUser?.role === 'student';
  const isTeacher =
    loggedInUser?.role === 'teacher' || loggedInUser?.role === 'manager' || loggedInUser?.role === 'administrator';

  const extraColumns = React.useMemo(() => {
    if (isStudent) {
      return [
        {
          title: 'Thông tin nộp bài',
          key: 'student-workitems',
          children: [
            {
              key: 'isCompleted',
              title: 'Trạng thái',
              width: 120,
              sort: false,
              render: (text: string, record: any, index: number) => {
                return (
                  <span className='tw-whitespace-nowrap'>
                    {record.studentWorkItem?.isCompleted ? (
                      <span style={{ color: colors.theme.success }}>Đã nộp bài</span>
                    ) : (
                      <span style={{ color: colors.theme.gold }}>Chưa nộp bài</span>
                    )}
                  </span>
                );
              },
            },
            {
              key: 'completedDate',
              title: 'Ngày nộp bài',
              width: 120,
              sort: false,
              render: (text: string, record: any, index: number) => {
                return (
                  <span className='tw-whitespace-nowrap'>
                    {record.studentWorkItem?.completedDate &&
                      moment(record.studentWorkItem?.completedDate).format('DD/MM/YYYY HH:mm')}
                  </span>
                );
              },
            },
            {
              key: 'score',
              title: 'Điểm',
              className: 'tw-text-right',
              width: 72,
              sort: false,
              render: (text: string, record: any, index: number) => {
                return <span>{record.studentWorkItem?.isCompleted ? record.studentWorkItem?.score : ''}</span>;
              },
            },
            // {
            //   title: 'Ghi chú',
            //   key: 'diffDate',
            //   width: '1%',
            //   render: (text: string, record: any, index: number) => {
            //     return (
            //       <div
            //         className='tw-whitespace-nowrap tw-font-medium'
            //         style={{
            //           color: moment.duration(moment(record.dueDate).diff(new Date())).asDays() >= 0 ? colors.theme.success : colors.theme.danger,
            //         }}
            //       >
            //         <ClockCircleOutlined className='tw-mr-2' />
            //         <span>{toHours(record.dueDate, moment()) >= 0 ? 'Còn ' : 'Quá hạn '}</span>
            //         {renderDuration(record.dueDate, moment())}
            //       </div>
            //     );
            //   },
            //   style: {
            //     fontWeight: 400,
            //     cursor: 'pointer',
            //   },
            // },
          ],
        },
      ];
    }

    return [];
  }, [isStudent]);

  // const expandable = React.useMemo(() => {
  //   return {
  //     expandIconColumnIndex: 999,
  //     expandedRowKeys,
  //     columnWidth: '1%',
  //     expandIcon: ({ expanded, onExpand, record }: any) => (expanded ? <CaretUpOutlined onClick={(e) => onExpand(record, e)} /> : <CaretDownOutlined onClick={(e) => onExpand(record, e)} />),
  //     expandRowByClick: true,
  //     onExpand: (expanded: any, record: any) => {
  //       const keys = [];
  //       if (expanded) {
  //         keys.push(record.id);
  //       }
  //       setExpandedRowKeys(keys);
  //     },

  //     expandedRowRender: (record: { instructions: string; id: string }) => (
  //       <div>
  //         <div style={{ marginBlock: 12 }}>
  //           <ReactQuill theme={null} value={record.instructions} readOnly />
  //         </div>
  //         <div style={{ marginBottom: 12 }}>
  //           <Files assignmentId={record.id} />
  //         </div>

  //         <div className='tw-flex'>
  //           <div style={{ marginBlock: 12, display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
  //             <UploadFiles
  //               assignmentId={record.id}
  //               onSuccess={() => {
  //                 queryClient.invalidateQueries(['t_SACHSO_SHARE_Attachments', record.id]);
  //               }}
  //             />
  //           </div>
  //           <div style={{ marginBlock: 12, display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
  //             <Space>
  //               <Tooltip title='Sửa' placement='bottom'>
  //                 <Button key='update-assignment' shape='circle' type='dashed' icon={<EditOutlined />} onClick={handleUpdate(record)} />
  //               </Tooltip>
  //               <Popconfirm title='Bạn có muốn xóa bài tập này không?' onConfirm={handleDelete(record.id)} okText='Xác nhận' cancelText='Không'>
  //                 <Tooltip title='Xóa' placement='bottom'>
  //                   <Button key='delete-assignment' shape='circle' type='dashed' icon={<DeleteOutlined />} danger />
  //                 </Tooltip>
  //               </Popconfirm>
  //             </Space>
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //   };
  // }, [expandedRowKeys, handleDelete, queryClient]);

  const onRow = (record: any, rowIndex: number) => {
    const { id: assigmentId, settings, typeExercise } = record;
    const convert =
      settings?.tags === 'QUIZCITY-WORKSHEET' || settings?.tags === 'LIVEWORKSHEET' ? 'auto_lws' : undefined;
    return {
      onClick: () => {
        if (isTeacher) {
          history.push(
            `/lms/teacher/classes/${classId}/classwork/assignments/${assigmentId}/details/${
              typeExercise?.toLocaleLowerCase() ?? convert
            }`,
          );
        } else if (isStudent) {
          history.push(`/lms/student/classes/${classId}/classwork/assignments/${assigmentId}/details`);
        }
      },
    };
  };

  return (
    <div>
      {isTeacher && (
        <Space>
          {/* <Button
            ghost
            style={{ backgroundColor: '#fa541c', color: 'white' }}
            icon={<EditFilled />}
            onClick={async () => {
              // Create an default assignment
              // INSERT
              const parameters = {
                title: `Bài tập Manual (${moment().format('YYYY-MM-DD HH:mm:ss')})`,
                points: 10,
                dueDate: moment().add(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
                assignmentType: 'ASSIGNMENT',
                typeExercise: 'manual',
                classId,
                createdBy: loggedInUser?.id,
              };
              const response = await add('Assignments', parameters, 'CLASSWORK');
              if (response.id) {
                history.push(`/lms/teacher/classes/${classId}/classwork/assignments/${response.id}/edit/manual`);
              }
            }}
          >
            Quiz City (Manual)
          </Button> */}
          {!(IS_MENARD|| IS_BASAO || IS_TRONGDONG || IS_LX) && (
            <Button
              ghost
              style={{ backgroundColor: '#1890ff', color: 'white' }}
              icon={<EditFilled />}
              onClick={async () => {
                // Create an default assignment
                // INSERT
                const parameters = {
                  title: `Bài tập Worksheet (${moment().format('YYYY-MM-DD HH:mm:ss')})`,
                  points: 10,
                  dueDate: moment().add(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
                  assignmentType: 'ASSIGNMENT',
                  typeExercise: 'auto_lws',
                  classId,
                  createdBy: loggedInUser?.id,
                };
                const response = await add('Assignments', parameters, 'CLASSWORK');
                if (response.id) {
                  history.push(`/lms/teacher/classes/${classId}/classwork/assignments/${response.id}/edit/auto_lws`);
                }
              }}
            >
              Quiz City (WorkSheet)
            </Button>
          )}
          <Button
            ghost
            style={{ backgroundColor: '#52c41a', color: 'white' }}
            icon={<EditFilled />}
            onClick={async () => {
              // Create an default assignment
              // INSERT
              const parameters = {
                title: `Bài tập Quiz (${moment().format('YYYY-MM-DD HH:mm:ss')})`,
                points: 10,
                dueDate: moment().add(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
                assignmentType: 'ASSIGNMENT',
                typeExercise: 'auto_quiz',
                classId,
                createdBy: loggedInUser?.id,
              };
              const response = await add('Assignments', parameters, 'CLASSWORK');
              if (response.id) {
                history.push(`/lms/teacher/classes/${classId}/classwork/assignments/${response.id}/edit/auto_quiz`);
              }
            }}
          >
            Quiz City (Trắc nghiệm)
          </Button>
        </Space>
      )}

      <DynamicTable
        loading={isLoading}
        dataSource={records}
        initialTableData={tableData}
        extraColumns={extraColumns}
        onRow={onRow}
        onReload={undefined}
        pagination={false}
      />
    </div>
  );
};

Assignments.defaultProps = {
  loggedInUser: null,
};

export default withAuthenticator(Assignments);
