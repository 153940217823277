import Teacher_Classes_Details from 'pages/Teacher/Classes/ClassDetails';
import Teacher_Classes from 'pages/Teacher/Classes/Classes';
import React from 'react';
// Icons
import AssignmentsManagement from 'modules/TeacherModule/components/AssignmentsManagement/components';
import Users from 'pages/Administration/Users/index';
import { MdDoneOutline, MdGroup as ClassIcon, MdClass } from 'react-icons/md';
import IndexAddQuestion from 'pages/Administration/FormAddQuestions';
import IndexManageKhoaNganh from 'pages/Administration/ManageKhoaNganh';
import ManagerCourse from 'pages/Administration/ManagerCourse';
import StatisticalReport from 'assets/icon/homeIcons/StatisticalReportIcon.png';
import classManager from 'assets/icon/homeIcons/classManagerIcon.png';
import ReportFromWeekToWeek from 'pages/Administration/Reports';
import IndexImportQuestion from 'pages/Administration/ImportQuestion';
import IndexCategoryType from 'pages/Administration/Category';
import ConfigSettings from 'pages/Administration/ConfigSettings';
import { FaBalanceScale, FaCodeBranch, FaCogs, FaSchool, FaThList, FaUsers } from 'react-icons/fa';
import colors from 'constants/colors';
import IndexManageRoles from 'pages/Administration/ManagerRoles';
import IndexManageFunction from 'pages/Administration/ManagerFunction';
import IndexManagePermissions from 'pages/Administration/ManagerPermissions';
import { SiGoogleclassroom } from 'react-icons/si';
import { HiClipboardList, HiOutlinePresentationChartBar } from 'react-icons/hi';
import { IoLibraryOutline } from 'react-icons/io5';
import { TbReportAnalytics } from 'react-icons/tb';
import { RiFunctionFill } from 'react-icons/ri';
import { IoMdOptions } from 'react-icons/io';
import settings from 'constants/settings';
import IndexManageSchoolYear from 'pages/Administration/ManagerSchoolYear';

const URL_HuongDanSuDung = settings.IS_VIMARU
  ? 'https://cloud.softech.vn/storage/Data/VIMARU/Huong-dan-su-dung-cho-Admin.pdf'
  : null;

const fileHelp = {
  name: 'Hướng dẫn sử dụng',
  href: URL_HuongDanSuDung,
  icon: <HiClipboardList size={20} className='tw-text-blue-500' />,
  layout: '/lms',
  exact: true,
  roles: ['administrator'],
  showInSidebar: true,
  type: ['allowedRoutes'],
  sortOrder: 9999,
};

export default [
  {
    component: Teacher_Classes,
    exact: true,
    icon: <SiGoogleclassroom size={20} className='tw-text-red-500' />,
    layout: '/lms',
    miniName: 'C',
    name: 'Quản lý lớp học',
    path: '/admin/classes',
    roles: ['administrator'],
    showInSidebar: true,
    sortOrder: 2,
    type: ['allowedRoutes'],
  },
  {
    collapse: true,
    path: '/admin/report',
    name: 'Báo cáo, thống kê',
    miniName: 'C',
    icon: <TbReportAnalytics size={20} style={{ color: '#AC81FF' }} />,
    roles: ['administrator', 'teacher'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 4,
    state: 'lms/admin/report',
    views: [
      {
        path: '/admin/week',
        name: 'Báo cáo theo tuần',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportWeek')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
      {
        path: '/admin/final',
        name: 'Báo cáo tổng kết',
        miniName: 'D',
        component: React.lazy(() => import('pages/Administration/Reports/ReportFinal')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },
  {
    path: '/admin/import-question',
    name: 'Quản lý câu hỏi',
    miniName: 'C',
    icon: 'fas fa-question text-green',
    component: IndexImportQuestion,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 0,
  },
  {
    component: Teacher_Classes_Details,
    exact: true,
    layout: '/lms',
    path: '/admin/classes/:classId',
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/users',
    name: 'Quản lý người dùng',
    miniName: 'C',
    icon: 'fa fa-user text-red',
    component: Users,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 0,
  },
  {
    path: '/admin/quan-ly-khoa-nganh',
    name: 'Danh mục Khoa, Ngành',
    miniName: 'C',
    icon: <FaThList color='#5E72E4' />,
    component: IndexManageKhoaNganh,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 0,
  },
  {
    path: '/admin/danh-muc-khoa-hoc',
    name: 'Danh mục khoá học',
    miniName: 'C',
    icon: <FaThList color={'#FAAD14'} />,
    component: ManagerCourse,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 1,
  },
  {
    path: '/admin/danh-muc-nien-khoa',
    name: 'Danh mục niên khoá',
    miniName: 'C',
    icon: <FaThList color={'#f310cd'} />,
    component: IndexManageSchoolYear,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 2,
  },
  // {
  //   path: '/admin/quan-ly-thong-bao',
  //   name: 'Thiết lập thông báo',
  //   miniName: 'C',
  //   icon: '	fa fa-bell',
  //   component: ManageNotification,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['administrationRouters'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },
  //
  // {
  //   path: '/admin/import-question',
  //   name: 'Import Questions',
  //   miniName: 'C',
  //   icon: '	fa fa-bell',
  //   component: IndexImportQuestion,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['administrationRouters'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },
  {
    path: '/admin/insert-type',
    name: 'Type & Options Type',
    miniName: 'C',
    icon: <FaThList color='#2DCE89' />,
    component: IndexCategoryType,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 0,
  },
  // {
  //   path: '/admin/add-question',
  //   name: 'Quản lý câu hỏi',
  //   miniName: 'C',
  //   component: IndexAddQuestion,
  //   icon: 'fas fa-book text-green',
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['allowedRoutes'],
  //   showInSidebar: true,
  // },
  // {
  //   path: '/admin/students',
  //   name: 'Quản lý học viên',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Administration/Vimaru/StudentManagement')),
  //   icon: <ClassIcon size={20} className='tw-text-yellow-500' />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['allowedRoutes'],
  //   showInSidebar: true,
  //   sortOrder: 1,
  // },

  {
    path: '/admin/presentations',
    name: 'Quản lý bài giảng',
    miniName: 'C',
    component: React.lazy(() => import('pages/Teacher/Presentations')),
    icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500' />,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
  },

  {
    path: '/admin/presentation/:presentationId/create',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/presentation/:presentationId/edit',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    collapse: true,
    path: '/admin/worksheets',
    name: 'Quản lý thư viện',
    miniName: 'C',
    icon: <IoLibraryOutline size={20} className='tw-text-green-600' />,
    roles: ['administrator'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
    state: 'lms/admin/worksheets',
    views: [
      {
        path: '/admin/worksheets/list/:filter',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
      },
      // {
      //   path: '/admin/worksheets/list/quizcity-manual',
      //   name: 'Bài tập Manual',
      //   miniName: 'D',
      //   component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
      //   layout: '/lms',
      //   exact: true,
      //   roles: ['administrator'],
      //   type: ['allowedRoutes'],

      //   showInSidebar: true,
      //   sortOrder: 0,
      // },
      {
        path: '/admin/worksheets/list/quizcity-worksheet',
        name: 'Bài tập Worksheet',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],

        showInSidebar: true,
        sortOrder: 1,
      },
      {
        path: '/admin/worksheets/list/quizcity-quiz',
        name: 'Bài tập Quiz',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: '/lms',
        exact: true,
        roles: ['administrator'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
      // {
      //   path: '/admin/worksheets/list/quizcity-exercise',
      //   name: 'Thư viện đề',
      //   miniName: 'D',
      //   component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
      //   layout: '/lms',
      //   exact: true,
      //   roles: ['administrator'],
      //   type: ['allowedRoutes'],
      //   showInSidebar: true,
      //   sortOrder: 1,
      // },
    ],
  },

  // {
  //   path: '/admin/assigments',
  //   name: 'Quản lý bài tập',
  //   miniName: 'C',
  //   component: AssignmentsManagement,
  //   icon: 'fas fa-book text-green',
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['allowedRoutes'],
  //   showInSidebar: true,
  //   sortOrder: 4,
  // },

  // {
  //   path: '/admin/quizcity/worksheets/update',
  //   name: 'Tạo worksheet',
  //   miniName: 'C',
  //   component: React.lazy(() => import('pages/Teacher/QuizCity/UpdateWorkSheet')),
  //   icon: <ClassIcon size={20} className='tw-text-blue-600' />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   showInSidebar: true,
  //   sortOrder: 0,
  // },
  // {
  //   path: '/admin/quizcity/worksheets/update/:id',
  //   component: React.lazy(() => import('pages/Teacher/QuizCity/UpdateWorkSheet')),
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  // },

  // {
  //   path: '/admin/classes/:classId',
  //   component: Teacher_Classes_Details,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['allowedRoutes'],
  // },

  {
    path: '/admin/classes/:classId/classwork/assignments/add',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/edit/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/details/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/Details')),
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/configs',
    name: 'Thiết lập thông tin',
    component: ConfigSettings,
    icon: <FaCogs style={{ color: '#EC4899' }} />,
    layout: '/lms',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 8,
  },
  // {
  //   path: '/admin/roles',
  //   name: 'Roles',
  //   component: IndexManageRoles,
  //   icon: <FaUsers style={{ color: '#5EB7B7' }} />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['rolesRouters'],
  //   showInSidebar: true,
  //   sortOrder: 6,
  // },
  // {
  //   path: '/admin/function',
  //   name: 'Chức năng',
  //   component: IndexManageFunction,
  //   icon: <RiFunctionFill style={{ color: '#D77FA1' }} />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['rolesRouters'],
  //   showInSidebar: true,
  //   sortOrder: 8,
  // },
  // {
  //   path: '/admin/permissions',
  //   name: 'Phân quyền',
  //   component: IndexManagePermissions,
  //   icon: <FaBalanceScale style={{ color: '#FC7978' }} />,
  //   layout: '/lms',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['rolesRouters'],
  //   showInSidebar: true,
  //   sortOrder: 7,
  // },
].concat(URL_HuongDanSuDung ? [fileHelp] : []);
