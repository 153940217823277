import settings from 'constants/settings';
import CodesExam from 'pages/Teacher/CodesExam/index';
import React from 'react';
import { HiClipboardList } from 'react-icons/hi';
import { MdDoneOutline, MdGroup as ClassIcon, MdOutlineEventNote, MdClass } from 'react-icons/md';
import { HiOutlinePresentationChartBar } from 'react-icons/hi';
import { SiGoogleclassroom } from 'react-icons/si';
import { IoLibraryOutline } from 'react-icons/io5';

export default [
  {
    path: '/admin/presentations',
    name: 'Quản lý bài giảng',
    miniName: 'C',
    component: React.lazy(() => import('pages/Teacher/Presentations')),
    icon: <HiOutlinePresentationChartBar size={20} className='tw-text-blue-500' />,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
  },
  {
    collapse: true,
    path: '/admin/worksheets',
    name: 'Quản lý thư viện',
    miniName: 'C',
    icon: <IoLibraryOutline size={20} className='tw-text-green-600' />,
    roles: ['teacher'],
    type: ['allowedRoutes'],
    showInSidebar: true,
    sortOrder: 3,
    state: 'lms/admin/worksheets',
    views: [
      {
        path: '/admin/worksheets/list/:filter',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['teacher'],
        type: ['allowedRoutes'],
      },
      {
        path: '/admin/worksheets/list/quizcity-worksheet',
        name: 'Bài tập Handout',
        miniName: 'D',
        component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
        layout: `/${settings.PATHNAME}`,
        exact: true,
        roles: ['teacher'],
        type: ['allowedRoutes'],
        showInSidebar: true,
        sortOrder: 1,
      },
      // {
      //   path: '/admin/worksheets/list/quizcity-quiz',
      //   name: 'Bài tập Quiz',
      //   miniName: 'D',
      //   component: React.lazy(() => import('pages/Teacher/QuizCity/WorkSheets')),
      //   layout: `/${settings.PATHNAME}`,
      //   exact: true,
      //   roles: ['administrator'],
      //   type: ['allowedRoutes'],
      //   showInSidebar: true,
      //   sortOrder: 1,
      // },
    ],
  },
  {
    path: '/student/codeExam/presentations/:presentationId/slides/:slideId',
    component: React.lazy(() => import('pages/AmesOnline/index')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher', 'manager', 'administrator'],
    type: ['allowedRoutes'],
  },
  {
    path: '/student/presentations/:presentationId/slides/:slideId/questions/:questionId',
    component: React.lazy(() => import('pages/Student/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher', 'manager', 'administrator'],
    type: ['allowedRoutes'],
  },
  {
    path: '/student/presentations/:presentationId/slides/:slideId/questions/result',
    component: React.lazy(() => import('pages/Student/Presentation')),
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher', 'manager', 'administrator'],
    type: ['allowedRoutes'],
  },
  {
    // path: '/instructstudentstouse',
    name: 'Hướng dẫn sử dụng',
    href: 'https://cloud.softech.vn/storage/Data/QUIZCITY/Handout/Huong-dan-su-dung-cho-giao-vien.pdf',
    icon: <HiClipboardList size={20} className='tw-text-blue-500' />,
    layout: `/${settings.PATHNAME}`,
    exact: true,
    roles: ['teacher'],
    showInSidebar: true,
    type: ['allowedRoutes'],
    sortOrder: 9999,
  },
];
