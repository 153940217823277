import { Checkbox, Col, Form, Input } from 'antd';
import React from 'react';
import Layout from '../../Layouts/Layout';

const IndexMultipleChoice = ({ form, field, setAddField, optionType }) => {
  const RenderLayoutAnswer = () => {
    switch (optionType.toUpperCase()) {
      case 'READANSWER':
        return (
          <Layout
            field={field}
            layout={['checkbox', 'input']}
            setAddField={setAddField}
            form={form}
            autoFocus={true}
            lg={21}
          />
        );

      default:
        return <></>;
    }
  };
  return <RenderLayoutAnswer />;
};

export default IndexMultipleChoice;
