/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import _ from 'lodash';
import colors from 'constants/colors';
import { FaGalacticSenate } from 'react-icons/fa';
import { IS_HANDOUT, IS_PT } from 'constants/settings';

function Write({ type, data, againAnswer, isDisableTest, isHiddenShowColor }) {
  const [result, setResult] = useState(null);
  const [state, setState] = useState([]);
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    Object.assign(type, {
      submit: () => {
        // handle submit
        if (data.length === 0) return {};
        // const res = handleResult(state);
        const res = handleResultByGroup(state);
        // setResult(res);
        // setIsDone(true);
        return { writeResult: res };
      },
      tryAgain: () => {
        setState(formatData(state));
        // setResult(null);
        // setIsDone(false);
      },
      setResult,
    });
    return () => {};
  }, [data, state, type]);

  useEffect(() => {
    setState(formatData(againAnswer?.userData));
  }, [data, againAnswer]);

  //data đang là mảng phẳng, cần format về dạng gom nhóm để render
  const formatData = (answers) => {
    if (!data) {
      return [];
    }
    const floatArray = answers ? _.flatten(answers) : [];
    //Nếu có kết quả làm bài thì tìm input tương ứng để lấy đáp án
    const newData = answers
      ? data.map((item) => {
          const userAnswer = floatArray.find((i) => i.left === item.left && i.top === item.top);
          return userAnswer ?? item;
        })
      : _.cloneDeep(data);
    //gom nhóm các input cùng group
    return newData;
  };
  //
  const hanleInputChange = useCallback(
    (index, value) => {
      if (isDisableTest) return;
      setState((pre) => {
        const arrayState = Array.from(pre);
        Object.assign(arrayState[index], { userAnswer: value });
        return arrayState;
      });
    },
    [isDisableTest],
  );
  if (!data) return null;

  return (
    <div>
      {state.map((item, index) => {
        const { width, height, top, left, text, groupName, angle, isCorrect } = item;

        let color = colors.font;
        if (isDisableTest && !isHiddenShowColor) {
          if (isCorrect) {
            color = colors.theme.success;
          } else if (isCorrect === false) {
            color = colors.theme.danger;
          }
        }

        return (
          <div
            key={`input-${index}`}
            style={{ position: 'absolute', width, height, top, left, transform: `rotate(${angle}deg)` }}
          >
            <Input
              value={item.userAnswer}
              onChange={(e) => {
                hanleInputChange(index, e.target.value);
              }}
              disabled={isDisableTest}
              style={{
                width: `${width}px`,
                height: `${height}px`,
                fontWeight: 600,
                fontSize: height * 0.5,
                borderColor: color,
                borderWidth: 2,
                color,
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

Write.propTypes = {
  type: PropTypes.object,
  data: PropTypes.array,
};

Write.defaultProps = {
  type: {},
  data: [],
};

export default Write;

// #region Tính điểm theo nhóm (sai 1 chỗ, vẫn tính điểm chỗ khác)
const handleResultByGroup = (userData) => {
  const convert = (value = '') =>
    value
      .trim()
      .toLowerCase()
      .replace(/(\W+)$/g, ''); // ko phân biệt hoa thường, bỏ dấu cuối câu
  const newState = userData.map((item) => {
    const arrayAnswer = item.text.split('|');
    item.isCorrect = arrayAnswer.some((key) => convert(item.userAnswer) === convert(key));
    return item;
  });

  const groups = _.groupBy(newState, 'groupName');

  // #region Tính điểm.
  const resultByGroup = _.map(groups, (item) => {
    const correctByGroup = _.countBy(item, 'isCorrect').true ?? 0;
    const totalByGroup = item.length;
    const score = correctByGroup / totalByGroup;
    return { groupName: item[0].groupName, score };
  });
  // #endregion

  const correct = resultByGroup
    .reduce((previousValue, currentValue) => previousValue + currentValue.score, 0)
    .toFixed(2);
  const total = resultByGroup.length;

  const percent = parseInt((correct * 100) / total);
  const resultString = `${correct}/${total}`;
  const star = percent / 20;
  //
  const count = Object.values(groups).filter((group) => group.some((x) => x.userAnswer)).length;
  const complete = `${count}/${total}`;

  return { userData: newState, percent, resultString, star, complete };
};
