import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import withTeacherAuthenticator from 'HOCs/withTeacherAuthenticator';
import { Button, Descriptions, Dropdown, Menu, Modal, Popconfirm, Space, Tooltip, message, notification } from 'antd';
import Card from 'components/Containers/Card';
import colors from 'constants/colors';
import settings, { IS_BASAO, IS_TRONGDONG } from 'constants/settings';
import { execute, query, queryFirst } from 'helpers/QueryHelper';
import i18next from 'i18next';
import ClassUsers from 'modules/Administration/components/Users/classUsers';
import Users from 'modules/Administration/components/Users/index';
import Update from 'modules/Class/components/Update';
import { getStudentsOrStudentByClassId } from 'modules/Teacher/api/getStudentsOrStudentByClassId';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdArrowBack } from 'react-icons/md';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { LoggedInUserType } from 'types';
import searchForm from './searchForm';
import usersTable from './usersTable';

const fetcher = (classId: string) => () => {
  return queryFirst('p_VIMARU_CLASSES_GetById', { id: classId });
};
const fetchCourses = () => {
  return query('p_AMES247_Course_Select');
};
const fetchSchoolYear = () => {
  return query('p_SACHSO_METADATA_NienKhoa_List');
};
type ClassInfomationHeaderProps = {
  classId: string;
  loggedInUser: LoggedInUserType;
  showActions: boolean;
};

const ClassInfomationHeader = ({ classId, loggedInUser, showActions }: ClassInfomationHeaderProps) => {
  const { t } = useTranslation();
  // HOOKS
  const history = useHistory();
  const queryClient = useQueryClient();
  const isAdmin =
    loggedInUser.role === 'administrator' ||
    loggedInUser.role === 'manager' ||
    ((settings.IS_MENARD || settings.IS_LG|| IS_BASAO || IS_TRONGDONG) && loggedInUser.role === 'teacher');

  const QUERY_KEY = ['p_VIMARU_CLASSES_GetById', classId];
  const [updateModalVisible, setUpdateModalVisible] = React.useState(false);
  const [addTeacherModalVisible, setAddTeacherModalVisible] = React.useState<boolean>(false);
  const [teacherId, setTeacherId] = React.useState<number>();
  const [addStudentsModalVisible, setAddStudentsModalVisible] = React.useState<boolean>(false);
  const [deleteStudentsModalVisible, setDeleteStudentsModalVisible] = React.useState<boolean>(false);
  const [studentsId, setStudentsId] = React.useState<number[]>([]);
  const [addingStudentsId, setAddingStudentsId] = React.useState<number[]>([]);
  const [deletingStudentsId, setDeletingStudentsId] = React.useState<number[]>([]);
  const { data, isSuccess } = useQuery(QUERY_KEY, fetcher(classId));
  const { data: courses } = useQuery('p_AMES247_Course_Select', () => fetchCourses());
  const { data: schoolYearOptions } = useQuery('p_SACHSO_METADATA_NienKhoa_List', () => fetchSchoolYear());
  const schoolYear = schoolYearOptions?.map((year) => ({ label: year?.name, value: year?.id }));
  const currentCourse = courses?.find((x) => x?.id === data?.courseId);
  const handleDelete = async () => {
    await execute('p_VIMARU_CLASSES_DeleteClass', { classId: data.id });
    message.success({
      content: `Đã xóa lớp có mã: ${data.code}`,
      duration: 0.5,
      onClose: () => {
        window.history.go(-1);
      },
    });
  };
  const title = (
    <Space align='center'>
      <Button
        type='text'
        icon={<MdArrowBack size={24} />}
        onClick={() => {
          let router = window.location.pathname;
          router = router.split('/');
          router = router.filter((item, index) => item !== '' && index < router.length - 1);
          let targetRouter = '';
          router.forEach((item) => (targetRouter += '/' + item));
          history.push(targetRouter);
        }}
      />
      <div style={{ alignItem: 'center' }}>
        {settings.IS_LG ? <div className='title-color'>Class Information</div> : 'THÔNG TIN LỚP HỌC'}
      </div>
    </Space>
  );

  const extra = (
    <React.Fragment>
      <Dropdown
        overlay={
          <Menu
            onClick={(info) => {
              switch (info.key) {
                case 'edit':
                  setUpdateModalVisible(true);
                  break;
                case 'teacher':
                  setAddTeacherModalVisible(true);
                  break;
                case 'add-students':
                  setAddStudentsModalVisible(true);
                  break;
                case 'delete-students':
                  setDeleteStudentsModalVisible(true);
                  break;
                default:
                  break;
              }
            }}
          >
            <Menu.Item key='edit' icon={<EditOutlined />}>
              {i18next.t('EditInfor')}
            </Menu.Item>
            {!(settings.IS_LG && loggedInUser.role !== 'administrator') && (
              <Menu.Item key='teacher' icon={<UserOutlined />}>
                {i18next.t('AddTeacher')}
              </Menu.Item>
            )}

            <Menu.Item key='add-students' icon={<TeamOutlined />}>
              {i18next.t('Add') + ' ' + settings.studentPronouns1}
            </Menu.Item>
            <Menu.Item key='delete-students' icon={<TeamOutlined />}>
              {i18next.t('Delete') + ' ' + settings.studentPronouns1}
            </Menu.Item>
            <Menu.Divider />
            <Popconfirm
              okText={t('Confirm')}
              cancelText={t('Close')}
              placement='topRight'
              showArrow={false}
              title={i18next.t('Are you sure to delete', { object: i18next.t('class') })}
              onConfirm={() => {
                handleDelete();
              }}
            >
              <Menu.Item danger key='delete' icon={<DeleteOutlined />}>
                {/* {settings.IS_LG ? 'Remove Class' : 'Xóa lớp học'} */}
                {i18next.t('DeleteClass')}
              </Menu.Item>
            </Popconfirm>
          </Menu>
        }
        trigger={['click']}
      >
        <Button icon={<MoreOutlined />} type='default' shape='circle' />
      </Dropdown>
    </React.Fragment>
  );

  // #region Chọn giáo viên đứng lớp.
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setTeacherId(selectedRowKeys[0] as number);
    },
    selectedRowKeys: teacherId ? [teacherId] : [], // Giáo viên được chọn đứng lớp.
  };

  const rowSelectionUsersTable = {
    type: 'radio',
    ...rowSelection,
  };
  // #endregion

  // #region Chọn học sinh vào lớp.
  const rowStudentsSelection = {
    selectedRowKeys: studentsId,
    onChange: (newSelectedRowKeys) => {
      setStudentsId(newSelectedRowKeys);
    },
  };

  const deletingRowStudentsSelection = {
    selectedRowKeys: deletingStudentsId,
    onChange: (newSelectedRowKeys) => {
      setDeletingStudentsId(newSelectedRowKeys);
    },
  };
  const addingRowStudentsSelection = {
    selectedRowKeys: addingStudentsId,
    onChange: (newSelectedRowKeys) => {
      setAddingStudentsId(newSelectedRowKeys);
    },
  };

  const deletingRowSelectionStudentsTable = {
    type: 'checkbox',
    ...deletingRowStudentsSelection,
  };
  const addingRowSelectionStudentsTable = {
    type: 'checkbox',
    ...addingRowStudentsSelection,
  };

  // #endregion

  React.useEffect(() => {
    // Giáo viên.
    if (data) {
      setTeacherId(parseInt(data.teacherId));
    }

    // Học sinh đã có trong lớp.
    if (addStudentsModalVisible || deleteStudentsModalVisible) {
      getStudentsOrStudentByClassId({classId:Number(classId), pageNum:1, pageSize: 1000,search:''}).then((response) => {
        const studentsInClass = response.map((item: { id: number }) => item.id);
        setStudentsId(studentsInClass);
      });
    }

    return () => {
      setTeacherId(null);
      setStudentsId([]);
    };
  }, [addStudentsModalVisible, deleteStudentsModalVisible, classId, data]);

  if (!data) return null;

  return (
    <React.Fragment>
      <Update
        classId={classId}
        visible={updateModalVisible}
        onOk={() => {
          setUpdateModalVisible(false);
          queryClient.invalidateQueries(QUERY_KEY);
        }}
        onCancel={() => {
          setUpdateModalVisible(false);
        }}
        loggedInUser={loggedInUser}
        courses={courses}
        schoolYear={schoolYear}
      />

      <Modal
        centered
        title={i18next.t('pages:classInformation.Teacher list')}
        open={addTeacherModalVisible}
        onOk={() => {
          query('p_VIMARU_AddTeacher_IntoClass', { classId, teacherId })
            .then((response) => {
              notification.success({
                message: i18next.t('notification'),
                description: i18next.t('add success', { field: i18next.t('table:notification.Teacher') }),
              });
              queryClient.invalidateQueries(QUERY_KEY);
            })
            .catch((error) => {
              // console.log('🚀 ~ file: ClassInfomationHeader.tsx ~ query ~ error', error);
              notification.error({
                message: i18next.t('notification'),
                description: i18next.t('add failed', { field: i18next.t('table:notification.Teacher') }),
              });
            });
          setTimeout(() => {
            setAddTeacherModalVisible(false);
          }, 1200);
        }}
        onCancel={() => {
          setAddTeacherModalVisible(false);
        }}
        width='90%'
        bodyStyle={{ paddingBottom: 0 }}
      >
        <Users
          isActive
          isTeacherRole
          paginationUsersTable={['bottomRight']}
          rowSelectionUsersTable={rowSelectionUsersTable}
          searchForm={searchForm}
          usersTable={usersTable}
          exportExcel={true}
        />
      </Modal>

      <Modal
        centered
        title={i18next.t('Add') + ' ' + settings.studentPronouns1}
        open={addStudentsModalVisible}
        okText={i18next.t('Add')}
        onOk={() => {
          // console.log(addingStudentsId);
          query('p_VIMARU_AddStudent_IntoClass', {
            classId,
            studentsId: addingStudentsId?.join(','),
            courseId: data.courseId,
          })
            .then((res) => {
              if (res?.[0]?.message) {
                notification.error({
                  description: res?.[0]?.message,
                  message: i18next.t('Failed'),
                });
              } else {
                notification.success({
                  description: i18next.t('add success', { field: i18next.t('table:notification.Student') }),
                  message: i18next.t('notification'),
                });
                queryClient.invalidateQueries(QUERY_KEY); // Refresh sỉ số học sinh.
                queryClient.invalidateQueries('p_VIMARU_STUDENTS_GetStudents_OrStudent_ByClassId'); // Refresh danh sách học sinh.
                setAddingStudentsId([]);
                setTimeout(() => {
                  setAddStudentsModalVisible(false);
                }, 500);
              }
            })
            .catch((error) => {
              // console.log('🚀 ~ file: ClassInfomationHeader.tsx ~ query ~ error', error);
              notification.error({
                description: i18next.t('update success', { field: i18next.t('table:notification.Student') }),
                message: i18next.t('notification'),
              });
            });
        }}
        onCancel={() => {
          setAddStudentsModalVisible(false);
        }}
        width='90%'
        bodyStyle={{ paddingBottom: 0 }}
      >
        <ClassUsers
          isActive
          paginationUsersTable={['bottomRight']}
          rowSelectionUsersTable={addingRowSelectionStudentsTable}
          searchForm={searchForm}
          usersTable={usersTable}
          exportExcel={true}
          studentsId={studentsId}
          mode='ADD'
        />
      </Modal>
      <Modal
        centered
        title={i18next.t('Remove') + ' ' + settings.studentPronouns1}
        open={deleteStudentsModalVisible}
        // okType='danger'
        okText={i18next.t('Remove')}
        // okButtonProps={{ style: colors.font }}
        onOk={() => {
          // console.log(deletingStudentsId);
          query('p_VIMARU_DeleteStudent_InClass', {
            classId,
            studentsId: deletingStudentsId?.join(','),
            // courseId: data.courseId,
          })
            .then((res) => {
              if (res?.[0]?.message) {
                notification.error({
                  description: res?.[0]?.message,
                  message: i18next.t('Failed'),
                });
              } else {
                notification.success({
                  description: i18next.t('Delete success', { object: i18next.t('table:notification.Student') }),
                  message: i18next.t('notification'),
                });
                queryClient.invalidateQueries(QUERY_KEY); // Refresh sỉ số học sinh.
                queryClient.invalidateQueries('p_VIMARU_STUDENTS_GetStudents_OrStudent_ByClassId');
                setDeletingStudentsId([]); // Refresh danh sách học sinh bị xóa.
                setTimeout(() => {
                  setDeleteStudentsModalVisible(false);
                }, 500);
              }
            })
            .catch((error) => {
              // console.log('🚀 ~ file: ClassInfomationHeader.tsx ~ query ~ error', error);
              notification.error({
                description: i18next.t('update failed', { field: i18next.t('table:notification.Student') }),
                message: i18next.t('notification'),
              });
            });
        }}
        onCancel={() => {
          setDeleteStudentsModalVisible(false);
        }}
        width='90%'
        bodyStyle={{ paddingBottom: 0 }}
      >
        <ClassUsers
          isActive
          paginationUsersTable={['bottomRight']}
          rowSelectionUsersTable={deletingRowSelectionStudentsTable}
          searchForm={searchForm}
          usersTable={usersTable}
          exportExcel={true}
          studentsId={studentsId}
          mode='DELETE'
        />
      </Modal>

      <Card title={title} extra={isAdmin && showActions && extra}>
        {isSuccess ? (
          <Descriptions
            labelStyle={{ color: colors.font, fontWeight: 600, whiteSpace: 'nowrap', width: '1%' }}
            layout='horizontal'
            bordered
            column={{ lg: 3, md: 2, sm: 1, xs: 1 }}
            size='small'
          >
            <Descriptions.Item label={i18next.t('table:classroomManagement.Class code')}>
              <Space>
                {data.code}
                <Tooltip title={i18next.t('table:classroomManagement.Click to copy')}>
                  <Button
                    type='dashed'
                    size='small'
                    shape='circle'
                    icon={<CopyOutlined />}
                    onClick={() => {
                      window.navigator.clipboard.writeText(data.code);
                      message.success({
                        content: i18next.t('table:classroomManagement.Copied class code'),
                        duration: 1.5,
                      });
                    }}
                  />
                </Tooltip>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label={i18next.t('table:classroomManagement.Class name')}>
              {data.className}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                settings.IS_VIMARU ? 'Số lượng sinh viên' : i18next.t('table:classroomManagement.Number of students')
              }
            >{`${data.numberOfStudent} (${settings.studentPronouns1})`}</Descriptions.Item>
            {settings.IS_LG ? (
              <></>
            ) : (
              <>
                <Descriptions.Item label={i18next.t('table:classroomManagement.Teacher')}>
                  {data.teacherName}
                </Descriptions.Item>
                <Descriptions.Item label={i18next.t('table:classInformation.Phone number')}>
                  {data.teacherPhone ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label='Email'>{data.teacherEmail ?? ''}</Descriptions.Item>
              </>
            )}
            <Descriptions.Item label={i18next.t('table:classroomManagement.Course')}>
              {currentCourse?.title ?? ''}
            </Descriptions.Item>
            {settings.IS_VIMARU && (
              <Descriptions.Item label='Niên khóa'>
                <b>{data?.name ?? ''}</b>
                {data?.name && (
                  <>
                    <br />
                    <b>Giai đoạn 1:</b> {moment(data?.giaiDoanI_FromDate)?.format('DD/MM/YYYY')}-
                    {moment(data?.giaiDoanI_ToDate)?.format('DD/MM/YYYY')} <br />
                    <b>Giai đoạn 2:</b> {moment(data?.giaiDoanII_FromDate)?.format('DD/MM/YYYY')}-
                    {moment(data?.giaiDoanII_ToDate)?.format('DD/MM/YYYY')}
                  </>
                )}
              </Descriptions.Item>
            )}
          </Descriptions>
        ) : <></>}
      </Card>
    </React.Fragment>
  );
};

ClassInfomationHeader.propTypes = {
  classId: PropTypes.string,
  loggedInUser: PropTypes.object,
  showActions: PropTypes.bool,
};

ClassInfomationHeader.defaultProps = {
  classId: '',
  loggedInUser: null,
  showActions: true,
};

export default withTeacherAuthenticator(ClassInfomationHeader);
