/* eslint-disable react/prop-types */
import withAuthenticator from 'HOCs/withAuthenticator';
import { IS_HANDOUT, IS_ISE, IS_LG, IS_PT, IS_TVHH } from 'constants/settings';
import React from 'react';
import ISEhome from './Student/ISEhome';
import LGhome from './Student/LGhome';
import StudentHome from './Student/Vimaru';
import HandoutHome from './Teacher/Handout/Home';
import PTTestHome from './Teacher/PTTest/Home';
// import TeacherHome from './Teacher/Home';

function HomePage({ loggedInUser }) {
  return (
    <React.Fragment>
      {IS_ISE || IS_TVHH ? (
        <ISEhome loggedInUser={loggedInUser} />
      ) : IS_LG ? (
        <LGhome loggedInUser={loggedInUser} />
      ) : IS_PT ? (
        <PTTestHome loggedInUser={loggedInUser} />
      ) : IS_HANDOUT ? (
        <HandoutHome loggedInUser={loggedInUser} />
      ) : (
        <StudentHome loggedInUser={loggedInUser} />
      )}
    </React.Fragment>
  );
}

export default withAuthenticator(HomePage);
