import Axios from 'axios';
import settings from 'constants/settings';
import { get, remove } from 'helpers/QueryHelper';

const instance = Axios.create({
  baseURL: 'https://server.sachso.edu.vn/api/v1.0/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
    ApplicationName: settings.APPLICATION_NAME,
  },
});

export const pdfToJPG = async (file, entityId) => {
  const DATA = new FormData();
  DATA.append('files', file);
  DATA.append('moduleName', 'SACHSO');
  DATA.append('subModuleName', 'QUIZCITY-WORKSHEET');
  DATA.append('entityName', 'Assignments');
  DATA.append('entityId', entityId);

  // entityName: t_SACHSO_LIVEWORKSHEET_Assignments
  // entityId: 358d8538-628d-471e-8ed5-5c337f4aa8cb
  let rs = null;
  try {
    const response = await instance.post('pdf/upload-files', DATA);
    if (response.data) {
      const { id, downloadUrl } = response.data.files[0];
      rs = { id, imgBase64: downloadUrl };
    } else {
      rs = false;
    }
  } catch (error) {
    return false;
  }
  return rs;
};

export const fileToURL = async (file, entityId) => {
  const DATA = new FormData();
  DATA.append('files', file);
  DATA.append('moduleName', 'SACHSO');
  DATA.append('subModuleName', 'QUIZCITY-WORKSHEET');
  DATA.append('entityName', 'Assignments');
  DATA.append('entityId', entityId);

  // entityName: t_SACHSO_LIVEWORKSHEET_Assignments
  // entityId: 358d8538-628d-471e-8ed5-5c337f4aa8cb
  let rs = null;
  try {
    const response = await instance.post('dynamic/upload-files', DATA);
    if (response.data) {
      const { id, downloadUrl, fileName } = response.data.files[0];
      rs = { id, audioURL: downloadUrl, fileName };
    } else {
      rs = false;
    }
  } catch (error) {
    return false;
  }
  return rs;
};

export const fetcher = async (entityId, id) => {
  const fields = '*';
  const parameters = {
    entityId,
    entityName: 't_SACHSO_LIVEWORKSHEET_Assignments',
    id,
  };
  const sort = 'CreatedDate';

  const response = await get('Attachments', fields, parameters, sort, 'SHARE', 'SACHSO');
  return response;
};

export const updateJsonData = async (id, JsonData, createdBy) => {
  if (!JsonData || JsonData === '{}') {
    console.error('🚀 ~ JsonData', JsonData);
    return message.error('Bạn đang insert dữ liệu rỗng ! Vui lòng thử lại hoặc liên hệ cho đội IT');
  } else {
    const DATA = {
      sqlCommand: '[dbo].[p_SACHSO_SHARE_Attachments_UpdateJsonData]',
      parameters: {
        id,
        JsonData,
        createdBy,
      },
    };
    const response = await instance.post('dynamic/execute', DATA);
    return response;
  }
};

export const removeFile = async (id) => {
  const response = await remove('Attachments', id, 'SHARE');
  return response;
};

export default { pdfToJPG, fetcher, updateJsonData, fileToURL };
