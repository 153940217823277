import { Button, Checkbox, Modal, Typography } from 'antd';
import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import i18next from 'i18next';
import { AUTH_LOGIN_SUCCESS } from 'modules/Auth/actions/types';
import React from 'react';
import { useDispatch } from 'react-redux';

export default function LearningCommitmentModal({ loggedInUser }) {
  const [modal, setModal] = React.useState(loggedInUser.learningCommitment ? false : true);
  const [isChecked, setIsChecked] = React.useState(false);
  const dispatch = useDispatch();

  const handleAgree = async () => {
    await query('p_QUIZCITY_User_LearningCommitment', {
      UserId: loggedInUser?.id,
    }).then(() => {
      dispatch({
        type: AUTH_LOGIN_SUCCESS,
        payload: {
          ...loggedInUser,
          learningCommitment: true,
        },
      });
      setModal(false);
    });
  };

  return (
    <>
      {(settings.IS_LG || settings.IS_ISE || settings.IS_TVHH) && loggedInUser.role == 'student' ? (
        <Modal
          // closable={false}
          onCancel={() => setModal(false)} // click nut X modal.
          maskClosable={false}
          width={700}
          title={[
            <Typography.Text style={{ display: 'block', textAlign: 'center', fontWeight: 'bold' }}>
              {/* LEARNING COMMITMENT  */}
              {i18next.t('LearnCommit').toUpperCase()}
            </Typography.Text>,
          ]}
          open={modal}
          centered
          onOk={() => setModal(false)}
          bodyStyle={{ fontSize: 16 }}
          footer={[
            <Button disabled={isChecked ? false : true} onClick={() => handleAgree()} type={'primary'}>
              {i18next.t('Confirm')}
            </Button>,
          ]}
        >
          <p className='modal-commitment' style={{ fontWeight: 500 }}>
            1. Complete <b>100%</b> of the course content (including theory videos, exercises and tests) based on
            learning journey.
          </p>
          <p className='modal-commitment' style={{ fontWeight: 500 }}>
            2. The maximum time to complete the course is <b>05 months.</b> After this time, the account will be
            suspended without being able to view the incomplete content.
          </p>
          <p className='modal-commitment' style={{ fontWeight: 500 }}>
            3. Any actions to copy the course content in any forms and share it with third parties (take pictures, video
            lessons ...) are <b>prohibited.</b>
          </p>
          <p className='modal-commitment' style={{ fontWeight: 500 }}>
            4. Do not use learning account of others, do not study for others, complete tests for others.
          </p>
          <p className='modal-commitment' style={{ fontWeight: 500 }}>
            5. It is strictly forbidden to use the same account for many people (>= 2 people)
          </p>
          <p className='modal-commitment' style={{ fontWeight: 500 }}>
            6. In case there is any technical or course error, learners are responsible for responding in the Support
            section in the website for support, absolutely do not fix error by yourself which may lead to negative
            impacts on the website.
          </p>

          <Checkbox onChange={() => setIsChecked(!isChecked)} style={{ fontWeight: 'bold' }}>
            I have read and agreed to the content of the learning commitment.
          </Checkbox>
        </Modal>
      ) : (
        <React.Fragment />
      )}
    </>
  );
}
