/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';
import { Row, Col } from 'antd';
import { hanhtrangsoClient } from 'configs/api';
import withAuthenticator from 'HOCs/withAuthenticator';
import Loading from 'components/Loading';
import TeacherContainer from 'components/Containers/TeacherContainer';
import StudentContainer from 'components/Containers/StudentContainer';
import { useHistory, useParams } from 'react-router';
import Book from '../Book';

import allBooks from '../allBooks';
import demoBooks from '../demoBooks';
import demoBooksV2 from '../demoBooksV2';
import demoBooksV3 from '../demoBooksV3Hcm';
import auth from './auth';
import AdministratorContainer from 'components/Containers/AdministratorContainer';

const fetcher = (loggedInUser) => () => {
  return hanhtrangsoClient.get(`/SachSo/getActivatedBooksByEmail?email=${loggedInUser.email}`);
};

function Books({ loggedInUser }) {
  // const params = useParams();
  // const classCurrent = params['0']?.replace(/:/g, '');
  // //
  // const isTeacher = loggedInUser.role === 'teacher';
  // const isStudent = loggedInUser.role === 'student';
  // let books = isTeacher ? _.filter(allBooks, (x) => x.schoolTypeId === loggedInUser.schoolTypeId) : [];
  // if (isTeacher) {
  //   // cho phép giáo viên xem các sách được chỉ định.
  //   books = _.concat(books, demoBooks, demoBooksV2).filter((x) => x.schoolTypeId === loggedInUser.schoolTypeId); // lọc theo cấp 1, 2, 3
  //   books = _.sortBy(books, ['sortOrder']).filter((x) => !x.isNew); // sách mới chưa được phép xem
  // }
  // const permission = auth.find((x) => x.email === loggedInUser.email);
  // if (permission) {
  //   if (permission.limited) {
  //     books = _.concat(allBooks, demoBooks, demoBooksV2).filter((x) => permission.classId.includes(x.classId));
  //   } else {
  //     books = _.sortBy(_.concat(allBooks, demoBooks, demoBooksV2), ['sortOrder']);
  //   }
  // }

  // const { data, isLoading, isSuccess } = useQuery(['getActivatedBooksByEmail', loggedInUser.email], fetcher(loggedInUser), {
  //   enabled: isStudent,
  // });

  // if (isSuccess && isStudent && !permission) {
  //   const activatedBooks = data.data.data;
  //   const list = _.map(activatedBooks, (book) => {
  //     return _.find(allBooks, (x) => x.id === book.bookId);
  //   }).filter((book) => book);
  //   books = _.sortBy(list, ['sortOrder']);
  // }
  const isAdmin = loggedInUser.role === 'administrator' || loggedInUser.role === 'manager';
  let books = demoBooksV3;
  const history = useHistory();
  // if (!isAdmin) {
  //   history.push('lms/home');
  // }
  return (
    <React.Fragment>
      {/* <AdministratorContainer> */}
      <Row gutter={[24, 24]}>
        {/* Riêng tài khoản "joya.dolly@gmail.com" id === 40515 là mở tất cả sách */}
        {/* Riêng tài khoản "preschool1@gmail.com" id === 38880 là mở 1 sách mầm non*/}
        {loggedInUser.id === 40515 &&
          books &&
          books.map((item) => {
            return (
              <Col key={`book-${item.id}`} xxl={6} lg={8} md={8} sm={12} xs={24}>
                <Book
                  item={item}
                  onClick={() => {
                    // eslint-disable-next-line no-undef
                    window.open(`${item.externalLink}/userid/${loggedInUser?.id}/bookid/${item.id}`, '_blank');
                  }}
                />
              </Col>
            );
          })}
        {loggedInUser.id !== 40515 &&
          loggedInUser.id !== 38880 &&
          books
            // .filter((x) => `${x.classId}` === classCurrent)
            .map((item) => {
              return (
                <Col key={`book-${item.id}`} xxl={6} lg={8} md={8} sm={12} xs={24}>
                  <Book
                    item={item}
                    onClick={() => {
                      // eslint-disable-next-line no-undef
                      window.open(`${item.externalLink}/userid/${loggedInUser?.id}/bookid/${item.id}`, '_blank');
                    }}
                  />
                </Col>
              );
            })}
        {loggedInUser.id === 38880 && books && (
          <Col key={`book-${books[0].id}`} xxl={6} lg={8} md={8} sm={12} xs={24}>
            <Book
              item={books[0]}
              onClick={() => {
                // eslint-disable-next-line no-undef
                window.open(`${books[0].externalLink}/userid/${loggedInUser?.rowId}/bookid/${books[0].id}`, '_blank');
              }}
            />
          </Col>
        )}
      </Row>
      {/* </AdministratorContainer> */}
      {/* <StudentContainer>
        {permission ? '' : isLoading && <Loading />}
        <Row gutter={[24, 24]}>
          {books &&
            books.map((item) => {
              return (
                <Col key={`book-${item.id}`} xxl={6} lg={8} md={8} sm={12} xs={24}>
                  <Book
                    item={item}
                    onClick={() => {
                      // eslint-disable-next-line no-undef
                      window.open(`${item.externalLink}/userid/${loggedInUser?.rowId}/bookid/${item.id}`, '_blank');
                    }}
                  />
                </Col>
              );
            })}
        </Row>
      </StudentContainer> */}
    </React.Fragment>
  );
}

export default withAuthenticator(Books);
