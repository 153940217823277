import { UserOutlined } from '@ant-design/icons';
import colors from 'constants/colors';
import settings from 'constants/settings';
import StudentsEssay from './Essay';
import i18next from 'i18next';

// p_SACHSO_Students_GetByClassId
export default {
  rowKey: 'studentId',
  displayColumns: [
    {
      title: i18next.t('studentCode'),
      key: 'studentId',
      width: '1%',
      render: 'renderNoWrap',
      // style: {
      //   fontWeight: 600,
      // },
      prefix: <UserOutlined className='tw-mr-2' />,
    },
    {
      title: i18next.t('pupil'),
      key: 'studentName',
      width: '1%',
      render: 'renderNoWrap',
      style: {
        fontWeight: 600,
      },
    },
    {
      title: i18next.t('AverageScore'),
      key: 'avgScore',
      width: '1%',
      children: [
        {
          title: 'SB',
          titleStyle: { textAlign: 'center' },
          key: 'sbScore',
          width: '1%',
          render: 'renderNumber',
          formatString: '0.00',
          style: {
            fontWeight: 600,
            color: colors.theme.danger,
          },
          formatConditions: [
            {
              condition: 'record.sbScore >= 6.5',
              style: {
                color: colors.theme.success,
              },
            },
          ],
        },
        {
          title: 'WB',
          titleStyle: { textAlign: 'center' },
          key: 'wbScore',
          width: '1%',
          render: 'renderNumber',
          formatString: '0.00',
          style: {
            fontWeight: 600,
            color: colors.theme.danger,
          },
          formatConditions: [
            {
              condition: 'record.wbScore >=  6.5',
              style: {
                color: colors.theme.success,
              },
            },
          ],
        },
      ],
    },
    {
      title: i18next.t('Notes'),
      key: 'description',
      width: '100%',
      render: 'renderNoWrap',
      sort: false,
      style: {
        fontWeight: 400,
      },
    },

    {
      title: '',
      key: 'student-details',
      width: '1%',
      render: 'renderDetails',
      sort: false,
      style: {
        fontWeight: 400,
      },
      params: {
        label: i18next.t('Detail'),
        modalTitle: i18next.t('DetailInformation'),
        // appName: 'Sgk',
        // sqlCommand: '[p_SACHSO_EBOOKS_GetScores_ByClassIdAndStudentId]',
        // parameters: ['classId', 'studentId', 'month'],
        appName: settings.APPLICATION_NAME,
        sqlCommand: '[p_SACHSO_EBOOKS_GetScores_ByClassIdAndStudentId]',
        parameters: ['classId', 'studentId', 'month'],
        rowKey: 'exerciseId',
        displayColumns: [
          {
            title: i18next.t('Book'),
            key: 'book',
            width: '1%',
            render: 'renderNoWrap',
            filter: true,
            style: {
              fontWeight: 600,
              minWidth: 72,
            },
          },
          {
            title: 'Unit',
            key: 'unit',
            width: '1%',
            render: 'renderNoWrap',
            filter: true,
            style: {
              minWidth: 72,
            },
          },
          {
            title: 'Exercise',
            key: 'exercise',
            width: '1%',
            render: 'renderNoWrap',
            style: {
              minWidth: 80,
            },
          },
          {
            title: 'Page',
            key: 'page',
            width: '1%',
            render: 'renderNoWrap',
            style: {
              minWidth: 72,
            },
          },
          {
            title: i18next.t('NumberOfTime'),
            key: 'numbers',
            width: '1%',
            render: 'renderNumber',
            style: {
              minWidth: 72,
            },
          },
          {
            title: i18next.t('TestResult'),
            children: [
              {
                title: i18next.t('TheLowest'),

                key: 'minScore',
                render: 'renderNumber',
                formatString: '0.00',
                style: {
                  fontWeight: 600,
                  color: colors.theme.danger,
                  minWidth: 100,
                },
                formatConditions: [
                  {
                    condition: 'record.minScore >= 6.5',
                    style: {
                      color: colors.theme.success,
                    },
                  },
                ],
              },
              {
                title: i18next.t('Average'),
                key: 'avgScore',
                width: '1%',
                render: 'renderNumber',
                formatString: '0.00',
                style: {
                  fontWeight: 600,
                  color: colors.theme.danger,
                  minWidth: 100,
                },
                formatConditions: [
                  {
                    condition: 'record.avgScore >= 6.5',
                    style: {
                      color: colors.theme.success,
                    },
                  },
                ],
              },
              {
                title: i18next.t('TheHighest'),
                key: 'maxScore',
                render: 'renderNumber',
                formatString: '0.00',
                style: {
                  fontWeight: 600,
                  color: colors.theme.danger,
                  minWidth: 100,
                },
                formatConditions: [
                  {
                    condition: 'record.maxScore >= 6.5',
                    style: {
                      color: colors.theme.success,
                    },
                  },
                ],
              },
            ],
          },
          {
            title: i18next.t('Notes'),
            key: 'notes',
            width: '100%',
            sort: false,
            render: 'renderNoWrap',
          },
          {
            title: '',
            key: 'exercise-details',
            width: '1%',
            render: 'renderDetails',
            sort: false,
            style: {
              fontWeight: 400,
            },
            params: {
              label: i18next.t('table:classInformation.Exam diary'),
              modalTitle: i18next.t('table:classInformation.Exam diary'),
              // appName: 'Sgk',
              // sqlCommand: '[p_SACHSO_EBOOKS_GetAnswerLogs_ByStudentId]',
              appName: settings.APPLICATION_NAME,
              sqlCommand: '[p_SACHSO_EBOOKS_GetAnswerLogs_ByStudentId]',
              parameters: ['exerciseId', 'studentId'],
              rowKey: 'id',
              displayColumns: [
                {
                  title: i18next.t('Book'),
                  key: 'book',
                  width: '1%',
                  render: 'renderNoWrap',
                  filter: true,
                  style: {
                    fontWeight: 600,
                    minWidth: 72,
                  },
                },
                {
                  title: 'Unit',
                  key: 'unit',
                  width: '1%',
                  render: 'renderNoWrap',
                  filter: true,
                  style: {
                    minWidth: 72,
                  },
                },
                {
                  title: 'Exercise',
                  key: 'exercise',
                  width: '1%',
                  render: 'renderNoWrap',
                  style: {
                    minWidth: 88,
                  },
                },
                {
                  title: 'Page',
                  key: 'page',
                  render: 'renderNoWrap',
                  style: {
                    minWidth: 72,
                  },
                },

                {
                  title: i18next.t('TestResult'),
                  children: [
                    {
                      title: i18next.t('Score'),
                      key: 'score',
                      render: 'renderNumber',
                      formatString: '0.00',
                      style: {
                        fontWeight: 600,
                        color: colors.theme.danger,
                        minWidth: 80,
                      },
                      formatConditions: [
                        {
                          condition: 'record.score >= 6.5',
                          style: {
                            color: colors.theme.success,
                          },
                        },
                      ],
                    },
                    {
                      title: i18next.t('table:statistic.Result'),
                      key: 'result',
                      render: 'renderText',
                      style: {
                        fontWeight: 600,
                        minWidth: 80,
                        textAlign: 'center',
                      },
                    },
                    {
                      title: i18next.t('Rate'),
                      key: 'rate',
                      render: 'renderRateDisabled',
                      style: {
                        fontSize: 14,
                        whiteSpace: 'nowrap',
                      },
                    },
                  ],
                },

                {
                  title: i18next.t('table:reference.Created date'),
                  key: 'createdDate',
                  width: '1%',
                  render: 'renderDateTime',
                },

                {
                  title: i18next.t('Notes'),
                  key: 'notes',
                  width: '100%',
                  render: 'renderNoWrap',
                },
              ],
            },
          },
          {
            key: 'Essay',
            render: (text, record) => record?.isEssay && <StudentsEssay record={record} />,
            sort: false,
            style: {
              fontWeight: 400,
            },
            title: '',
            width: '1%',
          },
        ],
      },
    },
  ],
};
