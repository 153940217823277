/* eslint-disable react/prop-types */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable sort-keys */
/* eslint-disable no-empty */
/* eslint-disable consistent-return */
/* eslint-disable import/order */
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Space, Spin, Table, Upload } from 'antd';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ExcelRenderer } from 'react-excel-renderer';
import { useSelector } from 'react-redux';
import columns from './column';
import { InsertDataQuestion, SelectAllType } from 'modules/Administration/ModuleAddQuestions';
import axios from 'axios';
import { query } from 'helpers/QueryHelper';
import { SettingDefault } from './SettingDefault';
import { SettingQuestionByExcel } from './ImportQuestionByExcel';
import DynamicButton from 'packages/DynamicButton/DynamicButton';
import settings from 'constants/settings';
import i18next from 'i18next';

const ViewImportQuestion = ({ buttonCloseModal }) => {
  const queryClient = useQueryClient();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [rows, setRows] = useState([]);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const { data: dataAllQuestion } = SelectAllType();

  const tableKey = useMemo(() => {
    if (rows && rows?.length > 0) {
      const stringArr = rows.map((item) => item.status);
      return stringArr.toString();
    } else {
      return 'question-table-excel-data';
    }
  }, [rows]);

  const fileHandler = (fileList) => {
    setIsProcessing(true);
    setIsDisableSubmit(true);
    const fileObj = fileList;
    if (!fileObj) {
      message.error(i18next.t('noFileUpload'));
      setIsProcessing(false);
      return false;
    }
    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      message.error(i18next.t('unknowfile'));
      setIsProcessing(false);
      return false;
    }
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
      } else {
        const removedEmptyRows = resp?.rows?.filter((row, indexRow) => indexRow > 0 && row?.length > 0);
        const tickedIndex = removedEmptyRows.map((row, indexRow) => {
          return {
            questionText: row[0]?.toString()?.replaceAll('"', "'"),
            index: indexRow,
            imageUrl: row[1]?.trim(),
            audioUrl: row[2]?.trim(),
            answer: row[3]?.toString()?.trim(),
            isCorrect: row[4],
            answer2: row[5]?.toString()?.trim(),
            type: row[6]?.trim()?.toUpperCase(),
            optionType: row[7]?.trim()?.toUpperCase(),
            grade: row[8]?.trim() ?? '',
            tags: row[9]?.trim(),
          };
        });

        if (tickedIndex.length === 0) {
          message.error('Không có dữ liệu nào được tìm thấy.');
          setIsProcessing(false);
          return false;
        }
        //Lấy các dòng chính của câu hỏi
        const questionList = tickedIndex.filter((i) => i.type);

        //Xử lý lấy đáp án cho câu hỏi
        const promises = questionList.map(
          (row, questionIndex) =>
            new Promise(async (resolve, reject) => {
              const findType = dataAllQuestion?.find((item) => {
                return item?.questionTypeCode?.toUpperCase() === row.type;
              });
              if (!findType) {
                return resolve({ data: row, error: 'Không tìm thấy Type (7)' });
              }
              const findOptionType = dataAllQuestion?.find((item) => {
                return item?.questionTypeCode?.toUpperCase() === row.optionType;
              });
              if (!findOptionType) {
                return resolve({ data: row, error: 'Không tìm thấy OptionType (8)' });
              }
              if (!row.grade) {
                return resolve({ data: row, error: 'Không tìm thấy Grade (9)' });
              }
              if (!row.tags) {
                return resolve({ data: row, error: 'Không tìm thấy Tags (10)' });
              }

              const rowIndex = row.index;
              const rowNextIndex = questionList[questionIndex + 1]?.index ?? tickedIndex.length;
              const questionText = row.questionText;
              const type = row.type;
              const optionType = row.optionType;
              // nếu dữ liệu không hợp lệ thì hiện lại dữ liệu từ excel để người nhập xem lại
              let newValue = {
                ...row,
                title: findOptionType.questionTypeName,
                subTitle: findOptionType.subTypeName,
              };

              let answers = [];

              if (optionType === 'SAYTHEWORDSTEXT' && type === 'RECORD') {
                if (!row.audioUrl) {
                  const splitArr = row?.answer?.split('|');
                  if (splitArr?.[0]) {
                    await axios
                      .get('https://toeic.ames.edu.vn/ames/api/AmesApi/AWSTextToSpeech?text=' + splitArr?.[0])
                      .then((res) => {
                        newValue.audioUrl = res?.data;
                        answers.push({
                          text: splitArr?.[0],
                          phonetic: splitArr?.[1],
                          textVN: splitArr?.[2],
                          sentences: splitArr?.[3],
                        });
                      });
                  }
                } else {
                  tickedIndex.slice(rowIndex, rowNextIndex)?.forEach((item) => {
                    const splitArr = item?.answer?.split('|');
                    answers.push({
                      text: splitArr?.[0],
                      phonetic: splitArr?.[1],
                      textVN: splitArr?.[2],
                      sentences: splitArr?.[3],
                    });
                  });
                }
              } else if (optionType === 'LOOKSENTENCEANDIMAGE' && type === 'RECORD') {
                const formatQuestionText = questionText?.toString()?.split('|');
                const questionTextFormat = formatQuestionText?.[0]?.replaceAll('"', "'");
                const questionTextVN = formatQuestionText?.[1]?.replaceAll('"', "'");
                newValue = {
                  ...newValue,
                  questionText: questionTextFormat,
                  questionTextVN: questionTextVN,
                };
              } else if (optionType === 'LISTENANDSPEAK' && type === 'RECORD') {
                const formatQuestionText = questionText?.toString()?.split('|');
                const questionTextFormat = formatQuestionText?.[0]?.replaceAll('"', "'");
                const questionTextVN = formatQuestionText?.[1]?.replaceAll('"', "'");

                newValue = {
                  ...newValue,
                  questionText: questionTextFormat,
                };
                tickedIndex?.slice(rowIndex, rowNextIndex).forEach((item) => {
                  const splitArray = item?.answer?.replaceAll('"', "'")?.split('|');
                  answers.push({
                    questionTextVN: questionTextVN,
                    text: splitArray?.[0],
                    phonetic: splitArray?.[1],
                    textVN: splitArray?.[2],
                    sentences: splitArray?.[3],
                  });
                });
              } else if (type === 'FILLINTHEBLANK') {
                row?.answer?.split('|')?.map((answer, answerIndex) => {
                  answers.push({
                    answer: answer?.replaceAll('"', "'"),
                    isCorrect: true,
                    id: answerIndex + 1,
                  });
                });
              } else if (type === 'UNSCRAMBLE') {
                answers.push({
                  answer: row.answer?.replaceAll('"', "'"),
                });
              } else {
                tickedIndex.slice(rowIndex, rowNextIndex)?.forEach((item) => {
                  if (item.answer) {
                    const { result } = SettingQuestionByExcel(type, optionType, item, answers.length, questionText);
                    answers.push(result);
                  }
                });
              }

              const { setting } = SettingDefault(type, optionType);

              return resolve({
                data: {
                  ...newValue,
                  settings: setting,
                  answers,
                },
              });
            }),
        );

        Promise.all(promises).then((results) => {
          const newRows = results.map((row) => {
            const { data, error } = row;
            return {
              ...data,
              color: error ? 'red' : 'green',
              status: error ? error : 'Hợp lệ',
            };
          });
          setRows(newRows);
          setIsProcessing(false);
          setIsDisableSubmit(false);
        });
      }
    });
    return false;
  };

  const handlerRemove = () => {
    setRows([]);
    setIsProcessing(false);
    setIsDisableSubmit(true);
  };
  const handleSubmit = () => {
    setIsProcessing(true);

    const promises = rows?.map(
      (row) =>
        new Promise((resolve) => {
          try {
            //nếu dữ liệu lỗi thì không lưu
            if (row.color !== 'green') {
              return resolve(row);
            }
            InsertDataQuestion(
              row?.type,
              row?.optionType,
              row?.questionText,
              JSON.stringify(row?.answers),
              row?.imageUrl,
              row?.audioUrl,
              '', //Video url
              row?.grade,
              ' ',
              JSON.stringify(row?.settings),
              1,
              ' ',
              row?.grade?.split(' ').join('').toUpperCase(),
              loggedInUser?.id,
              row?.tags,
            )
              .then((res) => {
                resolve({
                  ...row,
                  status: 'Lưu thành công',
                  color: 'green',
                });
              })
              .catch((err) => {
                resolve({
                  ...row,
                  status: 'Lưu thất bại',
                  color: 'red',
                });
              });
          } catch (error) {
            resolve({
              ...row,
              status: 'Lưu thất bại',
              color: 'red',
            });
          }
        }),
    );
    Promise.all(promises).then((results) => {
      queryClient.invalidateQueries('p_QUIZCITY_ResourcesQuestion_Search');
      message.success('Hoàn tất lưu dữ liệu!');
      setRows(results);
      setIsProcessing(false);
      setIsDisableSubmit(true);
    });
  };
  return (
    <Spin spinning={isProcessing}>
      <Space>
        <Upload name='file' beforeUpload={fileHandler} onRemove={handlerRemove} maxCount={1} accept='.xlsx'>
          <DynamicButton icon={<UploadOutlined />}>{i18next.t('Upload')}</DynamicButton>
        </Upload>
      </Space>
      <a
        href='https://cloud.softech.vn/storage/Data/MENARD/Exel-ImportQuiz/IMPORT_QuestionTemplate.xlsx'
        download
        style={{ marginLeft: 10, color: settings.IS_LG ? '#C2224f' : '' }}
      >
        <ins>{i18next.t('Sample file')}</ins>
      </a>
      {rows && (
        <>
          <div style={{ marginTop: 20 }}>
            <Table key={tableKey} rowKey='uid' dataSource={rows} pagination columns={columns} />
          </div>

          <div style={{ marginTop: 20, display: 'flex', justifyContent: 'end', columnGap: 20 }}>
            <DynamicButton onClick={() => buttonCloseModal?.()}>{i18next.t('Close')}</DynamicButton>
            <DynamicButton style={{ border: 'none' }} disabled={isDisableSubmit} onClick={handleSubmit} type='primary'>
              {i18next.t('Save')}
            </DynamicButton>
          </div>
        </>
      )}
    </Spin>
  );
};

export default ViewImportQuestion;
